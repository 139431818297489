<template>
  <d2-container>
    <el-form ref="form" status-icon :model="form" :rules="rules" label-width="120px">
      <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="后台设置" name="second">
              <el-form-item label="后台名称" prop="name">
                <el-input size="small" clearable  class="inputTextnew" st v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="后台图标" prop="logo">
                <el-upload
                  :headers="uploadHeaders"
                  class="avatar-uploader"
                  :action="uploadUrl + 'admin/v1/file/uploadFileImg'"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="form.logoImg" :src="form.logoImg" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="开启全屏" prop="isFull">
                <el-radio-group v-model="form.isFull">
                  <el-radio label="open">开启</el-radio>
                  <el-radio label="close">隐藏</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="开启多语言" prop="isLange">
                <el-radio-group v-model="form.isLange">
                  <el-radio label="open">开启</el-radio>
                  <el-radio label="close">隐藏</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="开启切换主体" prop="isSubject">
                <el-radio-group v-model="form.isSubject">
                  <el-radio label="open">开启</el-radio>
                  <el-radio label="close">隐藏</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="开启搜索" prop="isSearch">
                <el-radio-group v-model="form.isSearch">
                  <el-radio label="open">开启</el-radio>
                  <el-radio label="close">隐藏</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="开启日志" prop="isLog">
                <el-radio-group v-model="form.isLog">
                  <el-radio label="open">开启</el-radio>
                  <el-radio label="close">隐藏</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="开启字体设置" prop="isFont">
                <el-radio-group v-model="form.isFont">
                  <el-radio label="open">开启</el-radio>
                  <el-radio label="close">隐藏</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="开启主体颜色" prop="isColor">
                <el-radio-group v-model="form.isColor">
                  <el-radio label="open">开启</el-radio>
                  <el-radio label="close">隐藏</el-radio>
                </el-radio-group>
              </el-form-item>
            <div class="editBtn">
              <el-button :disabled="isUpdate" @click="update" type="primary" size="small"  >保存</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane label="文件储存" name="first">
            <el-divider content-position="left">储存配置</el-divider>
            <el-form-item label="类型" prop="isColor">
              <el-radio-group v-model="form.ossType">
                <el-radio label="file">本地</el-radio>
                <el-radio label="ali">阿里云</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-divider content-position="left">本地配置</el-divider>
            <el-form-item label="本地路径" prop="localFile">
              <el-input size="small" clearable  class="inputTextnew" st v-model="form.localFile" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="访问域名" prop="localDns">
              <el-input size="small" clearable  class="inputTextfile" st v-model="form.localDns" autocomplete="off"></el-input>
            </el-form-item>
            <el-divider content-position="left">阿里配置</el-divider>
            <el-form-item label="BucketName" prop="BucketName">
              <el-input size="small" clearable  class="inputTextnew" st v-model="form.aliBucketName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Endpoint" prop="Endpoint">
              <el-input size="small" clearable  class="inputTextnew" st v-model="form.aliEndpoint" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="accessKeyId" prop="accessKeyId">
              <el-input size="small" clearable  class="inputTextnew" st v-model="form.aliAccessKeyId" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="accessKeySecret" prop="accessKeySecret">
              <el-input size="small" clearable  class="inputTextnew" st v-model="form.aliAccessKeySecret" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="OssDns" prop="OssDns">
              <el-input size="small" clearable  class="inputTextfile" st v-model="form.aliOssDns" autocomplete="off"></el-input>
            </el-form-item>
            <!-- <el-divider content-position="left">腾讯配置</el-divider>
            <el-divider content-position="left">华为配置</el-divider>
            <el-divider content-position="left">七牛配置</el-divider> -->
            <div class="editBtn">
              <el-button :disabled="isUpdate" size="small" @click="update" type="primary">保存</el-button>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="支付设置" name="pay">
          </el-tab-pane>
          <el-tab-pane label="邮件设置" name="email">
          </el-tab-pane>
          <el-tab-pane label="邮箱设置" name="email">
          </el-tab-pane> -->
      </el-tabs>
    </el-form>
  </d2-container>
</template>

<script>
import api from '@/api'
import util from '@/libs/util.js'
export default {
  name: 'Setting',
  data () {
    return {
      isSelect: this.checkauthority(''),
      isCreate: this.checkauthority(''),
      isUpdate: this.checkauthority('setting_update'),
      isDelete: this.checkauthority(''),
      activeName: 'second',
      uploadUrl: process.env.VUE_APP_API,
      uploadHeaders: {
        Authorization: util.cookies.get('token')
      },
      form: {
        name: '',
        logo: '',
        isFull: '',
        isLange: '',
        isSubject: '',
        isSearch: '',
        isLog: '',
        isFont: '',
        isColor: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入后台名称', trigger: 'blur' }
        ],
        logo: [
          { required: true, message: '请输入后台图标', trigger: 'blur' }
        ],
        isFull: [
          { required: true, message: '请输入开启全屏', trigger: 'blur' }
        ],
        isLange: [
          { required: true, message: '请输入开启多语言', trigger: 'blur' }
        ],
        isSubject: [
          { required: true, message: '请输入开启切换主体', trigger: 'blur' }
        ],
        isSearch: [
          { required: true, message: '请输入开启搜索', trigger: 'blur' }
        ],
        isLog: [
          { required: true, message: '请输入开启日志', trigger: 'blur' }
        ],
        isFont: [
          { required: true, message: '请输入开启字体设置', trigger: 'blur' }
        ],
        isColor: [
          { required: true, message: '请输入开启主体颜色', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      if (res.code === 200) {
        this.$message.success('上传成功')
      }
      this.form.logo = res.body.url
      this.form.logoImg = res.body.dnsUrl + '/' + res.body.url
    },
    beforeAvatarUpload (file) {
      var type = file.type
      var isJPG = false
      if (type === 'image/jpeg') {
        isJPG = true
      } else if (type === 'image/png') {
        isJPG = true
      } else {
        isJPG = false
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG,PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    update () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.createTime = null
          if (this.form.settingId != null) {
            this.$confirm('此操作将永久修改该数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.updateApi()
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消修改'
              })
            })
          } else {
            this.updateApi()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async updateApi () {
      await api.UPDATE_SETTING(this.form)
      this.$message.success('操作成功')
    },
    async initDataApi () {
      var par = { settingId: 1 }
      var result = await api.GET_SETTING_INFO(par)
      this.form = result.body
      this.form.logoImg = process.env.VUE_APP_DOWNLOAD_URL + '/' + result.body.logo
      console.log(result)
    }
  },
  created () {
    this.initDataApi()
  }
}
</script>
<style>
  .editBtn{
    width: 100%;
    margin-left: 20px;
  }
  .inputTextnew {
    width: 230px;
  }
  .inputTextfile {
    width: 300px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 80px;
    display: block;
  }
</style>
