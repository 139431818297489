export default ({ request }) => ({
  // 分页查询
  userAgreement_update (data = {}) {
    return request({
      url: '/v1/userAgreement/update',
      method: 'post',
      data: data
    })
  },
  userAgreement_info (data = {}) {
    return request({
      url: '/v1/userAgreement/info',
      method: 'post',
      data: data
    })
  }
})
