<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import util from '@/libs/util'
export default {
  name: 'app',
  watch: {
    '$i18n.locale': 'i18nHandle'
  },
  created () {
    this.i18nHandle(this.$i18n.locale)
  },
  methods: {
    i18nHandle (val, oldVal) {
      util.cookies.set('lang', val)
      document.querySelector('html').setAttribute('lang', val)
    }
  }
}
</script>

<style lang="scss">
.d2-theme-container-main-body {
  background-color: #fff;
  // border: 1px solid #cfd7e5;
  border-top: none;
  border-bottom: none;
  box-sizing: border-box;
  overflow: auto;
}

@import '~@/assets/style/public-class.scss';
</style>
