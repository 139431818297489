<template>
   <d2-container>
      <head-search label-style='width:100px'>
         <head-search-item label='商品编码'>
            <el-input v-model="header.goodsCoding" size="small" placeholder="请输入商品编码"></el-input>
         </head-search-item>
         <head-search-item label='商品原ID'>
            <el-input v-model="header.goodId" size="small" placeholder="请输入商品原ID"></el-input>
         </head-search-item>
         <head-search-item label='商品名称'>
            <el-input v-model="header.goodsName" size="small" placeholder="请输入商品名称"></el-input>
         </head-search-item>
         <head-search-item label='统计日期'>
            <el-date-picker v-model="header.time" end-placeholder="结束日期" range-separator="至" size="small"
               start-placeholder="开始日期" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
         </head-search-item>
         <head-search-item label="仓库名称">
                <el-select v-model="header.warehouseId" class="searchInput" clearable placeholder="请选择仓库名称"
                    size="small">
                    <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.warehouseName"
                        :value="item.warehouseId">
                    </el-option>
                </el-select>
            </head-search-item>
         <div class="searchBtn">
            <el-button icon="el-icon-search" size="small" type="primary" @click="toQuery()">搜索</el-button>
            <el-button icon="el-icon-refresh" size="small" type="warning" @click="resetBtn()">重置</el-button>
            <!-- <el-button icon="el-icon-download" size="small" type="success" @click="exportBtn()">导出</el-button> -->
         </div>
      </head-search>
      <div class="topCount">
         <div>入库数量<span>{{ isNumber(count.sum) }}</span></div>
         <div>入库金额<span>{{ isNumber(count.sumPrice).toFixed(2) }}</span></div>
         <div>出库数量<span>{{ isNumber(count.goodsNum) }}</span></div>
         <div>出库金额<span>{{ isNumber(count.priceNum).toFixed(2) }}</span></div>
      </div>
      <el-table :data="tableData" :header-cell-style="headerClass" :cell-style="rowClass" v-loading="loading"  @sort-change="sortChange">
         <el-table-column prop="" label="序号">
            <template slot-scope="scope">
               {{ (search.pageNo - 1) * search.pageSize + scope.$index + 1 }}
            </template>
         </el-table-column>
         <el-table-column prop="goodsCoding" label="商品编码"></el-table-column>
         <el-table-column prop="goodsId" label="商品原ID"></el-table-column>
         <el-table-column prop="goodsName" label="商品名称"></el-table-column>
         <el-table-column prop="pictureUrl" label="商品图片">
            <template slot-scope="scope">
               <el-image :preview-src-list="[downloadUrl + '/' + scope.row.pictureUrl]"
                  :src="downloadUrl + '/' + scope.row.pictureUrl" style="width: 40px; height: 40px;" />
            </template>
         </el-table-column>
         <el-table-column prop="sum" label="入库数量" sortable="custom"></el-table-column>
         <el-table-column prop="sumPrice" label="入库金额" sortable="custom"></el-table-column>
         <el-table-column prop="goodsNum" label="出库数量">
            <template slot-scope="scope">
               {{ scope.row.goodsNum ? scope.row.goodsNum : 0 }}
            </template>
         </el-table-column>
         <el-table-column prop="priceNum" label="出库金额">
            <template slot-scope="scope">
               {{ scope.row.priceNum ? scope.row.priceNum : 0 }}
            </template>
         </el-table-column>
      </el-table>
      <!--      分页      -->
      <div style="display: flex; justify-content: right; margin-top: 10px">
         <el-pagination v-if="search.pageSize > 0" :current-page="search.pageNo" :page-size="search.pageSize"
            :page-sizes="[10, 20, 30, 40, 50, 100]" :total="search.pageTotal"
            layout="total, jumper, prev, pager, next,sizes" @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
         </el-pagination>
      </div>
   </d2-container>
</template>

<script>
let searchSource = {
   ipName: '',
   pageNo: 1,
   pageSize: 50,
   pageTotal: 0
}
let headerSource = {
   goodsCoding: null,
   goodId: null,
   goodsName: null,
   time: [],
   field:null,
   sort:null,
   warehouseId:null,
}
import api from '@/api'
import * as getListM from '../getListM'
export default {
   name:'commoditySummaryStatement',
   data() {
      return {
         warehouseList: [],//仓库
         downloadUrl: process.env.VUE_APP_DOWNLOAD_URL, // downloadUrl
         loading: false,
         header: JSON.parse(JSON.stringify(headerSource)),
         search: JSON.parse(JSON.stringify(searchSource)),
         count: {},
         tableData: [],
      }
   },
   async mounted() {
      this.initialize()
      this.getCount()
      this.seachEnterFun()
      this.warehouseList = await getListM.getWarehouse()//仓库名称
   },
   methods: {
      sortChange(column) {
      this.header.field = column.prop
      this.header.sort = column.order == 'ascending' ? 0 : column.order == 'descending' ? 1 : null
      if(this.header.sort==null){
         this.header.field = null
      }
      this.initialize()
      this.getCount()
      // column//当前列
      // column.prop//字段名
      // column.order//排序方法【ascending】是升序，【descending】是降序
    },
     //键盘回车监听方法
     seachEnterFun() {
      document.onkeydown = () => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          //调用定义的搜索方法
          this.toQuery();
        }
      };
    },
      async initialize() {
         this.loading = true
         const res = await api.getCommoditySummaryStatementList({
            "endTime": this.header.time && this.header.time.length > 0 ? this.header.time[1] : null,
            "goodsCoding": this.header.goodsCoding,
            "goodsId": this.header.goodId,
            "goodsName": this.header.goodsName,
            "pageNo": this.search.pageNo,
            "pageSize": this.search.pageSize,
            "startTime": this.header.time && this.header.time.length > 0 ? this.header.time[0] : null,
            "field":this.header.field,
            'sort':this.header.sort,
            warehouseId:this.header.warehouseId
         })
         try {
            this.tableData = res.body.datas
            this.search.pageTotal = res.body.sumDataCount?res.body.sumDataCount:0
            this.loading = false
         } catch (err) {

         }
      },
      async getCount() {
         const res = await api.getCommoditySummaryStatementCount({
            "endTime": this.header.time && this.header.time.length > 0 ? this.header.time[1] : null,
            "goodsCoding": this.header.goodsCoding,
            "goodsId": this.header.goodId,
            "goodsName": this.header.goodsName,
            "startTime": this.header.time && this.header.time.length > 0 ? this.header.time[0] : null,
            warehouseId:this.header.warehouseId
         })
         try {
            this.count = res.body
         } catch (err) {

         }
      },
      isNumber(e) {
         if (e) {
            return Number(e)
         } else {
            return 0.00
         }
      },
      exportBtn() {

      },
      resetBtn() {
         this.header = JSON.parse(JSON.stringify(headerSource))
         this.initialize()
         this.getCount()
      },
      toQuery() {
         this.initialize()
         this.getCount()
      },
      // table样式
      headerClass() {
         return "background: #eef1f6; color: #606266;text-align:center"
      },
      rowClass() {
         return "text-align:center"
      },
      handleSizeChange(val) {
         this.search.pageSize = val
         this.initialize()
      },
      handleCurrentChange(val) {
         this.search.pageNo = val
         this.initialize()
      },
   }
}
</script>

<style lang='scss' scoped>
.searchBtn {
   margin-left: 20px;
   margin-bottom: 10px;
}

.topCount {
   width: 100%;
   padding:0px 7px;
   display: flex;
   justify-content: space-around;
   /* justify-content: space-around; */
   box-shadow: 0px 0px 4px #888888;
   // border: 1px solid #000;
   min-height: 50px;
   align-items: center;
   margin: 0 auto;
   font-size: 18px;
   font-weight: 600;
   flex-wrap: wrap;
   margin-bottom:10px;
   border-radius: 10px;

   div {
      min-width: 250px;
   }

   span {
      color: blue;
      margin-left: 10px;
   }
}
</style>