var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "div",
            {
              staticClass: "searchBgc1",
              staticStyle: { "margin-bottom": "20px" }
            },
            [_vm._v("基本信息")]
          ),
          _c(
            "el-form",
            {
              ref: "redactData",
              staticClass: "ruleSty",
              attrs: {
                model: _vm.redactData,
                "label-width": "100px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "div2",
                  style:
                    _vm.redactData.purchaseType === _vm.tag4
                      ? "display:flex;justify-content: left;"
                      : ""
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "div3",
                      style:
                        _vm.redactData.purchaseType === _vm.tag4
                          ? "display:flex"
                          : ""
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "div1" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "采购类型", prop: "purchaseType" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择采购类型",
                                    size: "small",
                                    clearable: ""
                                  },
                                  on: { change: _vm.changePurchaseType },
                                  model: {
                                    value: _vm.redactData.purchaseType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.redactData,
                                        "purchaseType",
                                        $$v
                                      )
                                    },
                                    expression: "redactData.purchaseType"
                                  }
                                },
                                _vm._l(_vm.purchaseTypesData, function(item) {
                                  return _c("el-option", {
                                    key: item.keys,
                                    attrs: {
                                      label: item.values,
                                      value: item.keys,
                                      title: _vm.onHoverText
                                    },
                                    nativeOn: {
                                      mouseenter: function($event) {
                                        return _vm.onMouseOver(item.values)
                                      }
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          this.redactData.purchaseType !== this.tag4
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "渠道", prop: "channel" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择渠道",
                                        size: "small",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.redactData.channel,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.redactData,
                                            "channel",
                                            $$v
                                          )
                                        },
                                        expression: "redactData.channel"
                                      }
                                    },
                                    _vm._l(_vm.channelsData, function(item) {
                                      return _c("el-option", {
                                        key: item.keys,
                                        attrs: {
                                          label: item.values,
                                          value: item.keys,
                                          title: _vm.onHoverText
                                        },
                                        nativeOn: {
                                          mouseenter: function($event) {
                                            return _vm.onMouseOver(item.values)
                                          }
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          this.redactData.purchaseType !== this.tag4
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "付款方式",
                                    prop: "payMethod"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择付款方式",
                                        size: "small",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.redactData.payMethod,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.redactData,
                                            "payMethod",
                                            $$v
                                          )
                                        },
                                        expression: "redactData.payMethod"
                                      }
                                    },
                                    _vm._l(_vm.payMethodsData, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.value,
                                          value: item.id,
                                          title: _vm.onHoverText
                                        },
                                        nativeOn: {
                                          mouseenter: function($event) {
                                            return _vm.onMouseOver(item.values)
                                          }
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          this.redactData.purchaseType === this.tag5
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "预定日期",
                                    prop: "predictArriveDate"
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "215px" },
                                    attrs: {
                                      type: "date",
                                      size: "small",
                                      "value-format": "yyyy-MM-dd",
                                      placeholder: "选择日期"
                                    },
                                    model: {
                                      value: _vm.redactData.predictArriveDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.redactData,
                                          "predictArriveDate",
                                          $$v
                                        )
                                      },
                                      expression: "redactData.predictArriveDate"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "div1" },
                          [
                            this.redactData.purchaseType !== this.tag4
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "发货方式",
                                      prop: "deliveryMethod"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择发货方式",
                                          size: "small",
                                          clearable: ""
                                        },
                                        on: {
                                          change: _vm.deliveryMethodChange
                                        },
                                        model: {
                                          value: _vm.redactData.deliveryMethod,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.redactData,
                                              "deliveryMethod",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "redactData.deliveryMethod"
                                        }
                                      },
                                      _vm._l(_vm.deliveryMethodsData, function(
                                        item,
                                        index
                                      ) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.value,
                                            value: item.id,
                                            title: _vm.onHoverText
                                          },
                                          nativeOn: {
                                            mouseenter: function($event) {
                                              return _vm.onMouseOver(item.value)
                                            }
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.returnSty()
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "收货人",
                                      prop: "deliveryUserName"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "215px" },
                                      attrs: {
                                        placeholder: "请输入收货人",
                                        clearable: "",
                                        size: "small"
                                      },
                                      model: {
                                        value: _vm.redactData.deliveryUserName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.redactData,
                                            "deliveryUserName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "redactData.deliveryUserName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.returnSty()
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "联系方式",
                                      prop: "deliveryPhone"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "215px" },
                                      attrs: {
                                        placeholder: "请输入收货人联系方式",
                                        clearable: "",
                                        maxlength: "11",
                                        size: "small"
                                      },
                                      model: {
                                        value: _vm.redactData.deliveryPhone,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.redactData,
                                            "deliveryPhone",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "redactData.deliveryPhone"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "入库仓库",
                                  prop: "warehouseId"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择入库仓库",
                                      size: "small",
                                      clearable: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.getWarehouseAddress(
                                          _vm.redactData.warehouseId
                                        )
                                      }
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.openWarehouseId()
                                      }
                                    },
                                    model: {
                                      value: _vm.redactData.warehouseId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.redactData,
                                          "warehouseId",
                                          $$v
                                        )
                                      },
                                      expression: "redactData.warehouseId"
                                    }
                                  },
                                  _vm._l(_vm.warehouseIdsData, function(
                                    item,
                                    index
                                  ) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.warehouseName,
                                        value: item.warehouseId,
                                        disabled: item.disabled,
                                        title: _vm.onHoverText
                                      },
                                      nativeOn: {
                                        mouseenter: function($event) {
                                          return _vm.onMouseOver(item.value)
                                        }
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            this.redactData.purchaseType === this.tag4 &&
                            this.redactData.warehouseId !== ""
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "来源仓库",
                                      prop: "sourceWarehouseId"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择来源仓库",
                                          size: "small",
                                          clearable: ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changeSourceWarehouseId()
                                          }
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.openSourceWarehouseId()
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.redactData.sourceWarehouseId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.redactData,
                                              "sourceWarehouseId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "redactData.sourceWarehouseId"
                                        }
                                      },
                                      _vm._l(
                                        _vm.sourceWarehouseIdsData,
                                        function(item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.warehouseName,
                                              value: item.warehouseId,
                                              disabled: item.disabled,
                                              title: _vm.onHoverText
                                            },
                                            nativeOn: {
                                              mouseenter: function($event) {
                                                return _vm.onMouseOver(
                                                  item.value
                                                )
                                              }
                                            }
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            this.redactData.purchaseType === this.tag4 &&
                            this.redactData.sourceWarehouseId !== ""
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "仓库位置",
                                      prop: "sameWarehouseFlag"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择仓库位置",
                                          size: "small",
                                          clearable: ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changeSameWarehouseFlag()
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.redactData.sameWarehouseFlag,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.redactData,
                                              "sameWarehouseFlag",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "redactData.sameWarehouseFlag"
                                        }
                                      },
                                      _vm._l(
                                        _vm.sameWarehouseFlagData,
                                        function(item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.value,
                                              value: item.id,
                                              title: _vm.onHoverText
                                            },
                                            nativeOn: {
                                              mouseenter: function($event) {
                                                return _vm.onMouseOver(
                                                  item.value
                                                )
                                              }
                                            }
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            this.redactData.purchaseType === this.tag4 &&
                            this.redactData.sameWarehouseFlag === "0" &&
                            this.redactData.sourceWarehouseId !== ""
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "配送方式",
                                      prop: "distribution"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "0" },
                                        model: {
                                          value: _vm.redactData.distribution,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.redactData,
                                              "distribution",
                                              $$v
                                            )
                                          },
                                          expression: "redactData.distribution"
                                        }
                                      },
                                      [_vm._v(" 自配送 ")]
                                    ),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "2" },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.changeLogisticsRule()
                                          }
                                        },
                                        model: {
                                          value: _vm.redactData.distribution,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.redactData,
                                              "distribution",
                                              $$v
                                            )
                                          },
                                          expression: "redactData.distribution"
                                        }
                                      },
                                      [_vm._v(" 物流 ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _c("div")
                ]
              ),
              this.redactData.purchaseType !== "调拨" ||
              (this.redactData.sameWarehouseFlag !== "1" &&
                this.redactData.distribution === "2")
                ? _c(
                    "div",
                    {
                      staticClass: "searchBgc2",
                      staticStyle: {
                        "margin-bottom": "10px",
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [_vm._v(" 物流信息 ")]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "div1" },
                [
                  this.redactData.purchaseType !== "调拨" ||
                  (this.redactData.sameWarehouseFlag !== "1" &&
                    this.redactData.distribution === "2")
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "物流公司", prop: "courierCompany" }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "215px" },
                            attrs: {
                              placeholder: "请输入物流公司",
                              clearable: "",
                              size: "small"
                            },
                            model: {
                              value: _vm.redactData.courierCompany,
                              callback: function($$v) {
                                _vm.$set(_vm.redactData, "courierCompany", $$v)
                              },
                              expression: "redactData.courierCompany"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  this.redactData.purchaseType !== "调拨" ||
                  (this.redactData.sameWarehouseFlag !== "1" &&
                    this.redactData.distribution === "2")
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "物流单号", prop: "trackingNumber" }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "215px" },
                            attrs: {
                              placeholder: "请输入物流单号",
                              clearable: "",
                              size: "small"
                            },
                            model: {
                              value: _vm.redactData.trackingNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.redactData, "trackingNumber", $$v)
                              },
                              expression: "redactData.trackingNumber"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  this.redactData.purchaseType !== "调拨" ||
                  (this.redactData.sameWarehouseFlag !== "1" &&
                    this.redactData.distribution === "2")
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "收货地址", prop: "deliveryAddress" }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "530px" },
                            attrs: {
                              placeholder: "请输入收货地址",
                              clearable: "",
                              size: "small"
                            },
                            model: {
                              value: _vm.redactData.deliveryAddress,
                              callback: function($$v) {
                                _vm.$set(_vm.redactData, "deliveryAddress", $$v)
                              },
                              expression: "redactData.deliveryAddress"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              this.redactData.purchaseType !== this.tag4
                ? _c(
                    "div",
                    {
                      staticClass: "searchBgc2",
                      staticStyle: {
                        "margin-bottom": "10px",
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [_vm._v(" 交易截图 ")]
                  )
                : _vm._e(),
              this.redactData.purchaseType !== this.tag4
                ? _c(
                    "el-form-item",
                    { attrs: { label: "付款截图" } },
                    [
                      _c("erp-upload-img", {
                        attrs: {
                          showDelete: true,
                          "upload-path": "/purchasingManagement/purchasing/",
                          maxCount: 10
                        },
                        model: {
                          value: _vm.redactData.payProve,
                          callback: function($$v) {
                            _vm.$set(_vm.redactData, "payProve", $$v)
                          },
                          expression: "redactData.payProve"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "searchBgc2",
                  staticStyle: {
                    "margin-bottom": "10px",
                    display: "flex",
                    "justify-content": "space-between"
                  }
                },
                [
                  _vm._v(" 商品信息 "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        type: "success",
                        size: "small"
                      },
                      on: {
                        click: function($event) {
                          return _vm.addItems()
                        }
                      }
                    },
                    [_vm._v(" 添加 ")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "itemAddSty", attrs: { prop: "itemAddData" } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "itemAddData",
                      staticStyle: { "box-sizing": "border-box" },
                      attrs: {
                        data: _vm.redactData.itemAddData,
                        "max-height": "690px",
                        "element-loading-text": "数据加载中",
                        "cell-style": { textAlign: "center" },
                        "tooltip-effect": "dark",
                        "row-style": { height: "77px" },
                        "header-cell-style": {
                          background: "#dfe6ec",
                          color: "#666666",
                          textAlign: "center"
                        }
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "商品编码",
                          prop: "goodsCoding",
                          align: "center",
                          width: "100"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "white-space": "nowrap",
                                      width: "80%",
                                      "font-size": "10px",
                                      cursor: "pointer",
                                      color: "#1482f0"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(scope.row.goodsCoding) + " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品名称",
                          prop: "goodsName",
                          align: "center",
                          width: "300"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "box-sizing": "border-box",
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      "white-space": "normal",
                                      "word-break": "break-all",
                                      "line-height": "23px",
                                      "padding-right": "10px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(scope.row.goodsName) + " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品图片",
                          prop: "pictureUrl",
                          align: "center",
                          width: "80"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.pictureUrl !== "" &&
                                scope.row.pictureUrl !== null
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                        "margin-top": "8px",
                                        cursor: "pointer"
                                      },
                                      attrs: {
                                        src:
                                          _vm.downloadUrl +
                                          "/" +
                                          scope.row.pictureUrl,
                                        alt: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getPreview(
                                            _vm.downloadUrl +
                                              "/" +
                                              scope.row.pictureUrl
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                scope.row.pictureUrl === "" ||
                                scope.row.pictureUrl === null
                                  ? _c("div", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                        border: "1px solid #c0c0c0",
                                        display: "flex",
                                        "justify-content": "center",
                                        margin: "0 auto"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "分类",
                          prop: "goodsOne",
                          align: "center",
                          width: "120"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "box-sizing": "border-box",
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      "white-space": "normal",
                                      "word-break": "break-all",
                                      "line-height": "23px",
                                      "padding-right": "10px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.goodsOne === null
                                            ? "- -"
                                            : scope.row.goodsOne
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "品牌",
                          prop: "goodsBrandName",
                          align: "center",
                          width: "120"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "box-sizing": "border-box",
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      "white-space": "normal",
                                      "word-break": "break-all",
                                      "line-height": "23px",
                                      "padding-right": "10px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.goodsBrandName === null
                                            ? "- -"
                                            : scope.row.goodsBrandName
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品IP",
                          prop: "goodsIpName",
                          align: "center",
                          width: "120"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "box-sizing": "border-box",
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      "white-space": "normal",
                                      "word-break": "break-all",
                                      "line-height": "23px",
                                      "padding-right": "10px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(scope.row.goodsIpName) + " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "我的入库价(元)",
                          prop: "warehousePriceRange",
                          width: "120",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      overflow: "hidden",
                                      "white-space": "nowrap",
                                      "text-overflow": "ellipsis"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.warehousePriceRange ===
                                            null ||
                                            scope.row.warehousePriceRange ===
                                              undefined
                                            ? "- -"
                                            : scope.row.warehousePriceRange
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "我的库存数量",
                          prop: "inventoryNum",
                          width: "120",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      overflow: "hidden",
                                      "white-space": "nowrap",
                                      "text-overflow": "ellipsis"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.inventoryNum === null ||
                                            scope.row.inventoryNum === undefined
                                            ? "- -"
                                            : scope.row.inventoryNum
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "我的在途数量",
                          prop: "inTransitNum",
                          width: "120",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      overflow: "hidden",
                                      "white-space": "nowrap",
                                      "text-overflow": "ellipsis"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.inTransitNum === null ||
                                            scope.row.inTransitNum === undefined
                                            ? "- -"
                                            : scope.row.inTransitNum
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "采购数量",
                          align: "center",
                          width: "150"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "itemAddData." + scope.$index + ".num",
                                      rules: _vm.rules.num
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        precision: 0,
                                        step: 1,
                                        min: 1,
                                        size: "small"
                                      },
                                      model: {
                                        value: scope.row.num,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "num",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "scope.row.num"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      this.redactData.purchaseType !== this.tag4
                        ? _c("el-table-column", {
                            attrs: {
                              label: "采购单价",
                              prop: "unitPrice",
                              align: "center",
                              width: "150"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "itemAddData." +
                                              scope.$index +
                                              ".unitPrice",
                                            rules: _vm.rules.unitPrice
                                          }
                                        },
                                        [
                                          _c("el-input-number", {
                                            attrs: {
                                              precision: 2,
                                              step: 1,
                                              min: 0,
                                              size: "small"
                                            },
                                            model: {
                                              value: scope.row.unitPrice,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "unitPrice",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.unitPrice"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1245913476
                            )
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "100", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      icon: "el-icon-delete",
                                      plain: "",
                                      size: "small"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.remove(
                                          scope.$index,
                                          scope.row,
                                          _vm.redactData.itemAddData
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" 删除 ")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.redactData.itemAddData.length > 0
                ? _c("div", { staticClass: "textSty" }, [
                    _c("div", [
                      _c("span", [_vm._v("合计数量：")]),
                      _c(
                        "span",
                        {
                          staticClass: "spanSty",
                          staticStyle: { "margin-right": "10px" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                isNaN(_vm.count.totalNum)
                                  ? 0
                                  : _vm.count.totalNum
                              ) +
                              " "
                          )
                        ]
                      )
                    ]),
                    this.redactData.purchaseType !== this.tag4
                      ? _c("div", [
                          _c("span", [_vm._v("合计金额：")]),
                          _c("span", { staticClass: "spanSty" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  isNaN(_vm.count.totalAmount)
                                    ? 0
                                    : _vm.count.totalAmount / 100 > 0
                                    ? (_vm.count.totalAmount / 100).toFixed(2)
                                    : 0
                                ) +
                                " "
                            )
                          ]),
                          _vm._v(" 元 ")
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "searchBgc2",
                  staticStyle: { "margin-bottom": "20px" }
                },
                [_vm._v(" 备注信息 ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注信息" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "99%" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 5, maxRows: 10 },
                      placeholder: "请输入备注信息",
                      maxlength: "240",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.redactData.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.redactData, "remark", $$v)
                      },
                      expression: "redactData.remark"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "margin-bottom": "20px"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.resetForm("redactData")
                        }
                      }
                    },
                    [_vm._v(" 关闭 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        disabled: _vm.submitLoading,
                        loading: _vm.submitLoading
                      },
                      nativeOn: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.submitForm()
                        }
                      }
                    },
                    [_vm._v(" 保存 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { margin: "20px auto" },
          attrs: {
            "append-to-body": true,
            title: _vm.judgment,
            visible: _vm.dialogFormVisiblePic,
            width: "540px",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisiblePic = $event
            },
            close: function($event) {
              return _vm.closeDialogPic()
            }
          }
        },
        [
          this.dialogImageUrl !== ""
            ? _c("img", {
                staticStyle: {
                  width: "500px",
                  height: "500px",
                  "text-align": "center"
                },
                attrs: { alt: "", src: this.dialogImageUrl }
              })
            : _vm._e()
        ]
      ),
      _c("purchaseSelectGoods", {
        ref: "purchaseSelectGoods",
        on: { setGoods: _vm.selectGoods }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }