<template>
    <d2-container>
        <head-search label-style='width:100px'>
            <head-search-item label='退货单号'>
                <el-input v-model="header.outNo" size="small" placeholder="请输入商品编码"></el-input>
            </head-search-item>
            <head-search-item label='商品编码'>
                <el-input v-model="header.goodsCoding" size="small" placeholder="请输入商品编码"></el-input>
            </head-search-item>
            <head-search-item label='商品原ID'>
                <el-input v-model="header.goodsId" size="small" placeholder="请输入商品原ID"></el-input>
            </head-search-item>
            <head-search-item label='商品名称'>
                <el-input v-model="header.goodsName" size="small" placeholder="请输入商品名称"></el-input>
            </head-search-item>
            <head-search-item label="公司名称">
                <el-select multiple v-model="header.orgIds" class="searchInput" clearable placeholder="请选择公司名称" size="small">
                    <el-option v-for="(item, index) in companyList" :key="index" :label="item.orgName" :value="item.orgId">
                    </el-option>
                </el-select>
            </head-search-item>
            <head-search-item label="部门名称">
                <el-select multiple v-model="header.deptIds" class="searchInput" clearable placeholder="请选择部门名称" size="small" :disabled="disabledDeptIds">
                    <el-option v-for="(item, index) in branchList" :key="index" :label="item.orgName" :value="item.orgId">
                    </el-option>
                </el-select>
            </head-search-item>
            <head-search-item label="仓库名称">
                <el-select multiple v-model="header.warehouseIds" class="searchInput" clearable placeholder="请选择仓库名称"
                    size="small">
                    <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.warehouseName"
                        :value="item.warehouseId">
                    </el-option>
                </el-select>
            </head-search-item>
            <head-search-item label="操作人">
                <el-select multiple v-model="header.createdIds" class="searchInput" clearable placeholder="请选择操作人"
                    size="small">
                    <el-option v-for="(item, index) in userList" :key="index" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </head-search-item>
            <head-search-item label='统计日期'>
                <el-date-picker v-model="header.time" end-placeholder="结束日期" range-separator="至" size="small"
                    start-placeholder="开始日期" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </head-search-item>
            <div class="searchBtn">
                <el-button icon="el-icon-search" size="small" type="primary" @click="toQuery()">搜索</el-button>
                <el-button icon="el-icon-refresh" size="small" type="warning" @click="resetBtn()">重置</el-button>
                <!-- <el-button icon="el-icon-download" size="small" type="success" @click="exportBtn()">导出</el-button> -->
            </div>
        </head-search>
        <div class="topCount">
            <div>入库总价：<span>{{ isNumber(count.price).toFixed(2) }}</span></div>
            <div>出库总价：<span>{{ isNumber(count.outPrice).toFixed(2) }}</span></div>
            <div>数量：<span>{{ isNumber(count.sum).toFixed(2) }}</span></div>
        </div>
        <el-table :data="tableData" :header-cell-style="headerClass" :cell-style="rowClass" v-loading="loading" @sort-change="sortChange">
            <el-table-column prop="createdTimestamp" label="退货时间"></el-table-column>
            <el-table-column prop="outId" label="退货单号"></el-table-column>
            <el-table-column prop="goodsCoding" label="商品编码"></el-table-column>
            <el-table-column prop="goodsId" label="商品原ID"></el-table-column>
            <el-table-column prop="goodsName" label="商品名称"></el-table-column>
            <el-table-column prop="price" label="入库价（元）" sortable="custom"></el-table-column>
            <el-table-column prop="outPrice" label="出库价" sortable="custom"></el-table-column>
            <el-table-column prop="sum" label="数量" sortable="custom"></el-table-column>
            <el-table-column prop="reason" label="退货原因"></el-table-column>
            <el-table-column prop="orgName" label="公司名称"></el-table-column>
            <el-table-column prop="deptName" label="部门名称"></el-table-column>
            <el-table-column prop="warehouseName" label="仓库名称"></el-table-column>
            <el-table-column prop="createdName" label="操作人"></el-table-column>
        </el-table>
        <!--      分页      -->
        <div style="display: flex; justify-content: right; margin-top: 10px">
            <el-pagination v-if="search.pageSize > 0" :current-page="search.pageNo" :page-size="search.pageSize"
                :page-sizes="[10, 20, 30, 40, 50, 100]" :total="search.pageTotal"
                layout="total, jumper, prev, pager, next,sizes" @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
            </el-pagination>
        </div>
    </d2-container>
</template>

<script>
let searchSource = {
    ipName: '',
    pageNo: 1,
    pageSize: 50,
    pageTotal: 0
}
let headerSource = {
    time: [],
    createdIds: [],//制单人
    deptIds: [],//部门
    destination: [],//发货方式
    goodsCoding: null,//商品编码
    goodsId: null,//商品原ID
    goodsName: null,//商品名称
    intoNo: null,//入库单号
    intoType: [],//入库类型
    orgIds: [],//公司ID
    source: [],//订单来源
    warehouseIds: [],//仓库ID
    outNo:null,
    field:null,
    sort:null,
}
import api from '@/api'
import *as getListM from '../getListM'
export default {
    name: 'THCHKB',
    data() {
        return {
            count:{},
            disabledDeptIds:true,
            loading:false,
            search: JSON.parse(JSON.stringify(searchSource)),
            header: JSON.parse(JSON.stringify(headerSource)),
            list: [],
            tableData: [],
            companyList: [],
            branchList: [],
            userList: [],
            warehouseList: [],
        }
    },
    async mounted() {
        this.initialize()
        this.countGet()
        this.userList = await getListM.getUser() // 用户查询
        this.companyList = await getListM.getOrg()//公司名称
        this.warehouseList = await getListM.getWarehouse()//仓库名称
        this.seachEnterFun()
    },
    watch: {
        async 'header.orgIds'(newVal, oldVal) {
            if (newVal&&newVal.length!=0) {
                let map = new Map
                let list = []
                let name = null
                this.branchList = []
                if(this.header.orgIds.length!=0){
                    this.header.orgIds.forEach(async(e)=>{
                        list = await getListM.getPart(e)//部门名称
                        this.companyList.forEach((x)=>{
                            if(e==x.orgId){
                                name = x.orgName
                            }
                        })
                        list.forEach((s)=>{
                            s.orgName = name+ '--' + s.orgName
                        })
                        this.branchList.push(...list)
                    })
                }
                this.disabledDeptIds=false
            }else{
                this.branchList = []
                this.header.deptIds = []
                this.disabledDeptIds=true
            }
        }
    },
    methods: {
        isNumber(e) {
            if (e) {
                return Number(e)
            } else {
                return 0
            }
        },
        sortChange(column) {
            this.header.field = column.prop
            this.header.sort = column.order == 'ascending' ? 0 : column.order == 'descending' ? 1 : null
            if(this.header.sort==null){
                this.header.field = null
            }
            this.initialize()
            this.countGet()
            this.getCount()
            // column//当前列
            // column.prop//字段名
            // column.order//排序方法【ascending】是升序，【descending】是降序
        },
          //键盘回车监听方法
          seachEnterFun() {
            document.onkeydown = () => {
                let _key = window.event.keyCode;
                if (_key === 13) {
                    //调用定义的搜索方法
                    this.toQuery();
                }
            };
        },
        async countGet(){
            const res = await api.getNewStaticalGetNewStat({
                "createdIds": this.header.createdIds,//操作人
                "deptIds": this.header.deptIds,//部门
                "endTime": this.header.time && this.header.time.length > 0 ? this.header.time[1] : null,//结束时间
                "goodsCoding": this.header.goodsCoding,//商品编码
                "goodsId": this.header.goodsId,//商品原ID
                "goodsName": this.header.goodsName,//商品名称
                "orgIds": this.header.orgIds,//公司名称
                "outNo": this.header.outNo,//
                "pageNo": this.search.pageNo,//
                "pageSize": this.search.pageSize,//
                "startTime": this.header.time && this.header.time.length > 0 ? this.header.time[0] : null,//开始时间
                "warehouseIds": this.header.warehouseIds,//仓库
                "field":this.header.field,
                "sort":this.header.sort
            })
            try {
                this.count = res.body
            } catch (err) {

            }
        },
        async initialize() {
            this.loading = true
            const res = await api.getTHCHKBList({
                "createdIds": this.header.createdIds,//操作人
                "deptIds": this.header.deptIds,//部门
                "endTime": this.header.time && this.header.time.length > 0 ? this.header.time[1] : null,//结束时间
                "goodsCoding": this.header.goodsCoding,//商品编码
                "goodsId": this.header.goodsId,//商品原ID
                "goodsName": this.header.goodsName,//商品名称
                "orgIds": this.header.orgIds,//公司名称
                "outNo": this.header.outNo,//
                "pageNo": this.search.pageNo,//
                "pageSize": this.search.pageSize,//
                "startTime": this.header.time && this.header.time.length > 0 ? this.header.time[0] : null,//开始时间
                "warehouseIds": this.header.warehouseIds,//仓库
                "field":this.header.field,
                "sort":this.header.sort
            })
            try {
                this.tableData = res.body.datas
                this.search.pageTotal = res.body.sumDataCount?res.body.sumDataCount:0
                this.loading = false
            } catch (err) {

            }
        },
        async getCount() {

        },
        exportBtn() {

        },
        resetBtn() {
            this.header = JSON.parse(JSON.stringify(headerSource))
            this.initialize()
            this.countGet()
        },
        toQuery() {
            this.initialize()
            this.countGet()
        },
        // table样式
        headerClass() {
            return "background: #eef1f6; color: #606266;text-align:center"
        },
        rowClass() {
            return "text-align:center"
        },
        handleSizeChange(val) {
            this.search.pageSize = val
            this.initialize()
        },
        handleCurrentChange(val) {
            this.search.pageNo = val
            this.initialize()
        },
    }
}
</script>

<style lang='scss' scoped>
.searchBtn {
    margin-left: 20px;
    margin-bottom: 10px;
}
.topCount {
    width: 100%;
    padding: 0px 7px;
    display: flex;
    justify-content: space-around;
    /* justify-content: space-around; */
    box-shadow: 0px 0px 4px #888888;
    // border: 1px solid #000;
    min-height: 50px;
    align-items: center;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 600;
    flex-wrap: wrap;
    margin-bottom: 10px;
    border-radius: 10px;

    div {
        min-width: 250px;
    }

    span {
        color: blue;
        margin-left: 10px;
    }
}
</style>