export default (function (_ref) {
  var request = _ref.request;
  return {
    // 分页查询
    versionManagement_select: function versionManagement_select() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/versionManagement/getPageList',
        method: 'post',
        data: data
      });
    },
    versionManagement_save: function versionManagement_save() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/versionManagement/save',
        method: 'post',
        data: data
      });
    }
  };
});