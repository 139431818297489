var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.titleName,
            visible: _vm.visibleDialog,
            top: "8vh",
            width: "80%"
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleDialog = $event
            },
            close: _vm.dialogClose
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "InfoForm",
              staticClass: "demo-ruleForm",
              staticStyle: { "font-weight": "bold" },
              attrs: {
                model: _vm.redactData,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "盘点标题", prop: "checkName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: { clearable: "", size: "small" },
                        model: {
                          value: _vm.redactData.checkName,
                          callback: function($$v) {
                            _vm.$set(_vm.redactData, "checkName", $$v)
                          },
                          expression: "redactData.checkName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "盘点维度", prop: "dimension" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请选择盘点维度",
                            size: "small"
                          },
                          on: {
                            change: function($event) {
                              return _vm.changeDimensionality()
                            }
                          },
                          model: {
                            value: _vm.redactData.dimension,
                            callback: function($$v) {
                              _vm.$set(_vm.redactData, "dimension", $$v)
                            },
                            expression: "redactData.dimension"
                          }
                        },
                        [
                          _c("el-option", {
                            key: "0",
                            attrs: { label: "商品维度", value: 0 }
                          }),
                          _c("el-option", {
                            key: "1",
                            attrs: { label: "货架维度", value: 1 }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "盘点仓库", prop: "warehouseId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请选择盘点仓库",
                            size: "small"
                          },
                          model: {
                            value: _vm.redactData.warehouseId,
                            callback: function($$v) {
                              _vm.$set(_vm.redactData, "warehouseId", $$v)
                            },
                            expression: "redactData.warehouseId"
                          }
                        },
                        _vm._l(_vm.warehouseIdData, function(item) {
                          return _c("el-option", {
                            key: item.warehouseId,
                            attrs: {
                              label: item.warehouseName,
                              value: item.warehouseId
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "盘点类型", prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.redactData.type,
                            callback: function($$v) {
                              _vm.$set(_vm.redactData, "type", $$v)
                            },
                            expression: "redactData.type"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("录入盘库")
                          ]),
                          _c(
                            "el-radio",
                            { attrs: { disabled: true, label: 1 } },
                            [_vm._v("扫码盘库")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.titleName !== "新增盘库单"
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c("el-form-item", { attrs: { label: "盘点单号" } }, [
                        _c(
                          "div",
                          { staticStyle: { "font-weight": "normal" } },
                          [_vm._v(_vm._s(_vm.redactData.checkId))]
                        )
                      ]),
                      _c("el-form-item", { attrs: { label: "盘点状态" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.redactData.status === 0
                                ? "待盘库"
                                : _vm.redactData.status === 1
                                ? "盘库中"
                                : _vm.redactData.status === 3
                                ? "盘库完成"
                                : _vm.redactData.status === 9
                                ? "终止入库"
                                : ""
                            ) +
                            " "
                        )
                      ]),
                      _c("el-form-item", { attrs: { label: "制单人员" } }, [
                        _c(
                          "div",
                          { staticStyle: { "font-weight": "normal" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.redactData.createdName
                                    ? _vm.redactData.createdName
                                    : "- -"
                                ) +
                                " "
                            )
                          ]
                        )
                      ]),
                      _c("el-form-item", { attrs: { label: "创建时间" } }, [
                        _c(
                          "div",
                          { staticStyle: { "font-weight": "normal" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.redactData.createdTimestamp
                                    ? _vm.redactData.createdTimestamp
                                    : "- -"
                                ) +
                                " "
                            )
                          ]
                        )
                      ]),
                      _vm.redactData.status == 2
                        ? _c("el-form-item", { attrs: { label: "完成时间" } }, [
                            _c(
                              "div",
                              { staticStyle: { "font-weight": "normal" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.redactData.endTime
                                        ? _vm.redactData.endTime
                                        : "- -"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "盘点备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 3, maxRows: 5 },
                      clearable: "",
                      maxlength: "240",
                      "show-word-limit": "",
                      size: "small",
                      type: "textarea"
                    },
                    model: {
                      value: _vm.redactData.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.redactData, "remark", $$v)
                      },
                      expression: "redactData.remark"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    height: "40px",
                    "background-color": "#d7d7d7"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-weight": "bolder",
                        "margin-left": "20px",
                        "line-height": "40px",
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c("div", [
                        _c("span", { staticStyle: { color: "#f56c6c" } }, [
                          _vm._v("* ")
                        ]),
                        _vm._v(" 商品列表")
                      ]),
                      _c(
                        "div",
                        { staticStyle: { "margin-right": "20px" } },
                        [
                          _vm.redactData.dimension == "0" &&
                          _vm.redactData.warehouseId !== ""
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectGoods()
                                    }
                                  }
                                },
                                [_vm._v("选择商品 ")]
                              )
                            : _vm._e(),
                          _vm.redactData.dimension == "1" &&
                          _vm.redactData.warehouseId !== ""
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectArea()
                                    }
                                  }
                                },
                                [_vm._v("选择货架 ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "goodsDataSty" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "goodsData" } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.goodsDataLoading,
                              expression: "goodsDataLoading"
                            }
                          ],
                          ref: "goodsData",
                          staticStyle: { padding: "5px 5px 0" },
                          attrs: {
                            "cell-style": { textAlign: "center" },
                            data: _vm.redactData.goodsData,
                            "header-cell-style": {
                              background: "#dfe6ec",
                              color: "#666666",
                              textAlign: "center"
                            },
                            "row-style": { height: "57px" },
                            "element-loading-text": "数据加载中",
                            "max-height": "420px",
                            "tooltip-effect": "dark"
                          }
                        },
                        [
                          _c("el-table-column", {
                            key: "index",
                            attrs: {
                              align: "center",
                              label: "序号",
                              width: "80"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(" " + _vm._s(scope.$index + 1) + " ")
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            key: "id",
                            attrs: {
                              align: "center",
                              label: "商品编码",
                              prop: "id"
                            }
                          }),
                          _c("el-table-column", {
                            key: "goodsName",
                            attrs: {
                              align: "center",
                              label: "商品名称",
                              prop: "goodsName"
                            }
                          }),
                          _c("el-table-column", {
                            key: "pictureUrl",
                            attrs: {
                              align: "center",
                              label: "商品图片",
                              prop: "pictureUrl"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "40px",
                                        height: "40px"
                                      },
                                      attrs: {
                                        "preview-src-list": [
                                          _vm.downloadUrl +
                                            "/" +
                                            scope.row.pictureUrl
                                        ],
                                        src:
                                          _vm.downloadUrl +
                                          "/" +
                                          scope.row.pictureUrl
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            key: "intoPrices",
                            attrs: {
                              align: "center",
                              label: "入库价",
                              prop: "intoPrices"
                            }
                          }),
                          _vm.redactData.dimension == "1" &&
                          _vm.titleName !== "录入盘库单"
                            ? _c("el-table-column", {
                                key: "areaName",
                                attrs: {
                                  align: "center",
                                  prop: "areaName",
                                  label: "区域"
                                }
                              })
                            : _vm._e(),
                          _vm.redactData.dimension == "1" &&
                          _vm.titleName !== "录入盘库单"
                            ? _c("el-table-column", {
                                key: "shelvesName",
                                attrs: {
                                  align: "center",
                                  prop: "shelvesName",
                                  label: "货架"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.shelvesName
                                                  ? scope.row.shelvesName
                                                  : "- -"
                                              ) +
                                              " "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  4243770520
                                )
                              })
                            : _vm._e(),
                          _vm.redactData.dimension == "1" &&
                          _vm.titleName !== "录入盘库单"
                            ? _c("el-table-column", {
                                key: "shelvesLayers",
                                attrs: {
                                  align: "center",
                                  prop: "shelvesLayers",
                                  label: "层数"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.shelvesLayers
                                                  ? scope.row.shelvesLayers
                                                  : "- -"
                                              ) +
                                              " "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1230281944
                                )
                              })
                            : _vm._e(),
                          _vm.titleName !== "新增盘库单" &&
                          _vm.redactData.status != 0
                            ? _c("el-table-column", {
                                key: "sumAll",
                                attrs: {
                                  align: "center",
                                  label: "在库数量",
                                  prop: "sumAll"
                                }
                              })
                            : _vm._e(),
                          _vm.redactData.status === 1
                            ? _c("el-table-column", {
                                key: "checkNum",
                                attrs: {
                                  align: "center",
                                  label: "实际库存",
                                  prop: "checkNum"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "goodsData." +
                                                  scope.$index +
                                                  ".checkNum",
                                                rules: _vm.rules.checkNum
                                              }
                                            },
                                            [
                                              _c("el-input-number", {
                                                attrs: {
                                                  min: 0,
                                                  step: 1,
                                                  size: "small",
                                                  "step-strictly": ""
                                                },
                                                model: {
                                                  value: scope.row.checkNum,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "checkNum",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.checkNum"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1134082686
                                )
                              })
                            : _vm._e(),
                          _vm.redactData.status === 1
                            ? _c("el-table-column", {
                                attrs: { align: "center", label: "盘点盈亏" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          scope.row.checkNum != null
                                            ? _c(
                                                "el-tag",
                                                {
                                                  style: {
                                                    color:
                                                      scope.row.checkNum * 1 -
                                                        scope.row.sumAll * 1 >
                                                      0
                                                        ? "#00cc66"
                                                        : scope.row.checkNum *
                                                            1 -
                                                            scope.row.sumAll *
                                                              1 <
                                                          0
                                                        ? "#cc3333"
                                                        : ""
                                                  },
                                                  attrs: {
                                                    type:
                                                      scope.row.checkNum * 1 -
                                                        scope.row.sumAll * 1 >
                                                      0
                                                        ? "success"
                                                        : scope.row.checkNum *
                                                            1 -
                                                            scope.row.sumAll *
                                                              1 <
                                                          0
                                                        ? "danger"
                                                        : ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row.checkNum * 1 -
                                                          scope.row.sumAll * 1 >
                                                          0
                                                          ? "+" +
                                                              (scope.row
                                                                .checkNum *
                                                                1 -
                                                                scope.row
                                                                  .sumAll *
                                                                  1)
                                                          : scope.row.checkNum *
                                                              1 -
                                                              scope.row.sumAll *
                                                                1
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : scope.row.checkNum == null
                                            ? _c("span", [_vm._v("- -")])
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1821673394
                                )
                              })
                            : _vm._e(),
                          _vm.redactData.status === 0
                            ? _c("el-table-column", {
                                attrs: { align: "center", label: "操作" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "danger"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteRow(
                                                    scope.$index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("删除")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3216903132
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-form-item", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "45%",
                          "margin-top": "15px"
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.cancel()
                              }
                            }
                          },
                          [_vm._v("取消")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "small",
                              type: "success",
                              disabled: _vm.saveLoading,
                              loading: _vm.saveLoading
                            },
                            on: {
                              click: function($event) {
                                return _vm.save()
                              }
                            }
                          },
                          [_vm._v("保存")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "small",
                              type: "primary",
                              disabled: _vm.submitLoading,
                              loading: _vm.submitLoading
                            },
                            on: {
                              click: function($event) {
                                return _vm.submit()
                              }
                            }
                          },
                          [_vm._v("提交")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("setStockGoodsList", {
        ref: "setStockGoodsList",
        on: { setGoods: _vm.addGoods }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "区域选择",
            visible: _vm.visibleDialogArea,
            top: "25vh",
            width: "20%"
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleDialogArea = $event
            },
            close: _vm.dialogAreaClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "AreaForm",
              staticClass: "demo-ruleForm",
              staticStyle: { "font-weight": "bold" },
              attrs: {
                model: _vm.areaData,
                rules: _vm.area,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "盘点仓库", prop: "warehouseId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        disabled: true,
                        placeholder: "请选择盘点仓库",
                        size: "small"
                      },
                      model: {
                        value: _vm.areaData.warehouseId,
                        callback: function($$v) {
                          _vm.$set(_vm.areaData, "warehouseId", $$v)
                        },
                        expression: "areaData.warehouseId"
                      }
                    },
                    _vm._l(_vm.warehouseIdData, function(item) {
                      return _c("el-option", {
                        key: item.warehouseId,
                        attrs: {
                          label: item.warehouseName,
                          value: item.warehouseId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "盘点区域", prop: "areaId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        clearable: "",
                        placeholder: "请选择盘点区域",
                        size: "small"
                      },
                      on: { change: _vm.changeAreaId },
                      nativeOn: {
                        click: function($event) {
                          return _vm.getArea(_vm.redactData.warehouseId)
                        }
                      },
                      model: {
                        value: _vm.areaData.areaId,
                        callback: function($$v) {
                          _vm.$set(_vm.areaData, "areaId", $$v)
                        },
                        expression: "areaData.areaId"
                      }
                    },
                    _vm._l(_vm.areaIdData, function(item) {
                      return _c("el-option", {
                        key: item.areaId,
                        attrs: { label: item.areaName, value: item.areaId }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "盘点货架", prop: "shelvesId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        clearable: "",
                        placeholder: "请选择盘点货架",
                        size: "small"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.getShelves(_vm.areaData.areaId)
                        }
                      },
                      model: {
                        value: _vm.areaData.shelvesId,
                        callback: function($$v) {
                          _vm.$set(_vm.areaData, "shelvesId", $$v)
                        },
                        expression: "areaData.shelvesId"
                      }
                    },
                    _vm._l(_vm.shelvesIdData, function(item) {
                      return _c("el-option", {
                        key: item.shelvesId,
                        attrs: {
                          label: item.shelvesName,
                          value: item.shelvesId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c("el-form-item", [
                _c(
                  "div",
                  {
                    staticStyle: { "margin-left": "40%", "margin-top": "15px" }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.abolish()
                          }
                        }
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          type: "success",
                          disabled: _vm.loadingChooseArea,
                          loading: _vm.loadingChooseArea
                        },
                        on: {
                          click: function($event) {
                            return _vm.determine()
                          }
                        }
                      },
                      [_vm._v("确定")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }