<template>
  <d2-container>
    <el-form ref="fromlist" :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="数据库地址">
        <el-input size="small" clearable  v-model="formInline.mysqlUrl" placeholder="localhost:3306"></el-input>
      </el-form-item>
      <el-form-item label="数据库账号">
        <el-input size="small" clearable v-model="formInline.userName" placeholder="username"></el-input>
      </el-form-item>
      <el-form-item label="数据库密码">
        <el-input size="small" clearable v-model="formInline.passWord" placeholder="password"></el-input>
      </el-form-item>
      <el-form-item label="数据库名称">
        <el-input size="small" clearable v-model="formInline.dbName" placeholder="dataname"></el-input>
      </el-form-item>
      <el-form-item label="数据库驱动">
        <el-input size="small" clearable v-model="formInline.mysqlDev" placeholder="com.mysql.cj.jdbc.Driver"></el-input>
      </el-form-item>
      <el-form-item>
          <el-button size="small" @click="getTableList" type="success">连接</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="tableName"
        label="表名称">
      </el-table-column>
      <el-table-column
        prop="tableComment"
        label="表注释">
      </el-table-column>
      <el-table-column
        prop="engine"
        label="储存引擎">
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        width="100"
        label="操作">
        <template slot-scope="scope">
          <el-button
            plain
            type="primary"
            size="small"
            @click="generate(scope.$index, scope.row)" icon="el-icon-setting">生成</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Generate ref="generate" :dialogVisible="dialogVisible" @close="generateClose"></Generate>
  </d2-container>
</template>

<script>
import api from '@/api'
import Generate from './generate'
export default {
  name: 'GenerateTable',
  components: {
    Generate
  },
  data () {
    return {
      initform: {
        rootPkg: '1',
        feignServiceName: '1',
        prefix: '1',
        tableName: '1'
      },
      dialogVisible: false,
      tableData: [],
      formInline: {
        mysqlUrl: '192.168.10.60:3306',
        userName: 'lptnyy',
        passWord: 'wangyang119',
        dbName: 'unbox_admin',
        mysqlDev: 'com.mysql.cj.jdbc.Driver',
        tableName: ''
      }
    }
  },
  methods: {
    generateClose () {
      this.dialogVisible = false
    },
    generate (index, data) {
      this.dialogVisible = true
      this.formInline.tableName = data.tableName
      this.formInline.tableComment = data.tableComment
      this.$refs.generate.initTableData(this.formInline)
    },
    async getTableList () {
      localStorage.setItem('generateConfig', JSON.stringify(this.formInline))
      this.formInline.tableName = ''
      var data = await api.GET_TABLE_LIST(this.formInline)
      this.tableData = data.body
    }
  },
  created () {
    var config = localStorage.getItem('generateConfig')
    if (config !== null) {
      this.formInline = JSON.parse(config)
    }
  }
}
</script>
<style>
</style>
