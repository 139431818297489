export default (function (_ref) {
  var request = _ref.request;
  return {
    // 分页查询
    classification_getPageList: function classification_getPageList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/classify/getPageList',
        method: 'post',
        data: data
      });
    },
    // 新增
    classification_add: function classification_add() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/classify/save',
        method: 'post',
        data: data
      });
    },
    // 查询子类
    classification_add_subclass: function classification_add_subclass() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/classify/getListByFatherId',
        method: 'post',
        data: data
      });
    },
    // 删除
    classification_delete: function classification_delete() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/classify/delete',
        method: 'DELETE',
        data: data
      });
    },
    classification_update: function classification_update() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/classify/update',
        method: 'PUT',
        data: data
      });
    } //   // 编辑
    //   updateRow (data = {}) {
    //     return request({
    //       url: '/v1/brand/updateRow',
    //       method: 'put',
    //       data: data
    //     })
    //   },
    //   // 删除
    //   deleteRow (data = {}) {
    //     return request({
    //       url: '/v1/brand/deleteRow',
    //       method: 'delete',
    //       data: data
    //     })
    //   }

  };
});