<template>
  <div>
    <div class="dashboard-editor-container">
      <div class="singSty">
        <div class="verticalBar"></div>
        <span class="textSty">商品排行榜(前50)</span>
      </div>
      <div class="whSty">
        <el-table
          ref="table"
          v-loading="loadingCommodityCard"
          :header-cell-style="headerCellStyle"
          element-loading-text="数据加载中"
          :data="tableData"
          max-height="390px"
          :cell-style="{ textAlign: 'center' }"
          :row-style="{ height: '57px', textAlign: 'center' }"
          style="width: 100%;margin-left: 10px;margin:10px 10px 0px 10px"
          tooltip-effect="dark"
        >
          <el-table-column
            label="序号"
            type="index"
            align="center"
            width="80px"
          >
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            label="商品名称"
            prop="goodsName"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
              >
                {{ scope.row.goodsName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="在途数量" prop="ingCount" width="100" align="center">
          </el-table-column>
          <el-table-column label="在库数量" prop="invCount" width="100" align="center">
          </el-table-column>
          <!-- <el-table-column label="在库货损数量" prop="damageCount" width="120" align="center">
          </el-table-column> -->
          <el-table-column label="累计采购数量" prop="purchaseCount" width="120" align="center">
          </el-table-column>
          <el-table-column label="累计入库数量" prop="intoCount" width="120" align="center">
          </el-table-column>
          <el-table-column label="累计出库数量" prop="outCount" width="120" align="center">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api'
export default {
  name: 'commodityCard',
  data () {
    return {
      tableData: [],
      loadingCommodityCard: false,
      headerCellStyle: {
        color: '#666666',
        background: '#dfe6ec'
      }
    }
  },
  created () {
    this.initList()
  },
  methods: {
    async initList () {
      this.loadingCommodityCard = true
      const res = await api.getProductStatistics()
      if (res.code === 200) {
        this.tableData = res.body
        this.loadingCommodityCard = false
      } else {
        this.loadingCommodityCard = false
        this.$message.error('页面加载失败')
      }
    }
  }
}
</script>
<style scoped>
.dashboard-editor-container {
  margin-top: 20px;
  padding: 20px;
  margin-left: 20px;
  background-color: #ffffff;
  padding: 20px 20px 0px 0px;
  box-shadow: 0px 0px 4px #999999;
  border-radius: 5px;
}
.singSty {
  display: flex;
  height: 20px;
  padding-left: 10px;
}
.verticalBar {
  width: 10px;
  line-height: 20px;
  border-radius: 25%;
  background-color: #8ddece;
  margin-right: 5px;
}
.textSty {
  line-height: 20px;
  font-weight: bold;
}
.whSty {
  width: 100%;
  height: 410px;
}
</style>
