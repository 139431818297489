/**
 * <菜单管理请求接口
 * @author 王振宇
 * @since 2021-03-04
 */
export default ({ service, request, serviceForMock, requestForMock, mock, faker, tools }) => ({
  GET_MENU_PAGE_LIST (data = {}) {
    return request({
      url: '/v1/menu/getPageList',
      method: 'get',
      params: data
    })
  },
  GET_USER_MENU_LIST (data = {}) {
    return request({
      url: '/v1/menu/getUserMenuList',
      method: 'get',
      params: data
    })
  },
  GET_MENU_LIST (data = {}) {
    return request({
      url: '/v1/menu/getList',
      method: 'get',
      params: data
    })
  },
  GET_MENU_INFO () {
    return request({
      url: '/v1/menu/info',
      method: 'get'
    })
  },
  SAVE_MENU (data = {}) {
    return request({
      url: '/v1/menu/save',
      method: 'post',
      data: data
    })
  },
  UPDATE_MENU (data = {}) {
    return request({
      url: '/v1/menu/update',
      method: 'put',
      data: data
    })
  },
  DELETE_MENU (data = {}) {
    return request({
      url: '/v1/menu/delete',
      method: 'delete',
      data: data
    })
  },
  DELETE_BATCH_MENU (data = {}) {
    return request({
      url: '/v1/menu/deleteBatch',
      method: 'delete',
      data: data
    })
  }
})
