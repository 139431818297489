<template>
  <!-- 如果内容比较多可以添加top属性 -->
  <d2-container>
    <el-form :inline="true" :model="selectForm">
      <!-- <el-form-item>
     <el-input :disabled="isSelect" v-model="selectForm.name" @keyup.enter.native="search()" placeholder="角色名称"></el-input>
  </el-form-item> -->
      <!-- <el-form-item>
        <el-button :disabled="isSelect" @click="search" icon="el-icon-search">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button :disabled="isSelect" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item> -->
      <el-form-item>
        <el-button :disabled="isCreate" icon="el-icon-circle-plus-outline" size="small" type="success" @click="toAdd">
          新增
        </el-button>
      </el-form-item>
      <!-- <el-form-item>
        <el-button :disabled="isDelete" type="danger" @click="deleteBatch" icon="el-icon-delete">删除</el-button>
      </el-form-item> -->
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange">
      <el-table-column
        label="角色名称"
        prop="name">
      </el-table-column>
      <el-table-column
        label="创建时间"
        prop="createdTimestamp">
      </el-table-column>
      <el-table-column
        label="创建人"
        prop="createdName">
      </el-table-column>
      <el-table-column
        label="app登录"
        prop="appFlag">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.appFlag == 1" type="success">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="备注"
        prop="remark">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="260">
        <template slot-scope="scope">
          <el-button
            :disabled="isSelect"
            icon="el-icon-info"
            plain
            size="small"
            type="info" @click="info(scope.$index, scope.row)">详情
          </el-button>
          <el-button
            :disabled="isUpdate"
            icon="el-icon-edit-outline"
            plain
            size="small"
            type="primary" @click="edit(scope.$index, scope.row)">编辑
          </el-button>
          <!-- <el-button
            plain
            size="mini"
            type="danger"
            :disabled="isDelete"
            @click="dalete(scope.$index, scope.row)" icon="el-icon-delete">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-div">
      <el-pagination
        :current-page="this.pageNo"
        :page-size="this.pageSize"
        :disabled="isSelect"
        :page-sizes="[10,20,30,40,50,100]"
        :total="pageTotal"
        layout="jumper, prev, pager, next,sizes,total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <RoleEdit ref="edit" :dialogVisible="dialogVisible" :disabled="editDisabled" @close="userEditClose"
              @initTableData="initTableData"></RoleEdit>
  </d2-container>
</template>

<script>
import api from '@/api'
import RoleEdit from '../edit/index'

export default {
  name: 'RoleList',
  components: {
    RoleEdit
  },
  data () {
    return {
      isSelect: this.checkauthority('role_list'),
      isCreate: this.checkauthority('role_add'),
      isUpdate: this.checkauthority('edit_role'),
      isDelete: this.checkauthority('delete_role'),
      selectForm: {
        name: ''
      },
      editDisabled: false,
      dialogVisible: false,
      pageNo: 1,
      pageSize: 50,
      tableSelects: [],
      tableData: [],
      pageTotal: 0,
      centerDialogVisible: false
    }
  },
  methods: {
    switchChange (value) {
      this.updateStats(value)
    },
    checkStatsType (data) {
      if (data.stats === 0) {
        return 'success'
      } else {
        return 'danger'
      }
    },
    checkStats (data) {
      if (data.stats === 0) {
        return '正常'
      } else {
        return '已注销'
      }
    },
    btnCheckStats (data) {
      if (data.stats === 0) {
        return '注销'
      } else {
        return '恢复'
      }
    },
    async updateStats (data) {
      var par = {
        id: data.id,
        stats: data.stats
      }
      await api.UPDATE_ROLE(par)
      this.initTableData()
    },
    dalete (index, data) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.daleteApi(index, data)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async daleteApi (index, data) {
      var par = {
        id: data.id
      }
      await api.DELETE_ROLE(par)
      this.initTableData()
    },
    async deleteBatchApi (par) {
      await api.DELETE_BATCH_ROLE(par)
      this.$message.success('操作成功')
      this.initTableData()
    },
    deleteBatch () {
      if (this.tableSelects.length === 0) {
        this.$message.error('请勾选要删除的表格数据')
        return
      }
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var par = []
        this.tableSelects.forEach(element => {
          par.push(element.id)
        })
        this.deleteBatchApi(par)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    info (index, data) {
      this.editDisabled = true
      this.dialogVisible = true
      this.$refs.edit.setTitle('详情')
      this.$refs.edit.setData(data)
    },
    edit (index, data) {
      this.dialogVisible = true
      this.editDisabled = false
      this.$refs.edit.setTitle('编辑')
      this.$refs.edit.setData(data)
    },
    toAdd () {
      this.$refs.edit.setTitle('新增')
      this.dialogVisible = true
      this.editDisabled = false
      this.$refs.edit.initData()
    },
    userEditClose () {
      this.dialogVisible = false
    },
    reset () {
      this.selectForm.name = ''
      this.initTableData()
    },
    search () {
      this.pageNo = 1
      this.initTableData()
    },
    async initTableData () {
      var par = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        name: this.selectForm.name
      }
      const res = await api.GET_ROLE_PAGE_LIST(par)
      this.tableData = res.body.datas
      this.pageTotal = res.body.sumDataCount
      this.pageNo = res.body.pageNo
      this.pageSize = res.body.pageSize
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.initTableData()
    },
    handleCurrentChange (val) {
      this.pageNo = val
      this.initTableData()
    },
    handleSelectionChange (val) {
      this.pageSize = val
      this.initTableData()
    },
  },
  created () {
    if (this.isSelect) {
      return
    }
    this.initTableData()
  }
}
</script>
<style>
.editBtn {
  width: 60px;
}

.statsTag {
  width: 60px;
  text-align: center;
}

.demo .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

/*打开时文字位置设置*/
.demo .el-switch__label--right {
  z-index: 1;
  right: -4px;
}

/*关闭时文字位置设置*/
.demo .el-switch__label--left {
  z-index: 1;
  left: 18px;
}

/*显示文字*/
.demo .el-switch__label.is-active {
  display: block;
}

.demo.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}
</style>
