var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "updateVersion" },
    [
      _c(
        "el-button",
        {
          attrs: {
            size: "small",
            type: "primary",
            disabled: _vm.isAdd,
            icon: "el-icon-plus"
          },
          on: { click: _vm.add }
        },
        [_vm._v("新增")]
      ),
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.tableData } },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.text) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "versionText", label: "版本号" }
          }),
          _c("el-table-column", {
            attrs: { prop: "createdTimestamp", label: "更新时间" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pager-div" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": this.page.pageNo,
              disabled: _vm.isSelect,
              "page-sizes": [10, 20, 30, 40, 50, 100],
              layout: "jumper, prev, pager, next,sizes",
              total: _vm.pageTotal
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增版本",
            "append-to-body": true,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false
          },
          on: {
            close: _vm.close,
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "addForm", attrs: { rules: _vm.rules, model: _vm.addForm } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "版本号", prop: "versionText" } },
                [
                  _c("el-input", {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.addForm.versionText,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "versionText", $$v)
                      },
                      expression: "addForm.versionText"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "更新内容", prop: "text" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", size: "small" },
                    model: {
                      value: _vm.addForm.text,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "text", $$v)
                      },
                      expression: "addForm.text"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submit }
                },
                [_vm._v("提 交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }