<template>
  <div class="goodsDetails">
    <div class="title">
      <span>库存明细</span>
      <el-button
        type="primary"
        size="small"
        @click="openHistory"
        icon="el-icon-s-operation"
      >历史记录</el-button
      >
    </div>
    <div class="top">
      <div class="info">
        <head-search :label-style="labelStyle" :item-style="itemStyle">
          <head-search-item label="商品编码：">{{
            goodsInfo.goodsCoding
          }}</head-search-item>
          <head-search-item label="商品名称：">{{
            goodsInfo.goodsName
          }}</head-search-item>
          <head-search-item label="商品分类：">{{
            goodsInfo.goodsOne ?  goodsInfo.goodsOne : '- -'
          }}</head-search-item>
          <!-- <head-search-item label="二级分类：">{{
            goodsInfo.goodsTwo ?  goodsInfo.goodsTwo : '- -'
          }}</head-search-item>
          <head-search-item label="三级分类：">{{
            goodsInfo.goodsThree ? goodsInfo.goodsThree : '- -'
          }}</head-search-item> -->
          <head-search-item label="商品品牌：">{{
            goodsInfo.goodsBrandName
          }}</head-search-item>
          <head-search-item label="商品IP：">{{
            goodsInfo.goodsIpName
          }}</head-search-item>
          <head-search-item label="在库数量：">{{
            goodsInfo.inventoryNum
          }}</head-search-item>
          <head-search-item label="在途数量：">{{
            goodsInfo.inTransitNum
          }}</head-search-item>
          <head-search-item label="在库总额：">{{
            goodsInfo.inventoryTotalPrice
          }}</head-search-item>
          <head-search-item label="在途总额：">{{
            goodsInfo.inTransitTotalPrice
          }}</head-search-item>
          <head-search-item label="在库平均价：">{{
            goodsInfo.inventoryAveragePrice
          }}</head-search-item>
        </head-search>
      </div>
      <div class="img">
        <div class="text">商品图片:</div>
        <div class="image">
          <el-image
            style="width: 200px;height: 200px"
            v-if="goodsInfo.pictureUrl"
            :src="$oss + goodsInfo.pictureUrl"
            fit="fill"
          >
          </el-image>
        </div>
      </div>
    </div>
    <div class="tableContent">
      <div class="title">
        <span>在库详情</span>
        <el-button
          type="primary"
          size="small"
          @click="exportExcel"
          icon="el-icon-download"
          :disabled="loading"
          :loading="loading"
          v-if="!permission.isExport"
          >导出</el-button
        >
      </div>
      <div class="table">
        <el-table :data="goodsTable">
          <el-table-column align="center" label="序号">
            <template slot-scope="scope">
              {{ (page.pageNo - 1) * page.pageSize + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="orgName" label="公司名称" align="center">
          </el-table-column>
          <el-table-column prop="warehouseName" label="仓库名称" align="center">
          </el-table-column>
          <!-- <el-table-column prop="areaName" label="所在区域" align="center">
          </el-table-column>
          <el-table-column prop="shelvesName" label="所在货架" align="center">
          </el-table-column> -->
          <el-table-column
            align="center"
            label="入库价（元）"
            prop="unitPrice"
          ></el-table-column>
          <el-table-column
            align="center"
            label="在库数量"
            prop="inventoryNums"
          ></el-table-column>
          <!-- <el-table-column
            align="center"
            label="在途数量"
            prop="inTransitNum"
          ></el-table-column> -->
          <el-table-column
            align="center"
            label="小计"
            prop="inventoryTotalPrice"
          ></el-table-column>
          <el-table-column
            align="center"
            width="180"
            label="最近一次入库时间"
            prop="createdTimestamp"
          ></el-table-column>
          <el-table-column
            align="center"
            width="180"
            label="最近一次出库时间"
            prop="outTimestamp"
          ></el-table-column>
          <!-- <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                @click="detailsLog(scope.row)"
                type="info"
                size="small"
                icon="el-icon-info"
                >详情</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
        <el-pagination
          @current-change="currentChange"
          @size-change="sizeChange"
          :page-sizes="[10,20,30,40,50,100]"
          :page-size="page.pageSize"
          layout="->,total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
          style="margin-top: 10px"
        >
        </el-pagination>
      </div>
      <div class="details">
        <el-dialog title="商品详情" :visible.sync="dialogVisible" width="90%">
          <el-table :data="goodsDetailsTable" :row-class-name="damagefreight">
            <el-table-column label="商品条码" prop="barCode"></el-table-column>
            <!-- <el-table-column label="是否货损" prop="status">
              <template slot-scope="scope">
                {{
                  scope.row.status === 0
                    ? "正常"
                    : scope.row.status === 1
                    ? "损坏"
                    : "丢失"
                }}
              </template>
            </el-table-column> -->
            <el-table-column
              label="采购单号"
              prop="purchaseNo"
            ></el-table-column>
            <el-table-column label="入库单号" prop="intoNo"></el-table-column>
            <el-table-column
              label="入库时间"
              prop="createdTimestamp"
            ></el-table-column>
            <el-table-column
              label="扫码人"
              prop="createdName"
            ></el-table-column>
          </el-table>
          <el-pagination
            @current-change="detailsCurrentChange"
            @size-change="detailsSizeChange"
            :current-page="detailsPage.pageNo"
            :page-sizes="[10,20,30,40,50,100]"
            :page-size="detailsPage.pageSize"
            layout="->,total, sizes, prev, pager, next, jumper"
            :total="detailsPageTotal"
          ></el-pagination>
        </el-dialog>
      </div>
    </div>

    <el-dialog
      title="历史记录"
      ref="dialogHistory"
      width="60%"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleHistory"
      @close="false">
      <el-table
        ref="tableData"
        :data="historyData"
        element-loading-text="数据加载中"
        :cell-style="{ textAlign: 'center' }"
        tooltip-effect="dark"
        max-height="490"
        v-loading="loadingHistrory"
        :row-style="{ height: '57px' }"
        :header-cell-style="{
            background: '#dfe6ec',
            color: '#666666',
            textAlign: 'center'
          }"
      >
        <el-table-column label="序号" width="80" align="center">
            <template slot-scope="scope">
              {{ (searchHistory.pageNo - 1) * searchHistory.pageSize + scope.$index + 1 }}
            </template>
        </el-table-column>
        <el-table-column label="公司名称" width="200" prop="companyName" align="center"></el-table-column>
        <el-table-column
          width="200"
          align="center"
          label="所属部门"
          prop="deptName"
        >
          <template slot-scope="scope">
            {{ scope.row.deptName ? scope.row.deptName : '- -' }}
          </template>
        </el-table-column>
        <el-table-column label="仓库名称" prop="warehouseName" width="150"></el-table-column>
        <el-table-column
          align="center"
          label="库存总数"
          prop="sum"
          width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.sum ? scope.row.sum : '- -' }}
          </template>
        </el-table-column>
        <el-table-column label="出库数量" prop="inventoryNums" width="150"></el-table-column>
        <el-table-column label="入库单价" prop="price" width="150">
          <template slot-scope="scope">
            {{ scope.row.price ? scope.row.price : '- -' }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchHistory.pageNo"
        :page-sizes="[10,20,30,40,50,100]"
        :page-size="searchHistory.pageSize"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="searchHistory.sumDataCount"
      ></el-pagination>
    </el-dialog>

  </div>
</template>

<script>
import api from '@/api'
import { downloadFileByUrl } from '@/utils/index'
export default {
  name: 'goodsDetails',
  props: {},
  data () {
    return {
      permission: {
        isExport: this.checkauthority('product_InLibrary_export') // 导出
      },
      loading: false,
      loadingHistrory: false,
      goodsDetailsTable: [],
      labelStyle: 'width:140px;text-align:left;font-weight:600;text-align:right;',
      itemStyle:
        'width:400px;display:flex;align-items:baseline;margin-bottom: 35px;margin-left: 20px;',
      dialogVisible: false,
      dialogVisibleHistory: false,
      goodsTable: [],
      historyData: [], // 历史记录数据
      goodsInfo: {},
      detaildsTable: [],
      GoodsDetails: {},
      detailsPage: {
        pageNo: 1,
        pageSize: 50
      },
      page: {
        pageNo: 1,
        pageSize: 50
      },
      searchHistory: {
        pageNo: 1,
        pageSize: 50,
        sumDataCount: 0
      },
      detailsPageTotal: 0,
      pageTotal: 0
    }
  },
  created () {
    this.init()
  },
  watch: {
    $route (to, from) {
      this.$route.query.id = to.query.id
      this.init()
    }
  },
  methods: {
    // 搜索分页
    handleSizeChange (val) {
      this.searchHistory.pageSize = val
    },
    // 搜索跳转页数
    handleCurrentChange (val) {
      this.searchHistory.pageNo = val
    },
    init () {
      this.getGoodsInfo()
      this.getInfo()
    },
    damagefreight ({ row, rowIndex }) {
      if (row.status === 1) {
        return 'damage'
      } else if (row.status === 2) {
        return 'loss'
      }
    },
    async getGoodsInfo () {
      const { body } = await api.product_inLibraryDetails(
        Object.assign(this.page, {
          goodsCoding: this.$route.query.id
        })
      )
      this.goodsTable = body.datas
      this.pageTotal = body.sumDataCount
    },
    async getInfo () {
      const { body } = await api.product_baseInfo({
        goodsCoding: this.$route.query.id
      })
      this.goodsInfo = body
    },
    sizeChange (val) {
      this.page.pageSize = val
      this.getGoodsInfo()
    },
    currentChange (val) {
      this.page.pageNo = val
      this.getGoodsInfo()
    },
    async getGoodsDetails () {
      const { body: data } = await api.product_inLibraryInfo(
        Object.assign(this.GoodsDetails, this.detailsPage)
      )
      this.goodsDetailsTable = data.datas
      this.detailsPageTotal = data.sumDataCount
    },
    async detailsLog (row) {
      this.GoodsDetails = row
      this.getGoodsDetails()
      this.dialogVisible = true
    },
    detailsCurrentChange (val) {
      this.detailsPage.pageNo = val
      this.getGoodsDetails()
    },
    detailsSizeChange (val) {
      this.detailsPage.pageSize = val
      this.getGoodsDetails()
    },
    async exportExcel () {
      try {
        this.loading = true
        const { body } = await api.product_inLibraryExport({
          goodsCoding: this.$route.query.id
        })
        downloadFileByUrl(body)
      } finally {
        this.loading = false
      }
    },
    openHistory () {
      this.dialogVisibleHistory = true
      this.getHistory()
    },
    async getHistory () {
      this.loadingHistrory = true
      try {
        const res = await api.product_getGoodsCodingRecord({
          goodsCoding: this.$route.query.id,
          pageNo: 1,
          pageSize: 50
        })
        console.log(res)
        this.historyData = res.body.datas
        this.searchHistory.pageSize = res.body.pageSize
        this.searchHistory.pageNo = res.body.pageNo
        this.searchHistory.sumDataCount = res.body.sumDataCount
      } catch (e) {
        this.$message.error('获取历史记录失败')
      } finally {
        this.loadingHistrory = false
      }
    },
    handleClose () {
      this.dialogVisibleHistory = false
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #b8b8b81e;
  height: 30px;
  font-weight: bold;
}

.info {
  padding: 10px;
}

.el-image {
  width: 230px;
  height: 230px;
}

.text {
  font-weight: bold;
  padding: 5px;
  font-size: 14px;
  width: 80px;
}

.img {
  display: flex;
  padding-top: 10px;
  padding-right: 50px;
}

.top {
  display: flex;
}

.tableContent {
  position: relative;
  top: -40px;
}

::v-deep .content {
  font-size: 14px;
  width: 500px;
  margin-left: 10px;
}

::v-deep .damage {
  background: #efdede;
}

::v-deep .loss {
  background: #f5f7b2;
}
</style>
