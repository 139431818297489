export default ({ request }) => ({
  // 每日代发
  generationGetPage (data = {}) {
    return request({
      url: '/v1/issued/getLimit',
      method: 'post',
      data: data
    })
  },
  // 每日代发聚合统计
  generationGetAgg (data = {}) {
    return request({
      url: '/v1/issued/getAgg',
      method: 'post',
      data: data
    })
  },
  // 每日代发 - 商品
  generationGoodsLimit (data = {}) {
    return request({
      url: '/v1/issued/goodsLimit',
      method: 'post',
      data: data
    })
  },
  // 每日代发 - 商品聚合统计
  generationAggGoods (data = {}) {
    return request({
      url: '/v1/issued/aggGoods',
      method: 'post',
      data: data
    })
  },
  // 商品导出
  generationExportGoods (data = {}) {
    return request({
      url: '/v1/issued/exprotGoods',
      method: 'post',
      data: data
    })
  },
  // 每日代发 - 订单
  generationOrderLimit (data = {}) {
    return request({
      url: '/v1/issued/orderLimit',
      method: 'post',
      data: data
    })
  },
  // 每日代发 - 订单聚合统计
  generationAggOrder (data = {}) {
    return request({
      url: '/v1/issued/aggOrder',
      method: 'post',
      data: data
    })
  },
  // 订单导出
  generationExportOrder (data = {}) {
    return request({
      url: '/v1/issued/exportOrder',
      method: 'post',
      data: data
    })
  },
})
