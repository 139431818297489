var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basic" },
    [
      _vm._m(0),
      _c(
        "head-search",
        {
          attrs: { "label-style": _vm.labelStyle, "item-style": _vm.itemStyle }
        },
        [
          _c("head-search-item", { attrs: { label: "入库单号:" } }, [
            _vm._v(" " + _vm._s(_vm.info.enterWarehouseNumber) + " ")
          ]),
          Array.isArray(_vm.info.childNumbers) &&
          _vm.info.childNumbers.length > 0
            ? _c(
                "head-search-item",
                { attrs: { label: "子入库单号:" } },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "right",
                        width: "100",
                        trigger: "click"
                      }
                    },
                    [
                      _vm._l(_vm.info.childNumbers, function(item, index) {
                        return _c(
                          "el-button",
                          {
                            key: index,
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.toDetail(item)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(item) + " ")]
                        )
                      }),
                      _c("br"),
                      _c(
                        "el-button",
                        {
                          staticClass: "reference",
                          attrs: {
                            slot: "reference",
                            type: "text",
                            size: "small"
                          },
                          slot: "reference"
                        },
                        [_vm._v(" 查看 ")]
                      )
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.info.fatherId
            ? _c(
                "head-search-item",
                { attrs: { label: "主入库单号:" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "text",
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.toDetail(_vm.info.fatherId)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.info.fatherId) + " ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("head-search-item", { attrs: { label: "入库类型:" } }, [
            _vm._v(" " + _vm._s(_vm.info.enterWarehouseType) + " ")
          ]),
          _vm.info.enterWarehouseType === "其他入库"
            ? _c("head-search-item", { attrs: { label: "入库渠道:" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.info.channel
                        ? _vm.info.channel
                        : _vm.info.enterWarehouseSource
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          !(
            (_vm.info.enterWarehouseState == 3 ||
              _vm.info.enterWarehouseState == 2) &&
            _vm.info.enterWarehouseState == "代发"
          )
            ? _c("head-search-item", { attrs: { label: "入库仓库:" } }, [
                _vm._v(" " + _vm._s(_vm.info.warehouseName) + " ")
              ])
            : _vm._e(),
          _vm.info.enterWarehouseState === 0 &&
          _vm.info.enterWarehouseType === "调拨"
            ? _c("head-search-item", { attrs: { label: "仓库位置:" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.info.sameWarehouseFlag === 1
                        ? "与入库仓库同一地点"
                        : "不同地点（需扫码）"
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          _vm.info.enterWarehouseType === "调拨"
            ? _c("head-search-item", { attrs: { label: "调出仓库:" } }, [
                _vm._v(" " + _vm._s(_vm.info.sourceWarehouseName) + " ")
              ])
            : _vm._e(),
          _c("head-search-item", { attrs: { label: "入库金额:" } }, [
            _vm._v(" " + _vm._s(_vm.info.enterWarehouseAggregate) + " ")
          ]),
          _c("head-search-item", { attrs: { label: "入库状态:" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.info.isStop
                    ? "已终止"
                    : _vm.info.enterWarehouseState === 0
                    ? "待入库"
                    : _vm.info.enterWarehouseState === 1
                    ? "入库中"
                    : _vm.info.enterWarehouseState === 2
                    ? "部分入库"
                    : _vm.info.enterWarehouseState === 3
                    ? "完成入库"
                    : _vm.info.enterWarehouseState === 9
                    ? "已终止"
                    : ""
                ) +
                " "
            )
          ]),
          _vm.info.enterWarehouseType !== "其他" &&
          _vm.info.enterWarehouseType !== "盘盈"
            ? _c("head-search-item", { attrs: { label: "采购单号:" } }, [
                _vm._v(" " + _vm._s(_vm.info.purchaseId) + " ")
              ])
            : _vm._e(),
          _vm.info.enterWarehouseType !== "其他" &&
          _vm.info.enterWarehouseType !== "盘盈"
            ? _c("head-search-item", { attrs: { label: "采购类型:" } }, [
                _vm._v(" " + _vm._s(_vm.info.purchaseType) + " ")
              ])
            : _vm._e(),
          _vm.info.enterWarehouseType !== "调拨" &&
          _vm.info.enterWarehouseType !== "盘盈"
            ? _c(
                "head-search-item",
                {
                  attrs: {
                    label:
                      _vm.info.enterWarehouseType === "其他"
                        ? "入库来源："
                        : "采购渠道:"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.info.channel
                          ? _vm.info.channel
                          : _vm.info.enterWarehouseSource
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.info.enterWarehouseType !== "调拨" &&
          _vm.info.enterWarehouseState !== "代发" &&
          _vm.info.enterWarehouseState !== "其他"
            ? _c("head-search-item", { attrs: { label: "发货方式:" } }, [
                _vm._v(" " + _vm._s(_vm.info.deliveryMethod) + " ")
              ])
            : _vm._e(),
          _c("head-search-item", { attrs: { label: "付款方式:" } }, [
            _vm._v(" " + _vm._s(_vm.payWay(_vm.info.payWay)) + " ")
          ]),
          _vm.info.enterWarehouseType !== "调拨" &&
          _vm.info.enterWarehouseType !== "盘盈"
            ? [
                _c("head-search-item", { attrs: { label: "物流公司:" } }, [
                  _vm._v(" " + _vm._s(_vm.info.logisticsCompany) + " ")
                ]),
                _c(
                  "head-search-item",
                  { attrs: { label: "物流单号:" } },
                  [
                    _vm._v(" " + _vm._s(_vm.info.logisticsNumbers) + " "),
                    _vm.info.logisticsNumbers
                      ? _c(
                          "el-popover",
                          {
                            staticStyle: { height: "500px" },
                            attrs: {
                              width: "500",
                              trigger: "click",
                              title: "物流详情"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.popLoading,
                                    expression: "popLoading"
                                  }
                                ],
                                staticClass: "pop"
                              },
                              [
                                _vm.logisticsInfo.length > 0
                                  ? _c(
                                      "ul",
                                      {
                                        staticStyle: { "margin-right": "20px" }
                                      },
                                      _vm._l(_vm.logisticsInfo, function(
                                        item,
                                        index
                                      ) {
                                        return _c("li", { key: item.time }, [
                                          _c("div", { staticClass: "time" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  index === 0 ? "最新" : ""
                                                ) +
                                                _vm._s(item.time) +
                                                " "
                                            )
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "content" },
                                            [_vm._v(_vm._s(item.context))]
                                          )
                                        ])
                                      }),
                                      0
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "logistics",
                                attrs: {
                                  slot: "reference",
                                  size: "mini",
                                  type: "primary"
                                },
                                on: { click: _vm.logisticsNumbersInfo },
                                slot: "reference"
                              },
                              [_vm._v(" 查看物流 ")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c("head-search-item", { attrs: { label: "制单人员:" } }, [
            _vm._v(" " + _vm._s(_vm.info.createdName) + " ")
          ]),
          _c("head-search-item", { attrs: { label: "创建时间:" } }, [
            _vm._v(" " + _vm._s(_vm.info.createdTimestamp) + " ")
          ]),
          _vm.info.enterWarehouseState === 2 ||
          _vm.info.enterWarehouseState === 3
            ? _c("head-search-item", { attrs: { label: "入库时间:" } }, [
                _vm._v(" " + _vm._s(_vm.info.enterWarehouseTimestamp) + " ")
              ])
            : _vm._e()
        ],
        2
      ),
      _c("div", { staticClass: "remark" }, [
        _c("span", { staticClass: "label" }, [_vm._v("备注：")]),
        _c("span", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.info.remark))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("基本信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }