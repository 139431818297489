<template>
  <d2-container class="loaginLog">
    <el-form :inline="true" :model="selectForm">
      <el-form-item>
        <el-input clearable size="small" :disabled="isSelect" v-model="selectForm.ip" @keyup.enter.native="initList"
          placeholder="IP地址"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input clearable size="small" :disabled="isSelect" v-model="selectForm.createdName"
          @keyup.enter.native="initList" placeholder="登录用户"></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker :disabled="isSelect" value-format="yyyy-MM-dd HH:mm:ss" size="small" v-model="selectForm.time"
          type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" :disabled="isSelect" @click="initList" icon="el-icon-search">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="warning" :disabled="isSelect" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%" v-loading="loading">
      <el-table-column prop="createdName" label="登录用户">
      </el-table-column>
      <el-table-column prop="createdTimestamp" label="登录时间">
      </el-table-column>
      <el-table-column prop="type" label="登录类型">
        <template slot-scope="scope">
          {{ scope.row.type }}
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-div">
      <el-pagination :current-page="this.page.pageNo" :disabled="isSelect" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :page-sizes="[10, 20, 30, 40, 50, 100]"
        layout="jumper, prev, pager, next,sizes" :total="pageTotal">
      </el-pagination>
    </div>
  </d2-container>
</template>

<script>
import api from '@/api'
const searchData = {
  ip: null,
  createdName: '',
  startTime: '',
  endTime: '',
  time: []
}
export default {
  name: 'loaginLog',
  data () {
    return {
      page: {
        pageNo: 1,
        pageSize: 10
      },
      pageTotal: 0,
      selectForm: JSON.parse(JSON.stringify(searchData)),
      isSelect: this.checkauthority('login_select'),
      tableData: [],
      loading: false
    }
  },
  methods: {
    initList () {
      this.page.pageNo = 1
      this.getList()
    },
    async getList () {
      if (this.isSelect) {
        return
      }
      this.loading = true
      try {
        const { body: data } = await api.loginLog_select({
          ...this.page,
          ...this.selectForm,
          startTime: this.selectForm.time[0],
          endTime: this.selectForm.time[1]
        })
        this.pageTotal = data.sumDataCount
        this.tableData = data.datas
      } finally {
        this.loading = false
      }
    },
    reset () {
      this.selectForm = JSON.parse(JSON.stringify(searchData))
      this.time = []
      this.getList()
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.page.pageNo = val
      this.getList()
    }
  },
  created () {
    this.getList()
  }
}
</script>
