var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c(
      "div",
      { staticClass: "all" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("基本信息")]),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-wrap": "wrap",
              padding: "0 20px"
            }
          },
          [
            _c("div", { staticClass: "dataMain" }, [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("采购单号")
              ]),
              _vm._v("："),
              _c("span", [_vm._v(_vm._s(_vm.data.purchaseId))])
            ]),
            _c("div", { staticClass: "dataMain" }, [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("操作人")
              ]),
              _vm._v(" ："),
              _c("span", [_vm._v(_vm._s(_vm.data.updateName))])
            ]),
            _c("div", { staticClass: "dataMain" }, [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("操作时间")
              ]),
              _vm._v("："),
              _c("span", [_vm._v(_vm._s(_vm.data.updateTimestamp))])
            ]),
            _c("div", { staticClass: "dataMain" }, [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("仓库名称")
              ]),
              _vm._v("："),
              _c("span", [_vm._v(_vm._s(_vm.data.warehouseName))])
            ]),
            _c("div", { staticClass: "dataMain" }, [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("退货数量")
              ]),
              _vm._v("："),
              _c("span", [_vm._v(" " + _vm._s(_vm.data.sum))])
            ]),
            _c("div", { staticClass: "dataMain" }, [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("退货金额")
              ]),
              _vm._v("："),
              _c("span", [_vm._v(_vm._s(_vm.data.sumPrice))])
            ])
          ]
        ),
        _c("div", { staticClass: "title" }, [_vm._v("退款截图")]),
        _c("el-image", {
          staticStyle: {
            width: "100px",
            height: "100px",
            margin: "10px",
            cursor: "pointer"
          },
          attrs: {
            src: _vm.downloadUrl + "/" + _vm.data.refundProve,
            alt: "",
            "preview-src-list": [_vm.downloadUrl + "/" + _vm.data.refundProve]
          }
        }),
        _c("div", { staticClass: "title" }, [_vm._v("商品信息")]),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.data.resList,
              "header-cell-style": _vm.headerClass,
              "cell-style": _vm.rowClass
            }
          },
          [
            _c("el-table-column", {
              attrs: { prop: "goodsCoding", label: "商品编码" }
            }),
            _c("el-table-column", {
              attrs: { prop: "goodsName", label: "商品名称" }
            }),
            _c("el-table-column", {
              attrs: { prop: "idName", label: "商品分类" }
            }),
            _c("el-table-column", {
              attrs: { prop: "brandName", label: "商品品牌" }
            }),
            _c("el-table-column", {
              attrs: { prop: "ipName", label: "商品IP" }
            }),
            _c("el-table-column", {
              attrs: { prop: "pictureUrl", label: "商品图片" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-image", {
                        staticStyle: {
                          width: "50px",
                          height: "50px",
                          "margin-top": "8px",
                          cursor: "pointer"
                        },
                        attrs: {
                          src: _vm.downloadUrl + "/" + scope.row.pictureUrl,
                          alt: "",
                          "preview-src-list": [
                            _vm.downloadUrl + "/" + scope.row.pictureUrl
                          ]
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { prop: "enterWarehousePrices", label: "采购价格" }
            }),
            _c("el-table-column", {
              attrs: { prop: "sum", label: "退货数量" }
            }),
            _c("el-table-column", {
              attrs: { prop: "sumPrice", label: "总计" }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }