var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary", plain: "", round: "" },
          on: {
            click: function($event) {
              _vm.dialogVisible = true
            }
          }
        },
        [
          _c("d2-icon", {
            staticClass: "d2-mr-5",
            attrs: { name: "question-circle-o" }
          }),
          _vm._v(" 需要帮助吗 ")
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "帮助",
            width: "600px",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-top": "-25px" } },
            [
              _c("h2", { staticClass: "d2-mt-0" }, [
                _vm._v(" 这里有一些参考资料 ")
              ]),
              _c(
                "el-button-group",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$open("https://d2.pub/zh/doc/d2-admin")
                        }
                      }
                    },
                    [
                      _c("d2-icon", {
                        staticClass: "d2-mr-5",
                        attrs: { name: "book" }
                      }),
                      _vm._v(" 文档 ")
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$open(
                            "https://github.com/d2-projects/d2-admin/issues?q=is%3Aissue+is%3Aclosed"
                          )
                        }
                      }
                    },
                    [
                      _c("d2-icon", {
                        staticClass: "d2-mr-5",
                        attrs: { name: "question" }
                      }),
                      _vm._v(" 历史提问 ")
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$open(
                            "https://github.com/d2-projects/d2-admin/issues/new/choose"
                          )
                        }
                      }
                    },
                    [
                      _c("d2-icon", {
                        staticClass: "d2-mr-5",
                        attrs: { name: "plus" }
                      }),
                      _vm._v(" 提交问题 ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h2", [_vm._v("询问其它使用者或作者")]),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("img", {
                      staticStyle: { width: "100%" },
                      attrs: { src: require("./image/qq.svg") }
                    }),
                    _c("div", { staticClass: "d2-help--qr-info" }, [
                      _vm._v(" 请使用手机 QQ 扫面上方二维码"),
                      _c("br"),
                      _vm._v(" 1群 806395827 (满) | 2群 592981556 ")
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("img", {
                      staticStyle: { width: "100%" },
                      attrs: { src: require("./image/we.svg") }
                    }),
                    _c("div", { staticClass: "d2-help--qr-info" }, [
                      _vm._v(" 请使用手机微信扫面上方二维码"),
                      _c("br"),
                      _vm._v(" 添加作者微信好友，邀请加入微信群 ")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }