export default (function (_ref) {
  var request = _ref.request;
  return {
    // 统计报表 用户查询
    statisticalUser: function statisticalUser() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/user/likeUser',
        method: 'post',
        data: data
      });
    }
  };
});