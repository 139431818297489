<template>
  <div class="setGoodsList">
    <el-drawer
      title="请选择商品"
      ref="drawer"
      size="70%"
      :append-to-body="true"
      :visible.sync="drawerVisible"
      :before-close="handleClose"
    >
      <!--       搜索框         -->
      <div style="width:100%;">
        <div class="search">
          <div class="search_one">
            <div class="label">商品编码</div>
            <el-input
              class="filter-item"
              :disabled="permission.isSearch"
              v-model="search.goodsCoding"
              @keyup.enter.native="initList"
              placeholder="请输入商品编码"
              clearable
              size="small"
            />
          </div>
          <div class="search_one">
            <div class="label">商品名称</div>
            <el-input
              class="filter-item"
              :disabled="permission.isSearch"
              v-model="search.goodsName"
              placeholder="请输入商品"
              @keyup.enter.native="initList"
              clearable
              size="small"
            />
          </div>
          <div class="search_one">
            <div class="label">一级分类</div>
            <el-select
              class="filter-item"
              :disabled="permission.isSearch"
              v-model="search.goodsOne"
              placeholder="请选择一级分类"
              size="small"
              clearable
            >
              <el-option
                v-for="(item, index) in oneClassData"
                :key="index"
                :label="item.classifyName"
                :value="item.id"
                v-bind:title="onHoverText"
                @mouseenter.native="onMouseOver(item.classifyName)"
              ></el-option>
            </el-select>
          </div>

<!--          <div class="search_one">-->
<!--            <div class="label">二级分类</div>-->
<!--            <el-select-->
<!--              class="filter-item"-->
<!--              :disabled="permission.isSearch"-->
<!--              v-model="search.goodsTwo"-->
<!--              placeholder="请选择二级分类"-->
<!--              size="small"-->
<!--              clearable-->
<!--            >-->
<!--              <el-option-->
<!--                v-for="(item, index) in twoClassData"-->
<!--                :key="index"-->
<!--                :label="item.classifyName"-->
<!--                :value="item.id"-->
<!--                v-bind:title="onHoverText"-->
<!--                @mouseenter.native="onMouseOver(item.classifyName)"-->
<!--              ></el-option>-->
<!--            </el-select>-->
<!--          </div>-->
<!--          <div class="search_one">-->
<!--            <div class="label">三级分类</div>-->
<!--            <el-select-->
<!--              class="filter-item"-->
<!--              :disabled="permission.isSearch"-->
<!--              v-model="search.goodsThree"-->
<!--              placeholder="请选择三级分类"-->
<!--              size="small"-->
<!--              clearable-->
<!--            >-->
<!--              <el-option-->
<!--                v-for="(item, index) in threeClassData"-->
<!--                :key="index"-->
<!--                :label="item.classifyName"-->
<!--                :value="item.id"-->
<!--                v-bind:title="onHoverText"-->
<!--                @mouseenter.native="onMouseOver(item.classifyName)"-->
<!--              ></el-option>-->
<!--            </el-select>-->
<!--          </div>-->

          <div class="search_one">
            <div class="label">商品品牌</div>
            <el-select
              class="filter-item"
              :disabled="permission.isSearch"
              v-model="search.goodsBrand"
              placeholder="请选择商品品牌"
              size="small"
              clearable
            >
              <el-option
                v-for="(item, index) in goodsBrandData"
                :key="index"
                :label="item.brandName"
                :value="item.id"
                style="width:200px"
                v-bind:title="onHoverText"
                @mouseenter.native="onMouseOver(item.brandName)"
              ></el-option>
            </el-select>
          </div>

          <div class="search_one">
            <div class="label">商品IP</div>
            <el-select
              class="filter-item"
              :disabled="permission.isSearch"
              v-model="search.goodsIp"
              placeholder="请选择商品IP"
              size="small"
              clearable
            >
              <el-option
                v-for="(item, index) in goodsIpData"
                :key="index"
                :label="item.ipName"
                :value="item.id"
                style="width:200px"
                v-bind:title="onHoverText"
                @mouseenter.native="onMouseOver(item.ipName)"
              ></el-option>
            </el-select>
          </div>

          <div class="btn">
            <el-button
              icon="el-icon-search"
              type="primary"
              size="small"
              :disabled="permission.isSearch"
              @click="toQuery()"
            >
              搜索
            </el-button>
            <el-button
              icon="el-icon-refresh"
              type="warning"
              size="small"
              :disabled="permission.isReset"
              @click="reset()"
            >
              重置
            </el-button>
            <el-button icon="el-icon-circle-plus-outline" type="success" size="small" :disabled="permission.isReset" @click="addGoods()">添加</el-button>
          </div>
        </div>
      </div>

      <!--       TableData      -->
      <div style="margin-top: 10px; margin-left: 10px; margin-right: 10px;">
        <el-table
          ref="tableData"
          @selection-change="selectionChange"
          :data="tableData"
          element-loading-text="数据加载中"
          :cell-style="{ textAlign: 'center' }"
          tooltip-effect="dark"
          max-height="660"
          v-loading="loadingTotal"
          :row-style="{ height: '57px' }"
          :header-cell-style="{
            background: '#dfe6ec',
            color: '#666666',
            textAlign: 'center'
          }"
        >
          <el-table-column
            align="center"
            v-if="type ? type === 'checkButton' : false"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="checkSelect(scope.row)"
              >
                选择
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            type="selection"
            label="选择"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column label="序号" width="80" align="center">
            <template slot-scope="scope">
              {{ (search.pageNo - 1) * search.pageSize + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            label="商品编码"
            prop="goodsCoding"
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              <div
                style="display: flex;justify-content: space-between; width: 100%"
              >
                <div style="white-space: nowrap;width: 80%;">
                  {{ scope.row.goodsCoding }}
                </div>
                <div class="copySty" @click="copyUrl(scope.row.goodsCoding)">
                  复制
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center"  label="商品原ID" prop="goodsId">
          </el-table-column>
          <el-table-column
            label="商品名称"
            prop="goodsName"
            align="center"
            width="300"
          >
            <template slot-scope="scope">
              <div
                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
              >
                {{ scope.row.goodsName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="商品图片"
            prop="pictureUrl"
            align="center"
            width="100"
          >
            <template slot-scope="scope">
              <el-image
                :src="downloadUrl + '/' + scope.row.pictureUrl"
                alt=""
                :preview-src-list="[downloadUrl + '/' + scope.row.pictureUrl]"
                style="width: 50px; height: 50px;margin-top: 8px;cursor: pointer"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column
            label=" 一级分类"
            prop="goodsOne"
            align="center"
            width="120"
          >
            <template slot-scope="scope">
              <div
                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
              >
                {{ scope.row.goodsOne === null ? '- -' : scope.row.goodsOne }}
              </div>
            </template>
          </el-table-column>
<!--          <el-table-column-->
<!--            label="二级分类"-->
<!--            prop="goodsTwo"-->
<!--            align="center"-->
<!--            width="120"-->
<!--          >-->
<!--            <template slot-scope="scope">-->
<!--              <div-->
<!--                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"-->
<!--              >-->
<!--                {{ scope.row.goodsTwo === null ? '- -' : scope.row.goodsTwo }}-->
<!--              </div>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            label="三级分类"-->
<!--            prop="goodsThree"-->
<!--            align="center"-->
<!--            width="120"-->
<!--          >-->
<!--            <template slot-scope="scope">-->
<!--              <div-->
<!--                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"-->
<!--              >-->
<!--                {{-->
<!--                  scope.row.goodsThree === null ? '- -' : scope.row.goodsThree-->
<!--                }}-->
<!--              </div>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column
            label="商品品牌"
            prop="goodsBrandName"
            align="center"
            width="260"
          >
            <template slot-scope="scope">
              <div
                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
              >
                {{ scope.row.goodsBrandName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="商品IP"
            prop="goodsIpName"
            align="center"
            width="160"
          >
            <template slot-scope="scope">
              <div
                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
              >
                {{ scope.row.goodsIpName }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--       分页           -->
      <div
        style="display:flex;justify-content:right;margin-top: 10px;justify-content: space-between;"
      >
        <el-button
          size="small"
          type="primary"
          class="submit"
          @click="submit"
          v-if="type ? type !== 'checkButton' : false"
        >
          确认
        </el-button>
        <el-pagination
          :current-page="search.pageNo"
          @size-change="sizeChange"
          :page-size="search.pageSize"
          @current-change="currentChange"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="total, jumper, prev, pager, next,sizes"
          :total="search.pageTotal"
        ></el-pagination>
      </div>
      <add ref="addRef" @func="addSuccess()"></add>
    </el-drawer>
  </div>
</template>
<script>
import add from './add.vue'
// 搜索数据
import api from '@/api'
const searchDefault = {
  goodsCoding: '', // 商品编码
  goodsName: '', // 商品名称
  oneClass: '', // 一级分类
  twoClass: '', // 二级分类
  threeClass: '', // 三级分类
  goodsBrand: '', // 商品品牌
  goodsIp: '', // 商品Ip
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0
}
export default {
  components: { add },
  data () {
    return {
      radio: '',
      permission: {
        isSearch: this.checkauthority('goods_select') // 搜索
      },
      search: { ...searchDefault },
      tableData: [],
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL,
      dialogVisible: false,
      oneClassData: [],
      twoClassData: [],
      threeClassData: [],
      goodsBrandData: [],
      goodsIpData: [],
      onHoverText: '',
      drawerVisible: false,
      data: {},
      selection: []
    }
  },
  created () {
    this.initList()
  },
  props: {
    type: {
      type: String,
      default: 'checkbox'
    }
  },
  methods: {
    addSuccess(){
      this.toQuery()
    },
    addGoods(){
      this.$refs.addRef.open()
    },
    sizeChange (val) {
      this.search.pageSize = val
      this.initList()
    },
    currentChange (val) {
      this.search.pageNo = val
      this.initList()
    },
    onMouseOver (item) {
      this.onHoverText = item
    },
    open (data = {}) {
      this.data = data
      this.drawerVisible = true
    },
    async initList () {
      this.loadingTotal = true
      // 分类接口
      for (let i = 1; i < 4; i++) {
        const res = await api.getList_Classify({
          classifyLevel: i
        })
        if (res.code === 200) {
          if (i === 1) {
            this.oneClassData = res.body
          } else if (i === 2) {
            this.twoClassData = res.body
          } else if (i === 3) {
            this.threeClassData = res.body
          }
        } else {
          this.$message({
            type: 'danger',
            message: '分类菜单加载失败!'
          })
        }
      }
      // 品牌接口
      const _brand = await api.selectAll_brand({})
      if (_brand.code === 200) {
        this.goodsBrandData = _brand.body
      } else {
        this.$message({
          type: 'danger',
          message: '商品品牌加载失败!'
        })
      }
      // ip接口
      const _ip = await api.getListIp_ip({})
      if (_ip.code === 200) {
        this.goodsIpData = _ip.body
      } else {
        this.$message({
          type: 'danger',
          message: '商品品牌加载失败!'
        })
      }
      const _goods = await api.getPageList_goods({
        goodsCoding: this.search.goodsCoding,
        goodsName: this.search.goodsName,
        id1: this.search.goodsOne,
        id2: this.search.goodsTwo,
        id3: this.search.goodsThree,
        goodsBrand: this.search.goodsBrand,
        goodsIp: this.search.goodsIp,
        pageNo: this.search.pageNo,
        pageSize: this.search.pageSize
      })
      // console.log(_goods)
      if (_goods.code === 200) {
        // console.log(_goods.datas)
        this.tableData = _goods.body.datas
        this.search.pageTotal = _goods.body.sumDataCount
        this.loadingTotal = false
      } else {
        this.$message({
          type: 'danger',
          message: '页面加载失败!'
        })
        this.loadingTotal = false
      }
    },
    toQuery () {
      this.initList()
    },
    reset () {
      this.search = { ...searchDefault }
      this.initList()
    },
    handleClose () {
      this.$refs.tableData.clearSelection()
      this.drawerVisible = false
    },
    // /定义点击复制的功能
    copyUrl (url) {
      // 创建一个 Input标签
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      // 执行浏览器复制命令
      // / 复制命令会将当前选中的内容复制到剪切板中
      // / 如这里构建的 Input标签
      document.execCommand('Copy')
      this.$message({
        message: '复制成功',
        type: 'success'
      })
      // /复制成功后再将构造的标签 移除
      oInput.remove()
    },
    selectionChange (val) {
      this.selection = val
    },
    submit () {
      this.$emit('setGoods', this.selection, this.data)
      this.drawerVisible = false
      this.$refs.drawer.closeDrawer()
    },
    checkSelect (row) {
      this.$emit('checkSelect', row)
      this.drawerVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.search {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  position: relative;

  .search_one {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;

    .label {
      white-space: nowrap;
      font-size: 14px;
      margin-right: 10px;
      margin-left: 10px;
      width: 70px;
      text-align: center;
    }
  }
}

.btn {
  display: inline-block;
  position: absolute;
  right: 45px;
  bottom: 10px;
}

.el-select {
  width: 100%;
}

.copySty {
  font-size: 10px;
  cursor: pointer;
  color: #1482f0;
}

.submit {
  margin-left: 10px;
}
</style>
