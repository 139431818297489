<template>
  <div class="dialog">
    <el-dialog :close-on-click-modal="false" :title="titleName" :visible.sync="visibleDialog" top="8vh" width="80%"
      @close="dialogClose">
      <el-form ref="InfoForm" :model="redactData" :rules="rules" class="demo-ruleForm" label-width="100px"
        style="font-weight: bold">
        <div style="display: flex">
          <el-form-item label="调拨标题" prop="name">
            <el-input v-model="redactData.name" :disabled="titleName == '审核调拨单'" clearable size="small"
              style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item label="来源仓库" prop="outWarehouseId">
            <el-select v-model="redactData.outWarehouseId" :disabled="isEditable" class="searchInput" clearable filterable
              placeholder="请选择来源仓库" size="small" @change="changeOutWarehouse">
              <el-option v-for="item in warehouseOutData" :key="item.warehouseId" :label="item.warehouseName"
                :value="item.warehouseId" v-bind:title="onHoverText" @mouseenter.native="onMouseOver(item.warehouseName)">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="调入部门" prop="intoDeptId">
            <el-select v-model="redactData.intoDeptId" :disabled="isEditable" class="searchInput" clearable
              placeholder="请选择调入部门" size="small" style="margin-right:10px" @change="changeSet">
              <el-option v-for="item in intoDept" :key="item.orgId" :label="item.orgName" :value="item.orgId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="调入仓库" prop="intoWarehouseId">
            <el-select v-model="redactData.intoWarehouseId" :disabled="disabledWarehouse || isEditable"
              class="searchInput" clearable placeholder="请选择调入仓库" size="small" @click.native="getIntoWarehouse"
              style="margin-right:10px">
              <el-option v-for="item in intoWarehouse" :key="item.warehouseId" :label="item.warehouseName"
                :value="item.warehouseId">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="调拨备注" prop="remark">
          <el-input v-model="redactData.remark" :disabled="this.titleName == '审核调拨单'" :autosize="{ minRows: 3, maxRows: 5 }" clearable maxlength="240"
            show-word-limit size="small" type="textarea"></el-input>
        </el-form-item>
        <div style="width: 100%;height: 40px;background-color:#d7d7d7;">
          <div
            style="font-weight: bolder;margin-left: 20px;line-height: 40px;;display: flex;justify-content: space-between">
            <div><span style="color: #f56c6c">* &nbsp</span> 商品列表</div>
            <div style="margin-right: 20px">
              <el-button v-if="redactData.outWarehouseId && titleName != '审核调拨单'" size="mini" type="primary"
                @click="selectGoods()">选择商品
              </el-button>
              <el-button v-if="titleName == '审核调拨单'" size="mini" type="success" @click="selectShelves()">一键选择货架
              </el-button>
            </div>
          </div>
        </div>
        <div class="transferGoodsSty">
          <el-form-item prop="transferGoods">
            <el-table ref="transferGoods" v-loading="loadingGoods" :cell-style="{ textAlign: 'center' }"
              :data="redactData.transferGoods" :header-cell-style="{
                background: '#dfe6ec',
                color: '#666666',
                textAlign: 'center',
              }" :row-style="{ height: '57px' }" element-loading-text="数据加载中" max-height="430px"
              style="padding: 5px 5px 0;" tooltip-effect="dark" @selection-change="handleSelectionChange">
              <el-table-column type="selection" key="selection" width="55" v-if="titleName == '审核调拨单'">
              </el-table-column>
              <el-table-column align="center" label="序号" key="index" width="80">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column align="center" key="goodsCoding" label="商品编码" prop="goodsCoding" width="150">
              </el-table-column>
              <el-table-column align="center" key="goodsName"  label="商品名称" prop="goodsName" width="200">
              </el-table-column>
              <el-table-column align="center" key="pictureUrl"  label="商品图片" prop="pictureUrl" width="150">
                <template slot-scope="scope">
                  <el-image :preview-src-list="[downloadUrl + '/' + scope.row.pictureUrl]"
                    :src="downloadUrl + '/' + scope.row.pictureUrl" style="width: 40px; height: 40px;" />
                </template>
              </el-table-column>
              <el-table-column align="center" key="price" label="原调拨价格" width="190">
                <template slot-scope="scope">
                  <el-form-item :prop="'transferGoods.' + scope.$index + '.price'" :rules="rules.price">
                    <el-select v-model="scope.row.price" :disabled="titleName == '审核调拨单'" clearable placeholder="请选择调拨价格"
                      size="small" style="margin-right:10px;width: 140px;" @change="changeGoodsMaxNum(scope.row.goodsCoding,
                        scope.row.price,
                        scope.row.num,
                        scope.$index)" @click.native="getGoodsInPrice([scope.row.goodsCoding])">
                      <el-option v-for="item in goodsInPrice" :key="item.price" :label="item.price" :value="item.price">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" key="outPrice" label="新调拨价格" prop="outPrice" width="250">
                <template slot-scope="scope">
                  <el-input-number :disabled="titleName == '审核调拨单'" v-model="scope.row.outPrice" :min="0" :precision="2"
                    :step="10" size="small"></el-input-number>
                </template>
              </el-table-column>
              <el-table-column align="center" key="num" label="调拨数量" prop="num" width="250">
                <template slot-scope="scope">
                  <el-form-item :prop="'transferGoods.' + scope.$index + '.num'" :rules="rules.num">
                    <el-input-number :disabled="titleName == '审核调拨单'" v-model="scope.row.num" :max="scope.row.goodsMaxNum"
                      :min="1" :step="1" size="small" step-strictly @change="
                        goodsMaxNum(
                          scope.row.goodsCoding,
                          scope.row.price,
                          scope.row.num,
                          scope.$index
                        )
                      "></el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column v-if="titleName == '审核调拨单'" key="areaId"  align="center" label="入库区域" width="190">
                <template slot-scope="scope">
                  <el-form-item :prop="'transferGoods.' + scope.$index + '.areaId'" :rules="rules.areaId">
                    <el-select v-model="scope.row.areaId" clearable placeholder="请选择入库区域" size="small"
                      style="margin-right:10px;width: 140px;" @change="changeDisabledShelvesShelvesNum(
                        scope.row,
                        scope.$index)" @click.native="getArea(redactData.intoWarehouseId)">
                      <el-option v-for="item in areaData" :key="item.areaId" :label="item.areaName" :value="item.areaId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column v-if="titleName == '审核调拨单'" key="shelvesId" align="center" label="入库货架" width="190">
                <template slot-scope="scope">
                  <el-form-item :prop="'transferGoods.' + scope.$index + '.shelvesId'" :rules="rules.shelvesId">
                    <el-select v-model="scope.row.shelvesId" :disabled="scope.row.disabledShelves" clearable
                      placeholder="请选择入库货架" size="small" style="margin-right:10px;width: 140px;" @change="changeDisabledShelvesNum(
                        scope.row,
                        scope.$index)" @click.native="getShelves(scope.row.areaId)">
                      <el-option v-for="item in ShelvesData" :key="item.shelvesId" :label="item.shelvesName"
                        :value="item.shelvesId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column v-if="titleName == '审核调拨单'" key="shelvesNum" align="center" label="货架层数" width="190">
                <template slot-scope="scope">
                  <el-form-item :prop="'transferGoods.' + scope.$index + '.shelvesNum'" :rules="rules.shelvesNum">
                    <el-select ref="shelvesNumRef" v-model="scope.row.shelvesNum" :disabled="scope.row.disabledShelvesNum"
                      clearable placeholder="请选择货架层数" size="small" style="margin-right:10px;width: 140px;"
                      @change="logShelvesNum(scope.row)" @click.native="getShelvesNum(scope.row.shelvesId, scope.$index)">
                      <el-option v-for="(item, index) in ShelvesNumData" :key="index" :label="item.value"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column key="total"  align="center" label="总计">
                <template slot-scope="scope">
                  {{
                    scope.row.outPrice ? (scope.row.num ? (scope.row.outPrice * scope.row.num).toFixed(2) : '- -') : (scope.row.num ? (scope.row.price * scope.row.num).toFixed(2) : '- -')
                  }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" key="operate" v-if="titleName !== '审核调拨单'">
                <template slot-scope="scope">
                  <el-button size="small" type="danger" :disable="deleteLoading" :loading="deleteLoading" @click="deleteRow(scope.$index)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item>
            <div style="margin-left: 45%;margin-top: 15px">
              <el-button size="small" @click="cancel()">取消</el-button>
              <el-button v-if="this.titleName === '新增调拨单'" size="small" :disable="submitLoading" :loading="submitLoading"  type="success" @click="submitForm">提交
              </el-button>
              <el-button v-if="this.titleName === '编辑调拨单'" size="small" :disable="editLoading" :loading="editLoading" type="primary" @click="editForm">修改
              </el-button>
              <el-button v-if="this.titleName === '审核调拨单'" size="small" :disable="makeSureLoading" :loading="makeSureLoading" type="success" @click="makeSure">确认
              </el-button>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :title="'区域,货架,层数选择'" :visible.sync="visibleDialogArea" top="25vh"
      width="20%" @close="dialogAreaClose">
      <el-form ref="totalSelectionData" :model="totalSelectionData" :rules="area" class="demo-ruleForm"
        label-width="100px" style="font-weight: bold">
        <el-form-item label="入库区域" prop="areaId">
          <el-select v-model="totalSelectionData.areaId" clearable placeholder="请选择入库区域" size="small"
            style="width: 200px;" @change="changeDisabledTotalShelvesId(totalSelectionData.areaId)"
            @click.native="getArea(redactData.intoWarehouseId)">
            <el-option v-for="item in areaData" :key="item.areaId" :label="item.areaName" :value="item.areaId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库货架" prop="shelvesId">
          <el-select v-model="totalSelectionData.shelvesId" :disabled="disabledTotalSelectionShelves" clearable
            placeholder="请选择入库货架" size="small" style="width: 200px;"
            @change="changeDisabledTotalShelvesNum(totalSelectionData.shelvesId)"
            @click.native="getShelves(totalSelectionData.areaId)">
            <el-option v-for="item in ShelvesData" :key="item.shelvesId" :label="item.shelvesName"
              :value="item.shelvesId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="货架层数" prop="shelvesNum">
          <el-select v-model="totalSelectionData.shelvesNum" :disabled="disabledTotalSelectionShelvesNums" clearable
            placeholder="请选择货架层数" size="small" style="width: 200px;"
            @click.native="getShelvesNumForTotalSelection(totalSelectionData.shelvesId,)">
            <el-option v-for="(item, index) in ShelvesNumDataForTotalSelection" :key="index" :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>

          <div style="margin-left: 40%;margin-top: 15px">
            <el-button size="small" @click="abolish()">取消</el-button>
            <el-button size="small" type="success" @click="determine()">确定</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <setStockGoodsList ref="setStockGoodsList" @setGoods="addGoods"></setStockGoodsList>
  </div>
</template>
<script>

import api from '@/api'
import setStockGoodsList from '@/components/setStockGoodsList/index.vue'

const redactDataDefault = {
  name: '', // 调拨标题
  outWarehouseId: '', // 来源仓库
  intoDeptId: '', //  调入部门
  intoWarehouseId: '', // 调入仓库
  remark: '', // 备注
  transferGoods: [] // 调拨商品
}

const totalSelectionDataDefault = {
  areaId: '', // 区域id
  shelvesId: '', // 货架id
  shelvesNum: '' // 层数
}

export default {
  name: 'allotApplyDialog',
  components: {
    setStockGoodsList
  },
  data () {
    return {
      visibleDialog: false, // 弹窗开关
      visibleDialogArea: false, // 一键选择货架弹窗
      disabledTotalSelectionShelves: false, // 一键选择货架禁用货架
      disabledTotalSelectionShelvesNums: false, // 一键选择货架禁用货架层数
      loadingGoods: false, // 商品列表loading
      deleteLoading: false,
      submitLoading: false,
      editLoading: false,
      makeSureLoading: false,
      titleName: '', // 弹窗名称
      onHoverText: '',
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL, // downloadUrl
      redactData: JSON.parse(JSON.stringify(redactDataDefault)),
      warehouseOutData: [], // 用户下仓库Data
      intoDept: [], // 调入部门Data
      intoWarehouse: [], // 调入仓库Data
      selectionData: [], // 选择已勾选上调拨商品
      areaData: [], // 仓库区域Data
      ShelvesData: [], // 货架层数Data
      ShelvesNumData: [], // 货架层数Data
      ShelvesNumDataForTotalSelection: [], // 一键选择货架货架层数Data
      totalSelectionData: JSON.parse(JSON.stringify(totalSelectionDataDefault)), // 一键选择货架
      userInfo: {}, // 用户信息
      disabledWarehouse: false, // 禁用仓库flag
      goodsInPrice: [], // 商品对应价格
      isEditable: false, // 是否编辑
      managerFlag: false, // is总经办
      backToActiveName: '', // 关闭页面时 activeName
      rules: {
        name: [
          {
            required: true,
            message: '请输入调拨标题',
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: '请选择盘点类型',
            trigger: 'change'
          }
        ],
        outWarehouseId: [
          {
            required: true,
            message: '请选择调出仓库',
            trigger: 'change'
          }
        ],
        intoDeptId: [
          {
            required: true,
            message: '请选择调入部门',
            trigger: 'change'
          }
        ],
        intoWarehouseId: [
          {
            required: true,
            message: '请选择调入仓库',
            trigger: 'change'
          }
        ],
        transferGoods: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              this.validateTransferGoods(rule, value, callback, 'cover')
            }
          }
        ],
        price: [
          {
            required: true,
            message: '请选择调拨价格',
            trigger: 'change'
          }
        ],
        num: [
          {
            required: true,
            message: '请输入调拨数量',
            trigger: 'blur'
          }
        ],
        areaId: [
          {
            required: true,
            message: '请选择入库区域',
            trigger: 'change'
          }
        ],
        shelvesId: [
          {
            required: true,
            message: '请选择入库货架',
            trigger: 'change'
          }
        ],
        shelvesNum: [
          // {
          //   required: true,
          //   message: '请选择货架层数',
          //   trigger: 'change'
          // }
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              this.validateTransferGoodsShelvesNum(rule, value, callback, 'cover')
            }
          }
        ]
      },
      area: {
        areaId: [
          {
            required: true,
            message: '请选择入库区域',
            trigger: 'change'
          }
        ],
        shelvesId: [
          {
            required: true,
            message: '请选择入库货架',
            trigger: 'change'
          }
        ],
        shelvesNum: [
          // {
          //   required: true,
          //   message: '请选择货架层数',
          //   trigger: 'change'
          // }
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              this.validateTransferGoodsShelvesNum(rule, value, callback, 'cover')
            }
          }
        ]
      }
    }
  },
  methods: {
    // 校验是否添加商品
    validateTransferGoods (rule, value, callback) {
      if (this.redactData.transferGoods.length === 0) {
        callback(new Error('请添加商品'))
      } else {
        callback()
      }
    },
    // 校验货架层数
    validateTransferGoodsShelvesNum (rule, value, callback) {
      if (!value) {
        callback(new Error('请选择货架层数'))
      } else {
        callback()
      }
    },
    // 获取来源仓库
    async getPurchaseSourceWarehouseIds () {
      const res = await api.getPurchaseSourceWarehouseIds()
      this.warehouseOutData = res.body
    },
    // 获得公司
    async getCompany () {
      // super账号
      this.disabledWarehouse = true
      if (this.userInfo.orgId === 0 && this.userInfo.deptId === 0) {
        this.redactData.intoWarehouseId = ''
        this.redactData.outWarehouseId = ''
      } else if (this.userInfo.managerFlag) {
        // 总经办
        await this.getPart()
        await this.getWarehouse()
      } else {
        // 普通用户直接赋值
        this.redactData.intoWarehouseId = ''
        await this.getPart()
        await this.getUserWarehouse()
        await this.getWarehouse()
      }
    },
    // 获得部门列表
    async getPart () {
      const res = await api.getDeptList({ orgPid: this.userInfo.orgId })
      this.intoDept = res.body.filter(item => item.orgManagerFlag !== 1)
    },
    // 获得调入仓库
    async getWarehouse () {
      const res = await api.getDepartmentToWarehouse({
        deptId: this.redactData.intoDeptId
      })
      this.intoWarehouse = res.body.filter(item => item.warehouseId != this.redactData.outWarehouseId)
    },
    // 获得用户下部门仓库
    async getUserWarehouse () {
      const res = await api.getDepartmentToWarehouse({
        deptId: this.userInfo.deptId
      })
      this.warehouseOutData = res.body
    },
    // 获取调拨商品列表
    async getTransferGoods (id) {
      this.loadingGoods = true
      try {
        const res = await api.get_transferGoods({
          id: id
        })
        this.redactData.transferGoods = res.body
        this.redactData.transferGoods.forEach(item => {
          item.outPrice = item.outPrice == null ? undefined : item.outPrice
          item.disabledShelves = !!((item.shelvesId === undefined || item.shelvesId === '' || item.shelvesId === null))
          item.disabledShelvesNum = !!((item.shelvesNum === undefined || item.shelvesNum === '' || item.shelvesNum === null))
        })
      } catch (e) {
        this.$message.error('取调拨商品列表失败')
      } finally {
        this.loadingGoods = false
      }
    },
    // 获得商品对应的入库价
    async getGoodsInPrice (arr) {
      const res = await api.inventory_selectPrice({
        goodsCodings: arr,
        warehouseId: this.redactData.outWarehouseId
      })
      let filterArr = []
      filterArr = this.redactData.transferGoods.filter(item => item.goodsCoding === arr[0])
      console.log(filterArr)
      filterArr.forEach(item => {
        if (item.price) {
          res.body[arr[0]] = res.body[arr[0]].filter(el => el.price !== item.price)
        }
      })
      if (res.body[arr[0]].length > 0) {
        this.goodsInPrice = res.body[arr[0]]
      } else if (res.body[arr[0]].length === 0) {
        this.goodsInPrice = []
        this.$message.error('暂无调拨价格可选择')
      }
    },
    // 获取仓库区域
    async getArea (id) {
      if (id !== null && id !== '' && id !== undefined) {
        try {
          const res = await api.departmentGetChooseArea({
            warehouseId: id
          })
          this.areaData = res.body
        } catch (e) {
          this.$message.warning('获取仓库区域列表失败')
        } finally {
        }
      }
    },
    // 获取仓库货架
    async getShelves (id) {
      if (id !== null && id !== '' && id !== undefined) {
        try {
          const res = await api.departmentGetChooseShelves({
            areaId: id
          })
          this.ShelvesData = res.body
        } catch (e) {
          this.$message.error('获取仓库货架列表失败')
        } finally {
        }
      }
    },
    // 获取货架层数
    async getShelvesNum (id, index) {
      this.ShelvesNumData = []
      if (id !== null && id !== '' && id !== undefined) {
        this.ShelvesData.forEach(item => {
          if (id === item.shelvesId) {
            this.redactData.transferGoods[index].shelvesMaxNum = item.shelvesNum
            for (let i = 1; i < item.shelvesNum + 1; i++) {
              this.ShelvesNumData.push({
                key: i,
                value: i
              })
            }
          }
        })
      } else if (this.redactData.transferGoods[index].shelvesMaxNum) {
        for (let i = 1; i < this.redactData.transferGoods[index].shelvesMaxNum + 1; i++) {
          this.ShelvesNumData.push({
            key: i,
            value: i
          })
        }
      }
    },
    // 一键选择货架并获取层数
    getShelvesNumForTotalSelection (id) {
      this.ShelvesNumDataForTotalSelection = []
      this.ShelvesData.forEach(item => {
        if (id === item.shelvesId) {
          for (let i = 1; i < item.shelvesNum + 1; i++) {
            this.ShelvesNumDataForTotalSelection.push({
              key: i,
              value: i
            })
          }
        }
      })
    },
    // // 获取区域名称
    // async returnAreaName (id, areaId, index) {
    //   let areaName = ''
    //   const res = await api.departmentGetChooseArea({
    //     warehouseId: id
    //   })
    //   res.body.forEach(item => {
    //     if (item.areaId === areaId) {
    //       areaName = item.areaName
    //     }
    //   })
    //   // this.redactData.transferGoods[index].areaName = areaName
    //   // console.log(this.redactData.transferGoods[index].areaName)
    //   return areaName
    // },

    // // 获取货架名称
    // async returnShelvesName (id, shelvesId,index) {
    //   let shelvesName = ''
    //   const res = await api.departmentGetChooseShelves({
    //     areaId: id
    //   })
    //   res.body.forEach(item => {
    //     if (item.shelvesId === shelvesId) {
    //       shelvesName = item.shelvesName
    //     }
    //   })
    //   // this.redactData.transferGoods[index].shelvesName = shelvesName
    //   // console.log(this.redactData.transferGoods[index].shelvesName)
    //   return shelvesName
    // },
    // change 区域
    async changeDisabledShelvesShelvesNum (row, index) {
      if (row.areaId === undefined || row.areaId === '' || row.areaId === null) {
        row.areaName = ''
        row.disabledShelves = true
        row.disabledShelvesNum = true
      } else {
        row.disabledShelves = false
        row.disabledShelvesNum = true
        // row.areaName = await this.returnAreaName(this.redactData.intoWarehouseId, row.areaId, index)
      }
      row.shelvesId = ''
      row.shelvesName = ''
      row.shelvesNum = ''
      // this.$refs.shelvesNumRef.handleClearClick(event) // 触发el的clearable 事件
      this.ShelvesData = []
      this.ShelvesNumData = []
      this.$forceUpdate()
    },
    // 一键选择货架
    selectShelves () {
      if (this.selectionData.length === 0) {
        this.$message.warning('请勾选要选择的商品')
      } else {
        this.visibleDialogArea = true
        setTimeout(() => this.$refs.totalSelectionData.clearValidate(), 10)
        this.disabledTotalSelectionShelves = true
        this.disabledTotalSelectionShelvesNums = true
      }
    },
    //  一键选择货架 改变选择区域
    changeDisabledTotalShelvesId (id) {
      if (id === '') {
        this.totalSelectionData.shelvesId = '' // 货架id
        this.totalSelectionData.shelvesNum = '' // 层数
        this.disabledTotalSelectionShelves = true
        this.disabledTotalSelectionShelvesNums = true
      } else if (id !== '') {
        this.totalSelectionData.shelvesId = '' // 货架id
        this.totalSelectionData.shelvesNum = '' // 层数
        this.disabledTotalSelectionShelves = false
        this.disabledTotalSelectionShelvesNums = true
      }
    },
    //  一键选择货架 改变选择货架
    changeDisabledTotalShelvesNum (id) {
      if (id === '') {
        this.totalSelectionData.shelvesNum = '' // 层数
        this.disabledTotalSelectionShelvesNums = true
      } else if (id !== '') {
        this.totalSelectionData.shelvesNum = '' // 层数
        this.disabledTotalSelectionShelvesNums = false
      }
    },
    // 一键选择货架 关闭
    dialogAreaClose () {
      this.abolish()
    },
    // 一键选择货架 取消
    abolish () {
      this.visibleDialogArea = false
      this.totalSelectionData = JSON.parse(JSON.stringify(totalSelectionDataDefault))
    },
    // 一键选择货架 确认
    determine () {
      this.$refs.totalSelectionData.validate(async (valid) => {
        if (valid) {
          // let goodsCodingArr = []
          this.selectionData.forEach(item => {
            this.redactData.transferGoods[item.index].areaId = this.totalSelectionData.areaId
            this.redactData.transferGoods[item.index].shelvesId = this.totalSelectionData.shelvesId
            this.redactData.transferGoods[item.index].shelvesNum = this.totalSelectionData.shelvesNum
            this.redactData.transferGoods[item.index].disabledShelves = false
            this.redactData.transferGoods[item.index].disabledShelvesNum = false
          })
          this.$message.success('一键选择完成')
          this.$refs.transferGoods.clearSelection()
          this.abolish()
        } else {
          this.$message.warning('请选择完整')
        }
      })
    },
    // change 货架
    async changeDisabledShelvesNum (row, index) {
      row.shelvesNum = ''
      // this.$refs.shelvesNumRef.handleClearClick(event)
      row.disabledShelvesNum = row.shelvesId === undefined || row.shelvesId === '' || row.shelvesId === null
      this.ShelvesNumData = []
      this.$forceUpdate()
      // row.shelvesName = await this.returnShelvesName(row.areaId, row.shelvesId, index)
    },
    logShelvesNum (e) {
      this.$forceUpdate()
    },
    // 鼠标以上显示完整名称
    onMouseOver (item) {
      this.onHoverText = item
    },
    // change仓库
    changeOutWarehouse () {
      this.redactData.intoWarehouseId = ''
      this.redactData.transferGoods = []
    },
    // change部门
    changeSet () {
      this.redactData.intoWarehouseId = ''
      this.intoWarehouse = []
      this.emptyDepartment()
      this.getWarehouse()
    },
    // 点击获取调入仓库
    async getIntoWarehouse () {
      const res = await api.getDepartmentToWarehouse({
        deptId: this.redactData.intoDeptId
      })
      this.intoWarehouse = res.body.filter(item => item.warehouseId != this.redactData.outWarehouseId)
    },
    // 禁用flag
    emptyDepartment () {
      if (this.redactData.intoDeptId === '') {
        this.redactData.intoWarehouseId = ''
        this.disabledWarehouse = true
      } else if (this.redactData.intoDeptId !== '') {
        this.disabledWarehouse = false
      }
    },
    // 改变商品最大数
    changeGoodsMaxNum (goodsCoding, price, num, index) {
      this.redactData.transferGoods[index].outPrice = undefined
      this.redactData.transferGoods[index].num = undefined
      this.redactData.transferGoods[index].goodsMaxNum = undefined
      this.goodsMaxNum(goodsCoding, price, num, index)
    },
    // 商品最大数
    async goodsMaxNum (goodsCoding, price, num, index) {
      const res = await api.inventory_selectPrice({
        goodsCodings: [goodsCoding],
        warehouseId: this.redactData.outWarehouseId
      })
      if (price >= 0) {
        res.body[goodsCoding].forEach(item => {
          if (item.price === price) {
            this.redactData.transferGoods[index].goodsMaxNum = item.inventoryNums
          }
        })
      } else {
        this.redactData.transferGoods[index].goodsMaxNum = undefined
      }
    },
    // 选择已勾选上调拨商品
    handleSelectionChange (val) {
      val.forEach((val, index) => {
        this.redactData.transferGoods.forEach((item, index) => {
          if (val.goodsCoding == item.goodsCoding && val.price == item.price) {
            val.index = index
          }
        })
      })
      this.selectionData = val
    },
    // 选择商品
    selectGoods () {
      this.$refs.setStockGoodsList.open(this.redactData.outWarehouseId)
    },
    // 添加商品
    addGoods (data) {
      this.$message.success('添加成功')
      data.forEach((item) => {
        const obj = {
          goodsCoding: item.id,
          goodsName: item.goodsName,
          pictureUrl: item.pictureUrl
          // price: item.price,
          // sumAll: item.sumAll
        }
        this.redactData.transferGoods.push(obj)
      })
    },
    // 选择价格
    changePrice (newValue, oldValue, index, row, price) {
      this.getGoodsInPrice(row.goodsCoding)
    },
    // 开启弹窗
    openDialog (e, row, isManager, activeName) {
      this.backToActiveName = activeName
      this.managerFlag = isManager
      this.titleName = e
      setTimeout(() => {
        this.$refs.InfoForm.clearValidate()
      }, 0)
      this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      this.getPurchaseSourceWarehouseIds()
      this.getCompany()
      if (row) {
        this.redactData.name = row.name
        this.redactData.outWarehouseId = row.outWarehouseId
        this.redactData.intoDeptId = row.intoDeptId
        this.redactData.intoWarehouseId = row.intoWarehouseId
        this.redactData.remark = row.remark
        this.redactData.id = row.id
        this.redactData.transferGoods = []
        this.isEditable = true
        this.getTransferGoods(row.id)
      } else {
        this.isEditable = false
      }
      this.visibleDialog = true
    },
    // 关闭弹窗
    dialogClose () {
      this.cancel()
      this.$emit('customEvent')
    },
    // 表单取消
    cancel () {
      this.visibleDialog = false
      this.redactData = JSON.parse(JSON.stringify(redactDataDefault))
      this.$emit('customEvent')
    },
    // 删除商品
    deleteRow (index) {
      if (this.redactData.transferGoods[index].id === undefined) {
        this.redactData.transferGoods.splice(index, 1)
      } else if (this.redactData.transferGoods[index].id) {
        this.$confirm('此操作将会在此订单中永久删除, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try {
            this.deleteLoading = true
            const res = await api.delete_allotApply({
              id: this.redactData.transferGoods[index].id
            })
            if (res.code === 200) {
              this.$message.success('删除商品成功')
              this.redactData.transferGoods.splice(index, 1)
            }
          } catch (e) {
            this.$message.error('删除商品失败')
          } finally {
            this.deleteLoading = false
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      }
    },
    // 新增保存
    submitForm () {
      this.$refs.InfoForm.validate(async (valid) => {
        if (valid) {
          try {
            this.submitLoading = true
            this.redactData.transferGoods.forEach(item => {
              delete item.goodsMaxNum
              delete item.goodsName
              delete item.pictureUrl
            })
            const res = await api.save_allotApply(this.redactData)
            if (res.code === 200) {
              this.$message.success('新增调拨单成功')
              this.visibleDialog = false
              this.$emit('customEvent')
            } else {
              this.$message.error('新增调拨单失败')
            }
          } catch (error) {
            this.$message.error('新增调拨单失败')
          } finally {
            this.submitLoading = false
          }
        } else {
          this.$message.error('请填写完必要信息')
        }
      })
    },
    // 编辑保存
    editForm () {
      this.$refs.InfoForm.validate(async (valid) => {
        if (valid) {
          try {
            this.editLoading = true
            this.loadingGoods = true
            const data = JSON.parse(JSON.stringify(this.redactData))
            data.transferGoods.forEach(item => {
              delete item.goodsMaxNum
              delete item.goodsName
              delete item.pictureUrl
              delete item.areaId
              delete item.areaName
              delete item.shelvesId
              delete item.shelvesName
              delete item.disabledShelves
              delete item.disabledShelvesNum
            })
            delete data.intoDeptId
            delete data.intoWarehouseId
            delete data.outWarehouseId
            const res = await api.update_allotApply(data)
            if (res.code === 200) {
              this.$message.success('编辑调拨单成功')
              this.visibleDialog = false
              this.$emit('customEvent')
            } else {
              this.$message.error('编辑调拨单失败')
            }
          } catch (error) {
            this.$message.error('编辑调拨单失败')
          } finally {
            this.loadingGoods = false
            this.editLoading = false
          }
        } else {
          this.$message.error('请填写完必要信息')
        }
      })
    },
    // 确认调拨单
    makeSure () {
      this.$refs.InfoForm.validate(async (valid) => {
        if (valid) {
          this.redactData.transferGoods.forEach((item, index) => {
            delete item.goodsMaxNum
            delete item.shelvesMaxNum
            delete item.areaName
            delete item.shelvesName
            delete item.goodsName
            delete item.pictureUrl
            delete item.disabledShelves
            delete item.disabledShelvesNum
            delete item.index
          })
          delete this.redactData.intoDeptId
          delete this.redactData.intoWarehouseId
          delete this.redactData.outWarehouseId
          const res = await api.makeSure_allotApply(this.redactData)
          if (res.code === 200) {
            this.$message.success('已确认调拨单')
            this.visibleDialog = false
            this.$emit('customEvent')
          } else {
            this.$message.error('确认调拨单失败')
          }
        } else {
          this.$message.error('请填写完必要信息')
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .transferGoodsSty .el-form-item__content {
  margin-left: 0 !important;
}
</style>
