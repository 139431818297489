<template>
    <div>
        <el-dialog :close-on-click-modal="false" :title="titleName" :visible.sync="visibleDialog" top="8vh" width="80%"
            @close="dialogClose">
            <el-form ref="InfoForm" :model="form" :rules="rules" style="display:flex;flex-wrap: wrap;" label-width="100px"
                v-loading="formLoading">
                <el-form-item style="width:25%" label="盘库标题" prop="checkName">
                    <el-input v-model="form.checkName" size="small" :disabled="titleName=='录入盘库单'||titleName=='修正盘库单'" placeholder="" style="max-width: 200px;"></el-input>
                </el-form-item>
                <el-form-item style="width:25%" label="盘库维度" prop="dimension">
                    <el-select v-model="form.dimension" :disabled="titleName=='录入盘库单'||titleName=='修正盘库单'||titleName=='编辑盘库单'" placeholder="请选择盘库维度" size="small" style="max-width: 200px;">
                        <el-option v-for="item in dimensionList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="width:25%" label="盘点仓库" prop="warehouseId">
                    <el-select v-model="form.warehouseId" :disabled="titleName=='录入盘库单'||titleName=='修正盘库单'" placeholder="请选择盘点仓库" size="small" style="max-width: 200px;">
                        <el-option v-for="item in warehouseList" :key="item.warehouseId" :label="item.warehouseName"
                            :value="item.warehouseId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item style="width:25%" label="盘点类型" prop="type">
                    <el-radio-group v-model="form.type" :disabled="titleName=='录入盘库单'||titleName=='修正盘库单'">
                      <el-radio :label="0">录入盘库</el-radio>
                      <el-radio :disabled="true" :label="1">扫码盘库</el-radio>
                    </el-radio-group>
                </el-form-item> -->
                <el-form-item v-if="titleName=='编辑盘库单'||titleName=='录入盘库单'||titleName=='修正盘库单'" style="width:20%" label="盘库单号">{{ form.checkId }}</el-form-item>
                <el-form-item v-if="titleName=='编辑盘库单'||titleName=='录入盘库单'||titleName=='修正盘库单'" style="width:20%" label="盘库人员">{{ form.createdName }}</el-form-item>
                <el-form-item v-if="titleName=='编辑盘库单'||titleName=='录入盘库单'" style="width:20%" label="盘库状态">{{ almighty( form.status,'盘库状态')}}</el-form-item>
                <el-form-item v-if="titleName=='编辑盘库单'||titleName=='录入盘库单'" style="width:20%" label="创建时间">{{ form.createdTimestamp }}</el-form-item>
                <el-form-item v-if="titleName=='编辑盘库单'||titleName=='录入盘库单'" style="width:20%" label="完成时间">{{ form.endTime }}</el-form-item>
                <el-form-item style="width:100%" label="盘库备注">
                    <el-input type="textarea" :disabled="titleName=='录入盘库单'||titleName=='修正盘库单'" v-model="form.remark" placeholder=""></el-input>
                </el-form-item>
                <div class="title">
                    <div><span style="color:red">*</span>商品列表</div>
                    <el-button size="small" type="success" v-if="form.warehouseId && form.dimension == 0 && (titleName=='编辑盘库单'||titleName=='新增盘库单')" @click="selectGoods()">选择商品</el-button>
                    <el-button size="small" type="success" v-if="form.warehouseId && form.dimension == 1 && (titleName=='编辑盘库单'||titleName=='新增盘库单')" @click="selectGoodsShelf()">选择货架</el-button>
                </div>
                <div class="goodsDataSty">
                    <el-table :data="form.tableData" :header-cell-style="headerClass" :cell-style="rowClass">
                        <el-table-column label="序号" fixed>
                            <template slot-scope="scope">
                                {{ scope.$index + 1 }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="goodsCoding" label="商品编码" fixed></el-table-column>
                        <el-table-column prop="goodsName" label="商品名称" fixed></el-table-column>
                        <el-table-column prop="pictureUrl" label="商品图片">
                            <template slot-scope="scope">
                              <el-image :preview-src-list="[downloadUrl + '/' + scope.row.pictureUrl]"
                                :src="downloadUrl + '/' + scope.row.pictureUrl"
                                style="width: 40px; height: 40px;"/>
                            </template>
                        </el-table-column>
                        <el-table-column prop="intoPrices" label="入库价"></el-table-column>
                        <el-table-column prop="goodsNum" label="在库数量" v-if="!(form.warehouseId && form.dimension == 1 && (titleName=='编辑盘库单'||titleName=='新增盘库单'))"></el-table-column>
                        <el-table-column prop="areaName" label="区域" v-if="form.warehouseId && form.dimension == 1 && (titleName=='编辑盘库单'||titleName=='新增盘库单')"></el-table-column>
                        <el-table-column prop="shelvesName" label="货架" v-if="form.warehouseId && form.dimension == 1 && (titleName=='编辑盘库单'||titleName=='新增盘库单')"></el-table-column>
                        <el-table-column prop="shelvesLayers" label="层数" v-if="form.warehouseId && form.dimension == 1 && (titleName=='编辑盘库单'||titleName=='新增盘库单')"></el-table-column>
                        <el-table-column v-if="titleName != '编辑盘库单' && titleName != '新增盘库单'" prop="checkNum" label="手动录入" width="200">
                            <template slot-scope="scope">
                              <el-form-item :prop="'tableData.' + scope.$index + '.checkNum'" :rules="rules.checkNum">
                                <el-input-number
                                :disabled="titleName=='修正盘库单'"
                                  v-model="scope.row.checkNum"
                                  :min="1"
                                  :step="1"
                                  size="small"
                                  step-strictly
                                ></el-input-number>
                              </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="titleName != '编辑盘库单' && titleName != '新增盘库单'" prop="" label="盘点盈亏">
                            <template slot-scope="scope">
                                <el-tag :type="almightyM(scope.row,'color')">{{almightyM(scope.row,'num')}}</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="title" v-if="titleName=='修正盘库单'">盘库结果</div>
                <el-form-item v-if="titleName=='修正盘库单'" style="width:25%" label="待盘点数量">{{ Dnum }}</el-form-item>
                <el-form-item v-if="titleName=='修正盘库单'" style="width:25%" label="已盘点数量">{{ Ynum }}</el-form-item>
                <el-form-item v-if="titleName=='修正盘库单'" style="width:25%" label="盘盈">{{ Pwin }}</el-form-item>
                <el-form-item v-if="titleName=='修正盘库单'" style="width:25%" label="盘亏">{{ Plose }}</el-form-item>
                <el-form-item v-if="titleName=='录入盘库单'||titleName=='修正盘库单'" prop="checkRemark" style="width:100%" label="盈亏原因备注">
                    <el-input type="textarea" :disabled="titleName=='修正盘库单'" v-model="form.checkRemark" placeholder=""></el-input>
                </el-form-item>
                <div class="title" v-if="titleName=='修正盘库单'">修正库存</div>
                <el-form-item v-if="titleName=='修正盘库单'" style="width:50%" label="入库单号">{{ form.intoId }}</el-form-item>
                <el-form-item v-if="titleName=='修正盘库单'" style="width:50%" label="出库单号">{{ form.outId }}</el-form-item>
            </el-form>
            <div style="max-width:200px;margin:0 auto;margin-top: 10px;" v-if="titleName=='修正盘库单'">
                <el-button size="small" @click="dialogClose()">取消</el-button>
                <el-button size="small" type="primary" :disabled="btnDisabled" @click="amendBtn()">修正</el-button>
            </div>
            <div style="max-width:250px;margin:0 auto;margin-top: 10px;" v-else>
                <el-button size="small" @click="dialogClose()">取消</el-button>
                <el-button size="small" type="success" @click="saveBtn()">保存</el-button>
                <el-button size="small" type="primary" :disabled="btnDisabled" @click="submitBtn()">提交</el-button>
            </div>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" title="货架选择" :visible.sync="selectGoodsShelfLog" top="8vh" width="40%"
            @close="logClose">
            <el-form ref="InfoForm" :model="form" :rules="rules" style="display:flex;flex-wrap: wrap;" label-width="100px"
                v-loading="formLoading">
                <el-form-item label="盘点仓库" >
                  <el-select v-model="logData.warehouseId" :disabled="true" placeholder="请选择盘点仓库" size="small" style="width: 200px;" >
                    <el-option v-for="item in warehouseIdList" :key="item.warehouseId" :label="item.warehouseName" :value="item.warehouseId" />
                  </el-select>
                </el-form-item>
                <el-form-item label="盘点区域">
                  <el-select v-model="logData.areaID"  placeholder="请选择盘点仓库" size="small" style="width: 200px;" @change="areaIDC()" >
                    <el-option v-for="item in areaIDList" :key="item.areaId" :label="item.areaName" :value="item.areaId" />
                  </el-select>
                </el-form-item>
                <el-form-item label="盘点货架">
                  <el-select v-model="logData.goodsShelfId"  placeholder="请选择盘点仓库" size="small" style="width: 200px;" >
                    <el-option v-for="item in goodsShelfIdList" :key="item.shelvesId" :label="item.shelvesName" :value="item.shelvesId" />
                  </el-select>
                </el-form-item>
            </el-form>
            <div style="max-width:200px;margin:0 auto;margin-top: 10px;">
                <el-button size="small" @click="logClose()">取消</el-button>
                <el-button size="small" type="primary" @click="logSaveBtn()">确定</el-button>
            </div>
        </el-dialog>
        <setStockGoodsList ref="setStockGoodsList" @setGoods="addGoods"></setStockGoodsList>
    </div>
</template>

<script>
const formSource = {
    checkName:null,
    dimension:[],
    warehouseId:[],
    checkId:null,
    createdName:null,
    status:null,
    createdName:null,
    endTime:null,
    remark:null,
    tableData:[],
    checkRemark:null,
    intoId:null,
    outId:null,
    type:0,
}
import api from '@/api'
import setStockGoodsList from '@/components/setStockGoodsList/index.vue'
import *as getListM from '@/components/getListM.js'
export default {
    components: { setStockGoodsList },
    data() {
        return {
            btnDisabled:false,
            logData:{
                warehouseId:null,
                areaID:null,
                goodsShelfId:null,
            },
            warehouseIdList:[],
            areaIDList:[],
            goodsShelfIdList:[],
            selectGoodsShelfLog:false,
            dataOBj:{},
            Dnum: null,
            Ynum: null,
            Pwin: null,
            Plose: null,
            downloadUrl: process.env.VUE_APP_DOWNLOAD_URL, // downloadUrl
            titleName: null,
            visibleDialog: false,
            formLoading: false,
            form: JSON.parse(JSON.stringify(formSource)),
            rules: {
                checkName: [
                    { required: true, message: '请输入盘点标题', trigger: 'blur' },
                ],
                dimension: [{ required: true, message: '请选择盘库维度', trigger: 'blur' }],
                warehouseId: [{ required: true, message: '请选择仓库', trigger: 'blur' }],
                checkNum: [
                    { required: true, message: '请输入手动入录', trigger: 'blur' },
                    { pattern: /^[1-9]\d*$/, message: '请输入正整数', trigger: 'blur' }
                ],
            },
            dimensionList: [
                { value: 0, label: '商品维度' },
                // { value: 1, label: '货架维度' },
            ],
            warehouseList: [],
        }
    },
    methods: {
        // 获得商品对应的入库价
        async getGoodsinPrice(arr) {
            const data = await api.inventory_selectPrice({
                goodsCodings: arr,
                warehouseId: this.dataOBj.warehouseId
            })
            return data
        },
        async logSaveBtn(){
            const res = await api.makeInventoryGetInventoryList({ areaId: this.logData.areaID, shelvesId: this.logData.goodsShelfId, warehouseId: this.logData.warehouseId })
            try {
                const goodsData = res.body.map(inventoryItem => ({
                    goodsCoding: inventoryItem.goodsCoding,
                    goodsName: inventoryItem.goodsName,
                    pictureUrl: inventoryItem.pictureUrl,
                    intoPrices: inventoryItem.intoPrices,
                    areaName: inventoryItem.areaName,
                    shelvesName: inventoryItem.shelvesName,
                    shelvesLayers: inventoryItem.shelvesLayers
                }))
                this.form.tableData = []
                this.form.tableData = goodsData
                this.logClose()
            } catch (err) {

            }
        },
        logClose(){
            this.selectGoodsShelfLog = false
            this.logData.warehouseId = null
            this.logData.areaID = null
            this.logData.goodsShelfId = null
        },
        async areaIDC(){
            this.goodsShelfIdList = await getListM.getShelves(this.logData.areaID)
        },
        async selectGoodsShelf(){
            this.warehouseIdList = await getListM.getWarehouse()
            this.selectGoodsShelfLog=true
            this.logData.warehouseId = this.form.warehouseId
            this.areaIDList = await getListM.getArea(this.form.warehouseId)
            
        },
        calculate(){
            if (this.form.tableData.length > 0) {
                let list = JSON.parse(JSON.stringify(this.form.tableData))
                for (let i = 0; i < list.length; i++) {
                    this.Dnum += list[i].goodsNum
                    this.Ynum += (list[i].checkNum + list[i].scanNum?list[i].scanNum:0)
                    this.Pwin += ((list[i].checkNum + list[i].scanNum?list[i].scanNum:0) - list[i].goodsNum) > 0 ? ((list[i].checkNum + list[i].scanNum?list[i].scanNum:0) - list[i].goodsNum) : 0
                    this.Plose += ((list[i].checkNum + list[i].scanNum?list[i].scanNum:0) - list[i].goodsNum) < 0 ? ((list[i].checkNum + list[i].scanNum?list[i].scanNum:0) - list[i].goodsNum) : 0
                }
            }
        },
        // 初始化
        async initialize() {
            if (this.titleName != '新增盘库单') {
                let apiM = null
                if( this.titleName == '编辑盘库单'){
                    apiM = api.makeInventoryGetStockDetail
                }else if( this.titleName == '录入盘库单'||this.titleName == '修正盘库单'){
                    apiM = api.makeInventoryShowCheckStock
                }
                this.formLoading = true
                const res = await apiM({ id: this.dataOBj.id })
                try {
                    this.form.checkName = this.dataOBj.checkName
                    this.form.dimension = this.dataOBj.dimension
                    this.form.warehouseId = this.dataOBj.warehouseId
                    this.form.checkId = this.dataOBj.checkId
                    this.form.createdName = this.dataOBj.createdName
                    this.form.status = this.dataOBj.status
                    this.form.createdName = this.dataOBj.createdName
                    this.form.endTime = this.dataOBj.endTime
                    this.form.remark = this.dataOBj.remark
                    this.form.tableData = this.titleName == '编辑盘库单'? res.body.inventoryList:res.body.checkGoodsList
                    this.form.checkRemark = this.dataOBj.checkRemark
                    this.form.intoId = this.dataOBj.intoId
                    this.form.outId = this.dataOBj.outId
                    this.form.type = this.dataOBj.type
                    this.form.createdTimestamp = this.dataOBj.createdTimestamp
                    let list = this.form.tableData
                    this.Dnum=null
                    this.Ynum=null
                    this.Pwin=null
                    this.Plose=null
                    for (let i = 0; i < list.length; i++) {
                        this.Dnum += list[i].goodsNum
                        this.Ynum += (list[i].checkNum + list[i].scanNum?list[i].scanNum:0)
                        this.Pwin += ((list[i].checkNum + list[i].scanNum?list[i].scanNum:0) - list[i].goodsNum) > 0 ? ((list[i].checkNum + list[i].scanNum?list[i].scanNum:0) - list[i].goodsNum) : 0
                        this.Plose += ((list[i].checkNum + list[i].scanNum?list[i].scanNum:0) - list[i].goodsNum) < 0 ? ((list[i].checkNum + list[i].scanNum?list[i].scanNum:0) - list[i].goodsNum) : 0
                    }
                    this.formLoading = false
                } catch (err) {

                }
            }
        },
        // 弹窗打开
        async openDialog(t, e) {
            this.warehouseList = await getListM.getWarehouse()//仓库名称
            this.titleName = t
            this.visibleDialog = true
            this.dataOBj = e
            this.initialize()
        },
        //弹窗关闭
        dialogClose() {
            this.visibleDialog = false
            this.formLoading = false
            this.form = JSON.parse(JSON.stringify(formSource))
        },
        //保存
        async saveBtn(){
            if(this.form.tableData.length==0){
                this.$message.warning('请添加商品')
                return
            }
            let goodsCoding = []
            for(let i = 0;i<this.form.tableData.length;i++){
                goodsCoding.push(this.form.tableData[i].goodsCoding)
            }
            let params = {}
            params.checkName = this.form.checkName
            params.checkType = this.form.checkType
            params.dimension = this.form.dimension
            params.goodsCoding = goodsCoding
            params.remark = this.form.remark
            // params.type = this.form.type
            params.warehouseId = this.form.warehouseId
            let apiM = api.makeInventorySaveCheckStock
            if(this.titleName=='编辑盘库单'){
                params.checkId = this.dataOBj.checkId
                params.id = this.dataOBj.id
                apiM = api.makeInventoryUpdateCheckStock
                delete params.checkType
                // delete params.type
            }else if(this.titleName=='录入盘库单'){
                let goodsNumList = []
                for (let i = 0; i < this.form.tableData.length; i++) {
                    goodsNumList.push({id:this.form.tableData[i].id,checkNum:this.form.tableData[i].checkNum})
                }
                params.checkNo = this.dataOBj.checkId
                params.checkId = this.dataOBj.id
                params.checkRemark =  this.form.checkRemark
                params.goodsNumList = goodsNumList
                params.submitFlag= 0
                apiM = api.makeInventoryEnterCheckStock
                delete params.goodsCoding
                delete params.remark
                delete params.dimension
                delete params.checkName
                // delete params.type
                delete params.warehouseId
            }
            this.$refs.InfoForm.validate(async valid => {
                if (valid) {
                    const res = await apiM(params)
                    if (res.code == 200) {
                        this.dialogClose()
                        this.$message.success('保存成功')
                        this.$emit('successSave',true)
                    } else {
                        this.$message.warning('保存失败')
                    }
                }
            })
        },
        //提交
        submitBtn(){
            if(this.form.tableData.length==0){
                this.$message.warning('请添加商品')
                return
            }
            let goodsCoding = []
            for(let i = 0;i<this.form.tableData.length;i++){
                goodsCoding.push(this.form.tableData[i].goodsCoding)
            }
            let params = {}
            params.checkName = this.form.checkName
            params.checkType = this.form.checkType
            params.dimension = this.form.dimension
            params.goodsCoding = goodsCoding
            params.remark = this.form.remark
            // params.type = this.form.type
            params.warehouseId = this.form.warehouseId
            let apiM = api.makeInventoryCreateCheckStock
            if(this.titleName=='编辑盘库单'){
                params.checkId = this.dataOBj.checkId
                params.id = this.dataOBj.id
                apiM = api.makeInventoryUpdateCheckStock
                params.submitFlag = 1
                delete params.checkType
                // delete params.type
            }else if(this.titleName=='录入盘库单'){
                let goodsNumList = []
                for (let i = 0; i < this.form.tableData.length; i++) {
                    goodsNumList.push({id:this.form.tableData[i].id,checkNum:this.form.tableData[i].checkNum})
                }
                params.checkNo = this.dataOBj.checkId
                params.checkId = this.dataOBj.id
                params.checkRemark =  this.form.checkRemark
                params.goodsNumList = goodsNumList
                params.submitFlag= 1
                apiM = api.makeInventoryEnterCheckStock
                delete params.goodsCoding
                delete params.remark
                delete params.dimension
                delete params.checkName
                // delete params.type
                delete params.warehouseId
            }
            this.btnDisabled = true
            this.$refs.InfoForm.validate(async valid => {
                if (valid) {
                    const res = await apiM(params)
                    if (res.code == 200) {
                        this.dialogClose()
                        this.$message.success('提交成功')
                        this.$emit('successSave',true)
                        this.btnDisabled = false
                    } else {
                        this.$message.warning('提交失败')
                        this.btnDisabled = false
                    }
                }else{
                    this.btnDisabled = false
                }
            })
        },
        // 修正
        async amendBtn() {
            const goodsList = this.form.tableData.filter(item => item.checkNum + item.scanNum > item.goodsNum)
                .map(item => ({
                    goodsPictureUrl: item.pictureUrl,
                    goodsName: item.goodsName,
                    goodsCoding: item.goodsCoding,
                    commodityIntoSum: item.checkNum,
                }))
            const outGoodsList = await Promise.all(this.form.tableData.filter(item => item.checkNum + item.scanNum < item.goodsNum)
                .map(async item => {
                    const { body } = await this.getGoodsinPrice([item.goodsCoding])
                    return {
                        goodsPictureUrl: item.pictureUrl,
                        goodsName: item.goodsName,
                        goodsId: item.goodsCoding,
                        num: (item.checkNum + item.scanNum) - item.goodsNum,
                        recyclePrice: Math.min.apply(Math, body[item.goodsCoding].map(item => { return item.price }))
                    }
                }))
                this.btnDisabled = true
            const res = await api.amendmentDL({
                id: this.dataOBj.id,
                goodsList: goodsList.length == 0 ? null : goodsList,
                outGoodsList: outGoodsList.length == 0 ? null : outGoodsList,
            })
            if (res.code == 200) {
                this.dialogClose()
                this.$message.success('修正成功')
                this.$emit('successSave',true)
                this.btnDisabled = false
            } else {
                this.$message.warning('修正失败')
                this.btnDisabled = false
            }
        },
         // 选择商品
        selectGoods() {
            this.$refs.setStockGoodsList.open(this.form.warehouseId)
        },
        addGoods(data) {
            const newArr = data.map(item => ({
                goodsCoding: item.id,
                goodsName: item.goodsName,
                pictureUrl: item.pictureUrl,
                intoPrices: item.price,
                goodsNum: item.sumAll,
                type:this.form.type
            }))
            const indexList = []
            if((newArr.length+this.form.tableData.length)>200){   this.$message.error();('商品不能超过200'); return}
            newArr.forEach((item, index) => {
                this.form.tableData.forEach(redactItem => {
                    if (item.goodsCoding === redactItem.id) {
                        indexList.push(index)
                    }
                })
            })
            this.form.tableData.push(
                ...newArr.filter((item, index) => !indexList.includes(index))
            )
        },
        headerClass() {
            return "background: #eef1f6; color: #606266;text-align:center"
        },
        rowClass() {
            return "text-align:center"
        },
        almightyM(e,t){
            if (t == 'color') {
                if (((e.checkNum + e.scanNum) - e.goodsNum) == 0) {
                    return ''
                } else if (((e.checkNum + e.scanNum) - e.goodsNum) > 0) {
                    return 'success'
                } else if (((e.checkNum + e.scanNum) - e.goodsNum) < 0) {
                    return 'danger'
                }
            } else if (t == 'num') {
               return (e.checkNum + e.scanNum) - e.goodsNum
            }
        },
        almighty(e,t){
            if(t=='盘库状态'){
                if(e==0){
                    return '待盘库'
                }else if(e==1){
                    return '盘库中'
                }else if(e==2){
                    return '盘库完成'
                }else if(e==3){
                    return '终止盘库'
                }
            }
        }
    }
}
</script>

<style lang='scss' scoped>
.title{
    width:100%;
    padding:  0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #d7d7d7;
    height: 40px;
}
.goodsDataSty{
    width: 100%;
}
::v-deep .goodsDataSty .el-form-item__content {
  margin-left: 0 !important;
 
}
::v-deep .goodsDataSty .el-form-item {
    margin-bottom: 0 !important;
}
::v-deep .goodsDataSty .el-form-item__error{
    margin-left: 43px !important;
}
</style>