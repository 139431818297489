<template>
  <div class="goodsInfo">
    <d2-container>
      <head-search label-style="width:100px">
        <head-search-item label="商品编码">
          <el-input
            v-model="selectForm.goodsCoding"
            clearable
            placeholder="请输入商品编码"
            size="small"
            @keyup.enter.native="search"
          ></el-input>
        </head-search-item>
        <head-search-item label="商品名称">
          <el-input
            v-model="selectForm.goodsName"
            clearable
            placeholder="请输入商品名称"
            size="small"
            @keyup.enter.native="search"
          ></el-input>
        </head-search-item>
        <template slot="button">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
          >
            搜索
          </el-button>
          <el-button
            icon="el-icon-refresh"
            size="small"
            type="warning"
            @click="reset"
          >
            重置
          </el-button>
          <el-button
            :disabled="excelLoading"
            :loading="excelLoading"
            icon="el-icon-download"
            size="small"
            type="success"
            @click="exportExcel"
          >
            导出
          </el-button>
        </template>
      </head-search>
      <div>
        <div v-loading="loadingSum" class="showNum">
          <div class="font">
            商品总数量 <span class="num">{{ this.sumData.goodsNum }}</span>
          </div>
          <div class="font">
            商品总价格 <span class="num">{{ this.sumData.priceSum }}</span>
          </div>
        </div>
      </div>
      <div class="table">
        <el-table
          ref="tableData"
          v-loading="tableLoading"
          :cell-style="{ textAlign: 'center' }"
          :data="goodsInfoList"
          :header-cell-style="{
            background: '#dfe6ec',
            color: '#666666',
            textAlign: 'center'
          }"
          :row-style="{ height: '57px' }"
          element-loading-text="数据加载中"
          max-height="620px"
          tooltip-effect="dark"
        >
          <el-table-column align="center" label="序号" width="80">
            <template slot-scope="scope">
              {{
                (selectForm.pageNo - 1) * selectForm.pageSize + scope.$index + 1
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="商品编码"
            prop="goodsCoding"
            width="150"
          ></el-table-column>
          <el-table-column
            label="商品名称"
            prop="goodsName"
          ></el-table-column>
          <el-table-column
            label="商品图片"
            prop="pictureUl"
            width="150"
          >
            <template slot-scope="scope">
              <el-image
                :preview-src-list="[downloadUrl + '/' + scope.row.pictureUl]"
                :src="downloadUrl + '/' + scope.row.pictureUl"
                style="width: 40px; height: 40px;"/>
            </template>
          </el-table-column>
          <el-table-column
            label="商品数量"
            prop="goodsNum"
            width="150"
          ></el-table-column>
          <el-table-column
            label="商品单价"
            prop="intoPrice"
            width="150"
          ></el-table-column>
          <el-table-column
            label="商品价格"
            prop="priceSum"
            width="150"
          ></el-table-column>
        </el-table>
        <el-pagination
          :current-page="selectForm.pageNo"
          :page-size="selectForm.pageSize"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :total="selectForm.pageTotal"
          layout="->,total, sizes, prev, pager, next, jumper"
          @size-change="sizeChange"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </d2-container>
  </div>
</template>

<script>
import api from '@/api'
import { authority } from '@/views/store/stockin/list/contant'
import { downloadFileByUrl } from '@/utils/index.js'

const selectFormData = {
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0,
  goodsCoding: '',
  goodsName: ''
}
const sumDataDefault = {
  goodsNum: 0,
  priceSum: 0
}
export default {
  name: 'warehouseStatisticalGoodsInfo',
  props: {},
  data () {
    return {
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL, // downloadUrl
      selectForm: JSON.parse(JSON.stringify(selectFormData)),
      sumData: JSON.parse(JSON.stringify(sumDataDefault)),
      goodsInfoList: [],
      tableLoading: false,
      excelLoading: false,
      loadingSum: false
    }
  },
  computed: {},
  watch: {
    $route (to, from) {
      this.$route.query.date = to.query.date
      this.getList()
      this.getSum()
    }
  },
  created () {
    this.$store.commit('d2admin/page/modifyOpend', opened => {
      opened.forEach((current) => {
        if (current.name === 'warehouseStatisticalGoodsInfo') {
          current.meta.title = '入库统计-商品-' + current.query.date
        }
      })
    })
    this.auth = authority(this)
    this.getList()
    this.getSum()
  },
  methods: {
    search () {
      this.selectForm.pageNo = 1
      this.getList()
      this.getSum()
    },
    async getList () {
      this.tableLoading = true
      try {
        const res = await api.intoStaticalGoodsList({
          startTime: this.$route.query.date,
          endTime: this.$route.query.date,
          goodsCoding: this.selectForm.goodsCoding,
          goodsName: this.selectForm.goodsName,
          pageNo: this.selectForm.pageNo,
          pageSize: this.selectForm.pageSize
        })
        this.goodsInfoList = res.body.datas
        this.selectForm.pageNo = res.body.pageNo
        this.selectForm.pageSize = res.body.pageSize
        this.selectForm.pageTotal = res.body.sumDataCount
      } catch (error) {
        this.$message.error('加载数据失败')
      } finally {
        this.tableLoading = false
      }
    },
    async getSum () {
      this.loadingSum = true
      try {
        const res = await api.intoStaticalGoodsSum({
          startTime: this.$route.query.date,
          endTime: this.$route.query.date,
          goodsCoding: this.selectForm.goodsCoding,
          pageNo: this.selectForm.pageNo,
          pageSize: this.selectForm.pageSize
        })
        this.sumData.goodsNum = res.body.goodsNum ? res.body.goodsNum : 0
        this.sumData.priceSum = res.body.priceSum ? res.body.priceSum : 0
      } catch (error) {
        this.$message.error('聚合统计加载失败')
        this.sumData.goodsNum = 0
        this.sumData.priceSum = 0
      } finally {
        this.loadingSum = false
      }
    },
    reset () {
      this.selectForm = JSON.parse(JSON.stringify(selectFormData))
      this.getList()
      this.getSum()
    },
    sizeChange (val) {
      this.selectForm.pageSize = val
      this.getList()
      this.getSum()
    },
    currentChange (val) {
      this.selectForm.pageNo = val
      this.getList()
      this.getSum()
    },
    async exportExcel () {
      this.excelLoading = true
      try {
        const res = await api.exportIntoGoods({
          pageNo: this.selectForm.pageNo,
          pageSize: this.selectForm.pageSize,
          endTime: this.$route.query.date,
          field: '',
          goodsCoding: this.selectForm.goodsCoding,
          goodsName: this.selectForm.goodsName,
          sort: 0,
          startTime: this.$route.query.date
        })
        downloadFileByUrl(res.body)
      } catch (e) {
        this.$message.error('导出报表失败')
      } finally {
        this.excelLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.goodsInfo {
}

.showNum {
  width: 90%;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  display: flex;
  justify-content: space-around;
  line-height: 30px;
  border: 2px solid #999;
  border-radius: 5px;
}

.font {
  font-weight: bolder;
  font-size: 16px;
}

.num {
  margin-left: 20px;
  font-weight: normal;
}
</style>
