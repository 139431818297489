export default ({ request }) => ({
  // 获取采购类型、采购渠道
  getPurchaseSelect (data = {}) {
    return request({
      url: '/v1/enumeration/getList',
      method: 'post',
      data: data
    })
  },
  // 获取采购人员信息
  getPurchaseUser () {
    return request({
      url: '/v1/user/selectUser',
      method: 'post'
    })
  },
  // 获取仓库
  enumeration_exportExcel (data) {
    return request({
      url: '/v1/audit/auditExport',
      method: 'post',
      data: data
    })
  },
  // 获取仓库
  getPurchaseWarehouse () {
    return request({
      url: '/v1/warehouse/selectUserWarehouse',
      method: 'post'
    })
  },
  // 获取来源仓库
  getPurchaseSourceWarehouseIds () {
    return request({
      url: '/v1/warehouse/selectUserOrgWarehouse',
      method: 'post'
    })
  },
  // 分页查询
  getPageList_prurchasingList (params = {}, query = {}) {
    return request({
      url: '/v1/purchase/getPageList',
      method: 'post',
      params: params,
      data: query
    })
  },
  // 查询商品
  chooseProductPageList (data = {}) {
    return request({
      url: '/v1/goods/chooseProductPageList',
      method: 'post',
      data: data
    })
  },
  // 新增保存
  save_purchase (query = {}) {
    return request({
      url: '/v1/purchase/save',
      method: 'post',
      data: query
    })
  },
  // 编辑保存
  update_purchase (query = {}) {
    return request({
      url: '/v1/purchase/update',
      method: 'put',
      data: query
    })
  },
  // 查询详情
  info_purchase (params = {}) {
    return request({
      url: '/v1/purchase/info',
      method: 'get',
      params: params
    })
  },
  // 终止采购
  end_purchase (query = {}) {
    return request({
      url: '/v1/purchase/end',
      method: 'post',
      data: query
    })
  },
  // 调拨确认
  affirm_purchase (params = {}) {
    return request({
      url: '/v1/purchase/confirmAllocate',
      method: 'post',
      params: params
    })
  },
  // 查询物流
  getLogistics (params = {}) {
    return request({
      url: '/getLogistics',
      method: 'get',
      params: params
    })
  },
  // 处理明细
  deal_purchase (query = {}) {
    return request({
      url: '/v1/intoDetail/deal',
      method: 'post',
      data: query
    })
  },
  // 获取:我的入库价 我的库存数量 我的在途数量
  getMineProductInfo (query = {}) {
    return request({
      url: '/v1/goods/getMineProductInfo',
      method: 'post',
      data: query
    })
  },
  copyPurchaseOrder (query = {}) {
    return request({
      url: '/v1/purchase/copyPurchaseOrder',
      method: 'post',
      data: query
    })
  },
  // 采购随时添加图片
  purchasingListSaveImg(query={}){
    return request({
      url:'/v1/purchase/putPictureUrl',
      method:'post',
      data:query
    })
  },
    // 采购处理查询
    queryProductById(query={}){
      return request({
        url:'/v1/intoDetail/queryProductById',
        method:'post',
        data:query
      })
    }
  
})
