<template>
  <!-- 如果内容比较多可以添加top属性 -->
  <el-dialog
  :append-to-body="true"
    :title="title"
    :visible.sync="dialogVisible"
    width="550px"
    :close-on-click-modal="false"
    :before-close="handleClose">
  <el-form ref="form" status-icon :model="form" :rules="rules" label-width="80px">
       <el-form-item prop="url">
  <el-upload :headers="uploadHeaders" class="upload-demo" drag accept=".jpeg,.png,.jpg" :file-list="fileList"
    :action="uploadUrl + 'v1/file/uploadFileImg'" :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload" multiple>
    <i class="el-icon-upload"></i>
    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
    <div class="el-upload__tip" slot="tip">上传文件不能超过10M</div>
  </el-upload>
  </el-form-item>
    </el-form>
<div class="bottomDiv">
      <el-button @click="handleClose">关闭</el-button>
      <el-button :disabled="disabled" type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api'
import util from '@/libs/util.js'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  name: 'FileEdit',
  data () {
    return {
      uploadUrl: process.env.VUE_APP_API,
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL,
      uploadHeaders: {
        Authorization: util.cookies.get('token')
      },
      title: '',
      enumList: [],
      fileList: [],
      form: {
        fileId: null,
        createTime: null,
        isDel: null,
        saveSource: '',
        url: '',
        name: '',
        suffix: '',
        type: '',
        source: ''
      },
      rules: {
        fileId: [
          { required: true, message: '请输入文件id', trigger: 'blur' }
        ],
        createTime: [
          { required: true, message: '请输入创建时间', trigger: 'blur' }
        ],
        isDel: [
          { required: true, message: '请输入删除标记', trigger: 'blur' }
        ],
        saveSource: [
          { required: true, message: '请输入存放源', trigger: 'blur' }
        ],
        url: [
          { required: true, message: '请上传图片', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入文件名称', trigger: 'blur' }
        ],
        suffix: [
          { required: true, message: '请输入后缀', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请输入类型', trigger: 'blur' }
        ],
        source: [
          { required: true, message: '请输入来源', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.form = res.body
      this.form.urlImg = this.downloadUrl + '/' + res.body.url
    },
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('上传文件不能超过 10MB!')
      }
      return isLt2M
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    initEunmData () {
      this.getEnumList('file_type')
    },
    async getEnumList (name) {
      var par = {
        name: name
      }
      var res = await api.GET_ENUMERATION_LIST(par)
      this.enumList = res.body
    },
    setTitle (title) {
      this.title = title
    },
    initData () {
      this.initEunmData()
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields()
      }
      this.form.fileId = null
      this.form.createTime = null
      this.form.isDel = null
      this.form.saveSource = null
      this.form.url = null
      this.form.name = null
      this.form.suffix = null
      this.form.type = null
      this.form.source = null
      this.form.urlImg = null
    },
    setData (data) {
      this.initEunmData()
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields()
      }
      this.form.fileId = data.fileId
      this.form.createTime = data.createTime
      this.form.isDel = data.isDel
      this.form.saveSource = data.saveSource
      this.form.url = data.url
      this.form.name = data.name
      this.form.suffix = data.suffix
      this.form.type = data.type
      this.form.source = data.source
      this.form.urlImg = process.env.VUE_APP_DOWNLOAD_URL + '/' + data.url
    },
    async updateApi () {
      await api.UPDATE_FILE(this.form)
      this.$emit('initTableData')
      this.$message.success('操作成功')
      this.handleClose()
    },
    async saveApi () {
      await api.SAVE_FILE(this.form)
      this.$emit('initTableData')
      this.$message.success('操作成功')
      this.handleClose()
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.fileId != null) {
            this.$confirm('此操作将永久修改该数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.updateApi()
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消修改'
              })
            })
          } else {
            this.saveApi()
            this.fileList = []
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleClose (done) {
      this.$emit('close')
    }
  }
}
</script>

<style>
.inputText {
  width: 70%;
}

.bottomDiv {
  width: 100%;
  text-align: center;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px;
  text-align: center;
}

.avatar {
  width: 300px;
  display: block;
}
</style>
