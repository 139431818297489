export default (function (_ref) {
  var request = _ref.request;
  return {
    addCompanyOrDept: function addCompanyOrDept() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'v1/organization/addCompanyOrDept',
        method: 'post',
        data: data
      });
    },
    organizationChart_getPageList: function organizationChart_getPageList() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return request({
        url: 'v1/organization/getPageList',
        method: 'post',
        params: params,
        data: query
      });
    },
    getDeptList: function getDeptList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'v1/organization/getDeptList',
        method: 'post',
        data: data
      });
    },
    updCompanyOrDept: function updCompanyOrDept() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'v1/organization/updCompanyOrDept',
        method: 'put',
        data: data
      });
    },
    delCompanyOrDept: function delCompanyOrDept() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'v1/organization/delCompanyOrDept',
        method: 'delete',
        data: data
      });
    },
    getorgselect: function getorgselect() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'v1/organization/getOrgSelect',
        method: 'post',
        data: data
      });
    }
  };
});