// 库存列表
export default ({ request }) => ({
  inventoryStatisticalGetPage (data = {}) {
    return request({
      url: '/v1/inventoryPrice/invStatical',
      method: 'post',
      data: data
    })
  }
  // 按库位维度导出excel
  // inventoryStatisticalExport (data = {}) {
  //   return request({
  //     url: 'yyyyy',
  //     method: 'post',
  //     data: data
  //   })
  // },
})
