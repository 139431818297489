<template>
  <div class="dialog">
    <el-dialog ref="form" :close-on-click-modal="false" :title="titleName" :visible.sync="visibleDialog" width="90%"
      @close="dialogClose">
      <div class="title">
        <span>基本信息</span>
      </div>
      <el-form ref="form" :model="form" :rules="rules" inline size="normal">
        <div class="form">
          <template v-if="titleName === '编辑出库单'" class="showBox">
            <el-form-item class="show" label="出库单号" label-width="100px">
              <el-input v-model="form.outId" disabled size="small" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item class="show" label="出库状态" label-width="100px">
              <el-select v-model="form.status" disabled size="small" style="width: 200px">
                <el-option v-for="item in list.outStatus" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="show" label="制单人员" label-width="100px">
              <el-input v-model="form.createdName" disabled size="small" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item class="show" label="创建时间" label-width="100px">
              <el-input v-model="form.createdTimestamp" disabled size="small" style="width: 200px"></el-input>
            </el-form-item>
            <template v-if="form.type === 1">
              <el-form-item class="show" label="系统订单号" label-width="100px">
                <el-input v-model="form.orderId" disabled size="small" style="width: 200px"></el-input>
              </el-form-item>
              <el-form-item class="show" label="主订单号 " label-width="100px">
                <el-input v-model="form.outId" disabled size="small" style="width: 200px"></el-input>
              </el-form-item>
              <el-form-item class="show" label="子订单号" label-width="100px">
                <el-input v-model="form.outId" disabled size="small" style="width: 200px"></el-input>
              </el-form-item>
            </template>
          </template>
          <el-form-item label="出库类型" label-width="100px" prop="type">
            <el-select v-model="form.type" placeholder="请选择出库类型" size="small" :disabled="titleName === '编辑出库单'" disabled
              style="width: 200px">
              <el-option v-for="item in list.outType" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="judge != 5" label="订单来源" label-width="100px" prop="source">
            <el-select v-model="form.source" placeholder="请选择订单来源" size="small"
              style="width: 200px">
              <el-option v-for="item in list.outSource" :key="item.id" :label="item.values" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="仓库名称" label-width="100px" prop="areaId">
            <el-select filterable v-model="form.areaId" :disabled="titleName === '编辑出库单'" placeholder="请选择入库仓库"
              size="small" style="width: 200px" @change="changeWarehouse">
              <el-option v-for="item in list.WarehousingWarehouse" :key="item.warehouseId" :label="item.warehouseName"
                :value="item.warehouseId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="配送方式"
            label-width="100px"
            prop="distribution"
          >
            <el-select
              v-model="form.distribution"
              placeholder="请选择配送方式"
              size="small"
              style="width: 200px"
              @change="changeDistribution"
            >
              <el-option
                v-for="item in list.distributionType"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="judge != 5" label="物流公司" label-width="100px" prop="courierCompany">
            <el-input v-model="form.courierCompany" :disabled="isEdit" clearable placeholder="请输入物流公司" size="small"
              style="width: 200px"></el-input>
          </el-form-item>
          <el-form-item clearable label="物流单号" label-width="100px" prop="courierOrderId" v-if="judge != 5">
            <el-input v-model="form.courierOrderId" :disabled="isEdit" placeholder="请输入物流单号" size="small"
              style="width: 200px"></el-input>
          </el-form-item>
        </div>
        <br />
        <template v-if="!(form.type === 0 && form.distribution === 1) && judge != 5">
          <div class="title">
            <span>收货信息</span>
          </div>
          <div class="form">
            <el-form-item v-if="!(form.type === 0 && form.distribution === 0)" clearable label="收货姓名" label-width="80px"
              prop="chargeName">
              <el-input v-model="form.chargeName" :disabled="isEdit" placeholder="请输入收货姓名" size="small"></el-input>
            </el-form-item>
            <el-form-item v-if="!(form.type === 0 && form.distribution === 0)" clearable label="收货电话" label-width="80px"
              prop="chargePhone">
              <el-input v-model="form.chargePhone" :disabled="isEdit" placeholder="请输入收货电话" size="small"></el-input>
            </el-form-item>
            <el-form-item clearable label="收货地址" label-width="80px" prop="chargeAddress">
              <el-input v-model="form.chargeAddress" :disabled="isEdit" placeholder="请输入收货地址" size="small"
                style="width: 500px"></el-input>
            </el-form-item>
          </div>
        </template>
        <!--        <div class="title" v-if="titleName !== '新增出库单'">-->
        <!--          <span>出库打包图片</span>-->
        <!--        </div>-->
        <!--        <el-form-item :prop="this.titleName === '编辑出库单' ? 'outImage' : ''" v-if="titleName !== '新增出库单'" style="margin: 10px">-->
        <!--          <erp-upload-img-->
        <!--            v-model="form.outImage"-->
        <!--            :maxCount="10"-->
        <!--            :showDelete="true"-->
        <!--            :upload-path="'/store/stockout/'"-->
        <!--          ></erp-upload-img>-->
        <!--        </el-form-item>-->
        <div class="title">
          <span>商品信息</span>
          <div class="btn">
            <el-button :disabled="isEdit" size="small" type="success" @click="addRow">
              添加
            </el-button>
          </div>
        </div>
        <div class="table">
          <el-form-item prop="outGoodsList">
            <el-table ref="outGoodsList" :cell-style="{ textAlign: 'center' }" :data="form.outGoodsList"
              :header-cell-style="{
                background: '#dfe6ec',
                color: '#666666',
                textAlign: 'center'
              }" :row-style="{ height: '57px' }" element-loading-text="数据加载中" max-height="620px" row-key="key"
              style="overflow: auto" tooltip-effect="dark">
              <el-table-column align="center" label="序号" type="index" width="80"></el-table-column>
              <el-table-column align="center" label="商品编码" prop="goodsId" width="150"></el-table-column>
              <el-table-column align="center" label="商品名称" prop="goodsName" width="300"></el-table-column>
              <el-table-column align="center" label="商品图片" width="150">
                <template slot-scope="scope">
                  <el-image :preview-src-list="[$oss + scope.row.goodsPictureUrl]" :src="$oss + scope.row.goodsPictureUrl"
                    fit="cover" style="width: 50px; height: 50px"></el-image>
                </template>
              </el-table-column>
              <el-table-column align="center" label="入库价" width="200">
                <template slot-scope="scope">
                  <el-form-item :prop="'outGoodsList.' + scope.$index + '.recyclePrice'" :rules="rules.recyclePrice">
                    <erp-select v-model="scope.row.recyclePrice" @change="(newValue, oldValue) =>
                        changePrice(
                          newValue,
                          oldValue,
                          scope.$index,
                          scope.row,
                          goodsInPrice[scope.row.goodsId]
                        )
                      ">
                      <el-option v-for="item in goodsInPrice[scope.row.goodsId]" :key="item.value.price"
                        :disabled="item.disabled" :label="item.value.price" :value="item.value.price"></el-option>
                    </erp-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" label="在库库存" prop="inventoryNums" width="120">
                <template slot-scope="scope">
                  {{ scope.row.inventoryNums ? scope.row.inventoryNums : '- -' }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="出库价" width="150">
                <template slot-scope="scope">
                  <el-form-item :prop="'outGoodsList.' + scope.$index + '.outPrice'" :rules="rules.outPrice">
                    <el-input-number v-model="scope.row.outPrice" :disabled="isEdit" :min="0" :precision="2" clearable
                      placeholder="请输入出库价" size="small" type="number"
                      @input.native="changeInput($event)"></el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" label="数量" width="150">
                <template slot-scope="scope">
                  <el-form-item :prop="'outGoodsList.' + scope.$index + '.num'" :rules="rules.num">
                    <el-input-number v-model="scope.row.num" :disabled="isEdit" :max="scope.row.maxNum" :min="0"
                      :precision="0" clearable placeholder="请输入出库数量" size="small" type="number" @change="
                        maxNum(
                          scope.row.goodsId,
                          scope.row.recyclePrice,
                          scope.row.inventoryNums,
                          scope.$index
                        )
                        "></el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" label="出库总计" width="150">
                <template slot-scope="scope">
                  {{
                    scope.row.outPrice * scope.row.num
                    ? (scope.row.outPrice * scope.row.num).toFixed(2)
                    : 0
                  }}
                </template>
              </el-table-column>
              <el-table-column align="center" fixed="right" label="操作" width="150">
                <template slot-scope="scope">
                  <el-button :disabled="isEdit" size="mini" type="success" @click="copyBtn(scope.$index, scope.row)">
                    复制
                  </el-button>
                  <el-button :disabled="isEdit" size="mini" type="danger" @click="deleteRow(scope.$index, scope.row)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </div>
      </el-form>
      <div class="title">
        <span>统计</span>
      </div>
      <div class="count">
        <div class="count-item">
          出库数量：
          <span class="red">{{ count.outNum }}</span>
        </div>
        <div class="count-item">
          入库价之和：
          <span class="red">{{ count.inSum.toFixed(2) }}</span>
        </div>
        <div class="count-item">
          出库价之和：
          <span class="red">{{ count.outSum.toFixed(2) }}</span>
        </div>
        <div class="count-item">
          出库差额：
          <span class="red">{{ count.diffSum.toFixed(2) }}</span>
        </div>
      </div>
      <div class="title">
        <span>备注</span>
      </div>
      <el-input v-model="form.remark" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"></el-input>
      <span slot="footer">
        <el-button size="small" @click="cancel">取消</el-button>
        <el-button :disabled="keepLoading" :loading="keepLoading" size="small" type="primary" @click="save">
          保存
        </el-button>
      </span>
      <setStockGoodsList ref="setStockGoodsList" @setGoods="addGoods"></setStockGoodsList>
    </el-dialog>
  </div>
</template>
<script>
import { formData } from '../list/contant'
import setStockGoodsList from '@/components/setStockGoodsList/index.vue'
import erpSelect from '@/components/erp-select/index.vue'
import api from '@/api'

export default {
  name: 'Dialog',
  data() {
    return {
      judge: null,
      index: 0,
      recyclePriceList: [
        {
          label: 12,
          value: 1
        },
        {
          label: 11,
          value: 0
        }
      ],
      info: [], // 点击的行信息
      flagArr: [], // 用于存放 相同商品，同价位 的操作数组
      keepLoading: false,
      deleList: [],
      titleName: '',
      visibleDialog: false,
      isEdit: false,
      goodsInPrice: {},
      form: JSON.parse(JSON.stringify(formData)),
      rules: {
        type: [
          {
            required: true,
            message: '请选择出库类型',
            trigger: 'change'
          }
        ],
        source: [
          {
            required: true,
            message: '请选择订单来源',
            trigger: 'change'
          }
        ],
        areaId: [
          {
            required: true,
            message: '请选择入库仓库',
            trigger: 'change'
          }
        ],
        distribution: [
          {
            required: true,
            message: '请选择配送方式',
            trigger: 'change'
          }
        ],
        courierCompany: [
          {
            required: true,
            message: '请输入物流公司',
            trigger: 'blur'
          }
        ],
        courierOrderId: [
          {
            required: true,
            message: '请输入物流单号',
            trigger: 'blur'
          }
        ],
        recyclePrice: [
          {
            required: true,
            message: '请选择入库价',
            trigger: 'blur'
          }
        ],
        outPrice: [
          {
            required: true,
            message: '请输入出库价',
            trigger: 'blur'
          },
          {
            pattern: /^\d+(\.\d{1,2})?$/,
            message: '请输入正数，且小数点后不能超过两位',
            trigger: 'blur'
          }
        ],
        num: [
          {
            required: true,
            message: '请输入数量',
            trigger: 'blur'
          },
          {
            pattern: /^[1-9]\d*$/,
            message: '请输入正整数',
            trigger: 'blur'
          }
          // {
          //   required: true,
          //   trigger: 'blur',
          //   validator: (rule, value, callback) => {
          //     this.validateNum(rule, value, callback, 'cover')
          //   }
          // }
        ],
        outGoodsList: [
          {
            required: true,
            message: '商品信息不能为空',
            trigger: 'blur'
          }
        ],
        outImage: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              this.validatePicture(rule, value, callback, 'cover')
            }
          }
        ],
        chargeName: [
          {
            required: true,
            message: '请输入收件人姓名',
            trigger: 'blur'
          }
        ],
        chargePhone: [
          {
            required: true,
            message: '请输入收件人电话',
            trigger: 'blur'
          }
        ],
        chargeAddress: [
          {
            required: true,
            message: '请输入收件人地址',
            trigger: 'blur'
          }
        ]
      },
      list: {}
    }
  },
  computed: {
    count() {
      const count = {
        outNum: 0,
        inSum: 0,
        outSum: 0,
        diffSum: 0
      }
      this.form.outGoodsList.forEach(item => {
        count.outNum += +item.num
        count.inSum += +(item.recyclePrice * item.num)
        count.outSum += +(item.outPrice * item.num).toFixed(2)
        count.diffSum += +(
          (item.outPrice - item.recyclePrice) *
          item.num
        ).toFixed(2)
      })
      return count
    },
    // eslint-disable-next-line vue/return-in-computed-property
    filtrateSumToDom() {

    }
  },
  methods: {
    // 打开弹窗
    open() {
      setTimeout(() => {
        this.changeDistribution()
        this.flagArr = []
        this.$refs.form.clearValidate()
      })
      return {
        add: () => {
          this.visibleDialog = true
          this.form.type = 3
          this.titleName = '新增出库单'
        },
        edit: async row => {
          this.judge = row.type
          this.form = Object.assign(this.form, row)
          if (this.form.type === 0) {
            this.rules.chargeName[0].required = false
            this.rules.chargeAddress[0].required = false
            this.rules.chargePhone[0].required = false
          } else {
            this.rules.chargeName[0].required = true
            this.rules.chargeAddress[0].required = true
            this.rules.chargePhone[0].required = true
          }
          this.visibleDialog = true
          this.titleName = '编辑出库单'
          this.isEdit = row.type === 0 || row.type === 1;
          await this.getGoodsList(row.id)
          this.setoutList()
        }
      }
    },
    changeInput(e) {
      if (e.target.value.indexOf('.') >= 0) {
        e.target.value = e.target.value.substring(
          0,
          e.target.value.indexOf('.') + 3
        )
      }
    },
    // 获取出库单商品列表
    async getGoodsList(id) {
      const { body: data } = await api.outbound_info({ id: id })
      const arr = []
      data.outGoodsList.forEach(e => {
        arr.push(e.goodsId)
      })
      if (data.outImage == '') {
        this.form.outImage = []
      } else {
        this.form.outImage = (data.outImage || '').split(',').map(item => {
          return {
            name: item,
            url: item
          }
        })
      }
      const inPrices = await this.getGoodsinPrice(arr)
      // inPrices = this.inPricesHandler(inPrices)
      const price = inPrices.body
      // // console.log(price, 'price')
      // this.goodsInPrice = Object.assign({}, inPrices)

      let goodsInPrice = await this.getGoodsinPrice(arr)
      goodsInPrice = this.inPricesHandler(goodsInPrice)
      this.goodsInPrice = Object.assign({}, goodsInPrice, this.goodsInPrice)

      this.form.outGoodsList = data.outGoodsList
      this.form.outGoodsList.forEach((item, index) => {
        for (const key in price) {
          if (item.goodsId == key) {
            for (let i = 0; i < price[key].length; i++) {
              if (item.recyclePrice == price[key][i].price) {
                this.form.outGoodsList[index].inventoryNums = price[key][i].inventoryNums
              }
            }
          }
        }
      })
    },
    inPricesHandler(inPrices) {
      const res = {}
      for (const key in inPrices.body) {
        let obj = []
        obj = inPrices.body[key].map(item => {
          return {
            disabled: false,
            value: item
          }
        })
        res[key.toString()] = obj
      }
      return res
    },
    changeDistribution(val) {
      if (val === 2) {
        this.rules.courierCompany[0].required = true
        this.rules.courierOrderId[0].required = true
        this.rules.chargeName[0].required = true
        this.rules.chargePhone[0].required = true
        this.rules.chargeAddress[0].required = true
      } else {
        this.rules.chargeName[0].required = false
        this.rules.chargePhone[0].required = false
        this.rules.courierCompany[0].required = false
        this.rules.courierOrderId[0].required = false
        this.rules.chargeAddress[0].required = false
      }
    },
    // 去重数组
    filterSame(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] === arr[j]) {
            arr.splice(j, 1)
            j--
          }
        }
      }
      return arr.sort(function (a, b) {
        return a - b
      })
    },
    // 保存按钮
    save() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          let param = JSON.parse(JSON.stringify(this.form))
          // 处理参数
          param = this.paramHandler(param)
          let code = ''
          try {
            if (this.titleName === '新增出库单') {
              this.keepLoading = true
              code = await api.outbound_save(param)
              this.$emit('save')
            } else {
              this.keepLoading = true
              code = await api.outbound_edit(param)
              this.$emit('save')
            }
          } catch (e) {
            this.keepLoading = false
            throw new Error(e)
          }finally {
            this.keepLoading = false
          }
          if (code.code === 200) {
            this.keepLoading = false
            this.visibleDialog = false
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          }
        } else {
          return false
        }
      })
    },
    // 最大商数值
    maxNum(goodsId, recyclePrice, sumAll, index) {
      this.flagArr = []
      let numAll = 0
      const arr = []
      this.form.outGoodsList.forEach((item, index) => {
        if (item.goodsId == goodsId && item.recyclePrice == recyclePrice) {
          this.flagArr.push({
            num: item.num,
            index: index,
            sumAll: sumAll
          })
        }
      })
      // console.log(this.flagArr, ' this.flagArr')
      if (this.flagArr.length > 0) {
        this.flagArr.forEach((item) => {
          numAll += +item.num
          // console.log(numAll)
          arr.push(item.index)
          if (numAll == this.flagArr[0].sumAll) {
            // console.log(item)
            this.form.outGoodsList[item.index].maxNum = this.form.outGoodsList[item.index].num
          } else if (numAll + 1 > this.flagArr[0].sumAll) {
            this.form.outGoodsList[index].maxNum = this.form.outGoodsList[index].num - 1
          } else {
            this.form.outGoodsList[item.index].maxNum = undefined
          }
        })
      } else {
        this.form.outGoodsList[index].maxNum = undefined
      }
    },
    // 处理参数
    paramHandler(param) {
      let outImagesString = ''
      param.outGoodsList = [...param.outGoodsList, ...this.deleList]
      if (this.titleName === '编辑出库单') {
        if (param.outImage === '' || param.outImage === null) {
          outImagesString = ''
        } else if (param.outImage !== '') {
          outImagesString = param.outImage
            .map((item) => {
              return item.url
            })
            .join(',')
        }
      }
      console.log(outImagesString)
      return {
        areaId: param.areaId,
        chargeAddress: param.chargeAddress,
        chargeName: param.chargeName,
        chargePhone: param.chargePhone,
        courierCompany: param.courierCompany,
        courierOrderId: param.courierOrderId,
        distribution: param.distribution,
        outImage: (this.titleName === '编辑出库单' && (param.outImage === '' || null)) ? '' : (this.titleName === '编辑出库单' && (param.outImage !== '')) ? outImagesString : '',
        orderId: param.orderId,
        remark: param.remark,
        source: param.source,
        type: param.type,
        outId: param.outId,
        id: param.id,
        outGoodsList: (() => {
          const arr = []
          param.outGoodsList.forEach(e => {
            arr.push({
              goodsId: e.goodsId,
              goodsName: e.goodsName,
              goodsPictureUrl: e.goodsPictureUrl,
              id: e.id,
              num: e.num,
              outId: e.outId,
              outPrice: e.outPrice,
              recyclePrice: e.recyclePrice,
              total: e.total,
              delFlg: e.delFlg || undefined
            })
          })
          return arr
        })()
      }
    },
    // 取消按钮
    cancel() {
      this.visibleDialog = false
    },
    // 关闭弹窗事件
    dialogClose() {
      this.judge = null
      this.info = []
      this.isEdit = false
      this.goodsInPrice = {}
      this.keepLoading = false
      this.form = JSON.parse(JSON.stringify(formData))
    },
    // 复制行
    copyBtn(index, e){
      let obj = {
        goodsId: e.goodsId,
        recyclePrice: '',
        outPrice: '',
        num: '',
        goodsName: e.goodsName,
        // sumAll: e.sumAll,
        goodsPictureUrl: e.goodsPictureUrl,
        key: Symbol('key')
      }
      this.form.outGoodsList.splice(index,0,obj)
    },
    // 删除行
    deleteRow(index, row) {
      if (this.form.outGoodsList[index].id) {
        this.deleList.push(
          Object.assign(this.form.outGoodsList[index], { delFlg: 1 })
        )
      }
      this.form.outGoodsList.splice(index, 1)
      // this.changePrice(null, row.recyclePrice, row)
    },
    // 设置下拉框
    setList(list) {
      const res = JSON.parse(JSON.stringify(list))
      this.list = res
      const keyList = [3]
      // const keyList = [3,4]
      this.list.outType = this.list.outType.filter(item =>
        keyList.includes(item.key)
      )
      this.list.distributionType.pop()
    },
    // 打开添加商品Log
    addRow() {
      if (this.form.areaId) {
        this.$refs.setStockGoodsList.open(this.form.areaId)
        return
      }
      this.$message({
        message: '请先选择仓库名称',
        type: 'warning'
      })
    },
    // 获得商品对应的入库价
    async getGoodsinPrice(arr) {
      const data = await api.inventory_selectPrice({
        goodsCodings: arr,
        warehouseId: this.form.areaId
      })
      return data
    },
    // 添加商品
    async addGoods(data, e) {
      // console.log(this.setStockGoodsList)
      const arr = []
      data.forEach(e => {
        arr.push(e.id)
      })
      let goodsInPrice = await this.getGoodsinPrice(arr)
      goodsInPrice = this.inPricesHandler(goodsInPrice)
      this.goodsInPrice = Object.assign({}, goodsInPrice, this.goodsInPrice)
      const res = data.map(e => {
        const r = {
          goodsId: e.id,
          recyclePrice: '',
          outPrice: '',
          num: '',
          goodsName: e.goodsName,
          // sumAll: e.sumAll,
          goodsPictureUrl: e.pictureUrl,
          key: Symbol('key')
        }
        return r
      })
      this.form.outGoodsList.push(...res)
    },
    changePrice(newValue, oldValue, index, row, price) {
      price.forEach(e => {
        if (e.value.price === newValue) {
          this.form.outGoodsList[index].inventoryNums = e.value.inventoryNums
        }
      })
      this.goodsInPrice[row.goodsId].forEach(e => {
        if (e.value === newValue) {
          e.disabled = true
        } else if (e.value === oldValue) {
          e.disabled = false
        }
      })
      this.form.outGoodsList.forEach((item, index) => {
        if (item.goodsId == row.goodsId && item.recyclePrice == row.recyclePrice) {
          this.flagArr.push({
            num: item.num,
            index: index
          })
        }
      })
      row.num = 0
    },

    changeWarehouse() {
      this.form.outGoodsList = []
      this.deleList = []
      this.goodsInPrice = {}
    },
    // 图片校验
    validatePicture(rule, value, callback) {
      if (value.length === 0) {
        callback(new Error('图片为必选项'))
      } else {
        callback()
      }
    },
    async setoutList() {
      const { body: data } = await api.selectedEnums_getList({
        enumName: 'OutTypeEnum'
      })
      this.list.outType = data
    }
  },
  components: {
    setStockGoodsList,
    erpSelect
  }
}
</script>
<style lang="scss" scoped>
.title {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #b8b8b81e;
  height: 30px;
  font-weight: bold;
}

.count {
  display: flex;
  width: 100%;
  height: 50px;
}

.count-item {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.red {
  color: red;
}

.el-form-item {
  width: 280px;
  display: flex;
  margin-right: 5px;
  margin-bottom: 0px;
}

::v-deep .el-form-item__error {
  position: relative !important;
  padding-top: 0px;
  top: 0px;
}

.form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}

::v-deep .show {
  input {
    border: 1px !important;
    background: #00000000 !important;
    color: black !important;
  }

  .el-input__icon {
    display: none;
  }
}

::v-deep .el-form-item {
  width: fit-content !important;
}

.table {
  width: 100%;
  overflow: auto;
}
</style>
