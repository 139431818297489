export default ({ request }) => ({
  // 获取采购商品列表
  getPurchaseSummaryList (data = {}) {
    return request({
      url: '/purchase/statical/getList',
      method: 'post',
      data: data
    })
  },
  // 获取采购商品聚合统计
  getPurchaseSummaryResult (data = {}) {
    return request({
      url: '/purchase/statical/getAggregateStaticalResult',
      method: 'post',
      data: data
    })
  },
  // 获取采购单明细
  getPurchaseSummaryGoodsList (data = {}) {
    return request({
      url: '/purchase/statical/getGoodsList',
      method: 'post',
      data: data
    })
  },
  // 获取采购单明细聚合统计
  getPurchaseSummaryGoodsResult (data = {}) {
    return request({
      url: '/purchase/statical/getPurNewGoodsResult',
      method: 'post',
      data: data
    })
  }
})
