var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c("div", { staticStyle: { width: "100%" } }, [
      _c(
        "div",
        { staticClass: "searchSty" },
        [
          _c("el-date-picker", {
            attrs: {
              "picker-options": _vm.pickerOptions,
              align: "right",
              "end-placeholder": "结束日期",
              "range-separator": "至",
              size: "small",
              "start-placeholder": "开始日期",
              type: "daterange",
              "unlink-panels": "",
              "value-format": "yyyy-MM-dd"
            },
            model: {
              value: _vm.search.timeStamp,
              callback: function($$v) {
                _vm.$set(_vm.search, "timeStamp", $$v)
              },
              expression: "search.timeStamp"
            }
          }),
          _c(
            "div",
            { staticClass: "searchBtn" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-search",
                    size: "small",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.toQuery()
                    }
                  }
                },
                [_vm._v(" 搜索 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-refresh",
                    size: "small",
                    type: "warning"
                  },
                  on: {
                    click: function($event) {
                      return _vm.reset()
                    }
                  }
                },
                [_vm._v(" 重置 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loadingSum,
            expression: "loadingSum"
          }
        ],
        staticClass: "numberSty"
      },
      [
        _c(
          "div",
          {
            staticClass: "intNumSty",
            staticStyle: { "background-color": "#66a9c9" }
          },
          [
            _c("div", { staticClass: "titleName" }, [_vm._v("订单数量")]),
            _c("div", { staticClass: "num" }, [
              _vm._v(_vm._s(this.sumData.orderNum))
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "intNumSty",
            staticStyle: {
              "margin-left": "20px",
              "background-color": "#428675"
            }
          },
          [
            _c("div", { staticClass: "titleName" }, [_vm._v("商品数量")]),
            _c("div", { staticClass: "num" }, [
              _vm._v(_vm._s(this.sumData.productNum))
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "intNumSty",
            staticStyle: {
              "margin-left": "20px",
              "background-color": "#8c4b31"
            }
          },
          [
            _c("div", { staticClass: "titleName" }, [_vm._v("入库总金额")]),
            _c("div", { staticClass: "num" }, [
              _vm._v(_vm._s(this.sumData.totalIntoMoney))
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "intNumSty",
            staticStyle: {
              "margin-left": "20px",
              "background-color": "#9d3a21"
            }
          },
          [
            _c("div", { staticClass: "titleName" }, [_vm._v("出库总金额")]),
            _c("div", { staticClass: "num" }, [
              _vm._v(_vm._s(this.sumData.totalOutMoney))
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "intNumSty",
            staticStyle: {
              "margin-left": "20px",
              "background-color": "#fab09d"
            }
          },
          [
            _c("div", { staticClass: "titleName" }, [_vm._v("出入库差价")]),
            _c("div", { staticClass: "num" }, [
              _vm._v(
                _vm._s(
                  _vm.setNum(
                    this.sumData.totalOutMoney,
                    this.sumData.totalIntoMoney
                  )
                )
              )
            ])
          ]
        )
      ]
    ),
    _c(
      "div",
      { staticStyle: { "margin-top": "10px" } },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingTotal,
                expression: "loadingTotal"
              }
            ],
            ref: "tableData",
            attrs: {
              "cell-style": { textAlign: "center" },
              data: _vm.tableData,
              "header-cell-style": {
                background: "#dfe6ec",
                color: "#666666",
                textAlign: "center"
              },
              "row-style": { height: "57px" },
              "element-loading-text": "数据加载中",
              "max-height": "645px",
              "tooltip-effect": "dark"
            }
          },
          [
            _c("el-table-column", {
              attrs: { align: "center", label: "序号", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.search.pageNo - 1) * _vm.search.pageSize +
                              scope.$index +
                              1
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "日期", prop: "date" }
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "商品数量", prop: "productNum" }
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "入库金额",
                prop: "totalIntoMoney"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "出库金额",
                prop: "totalOutMoney"
              }
            }),
            _c("el-table-column", {
              attrs: { label: "出入库差价" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.setNum(
                              scope.row.totalOutMoney,
                              scope.row.totalIntoMoney
                            )
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "操作" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", size: "small", type: "info" },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.orderDetails(
                                scope.row,
                                scope.$index,
                                _vm.tableData
                              )
                            }
                          }
                        },
                        [_vm._v(" 订单明细 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", size: "small", type: "primary" },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.goodsDetails(
                                scope.row,
                                scope.$index,
                                _vm.tableData
                              )
                            }
                          }
                        },
                        [_vm._v(" 商品明细 ")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "right",
          "margin-top": "10px"
        }
      },
      [
        this.search.pageSize > 0
          ? _c("el-pagination", {
              attrs: {
                "current-page": _vm.search.pageNo,
                disabled: _vm.permission.isShow,
                "page-size": _vm.search.pageSize,
                "page-sizes": [10, 20, 30, 40, 50, 100],
                total: _vm.search.pageTotal,
                layout: "total, jumper, prev, pager, next,sizes"
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }