<template>
  <div>
    <div class="dashboard-editor-container">
      <div class="systemTime">截止到:{{ this.systemTime }}</div>
      <div class="cardRules" v-loading="loadingCard">
        <!-- 第一行 -->
        <!-- 库存商品数量 -->
        <div class="cardSty" style="background-color:#f77740">
          <div class="svgSty">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-icon_kucunshangpin"></use>
            </svg>
          </div>
          <el-statistic title="库存商品数量" class="statisticSty">
            <template slot="formatter">
              <span class="font">{{ this.tableData.invProductNum }}</span>
            </template>
          </el-statistic>
        </div>
        <!-- 库存商品总金额 -->
        <div class="cardSty" style="background-color:#4f86f7">
          <div class="svgSty">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-jine1"></use>
            </svg>
          </div>
          <el-statistic title="库存商品总金额" class="statisticSty">
            <template slot="formatter">
              <span class="font">{{ this.tableData.invProductMoney }}</span>
            </template>
          </el-statistic>
        </div>
        <!-- 货损商品数量 -->
        <!-- <div class="cardSty" style="background-color:#00cc66">
          <div class="svgSty">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-transport-full"></use>
            </svg>
          </div> -->
          <!-- <el-statistic title="货损商品数量" class="statisticSty">
            <template slot="formatter">
              <span class="font">{{ this.tableData.invDamageProductNum }}</span>
            </template>
          </el-statistic> -->
        <!-- </div> -->
        <!-- 货损商品总金额 -->
        <!-- <div class="cardSty" style="background-color:#ff33ff">
          <div class="svgSty">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-qianfeijine"></use>
            </svg>
          </div> -->
          <!-- <el-statistic title="货损商品总金额" class="statisticSty">
            <template slot="formatter">
              <span class="font">{{
                this.tableData.invDamageProductMoney
              }}</span>
            </template>
          </el-statistic> -->
        <!-- </div> -->
        <!-- 在途商品总金额 -->
        <div class="cardSty" style="background-color:#cc3333">
          <div class="svgSty">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-jine"></use>
              #icon-jine
            </svg>
          </div>
          <el-statistic title="在途商品总金额" class="statisticSty">
            <template slot="formatter">
              <span class="font">{{ this.tableData.ingIntoOrderMoney }}</span>
            </template>
          </el-statistic>
        </div>
        <!-- 第二行 -->
        <!-- 采购订单数量 -->
        <div class="cardSty" style="background-color:#33ccff">
          <div class="svgSty">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-dingdan-"></use>
            </svg>
          </div>
          <el-statistic title="采购订单数量" class="statisticSty">
            <template slot="formatter">
              <span class="font">{{ this.tableData.purchaseNum }}</span>
            </template>
          </el-statistic>
        </div>
        <!-- 代发订单数量 -->
        <div class="cardSty" style="background-color:#9443f4">
          <div class="svgSty">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-dingdanzhongxin"></use>
            </svg>
          </div>
          <el-statistic title="代发订单数量" class="statisticSty">
            <template slot="formatter">
              <span class="font">{{ this.tableData.issuingNum }}</span>
            </template>
          </el-statistic>
        </div>
        <!-- 调拨订单数量 -->
        <div class="cardSty" style="background-color:#99cc66">
          <div class="svgSty">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-zongheyewudingdan"></use>
            </svg>
          </div>
          <el-statistic title="调拨订单数量" class="statisticSty">
            <template slot="formatter">
              <span class="font">{{ this.tableData.allotNum }}</span>
            </template>
          </el-statistic>
        </div>
        <!-- 入库订单数量 -->
        <div class="cardSty" style="background-color:#ff6699">
          <div class="svgSty">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-list"></use>
            </svg>
          </div>
          <el-statistic title="入库订单数量" class="statisticSty">
            <template slot="formatter">
              <span class="font">{{ this.tableData.intoNum }}</span>
            </template>
          </el-statistic>
        </div>
        <!-- 出库订单数量 -->
        <div class="cardSty" style="background-color:#996666">
          <div class="svgSty">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-dingdanguanli"></use>
            </svg>
          </div>
          <el-statistic title="出库订单数量" class="statisticSty">
            <template slot="formatter">
              <span class="font">{{ this.tableData.outNum }}</span>
            </template>
          </el-statistic>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api'
import '../../../../assets/svg-icons/icons/font_3796784_9k5g6ei7lqm/iconfont'
export default {
  name: 'recordCard',
  data () {
    return {
      tableData: {},
      systemTime: '',
      loadingCard: false
    }
  },
  created () {
    this.initList()
    this.getSystemTime()
  },
  methods: {
    async initList () {
      this.loadingCard = true
      const res = await api.getTopInfo()
      if (res.code === 200) {
        this.tableData = res.body
        this.loadingCard = false
      } else {
        this.loadingCard = false
        this.$message.error('数据加载失败')
      }
    },
    getSystemTime () {
      var myDate = new Date()
      var Y = myDate.getFullYear()
      var M = myDate.getMonth() + 1
      var D = myDate.getDate()
      var H = myDate.getHours()
      var i = myDate.getMinutes()
      var s = myDate.getSeconds()
      if (M < 10) {
        M = '0' + M
      }
      if (D < 10) {
        D = '0' + D
      }
      if (H < 10) {
        H = '0' + H
      }
      if (i < 10) {
        i = '0' + i
      }
      if (s < 10) {
        s = '0' + s
      }
      var nowDate = Y + '年' + M + '月' + D + '日' + H + ':' + i + ':' + s
      this.systemTime = nowDate
    }
  }
}
</script>
<style scoped>
::v-deep .el-statistic {
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
}
::v-deep .el-statistic .head {
  margin-bottom: 15px;
}
.dashboard-editor-container {
  margin-top: 20px;
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #ffffff;
  padding: 20px 20px 0px 0px;
  box-shadow: 0px 0px 4px #999999;
  border-radius: 5px;
}
.systemTime {
  margin-left: 20px;
  margin-bottom: 10px;
  font-weight: bolder;
  font-size: 14px;
}
.cardRules {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
}
.cardSty {
  display: flex;
  flex: 0 0 18%;
  justify-content: space-around;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-left: 40px;
}
.statisticSty {
  color: #fff;
  font-weight: bold;
  width: 60%;
}
.font {
  margin-top: 30px;
  font-weight: 700;
  color: #fff;
  font-size: 26px;
}
.svgSty {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  display: block;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}
</style>
