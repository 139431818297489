export default ({ service, request, serviceForMock, requestForMock, mock, faker, tools }) => ({
  getMessage (params = {}, data = {}) {
    return request({
      url: '/v1/menu/getMessage',
      method: 'get',
      params: params,
      data: data
    })
  }
})
