var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "damagefreightType" },
    [
      _c(
        "d2-container",
        [
          _c(
            "head-search",
            { attrs: { "label-style": "width:100px" } },
            [
              _c(
                "template",
                { slot: "button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.auth.isSearch,
                        icon: "el-icon-search",
                        type: "primary",
                        size: "small"
                      },
                      on: { click: _vm.add }
                    },
                    [_vm._v(" 新增 ")]
                  )
                ],
                1
              )
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  ref: "tableData",
                  attrs: {
                    data: _vm.typeList,
                    "max-height": "620px",
                    "element-loading-text": "数据加载中",
                    "cell-style": { textAlign: "center" },
                    "tooltip-effect": "dark",
                    "row-style": { height: "57px" },
                    "header-cell-style": {
                      background: "#dfe6ec",
                      color: "#666666",
                      textAlign: "center"
                    }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", width: "80", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (_vm.selectForm.pageNo - 1) *
                                    _vm.selectForm.pageSize +
                                    scope.$index +
                                    1
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "type", label: "创建时间", width: "150" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: _vm.auth.isSearch,
                                  type: "primary",
                                  plain: "",
                                  size: "small"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.edit(scope.row)
                                  }
                                }
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  plain: "",
                                  disabled: _vm.auth.isSearch,
                                  type: "danger",
                                  size: "small"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.del(scope.row)
                                  }
                                }
                              },
                              [_vm._v(" 删除 ")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.selectForm.pageNo,
                  "page-sizes": [10, 20, 30, 40, 50, 100],
                  "page-size": _vm.selectForm.pageSize,
                  layout: "->,total, sizes, prev, pager, next, jumper",
                  total: _vm.pageTotal
                },
                on: {
                  "size-change": _vm.sizeChange,
                  "current-change": _vm.currentChange
                }
              }),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.title,
                    visible: _vm.visible,
                    width: "500px"
                  },
                  on: { close: _vm.close }
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-width": "80px",
                        inline: false,
                        size: "normal"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "货损类型", prop: "type" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "请输入内容" },
                            model: {
                              value: _vm.form.type,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "type", $$v)
                              },
                              expression: "form.type"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: { click: _vm.onSubmit }
                            },
                            [_vm._v(" 确认 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: { click: _vm.close }
                            },
                            [_vm._v("取消")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }