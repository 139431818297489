var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goodsDetails" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("span", [_vm._v("库存明细")]),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                icon: "el-icon-s-operation"
              },
              on: { click: _vm.openHistory }
            },
            [_vm._v("历史记录")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "top" }, [
        _c(
          "div",
          { staticClass: "info" },
          [
            _c(
              "head-search",
              {
                attrs: {
                  "label-style": _vm.labelStyle,
                  "item-style": _vm.itemStyle
                }
              },
              [
                _c("head-search-item", { attrs: { label: "商品编码：" } }, [
                  _vm._v(_vm._s(_vm.goodsInfo.goodsCoding))
                ]),
                _c("head-search-item", { attrs: { label: "商品名称：" } }, [
                  _vm._v(_vm._s(_vm.goodsInfo.goodsName))
                ]),
                _c("head-search-item", { attrs: { label: "商品分类：" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.goodsInfo.goodsOne ? _vm.goodsInfo.goodsOne : "- -"
                    )
                  )
                ]),
                _c("head-search-item", { attrs: { label: "商品品牌：" } }, [
                  _vm._v(_vm._s(_vm.goodsInfo.goodsBrandName))
                ]),
                _c("head-search-item", { attrs: { label: "商品IP：" } }, [
                  _vm._v(_vm._s(_vm.goodsInfo.goodsIpName))
                ]),
                _c("head-search-item", { attrs: { label: "在库数量：" } }, [
                  _vm._v(_vm._s(_vm.goodsInfo.inventoryNum))
                ]),
                _c("head-search-item", { attrs: { label: "在途数量：" } }, [
                  _vm._v(_vm._s(_vm.goodsInfo.inTransitNum))
                ]),
                _c("head-search-item", { attrs: { label: "在库总额：" } }, [
                  _vm._v(_vm._s(_vm.goodsInfo.inventoryTotalPrice))
                ]),
                _c("head-search-item", { attrs: { label: "在途总额：" } }, [
                  _vm._v(_vm._s(_vm.goodsInfo.inTransitTotalPrice))
                ]),
                _c("head-search-item", { attrs: { label: "在库平均价：" } }, [
                  _vm._v(_vm._s(_vm.goodsInfo.inventoryAveragePrice))
                ])
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "img" }, [
          _c("div", { staticClass: "text" }, [_vm._v("商品图片:")]),
          _c(
            "div",
            { staticClass: "image" },
            [
              _vm.goodsInfo.pictureUrl
                ? _c("el-image", {
                    staticStyle: { width: "200px", height: "200px" },
                    attrs: {
                      src: _vm.$oss + _vm.goodsInfo.pictureUrl,
                      fit: "fill"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "tableContent" }, [
        _c(
          "div",
          { staticClass: "title" },
          [
            _c("span", [_vm._v("在库详情")]),
            !_vm.permission.isExport
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "small",
                      icon: "el-icon-download",
                      disabled: _vm.loading,
                      loading: _vm.loading
                    },
                    on: { click: _vm.exportExcel }
                  },
                  [_vm._v("导出")]
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table" },
          [
            _c(
              "el-table",
              { attrs: { data: _vm.goodsTable } },
              [
                _c("el-table-column", {
                  attrs: { align: "center", label: "序号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                (_vm.page.pageNo - 1) * _vm.page.pageSize +
                                  scope.$index +
                                  1
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { prop: "orgName", label: "公司名称", align: "center" }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "warehouseName",
                    label: "仓库名称",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "入库价（元）",
                    prop: "unitPrice"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "在库数量",
                    prop: "inventoryNums"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "小计",
                    prop: "inventoryTotalPrice"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    width: "180",
                    label: "最近一次入库时间",
                    prop: "createdTimestamp"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    width: "180",
                    label: "最近一次出库时间",
                    prop: "outTimestamp"
                  }
                })
              ],
              1
            ),
            _c("el-pagination", {
              staticStyle: { "margin-top": "10px" },
              attrs: {
                "page-sizes": [10, 20, 30, 40, 50, 100],
                "page-size": _vm.page.pageSize,
                layout: "->,total, sizes, prev, pager, next, jumper",
                total: _vm.pageTotal
              },
              on: {
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "details" },
          [
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "商品详情",
                  visible: _vm.dialogVisible,
                  width: "90%"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.dialogVisible = $event
                  }
                }
              },
              [
                _c(
                  "el-table",
                  {
                    attrs: {
                      data: _vm.goodsDetailsTable,
                      "row-class-name": _vm.damagefreight
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "商品条码", prop: "barCode" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "采购单号", prop: "purchaseNo" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "入库单号", prop: "intoNo" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "入库时间", prop: "createdTimestamp" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "扫码人", prop: "createdName" }
                    })
                  ],
                  1
                ),
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.detailsPage.pageNo,
                    "page-sizes": [10, 20, 30, 40, 50, 100],
                    "page-size": _vm.detailsPage.pageSize,
                    layout: "->,total, sizes, prev, pager, next, jumper",
                    total: _vm.detailsPageTotal
                  },
                  on: {
                    "current-change": _vm.detailsCurrentChange,
                    "size-change": _vm.detailsSizeChange
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-dialog",
        {
          ref: "dialogHistory",
          attrs: {
            title: "历史记录",
            width: "60%",
            "append-to-body": true,
            "close-on-click-modal": false,
            visible: _vm.dialogVisibleHistory
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleHistory = $event
            },
            close: false
          }
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingHistrory,
                  expression: "loadingHistrory"
                }
              ],
              ref: "tableData",
              attrs: {
                data: _vm.historyData,
                "element-loading-text": "数据加载中",
                "cell-style": { textAlign: "center" },
                "tooltip-effect": "dark",
                "max-height": "490",
                "row-style": { height: "57px" },
                "header-cell-style": {
                  background: "#dfe6ec",
                  color: "#666666",
                  textAlign: "center"
                }
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", width: "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              (_vm.searchHistory.pageNo - 1) *
                                _vm.searchHistory.pageSize +
                                scope.$index +
                                1
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "公司名称",
                  width: "200",
                  prop: "companyName",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  align: "center",
                  label: "所属部门",
                  prop: "deptName"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.deptName ? scope.row.deptName : "- -"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "仓库名称",
                  prop: "warehouseName",
                  width: "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "库存总数",
                  prop: "sum",
                  width: "120"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.sum ? scope.row.sum : "- -") +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "出库数量",
                  prop: "inventoryNums",
                  width: "150"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "入库单价", prop: "price", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.price ? scope.row.price : "- -") +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "margin-top": "10px" },
            attrs: {
              "current-page": _vm.searchHistory.pageNo,
              "page-sizes": [10, 20, 30, 40, 50, 100],
              "page-size": _vm.searchHistory.pageSize,
              layout: "->,total, sizes, prev, pager, next, jumper",
              total: _vm.searchHistory.sumDataCount
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }