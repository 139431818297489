var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "dashboard-editor-container" }, [
      _c(
        "div",
        { staticClass: "singSty", staticStyle: { position: "relative" } },
        [
          _c("div", { staticClass: "verticalBar" }),
          _c("span", { staticClass: "textSty" }, [_vm._v("待处理异常单")]),
          _c(
            "svg",
            {
              staticClass: "icon",
              staticStyle: {
                width: "30px",
                height: "30px",
                position: "absolute",
                top: "-35px",
                left: "100%"
              },
              attrs: { "aria-hidden": "true" }
            },
            [_c("use", { attrs: { "xlink:href": "#icon-tuding" } })]
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "whSty" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "table",
              staticStyle: {
                width: "100%",
                "margin-left": "10px",
                margin: "10px 10px 0px 10px"
              },
              attrs: {
                "header-cell-style": _vm.headerCellStyle,
                "element-loading-text": "数据加载中",
                data: _vm.tableData,
                "max-height": "390px",
                "cell-style": { textAlign: "center" },
                "row-style": { height: "60px", textAlign: "center" },
                "tooltip-effect": "dark"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "订单编号",
                  width: "130",
                  prop: "orderNumber",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "数量",
                  prop: "productNum",
                  width: "60",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "采购类型",
                  prop: "type",
                  width: "80",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建者",
                  prop: "createdName",
                  width: "70",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  prop: "createdTimestamp",
                  align: "center"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }