export default (function (_ref) {
  var request = _ref.request;
  return {
    // 分页查询list
    getPageList_purchaseStatisticalList: function getPageList_purchaseStatisticalList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/statistics/dailyPurchase',
        method: 'post',
        data: data
      });
    },
    // 分页查询汇总
    getPageList_purchaseStatisticalSum: function getPageList_purchaseStatisticalSum() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/statistics/dailyPurchaseSum',
        method: 'post',
        data: data
      });
    },
    // 商品详情
    infoPurchaseStatisticalGoods: function infoPurchaseStatisticalGoods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/statistics/purchaseList',
        method: 'post',
        data: data
      });
    },
    // 商品详情聚合统计
    infoPurchaseStatisticalGoodsSum: function infoPurchaseStatisticalGoodsSum() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/statistics/purchaseSum',
        method: 'post',
        data: data
      });
    },
    // 订单详情
    infoPurchaseStatisticalOrder: function infoPurchaseStatisticalOrder() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/statistics/dailyPurchase/orderDetail',
        method: 'post',
        data: data
      });
    },
    // 订单详情聚合统计
    infoPurchaseStatisticalOrderSum: function infoPurchaseStatisticalOrderSum() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/statistics/dailyPurchase/orderSum',
        method: 'post',
        data: data
      });
    },
    // 每日采购订单维度统计导出
    exportPurOrder: function exportPurOrder() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/statistics/exportPurOrder',
        method: 'post',
        data: data
      });
    },
    // 每日采购订单维度统计导出
    exportGoodsPurchases: function exportGoodsPurchases() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/statistics/exportGoodsPurchases',
        method: 'post',
        data: data
      });
    }
  };
});