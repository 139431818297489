<template>
    <d2-container>
        <head-search label-style='width:100px'>
            <head-search-item label='盘库单号'>
                <el-input v-model="header.checkId" size="small" placeholder="请输入盘库单号"></el-input>
            </head-search-item>
            <head-search-item label='盘点仓库'>
                <el-select v-model="header.warehouseId" class="searchInput" clearable placeholder="请选择盘点仓库"
                    size="small">
                    <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.warehouseName"
                        :value="item.warehouseId">
                    </el-option>
                </el-select>
            </head-search-item>
            <head-search-item label='盘库维度'>
                <el-select v-model="header.dimension" class="searchInput" clearable placeholder="请选择盘库维度"
                    size="small">
                    <el-option v-for="(item, index) in dimensionalityList" :key="index" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </head-search-item>
            <head-search-item label='盘库人员'>
                <el-input v-model="header.createdName" size="small" placeholder="请输入盘库人员"></el-input>
            </head-search-item>
            <head-search-item label='提交时间'>
                <el-date-picker v-model="header.time" end-placeholder="结束日期" range-separator="至" size="small"
                    start-placeholder="开始日期" type="daterange" value-format="yyyy-MM-dd"></el-date-picker>
            </head-search-item>
            <head-search-item label='审核时间'>
                <el-date-picker v-model="header.time1" end-placeholder="结束日期" range-separator="至" size="small"
                    start-placeholder="开始日期" type="daterange" value-format="yyyy-MM-dd"></el-date-picker>
            </head-search-item>
            <div class="searchBtn">
                <el-button icon="el-icon-search" size="small" type="primary" @click="toQuery()">搜索</el-button>
                <el-button icon="el-icon-refresh" size="small" type="warning" @click="resetBtn()">重置</el-button>
            </div>
        </head-search>
        <el-table :data="tableData" :header-cell-style="headerClass" :cell-style="rowClass" v-loading="loading">
            <el-table-column label="序号">
                <template slot-scope="scope">
                    {{ (search.pageNo - 1) * search.pageSize + scope.$index + 1 }}
                </template>
            </el-table-column>
            <el-table-column prop="createdTimestamp" label="提交时间"></el-table-column>
            <el-table-column prop="checkId" label="盘库单号"></el-table-column>
            <el-table-column label="盘库维度">
                <template slot-scope="scope">
                    {{ scope.row.dimension == 0 ? '商品维度' : scope.row.dimension == 1 ? '货架维度' : '' }}
                </template>
            </el-table-column>
            <!-- <el-table-column prop="checkIdentify" label="盘库结果">
                <template slot-scope="scope">
                    <el-tag> {{ almightyM(scope.row.checkIdentify, '盘库结果') }}</el-tag>
                </template>
            </el-table-column> -->
            <el-table-column prop="companyName" label="公司名称"></el-table-column>
            <el-table-column prop="warehouseName" label="盘点仓库"></el-table-column>
            <el-table-column label="审核状态">
                <template slot-scope="scope">
                    <el-tag> {{ almightyM(scope.row.auditStatus, '审核状态') }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="auditTimestamp" label="审核时间"></el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <el-button type="primary" plain size="small" v-if="scope.row.auditStatus != 1"
                        @click="auditBtn(scope.row)">审核</el-button>
                    <el-button type="info" plain size="small" @click="goodsDetails(scope.row.id)">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--      分页      -->
        <div style="display: flex; justify-content: right; margin-top: 10px">
            <el-pagination v-if="search.pageSize > 0" :current-page="search.pageNo" :page-size="search.pageSize"
                :page-sizes="[10, 20, 30, 40, 50, 100]" :total="search.pageTotal"
                layout="total, jumper, prev, pager, next,sizes" @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
            </el-pagination>
        </div>
        <el-dialog :visible.sync=auditLog width="600px" title="设置" :close-on-click-modal="false" @close="eliminate">
            <el-form>
                <el-form-item label="审核">
                    <div>
                        <el-radio v-model="form.radio" :label=1>通过</el-radio>
                        <el-radio v-model="form.radio" :label=2>不通过</el-radio>
                    </div>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input type="textarea" v-model="form.text" placeholder=""></el-input>
                </el-form-item>
                <div style="margin:auto;width:123px">
                    <el-button size="small" @click="eliminate()">关闭</el-button>
                    <el-button size="small" type="success" plain @click="submitBtn()">提交</el-button>
                </div>
            </el-form>
        </el-dialog>
    </d2-container>
</template>

<script>
const headerSource = {
    checkId: null,
    warehouseIds: null,
    dimension: null,
    createdName: null,
    time: [],
    time1: [],
}
let searchSource = {
    ipName: '',
    pageNo: 1,
    pageSize: 50,
    pageTotal: 0
}
import api from '@/api'
import *as getListM from '@/components/getListM.js'
export default {
    data() {
        return {
            loading: false,
            id: null,
            form: {
                radio: 1,
                text: null,
            },
            auditLog: false,
            header: JSON.parse(JSON.stringify(headerSource)),
            search: JSON.parse(JSON.stringify(searchSource)),
            tableData: [],
            warehouseList: [],
            dimensionalityList: [
                { value: 0, label: '商品维度' },
                { value: 1, label: '货架维度' },
            ],
        }
    },
    async created() {
        this.warehouseList = await getListM.getWarehouse()//仓库名称
        this.initialize()
    },
    methods: {
        async submitBtn() {
            const res = await api.affirmAudit({
                "auditRemark": this.form.text,
                "auditStatus": this.form.radio,
                "id": this.id
            })
            try {
                this.$message({
                    type: 'success',
                    message: '审核成功!'
                })
                this.eliminate()
            } catch (err) {

            }
        },
        eliminate() {
            this.auditLog = false
            this.form.radio = 1
            this.form.text = null
        },
        auditBtn(e) {
            this.auditLog = true
            this.id = e.id
        },
        toQuery() {
            this.initialize()
        },
        resetBtn() {
            this.header = JSON.parse(JSON.stringify(headerSource)),
            this.initialize()
        },
        async initialize() {
            this.loading = true
            const res = await api.getAuditList({
                "checkId": this.header.checkId,
                "createdName": this.header.createdName,
                "dimension": this.header.dimension,
                // "field": "",
                "leftAuditTimestamp": this.header.time1 && this.header.time1.length != 0 ? this.header.time1[0] : null,
                "leftSubmitTimestamp": this.header.time && this.header.time.length != 0 ? this.header.time1[0] : null,
                "pageNo": this.search.pageNo,
                "pageSize": this.search.pageSize,
                "rightAuditTimestamp": this.header.time1 && this.header.time1.length != 0 ? this.header.time1[1] : null,
                "rightSubmitTimestamp": this.header.time && this.header.time.length != 0 ? this.header.time1[1] : null,
                // "sort": 0,
                // "type": 0,
                "warehouseId": this.header.warehouseIds
            })
            try {
                this.tableData = res.body.datas
                this.search.pageTotal = res.body.sumDataCount
                this.loading = false
            } catch (err) {

            }
        },
        almightyM(e, t) {
            if (t == '审核状态') {
                if (e == 0) {
                    return '待审核'
                } else if (e == 1) {
                    return '审核通过'
                } else if (e == 2) {
                    return '审核不通过'
                }
            }
        },
        // table样式
        headerClass() {
            return "background: #eef1f6; color: #606266;text-align:center"
        },
        rowClass() {
            return "text-align:center"
        },
        handleSizeChange(val) {
            this.search.pageSize = val
            this.initialize()
        },
        handleCurrentChange(val) {
            this.search.pageNo = val
            this.initialize()
        },
        // 明细
        async goodsDetails(row) {
            await this.$router.push({
                path: '/auditListParticulars',
                query: { id: row }
            })
        }
    }
}
</script>

<style lang='scss' scoped>
.searchBtn {
    margin-left: 20px;
    margin-bottom: 10px;
}
</style>