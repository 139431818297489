export default (function (_ref) {
  var request = _ref.request;
  return {
    // 商品出入库汇总
    getCommoditySummaryStatementList: function getCommoditySummaryStatementList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/newStatical/getInvResult',
        method: 'post',
        data: data
      });
    },
    // 数据统计
    getCommoditySummaryStatementCount: function getCommoditySummaryStatementCount() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/newStatical/getInvAggregate',
        method: 'post',
        data: data
      });
    }
  };
});