<template>
  <!-- 如果内容比较多可以添加top属性 -->
  <el-dialog
  :append-to-body="true"
    :title="title"
    :visible.sync="dialogVisible"
    width="40%"
    :close-on-click-modal="false"
    :before-close="handleClose">
    <el-form ref="form" status-icon :model="form" :rules="rules" label-width="80px">
      <el-form-item label="枚举名称" prop="name">
        <el-input size="small" clearable  style="width:70%" :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="枚举Key" prop="keys">
        <el-input size="small" clearable style="width:70%" :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.keys" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="枚举值" prop="values">
        <el-input size="small" clearable style="width:70%" :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.values" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input size="small" clearable style="width:70%" :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.remarks" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div class="bottomDiv">
      <el-button size="small" @click="handleClose">关闭</el-button>
      <el-button size="small" :disabled="disabled" type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  name: 'EnumerationEdit',
  data () {
    return {
      title: '',
      form: {
        name: '',
        values: '',
        keys: '',
        id: null,
        remarks: ''
      },
      rules: {
        values: [
          { required: true, message: '请输入枚举值', trigger: 'blur' }
        ],
        keys: [
          { required: true, message: '请输入枚举Key', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入枚举名称', trigger: 'blur' }
        ],
        remarks: [
          { required: true, message: '请输入枚举名称', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    setTitle (title) {
      this.title = title
    },
    initData () {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields()
      }
      this.form.name = null
      this.form.values = null
      this.form.keys = null
      this.form.id = null
      this.form.remarks = null
    },
    setData (data) {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields()
      }
      this.form.name = data.name
      this.form.values = data.values
      this.form.keys = data.keys
      this.form.id = data.id
      this.form.remarks = data.remarks
    },
    async updateApi () {
      await api.UPDATE_ENUMERATION(this.form)
      this.$emit('initTableData')
      this.$message.success('操作成功')
      this.handleClose()
    },
    async saveApi () {
      await api.SAVE_ENUMERATION(this.form)
      this.$emit('initTableData')
      this.$message.success('操作成功')
      this.handleClose()
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id != null) {
            this.$confirm('此操作将永久修改该数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.updateApi()
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消修改'
              })
            })
          } else {
            this.saveApi()
          }
        } else {
          return false
        }
      })
    },
    handleClose (done) {
      this.$emit('close')
    }
  }
}
</script>

<style>
  .inputText {
    width: 70%;
  }
  .bottomDiv {
    width: 100%;
    text-align: center;
  }
</style>
