//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      detailsList: [],
      errorList: [],
      errorVisible: false
    };
  },
  props: {
    logInfo: {
      type: Object
    }
  },
  methods: {}
};