/**
 * < 应用管理请求接口
 * @author 王振宇
 * @since 2021-05-15
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_APPS_PAGE_LIST: function GET_APPS_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/apps/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_APPS_LIST: function GET_APPS_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/apps/getList',
        method: 'get',
        params: data
      });
    },
    GET_APPS_INFO: function GET_APPS_INFO() {
      return request({
        url: '/v1/apps/info',
        method: 'get'
      });
    },
    SAVE_APPS: function SAVE_APPS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/apps/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_APPS: function UPDATE_APPS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/apps/update',
        method: 'put',
        data: data
      });
    },
    DELETE_APPS: function DELETE_APPS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/apps/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_APPS: function DELETE_BATCH_APPS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/apps/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});