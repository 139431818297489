export default (function (_ref) {
  var request = _ref.request;
  return {
    // 获取采购类型、采购渠道
    getPurchaseSelect: function getPurchaseSelect() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/enumeration/getList',
        method: 'post',
        data: data
      });
    },
    // 获取采购人员信息
    getPurchaseUser: function getPurchaseUser() {
      return request({
        url: '/v1/user/selectUser',
        method: 'post'
      });
    },
    // 获取仓库
    enumeration_exportExcel: function enumeration_exportExcel(data) {
      return request({
        url: '/v1/audit/auditExport',
        method: 'post',
        data: data
      });
    },
    // 获取仓库
    getPurchaseWarehouse: function getPurchaseWarehouse() {
      return request({
        url: '/v1/warehouse/selectUserWarehouse',
        method: 'post'
      });
    },
    // 获取来源仓库
    getPurchaseSourceWarehouseIds: function getPurchaseSourceWarehouseIds() {
      return request({
        url: '/v1/warehouse/selectUserOrgWarehouse',
        method: 'post'
      });
    },
    // 分页查询
    getPageList_prurchasingList: function getPageList_prurchasingList() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return request({
        url: '/v1/purchase/getPageList',
        method: 'post',
        params: params,
        data: query
      });
    },
    // 查询商品
    chooseProductPageList: function chooseProductPageList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/goods/chooseProductPageList',
        method: 'post',
        data: data
      });
    },
    // 新增保存
    save_purchase: function save_purchase() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/purchase/save',
        method: 'post',
        data: query
      });
    },
    // 编辑保存
    update_purchase: function update_purchase() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/purchase/update',
        method: 'put',
        data: query
      });
    },
    // 查询详情
    info_purchase: function info_purchase() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/purchase/info',
        method: 'get',
        params: params
      });
    },
    // 终止采购
    end_purchase: function end_purchase() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/purchase/end',
        method: 'post',
        data: query
      });
    },
    // 调拨确认
    affirm_purchase: function affirm_purchase() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/purchase/confirmAllocate',
        method: 'post',
        params: params
      });
    },
    // 查询物流
    getLogistics: function getLogistics() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/getLogistics',
        method: 'get',
        params: params
      });
    },
    // 处理明细
    deal_purchase: function deal_purchase() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/intoDetail/deal',
        method: 'post',
        data: query
      });
    },
    // 获取:我的入库价 我的库存数量 我的在途数量
    getMineProductInfo: function getMineProductInfo() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/goods/getMineProductInfo',
        method: 'post',
        data: query
      });
    },
    copyPurchaseOrder: function copyPurchaseOrder() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/purchase/copyPurchaseOrder',
        method: 'post',
        data: query
      });
    },
    // 采购随时添加图片
    purchasingListSaveImg: function purchasingListSaveImg() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/purchase/putPictureUrl',
        method: 'post',
        data: query
      });
    },
    // 采购处理查询
    queryProductById: function queryProductById() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/intoDetail/queryProductById',
        method: 'post',
        data: query
      });
    }
  };
});