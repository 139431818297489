<template>
  <div class="orderInfo">
    <d2-container>
      <head-search label-style="width:100px">
        <!-- <head-search-item label="出库类型">
          <el-select size="small" multiple collapse-tags v-model="selectForm.typeList" placeholder="请输入出库类型" clearable>
            <el-option
              :key="0"
              label="调拨"
              :value="0">
            </el-option>
            <el-option
              :key="1"
              label="代发"
              :value="1">
            </el-option>
            <el-option
              :key="2"
              label="销售"
              :value="2">
            </el-option>
            <el-option
              :key="3"
              label="其他"
              :value="3">
            </el-option>
            <el-option
              :key="5"
              label="货损"
              :value="5">
            </el-option>
          </el-select>
        </head-search-item> -->
        <head-search-item label="出库状态">
          <el-select size="small" multiple collapse-tags v-model="selectForm.statusList" placeholder="请输入出库状态"
            clearable>
            <el-option :key="0" label="待出库" :value="0">
            </el-option>
            <el-option :key="1" label="出库中" :value="1">
            </el-option>
            <el-option :key="2" label="部分出库" :value="2">
            </el-option>
            <el-option :key="3" label="全部出库" :value="3">
            </el-option>
            <el-option :key="4" label="终止出库" :value="4">
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="出库来源">
          <el-select size="small" multiple collapse-tags v-model="selectForm.sourceList" placeholder="请输入出库来源"
            clearable>
            <el-option v-for="item in sourceList" :key="item.id" :label="item.keys" :value="item.id">
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="代发单号">
          <el-input v-model="selectForm.outId" clearable placeholder="请输入代发单号" size="small"></el-input>
        </head-search-item>
        <head-search-item label="制单人员">
          <el-select v-model="selectForm.createIds" clearable collapse-tags multiple placeholder="请选择制单人员" size="small">
            <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="仓库名称">
          <el-select v-model="selectForm.warehouseIds" clearable collapse-tags multiple placeholder="请选择仓库名称"
            size="small">
            <el-option v-for="item in warehouseIdsData" :key="item.warehouseId" :label="item.warehouseName"
              :value="item.warehouseId"></el-option>
          </el-select>
        </head-search-item>
        <template slot="button">
          <el-button icon="el-icon-search" size="small" type="primary" @click="search">
            搜索
          </el-button>
          <el-button icon="el-icon-refresh" size="small" type="warning" @click="reset">
            重置
          </el-button>
          <el-button :disabled="excelLoading" :loading="excelLoading" icon="el-icon-download" size="small"
            type="success" @click="exportExcel">
            导出
          </el-button>
        </template>
      </head-search>
      <div v-loading="loadingSum" class="showNum">
        <div class="font">
          商品金额 <span class="num">{{ this.sumData.totalPrice }}</span>
        </div>
        <div class="font">
          商品数量 <span class="num">{{ this.sumData.goodsNum }}</span>
        </div>
        <!--        <div class="font">-->
        <!--          订单数量 <span class="num">{{ this.sumData.sumAll }}</span>-->
        <!--        </div>-->
      </div>
      <div class="table">
        <el-table ref="tableData" v-loading="tableLoading" :cell-style="{ textAlign: 'center' }" :data="orderInfoList"
          :header-cell-style="{
            background: '#dfe6ec',
            color: '#666666',
            textAlign: 'center'
          }" :row-style="{ height: '57px' }" element-loading-text="数据加载中" max-height="620px" tooltip-effect="dark">
          <el-table-column align="center" label="序号" width="80">
            <template slot-scope="scope">
              {{
            (selectForm.pageNo - 1) * selectForm.pageSize + scope.$index + 1
          }}
            </template>
          </el-table-column>
          <el-table-column label="代发单号" prop="outId" width="150"></el-table-column>
          <el-table-column label="商品数量" prop="subNum" width="150"></el-table-column>
          <el-table-column label="商品总价" prop="intoPrice" width="150"></el-table-column>
          <el-table-column label="出库类型" prop="typeName" width="150"></el-table-column>
          <el-table-column label="来源" prop="sourceName" width="150"></el-table-column>
          <el-table-column label="付款方式" prop="payMethod" width="150">
            <template slot-scope="scope">
              {{ payWay(scope.row.payMethod) }}
            </template>
          </el-table-column>
          <el-table-column label="出库状态" prop="statusName" width="150">
          </el-table-column>
          <el-table-column label="公司名称" prop="companyName" width="150"></el-table-column>
          <el-table-column label="部门名称" prop="deptName" width="150"></el-table-column>
          <el-table-column label="仓库名称" prop="areaName" width="150"></el-table-column>
          <el-table-column label="制单人员" prop="createdName" width="150">
            <template slot-scope="scope">
              {{ scope.row.createdName ? scope.row.createdName : '- -' }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :current-page="selectForm.pageNo" :page-size="selectForm.pageSize"
          :page-sizes="[10, 20, 30, 40, 50, 100]" :total="selectForm.pageTotal"
          layout="->,total, sizes, prev, pager, next, jumper" @size-change="sizeChange"
          @current-change="currentChange"></el-pagination>
      </div>
    </d2-container>
  </div>
</template>

<script>
import api from '@/api'
import { downloadFileByUrl } from '@/utils/index.js'

const selectFormData = {
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0,
  outId: '',
  createIds: [],
  warehouseIds: [],
  date: '',
  statusList: [],
  typeList: [],
  sourceList: []
}
const sumDataDefault = {
  goodsNum: 0,
  totalPrice: 0
}
export default {
  name: 'generationStatisticalOrderInfo',
  props: {},
  data() {
    return {
      sourceList: [],
      selectForm: JSON.parse(JSON.stringify(selectFormData)),
      sumData: JSON.parse(JSON.stringify(sumDataDefault)),
      orderInfoList: [],
      warehouseIdsData: [],
      tableLoading: false,
      loadingSum: false,
      excelLoading: false,
      userList: [],
      statusData: [
        {
          id: 0,
          value: '待采购'
        },
        {
          id: 1,
          value: '采购中'
        },
        {
          id: 2,
          value: '已完成'
        }
        // {
        //   id: 9,
        //   value: '已终止'
        // }
      ],
      deliveryMethodsData: [
        {
          id: 0,
          value: '入库'
        },
        {
          id: 1,
          value: '代发'
        }
      ]
    }
  },
  computed: {},
  watch: {
    $route(to, from) {
      this.$route.query.date = to.query.date
      this.getList()
      this.getSum()
      this.getWarehouse()
      this.getSource()
    }
  },
  created() {
    this.$store.commit('d2admin/page/modifyOpend', opened => {
      opened.forEach((current) => {
        if (current.name === 'generationStatisticalOrderInfo') {
          current.meta.title = '代发统计-订单-' + current.query.date
        }
      })
    })
    this.getList()
    this.getSum()
    this.getUser()
    this.getWarehouse()
    this.getSource()
  },
  methods: {
    payWay(e) {
      if (e == 0) {
        return '对公'
      } else if (e == 1) {
        return '备用金'
      } else if (e == 2) {
        return '备用金代付'
      } else if (e == 3) {
        return '张总'
      } else if (e == 4) {
        return '上海实体店备用金'
      } else if (e == 5) {
        return '现金'
      } else if (e == 6) {
        return '上海店现金'
      } else if (e == 7) {
        return '日本备用金'
      } else if (e == 8) {
        return '挂账'
      }else if (e == 9) {
        return '日币现金'
      }else if (e == 10) {
        return '法人信用卡'
      }else if (e == 11) {
        return '员工垫付'
      }else if (e == 12) {
        return '公司转账'
      }else if (e == 13) {
        return '公司转账'
      }
    },
    async getSource() {
      const source = await api.enumeration_getList({ name: 'out_source' })
      this.sourceList = source.body
    },
    search() {
      this.selectForm.pageNo = 1
      this.getList()
      this.getSum()
      this.getUser()
      this.getWarehouse()
    },
    // 获取用户
    async getUser() {
      const { body: data } = await api.statisticalUser()
      this.userList = data
    },
    async getWarehouse() {
      const { body: data } = await api.getPurchaseWarehouse()
      this.warehouseIdsData = data
    },
    async getList() {
      try {
        const res = await api.generationOrderLimit({
          pageNo: 1,
          pageSize: 50,
          createIds: this.selectForm.createIds,
          startTime: this.$route.query.date,
          outId: this.selectForm.outId,
          warehouseIds: this.selectForm.warehouseIds,
          statusList: this.selectForm.statusList,
          typeList: this.selectForm.typeList,
          sourceList: this.selectForm.sourceList
        })
        this.orderInfoList = res.body.datas
        this.selectForm.pageNo = res.body.pageNo
        this.selectForm.pageSize = res.body.pageSize
        this.selectForm.pageTotal = res.body.sumDataCount
        console.log(res.body.datas)
      } catch (error) {
        this.$message.error('加载数据失败')
      } finally {
        this.tableLoading = false
      }
    },
    async getSum() {
      this.loadingSum = true
      try {
        const res = await api.generationAggOrder({
          pageNo: 1,
          pageSize: 50,
          createIds: this.selectForm.createIds,
          startTime: this.$route.query.date,
          outId: this.selectForm.outId,
          warehouseIds: this.selectForm.warehouseIds,
          statusList: this.selectForm.statusList,
          typeList: this.selectForm.typeList,
          sourceList: this.selectForm.sourceList
        })
        this.sumData.totalPrice = res.body.totalPrice ? res.body.totalPrice : 0
        this.sumData.goodsNum = res.body.goodsNum ? res.body.goodsNum : 0
      } catch (error) {
        this.$message.error('聚合统计加载失败')
        this.sumData.totalPrice = 0
        this.sumData.goodsNum = 0
      } finally {
        this.loadingSum = false
      }
    },
    reset() {
      this.selectForm = JSON.parse(JSON.stringify(selectFormData))
      this.getList()
      this.getSum()
    },
    sizeChange(val) {
      this.selectForm.pageSize = val
      this.getList()
      this.getSum()
    },
    currentChange(val) {
      this.selectForm.pageNo = val
      this.getList()
      this.getSum()
    },
    async exportExcel() {
      this.excelLoading = true
      try {
        const res = await api.generationExportOrder({
          pageNo: 1,
          pageSize: 50,
          createIds: this.selectForm.createIds,
          startTime: this.$route.query.date,
          outId: this.selectForm.outId,
          warehouseIds: this.selectForm.warehouseIds,
          statusList: this.selectForm.statusList,
          typeList: this.selectForm.typeList,
          sourceList: this.selectForm.sourceList
        })
        downloadFileByUrl(res.body)
      } catch (e) {
        this.$message.error('导出报表失败')
      } finally {
        this.excelLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.orderInfo {}

.showNum {
  width: 30%;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  display: flex;
  justify-content: space-around;
  line-height: 30px;
  border: 2px solid #999;
  border-radius: 5px;
}

.font {
  font-weight: bolder;
  font-size: 16px;
}

.num {
  margin-left: 20px;
  font-weight: normal;
}
</style>
