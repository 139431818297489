<template>
  <div class="orderInfo">
    <d2-container>
      <head-search label-style="width:100px">
        <head-search-item label="出库单号">
          <el-input
            v-model="selectForm.outId"
            clearable
            placeholder="请输入出库单号"
            size="small"
            @keyup.enter.native="search"
          ></el-input>
        </head-search-item>
        <head-search-item label="出库类型">
          <el-select
            v-model="selectForm.types"
            clearable
            collapse-tags
            multiple
            placeholder="请选择出库类型"
            size="small"
          >
            <el-option
              v-for="item in warehousingType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="出库来源">
          <el-select
            v-model="selectForm.sources"
            clearable
            collapse-tags
            multiple
            placeholder="请选择出库来源"
            size="small"
          >
            <el-option
              v-for="item in warehouseSource"
              :key="item.id"
              :label="item.values"
              :value="item.id"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="出库状态">
          <el-select
            v-model="selectForm.status"
            clearable
            collapse-tags
            multiple
            placeholder="请选择出库状态"
            size="small"
          >
            <el-option
              v-for="item in statusData"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="仓库名称">
          <el-select
            v-model="selectForm.warehouseIds"
            clearable
            collapse-tags
            multiple
            placeholder="请选择仓库名称"
            size="small"
          >
            <el-option
              v-for="item in warehousingWarehouse"
              :key="item.warehouseId"
              :label="item.warehouseName"
              :value="item.warehouseId"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="制单人员">
          <el-select
            v-model="selectForm.createdIds"
            clearable
            collapse-tags
            multiple
            placeholder="请选择制单人员"
            size="small"
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </head-search-item>
        <template slot="button">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
          >
            搜索
          </el-button>
          <el-button
            icon="el-icon-refresh"
            size="small"
            type="warning"
            @click="reset"
          >
            重置
          </el-button>
          <el-button
            :disabled="excelLoading"
            :loading="excelLoading"
            icon="el-icon-download"
            size="small"
            type="success"
            @click="exportExcel"
          >
            导出
          </el-button>
        </template>
      </head-search>
      <div v-loading="loadingSum" class="showNum">
        <div class="font">
          出库总数量<span class="num">{{ this.sumData.inventoryNums }}</span>
        </div>
        <div class="font">
          商品总出库价格<span class="num">{{ this.sumData.price }}</span>
        </div>
        <div class="font">
          入库价格 <span class="num">{{ this.sumData.unitPrice }}</span>
        </div>
      </div>
      <div class="table">
        <el-table
          ref="tableData"
          v-loading="tableLoading"
          :cell-style="{ textAlign: 'center' }"
          :data="orderInfoList"
          :header-cell-style="{
            background: '#dfe6ec',
            color: '#666666',
            textAlign: 'center'
          }"
          :row-style="{ height: '57px' }"
          element-loading-text="数据加载中"
          max-height="620px"
          tooltip-effect="dark"
        >
          <el-table-column align="center" label="序号" width="80">
            <template slot-scope="scope">
              {{
                (selectForm.pageNo - 1) * selectForm.pageSize + scope.$index + 1
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="出库单号"
            prop="outId"
            width="130"
          ></el-table-column>
          <el-table-column
            label="父订单号"
            prop="fatherId"
            width="130"
          >
            <template slot-scope="scope">
              {{ scope.row.fatherId !== '' ? scope.row.fatherId : '- -' }}
            </template>
          </el-table-column>
          <el-table-column
            label="总出库价"
            prop="sumPrice"
            width="120"
          >
          </el-table-column>
          <el-table-column
            label="入库总价"
            prop="intoPrice"
            width="120"
          ></el-table-column>
          <el-table-column
            label="出库状态"
            prop="status"
            width="150"
          >
            <template slot-scope="scope">
              {{
                scope.row.status == 2 ? '部分出库' : scope.row.status == 3 ? '全部出库' : '- -'
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="出库类型"
            prop="type"
            width="150"
          >
            <template slot-scope="scope">
              {{
                scope.row.type == 0 ? '调拨出库' : scope.row.type == 1 ? '代发出库' : scope.row.type == 2 ? '销售出库' : scope.row.type == 3 ? '其他出库' : scope.row.type == 4 ? '货损出库' : '- -'
              }}
            </template>
          </el-table-column>
          <el-table-column
            :formatter="getNameBySource"
            label="订单来源"
            prop="sourceName"
            width="150"
          >
          </el-table-column>
          <el-table-column
            label="公司名称"
            prop="orgName"
            width="150"
          ></el-table-column>
          <el-table-column
            label="部门名称"
            prop="deptName"
            width="150"
          ></el-table-column>
          <el-table-column
            label="仓库名称"
            prop="warehouseName"
            width="150"
          ></el-table-column>
          <el-table-column
            label="制单人员"
            prop="createdName"
            width="150"
          >
            <template slot-scope="scope">
              {{ scope.row.createdName ? scope.row.createdName : '- -' }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="selectForm.pageNo"
          :page-size="selectForm.pageSize"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :total="selectForm.pageTotal"
          layout="->,total, sizes, prev, pager, next, jumper"
          @size-change="sizeChange"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </d2-container>
  </div>
</template>

<script>
import api from '@/api'
import { downloadFileByUrl } from '@/utils/index.js'

const selectFormData = {
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0,
  createdIds: [],
  endTime: '',
  field: '',
  outId: '',
  sort: 0,
  sources: [],
  startTime: '',
  types: [],
  warehouseIds: []
}
const sumDataDefault = {
  inventoryNums: 0,
  price: 0,
  unitPrice: 0
}
export default {
  name: 'warehouseStatisticalOrdersInfo',
  props: {},
  data () {
    return {
      selectForm: JSON.parse(JSON.stringify(selectFormData)),
      sumData: JSON.parse(JSON.stringify(sumDataDefault)),
      orderInfoList: [],
      tableLoading: false,
      loadingSum: false,
      excelLoading: false,
      userList: [],
      warehouseSource: [],
      warehousingWarehouse: [],
      payMethodList: [
        {
          id: '0',
          value: '对公'
        },
        {
          id: '1',
          value: '备用金'
        },
        {
          id: '2',
          value: '备用金代付'
        },
        {
          id: '3',
          value: '张总'
        },
        {
          id: '4',
          value: '上海实体店备用金'
        },
        { id: '5', value: '现金' },
        { id: '6', value: '上海店现金' },
        { id: '7', value: '日本备用金' },
        { id: '8', value: '挂账' },
        { id: '9', value: '日币现金' },
        { id: '10', value: '法人信用卡' },
        { id: '11', value: '员工垫付' },
        { id: '12', value: '公司转账' },
        { id: '13', value: '人民币转账' }
      ],
      statusData: [
        {
          id: 2,
          value: '部分出库'
        },
        {
          id: 3,
          value: '全部出库'
        }
      ],
      warehousingType: [
        // {
        //   label: '调拨出库',
        //   value: 0
        // },
        // {
        //   label: '代发出库',
        //   value: 1
        // },
        // {
        //   label: '销售出库',
        //   value: 2
        // },
        {
          label: '其他出库',
          value: 3
        },
        // {
        //   label: '货损出库',
        //   value: 4
        // }
      ]
    }
  },
  computed: {},
  watch: {
    $route (to, from) {
      this.$route.query.date = to.query.date
      this.getList()
      this.getSum()
      this.warehouse()
      this.getOutSourceList()
      this.getUser()
    }
  },
  created () {
    this.$store.commit('d2admin/page/modifyOpend', opened => {
      opened.forEach((current) => {
        if (current.name === 'deliveryStatisticalOrderInfo') {
          current.meta.title = '出库统计-订单-' + current.query.date
        }
      })
    })
    this.getList()
    this.getSum()
    this.warehouse()
    this.getOutSourceList()
    this.getUser()
  },
  methods: {
    search () {
      this.selectForm.pageNo = 1
      this.getList()
      this.getSum()
    },
    async getOutSourceList () {
      const { body: data } = await api.enumeration_getList({
        name: 'out_source'
      })
      this.warehouseSource = data
    },
    // 获取用户
    async getUser () {
      const { body: data } = await api.statisticalUser()
      this.userList = data
    },
    // 获得所有仓库
    async warehouse () {
      const { body: data } = await api.getPurchaseWarehouse()
      this.warehousingWarehouse = data
    },
    async getList () {
      this.tableLoading = true
      try {
        const res = await api.infoDeliveryStatisticalOrder({
          pageNo: 1,
          pageSize: 50,
          createdIds: this.selectForm.createdIds,
          endTime: this.$route.query.date,
          intoNo: this.selectForm.intoNo,
          payMethod: this.selectForm.payMethod,
          sources: this.selectForm.sources,
          startTime: this.$route.query.date,
          types: this.selectForm.types,
          warehouseIds: this.selectForm.warehouseIds,
          status:this.selectForm.status,
          outId:this.selectForm.outId,
        })
        this.orderInfoList = res.body.datas
        this.selectForm.pageNo = res.body.pageNo
        this.selectForm.pageSize = res.body.pageSize
        this.selectForm.pageTotal = res.body.sumDataCount
      } catch (error) {
        this.$message.error('加载数据失败')
      } finally {
        this.tableLoading = false
      }
    },
    async getSum () {
      this.loadingSum = true
      try {
        const res = await api.infoDeliveryStatisticalOrderSum({
          pageNo: 1,
          pageSize: 50,
          createdIds: this.selectForm.createdIds,
          endTime: this.$route.query.date,
          intoNo: this.selectForm.intoNo,
          payMethod: this.selectForm.payMethod,
          sources: this.selectForm.sources,
          startTime: this.$route.query.date,
          types: this.selectForm.types,
          warehouseIds: this.selectForm.warehouseIds,
          status:this.selectForm.status,
          outId:this.selectForm.outId,
        })
        this.sumData.inventoryNums = res.body.inventoryNums ? res.body.inventoryNums : 0
        this.sumData.price = res.body.price ? res.body.price : 0
        this.sumData.unitPrice = res.body.unitPrice ? res.body.unitPrice : 0
      } catch (error) {
        this.$message.error('聚合统计数据加载失败')
        this.sumData.inventoryNums = 0
        this.sumData.price = 0
        this.sumData.unitPrice = 0
      } finally {
        this.loadingSum = false
      }
    },
    reset () {
      this.selectForm = JSON.parse(JSON.stringify(selectFormData))
      this.getList()
      this.getSum()
    },
    getNameBySource (row, column, cellValue, index) {
      // console.log(row.source, column, cellValue, index)
      let name = ''
      this.warehouseSource.forEach((item) => {
        if (row.source === item.id) {
          // console.log(item.values)
          name = item.values
        }
      })
      return name
    },
    sizeChange (val) {
      this.selectForm.pageSize = val
      this.getList()
      this.getSum()
    },
    currentChange (val) {
      this.selectForm.pageNo = val
      this.getList()
      this.getSum()
    },
    async exportExcel () {
      this.excelLoading = true
      try {
        const res = await api.exportOutOrder({
          pageNo: this.selectForm.pageNo,
          pageSize: this.selectForm.pageSize,
          endTime: this.$route.query.date,
          field: '',
          sort: 0,
          startTime: this.$route.query.date,
          createdIds: this.selectForm.createdIds,
          outId: this.selectForm.outId,
          sources: this.selectForm.sources,
          status: this.selectForm.status,
          types: this.selectForm.types,
          warehouseIds: this.selectForm.warehouseIds
        })
        downloadFileByUrl(res.body)
      } catch (e) {
        this.$message.error('导出报表失败')
      } finally {
        this.excelLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.orderInfo {
}

.showNum {
  width: 30%;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  display: flex;
  justify-content: space-around;
  line-height: 30px;
  border: 2px solid #999;
  border-radius: 5px;
}

.font {
  font-weight: bolder;
  font-size: 16px;
}

.num {
  margin-left: 20px;
  font-weight: normal;
}
</style>
