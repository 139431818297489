export default ({ request }) => ({
  // 分页查询list
  getPageList_deliveryStatisticalList (data = {}) {
    return request({
      url: '/v1/statistics/dailyOut',
      method: 'post',
      data: data
    })
  },
  // 聚合统计
  getPageList_deliveryStatisticalSum (data = {}) {
    return request({
      url: '/v1/statistics/dailyOutSum',
      method: 'post',
      data: data
    })
  },
  // 出库商品
  infoDeliveryStatisticalGoods (data = {}) {
    return request({
      url: '/out/statical/goodsVo',
      method: 'post',
      data: data
    })
  },
  // 出库商品聚合统计
  infoDeliveryStatisticalGoodsSum (data = {}) {
    return request({
      url: '/out/statical/goodsVoSum',
      method: 'post',
      data: data
    })
  },
  // 出库订单
  infoDeliveryStatisticalOrder (data = {}) {
    return request({
      url: '/out/statical/orderVo',
      method: 'post',
      data: data
    })
  },
  // 出库订单聚合统计
  infoDeliveryStatisticalOrderSum (data = {}) {
    return request({
      url: '/out/statical/orderVoSum',
      method: 'post',
      data: data
    })
  },
  // 出库商品维度导出
  exportOutGoods (data = {}) {
    return request({
      url: '/out/statical/exportOutGoods',
      method: 'post',
      data: data
    })
  },
  // 出库订单维度导出
  exportOutOrder (data = {}) {
    return request({
      url: '/out/statical/exportOutOrder',
      method: 'post',
      data: data
    })
  }
})
