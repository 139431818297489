var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.titleName,
            "close-on-click-modal": false,
            visible: _vm.visibleDialog,
            width: "92%"
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleDialog = $event
            },
            close: _vm.dialogClose
          }
        },
        [
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v("基本信息")])
          ]),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.infoLoading,
                  expression: "infoLoading"
                }
              ],
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                rules: _vm.rules,
                size: "normal"
              }
            },
            [
              _c(
                "div",
                { staticClass: "form" },
                [
                  _vm.titleName === "编辑入库单"
                    ? [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "show",
                            attrs: { label: "入库单号", "label-width": "80px" }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: { size: "small", disabled: "" },
                              model: {
                                value: _vm.form.enterWarehouseNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "enterWarehouseNumber",
                                    $$v
                                  )
                                },
                                expression: "form.enterWarehouseNumber"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "show",
                            attrs: { label: "制单人员", "label-width": "80px" }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: { size: "small", disabled: "" },
                              model: {
                                value: _vm.form.createdName,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "createdName", $$v)
                                },
                                expression: "form.createdName"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "show",
                            attrs: { label: "创建时间", "label-width": "80px" }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: { size: "small", disabled: "" },
                              model: {
                                value: _vm.form.createdTimestamp,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "createdTimestamp", $$v)
                                },
                                expression: "form.createdTimestamp"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "show",
                            attrs: { label: "入库状态", "label-width": "80px" }
                          },
                          [
                            _c("div", { staticStyle: { width: "200px" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.form.enterWarehouseState === 0
                                      ? "待入库"
                                      : _vm.form.enterWarehouseState === 1
                                      ? "入库中"
                                      : _vm.form.enterWarehouseState === 2
                                      ? "部分入库"
                                      : _vm.form.enterWarehouseState === 3
                                      ? "完成入库"
                                      : _vm.form.enterWarehouseState === 9
                                      ? "已终止"
                                      : ""
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        ),
                        _vm.form.enterWarehouseType === "调拨"
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "show",
                                attrs: {
                                  label: "调出仓库",
                                  "label-width": "80px"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      disabled: "",
                                      clearable: "",
                                      size: "small"
                                    },
                                    model: {
                                      value: _vm.form.sourceWarehouseName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "sourceWarehouseName",
                                          $$v
                                        )
                                      },
                                      expression: "form.sourceWarehouseName"
                                    }
                                  },
                                  _vm._l(
                                    _vm.list.WarehousingWarehouse,
                                    function(item) {
                                      return _c("el-option", {
                                        key: item.warehouseId,
                                        attrs: {
                                          label: item.warehouseName,
                                          value: item.warehouseId
                                        }
                                      })
                                    }
                                  ),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.enterWarehouseType === "调拨"
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "show",
                                attrs: {
                                  label: "仓库位置",
                                  "label-width": "80px"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.form.sameWarehouseFlag === 1
                                        ? "与入库仓库同一地点"
                                        : "不同地点（需扫码）"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.form.enterWarehouseType !== "其他"
                          ? [
                              _vm.judge != "盘盈"
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "show",
                                      attrs: {
                                        label: "采购单号",
                                        "label-width": "80px"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "200px" },
                                        attrs: { size: "small", disabled: "" },
                                        model: {
                                          value: _vm.form.purchaseId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "purchaseId",
                                              $$v
                                            )
                                          },
                                          expression: "form.purchaseId"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.judge != "盘盈"
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "show",
                                      attrs: {
                                        label: "采购类型",
                                        "label-width": "80px"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "200px" },
                                        attrs: { size: "small", disabled: "" },
                                        model: {
                                          value: _vm.form.purchaseType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "purchaseType",
                                              $$v
                                            )
                                          },
                                          expression: "form.purchaseType"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.form.enterWarehouseType !== "调拨" &&
                              _vm.judge != "盘盈"
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "show",
                                      attrs: {
                                        label: "采购渠道",
                                        "label-width": "80px"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "200px" },
                                        attrs: { size: "small", disabled: "" },
                                        model: {
                                          value: _vm.form.channel,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "channel", $$v)
                                          },
                                          expression: "form.channel"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "入库类型",
                        "label-width": "80px",
                        prop: "enterWarehouseType"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            size: "small",
                            placeholder: "请选择入库类型",
                            disabled: true
                          },
                          model: {
                            value: _vm.form.enterWarehouseType,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "enterWarehouseType", $$v)
                            },
                            expression: "form.enterWarehouseType"
                          }
                        },
                        _vm._l(_vm.list.WarehousingType, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.form.enterWarehouseType !== "调拨" && _vm.judge !== "盘盈"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "付款方式",
                            "label-width": "80px",
                            prop: "payWay"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                disabled: _vm.titleName === "编辑入库单",
                                size: "small",
                                placeholder: "请选择付款方式"
                              },
                              model: {
                                value: _vm.form.payWay,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "payWay", $$v)
                                },
                                expression: "form.payWay"
                              }
                            },
                            _vm._l(_vm.list.PayWay, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "入库仓库",
                        "label-width": "80px",
                        prop: "warehouseId"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            disabled: _vm.titleName === "编辑入库单",
                            size: "small",
                            placeholder: "请选择入库仓库"
                          },
                          model: {
                            value: _vm.form.warehouseId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "warehouseId", $$v)
                            },
                            expression: "form.warehouseId"
                          }
                        },
                        _vm._l(_vm.list.WarehousingWarehouse, function(item) {
                          return _c("el-option", {
                            key: item.warehouseId,
                            attrs: {
                              label: item.warehouseName,
                              value: item.warehouseId
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.form.enterWarehouseType == "其他"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "入库来源",
                            "label-width": "80px",
                            prop: "enterWarehouseSource"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                disabled: _vm.titleName === "编辑入库单",
                                clearable: "",
                                size: "small",
                                placeholder: "请选择入库来源"
                              },
                              model: {
                                value: _vm.form.enterWarehouseSource,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "enterWarehouseSource",
                                    $$v
                                  )
                                },
                                expression: "form.enterWarehouseSource"
                              }
                            },
                            _vm._l(_vm.list.warehouseSource, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.keys, value: item.keys }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.enterWarehouseType !== "调拨" && _vm.judge != "盘盈"
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              clearable: "",
                              label: "物流公司",
                              "label-width": "80px",
                              prop: "trackingCompany"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: {
                                disabled: _vm.isEdit,
                                size: "small",
                                placeholder: "请输入物流公司"
                              },
                              model: {
                                value: _vm.form.logisticsCompany,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "logisticsCompany", $$v)
                                },
                                expression: "form.logisticsCompany"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.judge != "盘盈"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  clearable: "",
                                  label: "物流单号",
                                  "label-width": "80px",
                                  prop: "logisticsNumbers"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    disabled: _vm.isEdit,
                                    size: "small",
                                    placeholder: "请输入物流单号"
                                  },
                                  model: {
                                    value: _vm.form.logisticsNumbers,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "logisticsNumbers",
                                        $$v
                                      )
                                    },
                                    expression: "form.logisticsNumbers"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e(),
                  Array.isArray(_vm.info.childNumbers) &&
                  _vm.info.childNumbers.length > 0 &&
                  _vm.titleName !== "新增入库单"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "子订单号", "label-width": "80px" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pop",
                              staticStyle: { width: "200px" }
                            },
                            [
                              _c(
                                "el-popover",
                                { attrs: { width: "150", trigger: "click" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        size: "mini",
                                        type: "primary"
                                      },
                                      slot: "reference"
                                    },
                                    [_vm._v(" 查看 ")]
                                  ),
                                  _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        data: _vm.form.childNumbers,
                                        size: "mini",
                                        "max-height": "620px",
                                        "element-loading-text": "数据加载中",
                                        "cell-style": { textAlign: "center" },
                                        "tooltip-effect": "dark",
                                        "row-style": { height: "57px" },
                                        "header-cell-style": {
                                          background: "#dfe6ec",
                                          color: "#666666",
                                          textAlign: "center"
                                        }
                                      }
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: { label: "子订单号" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.toDetails(
                                                            scope.row
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(scope.row) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3304700011
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.form.fatherId
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "主订单号", "label-width": "80px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.toDetails(_vm.form.fatherId)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.form.fatherId) + " ")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              ),
              _c("br"),
              _c("div", { staticClass: "title" }, [
                _c("span", [_vm._v("商品信息")]),
                _vm.form.enterWarehouseType !== "调拨"
                  ? _c(
                      "div",
                      { staticClass: "btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.isEdit,
                              type: "success",
                              size: "small"
                            },
                            on: { click: _vm.addRow }
                          },
                          [_vm._v(" 添加 ")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "table" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "goodsList" } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "goodsList",
                          attrs: {
                            data: _vm.form.goodsList,
                            "max-height": "620px",
                            "element-loading-text": "数据加载中",
                            "cell-style": { textAlign: "center" },
                            "tooltip-effect": "dark",
                            "row-style": { height: "57px" },
                            "header-cell-style": {
                              background: "#dfe6ec",
                              color: "#666666",
                              textAlign: "center"
                            }
                          }
                        },
                        [
                          _c("el-table-column", {
                            key: "index",
                            attrs: {
                              label: "序号",
                              type: "index",
                              width: "80",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            key: "goodsCoding",
                            attrs: {
                              label: "商品编码",
                              prop: "goodsCoding",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            key: "goodsName",
                            attrs: {
                              label: "商品名称",
                              width: "700",
                              prop: "goodsName",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            key: "pictureUrl",
                            attrs: { label: "商品图片", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px"
                                      },
                                      attrs: {
                                        src: _vm.$oss + scope.row.pictureUrl,
                                        "preview-src-list": [
                                          _vm.$oss + scope.row.pictureUrl
                                        ],
                                        fit: "cover"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm.titleName == "编辑入库单" &&
                          _vm.form.enterWarehouseType === "采购"
                            ? _c("el-table-column", {
                                key: "purchaseNums",
                                attrs: { label: "采购数量", align: "center" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "goodsList." +
                                                  scope.$index +
                                                  ".purchaseNums",
                                                rules: _vm.rules.purchaseNums
                                              }
                                            },
                                            [
                                              _c("el-input-number", {
                                                attrs: {
                                                  precision: 0,
                                                  min: 0,
                                                  disabled: _vm.isEdit,
                                                  clearable: "",
                                                  type: "number",
                                                  size: "small",
                                                  placeholder: "请输入采购数量"
                                                },
                                                model: {
                                                  value: scope.row.purchaseNums,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "purchaseNums",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.purchaseNums"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2874881193
                                )
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            key: "enterWarehousePrices",
                            attrs: {
                              width: "150",
                              label: "入库价",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "goodsList." +
                                            scope.$index +
                                            ".enterWarehousePrices",
                                          rules: _vm.rules.enterWarehousePrices
                                        }
                                      },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            min: 0,
                                            precision: 2,
                                            disabled: _vm.isEdit,
                                            clearable: "",
                                            step: 0.01,
                                            type: "number",
                                            size: "small"
                                          },
                                          nativeOn: {
                                            input: function($event) {
                                              return _vm.changeInput($event)
                                            }
                                          },
                                          model: {
                                            value:
                                              scope.row.enterWarehousePrices,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "enterWarehousePrices",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.enterWarehousePrices"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            key: "enterWarehouseNums",
                            attrs: {
                              width: "150",
                              label: "入库数量",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "goodsList." +
                                            scope.$index +
                                            ".enterWarehouseNums",
                                          rules: _vm.rules.enterWarehouseNums
                                        }
                                      },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            width: "300px",
                                            min: 1,
                                            precision: 0,
                                            disabled: _vm.isEdit,
                                            clearable: "",
                                            type: "number",
                                            size: "small"
                                          },
                                          model: {
                                            value: scope.row.enterWarehouseNums,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "enterWarehouseNums",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.enterWarehouseNums"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            key: "total",
                            attrs: { label: "总计", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.enterWarehousePrices *
                                            scope.row.enterWarehouseNums
                                            ? (
                                                scope.row.enterWarehousePrices *
                                                scope.row.enterWarehouseNums
                                              ).toFixed(2)
                                            : 0
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm.form.enterWarehouseType !== "采购"
                            ? _c("el-table-column", {
                                key: "delete",
                                attrs: { fixed: "right", label: "操作" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                disabled: _vm.isEdit,
                                                type: "danger",
                                                size: "mini"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteRow(
                                                    scope.$index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" 删除 ")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "success",
                                                size: "mini"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.copyBtn(
                                                    scope.row,
                                                    scope.$index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("复制")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  516120383
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c("div", { staticClass: "title" }, [_c("span", [_vm._v("统计")])]),
          _c("div", { staticClass: "count" }, [
            _c("div", { staticClass: "count-item" }, [
              _vm._v(" 入库数量： "),
              _c("span", { staticClass: "red" }, [
                _vm._v(_vm._s(_vm.count.number))
              ])
            ]),
            _c("div", { staticClass: "count-item" }, [
              _vm._v(" 入库总计： "),
              _c("span", { staticClass: "red" }, [
                _vm._v(_vm._s(_vm.count.price.toFixed(2)))
              ])
            ])
          ]),
          _c("div", { staticClass: "title" }, [_c("span", [_vm._v("备注")])]),
          _c("el-input", {
            attrs: {
              type: "textarea",
              autosize: { minRows: 2, maxRows: 4 },
              maxlength: "250"
            },
            model: {
              value: _vm.form.remark,
              callback: function($$v) {
                _vm.$set(_vm.form, "remark", $$v)
              },
              expression: "form.remark"
            }
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.isEdit,
                    size: "small",
                    type: "primary",
                    loading: _vm.saveloading
                  },
                  on: { click: _vm.save }
                },
                [_vm._v(" 保存 ")]
              )
            ],
            1
          ),
          _c("SetGoodsList", {
            ref: "setGoodsList",
            on: { setGoods: _vm.addGoods }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }