var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "dashboard-editor-container" }, [
      _vm._m(0),
      _c("div", { staticClass: "whSty" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingEcharts,
                expression: "loadingEcharts"
              }
            ],
            staticStyle: { height: "100%" }
          },
          [
            _c("div", {
              ref: "pieChart",
              staticStyle: { width: "95%", height: "95%" }
            })
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "singSty" }, [
      _c("div", { staticClass: "verticalBar" }),
      _c("span", { staticClass: "textSty" }, [_vm._v("统计")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }