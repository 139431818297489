//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import goods from './goods/index.vue';
import stock from './stock/index.vue';
export default {
  components: {
    goods: goods,
    stock: stock
  },
  name: 'varehouseTable',
  data: function data() {
    return {
      permission: {
        isCheck: this.checkauthority('varehouseTable_check') // 查询

      },
      type: 'goods'
    };
  }
};