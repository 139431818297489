var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("div", { staticStyle: { width: "100%" } }, [
        _c(
          "div",
          { staticClass: "searchSty" },
          [
            _c("el-input", {
              staticClass: "filter-item",
              attrs: {
                clearable: "",
                placeholder: "请输入商品编码",
                "prefix-icon": "el-icon-search",
                size: "small"
              },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.toQuery($event)
                }
              },
              model: {
                value: _vm.search.goodsCoding,
                callback: function($$v) {
                  _vm.$set(_vm.search, "goodsCoding", $$v)
                },
                expression: "search.goodsCoding"
              }
            }),
            _c("el-input", {
              staticClass: "filter-item",
              attrs: {
                clearable: "",
                placeholder: "请输入商品",
                "prefix-icon": "el-icon-search",
                size: "small"
              },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.toQuery($event)
                }
              },
              model: {
                value: _vm.search.goodsName,
                callback: function($$v) {
                  _vm.$set(_vm.search, "goodsName", $$v)
                },
                expression: "search.goodsName"
              }
            }),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                attrs: {
                  clearable: "",
                  placeholder: "请选择商品分类",
                  size: "small"
                },
                model: {
                  value: _vm.search.goodsOne,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "goodsOne", $$v)
                  },
                  expression: "search.goodsOne"
                }
              },
              _vm._l(_vm.oneClassData, function(item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: {
                    label: item.classifyName,
                    value: item.id,
                    title: _vm.onHoverText
                  },
                  nativeOn: {
                    mouseenter: function($event) {
                      return _vm.onMouseOver(item.classifyName)
                    }
                  }
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                attrs: {
                  clearable: "",
                  placeholder: "请选择商品品牌",
                  size: "small"
                },
                model: {
                  value: _vm.search.goodsBrand,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "goodsBrand", $$v)
                  },
                  expression: "search.goodsBrand"
                }
              },
              _vm._l(_vm.goodsBrandData, function(item, index) {
                return _c("el-option", {
                  key: index,
                  staticStyle: { width: "200px" },
                  attrs: {
                    label: item.brandName,
                    value: item.id,
                    title: _vm.onHoverText
                  },
                  nativeOn: {
                    mouseenter: function($event) {
                      return _vm.onMouseOver(item.brandName)
                    }
                  }
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                attrs: {
                  clearable: "",
                  placeholder: "请选择商品IP",
                  size: "small"
                },
                model: {
                  value: _vm.search.goodsIp,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "goodsIp", $$v)
                  },
                  expression: "search.goodsIp"
                }
              },
              _vm._l(_vm.goodsIpData, function(item, index) {
                return _c("el-option", {
                  key: index,
                  staticStyle: { width: "200px" },
                  attrs: {
                    label: item.ipName,
                    value: item.id,
                    title: _vm.onHoverText
                  },
                  nativeOn: {
                    mouseenter: function($event) {
                      return _vm.onMouseOver(item.ipName)
                    }
                  }
                })
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "searchBtnSty" },
              [
                !_vm.permission.isSearch
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-search",
                          size: "small",
                          type: "primary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.toQuery()
                          }
                        }
                      },
                      [_vm._v(" 搜索 ")]
                    )
                  : _vm._e(),
                !_vm.permission.isReset
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-refresh",
                          size: "small",
                          type: "warning"
                        },
                        on: {
                          click: function($event) {
                            return _vm.reset()
                          }
                        }
                      },
                      [_vm._v(" 重置 ")]
                    )
                  : _vm._e(),
                !_vm.permission.isAdd
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: {
                          icon: "el-icon-circle-plus-outline",
                          size: "small",
                          type: "success"
                        },
                        on: { click: _vm.append }
                      },
                      [_vm._v(" 新增 ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticStyle: {
            "margin-top": "10px",
            "margin-left": "10px",
            "margin-right": "10px"
          }
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingTotal,
                  expression: "loadingTotal"
                }
              ],
              ref: "tableData",
              attrs: {
                "cell-style": { textAlign: "center" },
                data: _vm.tableData,
                "header-cell-style": {
                  background: "#dfe6ec",
                  color: "#666666",
                  textAlign: "center"
                },
                "row-style": { height: "57px" },
                "element-loading-text": "数据加载中",
                "max-height": "660",
                "tooltip-effect": "dark"
              }
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "序号", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              (_vm.search.pageNo - 1) * _vm.search.pageSize +
                                scope.$index +
                                1
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品编码",
                  prop: "goodsCoding",
                  width: "150"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                              width: "100%"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "white-space": "nowrap",
                                  width: "80%"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(scope.row.goodsCoding) + " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "copySty",
                                on: {
                                  click: function($event) {
                                    return _vm.copyUrl(scope.row.goodsCoding)
                                  }
                                }
                              },
                              [_vm._v(" 复制 ")]
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "商品原ID", prop: "goodsId" }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品名称",
                  prop: "goodsName",
                  width: "300"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "white-space": "nowrap",
                              "text-overflow": "ellipsis"
                            }
                          },
                          [_vm._v(" " + _vm._s(scope.row.goodsName) + " ")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品图片",
                  prop: "pictureUrl",
                  width: "100"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("img", {
                          staticStyle: {
                            width: "50px",
                            height: "50px",
                            "margin-top": "8px",
                            cursor: "pointer"
                          },
                          attrs: {
                            src: _vm.downloadUrl + "/" + scope.row.pictureUrl,
                            alt: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.getPreview(
                                _vm.downloadUrl + "/" + scope.row.pictureUrl
                              )
                            }
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品分类",
                  prop: "goodsOne",
                  width: "120"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "white-space": "nowrap",
                              "text-overflow": "ellipsis"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.goodsOne === null
                                    ? "- -"
                                    : scope.row.goodsOne
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品品牌",
                  prop: "goodsBrandName",
                  width: "150"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "white-space": "nowrap",
                              "text-overflow": "ellipsis"
                            }
                          },
                          [_vm._v(" " + _vm._s(scope.row.goodsBrandName) + " ")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品IP",
                  prop: "goodsIpName",
                  width: "150"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "white-space": "nowrap",
                              "text-overflow": "ellipsis"
                            }
                          },
                          [_vm._v(" " + _vm._s(scope.row.goodsIpName) + " ")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "right",
                  label: "操作",
                  width: "380"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        !_vm.permission.isRedact
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-edit-outline",
                                  plain: "",
                                  size: "small",
                                  type: "success"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.redact(
                                      scope.row,
                                      scope.$index,
                                      _vm.tableData
                                    )
                                  }
                                }
                              },
                              [_vm._v(" 编辑 ")]
                            )
                          : _vm._e(),
                        !_vm.permission.isInfo
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-info",
                                  plain: "",
                                  size: "small",
                                  type: "info"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.particulars(
                                      scope.row,
                                      scope.$index,
                                      _vm.tableData
                                    )
                                  }
                                }
                              },
                              [_vm._v(" 详情 ")]
                            )
                          : _vm._e(),
                        !_vm.permission.isDelete
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-delete",
                                  plain: "",
                                  size: "small",
                                  type: "danger"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.remove(
                                      scope.row,
                                      scope.$index,
                                      _vm.tableData
                                    )
                                  }
                                }
                              },
                              [_vm._v(" 删除 ")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "right",
            "margin-top": "10px"
          }
        },
        [
          this.search.pageSize > 0
            ? _c("el-pagination", {
                attrs: {
                  "current-page": _vm.search.pageNo,
                  "page-size": _vm.search.pageSize,
                  "page-sizes": [10, 20, 30, 40, 50, 100],
                  total: _vm.search.pageTotal,
                  layout: "total, jumper, prev, pager, next,sizes"
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogFormVisible,
            top: "5vh",
            width: "57%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.closeDialog()
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "redactData",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.redactData,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c("div", { staticStyle: { width: "100%" } }, [
                _c("div", { staticClass: "class1" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50%",
                        display: "flex",
                        "flex-direction": "column"
                      }
                    },
                    [
                      this.title === "编辑"
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "50%" },
                              attrs: { label: "商品编码" }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.redactData.goodsCoding) + " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品名称", prop: "goodsName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              clearable: "",
                              maxlength: "60",
                              placeholder: "请输入商品名称"
                            },
                            model: {
                              value: _vm.redactData.goodsName,
                              callback: function($$v) {
                                _vm.$set(_vm.redactData, "goodsName", $$v)
                              },
                              expression: "redactData.goodsName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品IP", prop: "goodsIp" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                clearable: "",
                                placeholder: "请选择商品IP"
                              },
                              model: {
                                value: _vm.redactData.goodsIp,
                                callback: function($$v) {
                                  _vm.$set(_vm.redactData, "goodsIp", $$v)
                                },
                                expression: "redactData.goodsIp"
                              }
                            },
                            _vm._l(_vm.goodsIpData, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                staticStyle: {
                                  width: "300px",
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis"
                                },
                                attrs: {
                                  label: item.ipName,
                                  value: item.id,
                                  title: _vm.onHoverText
                                },
                                nativeOn: {
                                  mouseenter: function($event) {
                                    return _vm.onMouseOver(item.ipName)
                                  }
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品品牌", prop: "goodsBrand" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                id: "goodsBrand",
                                clearable: "",
                                placeholder: "请选择商品品牌"
                              },
                              model: {
                                value: _vm.redactData.goodsBrand,
                                callback: function($$v) {
                                  _vm.$set(_vm.redactData, "goodsBrand", $$v)
                                },
                                expression: "redactData.goodsBrand"
                              }
                            },
                            _vm._l(_vm.goodsBrandData, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                staticStyle: {
                                  width: "300px",
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis"
                                },
                                attrs: {
                                  label: item.brandName,
                                  value: item.id,
                                  title: _vm.onHoverText
                                },
                                nativeOn: {
                                  mouseenter: function($event) {
                                    return _vm.onMouseOver(item.brandName)
                                  }
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品分类", prop: "goodsOne" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择商品分类"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.changeOneClass()
                                    }
                                  },
                                  model: {
                                    value: _vm.redactData.goodsOne,
                                    callback: function($$v) {
                                      _vm.$set(_vm.redactData, "goodsOne", $$v)
                                    },
                                    expression: "redactData.goodsOne"
                                  }
                                },
                                _vm._l(_vm.oneClassData, function(item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.classifyName,
                                      value: item.id,
                                      title: _vm.onHoverText
                                    },
                                    nativeOn: {
                                      mouseenter: function($event) {
                                        return _vm.onMouseOver(
                                          item.classifyName
                                        )
                                      }
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: this.showTwoClass,
                                  expression: "this.showTwoClass"
                                }
                              ],
                              attrs: { label: "二级分类", prop: "goodsTwo" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择二级分类"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.changeTwoClass()
                                    }
                                  },
                                  model: {
                                    value: _vm.redactData.goodsTwo,
                                    callback: function($$v) {
                                      _vm.$set(_vm.redactData, "goodsTwo", $$v)
                                    },
                                    expression: "redactData.goodsTwo"
                                  }
                                },
                                _vm._l(_vm.twoClassDataSelect, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.classifyName,
                                      value: item.id,
                                      title: _vm.onHoverText
                                    },
                                    nativeOn: {
                                      mouseenter: function($event) {
                                        return _vm.onMouseOver(
                                          item.classifyName
                                        )
                                      }
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: this.showThreeClass,
                                  expression: "this.showThreeClass"
                                }
                              ],
                              attrs: { label: "三级分类", prop: "goodsThree" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择三级分类"
                                  },
                                  model: {
                                    value: _vm.redactData.goodsThree,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.redactData,
                                        "goodsThree",
                                        $$v
                                      )
                                    },
                                    expression: "redactData.goodsThree"
                                  }
                                },
                                _vm._l(_vm.threeClassDataSelect, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.classifyName,
                                      value: item.id,
                                      title: _vm.onHoverText
                                    },
                                    nativeOn: {
                                      mouseenter: function($event) {
                                        return _vm.onMouseOver(
                                          item.classifyName
                                        )
                                      }
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50%",
                        display: "flex",
                        "flex-direction": "column"
                      }
                    },
                    [
                      _vm.title !== "新增"
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { height: "30px" },
                              attrs: { label: "商品原ID", prop: "goodsId" }
                            },
                            [_vm._v(" " + _vm._s(_vm.redactData.goodsId) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { height: "186px" },
                          attrs: { label: "商品图片", prop: "pictureUrl" }
                        },
                        [
                          _c("erp-upload-img", {
                            attrs: {
                              maxCount: 1,
                              showDelete: true,
                              "upload-path": "/commodityManagement/commodity/"
                            },
                            model: {
                              value: _vm.redactData.pictureUrl,
                              callback: function($$v) {
                                _vm.$set(_vm.redactData, "pictureUrl", $$v)
                              },
                              expression: "redactData.pictureUrl"
                            }
                          }),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item tooltip",
                              attrs: {
                                content:
                                  "注：商品方图请上传宽为500，高为500的图片",
                                effect: "dark",
                                placement: "top-start"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-info",
                                staticStyle: {
                                  color: "#1890ff",
                                  "margin-left": "20px"
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]),
              _c(
                "div",
                {
                  staticStyle: { "margin-top": "10px", "margin-right": "10px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品详情" } },
                    [
                      _c("erp-Editor", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingTextarea,
                            expression: "loadingTextarea"
                          }
                        ],
                        ref: "goodsDeatil",
                        staticStyle: { "text-align": "left" },
                        attrs: {
                          uploadPath: "/commodityManagement/goodsDeatil/",
                          value: _vm.redactData.goodsDeatil,
                          editorStyle:
                            "text-align:left;margin: 21px;width: calc(100% - 20px);"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { display: "flex", "justify-content": "center" }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.resetForm("redactData")
                        }
                      }
                    },
                    [_vm._v(" 返回 ")]
                  ),
                  _vm.title === "编辑"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.saveForm("redactData")
                            }
                          }
                        },
                        [_vm._v(" 保存 ")]
                      )
                    : _vm._e(),
                  _vm.title === "新增"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("redactData")
                            }
                          }
                        },
                        [_vm._v(" 立即创建 ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogFormVisibleInfo,
            top: "5vh",
            width: "65%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisibleInfo = $event
            },
            close: function($event) {
              return _vm.closeDialogInfo()
            }
          }
        },
        [
          _c("div", { staticClass: "searchBgc" }, [_vm._v("商品详情")]),
          _c(
            "el-form",
            {
              ref: "redactData",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.redactData, "label-width": "100px" }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    width: "100%",
                    height: "250px"
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "30%" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品编码:", prop: "goodsCoding" } },
                        [_vm._v(" " + _vm._s(_vm.redactData.goodsCoding) + " ")]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品分类:", prop: "goodsOne" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.redactData.goodsOne === null
                                  ? "- -"
                                  : _vm.redactData.goodsOne
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品品牌:", prop: "goodsBrand" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { height: "70px", overflow: "auto" }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.redactData.goodsBrand) + " "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { width: "60%", "margin-right": "1%" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            height: "185px"
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "商品原ID:", prop: "goodsId" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.redactData.goodsId) + " "
                                  )
                                ]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "商品名称:",
                                    prop: "goodsName"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        height: "40px",
                                        overflow: "auto"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.redactData.goodsName) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "商品IP:", prop: "goodsIp" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.redactData.goodsIp) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "商品图片:",
                                    prop: "pictureUrl"
                                  }
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "150px",
                                      height: "150px",
                                      "margin-top": "8px",
                                      cursor: "pointer"
                                    },
                                    attrs: {
                                      src:
                                        _vm.downloadUrl +
                                        "/" +
                                        _vm.redactData.pictureUrlString,
                                      alt: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.getPreview(
                                          _vm.downloadUrl +
                                            "/" +
                                            _vm.redactData.pictureUrlString
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          _c("div", [
            _c(
              "div",
              {
                staticClass: "searchBgc",
                staticStyle: { "margin-bottom": "10px" }
              },
              [_vm._v("详细信息")]
            ),
            _c("div", { staticStyle: { "margin-left": "20px" } }, [
              _c("p", {
                domProps: { innerHTML: _vm._s(_vm.redactData.goodsDeatil) }
              })
            ])
          ]),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "10px"
              }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialogInfo()
                    }
                  }
                },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogFormVisibleRelevance,
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisibleRelevance = $event
            },
            close: function($event) {
              return _vm.closeDialogRelevance()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "relevance" },
            [
              _c(
                "div",
                [
                  _vm._v(" 商品编码： "),
                  _c("el-input", {
                    staticStyle: { width: "200px", margin: "0px 20px" },
                    attrs: {
                      clearable: "",
                      oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                      size: "small"
                    },
                    model: {
                      value: _vm.searchGoodsCoding,
                      callback: function($$v) {
                        _vm.searchGoodsCoding = $$v
                      },
                      expression: "searchGoodsCoding"
                    }
                  })
                ],
                1
              ),
              !_vm.permission.isCodingGoods
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        size: "small",
                        type: "primary"
                      },
                      on: { click: _vm.toSearch }
                    },
                    [_vm._v(" 搜索 ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("div", [
            _c("div", { staticClass: "searchBgc" }, [_vm._v("搜索结果")]),
            _c(
              "div",
              { staticStyle: { "margin-top": "20px" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingRelevance,
                        expression: "loadingRelevance"
                      }
                    ],
                    ref: "relevanceData",
                    attrs: {
                      "cell-style": { textAlign: "center" },
                      data: _vm.relevanceData,
                      "header-cell-style": {
                        color: "#666666",
                        textAlign: "center"
                      },
                      "row-style": { height: "90px" },
                      "element-loading-text": "数据加载中",
                      "max-height": "140",
                      "tooltip-effect": "dark"
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "商品编码", prop: "goodsCoding" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "商品名称", prop: "goodsName" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "商品图片", prop: "pictureUrl" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.pictureUrl !== "" &&
                              scope.row.pictureUrl !== null
                                ? _c("img", {
                                    staticStyle: {
                                      width: "50px",
                                      height: "50px",
                                      "margin-top": "8px",
                                      cursor: "pointer"
                                    },
                                    attrs: {
                                      src:
                                        _vm.downloadUrl +
                                        "/" +
                                        scope.row.pictureUrl,
                                      alt: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.getPreview(
                                          _vm.downloadUrl +
                                            "/" +
                                            scope.row.pictureUrl
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              scope.row.pictureUrl === "" ||
                              scope.row.pictureUrl === null
                                ? _c("div", {
                                    staticStyle: {
                                      width: "50px",
                                      height: "50px",
                                      border: "1px solid #c0c0c0",
                                      display: "flex",
                                      "justify-content": "center",
                                      margin: "0 auto"
                                    }
                                  })
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "20px"
              }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialogRelevance()
                    }
                  }
                },
                [_vm._v("返回")]
              ),
              !_vm.permission.isAddGoods
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.toAdd()
                        }
                      }
                    },
                    [_vm._v(" 添加 ")]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { margin: "20px auto" },
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            title: _vm.judgment,
            visible: _vm.dialogFormVisiblePic,
            width: "540px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisiblePic = $event
            },
            close: function($event) {
              return _vm.closeDialogPic()
            }
          }
        },
        [
          this.dialogImageUrl !== ""
            ? _c("img", {
                staticStyle: {
                  width: "500px",
                  height: "500px",
                  "text-align": "center"
                },
                attrs: { src: this.dialogImageUrl, alt: "" }
              })
            : _vm._e(),
          this.dialogImageUrl === ""
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "500px",
                    height: "500px",
                    "text-align": "center",
                    border: "1px solid #c0c0c0",
                    display: "flex",
                    "justify-content": "center",
                    "align-items": "center"
                  }
                },
                [
                  _c("span", { staticStyle: { "font-size": "large" } }, [
                    _vm._v("暂无图片")
                  ])
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }