var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.page.totalSize > 0
        ? _c("el-pagination", {
            class: _vm.storage,
            staticStyle: { "margin-top": "8px", "text-align": "right" },
            attrs: {
              visible: _vm.page,
              "page-size": _vm.page.limit,
              "current-page": _vm.page.page,
              total: _vm.page.totalSize,
              layout: "total, prev, next, sizes",
              "prev-text": "上一页",
              "next-text": "下一页"
            },
            on: {
              "update:visible": function($event) {
                _vm.page = $event
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.page, "limit", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.page, "limit", $event)
              },
              "update:currentPage": function($event) {
                return _vm.$set(_vm.page, "page", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.page, "page", $event)
              },
              "prev-click": _vm.upPage,
              "next-click": _vm.nextPage,
              "size-change": function($event) {
                return _vm.sizeChangeHandler($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }