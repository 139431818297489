export default ({ request }) => ({
  addCompanyOrDept (data = {}) {
    return request({
      url: 'v1/organization/addCompanyOrDept',
      method: 'post',
      data: data
    })
  },
  organizationChart_getPageList (params = {}, query = {}) {
    return request({
      url: 'v1/organization/getPageList',
      method: 'post',
      params: params,
      data: query
    })
  },
  getDeptList (data = {}) {
    return request({
      url: 'v1/organization/getDeptList',
      method: 'post',
      data: data
    })
  },
  updCompanyOrDept (data = {}) {
    return request({
      url: 'v1/organization/updCompanyOrDept',
      method: 'put',
      data: data
    })
  },
  delCompanyOrDept (data = {}) {
    return request({
      url: 'v1/organization/delCompanyOrDept',
      method: 'delete',
      data: data
    })
  },
  getorgselect (data = {}) {
    return request({
      url: 'v1/organization/getOrgSelect',
      method: 'post',
      data: data
    })
  }
})
