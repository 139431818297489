<template>
  <d2-container>
    <div class="header">
      <el-button
        icon="el-icon-circle-plus-outline"
        type="success"
        v-if="!permission.isAdd"
        @click="add"
        size="small"
        >新增</el-button
      >
    </div>
    <div style="margin-top: 10px;">
      <el-table
        ref="tableData"
        :data="tableData"
        max-height="645px"
        element-loading-text="数据加载中"
        :cell-style="{ textAlign: 'center' }"
        tooltip-effect="dark"
        v-loading="loadingTotal"
        :row-style="{ height: '57px' }"
        :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center'
        }"
      >
        <el-table-column label="序号" width="80" align="center">
          <template slot-scope="scope">
            {{ (search.pageNo - 1) * search.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          label="出库来源名称"
          prop="values"
          align="center"
        ></el-table-column>
        <el-table-column
          label="创建时间"
          prop="createdTimestamp"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              plain
              type="success"
              icon="el-icon-edit-outline"
              size="small"
              v-if="!permission.isRedact"
              @click.native.prevent="redact(scope.row, scope.$index, tableData)"
              >编辑
            </el-button>
            <el-button
              plain
              type="danger"
              size="small"
              icon="el-icon-delete"
              v-if="!permission.isDelete"
              @click.native.prevent="remove(scope.row, scope.$index, tableData)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display:flex;justify-content:right;margin-top: 10px;">
      <el-pagination
        :current-page="search.pageNo"
        v-if="this.search.pageSize > 0 && permission.isShow === false"
        @size-change="handleSizeChange"
        :page-size="search.pageSize"
        @current-change="handleCurrentChange"
        :page-sizes="[10,20,30,40,50,100]"
        layout="total, jumper, prev, pager, next,sizes"
        :total="search.pageTotal"
      >
      </el-pagination>
    </div>
    <el-dialog
      :append-to-body="true"
      :title="judgment"
      :visible.sync="dialogFormVisible"
      @close="closeDialog()"
      width="21%"
      style="margin:20px auto"
      :close-on-click-modal="false"
    >
      <el-form
        :model="redactData"
        ref="redactData"
        label-width="100px"
        :rules="rules"
        class="demo-ruleForm"
      >
        <el-form-item label="出库来源" prop="value">
          <el-input
            v-model="redactData.value"
            maxlength="30"
            placeholder="请输入出库来源"
            clearable
            size="small"
            style="width:240px"
          >
          </el-input>
        </el-form-item>
        <div style="display: flex;justify-content: center;">
          <el-button size="small" @click="resetForm('redactData')"
            >取消</el-button
          >
          <el-button
            size="small"
            type="primary"
            v-if="this.judgment === '新增'"
            @click="submitForm()"
            >立即创建</el-button
          >
          <el-button
            size="small"
            type="primary"
            v-if="this.judgment === '编辑'"
            @click.native.prevent="submitForm()"
            >保存</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </d2-container>
</template>
<script>
import api from '@/api'
// 搜索数据
const searchDefault = {
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0
}
const redact = {
  id: '', // 主键id
  value: '', // ip名称
  name: 'out_source' // 管理名字
}
export default {
  name: 'out_source',
  data () {
    return {
      // 权限点
      permission: {
        isShow: this.checkauthority('page_ly'), // 查询
        isDelete: this.checkauthority('delete_ly'), // 删除
        isAdd: this.checkauthority('add_ly'), // 新增
        isRedact: this.checkauthority('update_ly') // 编辑
      },
      search: JSON.parse(JSON.stringify(searchDefault)),
      tableData: [],
      judgment: '',
      baseName: {
        name: 'out_source'
      },
      loadingTotal: false,
      dialogFormVisible: false,
      redactData: JSON.parse(JSON.stringify(redact)),
      rules: {
        value: [
          { required: true, message: '请输入入库来源', trigger: 'blur' },
          { pattern: /^(?!\s+)/, message: '首不能为空格', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.initList()
  },
  methods: {
    handleSizeChange (val) {
      this.search.pageSize = val
      this.initList()
    },
    handleCurrentChange (val) {
      this.search.pageNo = val
      this.initList()
    },
    // 初始化页面
    async initList () {
      this.loadingTotal = true
      const res = await api.getPageList_other(
        {
          pageNo: this.search.pageNo,
          pageSize: this.search.pageSize
        },
        this.baseName
      )
      if (res.code === 200) {
        this.tableData = res.body.datas
        this.search.pageTotal = res.body.sumDataCount
        this.loadingTotal = false
      } else {
        this.$message({
          type: 'danger',
          message: '页面加载失败!'
        })
        this.loadingTotal = false
      }
    },
    // 搜索
    searchTotal () {
      this.initList()
    },
    // 重置
    reset () {
      this.search.nextToken = null
      this.search.limit = 10
      this.search.pageNo = 1
      this.initList()
    },
    // 点击新增
    add () {
      this.redactData.value = ''
      this.judgment = '新增'
      this.dialogFormVisible = true
    },
    // 关闭新增 编辑弹窗
    closeDialog () {
      this.judgment = ''
      this.dialogFormVisible = false
      this.resetForm()
    },
    // 新增编辑返回
    resetForm () {
      this.judgment = ''
      this.redactData.value = ''
      this.redactData.id = ''
      this.$refs.redactData.clearValidate()
      this.dialogFormVisible = false
    },
    // 编辑
    redact (row) {
      this.judgment = '编辑'
      this.redactData.value = row.values
      this.redactData.id = row.id
      this.dialogFormVisible = true
    },
    // 新增编辑 创建
    submitForm () {
      this.$refs.redactData.validate(async valid => {
        if (valid) {
          if (this.judgment === '新增') {
            const res = await api.save_other({
              name: 'out_source',
              remark: '入库来源',
              keys: this.redactData.value,
              values: this.redactData.value
            })
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '新增成功!'
              })
              this.dialogFormVisible = false
              this.initList()
            } else {
              this.$message({
                type: 'danger',
                message: '新增失败!'
              })
            }
          } else {
            const res = await api.update_other({
              name: 'out_source',
              remark: '入库来源',
              id: this.redactData.id,
              keys: this.redactData.value,
              values: this.redactData.value
            })
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '编辑成功!'
              })
              this.dialogFormVisible = false
              this.initList()
            } else {
              this.$message({
                type: 'danger',
                message: '编辑失败!'
              })
            }
          }
        } else {
          this.$message({
            type: 'waring',
            message: '请填写内容后提交'
          })
        }
      })
    },
    // 删除
    remove (row) {
      this.$confirm('是否删除该入库来源？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await api.delete_other({
          id: row.id
        })
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.search.pageNo = 1
          this.initList()
        } else {
          this.$message({
            type: 'danger',
            message: '删除失败!'
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
}
</style>
