<template>
  <d2-container class="agreement">
    <el-tabs v-model="tabValue" @tab-click="tabChange" type="border-card">
      <el-tab-pane name="0" label="服务条款">
        <erp-Editor ref="editors0" :value="agreement" editorStyle="text-align:left;margin: 21px;width: calc(100% - 20px);"
          style="text-align: left"></erp-Editor>
      </el-tab-pane>
      <el-tab-pane name="1" label="隐私协议">
        <erp-Editor ref="editors1" :value="agreement" editorStyle="text-align:left;margin: 21px;width: calc(100% - 20px);"
          style="text-align: left"></erp-Editor></el-tab-pane>
      <el-button type="primary" style="margin-top:10px" @click="submit" size="small">保存</el-button>
    </el-tabs>
  </d2-container>
</template>

<script>
import api from '@/api'
export default {
  name: 'agreement',
  data () {
    return {
       // 权限点
       permission: {
        isSelectDept: this.checkauthority('user_agreement_update'), // 查看权限
      },
      tabValue: 0,
      agreement: ''
    }
  },
  methods: {
    async tabChange () {
      this.getAgreement()
    },
    async submit () {
      const { code } = await api.userAgreement_update({ id: +this.tabValue, text: this.$refs['editors' + this.tabValue].getContent() })
      if (code === 200) {
        this.$message({
          type: 'success',
          message: '保存成功'
        })
      }
    },
    async getAgreement () {
      const { body: data } = await api.userAgreement_info({ id: +this.tabValue })
      this.agreement = this.showHtml(data.text)
    },
    // 富文本处理
    showHtml (str) {
      return (
        str
          .replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
          .replace(/&amp;nbsp;/g, '')
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
          .replace(/&quot;/g, '"')
          // eslint-disable-next-line no-useless-escape
          .replace(/&#39;/g, '\'')
      )
    }
  },
  created () {
    this.tabChange()
  }
}
</script>

<style lang="scss" scoped>
.agreement {}
</style>
