<template>
  <d2-container>
    <head-search label-style='width:100px'>
      <head-search-item label='入库单号'>
        <el-input v-model="header.intoNo" size="small" placeholder="请输入入库单号"></el-input>
      </head-search-item>
      <head-search-item label="入库类型">
        <el-select multiple v-model="header.intoType" class="searchInput" clearable placeholder="请选择入库类型" size="small">
          <el-option v-for="(item, index) in warehousingType" :key="index" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="订单来源">
        <el-select multiple v-model="header.source" class="searchInput" clearable placeholder="请选择订单来源" size="small">
          <el-option v-for="(item, index) in orderSourceList" :key="index" :label="item.values" :value="item.values">
          </el-option>
        </el-select>
      </head-search-item>
      <!-- <head-search-item label="发货方式">
                <el-select multiple v-model="header.enterWarehouseSource" class="searchInput" clearable placeholder="请选择状态"
                    size="small">
                    <el-option v-for="(item, index) in outWay" :key="index" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </head-search-item> -->
      <head-search-item label="公司名称">
        <el-select multiple v-model="header.orgIds" class="searchInput" clearable placeholder="请选择公司名称" size="small">
          <el-option v-for="(item, index) in companyList" :key="index" :label="item.orgName" :value="item.orgId">
          </el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="部门名称">
        <el-select multiple v-model="header.deptIds" class="searchInput" clearable placeholder="请选择部门名称" size="small"
          :disabled="disabledDeptIds">
          <el-option v-for="(item, index) in branchList" :key="index" :label="item.orgName" :value="item.orgId">
          </el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="仓库名称">
        <el-select multiple v-model="header.warehouseIds" class="searchInput" clearable placeholder="请选择仓库名称"
          size="small">
          <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.warehouseName"
            :value="item.warehouseId">
          </el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="制单人">
        <el-select multiple v-model="header.createdIds" class="searchInput" clearable placeholder="请选择制单人" size="small">
          <el-option v-for="(item, index) in userList" :key="index" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </head-search-item>
      <head-search-item label='商品编码'>
        <el-input v-model="header.goodsCoding" size="small" placeholder="请输入商品编码"></el-input>
      </head-search-item>
      <head-search-item label='商品原ID'>
        <el-input v-model="header.goodsId" size="small" placeholder="请输入商品编码"></el-input>
      </head-search-item>
      <head-search-item label='商品名称'>
        <el-input v-model="header.goodsName" size="small" placeholder="请输入商品名称"></el-input>
      </head-search-item>
      <head-search-item label='创建时间'>
        <el-date-picker v-model="header.time" end-placeholder="结束日期" range-separator="至" size="small"
          start-placeholder="开始日期" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </head-search-item>
      <div class="searchBtn">
        <el-button icon="el-icon-search" size="small" type="primary" @click="toQuery()">搜索</el-button>
        <el-button icon="el-icon-refresh" size="small" type="warning" @click="resetBtn()">重置</el-button>
        <el-button :disabled="exportLoading" :loading="exportLoading" icon="el-icon-download" size="small"
          type="success" @click="exportExcel">
          导出
        </el-button>
      </div>
    </head-search>
    <div class="topCount">
      <div>商品数量：<span>{{ isNumber(count.sum) }}</span></div>
      <div>入库总价：<span>{{ isNumber(count.sumPrice).toFixed(2) }}</span></div>
    </div>
    <el-table :data="tableData" :header-cell-style="headerClass" :cell-style="rowClass" v-loading="loading"
      @sort-change="sortChange">
      <el-table-column prop="createdTimestamp" label="创建时间"></el-table-column>
      <el-table-column prop="enterWarehouseNumber" label="入库单号"></el-table-column>
      <el-table-column prop="enterWarehouseType" label="入库类型"></el-table-column>
      <!-- <el-table-column prop="enterWarehouseType" label="发货方式"></el-table-column> -->
      <el-table-column prop="enterWarehouseSource" label="订单来源"></el-table-column>
      <el-table-column prop="goodsCoding" label="商品编码"></el-table-column>
      <el-table-column prop="goodsId" label="商品原ID"></el-table-column>
      <el-table-column prop="goodsName" label="商品名称"></el-table-column>
      <el-table-column prop="sum" label="商品数量" sortable="custom"></el-table-column>
      <el-table-column prop="enterWarehousePrices" label="入库价" sortable="custom"></el-table-column>
      <el-table-column prop="sumPrice" label="入库总价" sortable="custom"></el-table-column>
      <el-table-column prop="orgName" label="公司名称"></el-table-column>
      <el-table-column prop="deptName" label="部门名称"></el-table-column>
      <el-table-column prop="warehouseName" label="仓库名称"></el-table-column>
      <el-table-column prop="createdName" label="制单人"></el-table-column>
    </el-table>
    <!--      分页      -->
    <div style="display: flex; justify-content: right; margin-top: 10px">
      <el-pagination v-if="search.pageSize > 0" :current-page="search.pageNo" :page-size="search.pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100]" :total="search.pageTotal"
        layout="total, jumper, prev, pager, next,sizes" @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </d2-container>
</template>

<script>

let searchSource = {
  ipName: '',
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0
}
let headerSource = {
  time: [],
  createdIds: [],//制单人
  deptIds: [],//部门
  enterWarehouseSource: [],//发货方式
  goodsCoding: null,//商品编码
  goodsId: null,//商品原ID
  goodsName: null,//商品名称
  intoNo: null,//入库单号
  intoType: [],//入库类型
  orgIds: [],//公司ID
  source: [],//订单来源
  warehouseIds: [],//仓库ID
  goodsId: null,
  field: null,
  sort: null,
}
import *as getListM from '../getListM'
import api from '@/api'
import { downloadFileByUrl } from '@/utils/index'
export default {
  name: 'warehousingDetails',
  data() {
    return {
      disabledDeptIds: true,
      loading: false,
      search: JSON.parse(JSON.stringify(searchSource)),
      header: JSON.parse(JSON.stringify(headerSource)),
      count: {},
      tableData: [],
      list: [],
      userList: [],//用户list
      orderSourceList: [],//订单来源
      companyList: [],//公司
      branchList: [],//部门
      warehouseList: [],//仓库
      // 发货方式
      outWay: [
        { label: '入库', value: 0 },
        { label: '代发', value: 1 }
      ],
      // 入库类型
      warehousingType: [
        { label: '采购', value: '采购' },
        // { label: '调拨', value: '调拨' },
        // { label: '代发', value: '代发' },
        { label: '其他', value: '其他' },
        { label: '赠品', value: '赠品' }
      ],
      exportLoading: false
    }
  },
  created() {
    this.initialize()
    this.getCount()
    this.getList()
    this.seachEnterFun()
  },
  watch: {
    async 'header.orgIds'(newVal, oldVal) {
      if (newVal && newVal.length != 0) {
        let map = new Map
        let list = []
        let name = null
        this.branchList = []
        if (this.header.orgIds.length != 0) {
          this.header.orgIds.forEach(async (e) => {
            list = await getListM.getPart(e)//部门名称
            this.companyList.forEach((x) => {
              if (e == x.orgId) {
                name = x.orgName
              }
            })
            list.forEach((s) => {
              s.orgName = name + '--' + s.orgName
            })
            this.branchList.push(...list)
          })
        }
        this.disabledDeptIds = false
      } else {
        this.branchList = []
        this.header.deptIds = []
        this.disabledDeptIds = true
      }
    }
  },
  methods: {
    sortChange(column) {
      this.header.field = column.prop
      this.header.sort = column.order == 'ascending' ? 0 : column.order == 'descending' ? 1 : null
      if (this.header.sort == null) {
        this.header.field = null
      }
      this.initialize()
      this.getCount()
      // column//当前列
      // column.prop//字段名
      // column.order//排序方法【ascending】是升序，【descending】是降序
    },
    //键盘回车监听方法
    seachEnterFun() {
      document.onkeydown = () => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          //调用定义的搜索方法
          this.toQuery();
        }
      };
    },
    async getList() {
      this.userList = await getListM.getUser() // 用户查询
      this.companyList = await getListM.getOrg()//公司名称
      this.warehouseList = await getListM.getWarehouse()//仓库名称
      const { body: data } = await api.enumeration_getList({ name: 'channel' })
      this.orderSourceList = data
    },
    // getWarehousingDetailsList
    async initialize() {
      this.loading = true
      const res = await api.getWarehousingDetailsList({
        "createdIds": this.header.createdIds,//制单人
        "deptIds": this.header.deptIds,//部门
        "enterWarehouseSource": this.header.enterWarehouseSource,//订单来源
        "endTime": this.header.time[1],//结束时间
        "field": this.header.field,//排序字段
        "goodsCoding": this.header.goodsCoding,//商品编码
        "goodsId": this.header.goodsId,//商品原ID
        "goodsName": this.header.goodsName,//商品名称
        "intoNo": this.header.intoNo,//入库单号
        "intoType": this.header.intoType,//入库类型
        "orgIds": this.header.orgIds,//公司ID
        "pageNo": this.search.pageNo,//
        "pageSize": this.search.pageSize,//
        "sort": this.header.sort,//排序（升/降）
        "source": this.header.source,////订单来源
        "startTime": this.header.time[0],//开始时间
        "warehouseIds": this.header.warehouseIds//仓库ID
      })
      try {
        this.tableData = res.body.datas
        this.search.pageTotal = res.body.sumDataCount ? res.body.sumDataCount : 0
        this.loading = false
      } catch (err) {

      }
    },
    async getCount() {
      const res = await api.getWarehousingDetailsCount({
        "createdIds": this.header.createdIds,//制单人
        "deptIds": this.header.deptIds,//部门
        "enterWarehouseSource": this.header.enterWarehouseSource,//订单来源
        "endTime": this.header.time[1],//结束时间
        "field": this.header.field,//排序字段
        "goodsCoding": this.header.goodsCoding,//商品编码
        "goodsId": this.header.goodsId,//商品原ID
        "goodsName": this.header.goodsName,//商品名称
        "intoNo": this.header.intoNo,//入库单号
        "intoType": this.header.intoType,//入库类型
        "orgIds": this.header.orgIds,//公司ID
        "pageNo": this.search.pageNo,//
        "pageSize": this.search.pageSize,//
        "sort": this.header.sort,//排序（升/降）
        "source": this.header.source,//
        "startTime": this.header.time[0],//开始时间
        "warehouseIds": this.header.warehouseIds//仓库ID
      })
      try {
        this.count = res.body
      } catch (err) {

      }
    },
    isNumber(e) {
      if (e) {
        return Number(e)
      }
      else {
        return 0
      }
    },
    exportBtn() {

    },
    resetBtn() {
      this.header = JSON.parse(JSON.stringify(headerSource))
      this.initialize()
      this.getCount()
    },
    async toQuery() {
      this.initialize()
      this.getCount()
    },
    // table样式
    headerClass() {
      return "background: #eef1f6; color: #606266;text-align:center"
    },
    rowClass() {
      return "text-align:center"
    },
    handleSizeChange(val) {
      this.search.pageSize = val
      this.initialize()
    },
    handleCurrentChange(val) {
      this.search.pageNo = val
      this.initialize()
    },
    async exportExcel() {
      try {
        this.exportLoading = true
        const data = await api.inventory_storage_exportInto({
          "createdIds": this.header.createdIds,//制单人
          "deptIds": this.header.deptIds,//部门
          "enterWarehouseSource": this.header.enterWarehouseSource,//订单来源
          "endTime": this.header.time[1],//结束时间
          "field": this.header.field,//排序字段
          "goodsCoding": this.header.goodsCoding,//商品编码
          "goodsId": this.header.goodsId,//商品原ID
          "goodsName": this.header.goodsName,//商品名称
          "intoNo": this.header.intoNo,//入库单号
          "intoType": this.header.intoType,//入库类型
          "orgIds": this.header.orgIds,//公司ID
          "pageNo": this.search.pageNo,//
          "pageSize": this.search.pageSize,//
          "sort": this.header.sort,//排序（升/降）
          "source": this.header.source,//
          "startTime": this.header.time[0],//开始时间
          "warehouseIds": this.header.warehouseIds//仓库ID
        })
        downloadFileByUrl(data.body)
      } finally {
        this.exportLoading = false
      }
    },
  }
}
</script>

<style lang='scss' scoped>
.searchBtn {
  margin-left: 20px;
  margin-bottom: 10px;
}

.topCount {
  width: 100%;
  padding: 0px 7px;
  display: flex;
  justify-content: space-around;
  /* justify-content: space-around; */
  box-shadow: 0px 0px 4px #888888;
  // border: 1px solid #000;
  min-height: 50px;
  align-items: center;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 600;
  flex-wrap: wrap;
  margin-bottom: 10px;
  border-radius: 10px;

  div {
    min-width: 250px;
  }

  span {
    color: blue;
    margin-left: 10px;
  }
}
</style>
