var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "log" },
    [
      _vm._m(0),
      _c(
        "el-timeline",
        { staticClass: "table" },
        _vm._l(_vm.operateLogs, function(activity, index) {
          return _c(
            "el-timeline-item",
            { key: index, attrs: { timestamp: activity.updateTimestamp } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    activity.updateName +
                      " " +
                      activity.operateDesc +
                      " 备注：" +
                      (activity.remark ? activity.remark : "无")
                  ) +
                  " "
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("操作记录")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }