<template>
  <d2-container>
    <!--       搜索框         -->
    <div style="width:100%;">
      <div class="searchSty">
        <head-search label-style="width:100px">
          <head-search-item label="盘库单号">
            <el-input v-model="search.checkId" clearable placeholder="请输入盘库单号" size="small"
              @keyup.enter.native="toQuery"></el-input>
          </head-search-item>
          <head-search-item label="盘库标题">
            <el-input v-model="search.checkName" clearable placeholder="请输入盘库标题" size="small"
              @keyup.enter.native="toQuery"></el-input>
          </head-search-item>
          <head-search-item label="盘库类型">
            <el-select v-model="search.type" clearable placeholder="请选择盘库类型" size="small">
              <el-option v-for="item in inventoryTypeData" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </head-search-item>
          <head-search-item label="盘库状态">
            <el-select v-model="search.status" clearable placeholder="请选择盘库状态" size="small">
              <el-option v-for="item in inventoryStatusData" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </head-search-item>
          <div class="searchBtn">
            <el-button icon="el-icon-search" v-if="!permission.isSelectDept" size="small" type="primary" @click="toQuery()">
              搜索
            </el-button>
            <el-button icon="el-icon-refresh" v-if="!permission.isSelectDept" size="small" type="warning" @click="reset()">
              重置
            </el-button>
            <el-button icon="el-icon-plus" v-if="!permission.isaddDept" size="small" type="success" @click="add()">
              新增
            </el-button>
          </div>
        </head-search>
      </div>
    </div>
    <!--       TableData      -->
    <div style="margin-top: 10px;">
      <el-table ref="tableData" v-loading="loadingList" :cell-style="{ textAlign: 'center' }" :data="tableData"
        :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center'
        }" :row-style="{ height: '57px' }" element-loading-text="数据加载中" max-height="645px" tooltip-effect="dark">
        <el-table-column align="center" label="序号" width="80">
          <template slot-scope="scope">
            {{ (search.pageNo - 1) * search.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="盘库单号" prop="checkId" width="150">
        </el-table-column>
        <el-table-column align="center" label="盘库标题" prop="checkName">
        </el-table-column>
        <!-- <el-table-column align="center" label="盘库类型" prop="type" width="150">
          <template slot-scope="scope">
            {{
              scope.row.type == '0' ? '录入盘库' : scope.row.type == '1' ? '扫码盘库' : ''
            }}
          </template>
        </el-table-column> -->
        <el-table-column align="center" label="盘库状态" prop="status" width="150">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.status === 0 ? '' : scope.row.status === 1 ? 'warning' : scope.row.status === 2 ? 'warning' : scope.row.status === 3 ? 'success' : scope.row.status === 9 ? 'danger' : ''">
              {{
                scope.row.status === 0 ? '待盘库' : scope.row.status === 1 ? '盘库中' : scope.row.status === 2 ? '待修正'
                  : scope.row.status === 3 ? '盘库完成' :
                    scope.row.status === 9 ? '终止盘库' : ''
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="审核状态" prop="auditStatus" width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.auditStatus == 0">
              <el-tag>待审核</el-tag>
            </div>
            <div v-if="scope.row.auditStatus == 1">
              <el-tag type="success">审核通过</el-tag>
            </div>
            <div v-if="scope.row.auditStatus == 2">
              <el-tag type="danger">审核不通过</el-tag>
            </div>
            <div v-if="scope.row.auditStatus == 9">
              <el-tag type="warning">终止盘库</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" align="center" label="备注" prop="remark" width="150">
          <template slot-scope="scope">
            {{ scope.row.remark ? scope.row.remark : '- -' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="盘库人员" prop="createdName" width="150">
          <template slot-scope="scope">
            {{ scope.row.createdName ? scope.row.createdName : '- -' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="盘库时间" prop="createdTimestamp" width="180">
          <template slot-scope="scope">
            {{ scope.row.createdTimestamp ? scope.row.createdTimestamp : '- -' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status === 0&&!permission.isOperationsDept" icon="el-icon-edit-outline" plain size="small" type="primary"
              @click="handleEdit(scope.$index, scope.row)">编辑
            </el-button>
            <el-button v-if="scope.row.status === 0&&!permission.isOperationsDept" icon="el-icon-edit-outline" plain size="small" type="danger"
              :disabled="deleteLoading" :loading="deleteLoading" @click="handleDelete(scope.$index, scope.row)">删除
            </el-button>
            <el-button v-if="scope.row.status === 1&&!permission.isOperationsDept" icon="el-icon-edit-outline" plain size="small" type="success"
              @click="entering(scope.$index, scope.row)">录入
            </el-button>
            <el-button v-if="scope.row.status === 1&&!permission.isOperationsDept" icon="el-icon-edit-outline" plain size="small" type="warning"
              :disabled="stopLoading" :loading="stopLoading" @click="stopEntering(scope.$index, scope.row)">终止
            </el-button>
            <el-button
              v-if="scope.row.status === 2 && scope.row.checkIdentify != 0 && scope.row.correct === 0 && scope.row.auditStatus == 1&&!permission.isOperationsDept"
              icon="el-icon-s-help" plain size="small" class="amendmentSty1"
              @click="amendment(scope.$index, scope.row)">修正
            </el-button>
            <el-button v-if="scope.row.correct === 1&&!permission.isOperationsDept" icon="el-icon-s-help" plain size="small" class="amendmentSty">已修正
            </el-button>
            <el-button v-if="(scope.row.status === 2 || scope.row.correct === 1)&&!permission.isDetail" icon="el-icon-info" plain size="small"
              type="info" @click="handleDetails(scope.$index, scope.row)">
              明细
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--       分页      -->
    <div style="display:flex;justify-content:right;margin-top: 10px;">
      <el-pagination v-if="this.search.pageSize > 0" :current-page="search.pageNo" :page-size="search.pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100]" :total="search.sumTotal" layout="total, jumper, prev, pager, next,sizes"
        @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </el-pagination>
      <!--   新增 / 编辑 弹窗   -->
      <MakeInventoryDialog ref="MakeInventoryDialog" @successSave="toQuery()"></MakeInventoryDialog>
    </div>
  </d2-container>
</template>
<script>
import MakeInventoryDialog from './MakeInventoryDialogs/index'
import api from '@/api'

const searchDefault = {
  pageNo: 1,
  pageSize: 50,
  sumTotal: 0,
  checkId: '',
  checkName: '',
  type: '',
  status: ''
}
export default {
  name: 'makeInventory',
  components: {
    MakeInventoryDialog
  },
  data() {
    return {
      // 权限点
      permission: {
        isOperationsDept:  this.checkauthority('make_inventory_delete_check_stock'), //删除(包括所有操作)
        isSelectDept: this.checkauthority('check_stock_get_page'), // 查看权限
        isaddDept: this.checkauthority('make_inventory_save_check_stock'), // 新增权限
        isDetail:this.checkauthority('make_inventory_get_check_detail'),//详情
      },
      tableData: [],
      orderNum: 0,
      productNum: 0,
      totalMoney: 0,
      loadingList: false,
      stopLoading: false,
      deleteLoading: false,
      inventoryTypeData: [ // 盘库类型
        {
          label: '录入盘库',
          value: '0'
        }, {
          label: '扫码盘库',
          value: '1'
        }
      ],
      inventoryStatusData: [
        {
          label: '待盘库',
          value: 0
        }, {
          label: '盘库中',
          value: 1
        },
        {
          label: '待修正',
          value: 2
        }, {
          label: '盘库完成',
          value: 3
        }, {
          label: '终止盘库',
          value: 9
        }
      ],
      search: JSON.parse(JSON.stringify(searchDefault))
    }
  },
  created() {
    this.initList()
  },
  methods: {
    exportExcel() {
      // console.log(this.$XLSX)
      // const wb = this.$XLSX.utils.book_new()
      // const ws = this.$XLSX.utils.aoa_to_sheet(this.tableData)
      // this.$XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      // this.$XLSX.writeFile(wb, 'table.xlsx')
    },
    handleSizeChange(val) {
      this.search.pageSize = val
      this.initList()
    },
    handleCurrentChange(val) {
      this.search.pageNo = val
      this.initList()
    },
    // 初始化页面
    async initList() {
      this.loadingList = true
      try {
        const res = await api.checkStockGetPage(this.search)
        this.tableData = res.body.datas
        this.search.pageNo = res.body.pageNo
        this.search.pageSize = res.body.pageSize
        this.search.sumTotal = res.body.sumDataCount
      } catch (error) {
        this.$message.error('加载数据失败')
      } finally {
        this.loadingList = false
      }
    },
    // 搜索
    toQuery() {
      this.search.pageNo = 1
      this.initList()
    },
    // 重置
    reset() {
      this.search = JSON.parse(JSON.stringify(searchDefault))
      this.search.nextToken = null
      this.search.pageSize = 50
      this.search.pageNo = 1
      this.initList()
    },
    add() {
      this.$refs.MakeInventoryDialog.openDialog('新增盘库单')
    },
    handleEdit(index, row) {
      this.$refs.MakeInventoryDialog.openDialog('编辑盘库单', row)
    },
    entering(index, row) {
      this.$refs.MakeInventoryDialog.openDialog('录入盘库单', row)
    },
    amendment(index, row) {
      this.$refs.MakeInventoryDialog.openDialog('修正盘库单', row)
    },
    async handleDetails(index, row) {
      await this.$router.push({
        path: '/MakeInventoryDetails',
        query: { checkId: row.checkId, id: row.id }
      })
    },
    handleDelete(index, row) {
      this.$confirm('是否删除该盘库单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          this.deleteLoading = true
          const res = await api.makeInventoryDeleteCheckStock({
            id: row.id,
            checkId: row.checkId
          })
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          }
          await this.initList()
        } catch (error) {
          this.$message.error('删除失败')
        } finally {
          this.deleteLoading = false
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    stopEntering(index, row) {
      this.$confirm('是否终止该盘库单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          this.stopLoading = true
          const res = await api.makeInventoryEndCheckStock({
            id: row.id
          })
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '终止成功!'
            })
          }
          await this.initList()
        } catch (error) {
          this.$message.error('终止失败')
        } finally {
          this.stopLoading = false
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消终止'
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.searchSty {
  display: flex;
}

.searchBtn {
  margin-left: 20px;
}

.searchInput {
  width: 200px;
}

.amendmentSty {
  box-sizing: border-box;
  border: 1px solid #da82b1;
  background-color: #f9e8f4;
  color: #c85cb9;
  transition: background-color 0.2s ease-out;
}

.amendmentSty:hover {
  color: #fff;
  background-color: #c27daa;
}

.amendmentSty1 {
  box-sizing: border-box;
  border: 1px solid #dac082;
  background-color: #f9f6e8;
  color: #c8ad5c;
  transition: background-color 0.2s ease-out;
}

.amendmentSty1:hover {
  color: #fff;
  background-color: #c2b37d;
}

.searchBtn {
  margin-left: 20px;
  margin-bottom: 10px;
}</style>
