export default (function (_ref) {
  var request = _ref.request;
  return {
    // 获取入库列表
    getWarehousingDetailsList: function getWarehousingDetailsList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/newStatical/getIntoStat',
        method: 'post',
        data: data
      });
    },
    // 获取入库数据统计
    getWarehousingDetailsCount: function getWarehousingDetailsCount() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/newStatical/getNewStatRes',
        method: 'post',
        data: data
      });
    }
  };
});