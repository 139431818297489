export default (function (_ref) {
  var request = _ref.request;
  return {
    // 分页查询
    auditing: function auditing() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/enumeration/getPageList',
        method: 'post',
        params: params
      });
    },
    // 审核确认
    checkAudit: function checkAudit() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/audit/checkAudit',
        method: 'post',
        data: data
      });
    }
  };
});