export default (function (_ref) {
  var request = _ref.request;
  return {
    // 分页查询list
    getPageList_warehouseStatisticalList: function getPageList_warehouseStatisticalList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/statistics/dailyInto',
        method: 'post',
        data: data
      });
    },
    // 分页查询汇总
    getPageList_warehouseStatisticalSum: function getPageList_warehouseStatisticalSum() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/statistics/dailyIntoSum',
        method: 'post',
        data: data
      });
    },
    // 入库商品详情
    intoStaticalGoodsList: function intoStaticalGoodsList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/intoStatical/goodsList',
        method: 'post',
        data: data
      });
    },
    // 入库商品详情聚合统计
    intoStaticalGoodsSum: function intoStaticalGoodsSum() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/intoStatical/goodsSum',
        method: 'post',
        data: data
      });
    },
    // 入库订单详情
    intoStaticalOrderList: function intoStaticalOrderList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/intoStatical/OrderList',
        method: 'post',
        data: data
      });
    },
    // 入库订单详情聚合统计
    intoStaticalOrderSum: function intoStaticalOrderSum() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/intoStatical/orderSum',
        method: 'post',
        data: data
      });
    },
    // 入库订单维度导出
    exportIntoOrder: function exportIntoOrder() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/intoStatical/exportIntoOrder',
        method: 'post',
        data: data
      });
    },
    // 入库商品维度导出
    exportIntoGoods: function exportIntoGoods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/intoStatical/exportIntoGoods',
        method: 'post',
        data: data
      });
    }
  };
});