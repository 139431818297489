export default ({ request }) => ({
  // 分页查询
  getPageList_other (params = {}, query = {}) {
    return request({
      url: '/v1/enumeration/getPageList',
      method: 'post',
      params: params,
      data: query
    })
  },
  // 新增
  save_other (data = {}) {
    return request({
      url: '/v1/enumeration/save',
      method: 'post',
      data: data
    })
  },
  // 编辑
  update_other (data = {}) {
    return request({
      url: '/v1/enumeration/update',
      method: 'put',
      data: data
    })
  },
  // 删除
  delete_other (data = {}) {
    return request({
      url: '/v1/enumeration/delete',
      method: 'delete',
      data: data
    })
  }
})
