import { Message, MessageBox } from 'element-ui'
import Router from 'vue-router'
import util from '@/libs/util.js'
import router from '@/router'
import api from '@/api'
import store from '@/store/index'
import { exRoutes, routes } from '../../../../router/routes'
import { getLeftMenus, getRouters, getTopMenus } from '@/menu'
import { encrypt } from '@/utils/rsa'
import { allRoutes } from '@/router/routes'
export default {
  namespaced: true,
  actions: {
    /**
     * @description 登录
     * @param {Object} context
     * @param {Object} payload username {String} 用户账号
     * @param {Object} payload password {String} 密码
     * @param {Object} payload route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
     */
    async login (
      { dispatch },
      { username = '', password = '', loginType = '' } = {}
    ) {
      password = encrypt(password)
      const res = await api.SYS_USER_LOGIN({ username, password, loginType })
      util.cookies.set('token', res.body.token)
      util.cookies.set('uuid',res.body.user.userId)
      const userInfoRes = await api.GET_USER_INFO({
        userId: res.body.user.userId
      })
      var userInfo = userInfoRes.body
      const userMenus = await api.GET_USER_MENU_LIST({
        userId: res.body.user.userId
      })
      userInfo.loginType = loginType
      const setting = await api.GET_SETTING_INFO({ id: 1 })
      localStorage.setItem('setting', JSON.stringify(setting.body))
      // 设置 cookie 一定要存 uuid 和 token 两个 cookie
      // 整个系统依赖这两个数据进行校验和存储
      // uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
      // token 代表用户当前登录状态 建议在网络请求中携带 token
      // 如有必要 token 需要定时更新，默认保存一天
      // 设置 vuex 用户信息
      var menus = getLeftMenus(userMenus)
      var routers = getRouters(userMenus)
      var topMenus = getTopMenus(userMenus)
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
      localStorage.setItem('menus', JSON.stringify(menus))
      localStorage.setItem('routers', JSON.stringify(routers))
      localStorage.setItem('topMenus', JSON.stringify(topMenus))
      localStorage.setItem('authoritys', res.body.user.roleCodes)

      // 初始化路由以及页面
      var isLoadRouter = exRoutes()
      isLoadRouter.forEach(res => {
        routes[0].children.push(res)
      })
      router.addRoutes(routes)
      store.commit('d2admin/page/init', isLoadRouter)
      store.commit('d2admin/menu/asideSet', menus)
      store.commit('d2admin/menu/headerSet', topMenus)
      store.commit('d2admin/search/init', topMenus)

      await dispatch(
        'd2admin/user/set',
        userInfo,
        { root: true }
      )
      // 用户登录后从持久化数据加载一系列的设置
      await dispatch('load')
    },
    /**
     * @description 注销用户并返回登录页面
     * @param {Object} context
     * @param {Object} payload confirm {Boolean} 是否需要确认
     */
    logout ({ commit, dispatch }, { confirm = false } = {}) {
      /**
       * @description 注销
       */
      async function logout () {
        // 退出登录请求
        await api.SYS_USER_LOGOUT()
        // 删除cookie
        util.cookies.remove('token')
        util.cookies.remove('uuid')
        // 清空 vuex 用户信息
        await dispatch('d2admin/user/set', {}, { root: true })
        // 跳转路由
        router.push({ name: 'login' })
        localStorage.removeItem('menus')
        localStorage.removeItem('routers')
        localStorage.removeItem('topMenus')
        location.reload()
      }
      // 判断是否需要确认
      if (confirm) {
        commit('d2admin/gray/set', true, { root: true })
        MessageBox.confirm('确定要退出当前用户吗', '退出', { type: 'warning' })
          .then(() => {
            commit('d2admin/gray/set', false, { root: true })
            logout()
          })
          .catch(() => {
            commit('d2admin/gray/set', false, { root: true })
            Message({ message: '取消注销操作' })
          })
      } else {
        logout()
      }
    },
    /**
     * @description 用户登录后从持久化数据加载一系列的设置
     * @param {Object} context
     */
    async load ({ dispatch }) {
      // 加载用户名
      await dispatch('d2admin/user/load', null, { root: true })
      // 加载主题
      await dispatch('d2admin/theme/load', null, { root: true })
      // 加载页面过渡效果设置
      await dispatch('d2admin/transition/load', null, { root: true })
      // 持久化数据加载上次退出时的多页列表
      // await dispatch('d2admin/page/openedLoad', null, { root: true })
      // 持久化数据加载侧边栏配置
      await dispatch('d2admin/menu/asideLoad', null, { root: true })
      // 持久化数据加载全局尺寸
      await dispatch('d2admin/size/load', null, { root: true })
      // 持久化数据加载颜色设置
      await dispatch('d2admin/color/load', null, { root: true })
    },
    async refresh ({ dispatch }) {
      let con = true
      allRoutes.forEach((e) => {
        if (e.name === router.currentRoute.name) {
          con = false
        }
      })
      routes.forEach((e) => {
        if (e.name === router.currentRoute.name) {
          con = false
        }
      })
      if (!con) {
        return
      }
      localStorage.removeItem('menus')
      localStorage.removeItem('routers')
      localStorage.removeItem('topMenus')
      const userDB = await dispatch('d2admin/db/get', {
        dbName: 'sys',
        path: 'user.info',
        defaultValue: {},
        user: true
      }, { root: true })
      await dispatch('d2admin/user/set', {}, { root: true })
      const userInfoRes = await api.GET_USER_INFO({
        userId: userDB.id
      })
      var userInfo = userInfoRes.body
      const userMenus = await api.GET_USER_MENU_LIST({
        userId: userDB.id
      })
      userInfo.loginType = userDB.loginType
      const setting = await api.GET_SETTING_INFO({ id: 1 })
      localStorage.setItem('setting', JSON.stringify(setting.body))
      var menus = getLeftMenus(userMenus)
      var routers = getRouters(userMenus)
      var topMenus = getTopMenus(userMenus)
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
      localStorage.setItem('menus', JSON.stringify(menus))
      localStorage.setItem('routers', JSON.stringify(routers))
      localStorage.setItem('topMenus', JSON.stringify(topMenus))
      localStorage.setItem('authoritys', userInfo.roleCodes)
      const isLoadRouter = exRoutes()
      routes[0].children = []
      isLoadRouter.forEach(res => {
        routes[0].children.push(res)
      })
      allRoutes.forEach(res => {
        routes[0].children.push(res)
      })
      router.matcher = new Router().matcher
      router.addRoutes(routes)
      store.commit('d2admin/page/init', isLoadRouter)
      store.commit('d2admin/menu/asideSet', menus)
      store.commit('d2admin/menu/headerSet', topMenus)
      store.commit('d2admin/search/init', topMenus)
      await dispatch(
        'd2admin/user/set',
        userInfo,
        { root: true }
      )
    }
  }
}
