//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import themeList from './components/d2-theme-list';
export default {
  components: {
    'd2-theme-list': themeList
  },
  data: function data() {
    return {
      dialogVisible: false
    };
  }
};