<template>
  <div class="audit">
    <d2-container>
      <head-search label-style="width:100px">
        <head-search-item label="采购金额">
          <el-input-number :max="selectForm.rightTotalMoney" v-model="selectForm.leftTotalMoney"
            :disabled="permission.isSearch" clearable size="small" @keyup.enter.native="search"></el-input-number>
        </head-search-item>
        <span style="line-height: 30px;margin-left: 10px;margin-right: 10px;">至</span>
        <head-search-item>
          <el-input-number :min="selectForm.leftTotalMoney" v-model="selectForm.rightTotalMoney"
            :disabled="permission.isSearch" clearable size="small" @keyup.enter.native="search"></el-input-number>
        </head-search-item>
        <head-search-item label="采购人">
          <el-select v-model="selectForm.createdIds" :disabled="permission.isSearch" clearable collapse-tags filterable
            multiple placeholder="请选择采购人" size="small">
            <el-option v-for="item in createdIdsData" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="采购单号">
          <el-input v-model="selectForm.purchaseNo" :disabled="permission.isSearch" clearable placeholder="请输采入购单号"
            size="small" @keyup.enter.native="search"></el-input>
        </head-search-item>
        <head-search-item label="仓库名称">
          <el-select v-model="selectForm.warehouseIds" :disabled="permission.isSearch" clearable collapse-tags
            filterable multiple placeholder="请选择仓库名称" size="small">
            <el-option v-for="item in list.warehouse" :key="item.warehouseId" :label="item.warehouseName"
              :value="item.warehouseId"></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="付款方式">
          <el-select v-model="selectForm.payMethod" :disabled="permission.isSearch" clearable filterable
            placeholder="请选择付款方式" size="small">
            <el-option v-for="item in list.payWay" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="发货方式">
          <el-select v-model="selectForm.deliveryMethod" :disabled="permission.isSearch" clearable filterable
            placeholder="请选择发货方式" size="small">
            <el-option v-for="item in list.outWay" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="采购状态">
          <el-select v-model="selectForm.status" :disabled="permission.isSearch" clearable filterable
            placeholder="请选择采购状态" size="small">
            <el-option v-for="item in list.purchaseState" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="审核状态">
          <el-select v-model="selectForm.auditStatus" :disabled="permission.isSearch" clearable placeholder="请选择采购状态"
            size="small">
            <el-option v-for="item in list.auditStatusState" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="提交时间">
          <el-date-picker v-model="selectForm.submitTimeStamp" :disabled="permission.isSearch" end-placeholder="结束日期"
            range-separator="至" size="small" start-placeholder="开始日期" type="daterange"
            value-format="yyyy-MM-dd"></el-date-picker>
        </head-search-item>
        <head-search-item label="审核时间">
          <el-date-picker v-model="selectForm.auditTimeStamp" :disabled="permission.isSearch" end-placeholder="结束日期"
            range-separator="至" size="small" start-placeholder="开始日期" type="daterange"
            value-format="yyyy-MM-dd"></el-date-picker>
        </head-search-item>
        <template slot="button">
          <el-button v-if="!permission.isSearch" icon="el-icon-search" size="small" type="primary" @click="search">
            搜索
          </el-button>
          <el-button v-if="!permission.isSearch" icon="el-icon-refresh" size="small" type="warning" @click="reset">
            重置
          </el-button>
          <el-button class="filter-item" size="small" icon="el-icon-folder-opened" type="success" :loading="exLoading"
            v-if="!permission.isExport" @click="exportExcels">
            导出
          </el-button>
        </template>
      </head-search>
      <div class="table">
        <el-table ref="tableData" v-loading="tableLoading" :cell-style="{ textAlign: 'center' }" :data="auditData"
          :header-cell-style="{
            background: '#dfe6ec',
            color: '#666666',
            textAlign: 'center'
          }" :row-style="{ height: '57px' }" element-loading-text="数据加载中" max-height="620px" tooltip-effect="dark">
          <el-table-column align="center" label="序号" width="80">
            <template slot-scope="scope">
              {{
            (selectForm.pageNo - 1) * selectForm.pageSize + scope.$index + 1
          }}
            </template>
          </el-table-column>
          <el-table-column label="提交时间" width="90">
            <template slot-scope="scope">
              {{ scope.row.createdTimestamp !== null ? scope.row.createdTimestamp : '- -' }}
            </template>
          </el-table-column>
          <el-table-column label="采购单号" prop="purchaseNo" width="180">
            <template slot-scope="scope">
              <div style="display: flex;justify-content: center; width: 100%">
                <div>
                  {{ scope.row.purchaseNo }}
                </div>
                <div class="allocateNoSty" style="margin-left: 20px" @click="copyUrl(scope.row.purchaseNo)">
                  复制
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="商品数量" prop="productNum" width="90"></el-table-column>
          <el-table-column label="采购金额" prop="totalMoney" width="120"></el-table-column>
          <el-table-column label="付款截图" prop="payProve" width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.payProve === ''">- -</div>
              <el-image v-else-if="(scope.row.payProve || '').split(',').length === 1 && scope.row.payProve !== ''"
                :preview-src-list="[downloadUrl + '/' + scope.row.payProve]"
                :src="downloadUrl + '/' + scope.row.payProve" style="width: 40px; height: 40px;" />
              <el-image v-else-if="(scope.row.payProve || '').split(',').length > 1 && scope.row.payProve !== ''"
                :preview-src-list="totalSrcList" :src="downloadUrl + '/' + scope.row.payProve.split(',')[0]"
                style="width: 40px; height: 40px;" @click="getAllPic((scope.row.payProve || '').split(','))" />
            </template>
          </el-table-column>
          <el-table-column label="付款方式" prop="payMethod" width="100"></el-table-column>
          <el-table-column label="采购人" prop="createdName" width="100"></el-table-column>
          <el-table-column label="公司名称" prop="companyName" width="100"></el-table-column>
          <el-table-column label="部门名称" prop="departmentName" width="100"></el-table-column>
          <el-table-column label="仓库名称" prop="warehouseName" width="100"></el-table-column>
          <el-table-column label="发货方式" prop="deliveryMethod" width="80"></el-table-column>
          <el-table-column label="采购状态" prop="status" width="80"></el-table-column>
          <el-table-column label="审核状态" prop="auditStatus" width="90">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.auditStatus === '待审核'">待审核</el-tag>
              <el-tag v-if="scope.row.auditStatus === '审核通过'" type="success">审核通过</el-tag>
              <el-tag v-if="scope.row.auditStatus === '审核不通过'" type="warning">审核不通过</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="审核时间" prop="auditTimestamp" width="90">
            <template slot-scope="scope">
              {{ scope.row.auditTimestamp !== null ? scope.row.auditTimestamp : '- -' }}
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.auditStatus === '待审核' && scope.row.status !== '待采购' && permission.isAudit === false"
                plain size="mini" type="primary"
                @click.native.prevent="audit(scope.row.purchaseNo, scope.row.status)">审核
              </el-button>
              <el-button
                v-if="(scope.row.status === '采购中' || scope.row.status === '已完成') && scope.row.auditStatus === '审核通过' && permission.isAudit === false"
                plain size="mini" type="warning" @click.native.prevent="recheck(scope.row.purchaseNo)">反审
              </el-button>
              <el-button plain size="mini" type="info" v-if="!permission.isInfo"
                @click.native.prevent="goInfo(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :current-page="selectForm.pageNo" :page-size="selectForm.pageSize"
          :page-sizes="[10, 20, 30, 40, 50, 100]" :total="selectForm.pageTotal"
          layout="->,total, sizes, prev, pager, next, jumper" @size-change="sizeChange"
          @current-change="currentChange"></el-pagination>

        <el-dialog :append-to-body="true" :close-on-click-modal="false" :title="'审核'"
          :visible.sync="dialogFormVisibleAudit" style="margin:20px auto" width="520px" @close="closeDialogAudit()">
          <el-form ref="auditCheckData" :model="ruleForm" :rules="rulesAudit" class="ruleSty" label-width="100px">
            <el-form-item label="审核" prop="auditStatus">
              <el-radio v-model="ruleForm.auditStatus" label="1">通过</el-radio>
              <el-radio v-if="this.ruleForm.status === '待终止'" v-model="ruleForm.auditStatus" label="2">不通过
              </el-radio>
            </el-form-item>
            <el-form-item label="备注" prop="auditRemark">
              <el-input v-model="ruleForm.auditRemark" type="textarea"></el-input>
            </el-form-item>
          </el-form>
          <div style="display: flex;justify-content: center;margin-bottom: 20px;">
            <el-button size="small" @click="closeDialogAudit()">
              关闭
            </el-button>
            <el-button :disabled="submitAuditLoading" :loading="submitAuditLoading" size="small" type="success"
              @click.native.prevent="submitAudit()">
              提交
            </el-button>
          </div>
        </el-dialog>
      </div>
    </d2-container>
  </div>
</template>
<script>
import api from '@/api'
import { downloadFileByUrl } from '@/utils/index.js'
const selectFormData = {
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0,
  purchaseNo: '',
  warehouseIds: [],
  payMethod: '',
  deliveryMethod: '',
  status: '',
  auditStatus: '',
  submitTimeStamp: [],
  auditTimeStamp: [],
  selectForm: '',
  leftTotalMoney: undefined,
  rightTotalMoney: undefined,
  sort: null,
  field: ''
}
const auditDefault = {
  auditRemark: '',
  auditStatus: '',
  purchaseNo: '',
  status: ''
}

export default {
  name: 'audit',
  data() {
    return {
      // 权限点
      permission: {
        isSearch: this.checkauthority('purchase_page'), // 搜索
        isExport: this.checkauthority('audit_export'), // 导出
        isAudit: this.checkauthority('purchase_review_procurement'), //  审核
        isInfo: this.checkauthority('purchase_detail') //  详情
      },
      createdIdsData: [],
      exLoading: false,
      totalSrcList: [], // 全部展示图片
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL, // downloadUrl
      auditData: [],
      tableLoading: false,
      searchExcel: JSON.parse(JSON.stringify(selectFormData)),
      submitAuditLoading: false,
      selectForm: JSON.parse(JSON.stringify(selectFormData)),
      ruleForm: JSON.parse(JSON.stringify(auditDefault)),
      dialogFormVisibleAudit: false,
      pageTotal: 0,
      rulesAudit: {
        auditStatus: [
          {
            validator: this.validateAudit,
            trigger: 'blur'
          }
        ],
        auditRemark: [
          {
            validator: this.validateAuditRemark,
            trigger: 'blur'
          }
        ]
      },
      list: {
        channel: [],
        source: [],
        org: [],
        partList: [],
        payWay: [
          {
            label: '对公',
            value: 0
          },
          {
            label: '备用金',
            value: 1
          },
          {
            label: '备用金代付',
            value: 2
          },
          {
            label: '张总',
            value: 3
          },
          {
            label: '上海实体店备用金',
            value: 4
          },
          {
            label: '现金',
            value: 5
          },
          {
            label: '上海店现金',
            value: 6
          },
          { label: '日本备用金', value: 7 },
          { label: '挂账', value: 8 },
          { label: '日币现金', value: 9 },
          { label: '法人信用卡', value: 10 },
          { label: '员工垫付', value: 11 },
          { label: '公司转账', value: 12 },
          { label: '人民币转账', value: 13 }
        ],
        // 发货方式
        outWay: [
          {
            label: '入库',
            value: 0
          },
          {
            label: '代发',
            value: 1
          }
        ],
        // 采购状态
        purchaseState: [
          {
            label: '待采购',
            value: 0
          },
          {
            label: '采购中',
            value: 1
          },
          {
            label: '已完成',
            value: 2
          },
          {
            label: '待终止',
            value: 3
          },
          {
            label: '已终止',
            value: 9
          }
        ],
        // 审核状态
        auditStatusState: [
          {
            label: '待审核',
            value: 0
          },
          {
            label: '审核通过',
            value: 1
          },
          {
            label: '审核不通过',
            value: 2
          }
        ]
      }
    }
  },
  methods: {
    async getPurchaseUser() {
      const res = await api.getPurchaseUser()
      this.createdIdsData = res.body
    },
    async exportExcels() {
      this.exLoading = true
      try {
        // delete this.searchExcel.submitTimeStamp
        // delete this.searchExcel.auditTimeStamp
        const { body: data } = await api.enumeration_exportExcel(this.searchExcel)
        downloadFileByUrl(data)
      } finally {
        this.exLoading = false
      }
    },
    getAllPic(arr) {
      arr.forEach((item) => {
        this.totalSrcList.push(this.downloadUrl + '/' + item)
      })
    },
    // /定义点击复制的功能
    copyUrl(url) {
      // 创建一个 Input标签
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      // 执行浏览器复制命令
      // / 复制命令会将当前选中的内容复制到剪切板中
      // / 如这里构建的 Input标签
      document.execCommand('Copy')
      this.$message({
        message: '复制成功',
        type: 'success'
      })
      // /复制成功后再将构造的标签 移除
      oInput.remove()
    },
    async getChannel() {
      const channel = await api.enumeration_getList({ name: 'channel' })
      this.list.channel = channel.body
    },
    async getSource() {
      const source = await api.enumeration_getList({ name: 'source' })
      this.list.source = source.body
    },
    async getOrg() {
      const org = await api.getorgselect()
      this.list.org = org.body
    },
    async getWarehouse() {
      const { body: data } = await api.getPurchaseWarehouse()
      this.list.warehouse = data
    },
    async getPart() {
      console.log(this.selectForm)
      const part = await api.getDeptList({ orgPid: this.selectForm.orgId })
      this.list.partList = part.body
    },
    // 初始化
    async initList() {
      this.tableLoading = true
      const obj = {
        auditStartTime: this.selectForm.auditTimeStamp[0] ? this.selectForm.auditTimeStamp[0] : '',
        auditEndTime: this.selectForm.auditTimeStamp[1] ? this.selectForm.auditTimeStamp[1] : '',
        startTime: this.selectForm.submitTimeStamp[0] ? this.selectForm.submitTimeStamp[0] : '',
        endTime: this.selectForm.submitTimeStamp[1] ? this.selectForm.submitTimeStamp[1] : '',
        auditStatus: this.selectForm.auditStatus,
        companyIds: this.selectForm.companyIds,
        deliveryMethod: this.selectForm.deliveryMethod,
        deptIds: this.selectForm.deptIds,
        payMethod: this.selectForm.payMethod,
        status: this.selectForm.status,
        createdIds: this.selectForm.createdIds,
        warehouseIds: this.selectForm.warehouseIds,
        purchaseNo: this.selectForm.purchaseNo,
        pageNo: this.selectForm.pageNo,
        pageSize: this.selectForm.pageSize,
        leftTotalMoney: this.selectForm.leftTotalMoney,
        rightTotalMoney: this.selectForm.rightTotalMoney,
        sort: 0,
        field: ''
      }
      const res = await api.getPageList_audit(obj)
      if (res.code === 200) {
        this.auditData = res.body.datas
        this.selectForm.pageNo = res.body.pageNo
        this.selectForm.pageSize = res.body.pageSize
        this.selectForm.pageTotal = res.body.sumDataCount
        this.searchExcel = JSON.parse(JSON.stringify({
          ...this.selectForm,
          auditStartTime: this.selectForm.auditTimeStamp[0] ? this.selectForm.auditTimeStamp[0] : '',
          auditEndTime: this.selectForm.auditTimeStamp[1] ? this.selectForm.auditTimeStamp[1] : '',
          startTime: this.selectForm.submitTimeStamp[0] ? this.selectForm.submitTimeStamp[0] : '',
          endTime: this.selectForm.submitTimeStamp[1] ? this.selectForm.submitTimeStamp[1] : ''
        }))
      } else if (res.code !== 200) {
        this.$message.error('列表数据加载失败')
      }
      this.tableLoading = false
    },
    // 搜索
    async search() {
      this.search.pageNo = 1
      await this.initList()
    },
    // 重置
    reset() {
      this.selectForm = JSON.parse(JSON.stringify(selectFormData))
      this.initList()
    },
    sizeChange(val) {
      this.selectForm.pageSize = val
      this.search()
    },
    currentChange(val) {
      this.selectForm.pageNo = val
      this.search()
    },
    // 审核
    audit(id, status) {
      this.ruleForm.purchaseNo = id
      this.ruleForm.status = status
      this.dialogFormVisibleAudit = true
    },
    // 反审
    recheck(id) {
      this.$confirm('是否将  ’' + id + '‘  订单审核状态变成待审核?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await api.checkAudit({
          auditRemark: '',
          auditStatus: 0,
          purchaseNo: id,
          reverseReview: 1
        })
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '修改为待审核成功!'
          })
          setTimeout(() => {
            this.initList()
          }, 1000)
        } else if (res.code !== 200) {
          this.$message.error('修改失败')
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消修改'
        })
      })
    },
    // 关闭审核
    closeDialogAudit() {
      this.dialogFormVisibleAudit = false
      this.ruleForm = JSON.parse(JSON.stringify(auditDefault))
      this.$refs.auditCheckData.clearValidate()
    },
    // 审核提交
    submitAudit() {
      this.$refs.auditCheckData.validate(async (valid) => {
        if (valid) {
          this.submitAuditLoading = true
          const res = await api.checkAudit({
            auditRemark: this.ruleForm.auditRemark,
            auditStatus: this.ruleForm.auditStatus,
            purchaseNo: this.ruleForm.purchaseNo,
            reverseReview: 0
          })
          if (res.code === 200) {
            this.$message.success('审核成功')
            this.submitAuditLoading = false
            this.closeDialogAudit()
            setTimeout(() => {
              this.initList()
            }, 1000)
          } else if (res.code !== 200) {
            this.submitAuditLoading = false
            this.$message.error('审核有误')
          }
        } else {
          this.$message.warning('输入有误,请仔细检查')
        }
      })
      this.submitAuditLoading = false
    },
    // 详情
    async goInfo(row) {
      await this.$router.push({
        path: '/auditDetail',
        query: {
          purchaseNo: row.purchaseNo
        }
      })
    },
    // 审核状态校验
    validateAudit(rule, value, callback) {
      console.log(value)
      if (!value) {
        callback(new Error('请选择审核状态'))
      } else {
        callback()
      }
    },
    // 不通过校验
    validateAuditRemark(rule, value, callback) {
      if (this.ruleForm.auditStatus == 2 && value === '') {
        callback(new Error('请输入拒绝理由'))
      } else {
        callback()
      }
    }
  },
  created() {
    this.getChannel()
    this.getSource()
    this.getOrg()
    this.getWarehouse()
    this.initList()
    this.getPurchaseUser()
  }
}
</script>
<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

.allocateNoSty {
  font-size: 10px;
  cursor: pointer;
  color: #1482f0;
}
</style>
