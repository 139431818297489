var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c(
      "div",
      [
        _c("div", [
          !_vm.permission.isWarehouse
            ? _c("div", [
                _c("div", { staticClass: "headline" }, [
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v("仓库详情")
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-around",
                      "line-height": "50px",
                      "font-size": "20px"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "headerData",
                        attrs: { title: this.warehouseName }
                      },
                      [
                        _vm._v(" 仓库名称： "),
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(_vm._s(this.warehouseName))
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "headerData",
                        attrs: { title: this.warehouseAddress }
                      },
                      [
                        _vm._v(" 仓库地址： "),
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(_vm._s(this.warehouseAddress))
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "headerData",
                        attrs: { title: this.areaNum }
                      },
                      [
                        _vm._v(" 区域数量： "),
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(_vm._s(this.areaNum))
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "headerData",
                        attrs: { title: this.shelvesNum }
                      },
                      [
                        _vm._v(" 货架数量： "),
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(_vm._s(this.shelvesNum))
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "headerData",
                        staticStyle: { "font-weight": "bold" },
                        attrs: { title: this.layerNum }
                      },
                      [
                        _vm._v(" 层数总计： "),
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(
                            _vm._s(this.layerNum == null ? 0 : this.layerNum)
                          )
                        ])
                      ]
                    )
                  ]
                )
              ])
            : _vm._e()
        ]),
        _c(
          "div",
          {
            staticStyle: {
              position: "absolute",
              right: "25px",
              "margin-top": "5px"
            }
          },
          [
            !_vm.permission.isAddArea
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "success",
                      size: "small",
                      icon: "el-icon-plus"
                    },
                    on: { click: _vm.addArea }
                  },
                  [_vm._v(" 新增区域 ")]
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          [
            _c("div", { staticClass: "headline" }, [
              _c("span", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v("区域列表")
              ])
            ]),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%", "margin-top": "20px" },
                attrs: {
                  data: _vm.particularsTableData,
                  "header-cell-style": {
                    background: "#dfe6ec",
                    color: "#666666",
                    textAlign: "center"
                  },
                  "cell-style": { textAlign: "center" }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "date", label: "序号", width: "180" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                (_vm.search2.currpage - 1) *
                                  _vm.search2.pagesize +
                                  scope.$index +
                                  1
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { prop: "areaName", label: "区域名称", width: "180" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "shelvesNum", label: "货架数量" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "layerNum", label: "层数总计" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.layerNum == null
                                  ? 0
                                  : scope.row.layerNum
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "all",
                    label: "操作",
                    fixed: "right",
                    width: "350"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          !_vm.permission.isEditorArea
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "success",
                                    size: "small",
                                    plain: "",
                                    icon: "el-icon-edit-outline"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.editorArea(scope.$index)
                                    }
                                  }
                                },
                                [_vm._v(" 编辑区域 ")]
                              )
                            : _vm._e(),
                          !_vm.permission.isEditorShelves
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "info",
                                    size: "small",
                                    plain: "",
                                    icon: "el-icon-info"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.areaParticulars(scope.$index)
                                    }
                                  }
                                },
                                [_vm._v(" 区域详情 ")]
                              )
                            : _vm._e(),
                          !_vm.permission.isDeleteArea
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    disabled: _vm.areaDeleteLoading,
                                    loading: _vm.areaDeleteLoading,
                                    size: "small",
                                    plain: "",
                                    icon: "el-icon-delete"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.areaDelete(scope.$index)
                                    }
                                  }
                                },
                                [_vm._v(" 删除区域 ")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm.search2.currentPage !== 0
              ? _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.search2.currpage,
                        "page-sizes": [10, 20, 30, 40, 50, 100],
                        "page-size": _vm.search2.pagesize,
                        layout: "total, jumper, prev, pager, next,sizes",
                        total: _vm.search2.currentPage
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              "append-to-body": true,
              title: _vm.areaTitle,
              visible: _vm.dialogVisible2,
              width: "400px",
              "close-on-click-modal": false
            },
            on: {
              "update:visible": function($event) {
                _vm.dialogVisible2 = $event
              },
              close: _vm.WarehouseDialog1
            }
          },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm1",
                attrs: {
                  "label-position": "right",
                  "label-width": "80px",
                  model: _vm.form2,
                  rules: _vm.rules1
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "区域名称", prop: "areaName" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "200px" },
                      attrs: { size: "small", clearable: "" },
                      model: {
                        value: _vm.form2.areaName,
                        callback: function($$v) {
                          _vm.$set(_vm.form2, "areaName", $$v)
                        },
                        expression: "form2.areaName"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.areaCancel()
                      }
                    }
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      disabled: _vm.areaDetermineLoading,
                      loading: _vm.areaDetermineLoading,
                      size: "small"
                    },
                    on: { click: _vm.areaDetermine }
                  },
                  [_vm._v(" 确 定 ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }