export default ({ request }) => ({
  // 分页查询list
  getPageList_warehouseStatisticalList (data = {}) {
    return request({
      url: '/v1/statistics/dailyInto',
      method: 'post',
      data: data
    })
  },
  // 分页查询汇总
  getPageList_warehouseStatisticalSum (data = {}) {
    return request({
      url: '/v1/statistics/dailyIntoSum',
      method: 'post',
      data: data
    })
  },
  // 入库商品详情
  intoStaticalGoodsList (data = {}) {
    return request({
      url: '/v1/intoStatical/goodsList',
      method: 'post',
      data: data
    })
  },
  // 入库商品详情聚合统计
  intoStaticalGoodsSum (data = {}) {
    return request({
      url: '/v1/intoStatical/goodsSum',
      method: 'post',
      data: data
    })
  },
  // 入库订单详情
  intoStaticalOrderList (data = {}) {
    return request({
      url: '/v1/intoStatical/OrderList',
      method: 'post',
      data: data
    })
  },
  // 入库订单详情聚合统计
  intoStaticalOrderSum (data = {}) {
    return request({
      url: '/v1/intoStatical/orderSum',
      method: 'post',
      data: data
    })
  },
  // 入库订单维度导出
  exportIntoOrder (data = {}) {
    return request({
      url: '/v1/intoStatical/exportIntoOrder',
      method: 'post',
      data: data
    })
  },
  // 入库商品维度导出
  exportIntoGoods (data = {}) {
    return request({
      url: '/v1/intoStatical/exportIntoGoods',
      method: 'post',
      data: data
    })
  }
})
