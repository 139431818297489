var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "varehouseTable" },
    [
      _c(
        _vm.type,
        { tag: "component" },
        [
          !_vm.permission.isCheck
            ? _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "success", size: "small" },
                  on: {
                    click: function($event) {
                      _vm.type = _vm.type === "goods" ? "stock" : "goods"
                    }
                  }
                },
                [
                  _vm._v(
                    "按" +
                      _vm._s(_vm.type === "goods" ? "库位" : "商品") +
                      "显示"
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }