//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Pagination',
  props: {
    pages: {
      limit: 5,
      totalSize: 0,
      page: 1,
      nextToken: ''
    },
    nextTokenArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      storageTokenArr: this.nextTokenArr,
      currentPage: 1,
      page: this.pages
    };
  },
  computed: {
    storage: function storage() {
      if (this.page.nextToken) {
        var flag = true;

        for (var i = 0; i < this.storageTokenArr.length; i++) {
          if (this.storageTokenArr[i].page === this.page.page) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.storageTokenArr[i].token = this.page.nextToken;
            flag = false;
          }
        }

        if (flag) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.storageTokenArr.push({
            page: this.page.page,
            token: this.page.nextToken
          });
        }
      } else {
        if (this.page.page === 1) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.storageTokenArr = [];
        }
      } // console.log(this.storageTokenArr)


      return this.storageTokenArr;
    }
  },
  created: function created() {},
  methods: {
    // 每页条数改变
    sizeChangeHandler: function sizeChangeHandler(e) {
      this.page.limit = e;
      this.page.page = 1;
      this.page.nextToken = null;
      this.storageTokenArr = [];
      this.$emit('page', this.page);
      this.$emit('nextTokenArr', this.storageTokenArr);
      this.$emit('initList'); // 每页条数改变回到顶部

      this.$nextTick(function () {
        window.scrollTo(0, 0);
      });
    },
    // 上一页
    upPage: function upPage(e) {
      if (e - 1 == 0) {
        this.page.page = e;
        this.page.nextToken = null;
        this.storageTokenArr = [];
        this.$emit('page', this.page);
        this.$emit('nextTokenArr', this.storageTokenArr);
        this.$emit('initList');
      } else {
        this.page.page = e;

        if (e - 2 < 0) {
          this.page.page = e;
          this.page.nextToken = null;
          this.storageTokenArr = [];
          this.$emit('page', this.page);
          this.$emit('nextTokenArr', this.storageTokenArr);
          this.$emit('initList');
        } else {
          for (var i = 0; i < this.storageTokenArr.length; i++) {
            if (this.storageTokenArr[i].page == e - 1) {
              this.page.nextToken = this.storageTokenArr[i].token;
            }
          }
        }

        this.$emit('page', this.page);
        this.$emit('nextTokenArr', this.storageTokenArr);
        this.$emit('initList');
      } // 点击上一页回到顶部


      this.$nextTick(function () {
        window.scrollTo(0, 0);
      });
    },
    nextPage: function nextPage(e) {
      this.page.page = e;

      for (var i = 0; i < this.storageTokenArr.length; i++) {
        if (this.storageTokenArr[i].page == e - 1) {
          this.page.nextToken = this.storageTokenArr[i].token;
        }
      }

      this.$emit('nextTokenArr', this.storageTokenArr);
      this.$emit('page', this.page);
      this.$emit('initList'); // 点击下一页回到顶部

      this.$nextTick(function () {
        window.scrollTo(0, 0);
      });
    }
  }
};