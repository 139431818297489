var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "div",
        { staticClass: "stockposition" },
        [
          _c(
            "head-search",
            { attrs: { "label-style": "width:100px;" } },
            [
              _c(
                "head-search-item",
                { attrs: { label: "商品条码" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品条码", size: "small" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.search($event)
                      }
                    },
                    model: {
                      value: _vm.selectForm.barCode,
                      callback: function($$v) {
                        _vm.$set(_vm.selectForm, "barCode", $$v)
                      },
                      expression: "selectForm.barCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品名称", size: "small" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.search($event)
                      }
                    },
                    model: {
                      value: _vm.selectForm.goodsName,
                      callback: function($$v) {
                        _vm.$set(_vm.selectForm, "goodsName", $$v)
                      },
                      expression: "selectForm.goodsName"
                    }
                  })
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "一级分类" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "collapse-tags": "",
                        multiple: "",
                        placeholder: "请选择一级分类",
                        size: "small"
                      },
                      model: {
                        value: _vm.selectForm.id1s,
                        callback: function($$v) {
                          _vm.$set(_vm.selectForm, "id1s", $$v)
                        },
                        expression: "selectForm.id1s"
                      }
                    },
                    _vm._l(_vm.options[0], function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.classifyName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "二级分类" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "collapse-tags": "",
                        multiple: "",
                        placeholder: "请选择二级分类",
                        size: "small"
                      },
                      model: {
                        value: _vm.selectForm.id2s,
                        callback: function($$v) {
                          _vm.$set(_vm.selectForm, "id2s", $$v)
                        },
                        expression: "selectForm.id2s"
                      }
                    },
                    _vm._l(_vm.options[1], function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.classifyName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "三级分类" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "collapse-tags": "",
                        multiple: "",
                        placeholder: "请选择三级分类",
                        size: "small"
                      },
                      model: {
                        value: _vm.selectForm.id3s,
                        callback: function($$v) {
                          _vm.$set(_vm.selectForm, "id3s", $$v)
                        },
                        expression: "selectForm.id3s"
                      }
                    },
                    _vm._l(_vm.options[2], function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.classifyName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "商品品牌" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "collapse-tags": "",
                        multiple: "",
                        placeholder: "请选择商品品牌",
                        size: "small"
                      },
                      model: {
                        value: _vm.selectForm.brands,
                        callback: function($$v) {
                          _vm.$set(_vm.selectForm, "brands", $$v)
                        },
                        expression: "selectForm.brands"
                      }
                    },
                    _vm._l(_vm.options[3], function(item) {
                      return _c("el-option", {
                        key: item.id,
                        staticStyle: {
                          width: "300px",
                          overflow: "scroll",
                          "word-break": "break-all",
                          height: "fit-content",
                          "white-space": "inherit"
                        },
                        attrs: { label: item.brandName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "商品IP" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "90%" },
                      attrs: {
                        "collapse-tags": "",
                        multiple: "",
                        placeholder: "请选择商品IP",
                        size: "small"
                      },
                      model: {
                        value: _vm.selectForm.ips,
                        callback: function($$v) {
                          _vm.$set(_vm.selectForm, "ips", $$v)
                        },
                        expression: "selectForm.ips"
                      }
                    },
                    _vm._l(_vm.options[4], function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.ipName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "collapse-tags": "",
                        multiple: "",
                        placeholder: "请选择状态",
                        size: "small"
                      },
                      model: {
                        value: _vm.selectForm.statusList,
                        callback: function($$v) {
                          _vm.$set(_vm.selectForm, "statusList", $$v)
                        },
                        expression: "selectForm.statusList"
                      }
                    },
                    _vm._l(_vm.options[6], function(item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.label, value: item.key }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "区域名称" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择区域名称", size: "small" },
                      on: { change: _vm.areaChange },
                      model: {
                        value: _vm.selectForm.areaId,
                        callback: function($$v) {
                          _vm.$set(_vm.selectForm, "areaId", $$v)
                        },
                        expression: "selectForm.areaId"
                      }
                    },
                    _vm._l(_vm.options[5], function(item) {
                      return _c("el-option", {
                        key: item.areaId,
                        attrs: { label: item.areaName, value: item.areaId }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "货架名称" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.shelvesDisable,
                        placeholder: "请选择货架名称",
                        size: "small"
                      },
                      model: {
                        value: _vm.selectForm.shelvesId,
                        callback: function($$v) {
                          _vm.$set(_vm.selectForm, "shelvesId", $$v)
                        },
                        expression: "selectForm.shelvesId"
                      }
                    },
                    _vm._l(_vm.shelvesOptions, function(item) {
                      return _c("el-option", {
                        key: item.shelvesId,
                        attrs: {
                          label: item.shelvesName,
                          value: item.shelvesId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "template",
                { slot: "button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        size: "small",
                        type: "primary"
                      },
                      on: { click: _vm.search }
                    },
                    [_vm._v(" 搜索 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh",
                        size: "small",
                        type: "warning"
                      },
                      on: { click: _vm.reset }
                    },
                    [_vm._v(" 重置 ")]
                  ),
                  !_vm.permission.isExport
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.exportLoading,
                            loading: _vm.exportLoading,
                            icon: "el-icon-download",
                            size: "small",
                            type: "success"
                          },
                          on: { click: _vm.exportExcel }
                        },
                        [_vm._v(" 导出 ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          ),
          _c("br"),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading"
                }
              ],
              ref: "tableData",
              attrs: {
                "cell-style": { textAlign: "center" },
                data: _vm.stockTable,
                "header-cell-style": {
                  background: "#dfe6ec",
                  color: "#666666",
                  textAlign: "center"
                },
                "row-class-name": _vm.damagefreight,
                "row-style": { height: "40px" },
                "element-loading-text": "数据加载中",
                "max-height": "620px",
                "tooltip-effect": "dark"
              }
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "序号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              (_vm.selectForm.pageNo - 1) *
                                _vm.selectForm.pageSize +
                                scope.$index +
                                1
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品编码",
                  prop: "goodsCoding",
                  width: "120"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品条码",
                  prop: "barCode",
                  width: "165"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品名称",
                  prop: "goodsName",
                  width: "200"
                }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "商品图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "40px", height: "40px" },
                          attrs: {
                            "preview-src-list": [
                              _vm.$oss + scope.row.pictureUrl
                            ],
                            src: _vm.$oss + scope.row.pictureUrl
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "一级分类", prop: "goodsOne" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.goodsOne ? scope.row.goodsOne : "- -"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "二级分类", prop: "goodsTwo" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.goodsTwo ? scope.row.goodsTwo : "- -"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "三级分类",
                  prop: "goodsThree"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.goodsThree
                                ? scope.row.goodsThree
                                : "- -"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": true,
                  align: "center",
                  label: "商品品牌",
                  prop: "goodsBrandName",
                  width: "120"
                }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "商品IP", prop: "goodsIpName" }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "状态", prop: "status" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.status == 0
                                ? "正常"
                                : scope.row.status == 1
                                ? "损坏"
                                : scope.row.status == 2
                                ? "丢失"
                                : ""
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "区域名称", prop: "areaName" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.areaName ? scope.row.areaName : "- -"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "货架名称",
                  prop: "shelvesName"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.shelvesName
                                ? scope.row.shelvesName
                                : "- -"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "货架层数",
                  prop: "shelvesLayers"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.shelvesLayers
                                ? scope.row.shelvesLayers
                                : "- -"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作", width: "220" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        !_vm.permission.isInfo
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-info",
                                  plain: "",
                                  size: "small",
                                  type: "info"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.detailsLog(scope.row)
                                  }
                                }
                              },
                              [_vm._v(" 详情 ")]
                            )
                          : _vm._e(),
                        !_vm.permission.isCode
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  type: "primary",
                                  disabled: _vm.makeLoading,
                                  loading: _vm.makeLoading
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.make(scope.row)
                                  }
                                }
                              },
                              [_vm._v(" 重新生码 ")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.selectForm.pageNo,
              "page-size": _vm.selectForm.pageSize,
              "page-sizes": [10, 20, 30, 40, 50, 100],
              total: _vm.pageTotal,
              layout: "->,total, jumper, prev, pager, next,sizes"
            },
            on: {
              "size-change": _vm.sizeChange,
              "current-change": _vm.currentChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { "close-on-click-modal": false, visible: _vm.dialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.detailsTableLoading,
                  expression: "detailsTableLoading"
                }
              ],
              attrs: { data: _vm.detaildsTable }
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "序号", type: "index" }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "时间", prop: "createTime" }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作人", prop: "createBy" }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "单号", prop: "orderNo" }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "扫码时间",
                  prop: "confirmationTime"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.confirmationTime
                                ? scope.row.confirmationTime
                                : "- -"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "扫码人",
                  prop: "confirmationBy"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.confirmationBy
                                ? scope.row.confirmationBy
                                : "- -"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }