// 出库
export default (function (_ref) {
  var request = _ref.request;
  return {
    // 查询出库单列表
    outbound_getPageList: function outbound_getPageList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/outbound/getPageList',
        method: 'post',
        data: data
      });
    },
    // 新增出库单
    outbound_save: function outbound_save() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/outbound/save',
        method: 'post',
        data: data
      });
    },
    // 编辑出库单
    outbound_edit: function outbound_edit() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/outbound/update',
        method: 'put',
        data: data
      });
    },
    outbound_info: function outbound_info() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/outbound/info',
        method: 'post',
        data: data
      });
    },
    // 当入库状态不是待出库的时候的明细查询
    outbound_detail: function outbound_detail() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/outbound/detail',
        method: 'post',
        data: data
      });
    },
    // 获取枚举类型下拉列表
    selectedEnums_getList: function selectedEnums_getList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/selectedEnums/getList',
        method: 'post',
        data: data
      });
    },
    inventory_selectPrice: function inventory_selectPrice() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/selectPrice',
        method: 'post',
        data: data
      });
    },
    outbound_outLog: function outbound_outLog() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/outbound/outLog',
        method: 'post',
        data: data
      });
    },
    outbound_stopOut: function outbound_stopOut() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/outbound/stopOut',
        method: 'post',
        data: data
      });
    },
    outDetail_updateGoods: function outDetail_updateGoods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/outDetail/updateGoods',
        method: 'post',
        data: data
      });
    },
    outDetail_copyOutBound: function outDetail_copyOutBound() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/outbound/copyOutBound',
        method: 'post',
        data: data
      });
    }
  };
});