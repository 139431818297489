var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { staticClass: "d2-mr", attrs: { size: "small" } },
    [
      _c("el-dropdown-menu"),
      _c(
        "div",
        {
          on: {
            click: function($event) {
              return _vm.upNew()
            }
          }
        },
        [
          _c("i", { staticClass: "el-icon-question" }),
          _c("span", { staticClass: "btn-text" }, [_vm._v("新手帮助")])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }