export default (function (_ref) {
  var request = _ref.request;
  return {
    // 获取采购商品列表
    getPurchaseSummaryInfoList: function getPurchaseSummaryInfoList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/purchase/statical/getGoodsList',
        method: 'post',
        data: data
      });
    },
    // 获取采购商品统计
    getPurchaseSummaryInfoCount: function getPurchaseSummaryInfoCount() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/purchase/statical/getPurNewGoodsResult',
        method: 'post',
        data: data
      });
    }
  };
});