var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "dashboard-editor-container" }, [
      _c("div", { staticClass: "systemTime" }, [
        _vm._v("截止到:" + _vm._s(this.systemTime))
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingCard,
              expression: "loadingCard"
            }
          ],
          staticClass: "cardRules"
        },
        [
          _c(
            "div",
            {
              staticClass: "cardSty",
              staticStyle: { "background-color": "#f77740" }
            },
            [
              _c("div", { staticClass: "svgSty" }, [
                _c(
                  "svg",
                  { staticClass: "icon", attrs: { "aria-hidden": "true" } },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "#icon-icon_kucunshangpin" }
                    })
                  ]
                )
              ]),
              _c(
                "el-statistic",
                {
                  staticClass: "statisticSty",
                  attrs: { title: "库存商品数量" }
                },
                [
                  _c("template", { slot: "formatter" }, [
                    _c("span", { staticClass: "font" }, [
                      _vm._v(_vm._s(this.tableData.invProductNum))
                    ])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "cardSty",
              staticStyle: { "background-color": "#4f86f7" }
            },
            [
              _c("div", { staticClass: "svgSty" }, [
                _c(
                  "svg",
                  { staticClass: "icon", attrs: { "aria-hidden": "true" } },
                  [_c("use", { attrs: { "xlink:href": "#icon-jine1" } })]
                )
              ]),
              _c(
                "el-statistic",
                {
                  staticClass: "statisticSty",
                  attrs: { title: "库存商品总金额" }
                },
                [
                  _c("template", { slot: "formatter" }, [
                    _c("span", { staticClass: "font" }, [
                      _vm._v(_vm._s(this.tableData.invProductMoney))
                    ])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "cardSty",
              staticStyle: { "background-color": "#cc3333" }
            },
            [
              _c("div", { staticClass: "svgSty" }, [
                _c(
                  "svg",
                  { staticClass: "icon", attrs: { "aria-hidden": "true" } },
                  [
                    _c("use", { attrs: { "xlink:href": "#icon-jine" } }),
                    _vm._v(" #icon-jine ")
                  ]
                )
              ]),
              _c(
                "el-statistic",
                {
                  staticClass: "statisticSty",
                  attrs: { title: "在途商品总金额" }
                },
                [
                  _c("template", { slot: "formatter" }, [
                    _c("span", { staticClass: "font" }, [
                      _vm._v(_vm._s(this.tableData.ingIntoOrderMoney))
                    ])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "cardSty",
              staticStyle: { "background-color": "#33ccff" }
            },
            [
              _c("div", { staticClass: "svgSty" }, [
                _c(
                  "svg",
                  { staticClass: "icon", attrs: { "aria-hidden": "true" } },
                  [_c("use", { attrs: { "xlink:href": "#icon-dingdan-" } })]
                )
              ]),
              _c(
                "el-statistic",
                {
                  staticClass: "statisticSty",
                  attrs: { title: "采购订单数量" }
                },
                [
                  _c("template", { slot: "formatter" }, [
                    _c("span", { staticClass: "font" }, [
                      _vm._v(_vm._s(this.tableData.purchaseNum))
                    ])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "cardSty",
              staticStyle: { "background-color": "#9443f4" }
            },
            [
              _c("div", { staticClass: "svgSty" }, [
                _c(
                  "svg",
                  { staticClass: "icon", attrs: { "aria-hidden": "true" } },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "#icon-dingdanzhongxin" }
                    })
                  ]
                )
              ]),
              _c(
                "el-statistic",
                {
                  staticClass: "statisticSty",
                  attrs: { title: "代发订单数量" }
                },
                [
                  _c("template", { slot: "formatter" }, [
                    _c("span", { staticClass: "font" }, [
                      _vm._v(_vm._s(this.tableData.issuingNum))
                    ])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "cardSty",
              staticStyle: { "background-color": "#99cc66" }
            },
            [
              _c("div", { staticClass: "svgSty" }, [
                _c(
                  "svg",
                  { staticClass: "icon", attrs: { "aria-hidden": "true" } },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "#icon-zongheyewudingdan" }
                    })
                  ]
                )
              ]),
              _c(
                "el-statistic",
                {
                  staticClass: "statisticSty",
                  attrs: { title: "调拨订单数量" }
                },
                [
                  _c("template", { slot: "formatter" }, [
                    _c("span", { staticClass: "font" }, [
                      _vm._v(_vm._s(this.tableData.allotNum))
                    ])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "cardSty",
              staticStyle: { "background-color": "#ff6699" }
            },
            [
              _c("div", { staticClass: "svgSty" }, [
                _c(
                  "svg",
                  { staticClass: "icon", attrs: { "aria-hidden": "true" } },
                  [_c("use", { attrs: { "xlink:href": "#icon-list" } })]
                )
              ]),
              _c(
                "el-statistic",
                {
                  staticClass: "statisticSty",
                  attrs: { title: "入库订单数量" }
                },
                [
                  _c("template", { slot: "formatter" }, [
                    _c("span", { staticClass: "font" }, [
                      _vm._v(_vm._s(this.tableData.intoNum))
                    ])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "cardSty",
              staticStyle: { "background-color": "#996666" }
            },
            [
              _c("div", { staticClass: "svgSty" }, [
                _c(
                  "svg",
                  { staticClass: "icon", attrs: { "aria-hidden": "true" } },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "#icon-dingdanguanli" }
                    })
                  ]
                )
              ]),
              _c(
                "el-statistic",
                {
                  staticClass: "statisticSty",
                  attrs: { title: "出库订单数量" }
                },
                [
                  _c("template", { slot: "formatter" }, [
                    _c("span", { staticClass: "font" }, [
                      _vm._v(_vm._s(this.tableData.outNum))
                    ])
                  ])
                ],
                2
              )
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }