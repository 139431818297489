import "core-js/modules/es.object.to-string";
import Vue from 'vue';
import d2Container from './d2-container'; // 注意 有些组件使用异步加载会有影响

Vue.component('d2-container', d2Container);
Vue.component('d2-icon', function () {
  return import('./d2-icon');
});
Vue.component('d2-icon-svg', function () {
  return import('./d2-icon-svg/index.vue');
});
Vue.component('erp-upload-img', function () {
  return import('./erp-upload-img/index.vue');
});
Vue.component('erp-upload-video', function () {
  return import('./erp-upload-video/index.vue');
});
Vue.component('erp-Editor', function () {
  return import('./erp-wangEditor/index.vue');
});
Vue.component('erp-isJurisdiction-search', function () {
  return import('./erp-isJurisdiction-search/index.vue');
});
Vue.component('head-search', function () {
  return import('./head-search/index.vue');
});
Vue.component('head-search-item', function () {
  return import('./head-search/search-item/index.vue');
});
Vue.component('purchaseSelectGoods', function () {
  return import('./purchaseSelectGoods/index.vue');
});