var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c("div", { staticClass: "goods" }, [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "head-search",
            { attrs: { "label-style": "width:100px" } },
            [
              _c(
                "head-search-item",
                { attrs: { label: "商品编码" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品编码", size: "small" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.Search($event)
                      }
                    },
                    model: {
                      value: _vm.search.goodsId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.search,
                          "goodsId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "search.goodsId"
                    }
                  })
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品名称", size: "small" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.Search($event)
                      }
                    },
                    model: {
                      value: _vm.search.goodsName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.search,
                          "goodsName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "search.goodsName"
                    }
                  })
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "仓库名称" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "searchInput",
                      attrs: {
                        clearable: "",
                        placeholder: "请选择仓库名称",
                        size: "small"
                      },
                      model: {
                        value: _vm.search.warehouseId,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.search,
                            "warehouseId",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "search.warehouseId"
                      }
                    },
                    _vm._l(_vm.warehouseList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.warehouseName,
                          value: item.warehouseId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "入库价区间" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入最低价格", size: "small" },
                    model: {
                      value: _vm.search.startPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.search, "startPrice", $$v)
                      },
                      expression: "search.startPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "--", "label-style": "width:30px" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入最高价格", size: "small" },
                    model: {
                      value: _vm.search.endPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.search, "endPrice", $$v)
                      },
                      expression: "search.endPrice"
                    }
                  })
                ],
                1
              ),
              _vm._t("default")
            ],
            2
          ),
          _c(
            "head-search",
            { attrs: { "label-style": "width:100px" } },
            [
              _c(
                "template",
                { slot: "button" },
                [
                  !_vm.permission.isSearch
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            size: "small",
                            type: "primary"
                          },
                          on: { click: _vm.Search }
                        },
                        [_vm._v(" 搜索 ")]
                      )
                    : _vm._e(),
                  !_vm.permission.isSearch
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-refresh",
                            size: "small",
                            type: "warning"
                          },
                          on: { click: _vm.Reset }
                        },
                        [_vm._v(" 重置 ")]
                      )
                    : _vm._e(),
                  !_vm.permission.isExport
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.exportLoading,
                            loading: _vm.exportLoading,
                            icon: "el-icon-download",
                            size: "small",
                            type: "success"
                          },
                          on: {
                            click: function($event) {
                              return _vm.exportExcel(true)
                            }
                          }
                        },
                        [_vm._v(" 有图导出 ")]
                      )
                    : _vm._e(),
                  !_vm.permission.isExport
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.exportLoading1,
                            loading: _vm.exportLoading1,
                            icon: "el-icon-download",
                            size: "small",
                            type: "success"
                          },
                          on: {
                            click: function($event) {
                              return _vm.exportExcel(false)
                            }
                          }
                        },
                        [_vm._v(" 无图导出 ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              ref: "goodsTable",
              attrs: {
                "cell-style": { textAlign: "center" },
                data: _vm.goodsTable,
                "header-cell-style": {
                  background: "#dfe6ec",
                  color: "#666666",
                  textAlign: "center"
                },
                "row-style": { height: "57px" },
                "element-loading-text": "数据加载中",
                "max-height": "620px",
                "tooltip-effect": "dark"
              },
              on: { "sort-change": _vm.sortChange }
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "序号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              (_vm.page.pageNo - 1) * _vm.page.pageSize +
                                scope.$index +
                                1
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品编码",
                  prop: "id",
                  width: "120"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品原ID",
                  prop: "goodsId",
                  width: "120"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.goodsId && scope.row.goodsId != " "
                                ? scope.row.goodsId
                                : "- -"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "商品名称", prop: "goodsName" }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品图片",
                  prop: "pictureUrl"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "40px", height: "40px" },
                          attrs: {
                            "preview-src-list": [
                              _vm.$oss + scope.row.pictureUrl
                            ],
                            src: _vm.$oss + scope.row.pictureUrl
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "入库价（元）",
                  prop: "price"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "right",
                              trigger: "click",
                              width: "280"
                            }
                          },
                          [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.priceLoading,
                                    expression: "priceLoading"
                                  }
                                ],
                                attrs: {
                                  data: _vm.priceData,
                                  "element-loading-text": "数据加载中"
                                }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { label: "序号", width: "70" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(scope.$index + 1) +
                                                " "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "价格",
                                    property: "price"
                                  }
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "在库库存",
                                    property: "inventoryNums"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "10px",
                                  cursor: "pointer",
                                  color: "#1482f0"
                                },
                                attrs: { slot: "reference" },
                                on: {
                                  click: function($event) {
                                    return _vm.getAllPrice(
                                      scope.row.id,
                                      scope.row.warehouseId
                                    )
                                  }
                                },
                                slot: "reference"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "border-bottom": "1px solid #1482f0"
                                    }
                                  },
                                  [_vm._v(_vm._s(scope.row.price))]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "在库数量",
                  prop: "sumAll",
                  sortable: "custom"
                }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        !_vm.permission.isBaseInfo
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-info",
                                  plain: "",
                                  size: "small",
                                  type: "info"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.toDetails(scope.row)
                                  }
                                }
                              },
                              [_vm._v(" 详情 ")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.page.pageNo,
              "page-size": _vm.page.pageSize,
              "page-sizes": [10, 20, 30, 40, 50, 100],
              total: _vm.pageTotal,
              layout: "->,total, sizes, prev, pager, next, jumper"
            },
            on: {
              "size-change": _vm.sizeChange,
              "current-change": _vm.currentchange
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }