<template>
  <div>
    <head-search label-style="width:100px">
      <head-search-item label="公司名称">
    <el-select v-model="companyId" placeholder="请选择公司" size="small" style="margin-right:10px" clearable
      @change="changeset" :disabled="disabledCompany">
      <el-option v-for="item in company" :key="item.orgId" :label="item.orgName" :value="item.orgId">
      </el-option>
    </el-select>
    </head-search-item>
    <head-search-item label="部门名称">
    <el-select v-model="departmentId" multiple placeholder="请选择部门" size="small" style="margin-right:10px" clearable
      :disabled="disabledDepartment">
      <el-option v-for="item in department" :key="item.orgId" :label="item.orgName" :value="item.orgId">
      </el-option>
    </el-select>
    </head-search-item>
    </head-search>
  </div>
</template>
<script>
import api from '@/api'
export default {
  name: 'isJurisdictionSearch',
  data () {
    return {
      // 公司
      company: [],
      companyId: '',
      // 部门
      department: [],
      departmentId: [],
      // 禁用
      disabledCompany: true,
      disabledDepartment: true,
      // 用户信息
      userInfo: {},
      permission: {
        isSelectDept: this.checkauthority('org_dept_list') // 查看权限
      }
    }
  },
  created () {
    this.getCompany()
  },
  mounted () {
  },
  methods: {
    // 获得公司列表
    async getCompany () {
      this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      if (this.isSelectDept) {
        return
      }
      const res = await api.getDeptList({ orgPid: -1 })
      this.company = res.body
      // is super账号
      if (this.userInfo.orgId === 0 && this.userInfo.deptId === 0) {
        this.companyId = ''
        this.departmentId = []
        this.disabledCompany = false
      } else if (this.userInfo.managerFlag) {
        // is 总经办账号
        this.companyId = this.userInfo.orgId
        this.getPart()
        this.departmentId = []
        this.disabledDepartment = false
      } else {
        // 普通用户直接赋值
        this.companyId = this.userInfo.orgId
        this.getPart()
        this.departmentId.push(this.userInfo.deptId)
      }
      this.$emit('initFinish')
    },
    // 获得部门列表
    async getPart () {
      const res = await api.getDeptList({ orgPid: this.companyId })
      this.department = res.body
    },
    changeset () {
      this.emptyCompany()
      if (this.companyId !== '') {
        this.department = []
        this.getPart()
        this.disabledDepartment = false
        this.$emit('changeList', this.companyId, this.departmentId)
      } else {
        // 选中的部门为空，则清空人员列表
        this.department = []
        this.disabledDepartment = true
        this.$emit('changeList', this.companyId, this.departmentId)
      }
    },
    emptyCompany () {
      if (this.companyId === '') {
        this.departmentId = []
        this.disabledCompany = false
      }
    },
    disabledInput () {
      this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      if (this.userInfo.orgId === 0 && this.userInfo.deptId === 0) {
        this.companyId = ''
        this.departmentId = []
        this.disabledCompany = false
        this.disabledDepartment = true
      } else if (this.userInfo.managerFlag) {
        // is 总经办账号
        this.companyId = this.userInfo.orgId
        this.getPart()
        this.departmentId = []
        this.disabledCompany = true
        this.disabledDepartment = false
      } else {
        // 普通用户直接赋值
        this.companyId = this.userInfo.orgId
        this.getPart()
        // if(this.departmentId)
        const num = this.departmentId.length
        for (let i = 0; i < num; i++) {
          if (this.departmentId[i] === this.userInfo.deptId) {
            this.disabledCompany = true
            this.disabledDepartment = true
          } else {
            this.departmentId.push(this.userInfo.deptId)
            this.disabledCompany = true
            this.disabledDepartment = true
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
// .sty {
//   display: inline-block;
// }
</style>
