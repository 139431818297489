var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c("div", { staticClass: "goods" }, [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "head-search",
            { attrs: { "label-style": "width:100px" } },
            [
              _c(
                "head-search-item",
                { attrs: { label: "公司名称" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        size: "small",
                        placeholder: "请选择公司名称"
                      },
                      model: {
                        value: _vm.search.companyIds,
                        callback: function($$v) {
                          _vm.$set(_vm.search, "companyIds", $$v)
                        },
                        expression: "search.companyIds"
                      }
                    },
                    _vm._l(_vm.orgOptions, function(item) {
                      return _c("el-option", {
                        key: item.orgId,
                        attrs: { label: item.orgName, value: item.orgId }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "仓库名称" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        size: "small",
                        placeholder: "请选择仓库名称"
                      },
                      model: {
                        value: _vm.search.warehouseIds,
                        callback: function($$v) {
                          _vm.$set(_vm.search, "warehouseIds", $$v)
                        },
                        expression: "search.warehouseIds"
                      }
                    },
                    _vm._l(_vm.warehouseIdOptions, function(item) {
                      return _c("el-option", {
                        key: item.warehouseId,
                        attrs: {
                          label: item.warehouseName,
                          value: item.warehouseId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "日期选择" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      align: "right",
                      type: "date",
                      size: "small",
                      placeholder: "选择日期",
                      clearable: false,
                      "picker-options": _vm.pickerOptionsDate
                    },
                    model: {
                      value: _vm.search.endTime,
                      callback: function($$v) {
                        _vm.$set(_vm.search, "endTime", $$v)
                      },
                      expression: "search.endTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "template",
                { slot: "button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        size: "small"
                      },
                      on: { click: _vm.Search }
                    },
                    [_vm._v(" 搜索 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh",
                        type: "warning",
                        size: "small"
                      },
                      on: { click: _vm.Reset }
                    },
                    [_vm._v(" 重置 ")]
                  ),
                  _vm._t("default")
                ],
                2
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              ref: "goodsTable",
              attrs: {
                data: _vm.stockTable,
                "max-height": "620px",
                "element-loading-text": "数据加载中",
                "cell-style": { textAlign: "center" },
                "tooltip-effect": "dark",
                "row-style": { height: "57px" },
                "header-cell-style": {
                  background: "#dfe6ec",
                  color: "#666666",
                  textAlign: "center"
                }
              },
              on: { "sort-change": _vm.SortChange }
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "序号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              (_vm.page.pageNo - 1) * _vm.page.pageSize +
                                scope.$index +
                                1
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "公司名称",
                  prop: "companyName"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "仓库名称",
                  prop: "warehouseName"
                }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "在库金额", prop: "price" }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "在库数量",
                  prop: "inventoryNums"
                }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "所属部门", prop: "deptName" }
              })
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.page.pageNo,
              "page-sizes": [10, 20, 30, 40, 50, 100],
              "page-size": _vm.page.pageSize,
              layout: "->,total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal
            },
            on: {
              "size-change": _vm.sizeChange,
              "current-change": _vm.currentchange
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }