export default ({ request }) => ({
    // 获取采购退货列表
    getPurchaseThListList(data = {}) {
      return request({
        url: '/v1/warehouseIntoOrder/getReturnMoney',
        method: 'post',
        data: data
      })
    },
    // 获取采购退货列表
    getPurchaseThListDetail(data = {}) {
        return request({
          url: '/v1/intoDetail/returnMonetResult',
          method: 'post',
          data: data
        })
      },
    
  })