<template>
  <div>
    <div class="dashboard-editor-container">
      <div class="singSty" style="position: relative;">
        <div class="verticalBar"></div>
        <span class="textSty">待处理异常单</span>
        <svg
          class="icon"
          aria-hidden="true"
          style="width: 30px;height:30px;position: absolute;top: -35px;left: 100%;"
        >
          <use xlink:href="#icon-tuding"></use>
        </svg>
      </div>
      <div class="whSty">
        <el-table
          ref="table"
          v-loading="loading"
          :header-cell-style="headerCellStyle"
          element-loading-text="数据加载中"
          :data="tableData"
          max-height="390px"
          :cell-style="{ textAlign: 'center' }"
          :row-style="{ height: '60px', textAlign: 'center' }"
          style="width: 100%;margin-left: 10px;margin:10px 10px 0px 10px"
          tooltip-effect="dark"
        >
          <el-table-column
            label="订单编号"
            width="130"
            prop="orderNumber"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="数量"
            prop="productNum"
            width="60"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="采购类型"
            prop="type"
            width="80"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="创建者"
            prop="createdName"
            width="70"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="createdTimestamp"
            align="center"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api'
export default {
  name: 'abnormalOrders',
  data () {
    return {
      tableData: [],
      loading: false,
      headerCellStyle: {
        color: '#666666',
        background: '#dfe6ec'
      }
    }
  },
  created () {
    this.initList()
  },
  methods: {
    async initList () {
      this.loading = true
      const res = await api.getAbnormalOrders()
      if (res.code === 200) {
        this.tableData = res.body
        this.loading = false
      } else {
        this.loading = false
        this.$message.error('待处理异常单数据加载失败')
      }
    }
  }
}
</script>
<style scoped>
.dashboard-editor-container {
  margin-top: 20px;
  padding: 20px;
  margin-left: 20px;
  background-color: #ffffff;
  padding: 20px 20px 0px 0px;
  box-shadow: 0px 0px 4px #999999;
  border-radius: 5px;
}
.singSty {
  display: flex;
  height: 20px;
  padding-left: 10px;
}
.verticalBar {
  width: 10px;
  line-height: 20px;
  border-radius: 25%;
  background-color: #8ddece;
  margin-right: 5px;
}
.textSty {
  line-height: 20px;
  font-weight: bold;
}
.whSty {
  width: 100%;
  height: 410px;
}
</style>
