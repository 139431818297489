var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingData,
              expression: "loadingData"
            }
          ],
          staticClass: "header"
        },
        [
          _c(
            "div",
            {
              staticClass: "searchBgc3",
              staticStyle: {
                "margin-bottom": "10px",
                display: "flex",
                "justify-content": "space-between"
              }
            },
            [
              _vm._v(" 基本信息 "),
              _vm.status === 0 && _vm.permission.isUpdate === false
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        size: "small",
                        type: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.redactForm()
                        }
                      }
                    },
                    [_vm._v(" 编辑 ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "redactData",
              staticClass: "ruleSty",
              attrs: {
                model: _vm.redactData,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "div2",
                  style:
                    _vm.redactData.purchaseType === _vm.tag3
                      ? "display:flex;justify-content: left;"
                      : ""
                },
                [
                  _c("div", { staticClass: "div3" }, [
                    _c(
                      "div",
                      { staticClass: "div1" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "采购单号", prop: "purchaseNo" } },
                          [
                            _c(
                              "div",
                              { staticStyle: { color: "#1482f0" } },
                              [_c("el-tag", [_vm._v(_vm._s(this.purchaseNo))])],
                              1
                            )
                          ]
                        ),
                        _c("el-form-item", { attrs: { label: "创建日期" } }, [
                          _c(
                            "div",
                            { staticStyle: { color: "#606266" } },
                            [
                              _c("el-tag", { attrs: { type: "warning" } }, [
                                _vm._v(_vm._s(this.createdTimestamp))
                              ])
                            ],
                            1
                          )
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "采购状态", prop: "status" } },
                          [
                            _c(
                              "div",
                              { staticStyle: { color: "#606266" } },
                              [
                                _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.status === 0
                                          ? "待采购"
                                          : this.status === 1
                                          ? "采购中"
                                          : this.status === 2
                                          ? "已完成"
                                          : this.status === 3
                                          ? "待终止"
                                          : this.status === 9
                                          ? "已终止"
                                          : "- -"
                                      ) +
                                      " "
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        ),
                        this.redactData.purchaseType === "调拨"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "配送方式",
                                  prop: "distribution"
                                }
                              },
                              [
                                _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v(
                                    _vm._s(
                                      this.redactData.distribution === 0
                                        ? "自配送"
                                        : this.redactData.distribution === 1
                                        ? "门店自取"
                                        : this.redactData.distribution === 2
                                        ? "物流"
                                        : ""
                                    ) + " "
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "div1" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "采购类型", prop: "purchaseType" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: true,
                                    clearable: "",
                                    placeholder: "请选择采购类型",
                                    size: "small"
                                  },
                                  on: { change: _vm.changePurchaseType },
                                  model: {
                                    value: _vm.redactData.purchaseType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.redactData,
                                        "purchaseType",
                                        $$v
                                      )
                                    },
                                    expression: "redactData.purchaseType"
                                  }
                                },
                                _vm._l(_vm.purchaseTypesData, function(item) {
                                  return _c("el-option", {
                                    key: item.keys,
                                    attrs: {
                                      label: item.values,
                                      value: item.keys,
                                      title: _vm.onHoverText
                                    },
                                    nativeOn: {
                                      mouseenter: function($event) {
                                        return _vm.onMouseOver(item.values)
                                      }
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          this.redactData.purchaseType !== this.tag3
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "渠道", prop: "channel" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled: true,
                                        clearable: "",
                                        placeholder: "请选择渠道",
                                        size: "small"
                                      },
                                      model: {
                                        value: _vm.redactData.channel,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.redactData,
                                            "channel",
                                            $$v
                                          )
                                        },
                                        expression: "redactData.channel"
                                      }
                                    },
                                    _vm._l(_vm.channelsData, function(item) {
                                      return _c("el-option", {
                                        key: item.keys,
                                        attrs: {
                                          label: item.values,
                                          value: item.keys,
                                          title: _vm.onHoverText
                                        },
                                        nativeOn: {
                                          mouseenter: function($event) {
                                            return _vm.onMouseOver(item.values)
                                          }
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          this.redactData.purchaseType !== this.tag3
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "付款方式",
                                    prop: "payMethod"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled: true,
                                        clearable: "",
                                        placeholder: "请选择付款方式",
                                        size: "small"
                                      },
                                      model: {
                                        value: _vm.redactData.payMethod,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.redactData,
                                            "payMethod",
                                            $$v
                                          )
                                        },
                                        expression: "redactData.payMethod"
                                      }
                                    },
                                    _vm._l(_vm.payMethodsData, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.value,
                                          value: item.id,
                                          title: _vm.onHoverText
                                        },
                                        nativeOn: {
                                          mouseenter: function($event) {
                                            return _vm.onMouseOver(item.values)
                                          }
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "div1" },
                        [
                          this.redactData.purchaseType === this.tag2
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "预定日期",
                                    prop: "predictArriveDate"
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "215px" },
                                    attrs: {
                                      disabled: _vm.showRedactFlag,
                                      "picker-options": _vm.pickerOptions,
                                      placeholder: "选择日期",
                                      size: "small",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.redactData.predictArriveDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.redactData,
                                          "predictArriveDate",
                                          $$v
                                        )
                                      },
                                      expression: "redactData.predictArriveDate"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          this.redactData.purchaseType !== this.tag3
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "发货方式",
                                    prop: "deliveryMethod"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled: true,
                                        clearable: "",
                                        placeholder: "请选择发货方式",
                                        size: "small"
                                      },
                                      model: {
                                        value: _vm.redactData.deliveryMethod,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.redactData,
                                            "deliveryMethod",
                                            $$v
                                          )
                                        },
                                        expression: "redactData.deliveryMethod"
                                      }
                                    },
                                    _vm._l(_vm.deliveryMethodsData, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.value,
                                          value: item.id,
                                          title: _vm.onHoverText
                                        },
                                        nativeOn: {
                                          mouseenter: function($event) {
                                            return _vm.onMouseOver(item.value)
                                          }
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.returnSty()
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "收货人",
                                    prop: "deliveryUserName"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "215px" },
                                    attrs: {
                                      disabled: _vm.showRedactFlag,
                                      clearable: "",
                                      placeholder: "请输入收货人",
                                      size: "small"
                                    },
                                    model: {
                                      value: _vm.redactData.deliveryUserName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.redactData,
                                          "deliveryUserName",
                                          $$v
                                        )
                                      },
                                      expression: "redactData.deliveryUserName"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.returnSty()
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "联系方式",
                                    prop: "deliveryPhone"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "215px" },
                                    attrs: {
                                      disabled: _vm.showRedactFlag,
                                      clearable: "",
                                      maxlength: "11",
                                      placeholder: "请输入收货人联系方式",
                                      size: "small"
                                    },
                                    model: {
                                      value: _vm.redactData.deliveryPhone,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.redactData,
                                          "deliveryPhone",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "redactData.deliveryPhone"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "入库仓库", prop: "warehouseId" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: true,
                                    clearable: "",
                                    placeholder: "请选择入库仓库",
                                    size: "small"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.getWarehouseAddress(
                                        _vm.item.warehouseAddress
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.redactData.warehouseId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.redactData,
                                        "warehouseId",
                                        $$v
                                      )
                                    },
                                    expression: "redactData.warehouseId"
                                  }
                                },
                                _vm._l(_vm.warehouseIdsData, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.warehouseName,
                                      value: item.warehouseId,
                                      title: _vm.onHoverText
                                    },
                                    nativeOn: {
                                      mouseenter: function($event) {
                                        return _vm.onMouseOver(item.value)
                                      }
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          this.redactData.purchaseType === this.tag3
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "来源仓库",
                                    prop: "sourceWarehouseId"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled: true,
                                        clearable: "",
                                        placeholder: "请选择入库仓库",
                                        size: "small"
                                      },
                                      model: {
                                        value: _vm.redactData.sourceWarehouseId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.redactData,
                                            "sourceWarehouseId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "redactData.sourceWarehouseId"
                                      }
                                    },
                                    _vm._l(_vm.sourceWarehouseIdsData, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.warehouseName,
                                          value: item.warehouseId,
                                          title: _vm.onHoverText
                                        },
                                        nativeOn: {
                                          mouseenter: function($event) {
                                            return _vm.onMouseOver(item.value)
                                          }
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          this.redactData.purchaseType === this.tag3
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "仓库位置",
                                    prop: "sameWarehouseFlag"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled: true,
                                        clearable: "",
                                        placeholder: "请选择仓库位置",
                                        size: "small"
                                      },
                                      model: {
                                        value: _vm.redactData.sameWarehouseFlag,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.redactData,
                                            "sameWarehouseFlag",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "redactData.sameWarehouseFlag"
                                      }
                                    },
                                    _vm._l(_vm.sameWarehouseFlagData, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.value,
                                          value: item.id,
                                          title: _vm.onHoverText
                                        },
                                        nativeOn: {
                                          mouseenter: function($event) {
                                            return _vm.onMouseOver(item.value)
                                          }
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ])
                ]
              ),
              this.redactData.purchaseType !== "调拨" ||
              this.redactData.distribution === 2
                ? _c(
                    "div",
                    {
                      staticClass: "searchBgc2",
                      staticStyle: {
                        "margin-bottom": "10px",
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [_vm._v(" 物流信息 ")]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "div1" },
                [
                  this.redactData.purchaseType !== "调拨" ||
                  this.redactData.distribution === 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "物流公司" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "215px" },
                            attrs: {
                              disabled: this.status === 9 || this.status === 2,
                              clearable: "",
                              placeholder: "请输入物流公司",
                              size: "small"
                            },
                            model: {
                              value: _vm.redactData.courierCompany,
                              callback: function($$v) {
                                _vm.$set(_vm.redactData, "courierCompany", $$v)
                              },
                              expression: "redactData.courierCompany"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  this.redactData.purchaseType !== "调拨" ||
                  this.redactData.distribution === 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "物流单号" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "215px" },
                            attrs: {
                              disabled: this.status === 9 || this.status === 2,
                              clearable: "",
                              placeholder: "请输入物流单号",
                              size: "small"
                            },
                            model: {
                              value: _vm.redactData.trackingNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.redactData, "trackingNumber", $$v)
                              },
                              expression: "redactData.trackingNumber"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  this.redactData.purchaseType !== "调拨" ||
                  this.redactData.distribution === 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "收货地址" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "530px" },
                            attrs: {
                              disabled: this.status === 9 || this.status === 2,
                              clearable: "",
                              placeholder: "请输入收货地址",
                              size: "small"
                            },
                            model: {
                              value: _vm.redactData.deliveryAddress,
                              callback: function($$v) {
                                _vm.$set(_vm.redactData, "deliveryAddress", $$v)
                              },
                              expression: "redactData.deliveryAddress"
                            }
                          }),
                          this.status !== 9 &&
                          this.redactData.trackingNumber !== ""
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: {
                                    size: "small",
                                    type: "info",
                                    disabled: _vm.LogisticsLoading,
                                    loading: _vm.LogisticsLoading
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openLogistics()
                                    }
                                  }
                                },
                                [_vm._v(" 查看物流 ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              this.redactData.purchaseType !== this.tag3
                ? _c(
                    "div",
                    {
                      staticClass: "searchBgc2",
                      staticStyle: {
                        "margin-bottom": "10px",
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [_vm._v(" 交易截图 ")]
                  )
                : _vm._e(),
              this.redactData.purchaseType !== this.tag3
                ? _c("div", { staticClass: "div4" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "付款截图" } },
                          [
                            _c("erp-upload-img", {
                              attrs: {
                                maxCount: 10,
                                showDelete: true,
                                "upload-path":
                                  "/purchasingManagement/purchasing/"
                              },
                              model: {
                                value: _vm.redactData.payProve,
                                callback: function($$v) {
                                  _vm.$set(_vm.redactData, "payProve", $$v)
                                },
                                expression: "redactData.payProve"
                              }
                            }),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "success", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.saveImg(1)
                                  }
                                }
                              },
                              [_vm._v("保存图片")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        this.redactData.purchaseType === this.tag2 &&
                        this.redactData.payProve.length > 0
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "尾款截图" } },
                              [
                                _c("erp-upload-img", {
                                  attrs: {
                                    maxCount: 10,
                                    showDelete: true,
                                    "upload-path":
                                      "/purchasingManagement/purchasing/"
                                  },
                                  model: {
                                    value: _vm.redactData.finalPaymentProve,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.redactData,
                                        "finalPaymentProve",
                                        $$v
                                      )
                                    },
                                    expression: "redactData.finalPaymentProve"
                                  }
                                }),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "success", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.saveImg(2)
                                      }
                                    }
                                  },
                                  [_vm._v("保存图片")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "searchBgc2",
                  staticStyle: {
                    "margin-bottom": "10px",
                    display: "flex",
                    "justify-content": "space-between"
                  }
                },
                [
                  _vm._v(" 商品信息 "),
                  !_vm.showRedactFlag
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            icon: "el-icon-circle-plus-outline",
                            size: "small",
                            type: "success"
                          },
                          on: {
                            click: function($event) {
                              return _vm.addItems()
                            }
                          }
                        },
                        [_vm._v(" 添加 ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "itemAddSty", attrs: { prop: "itemAddData" } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "itemAddData",
                      staticStyle: { "box-sizing": "border-box" },
                      attrs: {
                        "cell-style": { textAlign: "center" },
                        data: _vm.redactData.itemAddData,
                        "header-cell-style": {
                          background: "#dfe6ec",
                          color: "#666666",
                          textAlign: "center"
                        },
                        "row-style": { height: "77px" },
                        "element-loading-text": "数据加载中",
                        "max-height": "690px",
                        "tooltip-effect": "dark"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品编码",
                          prop: "goodsCoding",
                          width: "120"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.goodsCoding) + " "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品名称",
                          prop: "goodsName",
                          width: "260"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "box-sizing": "border-box",
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      "white-space": "normal",
                                      "word-break": "break-all",
                                      "line-height": "23px",
                                      "padding-right": "10px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(scope.row.goodsName) + " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品图片",
                          prop: "pictureUrl",
                          width: "80"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.pictureUrl !== "" &&
                                scope.row.pictureUrl !== null
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                        "margin-top": "8px",
                                        cursor: "pointer"
                                      },
                                      attrs: {
                                        src:
                                          _vm.downloadUrl +
                                          "/" +
                                          scope.row.pictureUrl,
                                        alt: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getPreview(
                                            _vm.downloadUrl +
                                              "/" +
                                              scope.row.pictureUrl
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                scope.row.pictureUrl === "" ||
                                scope.row.pictureUrl === null
                                  ? _c("div", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                        border: "1px solid #c0c0c0",
                                        display: "flex",
                                        "justify-content": "center",
                                        margin: "0 auto"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "分类",
                          prop: "goodsOne",
                          width: "120"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "box-sizing": "border-box",
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      "white-space": "normal",
                                      "word-break": "break-all",
                                      "line-height": "23px",
                                      "padding-right": "10px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.goodsOne === null
                                            ? "- -"
                                            : scope.row.goodsOne
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "品牌",
                          prop: "goodsBrandName",
                          width: "120"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "box-sizing": "border-box",
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      "white-space": "normal",
                                      "word-break": "break-all",
                                      "line-height": "23px",
                                      "padding-right": "10px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.goodsBrandName === null
                                            ? "- -"
                                            : scope.row.goodsBrandName
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品IP",
                          prop: "goodsIpName",
                          width: "120"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "box-sizing": "border-box",
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      "white-space": "normal",
                                      "word-break": "break-all",
                                      "line-height": "23px",
                                      "padding-right": "10px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(scope.row.goodsIpName) + " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "我的入库价(元)",
                          prop: "warehousePriceRange",
                          width: "120"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      overflow: "hidden",
                                      "white-space": "nowrap",
                                      "text-overflow": "ellipsis"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.warehousePriceRange ===
                                            null ||
                                            scope.row.warehousePriceRange ===
                                              undefined
                                            ? "- -"
                                            : scope.row.warehousePriceRange
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "我的库存数量",
                          prop: "inventoryNum",
                          width: "120"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      overflow: "hidden",
                                      "white-space": "nowrap",
                                      "text-overflow": "ellipsis"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.inventoryNum === null ||
                                            scope.row.inventoryNum === undefined
                                            ? "- -"
                                            : scope.row.inventoryNum
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "我的在途数量",
                          prop: "inTransitNum",
                          width: "120"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      overflow: "hidden",
                                      "white-space": "nowrap",
                                      "text-overflow": "ellipsis"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.inTransitNum === null ||
                                            scope.row.inTransitNum === undefined
                                            ? "- -"
                                            : scope.row.inTransitNum
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "采购数量",
                          width: "150"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "itemAddData." + scope.$index + ".num",
                                      rules: _vm.rules.num
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        disabled: _vm.showRedactFlag,
                                        min: 1,
                                        precision: 0,
                                        step: 1,
                                        size: "small"
                                      },
                                      model: {
                                        value: scope.row.num,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "num",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "scope.row.num"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      (this.confirmAllocate === true &&
                        this.redactData.purchaseType === this.tag3) ||
                      this.redactData.purchaseType !== this.tag3 ||
                      (this.status === 1 &&
                        this.redactData.purchaseType === this.tag3) ||
                      (this.status === 2 &&
                        this.redactData.purchaseType === this.tag3)
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "采购单价",
                              prop: "unitPrice",
                              width: "150"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "itemAddData." +
                                              scope.$index +
                                              ".unitPrice",
                                            rules: _vm.rules.unitPrice
                                          }
                                        },
                                        [
                                          _c("el-input-number", {
                                            attrs: {
                                              disabled: _vm.showRedactFlag,
                                              min: 0,
                                              precision: 2,
                                              step: 1,
                                              size: "small"
                                            },
                                            model: {
                                              value: scope.row.unitPrice,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "unitPrice",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.unitPrice"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              686421576
                            )
                          })
                        : _vm._e(),
                      _vm.status === 0
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "操作",
                              width: "100"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            disabled: _vm.showRedactFlag,
                                            icon: "el-icon-delete",
                                            plain: "",
                                            size: "small",
                                            type: "danger"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.remove(
                                                scope.$index,
                                                scope.row,
                                                _vm.redactData.itemAddData
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" 删除 ")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2824306214
                            )
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.redactData.itemAddData.length > 0
                ? _c("div", { staticClass: "textSty" }, [
                    _c("div", [
                      _c("span", [_vm._v("合计数量：")]),
                      _c(
                        "span",
                        {
                          staticClass: "spanSty",
                          staticStyle: { "margin-right": "10px" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                isNaN(_vm.count.totalNum)
                                  ? 0
                                  : _vm.count.totalNum
                              ) +
                              " "
                          )
                        ]
                      )
                    ]),
                    (this.confirmAllocate === true &&
                      this.redactData.purchaseType === this.tag3) ||
                    this.redactData.purchaseType !== this.tag3 ||
                    (this.status === 1 &&
                      this.redactData.purchaseType === this.tag3) ||
                    (this.status === 2 &&
                      this.redactData.purchaseType === this.tag3)
                      ? _c("div", [
                          _c("span", [_vm._v("合计金额：")]),
                          _c("span", { staticClass: "spanSty" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  isNaN(_vm.count.totalAmount)
                                    ? 0
                                    : _vm.count.totalAmount / 100 > 0
                                    ? (_vm.count.totalAmount / 100).toFixed(2)
                                    : 0
                                ) +
                                " "
                            )
                          ]),
                          _vm._v(" 元 ")
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              this.intoOrderInfo !== null
                ? _c(
                    "div",
                    {
                      staticClass: "searchBgc2",
                      staticStyle: {
                        "margin-bottom": "10px",
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _vm._v(" 入库明细 "),
                      _vm.permission.isDispose === false
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                icon: "el-icon-circle-plus-outline",
                                size: "small",
                                type: "warning"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toDispose()
                                }
                              }
                            },
                            [_vm._v(" 处理 ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              this.intoOrderInfo !== null
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-left": "20px",
                        "margin-bottom": "10px",
                        display: "flex",
                        "justify-content": "space-around"
                      }
                    },
                    [
                      _c("div", [
                        _c("span"),
                        _vm._v(" 采购数量: "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-left": "20px",
                              color: "rgb(102, 102, 102)"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(this.intoOrderInfo.totalNum) + " "
                            )
                          ]
                        )
                      ]),
                      _c("div", [
                        _vm._v(" 记录数量: "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-left": "20px",
                              color: "rgb(102, 102, 102)"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(this.intoOrderInfo.sweepCodeRecordNum) +
                                " "
                            )
                          ]
                        )
                      ]),
                      _c("div", [
                        _vm._v(" 完好: "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-left": "20px",
                              color: "rgb(102, 102, 102)"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(this.intoOrderInfo.intactNum) + " "
                            )
                          ]
                        )
                      ]),
                      _c("div", [
                        _vm._v(" 未记录: "),
                        _c(
                          "span",
                          {
                            staticStyle: { "margin-left": "20px", color: "red" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(this.intoOrderInfo.withoutRecordNum) +
                                " "
                            )
                          ]
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "searchBgc2",
                  staticStyle: { "margin-bottom": "20px" }
                },
                [_vm._v("备注信息")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注信息" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "99%" },
                    attrs: {
                      autosize: { minRows: 5, maxRows: 10 },
                      disabled: this.status === 9 || this.status === 2,
                      maxlength: "240",
                      placeholder: "请输入备注信息",
                      "show-word-limit": "",
                      type: "textarea"
                    },
                    model: {
                      value: _vm.redactData.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.redactData, "remark", $$v)
                      },
                      expression: "redactData.remark"
                    }
                  })
                ],
                1
              ),
              this.status === 9 || this.status === 3
                ? _c(
                    "div",
                    {
                      staticClass: "searchBgc2",
                      staticStyle: { "margin-bottom": "20px" }
                    },
                    [_vm._v("终止相关")]
                  )
                : _vm._e(),
              this.status === 9 || this.status === 3
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "终止图片" } },
                        [
                          (this.status === 9 || this.status === 3) &&
                          _vm.redactData.backProve.length > 0
                            ? _c("erp-upload-img", {
                                attrs: {
                                  maxCount: _vm.redactData.backProve.length,
                                  "upload-path":
                                    "/purchasingManagement/purchasing/backProve"
                                },
                                model: {
                                  value: _vm.redactData.backProve,
                                  callback: function($$v) {
                                    _vm.$set(_vm.redactData, "backProve", $$v)
                                  },
                                  expression: "redactData.backProve"
                                }
                              })
                            : _vm._e(),
                          (this.status === 9 || this.status === 3) &&
                          _vm.redactData.backProve.length === 0
                            ? _c("div", [_vm._v("- -")])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("el-form-item", { attrs: { label: "终止原因" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.redactData.refundReason
                                ? _vm.redactData.refundReason
                                : "- -"
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "searchBgc2",
                  staticStyle: { "margin-bottom": "20px" }
                },
                [_vm._v(" 操作日志 ")]
              ),
              _c(
                "div",
                [
                  _c(
                    "el-timeline",
                    _vm._l(_vm.logList, function(item, index) {
                      return _c(
                        "el-timeline-item",
                        {
                          key: index,
                          staticStyle: {
                            "box-sizing": "border-box",
                            overflow: "hidden",
                            "text-overflow": "ellipsis",
                            "white-space": "normal",
                            "word-break": "break-all",
                            "line-height": "23px",
                            "padding-right": "10px"
                          },
                          attrs: { timestamp: item.createdTimestamp }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.createdName +
                                  item.operateDesc +
                                  " " +
                                  _vm.remark +
                                  (item.remark === null ? "" : item.remark)
                              ) +
                              " "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("div", {
                staticClass: "searchBgc2",
                staticStyle: { "margin-bottom": "20px" }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "margin-bottom": "20px"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.resetForm("redactData")
                        }
                      }
                    },
                    [_vm._v(" 关闭 ")]
                  ),
                  this.status !== 2 &&
                  this.status !== 9 &&
                  _vm.permission.isUpdate === false
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type:
                              this.status !== 2 &&
                              this.status !== 9 &&
                              this.redactData.auditStatus !== 2
                                ? "success"
                                : this.status !== 2 &&
                                  this.status !== 9 &&
                                  this.redactData.auditStatus === 2
                                ? "primary"
                                : "",
                            size: "small",
                            disabled: _vm.submitLoading,
                            loading: _vm.submitLoading
                          },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.submitForm()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                this.status !== 2 && this.status !== 9
                                  ? "保存"
                                  : this.status !== 2 &&
                                    this.status !== 9 &&
                                    this.redactData.auditStatus != 1
                                  ? "重新保存"
                                  : ""
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  this.status != 9 &&
                  this.redactData.auditStatus != 1 &&
                  _vm.permission.isEnd === false
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            type:
                              this.status != 9 && this.status != 3
                                ? "warning"
                                : this.status == 3
                                ? "danger"
                                : ""
                          },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.stopPurchase()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                this.status != 9 && this.status != 3
                                  ? " 终止采购"
                                  : this.status == 3 &&
                                    this.redactData.auditStatus != 1
                                  ? "重新终止采购"
                                  : ""
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  this.status == 9 && _vm.permission.isCopy === false
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.loadingCopy,
                            loading: _vm.loadingCopy,
                            size: "small",
                            type: "primary"
                          },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.copyOrder()
                            }
                          }
                        },
                        [_vm._v(" 复制订单 ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { margin: "20px auto" },
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            title: "图片放大",
            visible: _vm.dialogFormVisiblePic,
            width: "540px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisiblePic = $event
            },
            close: function($event) {
              return _vm.closeDialogPic()
            }
          }
        },
        [
          this.dialogImageUrl !== ""
            ? _c("img", {
                staticStyle: {
                  width: "500px",
                  height: "500px",
                  "text-align": "center"
                },
                attrs: { src: this.dialogImageUrl, alt: "" }
              })
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { margin: "20px auto" },
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            title: "查看物流",
            visible: _vm.dialogFormVisibleLogistics,
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisibleLogistics = $event
            },
            close: function($event) {
              return _vm.closeDialogLogistics()
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "max-height": "600px", overflow: "scroll" } },
            [
              _c(
                "el-timeline",
                _vm._l(_vm.Logistics, function(item, index) {
                  return _c(
                    "el-timeline-item",
                    { key: index, attrs: { timestamp: item.time } },
                    [_vm._v(" " + _vm._s(item.context) + " ")]
                  )
                }),
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { margin: "20px auto" },
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            title: "终止采购",
            visible: _vm.dialogFormVisibleStopPurchase,
            width: "520px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisibleStopPurchase = $event
            },
            close: function($event) {
              return _vm.closeDialogStop()
            }
          }
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                color: "red",
                "text-align": "center",
                "margin-bottom": "20px",
                display: "flow-root"
              }
            },
            [
              _vm._v(
                " 注意：终止采购后当前采购单以及对应入库单状态将更新为终止! "
              )
            ]
          ),
          _c(
            "el-form",
            {
              ref: "redactData",
              staticClass: "ruleSty",
              attrs: {
                model: _vm.redactData,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "终止截图" } },
                [
                  _c("erp-upload-img", {
                    attrs: {
                      maxCount: 10,
                      showDelete: true,
                      "upload-path":
                        "/purchasingManagement/purchasing/backProve"
                    },
                    model: {
                      value: _vm.redactData.backProve,
                      callback: function($$v) {
                        _vm.$set(_vm.redactData, "backProve", $$v)
                      },
                      expression: "redactData.backProve"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "终止理由", prop: "refundReason" } },
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 3, maxRows: 5 },
                      clearable: "",
                      maxlength: "240",
                      "show-word-limit": "",
                      size: "small",
                      type: "textarea"
                    },
                    model: {
                      value: _vm.redactData.refundReason,
                      callback: function($$v) {
                        _vm.$set(_vm.redactData, "refundReason", $$v)
                      },
                      expression: "redactData.refundReason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-bottom": "20px"
              }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialogStop("redactData")
                    }
                  }
                },
                [_vm._v(" 关闭 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "success",
                    disabled: _vm.submitStopLoading,
                    loading: _vm.submitStopLoading
                  },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submitStopPurchasing()
                    }
                  }
                },
                [_vm._v(" 提交 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("purchaseSelectGoods", {
        ref: "purchaseSelectGoods",
        on: { setGoods: _vm.selectGoods }
      }),
      _c("purchaseSelectDispose", {
        ref: "purchaseSelectDispose",
        on: { handleGoods: _vm.selectGoods }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }