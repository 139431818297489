<template>
  <d2-container>
    <div class="stockposition">
      <head-search label-style="width:100px;">
        <head-search-item label="商品条码">
          <el-input
            v-model="selectForm.barCode"
            placeholder="请输入商品条码"
            size="small"
            @keyup.enter.native="search"
          ></el-input>
        </head-search-item>
        <head-search-item label="商品名称">
          <el-input
            v-model="selectForm.goodsName"
            placeholder="请输入商品名称"
            size="small"
            @keyup.enter.native="search"
          ></el-input>
        </head-search-item>
        <head-search-item label="一级分类">
          <el-select
            v-model="selectForm.id1s"
            collapse-tags
            multiple
            placeholder="请选择一级分类"
            size="small"
          >
            <el-option
              v-for="item in options[0]"
              :key="item.id"
              :label="item.classifyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="二级分类">
          <el-select
            v-model="selectForm.id2s"
            collapse-tags
            multiple
            placeholder="请选择二级分类"
            size="small"
          >
            <el-option
              v-for="item in options[1]"
              :key="item.id"
              :label="item.classifyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="三级分类">
          <el-select
            v-model="selectForm.id3s"
            collapse-tags
            multiple
            placeholder="请选择三级分类"
            size="small"
          >
            <el-option
              v-for="item in options[2]"
              :key="item.id"
              :label="item.classifyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="商品品牌">
          <el-select
            v-model="selectForm.brands"
            collapse-tags
            multiple
            placeholder="请选择商品品牌"
            size="small"
          >
            <el-option
              v-for="item in options[3]"
              :key="item.id"
              :label="item.brandName"
              :value="item.id"
              style="width: 300px; overflow: scroll;word-break: break-all;height: fit-content;white-space: inherit;"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="商品IP">
          <el-select
            v-model="selectForm.ips"
            collapse-tags
            multiple
            placeholder="请选择商品IP"
            size="small"
            style="width: 90%"
          >
            <el-option
              v-for="item in options[4]"
              :key="item.id"
              :label="item.ipName"
              :value="item.id"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="状态">
          <el-select
            v-model="selectForm.statusList"
            collapse-tags
            multiple
            placeholder="请选择状态"
            size="small"
          >
            <el-option
              v-for="item in options[6]"
              :key="item.key"
              :label="item.label"
              :value="item.key"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="区域名称">
          <el-select
            v-model="selectForm.areaId"
            placeholder="请选择区域名称"
            size="small"
            @change="areaChange"
          >
            <el-option
              v-for="item in options[5]"
              :key="item.areaId"
              :label="item.areaName"
              :value="item.areaId"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="货架名称">
          <el-select
            v-model="selectForm.shelvesId"
            :disabled="shelvesDisable"
            placeholder="请选择货架名称"
            size="small"
          >
            <el-option
              v-for="item in shelvesOptions"
              :key="item.shelvesId"
              :label="item.shelvesName"
              :value="item.shelvesId"
            ></el-option>
          </el-select>
        </head-search-item>
        <template slot="button">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
          >
            搜索
          </el-button>
          <el-button
            icon="el-icon-refresh"
            size="small"
            type="warning"
            @click="reset"
          >
            重置
          </el-button>
          <el-button
            :disabled="exportLoading"
            :loading="exportLoading"
            icon="el-icon-download"
            size="small"
            type="success"
            @click="exportExcel"
            v-if="!permission.isExport"
          >
            导出
          </el-button>
        </template>
      </head-search>
      <br />
      <el-table
        ref="tableData"
        v-loading="tableDataLoading"
        :cell-style="{ textAlign: 'center' }"
        :data="stockTable"
        :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center'
        }"
        :row-class-name="damagefreight"
        :row-style="{ height: '40px' }"
        element-loading-text="数据加载中"
        max-height="620px"
        tooltip-effect="dark"
      >
        <el-table-column align="center" label="序号">
          <template slot-scope="scope">
            {{
              (selectForm.pageNo - 1) * selectForm.pageSize + scope.$index + 1
            }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="商品编码"
          prop="goodsCoding"
          width="120"
        ></el-table-column>
        <el-table-column
          align="center"
          label="商品条码"
          prop="barCode"
          width="165"
        ></el-table-column>
        <el-table-column
          align="center"
          label="商品名称"
          prop="goodsName"
          width="200"
        ></el-table-column>
        <el-table-column align="center" label="商品图片">
          <template slot-scope="scope">
            <el-image
              :preview-src-list="[$oss + scope.row.pictureUrl]"
              :src="$oss + scope.row.pictureUrl"
              style="width: 40px; height: 40px"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="一级分类" prop="goodsOne">
          <template slot-scope="scope">
            {{ scope.row.goodsOne ? scope.row.goodsOne : "- -" }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="二级分类" prop="goodsTwo">
          <template slot-scope="scope">
            {{ scope.row.goodsTwo ? scope.row.goodsTwo : "- -" }}
          </template></el-table-column
        >
        <el-table-column align="center" label="三级分类" prop="goodsThree">
          <template slot-scope="scope">
            {{ scope.row.goodsThree ? scope.row.goodsThree : "- -" }}
          </template></el-table-column
        >
        <el-table-column
          :show-overflow-tooltip="true"
          align="center"
          label="商品品牌"
          prop="goodsBrandName"
          width="120"
        ></el-table-column>
        <el-table-column
          align="center"
          label="商品IP"
          prop="goodsIpName"
        ></el-table-column>
        <!-- <el-table-column
          align="center"
          label="入库价（元）"
          prop="unitPrice"
        ></el-table-column> -->
        <el-table-column align="center" label="状态" prop="status">
          <template slot-scope="scope">
            {{
              scope.row.status == 0
                ? "正常"
                : scope.row.status == 1
                ? "损坏"
                : scope.row.status == 2
                ? "丢失"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="区域名称" prop="areaName">
          <template slot-scope="scope">
            {{ scope.row.areaName ? scope.row.areaName : "- -" }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="货架名称" prop="shelvesName">
          <template slot-scope="scope">
            {{ scope.row.shelvesName ? scope.row.shelvesName : "- -" }}
          </template></el-table-column
        >
        <el-table-column align="center" label="货架层数" prop="shelvesLayers">
          <template slot-scope="scope">
            {{ scope.row.shelvesLayers ? scope.row.shelvesLayers : "- -" }}
          </template></el-table-column
        >
        <el-table-column align="center" label="操作" width="220">
          <template slot-scope="scope">
            <el-button
              icon="el-icon-info"
              plain
              size="small"
              type="info"
              @click="detailsLog(scope.row)"
              v-if="!permission.isInfo"
            >
              详情
            </el-button>
            <el-button
              size="small"
              type="primary"
              :disabled="makeLoading"
              :loading="makeLoading"
              @click="make(scope.row)"
              v-if="!permission.isCode"
            >
              重新生码
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="selectForm.pageNo"
        :page-size="selectForm.pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :total="pageTotal"
        layout="->,total, jumper, prev, pager, next,sizes"
        @size-change="sizeChange"
        @current-change="currentChange"
      ></el-pagination>
    </div>
    <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible">
      <el-table v-loading="detailsTableLoading" :data="detaildsTable">
        <el-table-column
          align="center"
          label="序号"
          type="index"
        ></el-table-column>
        <el-table-column
          align="center"
          label="时间"
          prop="createTime"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作人"
          prop="createBy"
        ></el-table-column>
        <el-table-column
          align="center"
          label="单号"
          prop="orderNo"
        ></el-table-column>
        <el-table-column
          align="center"
          label="扫码时间"
          prop="confirmationTime"
        >
        <template slot-scope="scope">
            {{ scope.row.confirmationTime ? scope.row.confirmationTime : "- -" }}
          </template>
      </el-table-column>
        <el-table-column
          align="center"
          label="扫码人"
          prop="confirmationBy"
        >
         <template slot-scope="scope">
            {{ scope.row.confirmationBy ? scope.row.confirmationBy : "- -" }}
          </template>
      </el-table-column>
      </el-table>
    </el-dialog>
  </d2-container>
</template>
<script>
import api from '@/api'
import { downloadFileByUrl } from '@/utils/index'

const searchFormat = {
  areaId: null,
  barCode: null,
  brands: [],
  field: '',
  goodsName: '',
  id1s: [],
  id2s: [],
  id3s: [],
  ips: [],
  pageNo: 1,
  pageSize: 50,
  shelvesId: null,
  sort: 0,
  statusList: [],
  warehouseId: 0
}
const Copy = value => {
  return JSON.parse(JSON.stringify(value))
}
export default {
  name: 'stockposition',
  data () {
    return {
      permission: {
        isExport: this.checkauthority('storage_export'), // 导出
        isInfo: this.checkauthority('storage_detail'), // 详情
        isCode: this.checkauthority('reload_print') // 生码
      },
      pageTotal: 0,
      detailsPageTotal: 0,
      selectForm: {},
      detaildsTable: [],
      stockTable: [],
      dialogVisible: false,
      options: [],
      listPromise: [],
      shelvesDisable: true,
      makeLoading: false,
      shelvesOptions: [],
      tableDataLoading: false,
      detailsTableLoading: false,
      exportLoading: false
    }
  },
  watch: {
    $route (to, from) {
      this.$route.query.warehouseId = to.query.warehouseId
      this.init()
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      searchFormat.warehouseId = this.$route.query.warehouseId
      this.selectForm = Copy(searchFormat)
      this.getList()
      this.getOptions()
      Promise.all([...this.listPromise]).then(e => {
        e.forEach(e => {
          this.options.push(e.body)
        })
        this.options.push([
          // {
          //   key: '1',
          //   label: '货损'
          // },
          {
            key: '2',
            label: '丢失'
          },
          {
            key: '0',
            label: '正常'
          }
        ])
      })
    },
    search () {
      this.selectForm.pageNo = 1
      this.getList()
    },
    reset () {
      this.shelvesDisable = true
      this.selectForm = Copy(searchFormat)
      searchFormat.warehouseId = this.$route.query.warehouseId
      this.selectForm.pageNo = 1
      this.getList()
    },
    detailsLog (row) {
      this.getDetailsInfo(row)
      this.detaildsTable = []
      this.dialogVisible = true
    },
    async getList () {
      this.tableDataLoading = true
      const { body: data } = await api.inventory_storageList(
        Object.assign(this.selectForm)
      )
      this.tableDataLoading = false
      this.pageTotal = data.sumDataCount
      this.stockTable = data.datas
    },
    getOptions () {
      [1, 2, 3].forEach(e => {
        this.listPromise.push(api.getList_Classify({ classifyLevel: e }))
      })
      this.listPromise.push(api.selectAll_brand())
      this.listPromise.push(api.getListIp_ip())
      this.listPromise.push(
        api.entrepot_selectArea({ warehouseId: this.$route.query.warehouseId })
      )
    },
    async areaChange () {
      this.selectForm.shelvesId = null
      if (this.selectForm.areaId) {
        this.shelvesDisable = false
      } else {
        this.shelvesDisable = true
      }
      const data = await api.entrepot_selectShelves({
        areaId: this.selectForm.areaId
      })
      this.shelvesOptions = data.body
    },
    sizeChange (val) {
      this.selectForm.pageSize = val
      this.getList()
    },
    currentChange (val) {
      this.selectForm.pageNo = val
      this.getList()
    },
    async exportExcel () {
      try {
        this.exportLoading = true
        this.selectForm.pageNo = 1
        const data = await api.inventory_storage_export(this.selectForm)
        downloadFileByUrl(data.body)
      } finally {
        this.exportLoading = false
      }
    },
    async getDetailsInfo (row) {
      this.detailsTableLoading = true
      const { body: data } = await api.inventory_storage_detail({
        barCode: row.barCode,
        warehouseId: searchFormat.warehouseId
      })
      this.detailsTableLoading = false
      this.detaildsTable = data
    },
    damagefreight ({ row, rowIndex }) {
      if (row.status === 1) {
        return 'damage'
      } else if (row.status === 2) {
        return 'loss'
      }
    },
    make (row) {
      this.$confirm('是否重新生码？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          this.makeLoading = true
          const { code } = await api.intoDetailPrint({
            barCode: row.barCode
          })
          if (code === 200) {
            this.$message.success('生码成功')
            this.$emit('refresh')
            this.getGoodsList()
          }
        } catch (error) {
          this.$message.error('生码失败')
        } finally {
          this.makeLoading = false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  margin-left: 10px;
  margin-right: 10px;
}

.head-search-item {
  width: 300px;
}

::v-deep .content {
  width: 200px !important;
}

::v-deep .el-select {
  width: 100% !important;
}

::v-deep .damage {
  background: #efdede;
}

::v-deep .loss {
  background: #f5f7b2;
}

::v-deep .el-select__tags-text {
  display: inline-block;
  max-width: 100px;

  @media (max-width: 1400px) {
    max-width: 42px;
  }

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .el-select .el-tag__close.el-icon-close {
  top: -7px;
  right: -4px;
}
</style>
