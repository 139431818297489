export default ({ request }) => ({
  // 豆腐块数据
  getTopInfo (query = {}) {
    return request({
      url: '/homePage/top/info',
      method: 'get',
      data: query
    })
  },
  // 柱状图数据
  getColumnarStatistical (data = {}) {
    return request({
      url: '/homePage/top/orderBrokenLine',
      method: 'post',
      data: data
    })
  },
  // 折现图数据
  getLineStatistics (data = {}) {
    return request({
      url: '/homePage/top/orderProductBrokenLine',
      method: 'post',
      data: data
    })
  },
  // 饼图数据
  getProductStatistics (query = {}) {
    return request({
      url: '/homePage/top/productStatistics',
      method: 'get',
      data: query
    })
  },
  // 商品数据
  getPieStatistics (query = {}) {
    return request({
      url: '/homePage/top/orderStatistics',
      method: 'get',
      data: query
    })
  },
  // 待处理采购单数据
  getPurchaseOrders (query = {}) {
    return request({
      url: '/homePage/top/waitPurchaseList',
      method: 'get',
      data: query
    })
  },
  // 待处理调拨单数据
  getAllotOrders (query = {}) {
    return request({
      url: '/homePage/top/waitAllotList',
      method: 'get',
      data: query
    })
  },
  // 待处理预定单数据
  getReserveOrders (query = {}) {
    return request({
      url: '/homePage/top/waitReserveList',
      method: 'get',
      data: query
    })
  },
  // 待处理异常单数据
  getAbnormalOrders (query = {}) {
    return request({
      url: '/homePage/top/waitAbnormalList',
      method: 'get',
      data: query
    })
  },
  // 待处理入库单数据
  getWaitIntoOrders (query = {}) {
    return request({
      url: '/homePage/top/waitIntoOrderList',
      method: 'get',
      data: query
    })
  },
  // 待处理出库单数据
  getWaitOutOrders (query = {}) {
    return request({
      url: '/homePage/top/waitOutOrderList',
      method: 'get',
      data: query
    })
  },
  // 商品到期提醒excel
  getproductExpirationExcel (data = {}) {
    return request({
      url: '/v1/inventory/storage/exportInv',
      method: 'post',
      data: data
    })
  },
  // 商品到期提醒列表
  getproductExpiration (data = {}) {
    return request({
      url: '/v1/inventory/storage/getPageResult',
      method: 'post',
      data: data
    })
  }
})
