/**
 * <角色请求接口
 * @author 王振宇
 * @since 2021-03-02
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_ROLE_PAGE_LIST: function GET_ROLE_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/role/getPageList',
        method: 'post',
        params: data
      });
    },
    GET_ROLE_LIST: function GET_ROLE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/role/getList',
        method: 'post',
        params: data
      });
    },
    GET_USER_ROLES: function GET_USER_ROLES() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return request({
        url: '/v1/role/userRoles',
        method: 'post',
        data: data,
        params: params
      });
    },
    GET_ROLE_INFO: function GET_ROLE_INFO() {
      return request({
        url: '/v1/role/info',
        method: 'post'
      });
    },
    SAVE_ROLE: function SAVE_ROLE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/role/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_ROLE: function UPDATE_ROLE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/role/update',
        method: 'put',
        data: data
      });
    },
    DELETE_ROLE: function DELETE_ROLE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/role/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_ROLE: function DELETE_BATCH_ROLE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/role/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});