/**
 * < 应用管理请求接口
 * @author 王振宇
 * @since 2021-05-15
 */
export default ({ service, request, serviceForMock, requestForMock, mock, faker, tools }) => ({
  GET_APPS_PAGE_LIST (data = {}) {
    return request({
      url: '/v1/apps/getPageList',
      method: 'get',
      params: data
    })
  },
  GET_APPS_LIST (data = {}) {
    return request({
      url: '/v1/apps/getList',
      method: 'get',
      params: data
    })
  },
  GET_APPS_INFO () {
    return request({
      url: '/v1/apps/info',
      method: 'get'
    })
  },
  SAVE_APPS (data = {}) {
    return request({
      url: '/v1/apps/save',
      method: 'post',
      data: data
    })
  },
  UPDATE_APPS (data = {}) {
    return request({
      url: '/v1/apps/update',
      method: 'put',
      data: data
    })
  },
  DELETE_APPS (data = {}) {
    return request({
      url: '/v1/apps/delete',
      method: 'delete',
      data: data
    })
  },
  DELETE_BATCH_APPS (data = {}) {
    return request({
      url: '/v1/apps/deleteBatch',
      method: 'delete',
      data: data
    })
  }
})
