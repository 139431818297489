<template>
  <div class="dialog">
    <el-dialog
      :close-on-click-modal="false"
      :title="titleName"
      :visible.sync="visibleDialog"
      top="8vh"
      width="80%"
      @close="dialogClose"
    >
      <el-form
        ref="InfoForm"
        :model="redactData"
        :rules="rules"
        class="demo-ruleForm"
        label-width="100px"
        style="font-weight: bold"
        v-loading="formLoading"
      >
        <div style="display: flex">
          <el-form-item label="盘点标题" prop="checkName">
            <el-input
              v-model="redactData.checkName"
              clearable
              size="small"
              style="width: 400px"
            ></el-input>
          </el-form-item>
          <el-form-item label="盘点维度" prop="dimension">
            <el-select
              v-model="redactData.dimension"
              placeholder="请选择盘点维度"
              size="small"
              style="width: 200px;"
              @change="changeDimensionality()"
            >
              <el-option key="0" label="商品维度" :value="0"> </el-option>
              <el-option key="1" label="货架维度" :value="1"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="盘点仓库" prop="warehouseId">
            <el-select
              v-model="redactData.warehouseId"
              placeholder="请选择盘点仓库"
              size="small"
              style="width: 200px;"
            >
              <el-option
                v-for="item in warehouseIdData"
                :key="item.warehouseId"
                :label="item.warehouseName"
                :value="item.warehouseId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="盘点类型" prop="type">
            <el-radio-group v-model="redactData.type">
              <el-radio :label="0">录入盘库</el-radio>
              <el-radio :disabled="true" :label="1">扫码盘库</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div
          v-if="titleName !== '新增盘库单'"
          style="display: flex;justify-content: space-between"
        >
          <el-form-item label="盘点单号">
            <div style="font-weight: normal">{{ redactData.checkId }}</div>
          </el-form-item>
          <el-form-item label="盘点状态">
            {{
              redactData.status === 0
                ? "待盘库"
                : redactData.status === 1
                ? "盘库中"
                : redactData.status === 3
                ? "盘库完成"
                : redactData.status === 9
                ? "终止入库"
                : ""
            }}
          </el-form-item>
          <el-form-item label="制单人员">
            <div style="font-weight: normal">
              {{ redactData.createdName ? redactData.createdName : "- -" }}
            </div>
          </el-form-item>
          <el-form-item label="创建时间">
            <div style="font-weight: normal">
              {{
                redactData.createdTimestamp
                  ? redactData.createdTimestamp
                  : "- -"
              }}
            </div>
          </el-form-item>
          <el-form-item label="完成时间" v-if="redactData.status == 2">
            <div style="font-weight: normal">
              {{ redactData.endTime ? redactData.endTime : "- -" }}
            </div>
          </el-form-item>
        </div>
        <el-form-item label="盘点备注" prop="remark">
          <el-input
            v-model="redactData.remark"
            :autosize="{ minRows: 3, maxRows: 5 }"
            clearable
            maxlength="240"
            show-word-limit
            size="small"
            type="textarea"
          ></el-input>
        </el-form-item>
        <div style="width: 100%;height: 40px;background-color:#d7d7d7;">
          <div
            style="font-weight: bolder;margin-left: 20px;line-height: 40px;;display: flex;justify-content: space-between"
          >
            <div><span style="color: #f56c6c">* &nbsp</span> 商品列表</div>
            <div style="margin-right: 20px">
              <el-button
                v-if="
                  redactData.dimension == '0' && redactData.warehouseId !== ''
                "
                size="mini"
                type="primary"
                @click="selectGoods()"
                >选择商品
              </el-button>
              <el-button
                v-if="
                  redactData.dimension == '1' && redactData.warehouseId !== ''
                "
                size="mini"
                type="success"
                @click="selectArea()"
                >选择货架
              </el-button>
            </div>
          </div>
        </div>
        <div class="goodsDataSty">
          <el-form-item prop="goodsData">
            <el-table
              ref="goodsData"
              :cell-style="{ textAlign: 'center' }"
              :data="redactData.goodsData"
              :header-cell-style="{
                background: '#dfe6ec',
                color: '#666666',
                textAlign: 'center'
              }"
              v-loading="goodsDataLoading"
              :row-style="{ height: '57px' }"
              element-loading-text="数据加载中"
              max-height="420px"
              style="padding: 5px 5px 0;"
              tooltip-effect="dark"
            >
              <el-table-column
                align="center"
                key="index"
                label="序号"
                width="80"
              >
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                key="id"
                label="商品编码"
                prop="id"
              >
              </el-table-column>
              <el-table-column
                align="center"
                key="goodsName"
                label="商品名称"
                prop="goodsName"
              >
              </el-table-column>
              <el-table-column
                align="center"
                label="商品图片"
                key="pictureUrl"
                prop="pictureUrl"
              >
                <template slot-scope="scope">
                  <el-image
                    :preview-src-list="[
                      downloadUrl + '/' + scope.row.pictureUrl
                    ]"
                    :src="downloadUrl + '/' + scope.row.pictureUrl"
                    style="width: 40px; height: 40px;"
                  />
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                key="intoPrices"
                label="入库价"
                prop="intoPrices"
              >
              </el-table-column>
              <el-table-column
                v-if="redactData.dimension == '1' && titleName !== '录入盘库单'"
                align="center"
                prop="areaName"
                key="areaName"
                label="区域"
              >
              </el-table-column>
              <el-table-column
                v-if="redactData.dimension == '1' && titleName !== '录入盘库单'"
                align="center"
                prop="shelvesName"
                key="shelvesName"
                label="货架"
              >
                <template slot-scope="scope">
                  {{ scope.row.shelvesName ? scope.row.shelvesName : "- -" }}
                </template>
              </el-table-column>
              <el-table-column
                v-if="redactData.dimension == '1' && titleName !== '录入盘库单'"
                align="center"
                key="shelvesLayers"
                prop="shelvesLayers"
                label="层数"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.shelvesLayers ? scope.row.shelvesLayers : "- -"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="在库数量"
                prop="sumAll"
                key="sumAll"
                v-if="titleName !== '新增盘库单' && redactData.status != 0"
              >
              </el-table-column>
              <el-table-column
                v-if="redactData.status === 1"
                align="center"
                label="实际库存"
                prop="checkNum"
                key="checkNum"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'goodsData.' + scope.$index + '.checkNum'"
                    :rules="rules.checkNum"
                  >
                    <el-input-number
                      v-model="scope.row.checkNum"
                      :min="0"
                      :step="1"
                      size="small"
                      step-strictly
                    ></el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                v-if="redactData.status === 1"
                align="center"
                label="盘点盈亏"
              >
                <template slot-scope="scope">
                  <el-tag
                    v-if="scope.row.checkNum != null"
                    :style="{
                      color:
                        scope.row.checkNum * 1 - scope.row.sumAll * 1 > 0
                          ? '#00cc66'
                          : scope.row.checkNum * 1 - scope.row.sumAll * 1 < 0
                          ? '#cc3333'
                          : ''
                    }"
                    :type="
                      scope.row.checkNum * 1 - scope.row.sumAll * 1 > 0
                        ? 'success'
                        : scope.row.checkNum * 1 - scope.row.sumAll * 1 < 0
                        ? 'danger'
                        : ''
                    "
                  >
                    {{
                      scope.row.checkNum * 1 - scope.row.sumAll * 1 > 0
                        ? "+" + (scope.row.checkNum * 1 - scope.row.sumAll * 1)
                        : scope.row.checkNum * 1 - scope.row.sumAll * 1
                    }}
                  </el-tag>
                  <span v-else-if="scope.row.checkNum == null">- -</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="redactData.status === 0"
                align="center"
                label="操作"
              >
                <template slot-scope="scope">
                  <el-button
                    size="small"
                    type="danger"
                    @click="deleteRow(scope.$index)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item>
            <div style="margin-left: 45%;margin-top: 15px">
              <el-button size="small" @click="cancel()">取消</el-button>
              <el-button
                size="small"
                type="success"
                :disabled="saveLoading"
                :loading="saveLoading"
                @click="save()"
                >保存</el-button
              >
              <el-button
                size="small"
                type="primary"
                :disabled="submitLoading"
                :loading="submitLoading"
                @click="submit()"
                >提交</el-button
              >
            </div>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
    <setStockGoodsList
      ref="setStockGoodsList"
      @setGoods="addGoods"
    ></setStockGoodsList>
    <el-dialog
      :close-on-click-modal="false"
      :title="'区域选择'"
      :visible.sync="visibleDialogArea"
      top="25vh"
      width="20%"
      @close="dialogAreaClose"
    >
      <el-form
        ref="AreaForm"
        :model="areaData"
        :rules="area"
        class="demo-ruleForm"
        label-width="100px"
        style="font-weight: bold"
      >
        <el-form-item label="盘点仓库" prop="warehouseId">
          <el-select
            v-model="areaData.warehouseId"
            :disabled="true"
            placeholder="请选择盘点仓库"
            size="small"
            style="width: 200px;"
          >
            <el-option
              v-for="item in warehouseIdData"
              :key="item.warehouseId"
              :label="item.warehouseName"
              :value="item.warehouseId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="盘点区域" prop="areaId">
          <el-select
            v-model="areaData.areaId"
            clearable
            placeholder="请选择盘点区域"
            size="small"
            style="width: 200px;"
            @change="changeAreaId"
            @click.native="getArea(redactData.warehouseId)"
          >
            <el-option
              v-for="item in areaIdData"
              :key="item.areaId"
              :label="item.areaName"
              :value="item.areaId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="盘点货架" prop="shelvesId">
          <el-select
            v-model="areaData.shelvesId"
            clearable
            placeholder="请选择盘点货架"
            size="small"
            style="width: 200px;"
            @click.native="getShelves(areaData.areaId)"
          >
            <el-option
              v-for="item in shelvesIdData"
              :key="item.shelvesId"
              :label="item.shelvesName"
              :value="item.shelvesId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div style="margin-left: 40%;margin-top: 15px">
            <el-button size="small" @click="abolish()">取消</el-button>
            <el-button
              size="small"
              type="success"
              :disabled="loadingChooseArea"
              :loading="loadingChooseArea"
              @click="determine()"
              >确定</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import setStockGoodsList from '@/components/setStockGoodsList/index.vue'
import api from '@/api'

const redactDataDefault = {
  checkName: '',
  type: '',
  remark: '',
  dimension: '',
  warehouseId: '',
  goodsData: []
}
const areaDataDefault = {
  warehouseId: '',
  areaId: '',
  shelvesId: ''
}
export default {
  name: 'MakeInventoryDialog',
  components: {
    setStockGoodsList
  },
  data () {
    return {
      visibleDialog: false, // 弹窗开关
      visibleDialogArea: false,
      titleName: '', // 弹窗名称
      loadingChooseArea: false, // 选择货架加载flag
      formLoading: false,
      saveLoading: false,
      submitLoading: false,
      goodsDataLoading: false,
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL, // downloadUrl
      redactData: JSON.parse(JSON.stringify(redactDataDefault)),
      areaData: JSON.parse(JSON.stringify(areaDataDefault)),
      warehouseIdData: [],
      areaIdData: [],
      shelvesIdData: [],
      userInfo: {},
      rules: {
        checkName: [
          {
            required: true,
            message: '请输入盘点标题',
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: '请选择盘点类型',
            trigger: 'change'
          }
        ],
        dimension: [
          {
            required: true,
            message: '请选择盘点维度',
            trigger: 'change'
          }
        ],
        warehouseId: [
          {
            required: true,
            message: '请选择盘点仓库',
            trigger: 'change'
          }
        ],
        goodsData: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              this.validateGoods(rule, value, callback, 'cover')
            }
          }
        ],
        checkNum: [
          {
            required: true,
            message: '请输入数量',
            trigger: 'blur'
          }
        ]
      },
      area: {
        warehouseId: [
          {
            required: true,
            message: '请选择盘点仓库',
            trigger: 'change'
          }
        ]
        // areaId: [
        //   {
        //     required: true,
        //     message: '请选择盘点区域',
        //     trigger: 'change'
        //   }
        // ],
        // shelvesId: [
        //   {
        //     required: true,
        //     message: '请选择盘点货架',
        //     trigger: 'change'
        //   }
        // ]
      }
    }
  },
  computed: {},
  methods: {
    async openDialog (title, row) {
      this.titleName = title
      this.userInfo = JSON.parse(window.localStorage.getItem('userInfo') ?? '{}')
      await this.getUserWarehouse()
      setTimeout(() => {
        this.$refs.InfoForm.clearValidate()
      }, 0)
      if (row) {
        this.redactData = row
        if (title === '编辑盘库单' || title === '修正盘库单') {
          await this.getEditGoods(row)
        } else if (title === '录入盘库单') {
          await this.getEnteringGoods(row)
        }
      }
      this.visibleDialog = true
    },
    async getUserWarehouse () {
      const res = await api.getDepartmentToWarehouse({
        deptId: this.userInfo.deptId
      })
      this.warehouseIdData = res.body
    },
    async getEditGoods (row) {
      this.redactData.goodsData = []
      try {
        this.goodsDataLoading = true
        const { dimension } = this.redactData
        const res = await api.makeInventoryGetStockDetail({ id: row.id })
        const data = res.body.inventoryList.map(item => {
          const { goodsCoding, goodsName, intoPrices, goodsNum, pictureUrl, areaName, shelvesName, shelvesLayers, intoPrice } = item
          return {
            id: goodsCoding,
            goodsName,
            intoPrices: dimension === 0 ? intoPrices : intoPrice,
            sumAll: goodsNum,
            pictureUrl,
            areaName: dimension === 1 ? areaName : undefined,
            shelvesName: dimension === 1 ? shelvesName : undefined,
            shelvesLayers: dimension === 1 ? shelvesLayers : undefined
          }
        })
        this.redactData.goodsData = data
      } catch (e) {
        this.$message.error('获取商品失败')
      } finally {
        this.goodsDataLoading = false
      }
    },
    async getEnteringGoods (row) {
      this.redactData.goodsData = []
      try {
        this.goodsDataLoading = true
        const res = await api.makeInventoryShowCheckStock({
          id: row.id
        })
        const data = res.body.checkGoodsList.map(item => {
          const { goodsCoding, goodsName, intoPrices, goodsNum, pictureUrl } = item
          return {
            id: goodsCoding,
            goodsName: goodsName,
            intoPrices: intoPrices,
            sumAll: goodsNum,
            pictureUrl: pictureUrl
          }
        })
        this.redactData.goodsData = data
      } catch (e) {
        this.$message.error(' 获取商品失败')
      } finally {
        this.goodsDataLoading = false
      }
    },
    validateGoods (rule, value, callback) {
      if (this.redactData.goodsData.length === 0) {
        callback(new Error('请选择商品'))
      } else {
        callback()
      }
    },
    dialogClose () {
      this.cancel()
    },
    dialogAreaClose () {
      this.abolish()
    },
    // 表单取消
    cancel () {
      this.visibleDialog = false
      this.redactData = JSON.parse(JSON.stringify(redactDataDefault))
    },
    // 选择区域 取消
    abolish () {
      this.visibleDialogArea = false
      this.areaData = JSON.parse(JSON.stringify(areaDataDefault))
    },
    deleteRow (index) {
      this.redactData.goodsData.splice(index, 1)
    },
    // 选择区域 确认
    async determine () {
      this.loadingChooseArea = true
      try {
        const { areaId, shelvesId, warehouseId } = this.areaData
        const res = await api.makeInventoryGetInventoryList({ areaId, shelvesId, warehouseId })
        const goodsData = res.body.map(inventoryItem => ({
          id: inventoryItem.goodsCoding,
          goodsName: inventoryItem.goodsName,
          pictureUrl: inventoryItem.pictureUrl,
          intoPrices: inventoryItem.intoPrices,
          areaName: inventoryItem.areaName,
          shelvesName: inventoryItem.shelvesName,
          shelvesLayers: inventoryItem.shelvesLayers
        }))
        this.redactData.goodsData = goodsData
        this.visibleDialogArea = false
        this.$message.success('选择成功')
      } catch (e) {
        this.$message.error('获取信息失败')
      } finally {
        this.loadingChooseArea = false
      }
    },
    async save () {
      const titleMap = new Map([
        [
          '新增盘库单',
          {
            apiFunc: api.makeInventorySaveCheckStock,
            message: '保存成功'
          }
        ],
        [
          '编辑盘库单',
          {
            apiFunc: api.makeInventoryUpdateCheckStock,
            message: '保存成功'
          }
        ],
        [
          '录入盘库单',
          {
            apiFunc: api.makeInventoryEnterCheckStock,
            message: '保存成功'
          }
        ]
      ])
      this.$refs.InfoForm.validate(async valid => {
        if (valid) {
          try {
            this.formLoading = true
            this.saveLoading = true
            const { apiFunc, message } = titleMap.get(this.titleName)
            const data = this.getData(this.titleName, this.redactData)
            const params = {
              id:
                this.titleName !== '录入盘库单'
                  ? this.redactData.id
                  : undefined,
              checkId:
                this.titleName === '录入盘库单'
                  ? this.redactData.id
                  : this.redactData.checkId,
              checkName:
                this.titleName !== '录入盘库单'
                  ? this.redactData.checkName
                  : undefined,
              dimension:
                this.titleName !== '录入盘库单'
                  ? this.redactData.dimension
                  : undefined,
              goodsCoding: this.titleName !== '录入盘库单' ? data : undefined,
              goodsNumList: this.titleName === '录入盘库单' ? data : undefined,
              remark:
                this.titleName !== '录入盘库单'
                  ? this.redactData.remark
                  : undefined,
              type:
                this.titleName === '新增盘库单'
                  ? this.redactData.type
                  : undefined,
              warehouseId:
                this.titleName !== '录入盘库单'
                  ? this.redactData.warehouseId
                  : undefined
            }
            // 移除无用的属性
            Object.keys(params).forEach(key => {
              if (params[key] === undefined) {
                delete params[key]
              }
            })
            const res = await apiFunc(params)
            if (res.code === 200) {
              this.visibleDialog = false
              this.$message.success(message)
            }
          } catch (error) {
            this.$message.warning('保存失败')
          } finally {
            this.formLoading = false
            this.saveLoading = false
          }
        } else {
          this.$message.error('请填写完整信息')
        }
      })
    },
    async submit () {
      const titleMap = new Map([
        [
          '新增盘库单',
          {
            apiFunc: api.makeInventoryCreateCheckStock,
            message: '提交成功'
          }
        ],
        [
          '编辑盘库单',
          {
            apiFunc: api.makeInventoryUpdateCheckStock,
            message: '提交成功'
          }
        ],
        [
          '录入盘库单',
          {
            apiFunc: api.makeInventoryEnterCheckStock,
            message: '保存成功'
          }
        ]
      ])
      this.$refs.InfoForm.validate(async valid => {
        if (valid) {
          try {
            this.formLoading = true
            this.saveLoading = true
            const { apiFunc, message } = titleMap.get(this.titleName)
            const data = this.getData(this.titleName, this.redactData)
            const params = {
              id:
                this.titleName !== '录入盘库单'
                  ? this.redactData.id
                  : undefined,
              checkId:
                this.titleName === '录入盘库单'
                  ? this.redactData.id
                  : this.redactData.checkId,
              checkNo:
                this.titleName === '录入盘库单'
                  ? this.redactData.checkId
                  : undefined,
              checkName:
                this.titleName !== '录入盘库单'
                  ? this.redactData.checkName
                  : undefined,
              dimension:
                this.titleName !== '录入盘库单'
                  ? this.redactData.dimension
                  : undefined,
              goodsCoding: this.titleName !== '录入盘库单' ? data : undefined,
              goodsNumList: this.titleName === '录入盘库单' ? data : undefined,
              remark:
                this.titleName !== '录入盘库单'
                  ? this.redactData.remark
                  : undefined,
              type:
                this.titleName === '新增盘库单'
                  ? this.redactData.type
                  : undefined,
              warehouseId:
                this.titleName !== '录入盘库单'
                  ? this.redactData.warehouseId
                  : undefined,
              submitFlag: this.titleName !== '新增盘库单' ? 1 : undefined
            }
            // 移除无用的属性
            Object.keys(params).forEach(key => {
              if (params[key] === undefined) {
                delete params[key]
              }
            })
            const res = await apiFunc(params)

            if (res.code === 200) {
              this.visibleDialog = false
              this.$message.success(message)
            }
          } catch (error) {
            this.$message.warning('提交失败')
          } finally {
            this.formLoading = false
            this.saveLoading = false
          }
        } else {
          this.$message.error('请填写完整信息')
        }
      })
    },
    getData (titleName, redactData) {
      let data = []
      if (titleName === '新增盘库单' || titleName === '编辑盘库单') {
        data = redactData.goodsData.map(item => item.id)
      } else if (titleName === '录入盘库单') {
        data = redactData.goodsData.map(item => ({
          goodsCoding: item.id,
          checkNum: item.checkNum
        }))
      }

      return data
    },
    changeDimensionality () {
      this.redactData.goodsData = []
    },
    // 选择商品
    selectGoods () {
      this.$refs.setStockGoodsList.open(this.redactData.warehouseId)
    },
    // 选择货架
    selectArea () {
      this.visibleDialogArea = true
      setTimeout(() => {
        this.$refs.AreaForm.clearValidate()
      }, 10)
      this.areaData.warehouseId = this.redactData.warehouseId
    },
    // 获取区域
    async getArea (id) {
      const res = await api.departmentGetChooseArea({
        warehouseId: id
      })
      this.areaIdData = res.body
    },
    // 获取货架
    async getShelves (id) {
      const res = await api.departmentGetChooseShelves({
        areaId: id
      })
      this.shelvesIdData = res.body
    },
    changeAreaId () {
      this.areaData.shelvesId = ''
      this.shelvesIdData = []
    },
    // 添加商品
    addGoods (data) {
      const newArr = data.map(item => ({
        id: item.id,
        goodsName: item.goodsName,
        pictureUrl: item.pictureUrl,
        intoPrices: item.price
      }))
      const indexList = []
      newArr.forEach((item, index) => {
        this.redactData.goodsData.forEach(redactItem => {
          if (item.id === redactItem.id) {
            indexList.push(index)
          }
        })
      })
      this.redactData.goodsData.push(
        ...newArr.filter((item, index) => !indexList.includes(index))
      )
    }

  }
}
</script>
<style lang="scss" scoped>
::v-deep .goodsDataSty .el-form-item__content {
  margin-left: 0 !important;
}
</style>
