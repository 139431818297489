export default ({ request }) => ({
  // 分页查询list
  getPageList_purchaseStatisticalList (data = {}) {
    return request({
      url: '/v1/statistics/dailyPurchase',
      method: 'post',
      data: data
    })
  },
  // 分页查询汇总
  getPageList_purchaseStatisticalSum (data = {}) {
    return request({
      url: '/v1/statistics/dailyPurchaseSum',
      method: 'post',
      data: data
    })
  },
  // 商品详情
  infoPurchaseStatisticalGoods (data = {}) {
    return request({
      url: '/v1/statistics/purchaseList',
      method: 'post',
      data: data
    })
  },
  // 商品详情聚合统计
  infoPurchaseStatisticalGoodsSum (data = {}) {
    return request({
      url: '/v1/statistics/purchaseSum',
      method: 'post',
      data: data
    })
  },
  // 订单详情
  infoPurchaseStatisticalOrder (data = {}) {
    return request({
      url: '/v1/statistics/dailyPurchase/orderDetail',
      method: 'post',
      data: data
    })
  },
  // 订单详情聚合统计
  infoPurchaseStatisticalOrderSum (data = {}) {
    return request({
      url: '/v1/statistics/dailyPurchase/orderSum',
      method: 'post',
      data: data
    })
  },
  // 每日采购订单维度统计导出
  exportPurOrder (data = {}) {
    return request({
      url: '/v1/statistics/exportPurOrder',
      method: 'post',
      data: data
    })
  },
  // 每日采购订单维度统计导出
  exportGoodsPurchases (data = {}) {
    return request({
      url: '/v1/statistics/exportGoodsPurchases',
      method: 'post',
      data: data
    })
  }
})
