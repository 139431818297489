<template>
  <div class="basic">
    <div class="title">
      <span>基本信息</span>
    </div>
    <head-search :item-style="itemStyle" :label-style="labelStyle">
      <head-search-item v-if="info.fatherId" label="主订单号:">
        <el-button
          style="padding:0;font-size:16px"
          type="text"
          @click="toDetail"
        >
          {{ info.fatherId }}
        </el-button>
      </head-search-item>
      <head-search-item label="出库单号:">
        {{ info.outId }}
      </head-search-item>
      <head-search-item
        v-if="
          (info.type === 3 && info.status === 0) ||
            ((info.status === 2 || info.status === 3) && info.type === 1)
        "
        label="系统订单号:"
      >
        {{ info.orderId }}
      </head-search-item>
      <head-search-item label="出库类型:">
        {{ listMap.outType.get(info.type) }}
      </head-search-item>
      <head-search-item label="出库状态:">
        {{
          info.status === 0
            ? '待出库'
            : info.status === 1
              ? '出库中'
              : info.status === 2
                ? '部分出库'
                : info.status === 3
                  ? '全部出库'
                  : info.status === 4
                    ? '终止出库'
                    : ''
        }}
      </head-search-item>
      <head-search-item label="订单来源:">
        {{ listMap.outSource.get(info.source) }}
      </head-search-item>
      <head-search-item label="配送方式:">
        {{ listMap.distributionType.get(info.distribution) }}
      </head-search-item>
      <head-search-item label="出库数量:">
        {{ goodsInfo.num ? goodsInfo.num : logInfo.outNum }}
      </head-search-item>
      <head-search-item v-if="info.status === 3" label="出库时间:">
        {{ info.outboundTime }}
      </head-search-item>
      <head-search-item
        v-if="!((info.status === 2 || info.status === 3) && info.type === 1)"
        label="入库价总额:"
      >
        {{ goodsInfo.recyclePrice ? goodsInfo.recyclePrice : logInfo.recyclePrice }}
      </head-search-item>
      <head-search-item label="出库价总额:">
        {{ goodsInfo.price ? goodsInfo.price : logInfo.price }}
      </head-search-item>
      <head-search-item
        v-if="!((info.status === 2 || info.status === 3) && info.type === 1)"
        label="仓库名称:"
      >
        {{ info.areaName }}
      </head-search-item>
      <head-search-item label="制单人员:">
        {{ info.createdName }}
      </head-search-item>
      <head-search-item label="创建时间:">
        {{ info.createdTimestamp }}
      </head-search-item>
    </head-search>
    <div
      v-if="!((info.status === 2 || info.status === 3) && info.type === 1)"
      class="remark"
    >
      <span class="label">备注：</span>
      <span class="content">{{ info.remark }}</span>
    </div>
    <div class="title">
      <span>用户信息</span>
    </div>
    <head-search :item-style="itemStyle" :label-style="labelStyle">
      <head-search-item label="收货姓名:">
        {{ info.chargeName }}
      </head-search-item>
      <head-search-item label="收货电话:">
        {{ info.chargePhone }}
      </head-search-item>
      <head-search-item label="物流公司:">
        {{ info.courierCompany }}
      </head-search-item>
      <head-search-item label="物流单号:">
        {{ info.courierOrderId }}
      </head-search-item>
    </head-search>
    <div class="remark">
      <span class="label">收货地址：</span>
      <span class="content">{{ info.chargeAddress }}</span>
    </div>
<!--    <div class="title">-->
<!--      <span>出库打包图片</span>-->
<!--    </div>-->
<!--    <div style="width: 100%;height: 180px">-->
<!--      <template v-if="goodsInfo.outImage && goodsInfo.outImage != ''  &&  goodsInfo.outImage != ' ' ">-->
<!--              <img-->
<!--                v-for="(item,index) in goodsInfo.outImage.split(',')"-->
<!--                :key="index"-->
<!--                :src="downloadUrl + '/' + item"-->
<!--                alt=""-->
<!--                style=" width: 150px; height: 150px; text-align: center;cursor: pointer;margin: 15px"-->
<!--                @click="getPreview(downloadUrl + '/' + item)"-->
<!--              />-->
<!--      </template>-->
<!--    </div>-->
    <!--     点击图片放大弹出页面         -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :title="'图片放大'"
      :visible.sync="dialogFormVisiblePic"
      style="margin:20px auto"
      width="540px"
      @close="closeDialogPic()"
    >
      <img
        v-if="this.dialogImageUrl !== ''"
        :src="this.dialogImageUrl"
        alt=""
        style=" width: 500px; height: 500px; text-align: center"
      />
    </el-dialog>
  </div>
</template>
<script>
import api from '@/api'
import { selectFormData } from '../../list/contant'

export default {
  watch: {
    info: {
      handler (val) {
        Promise.all([
          this.getSelectList('DistributionEnum', 'distributionType'),
          this.getSelectList('OutTypeEnum', 'outType'),
          this.getoutSourceList()
        ]).then(() => {
          this.listMap.outType = new Map()
          this.listMap.distributionType = new Map()
          this.listMap.outSource = new Map()
          this.list.outType.forEach(item => {
            this.listMap.outType.set(item.key, item.value)
          })
          this.list.distributionType.forEach(item => {
            this.listMap.distributionType.set(item.key, item.value)
          })
          this.list.outSource.forEach(item => {
            this.listMap.outSource.set(item.id, item.keys)
          })
        })
      },
      immediate: true,
      deep: true
    }
  },
  props: {
    info: {
      type: Object
    },
    goodsInfo: {
      type: Object
    },
    logInfo: {
      type: Object
    }
  },
  data () {
    return {
      dialogImageUrl: '',
      dialogFormVisiblePic: false,
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL, // downloadUrl
      selectForm: JSON.parse(JSON.stringify(selectFormData)),
      labelStyle: 'width:80px;text-align:left;font-weight:600',
      itemStyle:
        'width:300px;padding-bottom:20px;padding-top:20px;display:flex;align-items: baseline;',
      contentStyle: '',
      title: '',
      list: {},
      listMap: {
        outType: new Map(),
        distributionType: new Map(),
        outSource: new Map()
      }
    }
  },
  methods: {
    toDetail () {
      this.selectForm.outId = this.info.fatherId
      this.$router.push({
        path: '/stockoutdetails',
        query: {
          outId: this.info.fatherId
        }
      })
    },
    async getSelectList (name, target) {
      const { body: data } = await api.selectedEnums_getList({
        enumName: name
      })
      this.list[target] = data
    },
    async getoutSourceList () {
      const { body: data } = await api.enumeration_getList({
        name: 'out_source'
      })
      this.list.outSource = data
    },
    // 图片放大开启
    getPreview (payProve) {
      this.dialogFormVisiblePic = true
      this.dialogImageUrl = payProve
    },
    // 图片放大关闭
    closeDialogPic () {
      this.dialogFormVisiblePic = false
      this.dialogImageUrl = ''
    },
  }
}
</script>
<style lang="scss" scoped>
.title {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #b8b8b81e;
  height: 30px;
  font-weight: bold;
}

.head-search {
  margin-left: 20px;
}

::v-deep .content {
  font-size: 14px !important;
  margin-left: 10px;
}

.remark {
  position: relative;
  left: 20px;
  width: 90%;
  right: 50px;
  margin-bottom: 20px;
  margin-top: 20px;

  .label {
    width: 80px;
    text-align: left;
    font-weight: 600;
    font-size: 14px;
  }
}
</style>
