<template>
  <d2-container class="updateVersion">
    <el-button size="small" type='primary' :disabled="isAdd" icon="el-icon-plus" @click="add">新增</el-button>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="scope">
          {{ scope.row.text }}
        </template>
      </el-table-column>
      <el-table-column prop="versionText" label="版本号">
      </el-table-column>
      <el-table-column prop="createdTimestamp" label="更新时间">
      </el-table-column>
    </el-table>
    <div class="pager-div">
      <el-pagination :current-page="this.page.pageNo" :disabled="isSelect" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :page-sizes="[10, 20, 30, 40, 50, 100]"
        layout="jumper, prev, pager, next,sizes" :total="pageTotal">
      </el-pagination>
    </div>
    <el-dialog @close="close" title="新增版本" :append-to-body="true" :visible.sync="dialogVisible"
      :close-on-click-modal="false">
      <el-form ref="addForm" :rules="rules" :model="addForm">
        <el-form-item label="版本号" prop="versionText">
          <el-input v-model="addForm.versionText" size="small" />
        </el-form-item>
        <el-form-item label="更新内容" prop="text">
          <el-input type="textarea" v-model="addForm.text" size="small" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">提 交</el-button>
      </span>
    </el-dialog>
  </d2-container>
</template>

<script>
import api from '@/api'
export default {
  name: 'updateVersion',
  data () {
    return {
      isSelect: this.checkauthority('select_record'),
      isAdd: this.checkauthority('add_record'),
      tableData: [],
      addForm: {
        versionText: '',
        text: ''
      },
      page: {
        pageNo: 1,
        pageSize: 10
      },
      dialogVisible: false,
      pageTotal: 0,
      rules: {
        versionText: [
          {
            required: true,
            message: '请输入版本号',
            trigger: 'blur'
          }
        ],
        text: [{
          required: true,
          message: '请输入更新内容',
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    async getList () {
      if (this.isSelect) {
        return
      }
      const { body: data } = await api.versionManagement_select({ ...this.page })
      this.pageTotal = data.sumDataCount
      this.tableData = data.datas
    },
    add () {
      this.dialogVisible = true
    },
    submit () {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          const result = await api.versionManagement_save(this.addForm)
          if (result.code === 200) {
            this.$message({
              type: 'success',
              message: '提交成功'
            })
            this.dialogVisible = false
            this.page.pageNo = 1
            this.getList()
          }
        }
      })
    },
    close () {
      this.$refs.addForm.resetFields()
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.page.pageNo = val
      this.getList()
    }
  },
  created () {
    this.getList()
  }
}
</script>
