//
//
//
//
//
//
//
//
export default {
  model: {
    prop: 'value',
    event: 'ValueChange'
  },
  props: ['value'],
  data: function data() {
    return {
      val: this.value,
      newValue: {},
      oldValue: {}
    };
  },
  watch: {
    value: function value(val) {
      this.value = val;
    }
  },
  methods: {
    seleChange: function seleChange(val) {
      this.newValue = val;
      this.$emit('ValueChange', this.val);
      this.$emit('change', this.newValue, this.oldValue);
      this.oldValue = this.newValue;
    }
  }
};