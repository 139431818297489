var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("recordCard", { ref: "recordCard" }),
      _c("productExpiration", { ref: "productExpiration" }),
      _c("columnarStatisticalChart", { ref: "columnarStatisticalChart" }),
      _c("brokenLineGraph", { ref: "brokenLineGraph" }),
      _c(
        "div",
        { staticClass: "divSty1" },
        [
          _c("pieStatistics", { ref: "pieStatistics", staticClass: "pieSty" }),
          _c("commodityCard", {
            ref: "commodityCard",
            staticClass: "commodity"
          })
        ],
        1
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "cardSty" },
        [
          _c("purchaseOrders", { ref: "purchaseOrders", staticClass: "card" }),
          _c("reserveOrders", { ref: "reserveOrders", staticClass: "card" })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "cardSty1" },
        [
          _c("waitIntoOrders", { ref: "waitIntoOrders", staticClass: "card" }),
          _c("waitOutOrders", { ref: "waitOutOrders", staticClass: "card" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message" }, [
      _c("div", { staticClass: "spanSty" }, [_vm._v(" 待办信息 ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }