var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goodsInfo" },
    [
      _c(
        "d2-container",
        [
          _c(
            "head-search",
            { attrs: { "label-style": "width:100px" } },
            [
              _c(
                "head-search-item",
                { attrs: { label: "商品编码" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入商品编码",
                      size: "small"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.search($event)
                      }
                    },
                    model: {
                      value: _vm.selectForm.goodsCoding,
                      callback: function($$v) {
                        _vm.$set(_vm.selectForm, "goodsCoding", $$v)
                      },
                      expression: "selectForm.goodsCoding"
                    }
                  })
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入商品名称",
                      size: "small"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.search($event)
                      }
                    },
                    model: {
                      value: _vm.selectForm.goodsName,
                      callback: function($$v) {
                        _vm.$set(_vm.selectForm, "goodsName", $$v)
                      },
                      expression: "selectForm.goodsName"
                    }
                  })
                ],
                1
              ),
              _c(
                "template",
                { slot: "button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        size: "small",
                        type: "primary"
                      },
                      on: { click: _vm.search }
                    },
                    [_vm._v(" 搜索 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh",
                        size: "small",
                        type: "warning"
                      },
                      on: { click: _vm.reset }
                    },
                    [_vm._v(" 重置 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.excelLoading,
                        loading: _vm.excelLoading,
                        icon: "el-icon-download",
                        size: "small",
                        type: "success"
                      },
                      on: { click: _vm.exportExcel }
                    },
                    [_vm._v(" 导出 ")]
                  )
                ],
                1
              )
            ],
            2
          ),
          _c("div", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadingSum,
                    expression: "loadingSum"
                  }
                ],
                staticClass: "showNum"
              },
              [
                _c("div", { staticClass: "font" }, [
                  _vm._v(" 采购数量 "),
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(this.sumData.goodsNum))
                  ])
                ]),
                _c("div", { staticClass: "font" }, [
                  _vm._v(" 采购总价 "),
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(this.sumData.totalPrice))
                  ])
                ]),
                _c("div", { staticClass: "font" }, [
                  _vm._v(" 代发数量 "),
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(this.sumData.otherNum))
                  ])
                ]),
                _c("div", { staticClass: "font" }, [
                  _vm._v(" 代发总价 "),
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(this.sumData.otherPrice))
                  ])
                ])
              ]
            )
          ]),
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  ref: "tableData",
                  attrs: {
                    "cell-style": { textAlign: "center" },
                    data: _vm.goodsInfoList,
                    "header-cell-style": {
                      background: "#dfe6ec",
                      color: "#666666",
                      textAlign: "center"
                    },
                    "row-style": { height: "57px" },
                    "element-loading-text": "数据加载中",
                    "max-height": "620px",
                    "tooltip-effect": "dark"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", label: "序号", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (_vm.selectForm.pageNo - 1) *
                                    _vm.selectForm.pageSize +
                                    scope.$index +
                                    1
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品编码",
                      prop: "goodsCoding",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "商品名称", prop: "goodsName" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品图片",
                      prop: "pictureUrl",
                      width: "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-image", {
                              staticStyle: { width: "40px", height: "40px" },
                              attrs: {
                                "preview-src-list": [
                                  _vm.downloadUrl + "/" + scope.row.pictureUrl
                                ],
                                src:
                                  _vm.downloadUrl + "/" + scope.row.pictureUrl
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单价",
                      prop: "unitPrice",
                      width: "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.unitPrice &&
                                    scope.row.unitPrice != 0
                                    ? scope.row.unitPrice
                                    : "- -"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购数量",
                      prop: "goodsNum",
                      width: "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.goodsNum && scope.row.goodsNum != 0
                                    ? scope.row.goodsNum
                                    : "- -"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购总价",
                      prop: "totalPrice",
                      width: "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.totalPrice &&
                                    scope.row.totalPrice != 0
                                    ? scope.row.totalPrice
                                    : "- -"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "代发数量",
                      prop: "otherNum",
                      width: "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.otherNum && scope.row.otherNum != 0
                                    ? scope.row.otherNum
                                    : "- -"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "代发总价",
                      prop: "otherPrice",
                      width: "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.otherPrice &&
                                    scope.row.otherPrice != 0
                                    ? scope.row.otherPrice
                                    : "- -"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.selectForm.pageNo,
                  "page-size": _vm.selectForm.pageSize,
                  "page-sizes": [10, 20, 30, 40, 50, 100],
                  total: _vm.selectForm.pageTotal,
                  layout: "->,total, sizes, prev, pager, next, jumper"
                },
                on: {
                  "size-change": _vm.sizeChange,
                  "current-change": _vm.currentChange
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }