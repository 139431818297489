import layoutHeaderAside from '@/layout/header-aside'
import { cloneDeep } from 'lodash'
// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
/**
 *  导入路由
 * @returns
 */
export function exRoutes () {
  var allRoutes = []
  var routes = localStorage.getItem('routers')
  if (routes != null) {
    var newOutes = JSON.parse(routes)
    newOutes.forEach(res => {
      try {
        var newRes = {
          path: res.path,
          name: res.name,
          component: _import(res.componentStr),
          meta: {
            title: res.meta.title
          }
        }
        allRoutes.push(newRes)
      } catch (e) {
        console.log(e)
      }
    })
  }
  return allRoutes
}
export const allRoutes = [
  // 首页
  {
    path: 'index',
    name: 'index',
    meta: {
      auth: true
    },
    component: _import('system/main/index')
  },
  // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  },
  // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  },
  {
    path: '/log',
    name: 'log',
    meta: {
      auth: true
    },
    component: _import('system/log')
  }
]
function getNetRoutes () {
  const allRoute = cloneDeep(allRoutes)
  var pushs = exRoutes()
  pushs.forEach(op => {
    allRoute.push(op)
  })
  return allRoute
}

/**
 * 在主框架内显示
 */
const frameIn = [
  {
    path: '/',
    redirect: { name: 'index' },
    component: layoutHeaderAside,
    children: getNetRoutes(),
    name: 'root'
  }
]

/**
 * 在主框架之外显示
 */
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    component: _import('system/login')
  }
]

/**
 * 错误页面
 */
const errorPage = [
  {
    path: '*',
    name: '404',
    component: _import('system/error/404')
  }
]

// 导出需要显示菜单的
export const frameInRoutes = frameIn

// 重新组织后导出
export const routes = [
  ...frameIn,
  ...frameOut,
  ...errorPage
]
