var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "agreement" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.tabChange },
          model: {
            value: _vm.tabValue,
            callback: function($$v) {
              _vm.tabValue = $$v
            },
            expression: "tabValue"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "0", label: "服务条款" } },
            [
              _c("erp-Editor", {
                ref: "editors0",
                staticStyle: { "text-align": "left" },
                attrs: {
                  value: _vm.agreement,
                  editorStyle:
                    "text-align:left;margin: 21px;width: calc(100% - 20px);"
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "1", label: "隐私协议" } },
            [
              _c("erp-Editor", {
                ref: "editors1",
                staticStyle: { "text-align": "left" },
                attrs: {
                  value: _vm.agreement,
                  editorStyle:
                    "text-align:left;margin: 21px;width: calc(100% - 20px);"
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "10px" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.submit }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }