<template>
  <!-- 如果内容比较多可以添加top属性 -->
  <d2-container>
    <el-form :inline="true" :model="selectForm">
      <el-form-item>
        <el-button size="small" :disabled="isCreate" type="success" icon="el-icon-circle-plus-outline" @click="toAdd">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      row-key="menuId"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      @selection-change="handleSelectionChange">
        <el-table-column
          prop="title"
          label="名称">
        </el-table-column>
        <el-table-column
          prop="type"
          label="目录类型">
          <template slot-scope="scope">
            {{checkMenuType(scope.row.type)}}
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          prop="createTime"
          label="创建时间">
        </el-table-column>
        <el-table-column
          prop="hidden"
          width="100"
          label="菜单显示">
          <template slot-scope="scope">
            <el-switch
              :disabled="isUpdate"
              v-model="scope.row.hidden"
              class="demo"
              active-color="#00A854"
              active-text="显示"
              @change="switchChange(scope.row)"
              :active-value="true"
              inactive-color="#F04134"
              inactive-text="隐藏"
              :inactive-value="false"/>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          width="260"
          label="操作">
          <template slot-scope="scope">
            <el-button
              :disabled="isSelect"
              plain
              type="info"
              size="small"
              @click="info(scope.$index, scope.row)" icon="el-icon-info">详情</el-button>
            <el-button
              plain
              :disabled="isUpdate"
              type="primary"
              size="small"
              @click="edit(scope.$index, scope.row)" icon="el-icon-edit-outline">编辑</el-button>
            <el-button
              plain
              :disabled="isDelete"
              size="small"
              type="danger"
              @click="dalete(scope.$index, scope.row)" icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <MenuEdit ref="edit" :disabled="editDisabled" :dialogVisible="dialogVisible" @close="userEditClose" @initTableData="initTableData"></MenuEdit>
  </d2-container>
</template>

<script>
import api from '@/api'
import MenuEdit from '../edit/index'

export default {
  name: 'MenuList',
  components: {
    MenuEdit
  },
  data () {
    return {
      isSelect: this.checkauthority('menu_select'),
      isCreate: this.checkauthority('menu_add'),
      isUpdate: this.checkauthority('menu_edit'),
      isDelete: this.checkauthority('menu_delete'),
      menuTyes: [],
      selectForm: {
        title: ''
      },
      editDisabled: false,
      dialogVisible: false,
      pageNo: 1,
      pageSize: 50,
      tableSelects: [],
      tableData: [],
      pageTotal: 0,
      centerDialogVisible: false
    }
  },
  methods: {
    initEunmData () {
      this.getEnumList('menu_type')
    },
    async getEnumList (name) {
      var par = {
        name: name
      }
      var res = await api.GET_ENUMERATION_LIST(par)
      this.menuTyes = res.body
    },
    checkMenuType (value) {
      for (var i = 0; i < this.menuTyes.length; i++) {
        if (value + '' === this.menuTyes[i].keys) {
          return this.menuTyes[i].values
        }
      }
    },
    switchChange (value) {
      this.updateStats(value)
    },
    checkStatsType (data) {
      if (data.stats === 0) {
        return 'success'
      } else {
        return 'danger'
      }
    },
    checkStats (data) {
      if (data.stats === 0) {
        return '正常'
      } else {
        return '已注销'
      }
    },
    btnCheckStats (data) {
      if (data.stats === 0) {
        return '注销'
      } else {
        return '恢复'
      }
    },
    async updateStats (data) {
      var par = {
        menuId: data.menuId,
        hidden: data.hidden
      }
      await api.UPDATE_MENU(par)
      this.initTableData()
    },
    dalete (index, data) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.daleteApi(index, data)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async daleteApi (index, data) {
      var par = {
        menuId: data.menuId
      }
      await api.DELETE_MENU(par)
      this.initTableData()
    },
    async deleteBatchApi (par) {
      await api.DELETE_BATCH_MENU(par)
      this.$message.success('操作成功')
      this.initTableData()
    },
    deleteBatch () {
      if (this.tableSelects.length === 0) {
        this.$message.error('请勾选要删除的表格数据')
        return
      }
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var par = []
        this.tableSelects.forEach(element => {
          par.push(element.menuId)
        })
        this.deleteBatchApi(par)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    info (index, data) {
      this.editDisabled = true
      this.dialogVisible = true
      this.$refs.edit.setTitle('详情')
      this.$refs.edit.setData(data)
    },
    edit (index, data) {
      this.dialogVisible = true
      this.editDisabled = false
      this.$refs.edit.setTitle('编辑')
      this.$refs.edit.setData(data)
    },
    toAdd () {
      this.$refs.edit.setTitle('新增')
      this.dialogVisible = true
      this.editDisabled = false
      this.$refs.edit.initData()
    },
    userEditClose () {
      this.dialogVisible = false
    },
    reset () {
      this.selectForm.title = ''
      this.initTableData()
    },
    search () {
      this.initTableData()
    },
    async initTableData () {
      var par = {}
      const res = await api.GET_MENU_LIST(par)
      this.tableData = res.body
      this.pageTotal = res.body.sumDataCount
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.initTableData()
    },
    handleCurrentChange (val) {
      this.pageNo = val
      this.initTableData()
    },
    handleSelectionChange (val) {
      this.tableSelects = val
    }
  },
  created () {
    if (this.isSelect) {
      return
    }
    this.initTableData()
    this.initEunmData()
  }
}
</script>
<style>
  .editBtn{
    width: 60px;
  }
  .statsTag {
    width: 60px;
    text-align: center;
  }
 .demo .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  /*打开时文字位置设置*/
  .demo .el-switch__label--right {
    z-index: 1;
    right: -4px;
 }
 /*关闭时文字位置设置*/
 .demo .el-switch__label--left {
    z-index: 1;
    left: 18px;
 }
 /*显示文字*/
 .demo .el-switch__label.is-active {
   display: block;
 }
 .demo.el-switch .el-switch__core,
 .el-switch .el-switch__label {
   width: 50px !important;
 }
</style>
