export default ({ service, request, serviceForMock, requestForMock, mock, faker, tools }) => ({
  GET_GENERATE_CONFIG () {
    return request({
      url: '/generate/getConfig',
      method: 'get'
    })
  },
  SAVE_GENERATE_CONFIG (data = {}) {
    return request({
      url: '/generate/saveConfig',
      method: 'post'
    })
  },
  GET_TABLE_LIST (data = {}) {
    return request({
      url: '/generate/getTableList',
      method: 'get',
      params: data
    })
  },
  GET_TABLE_INFO (data = {}) {
    return request({
      url: '/generate/getTableInfo',
      method: 'get',
      params: data
    })
  },
  GENERATE (data = {}) {
    return request({
      url: '/generate/generate',
      method: 'post',
      data: data
    })
  }
})
