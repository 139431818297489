export default ({ request }) => ({
  // 分页查询
  loginLog_select (data = {}) {
    return request({
      url: '/v1/login/getPageList',
      method: 'post',
      data: data
    })
  }
})
