var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setGoodsList" },
    [
      _c(
        "el-drawer",
        {
          ref: "drawer",
          attrs: {
            "append-to-body": true,
            "before-close": _vm.handleClose,
            visible: _vm.drawerVisible,
            size: "70%",
            title: "请选择商品"
          },
          on: {
            "update:visible": function($event) {
              _vm.drawerVisible = $event
            }
          }
        },
        [
          _c("div", { staticStyle: { width: "100%" } }, [
            _c("div", { staticClass: "search" }, [
              _c(
                "div",
                { staticClass: "search_one" },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("仓库名称")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        filterable: "",
                        disabled: _vm.isAreaIdDisabled,
                        placeholder: "请选择仓库",
                        size: "small"
                      },
                      model: {
                        value: _vm.search.warehouseId,
                        callback: function($$v) {
                          _vm.$set(_vm.search, "warehouseId", $$v)
                        },
                        expression: "search.warehouseId"
                      }
                    },
                    _vm._l(_vm.warehousingWarehouse, function(item) {
                      return _c("el-option", {
                        key: item.warehouseId,
                        attrs: {
                          label: item.warehouseName,
                          value: item.warehouseId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "search_one" },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("商品编码")]),
                  _c("el-input", {
                    staticClass: "filter-item",
                    attrs: {
                      disabled: _vm.permission.isSearch,
                      clearable: "",
                      placeholder: "请输入商品编码",
                      size: "small"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.initList($event)
                      }
                    },
                    model: {
                      value: _vm.search.id,
                      callback: function($$v) {
                        _vm.$set(_vm.search, "id", $$v)
                      },
                      expression: "search.id"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "search_one" },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("商品名称")]),
                  _c("el-input", {
                    staticClass: "filter-item",
                    attrs: {
                      disabled: _vm.permission.isSearch,
                      clearable: "",
                      placeholder: "请输入商品",
                      size: "small"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.initList($event)
                      }
                    },
                    model: {
                      value: _vm.search.goodsName,
                      callback: function($$v) {
                        _vm.$set(_vm.search, "goodsName", $$v)
                      },
                      expression: "search.goodsName"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "search_one" },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("一级分类")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        disabled: _vm.permission.isSearch,
                        clearable: "",
                        "collapse-tags": "",
                        multiple: "",
                        placeholder: "请选择一级分类",
                        size: "small"
                      },
                      model: {
                        value: _vm.search.id1s,
                        callback: function($$v) {
                          _vm.$set(_vm.search, "id1s", $$v)
                        },
                        expression: "search.id1s"
                      }
                    },
                    _vm._l(_vm.oneClassData, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.classifyName,
                          value: item.id,
                          title: _vm.onHoverText
                        },
                        nativeOn: {
                          mouseenter: function($event) {
                            return _vm.onMouseOver(item.classifyName)
                          }
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "search_one" },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("商品品牌")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        disabled: _vm.permission.isSearch,
                        clearable: "",
                        "collapse-tags": "",
                        multiple: "",
                        placeholder: "请选择商品品牌",
                        size: "small"
                      },
                      model: {
                        value: _vm.search.brands,
                        callback: function($$v) {
                          _vm.$set(_vm.search, "brands", $$v)
                        },
                        expression: "search.brands"
                      }
                    },
                    _vm._l(_vm.goodsBrandData, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        staticStyle: { width: "200px" },
                        attrs: {
                          label: item.brandName,
                          value: item.id,
                          title: _vm.onHoverText
                        },
                        nativeOn: {
                          mouseenter: function($event) {
                            return _vm.onMouseOver(item.brandName)
                          }
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "search_one" },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("商品IP")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        disabled: _vm.permission.isSearch,
                        clearable: "",
                        "collapse-tags": "",
                        multiple: "",
                        placeholder: "请选择商品IP",
                        size: "small"
                      },
                      model: {
                        value: _vm.search.ips,
                        callback: function($$v) {
                          _vm.$set(_vm.search, "ips", $$v)
                        },
                        expression: "search.ips"
                      }
                    },
                    _vm._l(_vm.goodsIpData, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        staticStyle: { width: "200px" },
                        attrs: {
                          label: item.ipName,
                          value: item.id,
                          title: _vm.onHoverText
                        },
                        nativeOn: {
                          mouseenter: function($event) {
                            return _vm.onMouseOver(item.ipName)
                          }
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.permission.isSearch,
                        icon: "el-icon-search",
                        size: "small",
                        type: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.toQuery()
                        }
                      }
                    },
                    [_vm._v(" 搜索 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.permission.isReset,
                        icon: "el-icon-refresh",
                        size: "small",
                        type: "warning"
                      },
                      on: {
                        click: function($event) {
                          return _vm.reset()
                        }
                      }
                    },
                    [_vm._v(" 重置 ")]
                  )
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "10px",
                "margin-left": "10px",
                "margin-right": "10px"
              }
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingTotal,
                      expression: "loadingTotal"
                    }
                  ],
                  ref: "tableData",
                  attrs: {
                    "cell-style": { textAlign: "center" },
                    data: _vm.tableData,
                    "header-cell-style": {
                      background: "#dfe6ec",
                      color: "#666666",
                      textAlign: "center"
                    },
                    "row-style": { height: "57px" },
                    "element-loading-text": "数据加载中",
                    "max-height": "660",
                    "tooltip-effect": "dark"
                  },
                  on: { "selection-change": _vm.selectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "选择",
                      type: "selection",
                      width: "55"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "序号", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (_vm.search.pageNo - 1) *
                                    _vm.search.pageSize +
                                    scope.$index +
                                    1
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "商品编码",
                      prop: "id",
                      width: "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  width: "100%"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "white-space": "nowrap",
                                      width: "80%"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(scope.row.id) + " ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "copySty",
                                    on: {
                                      click: function($event) {
                                        return _vm.copyUrl(scope.row.id)
                                      }
                                    }
                                  },
                                  [_vm._v(" 复制 ")]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "商品原ID",
                      prop: "goodsId"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "商品名称",
                      prop: "goodsName",
                      width: "200"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", [
                              _vm._v(" " + _vm._s(scope.row.goodsName) + " ")
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "商品图片",
                      prop: "pictureUrl",
                      width: "100"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-image", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "margin-top": "8px",
                                cursor: "pointer"
                              },
                              attrs: {
                                "preview-src-list": [
                                  _vm.downloadUrl + "/" + scope.row.pictureUrl
                                ],
                                src:
                                  _vm.downloadUrl + "/" + scope.row.pictureUrl,
                                alt: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.getPreview(
                                    _vm.downloadUrl + "/" + scope.row.pictureUrl
                                  )
                                }
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "入库价",
                      prop: "price",
                      width: "140"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "在库数量",
                      prop: "sumAll",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: " 一级分类",
                      prop: "goodsOne",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.id1Name === null
                                        ? "- -"
                                        : scope.row.id1Name
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "商品品牌",
                      prop: "brandName",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.brandName
                                    ? scope.row.brandName
                                    : "- -"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "商品IP",
                      prop: "ipName",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.ipName ? scope.row.ipName : "- -"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "margin-top": "10px"
              }
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "submit",
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submit }
                },
                [_vm._v(" 确认 ")]
              ),
              this.search.pageSize > 0
                ? _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.search.pageNo,
                      "page-sizes": [10, 20, 30, 40, 50, 100],
                      "page-size": _vm.search.pageSize,
                      total: _vm.search.pageTotal,
                      layout: "total, jumper, prev, pager, next,sizes"
                    },
                    on: {
                      "size-change": _vm.sizeChange,
                      "current-change": _vm.currentChange
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }