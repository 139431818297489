// Vue
import Vue from 'vue'
import i18n from './i18n'
import App from './App'
import d2Admin from '@/plugin/d2admin'
import store from '@/store/index'
import global from './plugin/global/index'
import * as echarts from 'echarts'
import router from './router'
import iconPicker from 'e-icon-picker'
import 'e-icon-picker/dist/symbol.js'
import 'e-icon-picker/dist/index.css'
import 'font-awesome/css/font-awesome.min.css'
import 'element-ui/lib/theme-chalk/icon.css'
import Pagination from '@/components/Pagination/index'
import VuevideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
const XLSX = require('xlsx')

Vue.prototype.$echarts = echarts
Vue.prototype.$XLSX = XLSX
// event Bus 用于无关系组件间的通信。
Vue.prototype.$bus = new Vue()
Vue.use(global)
Vue.use(iconPicker, { FontAwesome: true, ElementUI: true, eIcon: true, eIconSymbol: true })
Vue.component('Pagination', Pagination)
// 核心插件
Vue.use(d2Admin)
Vue.use(VuevideoPlayer)

/**
  * 验证权限
  * @param {*} authority
  */
Vue.prototype.checkauthority = function (authority) {
  var authoritys = localStorage.getItem('authoritys')
  if (authoritys !== null) {
    authoritys = authoritys.split(',')
    for (var index in authoritys) {
      var data = authoritys[index]
      if (data === authority) {
        return false
      }
    }
  }
  return true
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created () {
    this.$store.dispatch('d2admin/account/refresh')
    // // 处理路由 得到每一级的路由设置
    // this.$store.commit('d2admin/page/init', frameInRoutes)
    // //设置顶栏菜单
    // this.$store.commit('d2admin/menu/headerSet', menuHeader)
    // 设置侧边栏菜单
    // this.$store.commit('d2admin/menu/asideSet', menuAside)
    // //初始化菜单搜索功能
    // this.$store.commit('d2admin/search/init', menuHeader)
  },
  mounted () {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow')
    // 用户登录后从数据库加载一系列的设置
    this.$store.dispatch('d2admin/account/load')
    // 获取并记录用户 UA
    this.$store.commit('d2admin/ua/get')
    // 初始化全屏监听
    this.$store.dispatch('d2admin/fullscreen/listen')
  }
}).$mount('#app')
