/**
 * <枚举管理请求接口
 * @author 王振宇
 * @since 2021-03-02
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_ENUMERATION_PAGE_LIST: function GET_ENUMERATION_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/enumeration/getPageList',
        method: 'post',
        params: data
      });
    },
    GET_ENUMERATION_LIST: function GET_ENUMERATION_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/enumeration/getList',
        method: 'post',
        data: data
      });
    },
    GET_ENUMERATION_INFO: function GET_ENUMERATION_INFO() {
      return request({
        url: '/v1/enumeration/info',
        method: 'get'
      });
    },
    SAVE_ENUMERATION: function SAVE_ENUMERATION() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/enumeration/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_ENUMERATION: function UPDATE_ENUMERATION() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/enumeration/update',
        method: 'put',
        data: data
      });
    },
    DELETE_ENUMERATION: function DELETE_ENUMERATION() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/enumeration/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_ENUMERATION: function DELETE_BATCH_ENUMERATION() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/enumeration/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});