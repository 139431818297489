import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.search";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var searchDefault = {
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0,
  outNo: ''
};
export default {
  name: 'salesReturns',
  data: function data() {
    return {
      search: JSON.parse(JSON.stringify(searchDefault))
    };
  },
  mounted: function mounted() {},
  methods: {
    initList: function initList() {},
    // 搜索
    toQuery: function toQuery() {
      this.search.pageNo = 1;
      this.initList();
    },
    // 重置
    reset: function reset() {
      this.search = JSON.parse(JSON.stringify(searchDefault));
      this.search.nextToken = null;
      this.search.pageSize = 50;
      this.search.pageNo = 1;
      this.initList();
    }
  }
};