<template>
    <el-dropdown size="small" class="d2-mr">
        <!-- <a href='https://docs.qq.com/pdf/DWWppRUxHZ3lPbkd3?'>
            <i class="el-icon-question"></i>
            <span class="btn-text">新手帮助</span>
        </a> -->
        <el-dropdown-menu>
            
        </el-dropdown-menu>
        <div @click="upNew()">
                <i class="el-icon-question"></i>
                <span class="btn-text">新手帮助</span>
            </div>
    </el-dropdown>
</template>
  
<script>
import { mapState, mapActions } from 'vuex'
import { useRoute, useRouter } from "vue-router";
export default {
    computed: {

    },
    methods: {
        upNew() {
            window.open('https://docs.qq.com/doc/DWXFOd3BSV2pIQll5')
        }
    }
}
</script>
  