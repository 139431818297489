export default ({ request }) => ({
  // 分页查询
  getLimit_ip (data = {}) {
    return request({
      url: '/v1/ip/getLimit',
      method: 'post',
      data: data
    })
  },
  // 新增
  insertRow_ip (data = {}) {
    return request({
      url: '/v1/ip/insertRow',
      method: 'post',
      data: data
    })
  },
  // 编辑
  updateRow_ip (data = {}) {
    return request({
      url: '/v1/ip/updateRow',
      method: 'put',
      data: data
    })
  },
  // 删除
  deleteRow_ip (data = {}) {
    return request({
      url: '/v1/ip/deleteRow',
      method: 'delete',
      data: data
    })
  }
})
