<template>
  <el-dialog
  :append-to-body="true"
    top="3%"
    title="生成配置"
    :visible.sync="dialogVisible"
    width="90%"
    :close-on-click-modal="false"
    :before-close="handleClose">
    <el-form :inline="true" :model="initform" class="demo-form-inline">
      <el-form-item label="根包名">
        <el-input clearable size="small" v-model="initform.rootPkg" placeholder="com.unbox.admin"></el-input>
      </el-form-item>
      <el-form-item label="FeginService名">
        <el-input clearable size="small"  v-model="initform.feignServiceName" placeholder="admin-service"></el-input>
      </el-form-item>
      <el-form-item label="GateWay访问根目录">
        <el-input clearable size="small"  v-model="initform.gateWayRoot" placeholder="admin"></el-input>
      </el-form-item>
      <el-form-item label="去前缀">
        <el-input clearable size="small"  v-model="initform.prefix" placeholder="unbox_"></el-input>
      </el-form-item>
      <el-form-item label="接口版本">
        <el-input clearable size="small"  v-model="initform.apiVersion" placeholder="v1"></el-input>
      </el-form-item>
      <el-form-item label="创建权限值">
        <el-input clearable size="small"  v-model="initform.isCreateStr" placeholder="xxxx_create"></el-input>
      </el-form-item>
      <el-form-item label="编辑权限值">
        <el-input clearable size="small"  v-model="initform.isUpdateStr" placeholder="xxxx_update"></el-input>
      </el-form-item>
      <el-form-item label="删除权限值">
        <el-input clearable size="small"  v-model="initform.isDeleteStr" placeholder="xxxx_delete"></el-input>
      </el-form-item>
      <el-form-item label="查询权限值">
        <el-input clearable size="small"  v-model="initform.isSelectStr" placeholder="xxxx_select"></el-input>
      </el-form-item>
      <el-form-item>
          <el-button size="small" @click="generate(1)" type="primary">生成后端</el-button>
          <el-button size="small" @click="generate(2)" type="primary">生成前端</el-button>
      </el-form-item>
    </el-form>
    <el-tabs type="border-card">
      <el-tab-pane label="后端生成">
        <el-table
          :data="tableData"
          style="width: 100%">
          <el-table-column
            prop="columnName"
            label="列名"
            width="180">
          </el-table-column>
          <el-table-column
            prop="dataType"
            label="类型"
            width="180">
          </el-table-column>
          <el-table-column
            prop="columnComment"
            label="注释">
          </el-table-column>
          <el-table-column
            prop="columnKey"
            label="PRI">
          </el-table-column>
          <el-table-column
            label="查询类型">
            <template slot-scope="scope">
              <el-select v-model="scope.row.queryType" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            label="查询排序">
            <template slot-scope="scope">
              <el-select v-model="scope.row.sortType" placeholder="请选择">
                <el-option
                  v-for="item in sortOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="前端生成">
        <el-table
          :data="tableData"
          style="width: 100%">
          <el-table-column
            prop="columnName"
            label="列名"
            width="180">
          </el-table-column>
          <el-table-column
            prop="dataType"
            label="类型"
            width="180">
          </el-table-column>
          <el-table-column
            prop="columnComment"
            label="注释">
          </el-table-column>
          <el-table-column
            prop="columnKey"
            label="PRI">
          </el-table-column>
          <el-table-column
            width="100"
            align="center"
            label="搜索页">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.webSelectFlug"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            label="显示控件">
            <template slot-scope="scope">
              <el-select v-model="scope.row.webSelectControl" placeholder="请选择">
                <el-option
                  v-for="item in selectControlEnums"
                  :key="item.keys"
                  :label="item.values"
                  :value="item.keys">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            align="center"
            label="Table">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.webTableListFlug"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            label="显示控件">
            <template slot-scope="scope">
              <el-select v-model="scope.row.webTableControl" placeholder="请选择">
                <el-option
                  v-for="item in tableControlEnums"
                  :key="item.keys"
                  :label="item.values"
                  :value="item.keys">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            align="center"
            label="编辑页">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.webEditFlug"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            label="显示控件">
            <template slot-scope="scope">
              <el-select v-model="scope.row.webEditControl" placeholder="请选择">
                <el-option
                  v-for="item in editControlEnums"
                  :key="item.keys"
                  :label="item.values"
                  :value="item.keys">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            label="显示排序">
            <template slot-scope="scope">
              <el-input v-model="scope.row.webSortTableDisPlayNum"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import api from '@/api'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  name: 'Generate',
  components: {},
  data () {
    return {
      selectControlEnums: [],
      editControlEnums: [],
      tableControlEnums: [],
      initform: {
        rootPkg: 'com.unbox.admin',
        feignServiceName: 'admin-service',
        prefix: 'unbox_',
        tableName: '',
        tableComment: '',
        visitRoot: 'user',
        gateWayRoot: 'admin',
        apiVersion: 'v1'
      },
      data: {},
      tableData: [],
      options: [{
        value: 'eq',
        label: 'eq'
      }, {
        value: 'like',
        label: 'like'
      }, {
        value: '',
        label: '忽略'
      }],
      sortOptions: [{
        value: 'asc',
        label: 'asc'
      }, {
        value: 'desc',
        label: 'desc'
      }, {
        value: '',
        label: '忽略'
      }]
    }
  },
  methods: {
    async initSelectControlEnumsApi () {
      var res = await api.GET_ENUMERATION_LIST({ name: 'gen_select_control' })
      this.selectControlEnums = res.body
    },
    async initEditControlEnumsApi () {
      var res = await api.GET_ENUMERATION_LIST({ name: 'gen_edit_control' })
      this.editControlEnums = res.body
    },
    async initTableControlEnumsApi () {
      var res = await api.GET_ENUMERATION_LIST({ name: 'gen_table_control' })
      this.tableControlEnums = res.body
    },
    initTableData (data) {
      this.data = data
      this.initform.tableName = data.tableName
      this.initform.tableComment = data.tableComment
      this.getData()
    },
    async getData () {
      var data = await api.GET_TABLE_INFO(this.data)
      data.body.forEach(element => {
        element.queryType = 'eq'
        element.sortType = ''
        element.webSelectFlug = false
        element.webTableListFlug = false
        element.webEditFlug = false
        element.webSortTableDisPlayNum = 0
      })
      this.tableData = data.body
    },
    handleClose () {
      this.$emit('close')
    },
    async generate (type) {
      localStorage.setItem('tableConfig', JSON.stringify(this.initform))
      this.initform.generateInfos = this.tableData
      this.initform.type = type
      await api.GENERATE(this.initform)
      window.open(process.env.VUE_APP_API + 'generate/downloadZip')
    }
  },
  created () {
    var tableConfig = localStorage.getItem('tableConfig')
    if (tableConfig !== null) {
      this.initform = JSON.parse(tableConfig)
    }
    this.initEditControlEnumsApi()
    this.initSelectControlEnumsApi()
    this.initTableControlEnumsApi()
  }
}
</script>
<style>
</style>
