<template>
  <div class="good">
    <div class="title">
      <span>商品信息</span>
      <el-button
        v-if="info.status != 4 && permission.isStop === false"
        size="mini"
        type="danger"
        :disabled="stopOutLoading"
        :loading="stopOutLoading"
        @click="stopOut"
      >
        终止出库
      </el-button>
    </div>
    <div class="table">
      <el-table
        ref="tableData"
        :cell-style="{ textAlign: 'center' }"
        :data="goodsList"
        :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center'
        }"
        :row-class-name="rowClassName"
        :row-style="{ height: '57px' }"
        element-loading-text="数据加载中"
        max-height="620px"
        tooltip-effect="dark"
      >
        <!-- 序号 -->
        <el-table-column label="序号">
          <template slot-scope="scope">
            {{ (page.pageNo - 1) * page.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="商品编码" prop="goodsId"></el-table-column>
        <el-table-column label="商品名称" prop="goodsName"></el-table-column>
        <el-table-column label="商品图片">
          <template slot-scope="scope">
            <el-image
              :preview-src-list="[$oss + scope.row.goodsPictureUrl]"
              :src="$oss + scope.row.goodsPictureUrl"
              fit="cover"
              style="width: 50px; height: 50px"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column v-if="infoStatus != 0 && infoStatus != 1 && infoStatus != 4 " label="是否扫码" prop="scanCode">
          <template slot-scope="scope">
            <div v-if="infoStatus == 2">
              <el-tag v-if="scope.row.scanCode == true" type="success">是</el-tag>
              <el-tag v-else-if="scope.row.scanCode == false" type="warning">否</el-tag>
              <span v-else-if="scope.row.scanCode == null"></span>
            </div>
            <div v-if="infoStatus== 3">
              <!--              <el-tag-->
              <!--                :type="scope.row.scanCode == true ? 'success' : scope.row.scanCode == false ? 'warning' : '' ">-->
              <!--                {{ scope.row.scanCode == true ? '是' : scope.row.scanCode == false ? '否' : '- -' }}-->
              <!--              </el-tag>-->
              <el-tag v-if="scope.row.scanCode == true" type="success">是</el-tag>
              <el-tag v-else-if="scope.row.scanCode == false" type="warning">否</el-tag>
              <span v-if="scope.row.scanCode == null"></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="入库价" prop="recyclePrice"></el-table-column>
        <el-table-column
          key="出库价"
          label="出库价"
          prop="outPrice"
          width="200"
        >
          <template slot-scope="scope">
            <el-input-number
              v-if="info.status != 0 && info.status != 4"
              v-model="scope.row.outPrice"
              :disabled="!isEdit"
              :min="0"
              :precision="2"
              size="small"
              type="number"
            ></el-input-number>
            <span v-else>{{ scope.row.outPrice }}</span>
          </template>
        </el-table-column>
        <template v-if="!isStock">
          <el-table-column
            :key="'数量'"
            label="数量"
            prop="num"
          ></el-table-column>
          <el-table-column label="出库总计" prop="total"></el-table-column>
        </template>
        <template v-if="isStock">
          <el-table-column label="条码" prop="barCode" width="180"></el-table-column>
          <el-table-column label="区域名称" prop="areaName"></el-table-column>
          <el-table-column
            label="货架名称"
            prop="shelvesName"
          >
           <template slot-scope="scope">
              {{
                scope.row.shelvesName ? scope.row.shelvesName : '- -'
              }}
            </template>
        </el-table-column>
          <el-table-column label="扫码人" prop="scanName"></el-table-column>
          <el-table-column label="扫码时间" prop="scanTime" width="180"></el-table-column>
        </template>
        <el-table-column
          v-if="info.status != 0 && info.status != 4 && info.distribution !== 4 "
          :key="'操作'"
          label="操作"
          width="220"
        >
          <template slot-scope="scope">
            <el-button
              icon="el-icon-delete"
              size="mini"
              type="danger"
              @click="deleteGoods(scope.row)"
            ></el-button>
            <el-button
              v-if="!isEdit"
              icon="el-icon-edit"
              size="mini"
              type="primary"
              @click="editGoods(scope.row)"
            ></el-button>
            <el-button
              v-if="isEdit"
              size="mini"
              type="info"
              @click="cancelEdit(scope.$index, scope.row)"
            >
              取消
            </el-button>
            <el-button
              v-if="isEdit"
              size="mini"
              type="primary"
              @click="keepEdit(scope.row)"
            >
              保存
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page.pageNo"
        :page-size="page.pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :total="pageTotal"
        layout="->,total, sizes, prev, pager, next, jumper"
        @size-change="sizeChange"
        @current-change="currentChange"
      ></el-pagination>
    </div>
    <el-dialog :close-on-click-modal="false" title="退货" :visible.sync="tuihuoLog" top="8vh" width="400px"
            @close="logClose">
            <el-form ref="InfoForm" style="display:flex;flex-wrap: wrap;" label-width="100px">
              <el-form-item label="退货备注">
                <el-input type="textarea" v-model="Ttextarea"></el-input>
              </el-form-item>
            </el-form>
            <div style="max-width:200px;margin:0 auto;margin-top: 10px;">
                <el-button size="small" @click="logClose()">取消</el-button>
                <el-button size="small" type="primary" @click="logSaveBtn()" :disabled="tuihuoBtn">确定</el-button>
            </div>
        </el-dialog>
  </div>
</template>
<script>
import api from '@/api'

export default {
  data () {
    return {
      tuihuoBtn:false,
      row:{},
      type:'',
      tuihuoLog:false,
      Ttextarea:'',
      // 权限点
      permission: {
        isStop: this.checkauthority('outbound_end') // 终止出库
      },
      oldGoodsInfo: {},
      infoStatus: '',
      stopOutLoading: false,
      goodsList: [],
      page: {
        pageNo: 1,
        pageSize: 50
      },
      pageTotal: 0,
      isStock: false,
      isEdit: false
    }
  },
  props: {
    goodsInfo: {
      type: Object
    },
    info: {
      type: Object
    }
  },
  watch: {
    goodsInfo: {
      handler (val) {
        this.oldGoodsInfo = val.datas
        if (val.outGoodsList) {
          this.goodsList = val.outGoodsList
          this.pageTotal = val.outGoodsList.length
        } else {
          this.goodsList = val.datas
          this.pageTotal = val.sumDataCount
        }
        this.goodsListHandler()
      },
      deep: true
    },
    info: {
      handler (val) {
        this.getGoods()
        const status = [1, 2, 3]
        if (status.includes(val.status)) {
          this.isStock = true
        } else {
          this.isStock = false
        }
        if (val.distribution === 4) {
          this.isStock = false
        }
      },
      deep: true
    }
  },
  mounted () {
    // 全局事件总线，获取兄弟组件传递过来的索引值
    this.$bus.$on('fun', async e => {
      this.infoStatus = e.status
      console.log(this.infoStatus)
    })
  },
  methods: {
    async logSaveBtn(){
      this.tuihuoBtn = true
      if(this.type == '删除'){
        const { code } = await api.outDetail_updateGoods({
          outDetailId: this.row.outDetailId,
          outGoodsId: this.row.outGoodsId,
          outId: this.row.outId,
          reason:this.Ttextarea,
        })
        if (code === 200) {
          this.$message.success('删除成功')
          this.$emit('refresh')
          this.logClose()
        }
      } else if (this.type == '终止出库') {
        const { code } = await api.outbound_stopOut({ id: this.info.id,reason:this.Ttextarea, })
        if (code === 200) {
          this.$message.success('终止出库成功')
          this.$emit('refresh')
          this.logClose()
        }
      }
      this.tuihuoBtn = false
    },
    logClose() {
      this.tuihuoLog = false
      this.row = {}
      this.type = ''
      this.Ttextarea = ''
    },
    rowClassName ({
      row,
      rowIndex
    }) {
      if (row.freightDamage) {
        if (row.freightDamage === 1) {
          return 'freightDamage'
        }
      }
    },
    // 判断状态和类型
    StateTypeCount (
      target = {
        Status: [],
        Type: []
      }
    ) {
      const res =
        target.Status.reduce((pre, cur) => {
          return pre || cur
        }) &&
        target.Type.reduce((pre, cur) => {
          return pre || cur
        })
      return res
    },
    getGoods () {
      this.$emit('getGoods', this.page)
    },
    deleteGoods (row) {
      if(this.info.status==3||this.info.status==2){
        this.tuihuoLog = true
        this.row = row
        this.type = '删除'
        return
      }
      this.$confirm('是否删除该商品？', '删除商品').then(async () => {
        const { code } = await api.outDetail_updateGoods({
          outDetailId: row.outDetailId,
          outGoodsId: row.outGoodsId,
          outId: row.outId
        })
        if (code === 200) {
          this.$message.success('删除商品成功')
          this.$emit('refresh')
        }
      })
    },
    editGoods (row) {
      this.isEdit = true
    },
    cancelEdit (index, row) {
      this.goodsList[index] = JSON.parse(
        JSON.stringify(this.oldGoodsInfo[index])
      )
      this.goodsListHandler()
      this.$forceUpdate()
      this.isEdit = false
    },
    keepEdit (row) {
      this.$confirm('是否保存该商品？', '保存商品').then(async () => {
        const { code } = await api.outDetail_updateGoods({
          outDetailId: row.outDetailId,
          outGoodsId: row.outGoodsId,
          outId: row.outId,
          outPrice: row.outPrice
        })
        if (code === 200) {
          this.$message.success('保存商品成功')
          this.$emit('refresh')
          this.isEdit = false
        }
      })
    },
    stopOut () { 
      if(this.info.status==3||this.info.status==2){
        this.tuihuoLog = true
        // this.row = row
        this.type = '终止出库'
        return
      }
      this.$confirm('是否终止出库？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          try {
            this.stopOutLoading = true
            const { code } = await api.outbound_stopOut({ id: this.info.id })
            if (code === 200) {
              this.$message.success('终止出库成功')
              this.$emit('refresh')
            }
          } catch (error) {
            this.$message.error('终止出库失败')
          } finally {
            this.stopOutLoading = false
          }
        })
    },
    sizeChange (val) {
      this.page.pageSize = val
      this.getGoods()
    },
    currentChange (val) {
      this.page.pageNo = val
      console.log(val)
      this.getGoods()
    },
    goodsListHandler () {
      this.goodsList = this.goodsList.map(e => {
        return {
          ...e,
          goodsId: e.goodsId || e.goodsCoding,
          goodsPictureUrl: e.goodsPictureUrl || e.pictureUrl,
          recyclePrice: e.recyclePrice || e.unitPrice || 0,
          scanName: e.scanName || e.createdName,
          scanTime: e.scanTime || e.createdTimestamp
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #b8b8b81e;
  height: 30px;
  font-weight: bold;
}

::v-deep .freightDamage {
  background: #ff0000;
  color: #fff;

  &:hover {
    color: #606266;
  }
}
</style>
