// 出库
export default ({ request }) => ({
  // 查询出库单列表
  outbound_getPageList (data = {}) {
    return request({
      url: '/v1/outbound/getPageList',
      method: 'post',
      data: data
    })
  }, // 新增出库单
  outbound_save (data = {}) {
    return request({
      url: '/v1/outbound/save',
      method: 'post',
      data: data
    })
  },
  // 编辑出库单
  outbound_edit (data = {}) {
    return request({
      url: '/v1/outbound/update',
      method: 'put',
      data: data
    })
  },
  outbound_info (data = {}) {
    return request({
      url: '/v1/outbound/info',
      method: 'post',
      data: data
    })
  },
  // 当入库状态不是待出库的时候的明细查询
  outbound_detail (data = {}) {
    return request({
      url: '/v1/outbound/detail',
      method: 'post',
      data: data
    })
  },
  // 获取枚举类型下拉列表
  selectedEnums_getList (data = {}) {
    return request({
      url: '/v1/selectedEnums/getList',
      method: 'post',
      data: data
    })
  },
  inventory_selectPrice (data = {}) {
    return request({
      url: '/v1/inventory/selectPrice',
      method: 'post',
      data: data
    })
  },
  outbound_outLog (data = {}) {
    return request({
      url: '/v1/outbound/outLog',
      method: 'post',
      data: data
    })
  },
  outbound_stopOut (data = {}) {
    return request({
      url: '/v1/outbound/stopOut',
      method: 'post',
      data: data
    })
  },
  outDetail_updateGoods (data = {}) {
    return request({
      url: '/v1/outDetail/updateGoods',
      method: 'post',
      data: data
    })
  },
  outDetail_copyOutBound (data = {}) {
    return request({
      url: '/v1/outbound/copyOutBound',
      method: 'post',
      data: data
    })
  },
  //获取准备出库的商品列表
  //outId
  //queryType: "YJ"
  getReadyList (data) {
    return request({
      url: '/v1/outbound/outBoundPage',
      method: 'post',
      data
    })
  },
  rowOutGoods (data) {
    return request({
      url: '/app/out/confirm',
      method: 'post',
      data
    })
  },
  /**
   * 出库
   * */
  outGoods (data) {
    return request({
      url: '/app/out/noScanCode',
      method: 'post',
      data
    })
  }
})
