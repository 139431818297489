//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 豆腐块
import recordCard from './recordCard/index.vue'; // 条形统计图

import columnarStatisticalChart from './columnarStatisticalChart/index.vue'; // 折线统计图

import brokenLineGraph from './brokenLineGraph/index.vue'; // 饼形统计图

import pieStatistics from './pieStatistics/index.vue'; // 商品卡

import commodityCard from './commodityCard/index.vue'; // 待处理采购订单

import purchaseOrders from './pendingOrders/purchaseOrders/index.vue'; // 待处理调拨订单

import allotOrders from './pendingOrders/allotOrders/index.vue'; // 待处理预定订单

import reserveOrders from './pendingOrders/reserveOrders/index.vue'; // 待处理异常订单

import abnormalOrders from './pendingOrders/abnormalOrders/index.vue'; // 待处理入库订单

import waitIntoOrders from './pendingOrders/waitIntoOrders/index.vue'; // 待处理预定订单

import waitOutOrders from './pendingOrders/waitOutOrders/index.vue'; // 商品到期提醒

import productExpiration from './productExpiration/index.vue';
export default {
  components: {
    recordCard: recordCard,
    columnarStatisticalChart: columnarStatisticalChart,
    brokenLineGraph: brokenLineGraph,
    pieStatistics: pieStatistics,
    commodityCard: commodityCard,
    purchaseOrders: purchaseOrders,
    allotOrders: allotOrders,
    reserveOrders: reserveOrders,
    abnormalOrders: abnormalOrders,
    waitIntoOrders: waitIntoOrders,
    waitOutOrders: waitOutOrders,
    productExpiration: productExpiration
  },
  data: function data() {
    return {};
  }
};