var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basic" },
    [
      _vm._m(0),
      _c(
        "head-search",
        {
          attrs: { "item-style": _vm.itemStyle, "label-style": _vm.labelStyle }
        },
        [
          _vm.info.fatherId
            ? _c(
                "head-search-item",
                { attrs: { label: "主订单号:" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { padding: "0", "font-size": "16px" },
                      attrs: { type: "text" },
                      on: { click: _vm.toDetail }
                    },
                    [_vm._v(" " + _vm._s(_vm.info.fatherId) + " ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("head-search-item", { attrs: { label: "出库单号:" } }, [
            _vm._v(" " + _vm._s(_vm.info.outId) + " ")
          ]),
          (_vm.info.type === 3 && _vm.info.status === 0) ||
          ((_vm.info.status === 2 || _vm.info.status === 3) &&
            _vm.info.type === 1)
            ? _c("head-search-item", { attrs: { label: "系统订单号:" } }, [
                _vm._v(" " + _vm._s(_vm.info.orderId) + " ")
              ])
            : _vm._e(),
          _c("head-search-item", { attrs: { label: "出库类型:" } }, [
            _vm._v(" " + _vm._s(_vm.listMap.outType.get(_vm.info.type)) + " ")
          ]),
          _c("head-search-item", { attrs: { label: "出库状态:" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.info.status === 0
                    ? "待出库"
                    : _vm.info.status === 1
                    ? "出库中"
                    : _vm.info.status === 2
                    ? "部分出库"
                    : _vm.info.status === 3
                    ? "全部出库"
                    : _vm.info.status === 4
                    ? "终止出库"
                    : ""
                ) +
                " "
            )
          ]),
          _c("head-search-item", { attrs: { label: "订单来源:" } }, [
            _vm._v(
              " " + _vm._s(_vm.listMap.outSource.get(_vm.info.source)) + " "
            )
          ]),
          _c("head-search-item", { attrs: { label: "配送方式:" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.listMap.distributionType.get(_vm.info.distribution)
                ) +
                " "
            )
          ]),
          _c("head-search-item", { attrs: { label: "出库数量:" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.goodsInfo.num ? _vm.goodsInfo.num : _vm.logInfo.outNum
                ) +
                " "
            )
          ]),
          _vm.info.status === 3
            ? _c("head-search-item", { attrs: { label: "出库时间:" } }, [
                _vm._v(" " + _vm._s(_vm.info.outboundTime) + " ")
              ])
            : _vm._e(),
          !(
            (_vm.info.status === 2 || _vm.info.status === 3) &&
            _vm.info.type === 1
          )
            ? _c("head-search-item", { attrs: { label: "入库价总额:" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.goodsInfo.recyclePrice
                        ? _vm.goodsInfo.recyclePrice
                        : _vm.logInfo.recyclePrice
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          _c("head-search-item", { attrs: { label: "出库价总额:" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.goodsInfo.price ? _vm.goodsInfo.price : _vm.logInfo.price
                ) +
                " "
            )
          ]),
          !(
            (_vm.info.status === 2 || _vm.info.status === 3) &&
            _vm.info.type === 1
          )
            ? _c("head-search-item", { attrs: { label: "仓库名称:" } }, [
                _vm._v(" " + _vm._s(_vm.info.areaName) + " ")
              ])
            : _vm._e(),
          _c("head-search-item", { attrs: { label: "制单人员:" } }, [
            _vm._v(" " + _vm._s(_vm.info.createdName) + " ")
          ]),
          _c("head-search-item", { attrs: { label: "创建时间:" } }, [
            _vm._v(" " + _vm._s(_vm.info.createdTimestamp) + " ")
          ])
        ],
        1
      ),
      !((_vm.info.status === 2 || _vm.info.status === 3) && _vm.info.type === 1)
        ? _c("div", { staticClass: "remark" }, [
            _c("span", { staticClass: "label" }, [_vm._v("备注：")]),
            _c("span", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.info.remark))
            ])
          ])
        : _vm._e(),
      _vm._m(1),
      _c(
        "head-search",
        {
          attrs: { "item-style": _vm.itemStyle, "label-style": _vm.labelStyle }
        },
        [
          _c("head-search-item", { attrs: { label: "收货姓名:" } }, [
            _vm._v(" " + _vm._s(_vm.info.chargeName) + " ")
          ]),
          _c("head-search-item", { attrs: { label: "收货电话:" } }, [
            _vm._v(" " + _vm._s(_vm.info.chargePhone) + " ")
          ]),
          _c("head-search-item", { attrs: { label: "物流公司:" } }, [
            _vm._v(" " + _vm._s(_vm.info.courierCompany) + " ")
          ]),
          _c("head-search-item", { attrs: { label: "物流单号:" } }, [
            _vm._v(" " + _vm._s(_vm.info.courierOrderId) + " ")
          ])
        ],
        1
      ),
      _c("div", { staticClass: "remark" }, [
        _c("span", { staticClass: "label" }, [_vm._v("收货地址：")]),
        _c("span", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.info.chargeAddress))
        ])
      ]),
      _c(
        "el-dialog",
        {
          staticStyle: { margin: "20px auto" },
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            title: "图片放大",
            visible: _vm.dialogFormVisiblePic,
            width: "540px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisiblePic = $event
            },
            close: function($event) {
              return _vm.closeDialogPic()
            }
          }
        },
        [
          this.dialogImageUrl !== ""
            ? _c("img", {
                staticStyle: {
                  width: "500px",
                  height: "500px",
                  "text-align": "center"
                },
                attrs: { src: this.dialogImageUrl, alt: "" }
              })
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("基本信息")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("用户信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }