<template>
  <div>
    <div class="dashboard-editor-container">
      <div class="whSty">
        <div>
          <div class="tabSty">
            <div class="singSty">
              <div class="verticalBar"></div>
              <span class="textSty">订单商品</span>
            </div>
            <div>
              <el-date-picker
                v-if="this.search.type === '日'"
                v-model="search.time"
                align="right"
                type="date"
                size="small"
                placeholder="选择日期"
                :clearable="false"
                :picker-options="pickerOptionsDate"
              >
              </el-date-picker>
              <el-date-picker
                v-if="this.search.type === '月'"
                size="small"
                v-model="search.time"
                type="month"
                placeholder="选择月"
                :clearable="false"
                :picker-options="pickerOptionsMonth"
              >
              </el-date-picker>
              <el-date-picker
                v-if="this.search.type === '年'"
                size="small"
                v-model="search.time"
                type="year"
                placeholder="选择年"
                :clearable="false"
                :picker-options="pickerOptionsYear"
              >
              </el-date-picker>
              <el-radio-group
                v-model="search.type"
                size="small"
                style="margin-left: 20px;"
              >
                <el-radio-button label="日"></el-radio-button>
                <el-radio-button label="月"></el-radio-button>
                <el-radio-button label="年"></el-radio-button>
              </el-radio-group>
              <el-button
                type="success"
                plain
                size="small"
                style="margin-left: 20px;"
                @click="getSearch()"
                >搜索</el-button
              >
            </div>
          </div>
        </div>
        <!-- 创建折线图 -->
        <div v-loading="loadingEcharts" style="height:83%;margin-left: 10px;">
          <div ref="lineChart" style="width:100%;height:95%;"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api'
const searchDefault = {
  time: new Date(), // 获取日期与时间
  type: '日'
}
const cardDefault = {
  purchaseNum: [], // 采购商品
  issuingNum: [], // 代发商品
  allotNum: [], // 调拨商品
  intoNum: [], // 入库商品
  outNum: [] // 出库商品
}
export default {
  name: 'brokenLineGraph',
  data () {
    return {
      search: JSON.parse(JSON.stringify(searchDefault)),
      cardData: JSON.parse(JSON.stringify(cardDefault)),
      loadingEcharts: false,
      dateData: [], // 日期数组
      pickerOptionsDate: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      pickerOptionsMonth: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      pickerOptionsYear: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  mounted () {
    this.initList()
  },
  methods: {
    async initList () {
      console.log(this.search.time)
      const myDate = new Date(this.search.time)
      var Y = myDate.getFullYear() // 获取当前完整年份
      var M = myDate.getMonth() + 1 // 获取当前月份
      var D = myDate.getDate() // 获取当前日1-31
      var H = myDate.getHours() // 获取当前小时
      var i = myDate.getMinutes() // 获取当前分钟
      var s = myDate.getSeconds() // 获取当前秒数
      // 月份不足10补0
      if (M < 10) {
        M = '0' + M
      }
      // 日不足10补0
      if (D < 10) {
        D = '0' + D
      }
      // 小时不足10补0
      if (H < 10) {
        H = '0' + H
      }
      // 分钟不足10补0
      if (i < 10) {
        i = '0' + i
      }
      // 秒数不足10补0
      if (s < 10) {
        s = '0' + s
      }
      // 拼接日期分隔符根据自己的需要来修改
      var changeDate = Y + '-' + M + '-' + D
      var changeMonth = Y + '-' + M
      var changeYear = Y
      if (this.search.type === '日') {
        this.search.time = changeDate.toString()
      } else if (this.search.type === '月') {
        this.search.time = changeMonth.toString()
      } else if (this.search.type === '年') {
        this.search.time = changeYear.toString()
      }
      this.$echarts.init(this.$refs.lineChart).dispose()
      this.cardData = JSON.parse(JSON.stringify(cardDefault))
      this.dateData = []
      this.loadingEcharts = true
      setTimeout(() => {
        this.initChart()
        this.loadingEcharts = false
      }, 1000)
      const res = await api.getLineStatistics({
        time: this.search.time,
        type:
          this.search.type === '日'
            ? 'day'
            : this.search.type === '月'
              ? 'month'
              : this.search.type === '年'
                ? 'year'
                : ''
      })
      if (res.code === 200) {
        this.dateData = res.body.map(v => v.time) // 日期转换
        this.cardData.purchaseNum = res.body.map(v => v.purchaseNum) // 数据转换
        this.cardData.outNum = res.body.map(v => v.outNum)// 数据转换
        this.cardData.intoNum = res.body.map(v => v.intoNum) // 数据转换
        this.cardData.allotNum = res.body.map(v => v.allotNum)// 数据转换
        this.cardData.issuingNum = res.body.map(v => v.issuingNum)// 数据转换
        this.loadingEcharts = false
        this.initChart()
      } else {
        this.loadingEcharts = false
        this.$message.error('折线图加载失败')
      }
    },
    initChart () {
      const myChart = this.$echarts.init(this.$refs.lineChart)
      const option = {
        legend: {
          data: ['采购商品', '代发商品', '调拨商品', '入库商品', '出库商品'],
          selected: {
            采购商品: true,
            代发商品: true,
            调拨商品: true,
            入库商品: true,
            出库商品: true
          }
        },
        xAxis: {
          type: 'category',
          data: this.dateData,
          name: '日期', // x轴名称
          nameTextStyle: {
            fontWeight: 600,
            fontSize: 12,
            color: '#000'
          }
        },
        yAxis: {
          type: 'value',
          name: '数量',
          nameTextStyle: {
            fontWeight: 600,
            fontSize: 12,
            color: '#000'
          }
        },
        grid: {
          left: '5%',
          bottom: '2%',
          width: '85%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          textStyle: {
            align: 'left'
          }
        },
        series: [
          {
            name: '采购商品',
            data: this.cardData.purchaseNum,
            type: 'line'
          },
          {
            name: '代发商品',
            data: this.cardData.consignmentGoods,
            type: 'line'
          },
          {
            name: '调拨商品',
            data: this.cardData.allotNum,
            type: 'line'
          },
          {
            name: '入库商品',
            data: this.cardData.intoNum,
            type: 'line'
          },
          {
            name: '出库商品',
            data: this.cardData.outNum,
            type: 'line'
          }
        ]
      }
      myChart.setOption(option)
    },
    getSearch () {
      console.log(this.search.time)
      this.initList()
    }
  }
}
</script>
<style scoped>
.dashboard-editor-container {
  margin-top: 20px;
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #ffffff;
  padding: 0px 10px 0px 0px;
  box-shadow: 0px 0px 4px #999999;
  border-radius: 5px;
}
.whSty {
  width: 100%;
  height: 450px;
}
.tabSty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}
.singSty {
  display: flex;
  height: 20px;
  padding-left: 10px;
}
.verticalBar {
  width: 10px;
  line-height: 20px;
  border-radius: 25%;
  background-color: #8ddece;
  margin-right: 5px;
}
.textSty {
  line-height: 20px;
  font-weight: bold;
}
</style>
