export default (function (_ref) {
  var request = _ref.request;
  return {
    // 分页查询list
    getPageList_deliveryStatisticalList: function getPageList_deliveryStatisticalList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/statistics/dailyOut',
        method: 'post',
        data: data
      });
    },
    // 聚合统计
    getPageList_deliveryStatisticalSum: function getPageList_deliveryStatisticalSum() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/statistics/dailyOutSum',
        method: 'post',
        data: data
      });
    },
    // 出库商品
    infoDeliveryStatisticalGoods: function infoDeliveryStatisticalGoods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/out/statical/goodsVo',
        method: 'post',
        data: data
      });
    },
    // 出库商品聚合统计
    infoDeliveryStatisticalGoodsSum: function infoDeliveryStatisticalGoodsSum() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/out/statical/goodsVoSum',
        method: 'post',
        data: data
      });
    },
    // 出库订单
    infoDeliveryStatisticalOrder: function infoDeliveryStatisticalOrder() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/out/statical/orderVo',
        method: 'post',
        data: data
      });
    },
    // 出库订单聚合统计
    infoDeliveryStatisticalOrderSum: function infoDeliveryStatisticalOrderSum() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/out/statical/orderVoSum',
        method: 'post',
        data: data
      });
    },
    // 出库商品维度导出
    exportOutGoods: function exportOutGoods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/out/statical/exportOutGoods',
        method: 'post',
        data: data
      });
    },
    // 出库订单维度导出
    exportOutOrder: function exportOutOrder() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/out/statical/exportOutOrder',
        method: 'post',
        data: data
      });
    }
  };
});