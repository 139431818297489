export default (function (_ref) {
  var request = _ref.request;
  return {
    // 获取采购商品列表
    getPurchaseSummaryList: function getPurchaseSummaryList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/purchase/statical/getList',
        method: 'post',
        data: data
      });
    },
    // 获取采购商品聚合统计
    getPurchaseSummaryResult: function getPurchaseSummaryResult() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/purchase/statical/getAggregateStaticalResult',
        method: 'post',
        data: data
      });
    },
    // 获取采购单明细
    getPurchaseSummaryGoodsList: function getPurchaseSummaryGoodsList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/purchase/statical/getGoodsList',
        method: 'post',
        data: data
      });
    },
    // 获取采购单明细聚合统计
    getPurchaseSummaryGoodsResult: function getPurchaseSummaryGoodsResult() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/purchase/statical/getPurNewGoodsResult',
        method: 'post',
        data: data
      });
    }
  };
});