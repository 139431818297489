export default ({ request }) => ({
  // 分页查询
  getPageList_brand (data = {}) {
    return request({
      url: '/v1/brand/getPageList',
      method: 'post',
      data: data
    })
  },
  // 新增
  insertRow_brand (data = {}) {
    return request({
      url: '/v1/brand/insertRow',
      method: 'post',
      data: data
    })
  },
  // 编辑
  updateRow_brand (data = {}) {
    return request({
      url: '/v1/brand/updateRow',
      method: 'put',
      data: data
    })
  },
  // 删除
  deleteRow_brand (data = {}) {
    return request({
      url: '/v1/brand/deleteRow',
      method: 'delete',
      data: data
    })
  }
})
