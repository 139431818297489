var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "head-search",
        { attrs: { "label-style": "width:100px" } },
        [
          _c(
            "head-search-item",
            { attrs: { label: "采购单号" } },
            [
              _c("el-input", {
                staticClass: "searchInput",
                staticStyle: { width: "200px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "请输入采购单号",
                  clearable: "",
                  size: "small"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.initList($event)
                  }
                },
                model: {
                  value: _vm.search.purchaseNo,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "purchaseNo", $$v)
                  },
                  expression: "search.purchaseNo"
                }
              })
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "商品名称" } },
            [
              _c("el-input", {
                staticClass: "searchInput",
                staticStyle: { width: "200px" },
                attrs: {
                  placeholder: "请输入商品名称",
                  clearable: "",
                  size: "small"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.initList($event)
                  }
                },
                model: {
                  value: _vm.search.goodsName,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "goodsName", $$v)
                  },
                  expression: "search.goodsName"
                }
              })
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "采购类型" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "searchInput",
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    placeholder: "请选择采购类型",
                    size: "small",
                    clearable: ""
                  },
                  model: {
                    value: _vm.search.purchaseTypes,
                    callback: function($$v) {
                      _vm.$set(_vm.search, "purchaseTypes", $$v)
                    },
                    expression: "search.purchaseTypes"
                  }
                },
                _vm._l(_vm.purchaseTypesData, function(item) {
                  return _c("el-option", {
                    key: item.keys,
                    attrs: {
                      label: item.values,
                      value: item.keys,
                      title: _vm.onHoverText
                    },
                    nativeOn: {
                      mouseenter: function($event) {
                        return _vm.onMouseOver(item.values)
                      }
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "采购发起人" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "searchInput",
                  attrs: {
                    placeholder: "请选择采购发起人",
                    size: "small",
                    multiple: "",
                    "collapse-tags": "",
                    clearable: ""
                  },
                  model: {
                    value: _vm.search.createdIds,
                    callback: function($$v) {
                      _vm.$set(_vm.search, "createdIds", $$v)
                    },
                    expression: "search.createdIds"
                  }
                },
                _vm._l(_vm.createdIdsData, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label: item.userName,
                      value: item.id,
                      title: _vm.onHoverText
                    },
                    nativeOn: {
                      mouseenter: function($event) {
                        return _vm.onMouseOver(item.value)
                      }
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "渠道" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "searchInput",
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    placeholder: "请选择渠道",
                    size: "small",
                    clearable: ""
                  },
                  model: {
                    value: _vm.search.channels,
                    callback: function($$v) {
                      _vm.$set(_vm.search, "channels", $$v)
                    },
                    expression: "search.channels"
                  }
                },
                _vm._l(_vm.channelData, function(item) {
                  return _c("el-option", {
                    key: item.keys,
                    attrs: {
                      label: item.values,
                      value: item.keys,
                      title: _vm.onHoverText
                    },
                    nativeOn: {
                      mouseenter: function($event) {
                        return _vm.onMouseOver(item.values)
                      }
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "发货方式" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "searchInput",
                  attrs: {
                    placeholder: "请选择发货方式",
                    size: "small",
                    multiple: "",
                    "collapse-tags": "",
                    clearable: ""
                  },
                  model: {
                    value: _vm.search.deliveryMethods,
                    callback: function($$v) {
                      _vm.$set(_vm.search, "deliveryMethods", $$v)
                    },
                    expression: "search.deliveryMethods"
                  }
                },
                _vm._l(_vm.deliveryMethodsData, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label: item.value,
                      value: item.id,
                      title: _vm.onHoverText
                    },
                    nativeOn: {
                      mouseenter: function($event) {
                        return _vm.onMouseOver(item.value)
                      }
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "状态" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "searchInput",
                  attrs: {
                    placeholder: "请选择状态",
                    size: "small",
                    multiple: "",
                    "collapse-tags": "",
                    clearable: ""
                  },
                  model: {
                    value: _vm.search.status,
                    callback: function($$v) {
                      _vm.$set(_vm.search, "status", $$v)
                    },
                    expression: "search.status"
                  }
                },
                _vm._l(_vm.statusData, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label: item.value,
                      value: item.id,
                      title: _vm.onHoverText
                    },
                    nativeOn: {
                      mouseenter: function($event) {
                        return _vm.onMouseOver(item.value)
                      }
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "采购日期" } },
            [
              _c("el-date-picker", {
                staticClass: "searchTimer",
                attrs: {
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                  align: "center",
                  size: "small",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "picker-options": _vm.pickerOptions
                },
                model: {
                  value: _vm.search.createTimes,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "createTimes", $$v)
                  },
                  expression: "search.createTimes"
                }
              })
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "金额区间" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  max: _vm.search.rightTotalMoney,
                  min: 0,
                  clearable: "",
                  size: "small"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.search($event)
                  }
                },
                model: {
                  value: _vm.search.leftTotalMoney,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "leftTotalMoney", $$v)
                  },
                  expression: "search.leftTotalMoney"
                }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                "line-height": "30px",
                "margin-left": "10px",
                "margin-right": "10px"
              }
            },
            [_vm._v("~")]
          ),
          _c(
            "head-search-item",
            [
              _c("el-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  min: _vm.search.leftTotalMoney,
                  clearable: "",
                  size: "small"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.search($event)
                  }
                },
                model: {
                  value: _vm.search.rightTotalMoney,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "rightTotalMoney", $$v)
                  },
                  expression: "search.rightTotalMoney"
                }
              })
            ],
            1
          ),
          _c(
            "template",
            { slot: "button" },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    "margin-right": "10px",
                    "margin-left": "25px"
                  },
                  attrs: {
                    disabled: _vm.permission.isShow,
                    icon: "el-icon-search",
                    type: "primary",
                    size: "small"
                  },
                  on: {
                    click: function($event) {
                      return _vm.searchTotal()
                    }
                  }
                },
                [_vm._v(" 搜索 ")]
              ),
              !_vm.permission.isShow
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        icon: "el-icon-refresh",
                        type: "warning",
                        size: "small"
                      },
                      on: {
                        click: function($event) {
                          return _vm.reset()
                        }
                      }
                    },
                    [_vm._v(" 重置 ")]
                  )
                : _vm._e(),
              !_vm.permission.isAdd
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        type: "success",
                        size: "small"
                      },
                      on: {
                        click: function($event) {
                          return _vm.add()
                        }
                      }
                    },
                    [_vm._v(" 新增 ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      ),
      _c("div", { staticStyle: { "margin-top": "10px", display: "flex" } }, [
        _c(
          "i",
          {
            staticClass: "el-icon-warning",
            staticStyle: {
              color: "#f60",
              "line-height": "22px",
              "margin-right": "10px"
            }
          },
          [_vm._v("TIPS:")]
        ),
        _c(
          "div",
          { staticStyle: { display: "flex", "margin-right": "10px" } },
          [
            _c("span", [_vm._v("异常：")]),
            _c("div", {
              staticStyle: {
                width: "20px",
                height: "20px",
                background: "#f8c662"
              }
            })
          ]
        ),
        _c(
          "div",
          { staticStyle: { display: "flex", "margin-right": "10px" } },
          [
            _c("span", [_vm._v("审核拒绝：")]),
            _c("div", {
              staticStyle: {
                width: "20px",
                height: "20px",
                background: "#65CCFF"
              }
            })
          ]
        )
      ]),
      _c(
        "div",
        {
          staticStyle: {
            "margin-top": "10px",
            "margin-left": "10px",
            "margin-right": "10px"
          }
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingTotal,
                  expression: "loadingTotal"
                }
              ],
              ref: "tableData",
              attrs: {
                data: _vm.tableData,
                "max-height": "618px",
                "element-loading-text": "数据加载中",
                "cell-style": { textAlign: "center" },
                "row-style": { height: "57px", textAlign: "center" },
                "row-class-name": _vm.specialLabel,
                "header-cell-style": {
                  background: "#dfe6ec",
                  color: "#666666",
                  textAlign: "center"
                }
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", width: "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              (_vm.page.pageNo - 1) * _vm.page.pageSize +
                                scope.$index +
                                1
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "采购单号",
                  prop: "purchaseNo",
                  align: "center",
                  width: "180"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center",
                              width: "100%"
                            }
                          },
                          [
                            _c("div", [
                              _vm._v(" " + _vm._s(scope.row.purchaseNo) + " ")
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "copySty",
                                staticStyle: { "margin-left": "10px" },
                                on: {
                                  click: function($event) {
                                    return _vm.copyUrl(scope.row.purchaseNo)
                                  }
                                }
                              },
                              [_vm._v(" 复制 ")]
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "采购类型",
                  prop: "purchaseType",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品数量",
                  prop: "productNum",
                  align: "center",
                  width: "120"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "采购金额",
                  prop: "totalMoney",
                  align: "center",
                  width: "120"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.totalMoney === null
                                  ? "- -"
                                  : scope.row.totalMoney
                              ) +
                              " "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "渠道",
                  prop: "channel",
                  width: "100",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.channel === ""
                                  ? "- -"
                                  : scope.row.channel
                              ) +
                              " "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "发货方式",
                  prop: "deliveryMethod",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.deliveryMethod === "0"
                                  ? "入库"
                                  : scope.row.deliveryMethod === "1"
                                  ? "代发"
                                  : "- -"
                              ) +
                              " "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "采购发起人",
                  prop: "createdName",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  prop: "status",
                  width: "100",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.status === 0
                                  ? "待采购"
                                  : scope.row.status === 1
                                  ? "采购中"
                                  : scope.row.status === 2
                                  ? "已完成"
                                  : scope.row.status === 3
                                  ? "待终止"
                                  : scope.row.status === 9
                                  ? "已终止"
                                  : "- -"
                              ) +
                              " "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": true,
                  label: "备注信息",
                  prop: "remark",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.remark ? scope.row.remark : "- -"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  prop: "createdTimestamp",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        !_vm.permission.isInfo
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  plain: "",
                                  type: "info",
                                  size: "small"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.returnInfo(scope.row)
                                  }
                                }
                              },
                              [_vm._v("详情")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "right",
            "margin-top": "10px"
          }
        },
        [
          this.page.pageSize > 0
            ? _c("el-pagination", {
                attrs: {
                  "current-page": _vm.page.pageNo,
                  "page-sizes": [10, 20, 30, 40, 50, 100],
                  "page-size": _vm.page.pageSize,
                  layout: "total, jumper, prev, pager, next,sizes",
                  total: _vm.page.sumDataCount
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }