export default (function (_ref) {
  var request = _ref.request;
  return {
    // 分页查询
    getPageList_damagefreightType: function getPageList_damagefreightType() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return request({
        url: '/v1/enumeration/getPageList',
        method: 'post',
        params: params,
        data: query
      });
    },
    // 新增
    save_damagefreightType: function save_damagefreightType() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/enumeration/save',
        method: 'post',
        data: data
      });
    },
    // 编辑
    update_damagefreightType: function update_damagefreightType() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/enumeration/update',
        method: 'put',
        data: data
      });
    },
    // 删除
    delete_damagefreightType: function delete_damagefreightType() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/enumeration/delete',
        method: 'delete',
        data: data
      });
    }
  };
});