<template>
  <d2-container>
    <div class="header" v-loading="loadingData">
      <div
        class="searchBgc3"
        style="margin-bottom: 10px;display: flex;justify-content: space-between;"
      >
        基本信息
        <el-button
          v-if="status === 0 && permission.isUpdate === false"
          icon="el-icon-circle-plus-outline"
          size="small"
          style="margin-right: 10px;"
          type="primary"
          @click="redactForm()"
        >
          编辑
        </el-button>
      </div>
      <el-form
        ref="redactData"
        :model="redactData"
        :rules="rules"
        class="ruleSty"
        label-width="100px"
      >
        <div
          :style="
            redactData.purchaseType === tag3
              ? 'display:flex;justify-content: left;'
              : ''
          "
          class="div2"
        >
          <div class="div3">
            <div class="div1">
              <el-form-item label="采购单号" prop="purchaseNo">
                <div style="  color: #1482f0;">
                  <el-tag>{{ this.purchaseNo }}</el-tag>
                </div>
              </el-form-item>
              <el-form-item label="创建日期">
                <div style="color: #606266">
                  <el-tag type="warning">{{ this.createdTimestamp }}</el-tag>
                </div>
              </el-form-item>
              <el-form-item label="采购状态" prop="status">
                <div style="color: #606266">
                  <el-tag type="success">
                    {{
                      this.status === 0
                        ? '待采购'
                        : this.status === 1
                          ? '采购中'
                          : this.status === 2
                            ? '已完成'
                            : this.status === 3
                              ? '待终止'
                            : this.status === 9
                              ? '已终止'
                              : '- -'
                    }}
                  </el-tag>
                </div>
              </el-form-item>
              <el-form-item
                v-if="this.redactData.purchaseType === '调拨'"
                label="配送方式"
                prop="distribution"
              >
                <el-tag type="danger">{{
                    this.redactData.distribution === 0
                      ? '自配送'
                      : this.redactData.distribution === 1
                        ? '门店自取'
                        : this.redactData.distribution === 2
                          ? '物流'
                          : ''
                  }}
                </el-tag>
              </el-form-item>
            </div>
            <div>
              <div class="div1">
                <el-form-item label="采购类型" prop="purchaseType">
                  <el-select
                    v-model="redactData.purchaseType"
                    :disabled="true"
                    clearable
                    placeholder="请选择采购类型"
                    size="small"
                    @change="changePurchaseType"
                  >
                    <el-option
                      v-for="item in purchaseTypesData"
                      :key="item.keys"
                      :label="item.values"
                      :value="item.keys"
                      v-bind:title="onHoverText"
                      @mouseenter.native="onMouseOver(item.values)"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="this.redactData.purchaseType !== this.tag3"
                  label="渠道"
                  prop="channel"
                >
                  <el-select
                    v-model="redactData.channel"
                    :disabled="true"
                    clearable
                    placeholder="请选择渠道"
                    size="small"
                  >
                    <el-option
                      v-for="item in channelsData"
                      :key="item.keys"
                      :label="item.values"
                      :value="item.keys"
                      v-bind:title="onHoverText"
                      @mouseenter.native="onMouseOver(item.values)"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="this.redactData.purchaseType !== this.tag3"
                  label="付款方式"
                  prop="payMethod"
                >
                  <el-select
                    v-model="redactData.payMethod"
                    :disabled="true"
                    clearable
                    placeholder="请选择付款方式"
                    size="small"
                  >
                    <el-option
                      v-for="(item, index) in payMethodsData"
                      :key="index"
                      :label="item.value"
                      :value="item.id"
                      v-bind:title="onHoverText"
                      @mouseenter.native="onMouseOver(item.values)"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="div1">
                <el-form-item
                  v-if="this.redactData.purchaseType === this.tag2"
                  label="预定日期"
                  prop="predictArriveDate"
                >
                  <el-date-picker
                    v-model="redactData.predictArriveDate"
                    :disabled="showRedactFlag"
                    :picker-options="pickerOptions"
                    placeholder="选择日期"
                    size="small"
                    style="width:215px"
                    type="date"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item
                  v-if="this.redactData.purchaseType !== this.tag3"
                  label="发货方式"
                  prop="deliveryMethod"
                >
                  <el-select
                    v-model="redactData.deliveryMethod"
                    :disabled="true"
                    clearable
                    placeholder="请选择发货方式"
                    size="small"
                  >
                    <el-option
                      v-for="(item, index) in deliveryMethodsData"
                      :key="index"
                      :label="item.value"
                      :value="item.id"
                      v-bind:title="onHoverText"
                      @mouseenter.native="onMouseOver(item.value)"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="returnSty()"
                  label="收货人"
                  prop="deliveryUserName"
                >
                  <el-input
                    v-model="redactData.deliveryUserName"
                    :disabled="showRedactFlag"
                    clearable
                    placeholder="请输入收货人"
                    size="small"
                    style="width:215px"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  v-if="returnSty()"
                  label="联系方式"
                  prop="deliveryPhone"
                >
                  <el-input
                    v-model.trim="redactData.deliveryPhone"
                    :disabled="showRedactFlag"
                    clearable
                    maxlength="11"
                    placeholder="请输入收货人联系方式"
                    size="small"
                    style="width:215px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="入库仓库" prop="warehouseId">
                  <el-select
                    v-model="redactData.warehouseId"
                    :disabled="true"
                    clearable
                    placeholder="请选择入库仓库"
                    size="small"
                    @change="getWarehouseAddress(item.warehouseAddress)"
                  >
                    <el-option
                      v-for="(item, index) in warehouseIdsData"
                      :key="index"
                      :label="item.warehouseName"
                      :value="item.warehouseId"
                      v-bind:title="onHoverText"
                      @mouseenter.native="onMouseOver(item.value)"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="this.redactData.purchaseType === this.tag3"
                  label="来源仓库"
                  prop="sourceWarehouseId"
                >
                  <el-select
                    v-model="redactData.sourceWarehouseId"
                    :disabled="true"
                    clearable
                    placeholder="请选择入库仓库"
                    size="small"
                  >
                    <el-option
                      v-for="(item, index) in sourceWarehouseIdsData"
                      :key="index"
                      :label="item.warehouseName"
                      :value="item.warehouseId"
                      v-bind:title="onHoverText"
                      @mouseenter.native="onMouseOver(item.value)"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="this.redactData.purchaseType === this.tag3"
                  label="仓库位置"
                  prop="sameWarehouseFlag"
                >
                  <el-select
                    v-model="redactData.sameWarehouseFlag"
                    :disabled="true"
                    clearable
                    placeholder="请选择仓库位置"
                    size="small"
                  >
                    <el-option
                      v-for="(item, index) in sameWarehouseFlagData"
                      :key="index"
                      :label="item.value"
                      :value="item.id"
                      v-bind:title="onHoverText"
                      @mouseenter.native="onMouseOver(item.value)"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            this.redactData.purchaseType !== '调拨' ||
              this.redactData.distribution === 2
          "
          class="searchBgc2"
          style="margin-bottom: 10px;display: flex;justify-content: space-between;"
        >
          物流信息
        </div>
        <div class="div1">
          <el-form-item
            v-if="
              this.redactData.purchaseType !== '调拨' ||
                this.redactData.distribution === 2
            "
            label="物流公司"
          >
            <el-input
              v-model="redactData.courierCompany"
              :disabled="this.status === 9 || this.status === 2"
              clearable
              placeholder="请输入物流公司"
              size="small"
              style="width:215px"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="
              this.redactData.purchaseType !== '调拨' ||
                this.redactData.distribution === 2
            "
            label="物流单号"
          >
            <el-input
              v-model="redactData.trackingNumber"
              :disabled="this.status === 9 || this.status === 2"
              clearable
              placeholder="请输入物流单号"
              size="small"
              style="width:215px"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="
              this.redactData.purchaseType !== '调拨' ||
                this.redactData.distribution === 2
            "
            label="收货地址"
          >
<!--            prop="deliveryAddress"-->
            <el-input
              v-model="redactData.deliveryAddress"
              :disabled="this.status === 9 || this.status === 2"
              clearable
              placeholder="请输入收货地址"
              size="small"
              style="width:530px"
            ></el-input>
            <el-button
              v-if="this.status !== 9 && this.redactData.trackingNumber !== ''"
              size="small"
              style="margin-left: 20px"
              type="info"
              :disabled="LogisticsLoading"
              :loading="LogisticsLoading"
              @click="openLogistics()"
            >
              查看物流
            </el-button>
          </el-form-item>
        </div>
        <div
          v-if="this.redactData.purchaseType !== this.tag3"
          class="searchBgc2"
          style="margin-bottom: 10px;display: flex;justify-content: space-between;"
        >
          交易截图
        </div>
        <div v-if="this.redactData.purchaseType !== this.tag3" class="div4">
          <div>
            <el-form-item label="付款截图">
              <erp-upload-img
                v-model="redactData.payProve"
                :maxCount="10"
                :showDelete="true"
                :upload-path="'/purchasingManagement/purchasing/'"
              ></erp-upload-img>
              <!-- <erp-upload-img
                v-if="this.status !== 0"
                v-model="redactData.payProve"
                :maxCount="redactData.payProve.length"
                :upload-path="'/purchasingManagement/purchasing/'"
              ></erp-upload-img> -->
              <el-button type="success" size="small" @click="saveImg(1)">保存图片</el-button>
            </el-form-item>
          </div>
          <div>
            <el-form-item
              v-if="this.redactData.purchaseType === this.tag2 && this.redactData.payProve.length > 0"
              label="尾款截图"
            >
              <erp-upload-img
                v-model="redactData.finalPaymentProve"
                :maxCount="10"
                :showDelete="true"
                :upload-path="'/purchasingManagement/purchasing/'"
              ></erp-upload-img>
              <el-button type="success" size="small" @click="saveImg(2)">保存图片</el-button>
              <!-- <erp-upload-img
                v-if="this.status !== 0"
                v-model="redactData.finalPaymentProve"
                :maxCount="redactData.finalPaymentProve.length"
                :showDelete="true"
                :upload-path="'/purchasingManagement/purchasing/'"
              ></erp-upload-img> -->
              <!-- <div v-if="this.status === 9 || this.status === 2">
                <img
                  v-for="(item, index) in redactData.finalPaymentProve"
                  :key="index"
                  style=" width: 150px; height: 150px; text-align: center;cursor: pointer;"
                  alt=""
                  :src="downloadUrl + '/' + item.url"
                  @click="getPreview(downloadUrl + '/' + item.url)"
                />
              </div> -->
            </el-form-item>
          </div>
        </div>
        <div
          class="searchBgc2"
          style="margin-bottom: 10px;display: flex;justify-content: space-between;"
        >
          商品信息
          <el-button
            v-if="!showRedactFlag"
            icon="el-icon-circle-plus-outline"
            size="small"
            style="margin-right: 10px;"
            type="success"
            @click="addItems()"
          >
            添加
          </el-button>
        </div>
        <el-form-item class="itemAddSty" prop="itemAddData">
          <el-table
            ref="itemAddData"
            :cell-style="{ textAlign: 'center' }"
            :data="redactData.itemAddData"
            :header-cell-style="{
              background: '#dfe6ec',
              color: '#666666',
              textAlign: 'center'
            }"
            :row-style="{ height: '77px' }"
            element-loading-text="数据加载中"
            max-height="690px"
            style="box-sizing: border-box;"
            tooltip-effect="dark"
          >
            <el-table-column
              align="center"
              label="商品编码"
              prop="goodsCoding"
              width="120"
            >
              <template slot-scope="scope">
                {{ scope.row.goodsCoding }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="商品名称"
              prop="goodsName"
              width="260"
            >
              <template slot-scope="scope">
                <div
                  style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;"
                >
                  {{ scope.row.goodsName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="商品图片"
              prop="pictureUrl"
              width="80"
            >
              <template slot-scope="scope">
                <img
                  v-if="
                    scope.row.pictureUrl !== '' && scope.row.pictureUrl !== null
                  "
                  :src="downloadUrl + '/' + scope.row.pictureUrl"
                  alt=""
                  style="width: 50px; height: 50px;margin-top: 8px;cursor: pointer"
                  @click="getPreview(downloadUrl + '/' + scope.row.pictureUrl)"
                />
                <div
                  v-if="
                    scope.row.pictureUrl === '' || scope.row.pictureUrl === null
                  "
                  style="width: 50px;height: 50px;border: 1px solid #c0c0c0;display: flex;justify-content: center;margin: 0 auto;"
                ></div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="分类"
              prop="goodsOne"
              width="120"
            >
              <template slot-scope="scope">
                <div
                  style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;"
                >
                  {{ scope.row.goodsOne === null ? '- -' : scope.row.goodsOne }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="品牌"
              prop="goodsBrandName"
              width="120"
            >
              <template slot-scope="scope">
                <div
                  style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;"
                >
                  {{
                    scope.row.goodsBrandName === null
                      ? '- -'
                      : scope.row.goodsBrandName
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="商品IP"
              prop="goodsIpName"
              width="120"
            >
              <template slot-scope="scope">
                <div
                  style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;"
                >
                  {{ scope.row.goodsIpName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="我的入库价(元)"
              prop="warehousePriceRange"
              width="120"
            >
              <template slot-scope="scope">
                <div
                  style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
                >
                  {{
                    scope.row.warehousePriceRange === null ||
                    scope.row.warehousePriceRange === undefined
                      ? '- -'
                      : scope.row.warehousePriceRange
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="我的库存数量"
              prop="inventoryNum"
              width="120"
            >
              <template slot-scope="scope">
                <div
                  style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
                >
                  {{
                    scope.row.inventoryNum === null ||
                    scope.row.inventoryNum === undefined
                      ? '- -'
                      : scope.row.inventoryNum
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="我的在途数量"
              prop="inTransitNum"
              width="120"
            >
              <template slot-scope="scope">
                <div
                  style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
                >
                  {{
                    scope.row.inTransitNum === null ||
                    scope.row.inTransitNum === undefined
                      ? '- -'
                      : scope.row.inTransitNum
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="采购数量" width="150">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'itemAddData.' + scope.$index + '.num'"
                  :rules="rules.num"
                >
                  <el-input-number
                    v-model.number="scope.row.num"
                    :disabled="showRedactFlag"
                    :min="1"
                    :precision="0"
                    :step="1"
                    size="small"
                  ></el-input-number>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              v-if="
                (this.confirmAllocate === true &&
                  this.redactData.purchaseType === this.tag3) ||
                  this.redactData.purchaseType !== this.tag3 ||
                  (this.status === 1 &&
                    this.redactData.purchaseType === this.tag3) ||
                  (this.status === 2 &&
                    this.redactData.purchaseType === this.tag3)
              "
              align="center"
              label="采购单价"
              prop="unitPrice"
              width="150"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'itemAddData.' + scope.$index + '.unitPrice'"
                  :rules="rules.unitPrice"
                >
                  <el-input-number
                    v-model="scope.row.unitPrice"
                    :disabled="showRedactFlag"
                    :min="0"
                    :precision="2"
                    :step="1"
                    size="small"
                  ></el-input-number>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              v-if="status === 0"
              align="center"
              label="操作"
              width="100"
            >
              <template slot-scope="scope">
                <el-button
                  :disabled="showRedactFlag"
                  icon="el-icon-delete"
                  plain
                  size="small"
                  type="danger"
                  @click.native.prevent="
                    remove(scope.$index, scope.row, redactData.itemAddData)
                  "
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <div v-if="redactData.itemAddData.length > 0" class="textSty">
          <div>
            <span>合计数量：</span>
            <span class="spanSty" style="margin-right:10px">
              {{ isNaN(count.totalNum) ? 0 : count.totalNum }}
            </span>
          </div>
          <div
            v-if="
              (this.confirmAllocate === true &&
                this.redactData.purchaseType === this.tag3) ||
                this.redactData.purchaseType !== this.tag3 ||
                (this.status === 1 &&
                  this.redactData.purchaseType === this.tag3) ||
                (this.status === 2 &&
                  this.redactData.purchaseType === this.tag3)
            "
          >
            <span>合计金额：</span>
            <span class="spanSty">
              {{
                isNaN(count.totalAmount)
                  ? 0
                  : count.totalAmount / 100 > 0
                    ? (count.totalAmount / 100).toFixed(2)
                    : 0
              }}
            </span>
            &nbsp;元
          </div>
        </div>
        <div
          v-if="this.intoOrderInfo !== null"
          class="searchBgc2"
          style="margin-bottom: 10px;display: flex;justify-content: space-between;"
        >
          入库明细
          <el-button
            icon="el-icon-circle-plus-outline"
            size="small"
            style="margin-right: 10px;"
            type="warning"
            @click="toDispose()"
            v-if="permission.isDispose === false"
          >
            处理
          </el-button>
        </div>
        <div
          v-if="this.intoOrderInfo !== null"
          style="margin-left: 20px;margin-bottom:10px;display: flex;justify-content: space-around;"
        >
          <div>
            <span></span>
            采购数量:
            <span style="margin-left:20px;color: rgb(102, 102, 102);">
              {{ this.intoOrderInfo.totalNum }}
            </span>
          </div>
          <div>
            记录数量:
            <span style="margin-left:20px;color: rgb(102, 102, 102);">
              {{ this.intoOrderInfo.sweepCodeRecordNum }}
            </span>
          </div>
          <div>
            完好:
            <span style="margin-left:20px;color: rgb(102, 102, 102);">
              {{ this.intoOrderInfo.intactNum }}
            </span>
          </div>
          <div>
            未记录:
            <span style="margin-left:20px;color: red;">
              {{ this.intoOrderInfo.withoutRecordNum }}
            </span>
          </div>
          <!-- <div>
            货损:
            <span style="margin-left:20px;color: red;">
              {{ this.intoOrderInfo.cargoDamageNum }}
            </span>
          </div> -->
        </div>

        <div class="searchBgc2" style="margin-bottom: 20px;">备注信息</div>
        <el-form-item label="备注信息">
          <el-input
            v-model="redactData.remark"
            :autosize="{ minRows: 5, maxRows: 10 }"
            :disabled="
              this.status === 9 || this.status === 2
            "
            maxlength="240"
            placeholder="请输入备注信息"
            show-word-limit
            style="width: 99%"
            type="textarea"
          ></el-input>
        </el-form-item>

        <div v-if="this.status === 9 || this.status === 3" class="searchBgc2" style="margin-bottom: 20px;">终止相关</div>
        <div v-if="this.status === 9 || this.status === 3">
        <el-form-item label="终止图片">
            <erp-upload-img
              v-if="( this.status === 9 || this.status === 3 ) && redactData.backProve.length > 0"
              v-model="redactData.backProve"
              :maxCount="redactData.backProve.length"
              :upload-path="'/purchasingManagement/purchasing/backProve'"
            ></erp-upload-img>
            <div v-if="( this.status === 9 || this.status === 3 ) && redactData.backProve.length === 0">- -</div>
          </el-form-item>
        <el-form-item label="终止原因">
            {{ redactData.refundReason ? redactData.refundReason : '- -' }}
          </el-form-item>
        </div>

        <div class="searchBgc2" style="margin-bottom: 20px;">
          操作日志
        </div>
        <div>
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in logList"
              :key="index"
              :timestamp="item.createdTimestamp"
              style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;"
            >
              {{
                item.createdName +
                item.operateDesc +
                '     ' +
                remark +
                (item.remark === null ? '' : item.remark)
              }}
            </el-timeline-item>
          </el-timeline>
        </div>
        <div class="searchBgc2" style="margin-bottom: 20px;"></div>
        <div style="display: flex;justify-content: center;margin-bottom: 20px;">
          <el-button size="small" @click="resetForm('redactData')">
            关闭
          </el-button>
          <el-button
            v-if="this.status !==  2 && this.status !== 9 && permission.isUpdate === false "
            :type="this.status !==  2 && this.status !== 9  && this.redactData.auditStatus !== 2 ? 'success' : this.status !==  2 && this.status !== 9  && this.redactData.auditStatus === 2 ? 'primary' : ''"
            size="small"
            :disabled="submitLoading"
            :loading="submitLoading"
            @click.native.prevent="submitForm()"
          >
            {{
              this.status !==  2 && this.status !== 9 ? '保存' : this.status !==  2 && this.status !== 9 && this.redactData.auditStatus != 1 ? '重新保存' : ''
            }}
          </el-button>
          <el-button
            v-if="this.status != 9 && this.redactData.auditStatus != 1 && permission.isEnd === false "
            size="small"
            :type="this.status != 9 && this.status != 3 ? 'warning' : this.status == 3 ? 'danger' : ''"
            @click.native.prevent="stopPurchase()"
          >
            {{this.status != 9 && this.status != 3 ? ' 终止采购' : this.status == 3 && this.redactData.auditStatus != 1 ? '重新终止采购' : ''}}
          </el-button>
          <el-button
            v-if="this.status == 9 && permission.isCopy === false "
            :disabled="loadingCopy"
            :loading="loadingCopy"
            size="small"
            type="primary"
            @click.native.prevent="copyOrder()"
          >
            复制订单
          </el-button>
          <!-- confirmAllocate -->
        </div>
      </el-form>
    </div>

    <!--     点击图片放大弹出页面         -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :title="'图片放大'"
      :visible.sync="dialogFormVisiblePic"
      style="margin:20px auto"
      width="540px"
      @close="closeDialogPic()"
    >
      <img
        v-if="this.dialogImageUrl !== ''"
        :src="this.dialogImageUrl"
        alt=""
        style=" width: 500px; height: 500px; text-align: center"
      />
    </el-dialog>

    <!--     点击查看物流出页面         -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :title="'查看物流'"
      :visible.sync="dialogFormVisibleLogistics"
      style="margin:20px auto;"
      width="40%"
      @close="closeDialogLogistics()"
    >
      <div style="max-height: 600px;overflow:scroll;">
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in Logistics"
            :key="index"
            :timestamp="item.time"
          >
            {{ item.context }}
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>

    <!--     点击终止采购弹出页面         -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :title="'终止采购'"
      :visible.sync="dialogFormVisibleStopPurchase"
      style="margin:20px auto"
      width="520px"
      @close="closeDialogStop()"
    >
      <span
        style="color:red;text-align: center;margin-bottom: 20px;display: flow-root"
      >
        注意：终止采购后当前采购单以及对应入库单状态将更新为终止!
      </span>
      <el-form
        ref="redactData"
        :model="redactData"
        :rules="rules"
        class="ruleSty"
        label-width="100px"
      >
        <el-form-item label="终止截图">
          <erp-upload-img
            v-model="redactData.backProve"
            :maxCount="10"
            :showDelete="true"
            :upload-path="'/purchasingManagement/purchasing/backProve'"
          ></erp-upload-img>
        </el-form-item>
        <el-form-item label="终止理由" prop="refundReason">
          <el-input v-model="redactData.refundReason" :autosize="{ minRows: 3, maxRows: 5}" clearable maxlength="240"
                    show-word-limit size="small"
                    type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex;justify-content: center;margin-bottom: 20px;">
        <el-button size="small" @click="closeDialogStop('redactData')">
          关闭
        </el-button>
        <el-button
          size="small"
          type="success"
          :disabled="submitStopLoading"
          :loading="submitStopLoading"
          @click.native.prevent="submitStopPurchasing()"
        >
          提交
        </el-button>
      </div>
    </el-dialog>

    <!-- 商品库选择 -->
    <purchaseSelectGoods
      ref="purchaseSelectGoods"
      @setGoods="selectGoods"
    ></purchaseSelectGoods>

    <!-- 处理商品 -->
    <purchaseSelectDispose
      ref="purchaseSelectDispose"
      @handleGoods="selectGoods"
    ></purchaseSelectDispose>
  </d2-container>
</template>
<script>
import api from '@/api'
import purchaseSelectDispose from '../purchaseSelectDispose/index.vue'
import { mapActions } from 'vuex'

const redactDataDefault = {
  purchaseNo: '', // 采购单号
  purchaseType: '', // 采购
  channel: '', // 渠道
  payMethod: '', // 付款方式
  payProve: [], // 付款截图
  finalPaymentProve: [], // 尾款截图
  backProve: [], // 退款图片
  refundReason: '', // 退款原因
  predictArriveDate: '', // 预定日期
  deliveryMethod: '', // 发货方式
  deliveryUserName: '', // 收货人
  deliveryPhone: '', // 收货人联系方式
  warehouseId: '', // 入库仓库
  sourceWarehouseId: '', // 来源仓库
  sameWarehouseFlag: '', //  是否来自同意仓库
  deliveryAddress: '', // 收获地址
  courierCompany: '', // 物流公司
  trackingNumber: '', // 物流单号
  auditStatus: '', // 审核状态
  remark: '', // 备注
  itemAddData: [
    // 前端存放 添加商品数据
  ], // 新增商品Data
  productList: [], // 给后台处理数据的数组 -- 新增编辑
  deleteList: [] // 给后台处理数据的数组 -- 删除
}
const intoOrderInfoDefault = {
  cargoDamageList: [],
  cargoDamageNum: null, // 货损数量
  enterWarehouseNumber: '',
  intactNum: null, // 完好数量
  sweepCodeRecordNum: null, // 记录数量
  totalNum: null, // 采购数量
  withoutRecordList: [],
  withoutRecordNum: null // 未记录数量
}
export default {
  name: 'purchaseDetails',
  components: {
    purchaseSelectDispose
  },
  directives: {
    // 防止el-button重复点击
    preventReClick: {
      // 指令的定义
      inserted (el, binding) {
        el.addEventListener('click', () => {
          if (!el.disabled) {
            el.disabled = true
            setTimeout(() => {
              el.disabled = false
            }, binding.value || 5000)
          }
        })
      }
    }
  },
  data () {
    return {
      enterWarehouseNumber:null,
      version:null,
      // 权限点
      permission: {
        isEnd: this.checkauthority('purchase_end'), // 终止
        isUpdate: this.checkauthority('purchase_upd'), // 编辑

        isCopy: this.checkauthority('purchase_copy'), // 复制订单
        isDispose: this.checkauthority('into_detail_damage_list')// 处理
      },
      intoOrderInfo: JSON.parse(JSON.stringify(intoOrderInfoDefault)), // 入库明细
      confirmAllocate: false, // 就显示确认按钮
      remark: '备注信息:', // 文字
      cargoDamageFlag: null, // 是否破损
      logList: [], // 操作日志
      showRedactFlag: true, // 打开编辑
      purchaseNo: '', // 采购单号
      createdTimestamp: '', // 创建时间
      status: '', // 采购状态
      onHoverText: '', // 鼠标以上显示文字
      purchaseTypesData: [], // 采购类型Data
      channelsData: [], // 渠道Data
      warehouseIdsData: [], // 仓库Data
      sourceWarehouseIdsData: [], // 来源仓库Data
      matchData: [], // 比较数组
      Logistics: [], // 物流数组
      sameWarehouseFlagData: [
        // 是否同一地点
        {
          id: 0,
          value: '不同地点(需扫码)'
        },
        {
          id: 1,
          value: '与入库仓库同一地点'
        }
      ],
      payMethodsData: [
        {
          id: '0',
          value: '对公'
        },
        {
          id: '1',
          value: '备用金'
        },
        {
          id: '2',
          value: '备用金代付'
        },
        { id: '3', value: '张总'},
        { id: '4', value: '上海实体店备用金'},
        { id: '5', value: '现金'},
        { id: '6', value: '上海店现金'},
        { id: '7', value: '日本备用金'},
        {id: '8', value: '挂账'},
        { id: '9', value: '日币现金' },
        { id: '10', value: '法人信用卡' },
        { id: '11', value: '员工垫付' },
        { id: '12', value: '公司转账' },
        { id: '13', value: '人民币转账' }
      ], // 付款方式
      deliveryMethodsData: [
        {
          id: '0',
          value: '入库'
        },
        {
          id: '1',
          value: '代发'
        }
      ], // 发货方式Data
      redactData: JSON.parse(JSON.stringify(redactDataDefault)), // 新增Data
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL, // downloadUrl
      judgment: '', // 标题
      loadingCopy: false,
      loadingData: false,
      submitLoading: false,
      submitStopLoading: false,
      dialogFormVisiblePic: false, // 弹框开启
      dialogFormVisibleLogistics: false, // 弹框开启
      dialogFormVisibleStopPurchase: false, // 终止采购
      LogisticsLoading: false,
      dialogImageUrl: '', // 图片路径
      totalNum: 0, // 合计数量
      totalAmount: 0, // 合计金额
      tag1: '补货',
      tag2: '预定',
      tag3: '调拨',
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000 + 1
        }
      },
      rules: {
        purchaseType: [
          {
            required: true,
            message: '请选择采购类型',
            trigger: 'blur'
          }
        ],
        channel: [{
          required: true,
          message: '请选择渠道',
          trigger: 'blur'
        }],
        payMethod: [
          {
            required: true,
            message: '请选择付款方式',
            trigger: 'blur'
          }
        ],
        deliveryMethod: [
          {
            required: true,
            message: '请选择发货方式',
            trigger: 'blur'
          }
        ],
        deliveryUserName: [
          {
            required: true,
            message: '请输入收货人姓名',
            trigger: 'blur'
          },
          {
            pattern: /^(?!\s+)/,
            message: '首不能为空格',
            trigger: 'blur'
          }
        ],
        deliveryPhone: [
          {
            required: true,
            message: '请输入收货人联系方式',
            trigger: 'blur'
          },
          {
            pattern: /^(?!\s+)/,
            message: '首不能为空格',
            trigger: 'blur'
          }
          // {
          //   pattern: /^(1[3584]\d{9})$/,
          //   message: '请输入正确的手机号码或者座机号'
          // }
        ],
        predictArriveDate: [
          {
            required: true,
            message: '请选择预定日期',
            trigger: 'blur'
          }
        ],
        warehouseId: [
          {
            required: true,
            message: '请选择仓库',
            trigger: 'blur'
          }
        ],
        sourceWarehouseId: [
          {
            required: true,
            message: '请选择来源仓库',
            trigger: 'blur'
          }
        ],
        sameWarehouseFlag: [
          {
            required: true,
            message: '请选择仓库位置',
            trigger: 'blur'
          }
        ],
        deliveryAddress: [
          {
            required: true,
            message: '请输入收货地址',
            trigger: 'blur'
          },
          {
            pattern: /^(?!\s+)/,
            message: '首不能为空格',
            trigger: 'blur'
          }
        ],
        itemAddData: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              this.validateItemAddData(rule, value, callback, 'cover')
            }
          }
        ],
        num: [{
          required: true,
          message: '请输入采购数量',
          trigger: 'blur'
        }],
        backProve: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              this.validatePicture(rule, value, callback, 'cover')
            }
          }
        ],
        refundReason: [{
          required: true,
          message: '请填写退款原因',
          trigger: 'blur'
        }],
        unitPrice: [
          {
            required: true,
            message: '请输入采购价格',
            trigger: 'blur'
          },
          {
            pattern: /^\d+(\.\d{1,2})?$/,
            message: '请输入大于等于0的数字，且小数点后不能超过两位',
            trigger: 'blur'
          },
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              this.validateNum(rule, value, callback, 'cover')
            }
          }
        ]
      }
    }
  },
  watch: {
    $route (to, from) {
      this.$route.query.purchaseNo = to.query.purchaseNo
      this.initList()
      this.getPurchaseSource()
      this.getPurchaseChannel()
      this.getPurchaseWarehouse()
      this.getPurchaseSourceWarehouseIds()
    }
  },
  created () {
    this.$store.commit('d2admin/page/modifyOpend', opened => {
      opened.forEach((current) => {
        if (current.name === 'purchaseDetails') {
          current.meta.title = '采购详情-' + current.query.purchaseNo
        }
      })
    })
    this.initList()
    this.getPurchaseSource()
    this.getPurchaseChannel()
    this.getPurchaseWarehouse()
    this.getPurchaseSourceWarehouseIds()
  },
  // 页面销毁之前
  beforeDestroy () {
    //
  },
  computed: {
    count () {
      let num = 0
      let price = 0
      this.redactData.itemAddData.forEach(e => {
        const unitPrice = Number(e.unitPrice) * 100
        num += e.num ? Number(e.num) : null
        price += unitPrice * e.num ? Number(unitPrice) * Number(e.num) : null
      })
      return {
        totalNum: num,
        totalAmount: price
      }
    }
  },
  methods: {
    saveImg(i){
      if(i==1&&this.redactData.payProve.length==0){
        this.$message.error('图片不能为空')
        return
      }else if(i==2&&this.redactData.finalPaymentProve.length==0){
        this.$message.error('图片不能为空')
        return
      }
      const arrayPayProve = []
      const arrayFinalPaymentProve = []
      let obj = {}
      // 图片处理
      if (this.redactData.payProve.length > 0) {
        this.redactData.payProve.map(e => {
          arrayPayProve.push(e.url)
          obj.payProve = arrayPayProve.join()
        })
      } else {
        obj.payProve = arrayPayProve.join()
      }
      // 图片处理
      if (this.redactData.finalPaymentProve.length > 0) {
        this.redactData.finalPaymentProve.map(e => {
          arrayFinalPaymentProve.push(e.url)
          obj.finalPaymentProve = arrayFinalPaymentProve.join()
        })
      } else {
        obj.finalPaymentProve = arrayFinalPaymentProve.join()
      }
      api.purchasingListSaveImg({
        "finalPaymentProve": obj.finalPaymentProve,
        "payProve": obj.payProve,
        "purchaseNo": this.redactData.purchaseNo,
        "version": this.version
      }).then(res=>{
        this.$message({
                type: 'success',
                message: '添加成功!'
              })
      }).catch(err=>{
        this.message({
          type: 'error',
          message: '添加失败'
        })
      })
    },
    ...mapActions('d2admin/page', ['close']),
    // 返回样式
    returnSty () {
      if (this.redactData.purchaseType !== this.tag3) {
        if (this.redactData.deliveryMethod === '1') {
          return true
        } else {
          return false
        }
      }
    },
    // 获取采购类型
    async getPurchaseSource () {
      const res = await api.getPurchaseSelect({
        name: 'source'
      })
      const filterName = [49]
      this.purchaseTypesData =
        res.body.filter((item, index) => !filterName.includes(item.id))
    },
    // 获取采购渠道
    async getPurchaseChannel () {
      const res = await api.getPurchaseSelect({
        name: 'channel'
      })
      this.channelsData = res.body
    },
    // 获取仓库
    async getPurchaseWarehouse () {
      const res = await api.getPurchaseWarehouse()
      this.warehouseIdsData = res.body
    },
    // 获取来源仓库
    async getPurchaseSourceWarehouseIds () {
      const res = await api.getPurchaseSourceWarehouseIds()
      this.sourceWarehouseIdsData = res.body
    },
    // 获取仓库地址
    getWarehouseAddress (val) {
      console.log(val)
      this.sourceWarehouseIdsData.forEach(e => {
        if (val === e.warehouseId) {
          this.redactData.deliveryAddress = e.warehouseAddress
        }
      })
    },
    // 鼠标以上显示完整名称
    onMouseOver (item) {
      this.onHoverText = item
    },
    // 获取数据
    async initList () {
      this.loadingData = true
      try {
        const res = await api.info_purchase({
          purchaseNo: this.$route.query.purchaseNo
        })
        this.redactData.purchaseType = res.body.purchaseType
        this.redactData.channel = res.body.channel
        this.redactData.payMethod = res.body.payMethod
        this.redactData.predictArriveDate = res.body.predictArriveDate
        this.redactData.deliveryMethod = res.body.deliveryMethod
        this.redactData.deliveryUserName = res.body.deliveryUserName
        this.redactData.deliveryPhone = res.body.deliveryPhone
        this.redactData.warehouseId = res.body.warehouseId
        this.redactData.sourceWarehouseId = res.body.sourceWarehouseId
        this.redactData.purchaseNo = res.body.purchaseNo
        this.redactData.sameWarehouseFlag = res.body.sameWarehouseFlag
        this.redactData.deliveryAddress = res.body.deliveryAddress
        this.redactData.courierCompany = res.body.courierCompany
        this.redactData.distribution = res.body.distribution
        this.redactData.trackingNumber = res.body.trackingNumber
        this.redactData.remark = res.body.remark
        this.redactData.itemAddData = res.body.productList
        this.cargoDamageFlag = res.body.cargoDamageFlag
        this.logList = res.body.logList
        this.intoOrderInfo = res.body.intoOrderInfo
        this.purchaseNo = res.body.purchaseNo
        this.status = res.body.status
        this.confirmAllocate = res.body.confirmAllocate
        this.createdTimestamp = res.body.createdTimestamp
        this.redactData.auditStatus = res.body.auditStatus
        this.redactData.refundReason = res.body.refundReason
        this.version=res.body.version
        let marchArray = []
        marchArray = JSON.parse(JSON.stringify(res.body.productList))
        marchArray.map(e => {
          this.matchData.push(e)
        })
        if (res.body.backProve === '' || res.body.backProve === null) {
          this.redactData.backProve = []
        } else {
          this.redactData.backProve = (res.body.backProve || '')
            .split(',')
            .map(item => {
              return {
                name: item,
                url: item
              }
            })
        }
        if (res.body.payProve === '' || res.body.payProve === null) {
          this.redactData.payProve = []
        } else {
          this.redactData.payProve = (res.body.payProve || '')
            .split(',')
            .map(item => {
              return {
                name: item,
                url: item
              }
            })
        }
        if (res.body.finalPaymentProve === '' || res.body.finalPaymentProve === null) {
          this.redactData.finalPaymentProve = []
        } else {
          this.redactData.finalPaymentProve = (res.body.finalPaymentProve || '')
            .split(',')
            .map(item => {
              return {
                name: item,
                url: item
              }
            })
        }
      } catch (e) {
        this.message({
          type: 'error',
          message: '获取数据失败'
        })
      } finally {
        this.loadingData = false
      }
    },
    // 采购类型改变
    changePurchaseType () {
      const oldPurchaseType = JSON.parse(
        JSON.stringify(this.redactData.purchaseType)
      )
      const oldItemAddData = JSON.parse(
        JSON.stringify(this.redactData.itemAddData)
      )
      this.redactData = JSON.parse(JSON.stringify(redactDataDefault))
      this.redactData.purchaseType = oldPurchaseType
      this.redactData.itemAddData = oldItemAddData
    },
    // 图片放大开启
    getPreview (payProve) {
      this.judgment = '放大图片'
      this.dialogFormVisiblePic = true
      this.dialogImageUrl = payProve
    },
    // 图片放大关闭
    closeDialogPic () {
      this.dialogFormVisiblePic = false
      this.dialogImageUrl = ''
    },
    // 终止采购关闭
    closeDialogStop () {
      this.$refs.redactData.clearValidate(['backProve'])
      this.$refs.redactData.clearValidate(['refundReason'])
      this.dialogFormVisibleStopPurchase = false
      if (this.status != 9 && this.status != 3) {
        this.redactData.backProve = []
        this.redactData.refundReason = ''
      }
      // this.redactData.backProve = []
      // this.redactData.refundReason = ''
    },
    // 添加商品
    addItems () {
      if (this.redactData.warehouseId !== '') {
        this.$refs.purchaseSelectGoods.open()
      } else {
        this.$message({
          type: 'warning',
          message: '请先选择仓库以便添加商品!'
        })
      }
    },
    // 选择商品
    async selectGoods (data) {
      const array = []
      data.map(e => {
        array.push(e.goodsCoding)
      })
      const res = await api.getMineProductInfo({
        goodsCodings: array,
        warehouseId: this.redactData.warehouseId
      })
      console.log(res.body, '插入值')
      data.map(e => {
        this.redactData.itemAddData.push(e)
      })
      console.log(this.redactData.itemAddData, 'redactData.itemAddData')
      for (var i = 0; i < res.body.length; i++) {
        for (var j = 0; j < this.redactData.itemAddData.length; j++) {
          if (
            res.body[i].goodsCoding ===
            this.redactData.itemAddData[j].goodsCoding
          ) {
            console.log(this.redactData.itemAddData[j])
            this.redactData.itemAddData[j].inTransitNum =
              res.body[i].inTransitNum
            this.redactData.itemAddData[j].inventoryNum =
              res.body[i].inventoryNum
            this.redactData.itemAddData[j].warehousePriceRange =
              res.body[i].warehousePriceRange
          }
        }
      }
    },
    // 处理详细
    toDispose () {
      this.$refs.purchaseSelectDispose.open(this.intoOrderInfo.enterWarehouseNumber)
    },
    // 打开编辑
    redactForm () {
      this.showRedactFlag = !this.showRedactFlag
    },
    // 关闭
    resetForm () {
      this.redactData = JSON.parse(JSON.stringify(redactDataDefault))
      this.$refs.redactData.clearValidate()
      const tagName = '/purchaseDetails?purchaseNo=' + this.$route.query.purchaseNo
      this.$router.push({ path: '/purchasing' })
      setTimeout(() => this.close({ tagName: tagName }), 10)
    },
    // 新增保存
    submitForm () {
      this.redactData.itemAddData.map(e => {
        const obj = {
          allocateNum: e.allocateNum === undefined ? null : e.allocateNum,
          allocateUnitPrice:
            e.allocateUnitPrice === undefined ? null : e.allocateUnitPrice,
          delFlg: e.allocateNum === undefined ? 0 : e.delFlg,
          goodsBrand: e.goodsBrand,
          goodsBrandName: e.goodsBrandName,
          goodsCoding: e.goodsCoding,
          goodsIp: e.goodsIp,
          goodsIpName: e.goodsIpName,
          goodsName: e.goodsName,
          goodsOne: e.goodsOne,
          goodsThree: e.goodsThree,
          goodsTwo: e.goodsTwo,
          id: e.id === e.goodsCoding ? '' : e.id,
          id1: e.id1,
          id2: e.id2,
          id3: e.id3,
          money: e.num * e.unitPrice,
          num: e.num === undefined ? null : e.num,
          pictureUrl: e.pictureUrl,
          purchaseNo: e.purchaseNo === undefined ? null : e.purchaseNo,
          unitPrice: e.unitPrice === undefined ? null : e.unitPrice
        }
        this.redactData.productList.push(obj)
      })
      const obj = JSON.parse(JSON.stringify(this.redactData))
      const arrayPayProve = []
      const arrayFinalPaymentProve = []
      // 审核状态处理
      obj.auditStatus = this.redactData.auditStatus === 2 ? 0 : this.redactData.auditStatus
      // 日期处理
      if (this.redactData.predictArriveDate === '') {
        obj.predictArriveDate = null
      }
      // 图片处理
      if (this.redactData.payProve.length > 0) {
        this.redactData.payProve.map(e => {
          arrayPayProve.push(e.url)
          obj.payProve = arrayPayProve.join()
        })
      } else {
        obj.payProve = arrayPayProve.join()
      }
      // 图片处理
      if (this.redactData.finalPaymentProve.length > 0) {
        this.redactData.finalPaymentProve.map(e => {
          arrayFinalPaymentProve.push(e.url)
          obj.finalPaymentProve = arrayFinalPaymentProve.join()
        })
      } else {
        obj.finalPaymentProve = arrayFinalPaymentProve.join()
      }
      delete obj.itemAddData
      this.$refs.redactData.validate(async valid => {
        if (valid) {
          try {
            this.loadingData = true
            this.submitLoading = true
            const res = await api.update_purchase(obj)
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '编辑成功!'
              })
              this.redactData = JSON.parse(JSON.stringify(redactDataDefault))
              this.$refs.redactData.clearValidate()
              this.$router.replace({ path: '/purchasing' })
              this.close({ tagName: '/purchaseDetails' })
              this.close({ tagName: '/newlyPurchasing' })
            } else {
              this.redactData.productList = []
              this.$message({
                type: 'danger',
                message: '编辑失败!'
              })
            }
          } catch (error) {
            this.$message({
              type: 'danger',
              message: '编辑失败!'
            })
          } finally {
            this.loadingData = false
            this.submitLoading = false
          }
        } else {
          this.redactData.productList = []
          this.$message({
            type: 'warning',
            message: '请填写必填内容后提交'
          })
        }
      })
    },
    // 终止采购
    stopPurchase () {
      this.dialogFormVisibleStopPurchase = true
    },
    // 终止采购确认
    submitStopPurchasing () {
      const obj = JSON.parse(JSON.stringify(this.redactData))
      const array = []
      if (this.redactData.backProve.length > 0) {
        this.redactData.backProve.map(e => {
          array.push(e.url)
          obj.backProve = array.join()
        })
      } else {
        obj.backProve = array.join()
      }
      this.$refs.redactData.validate(async valid => {
        if (valid) {
          try {
            this.loadingData = true
            this.submitStopLoading = true
            const res = await api.end_purchase({
              backProve: obj.backProve,
              refundReason: obj.refundReason,
              purchaseNo: this.purchaseNo
            })
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '终止采购提交成功!'
              })
              this.redactData = JSON.parse(JSON.stringify(redactDataDefault))
              this.$refs.redactData.clearValidate()
              this.$router.replace({ path: '/purchasing' })

              this.close({ tagName: '/newlyPurchasing' })
            } else {
              this.$message({
                type: 'warning',
                message: '终止采购提交失败!'
              })
            }
          } catch (error) {
            this.$message({
              type: 'warning',
              message: '终止采购提交失败!'
            })
          } finally {
            this.loadingData = false
            this.submitStopLoading = false
          }
        } else {
          this.$message({
            type: 'warning',
            message: '请填写必填内容后提交'
          })
        }
      })
    },
    // 复制订单
    async copyOrder () {
      this.loadingCopy = true
      try {
        await api.copyPurchaseOrder({
          purchaseNo: this.purchaseNo
        })
        this.$message.success('已复制该订单')
        this.resetForm()
      } catch (e) {
        this.$message.warning('复制订单失败')
      } finally {
        this.loadingCopy = false
      }
    },
    // 删除按钮
    remove (index, row, rows) {
      this.$confirm('是否删除该商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const obj = {
          id: row.id === row.goodsCoding ? '' : row.id
        }
        if (obj.id !== '') {
          this.redactData.itemAddData.forEach((e, index) => {
            if (e.id === obj.id) {
              this.redactData.deleteList.push(obj.id)
              rows.splice(index, 1)
            }
          })
        } else {
          rows.splice(index, 1)
        }
      })
    },
    // 查看物流
    async openLogistics () {
      if (this.redactData.trackingNumber === '') {
        console.log('1')
        this.$message({
          type: 'warning',
          message: '请填写物流单号!'
        })
      } else {
        try {
          this.LogisticsLoading = true
          const res = await api.getLogistics({
            // logisticsNumber: "JD0086304241079"
            logisticsNumber: this.redactData.trackingNumber
          })
          if (res.body === null || res.body == []) {
            this.$message({
              type: 'info',
              message: '暂无物流信息，请稍后再试'
            })
          } else {
            this.Logistics = res.body
            this.dialogFormVisibleLogistics = true
          }
        } catch (error) {
          this.$message({
            type: 'danger',
            message: '获取物流信息失败!'
          })
        } finally {
          this.LogisticsLoading = false
        }
      }
    },
    // 关闭查看
    closeDialogLogistics () {
      this.dialogFormVisibleLogistics = false

      this.Logistics = []
    },
    // itemAddData校验
    validateItemAddData (rule, value, callback) {
      if (this.redactData.itemAddData.length === 0) {
        callback(new Error('请添加商品'))
      } else {
        callback()
      }
    },
    // Num校验
    validateNum (rule, value, callback) {
      if (this.redactData.purchaseType === '调拨') {
        if (value >= 0) {
          callback()
        } else {
          callback(new Error('请输入大于等于0的数字'))
        }
      } else {
        if (value > 0) {
          callback()
        } else {
          callback(new Error('请输入大于0的数字'))
        }
      }
    },
    // 图片校验
    validatePicture (rule, value, callback) {
      if (value.length === 0) {
        callback(new Error('图片为必选项'))
      } else {
        callback()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  margin-left: 9px;
  margin-right: 9px;
  box-sizing: border-box;
  border: 2px solid #888;
  border-radius: 10px;
  min-height: 100%;
  width: 99%;
}

.searchBgc1 {
  height: 30px;
  line-height: 30px;
  background-color: #dfe6ec;
  padding-left: 10px;
  font-weight: bolder;
  border-radius: 10px 10px 0px 0px;
}

.searchBgc2 {
  height: 30px;
  line-height: 30px;
  background-color: #dfe6ec;
  padding-left: 10px;
  font-weight: bolder;
}

.searchBgc3 {
  height: 30px;
  line-height: 30px;
  border-radius: 10px 10px 0 0;
  background-color: #dfe6ec;
  padding-left: 10px;
  font-weight: bolder;
}

.ruleSty {
  width: 100%;
}

.textSty {
  display: flex;
  justify-content: right;
  margin-right: 5%;
  color: rgb(102, 102, 102);
  font-weight: bolder;
  margin-bottom: 20px;
}

.spanSty {
  width: 5%;
  height: 17px;
  line-height: 17px;
  color: #f60;
}

.div1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-right: 20px;
  flex-grow: transition 1;
}

.div2 {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  flex-grow: transition 1;
}

.div3 {
  display: inline-block;
}

.div4 {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  flex-grow: transition 1;
}

::v-deep .itemAddSty .el-form-item__content {
  margin-left: 0px !important;
  padding: 0px 10px 0px;
}

::v-deep .itemAddSty .el-form-item__error {
  // margin-left: 48.2%;
  position: relative;
  top: 0;
  left: 0;
}
</style>
