<template>
  <div class="orderDetails">
    <Basic
      :goodsInfo="goodsInfo"
      :info="info"
      :logInfo="logInfo"
      @getInfo="getInfo"
    ></Basic>
    <Good
      :goodsInfo="goodsInfo"
      :info="info"
      @getGoods="getGoods"
      @refresh="refresh"
    ></Good>
    <Particulars v-if="info.type !== 1" :logInfo="logInfo"></Particulars>
    <Log :logInfo="logInfo"></Log>
    <div style="display: flex;justify-content: center;margin-bottom: 20px;">
      <el-button size="small" @click="resetForm()">
        关闭
      </el-button>
      <el-button
        v-if="info.status === 4 && info.type === 3 && permission.isCopy === false"
        size="small"
        type="success"
        :loading="loadingCopy"
        :disabled="loadingCopy"
        @click.native.prevent="copyOrder()"
      >
        复制订单
      </el-button>
    </div>
  </div>
</template>
<script>
import Basic from './com/basic.vue'
import Good from './com/good.vue'
import Particulars from './com/particulars.vue'
import Log from './com/log.vue'
import api from '@/api'
import { selectFormData } from '../list/contant'
import { mapActions } from 'vuex'

export default {
  name: 'orderDetails',
  data () {
    return {
      // 权限点
      permission: {
        isCopy: this.checkauthority('outbound_copy') // 复制订单
      },
      query: {
        outId: this.$route.query.outId
      },
      loadingCopy: false,
      selectForm: JSON.parse(JSON.stringify(selectFormData)),
      info: {},
      goodsInfo: {},
      logInfo: {}
    }
  },
  created () {
    this.$store.commit('d2admin/page/modifyOpend', opened => {
      opened.forEach(current => {
        if (current.name === 'orderDetails') {
          current.meta.title = '出库明细-' + current.query.outId
        }
      })
    })
    this.getInfo()
  },
  watch: {
    $route (to, from) {
      this.query.outId = to.query.outId
      this.getInfo()
    }
  },
  methods: {
    ...mapActions('d2admin/page', ['close']),
    refresh () {
      this.getInfo()
    },
    async getInfo () {
      this.selectForm.outId = this.query.outId
      const data = await api.outbound_getPageList(this.selectForm)
      this.info = data.body.datas[0]
      this.$bus.$emit('fun', this.info)
      this.getLogInfo()
    },
    async getGoods (page) {
      let data
      if (
        +this.info.status === 0 ||
        +this.info.status === 4 ||
        +this.info.distribution === 4
      ) {
        data = await api.outbound_info({
          id: this.info.id
        })
      } else {
        data = await api.outbound_detail({
          id: this.info.id,
          pageNo: page.pageNo,
          pageSize: page.pageSize
        })
      }
      this.goodsInfo = data.body
    },
    async getLogInfo () {
      const data = await api.outbound_outLog({ id: this.info.id })
      this.logInfo = data.body
    },
    resetForm () {
      const tagName = '/stockoutdetails?outId=' + this.query.outId
      this.$router.push({ path: '/stockout' })
      this.close({ tagName: tagName })
    },
    async copyOrder () {
      this.loadingCopy = true
      try {
        await api.outDetail_copyOutBound({
          id: this.info.id
        })
        this.$message.success('已复制该订单')
        this.resetForm()
      } catch (e) {
        this.$message.warning('复制订单失败')
      } finally {
        this.loadingCopy = false
      }
    }
  },
  components: {
    Basic,
    Good,
    Particulars,
    Log
  }
}
</script>
<style lang="scss" scoped></style>
