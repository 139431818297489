export default (function (_ref) {
  var request = _ref.request;
  return {
    // 分页查询
    getAuditList: function getAuditList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/checkStock/checkAuditPage',
        method: 'post',
        data: data
      });
    },
    // 审核确认
    affirmAudit: function affirmAudit() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/checkStock/auditCheckStock',
        method: 'post',
        data: data
      });
    },
    // 明细
    getAffirmAuditParticulars: function getAffirmAuditParticulars() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/checkStock/getCheckDetail',
        method: 'post',
        data: data
      });
    }
  };
});