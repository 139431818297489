//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'log',
  props: {
    operateLogs: {
      type: Array
    }
  }
};