import { JSEncrypt } from 'jsencrypt'

const publicKey = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDT7gpqpsNqKVG0Zr7lNq9SCE9VrTxYZ5JKkeiCfr0vub9hSa47IV80T6d79Vq0MJtEIPujAvv/DVZuaCIrju1nD41hCk15t5yRTevL34f9DkVxtfpCdpu7J4nGqsjHPF18Rbzb4NUjYWihkXqUHVFzfHU86aHrgxcjq0j6JVbBzQIDAQAB-----END PUBLIC KEY-----'
const privateKey = ''
export function encrypt (val) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey)
  const res = encryptor.encrypt(val)
  return res
}
export function decrypt (msg) {
  const decrypt = new JSEncrypt()
  decrypt.setPrivateKey(privateKey)
  var decryptMsg = decrypt.decrypt(msg)
  return decryptMsg
}
