var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.infoDataLoading,
            expression: "infoDataLoading"
          }
        ],
        staticClass: "header"
      },
      [
        _c(
          "div",
          {
            staticClass: "searchBgc3",
            staticStyle: {
              "margin-bottom": "10px",
              display: "flex",
              "justify-content": "space-between"
            }
          },
          [_vm._v(" 基本信息 ")]
        ),
        _c(
          "el-form",
          {
            ref: "InfoForm",
            staticClass: "demo-ruleForm",
            staticStyle: { "font-weight": "bold" },
            attrs: { model: _vm.redactData, "label-width": "100px" }
          },
          [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "盘点单号" } },
                  [_c("el-tag", [_vm._v(_vm._s(_vm.redactData.checkId))])],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "创建时间" } },
                  [
                    _c("el-tag", { attrs: { type: "success" } }, [
                      _vm._v(
                        " " + _vm._s(_vm.redactData.createdTimestamp) + " "
                      )
                    ])
                  ],
                  1
                ),
                _vm.redactData.status == 2
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "完成时间" } },
                      [
                        _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(" " + _vm._s(_vm.redactData.endTime) + " ")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _c("el-form-item", { attrs: { label: "审核状态" } }, [
                  _vm.redactData.auditStatus == 0
                    ? _c("div", [_c("el-tag", [_vm._v("待审核")])], 1)
                    : _vm._e(),
                  _vm.redactData.auditStatus == 1
                    ? _c(
                        "div",
                        [
                          _c("el-tag", { attrs: { type: "success" } }, [
                            _vm._v("审核通过")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.redactData.auditStatus == 2
                    ? _c(
                        "div",
                        [
                          _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v("审核不通过")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.redactData.auditStatus == 9
                    ? _c(
                        "div",
                        [
                          _c("el-tag", { attrs: { type: "warning" } }, [
                            _vm._v("终止盘库")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: "盘点状态" } },
                  [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            _vm.redactData.status === 0
                              ? ""
                              : _vm.redactData.status === 1
                              ? "warning"
                              : _vm.redactData.status === 3
                              ? "success"
                              : _vm.redactData.status === 9
                              ? "danger"
                              : ""
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.redactData.status === 0
                                ? "待盘库"
                                : _vm.redactData.status === 1
                                ? "盘库中"
                                : _vm.redactData.status === 3
                                ? "盘库完成"
                                : _vm.redactData.status === 9
                                ? "终止盘库"
                                : ""
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("el-form-item", { attrs: { label: "制单人员" } }, [
                  _c("div", { staticStyle: { "font-weight": "normal" } }, [
                    _vm._v(_vm._s(_vm.redactData.createdName))
                  ])
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c("el-form-item", { attrs: { label: "盘点维度" } }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.redactData.dimension == 0 ? "商品维度" : "货架维度"
                      )
                    )
                  ])
                ]),
                _c("el-form-item", { attrs: { label: "盘点标题" } }, [
                  _c("span", [_vm._v(_vm._s(_vm.redactData.checkName))])
                ])
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "盘库备注" } },
              [
                _c("el-input", {
                  attrs: { disabled: "", type: "textarea" },
                  model: {
                    value: _vm.redactData.remark,
                    callback: function($$v) {
                      _vm.$set(_vm.redactData, "remark", $$v)
                    },
                    expression: "redactData.remark"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "searchBgc2",
                staticStyle: {
                  "margin-bottom": "10px",
                  display: "flex",
                  "justify-content": "space-between"
                }
              },
              [
                _vm._v(" 商品列表 "),
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      height: "26px",
                      "margin-right": "20px",
                      "margin-top": "2px"
                    },
                    attrs: {
                      disable: _vm.exportLoading,
                      loading: _vm.exportLoading,
                      size: "mini",
                      type: "primary"
                    },
                    on: {
                      click: function($event) {
                        return _vm.exportTable()
                      }
                    }
                  },
                  [_vm._v("导出 ")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "goodsDataSty" },
              [
                _c(
                  "el-form-item",
                  { staticClass: "itemAddSty" },
                  [
                    _c(
                      "el-table",
                      {
                        ref: "goodsData",
                        staticStyle: { padding: "5px 5px 0" },
                        attrs: {
                          "cell-style": { textAlign: "center" },
                          data: _vm.redactData.goodsData,
                          "header-cell-style": {
                            background: "#dfe6ec",
                            color: "#666666",
                            textAlign: "center"
                          },
                          "row-style": { height: "57px" },
                          "element-loading-text": "数据加载中",
                          "max-height": "430px",
                          "tooltip-effect": "dark"
                        }
                      },
                      [
                        _c("el-table-column", {
                          key: "index",
                          attrs: {
                            align: "center",
                            label: "序号",
                            width: "80"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(" " + _vm._s(scope.$index + 1) + " ")
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          key: "id",
                          attrs: {
                            align: "center",
                            label: "商品编码",
                            prop: "id"
                          }
                        }),
                        _c("el-table-column", {
                          key: "goodsName",
                          attrs: {
                            align: "center",
                            label: "商品名称",
                            prop: "goodsName"
                          }
                        }),
                        _c("el-table-column", {
                          key: "pictureUrl",
                          attrs: {
                            align: "center",
                            label: "商品图片",
                            prop: "pictureUrl"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "40px",
                                      height: "40px"
                                    },
                                    attrs: {
                                      "preview-src-list": [
                                        _vm.downloadUrl +
                                          "/" +
                                          scope.row.pictureUrl
                                      ],
                                      src:
                                        _vm.downloadUrl +
                                        "/" +
                                        scope.row.pictureUrl
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _vm.redactData.dimension == "1"
                          ? _c("el-table-column", {
                              key: "areaName",
                              attrs: {
                                align: "center",
                                prop: "areaName",
                                label: "区域"
                              }
                            })
                          : _vm._e(),
                        _vm.redactData.dimension == "1"
                          ? _c("el-table-column", {
                              key: "shelvesName",
                              attrs: {
                                align: "center",
                                prop: "shelvesName",
                                label: "货架"
                              }
                            })
                          : _vm._e(),
                        _vm.redactData.dimension == "1"
                          ? _c("el-table-column", {
                              key: "shelvesLayers",
                              attrs: {
                                align: "center",
                                prop: "shelvesLayers",
                                label: "层数"
                              }
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          key: "intoPrices",
                          attrs: {
                            align: "center",
                            label: "入库价",
                            prop: "intoPrices"
                          }
                        }),
                        _c("el-table-column", {
                          key: "goodsNum",
                          attrs: {
                            align: "center",
                            label: "在库数量",
                            prop: "goodsNum"
                          }
                        }),
                        _c("el-table-column", {
                          key: "checkNum",
                          attrs: {
                            align: "center",
                            label: "实际库存",
                            prop: "checkNum"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.redactData.status === 9
                                          ? "- -"
                                          : scope.row.checkNum
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _vm.redactData.status !== 9
                          ? _c("el-table-column", {
                              key: "breakEven",
                              attrs: {
                                align: "center",
                                label: "盘点盈亏",
                                prop: "breakEven"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "el-tag",
                                          {
                                            style: {
                                              color:
                                                scope.row.breakEven > 0
                                                  ? "#00cc66"
                                                  : scope.row.breakEven < 0
                                                  ? "#cc3333"
                                                  : ""
                                            },
                                            attrs: {
                                              type:
                                                scope.row.breakEven > 0
                                                  ? "success"
                                                  : scope.row.breakEven < 0
                                                  ? "danger"
                                                  : ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(scope.row.breakEven) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2183182460
                              )
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "title" }, [_vm._v("盘库结果")]),
            _c("div", { staticClass: "bottom" }, [
              _c("div", [_vm._v("待盘点数量:" + _vm._s(_vm.Dnum))]),
              _c("div", [_vm._v("已盘点数量:" + _vm._s(_vm.Ynum))]),
              _c("div", [_vm._v("盘盈:" + _vm._s(_vm.Pwin))]),
              _c("div", [_vm._v("盘亏:" + _vm._s(_vm.Plose))]),
              _c("div", [
                _vm._v("出库单号："),
                _c(
                  "span",
                  {
                    staticClass: "jump",
                    on: {
                      click: function($event) {
                        _vm.redactData.outId
                          ? _vm.handleDetailsOut(_vm.redactData.outId)
                          : ""
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.redactData.outId))]
                )
              ]),
              _c("div", [
                _vm._v("入库单号："),
                _c(
                  "span",
                  {
                    staticClass: "jump",
                    on: {
                      click: function($event) {
                        _vm.redactData.intoId
                          ? _vm.handleDetailsIn(_vm.redactData.intoId)
                          : ""
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.redactData.intoId))]
                )
              ]),
              _c(
                "div",
                { staticClass: "textarea" },
                [
                  _vm._v(" 盈亏原因备注： "),
                  _c("el-input", {
                    attrs: { type: "textarea", disabled: "", placeholder: "" },
                    model: {
                      value: _vm.redactData.checkRemark,
                      callback: function($$v) {
                        _vm.$set(_vm.redactData, "checkRemark", $$v)
                      },
                      expression: "redactData.checkRemark"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              {
                staticClass: "searchBgc2",
                staticStyle: { "margin-bottom": "20px", "margin-top": "20px" }
              },
              [_vm._v(" 操作日志 ")]
            ),
            _c(
              "div",
              [
                _c(
                  "el-timeline",
                  _vm._l(_vm.redactData.logList, function(item, index) {
                    return _c(
                      "el-timeline-item",
                      {
                        key: index,
                        staticStyle: {
                          "box-sizing": "border-box",
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "normal",
                          "word-break": "break-all",
                          "line-height": "23px",
                          "padding-right": "10px"
                        },
                        attrs: { timestamp: item.createdTimestamp }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.createdName +
                                item.operateDesc +
                                " " +
                                "备注信息:" +
                                (item.remark === null ? "" : item.remark)
                            ) +
                            " "
                        )
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "center",
              "margin-top": "10px"
            }
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { "margin-bottom": "20px", "margin-top": "20px" },
                attrs: { size: "mini" },
                on: {
                  click: function($event) {
                    return _vm.goBack()
                  }
                }
              },
              [_vm._v("关闭")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }