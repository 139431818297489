var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.messageList } },
            [
              _c("el-table-column", {
                attrs: { prop: "titleName", align: "center", label: "标题" }
              }),
              _c("el-table-column", {
                attrs: { prop: "titleName", align: "center", label: "状态" }
              }),
              _c("el-table-column", {
                attrs: { prop: "titleName", align: "center", label: "时间" }
              }),
              _c(
                "el-table-column",
                { attrs: { label: "操作", align: "center" } },
                [
                  [
                    _c("el-button", { attrs: { type: "primary" } }, [
                      _vm._v("详情")
                    ])
                  ]
                ],
                2
              )
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              total: _vm.page.totalSize,
              "current-page": _vm.page.pageNo,
              "page-size": [10, 20, 30, 40, 50, 100],
              layout: "jumper, prev, pager, next,sizes"
            },
            on: {
              "current-change": _vm.handleClick,
              "size-change": _vm.handleClick
            }
          }),
          _c("el-tab-pane", { attrs: { label: "全部消息", name: "0" } }),
          _c("el-tab-pane", { attrs: { label: "未读消息", name: "1" } }),
          _c("el-tab-pane", { attrs: { label: "已读消息", name: "2" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }