<template>
  <div class="basic">
    <div class="title">
      <span>基本信息</span>
    </div>
    <head-search :label-style="labelStyle" :item-style="itemStyle">
      <head-search-item label="入库单号:">
        {{ info.enterWarehouseNumber }}
      </head-search-item>
      <head-search-item label="子入库单号:" v-if="Array.isArray(info.childNumbers) && info.childNumbers.length > 0">
        <el-popover placement="right" width="100" trigger="click">
          <el-button v-for="(item, index) in info.childNumbers" :key="index" @click="toDetail(item)" type="text">
            {{ item }}
          </el-button>
          <br />
          <el-button class="reference" slot="reference" type="text" size="small">
            查看
          </el-button>
        </el-popover>
      </head-search-item>
      <head-search-item label="主入库单号:" v-if="info.fatherId">
        <el-button type="text" class="text" @click="toDetail(info.fatherId)">
          {{ info.fatherId }}
        </el-button>
      </head-search-item>
      <head-search-item label="入库类型:">
        {{ info.enterWarehouseType }}
      </head-search-item>
      <head-search-item label="入库渠道:" v-if="info.enterWarehouseType === '其他入库'">
        {{ info.channel ? info.channel : info.enterWarehouseSource }}
      </head-search-item>
      <head-search-item label="入库仓库:" v-if="!(
      (info.enterWarehouseState == 3 || info.enterWarehouseState == 2) &&
      info.enterWarehouseState == '代发'
    )
      ">
        {{ info.warehouseName }}
      </head-search-item>
      <head-search-item label="仓库位置:" v-if="info.enterWarehouseState === 0 && info.enterWarehouseType === '调拨'
      ">
        {{
      info.sameWarehouseFlag === 1
        ? '与入库仓库同一地点'
        : '不同地点（需扫码）'
    }}
      </head-search-item>
      <head-search-item label="调出仓库:" v-if="info.enterWarehouseType === '调拨'">
        {{ info.sourceWarehouseName }}
      </head-search-item>
      <head-search-item label="入库金额:">
        {{ info.enterWarehouseAggregate }}
      </head-search-item>
      <head-search-item label="入库状态:">
        {{
      info.isStop
        ? '已终止'
        : info.enterWarehouseState === 0
          ? '待入库'
          : info.enterWarehouseState === 1
            ? '入库中'
            : info.enterWarehouseState === 2
              ? '部分入库'
              : info.enterWarehouseState === 3
                ? '完成入库'
                : info.enterWarehouseState === 9
                  ? '已终止'
                  : ''
    }}
      </head-search-item>
      <head-search-item v-if="info.enterWarehouseType !== '其他' && info.enterWarehouseType !== '盘盈'" label="采购单号:">
        {{ info.purchaseId }}
      </head-search-item>
      <head-search-item v-if="info.enterWarehouseType !== '其他' && info.enterWarehouseType !== '盘盈'" label="采购类型:">
        {{ info.purchaseType }}
      </head-search-item>
      <head-search-item v-if="info.enterWarehouseType !== '调拨' && info.enterWarehouseType !== '盘盈'"
        :label="info.enterWarehouseType === '其他' ? '入库来源：' : '采购渠道:'">
        {{ info.channel ? info.channel : info.enterWarehouseSource }}
      </head-search-item>
      <!--      <head-search-item-->
      <!--        :label="'是否扫码'"-->
      <!--      >-->
      <!--        {{ info.small_trash  === 1 ? '是' : '否' }}-->
      <!--      </head-search-item>-->
      <head-search-item label="发货方式:" v-if="info.enterWarehouseType !== '调拨' &&
      info.enterWarehouseState !== '代发' &&
      info.enterWarehouseState !== '其他'
      ">
        {{ info.deliveryMethod }}
      </head-search-item>
      <head-search-item label="付款方式:">
        {{ payWay(info.payWay) }}
      </head-search-item>
      <template v-if="info.enterWarehouseType !== '调拨' && info.enterWarehouseType !== '盘盈'">
        <head-search-item label="物流公司:">
          {{ info.logisticsCompany }}
        </head-search-item>
        <head-search-item label="物流单号:">
          {{ info.logisticsNumbers }}
          <el-popover v-if="info.logisticsNumbers" style="height: 500px;" width="500" trigger="click" title="物流详情">
            <div v-loading="popLoading" class="pop">
              <ul v-if="logisticsInfo.length > 0" style="margin-right:20px">
                <li v-for="(item, index) in logisticsInfo" :key="item.time">
                  <div class="time">
                    {{ index === 0 ? '最新' : '' }}{{ item.time }}
                  </div>
                  <div class="content">{{ item.context }}</div>
                </li>
              </ul>
            </div>
            <el-button @click="logisticsNumbersInfo" size="mini" class="logistics" type="primary" slot="reference">
              查看物流
            </el-button>
          </el-popover>
        </head-search-item>
      </template>
      <head-search-item label="制单人员:">
        {{ info.createdName }}
      </head-search-item>
      <head-search-item label="创建时间:">
        {{ info.createdTimestamp }}
      </head-search-item>
      <head-search-item label="入库时间:" v-if="info.enterWarehouseState === 2 || info.enterWarehouseState === 3">
        {{ info.enterWarehouseTimestamp }}
      </head-search-item>
    </head-search>
    <div class="remark">
      <span class="label">备注：</span>
      <span class="content">{{ info.remark }}</span>
    </div>
  </div>
</template>
<script>
import api from '@/api'
export default {
  //  入库状态;
  //  0 待入库
  //  1 入库中
  //  2 部分入库
  //  3 完成入库
  //  4 已终止
  //  5 代发全部入库
  //  6 调拨入库
  props: {
    info: {
      type: Object
    }
  },
  watch: {
    async info(val) {
      this.orderSource()
    }
  },
  data() {
    return {
      logisticsInfo: [],
      labelStyle: 'width:80px;text-align:left;font-weight:600',
      itemStyle:
        'width:300px;padding-bottom:20px;padding-top:20px;display: flex;align-items: baseline;',
      contentStyle: '',
      title: '',
      inSource: new Map(),
      popLoading: false
    }
  },
  methods: {
    payWay(e) {
      if (e == '0') {
        return '对公'
      } else if (e == '1') {
        return '备用金'
      } else if (e == '2') {
        return '备用金代付'
      } else if (e == '3') {
        return '张总'
      } else if (e == '4') {
        return '上海实体店备用金'
      } else if (e == '5') {
        return '现金'
      } else if (e == '6') {
        return '上海店现金'
      } else if (e == '7') {
        return '日本备用金'
      } else if (e == '8') {
        return '挂账'
      }else if (e == 9) {
        return '日币现金'
      }
      else if (e == 10) {
        return '法人信用卡'
      }
    },
    async orderSource() {
      const { body: data } = await api.enumeration_getList({ name: 'channel' })
      this.inSource = new Map()
      data.forEach(item => {
        this.inSource.set(item.id, item.keys)
      })
    },
    async logisticsNumbersInfo() {
      this.popLoading = true
      const data = await api.getLogistics({
        logisticsNumber: this.info.logisticsNumbers
      })
      this.popLoading = false
      this.logisticsInfo = data.body
      if (!this.logisticsInfo) {
        this.$message.error('没有获取到物流信息')
      }
    },
    toDetail(item) {
      this.$router.push({
        path: '/stockindetails',
        query: {
          id: item
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pop {
  width: 100%;
  height: 500px;
  overflow: auto;
}

.title {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #b8b8b81e;
  height: 30px;
  font-weight: bold;
}

.basic-item {
  width: 200px;
}

.head-search {
  margin-left: 20px;
}

::v-deep .content {
  font-size: 14px !important;
  margin-left: 10px;
}

.head-search-item {
  position: relative;
}

.trackShow {
  position: absolute;
  right: 0;
  top: 17px;
}

.reference {
  position: absolute;
  top: 15px;
  left: 90px;
}

.user {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  margin-top: 10px;
}

.user-item {
  display: flex;
}

.logistics {
  position: absolute;
  top: 17px;
  right: 20px;
}

::v-deep .text {
  padding: 0 !important;
}

.remark {
  position: relative;
  margin-top: 20px;
  left: 20px;
  width: 90%;
  right: 50px;
  margin-bottom: 20px;

  .label {
    width: 80px;
    text-align: left;
    font-weight: 600;
    font-size: 14px;
  }
}
</style>
<style>
.el-tooltip__popper {
  width: 300px !important;
}
</style>
