export default ({ request }) => ({
  // 分页查询
  classification_getPageList (data = {}) {
    return request({
      url: '/v1/classify/getPageList',
      method: 'post',
      data: data
    })
  },
  // 新增
  classification_add (data = {}) {
    return request({
      url: '/v1/classify/save',
      method: 'post',
      data: data
    })
  },
  // 查询子类
  classification_add_subclass (data = {}) {
    return request({
      url: '/v1/classify/getListByFatherId',
      method: 'post',
      data: data
    })
  },
  // 删除
  classification_delete (data = {}) {
    return request({
      url: '/v1/classify/delete',
      method: 'DELETE',
      data: data
    })
  },
  classification_update (data = {}) {
    return request({
      url: '/v1/classify/update',
      method: 'PUT',
      data: data
    })
  }

  //   // 编辑
  //   updateRow (data = {}) {
  //     return request({
  //       url: '/v1/brand/updateRow',
  //       method: 'put',
  //       data: data
  //     })
  //   },
  //   // 删除
  //   deleteRow (data = {}) {
  //     return request({
  //       url: '/v1/brand/deleteRow',
  //       method: 'delete',
  //       data: data
  //     })
  //   }
})
