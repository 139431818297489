<template>
    <d2-container class="all">
        <div class="content">
            <head-search label-style='width:100px;' class="top">
                <head-search-item class="topBox" label='盘库标题'><el-tag>{{ data.checkName }}</el-tag></head-search-item>
                <head-search-item class="topBox" label='盘库维度'>
                    <el-tag>
                        {{ data.dimension == 0 ? '商品维度' : data.dimension == 1 ? '货架维度' : '' }}</el-tag>
                </head-search-item>
                <head-search-item class="topBox" label='盘点仓库'><el-tag>{{ data.warehouseName }}</el-tag></head-search-item>
                <head-search-item class="topBox" label='盘库单号'><el-tag>{{ data.checkId }}</el-tag> </head-search-item>
                <head-search-item class="topBox" label='盘库状态'><el-tag>{{ almightyM(data.status, '盘库状态')
                }}</el-tag></head-search-item>
                <head-search-item class="topBox" label='盘库人员'><el-tag>{{ data.createdName }}</el-tag> </head-search-item>
                <head-search-item class="topBox" label='创建时间'><el-tag>{{ data.createdTimestamp
                }}</el-tag></head-search-item>
                <head-search-item class="topBox" label='完成时间'><el-tag>{{ data.endTime }}</el-tag> </head-search-item>
            </head-search>
            <div class="textarea" style="padding:15px;font-size: 14px;">
                盘库备注：
                <el-input type="textarea" disabled v-model="data.remark" placeholder=""></el-input>
            </div>
            <div class="title">
                <div>商品列表</div>
                <el-button type="primary" plain size="small">导出</el-button>
            </div>
            <el-table :data="data.dimension==0?data.checkGoodsList:data.inventoryList" :header-cell-style="headerClass" :cell-style="rowClass">
                <el-table-column prop="" label="序号">
                    <template slot-scope="scope">
                        {{ scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="goodsCoding" label="商品编码"></el-table-column>
                <el-table-column prop="goodsName" label="商品名称"></el-table-column>
                <el-table-column prop="pictureUrl" label="商品图片">
                    <template slot-scope="scope">
                        <img :src="downloadUrl + '/' + scope.row.pictureUrl"
                            style="width: 50px; height: 50px;margin-top: 8px;cursor: pointer" />
                    </template>
                </el-table-column>
                <el-table-column prop="intoPrices" label="入库价">
                    <template slot-scope="scope">
                        {{scope.row.intoPrice}}
                    </template>
                </el-table-column>
                <el-table-column prop="goodsNum" label="在库数量"></el-table-column>
                <el-table-column prop="checkNum" label="实际库存">
                    <template slot-scope="scope">
                        {{ scope.row.checkNum + (scope.row.scanNum?scope.row.scanNum:0) }}
                    </template>
                </el-table-column>
                <el-table-column prop="" label="盘点盈亏">
                    <template slot-scope="scope">
                        <el-tag :type="colorM(scope.row)"> {{ (scope.row.checkNum + (scope.row.scanNum?scope.row.scanNum:0)) - scope.row.goodsNum
                        }}</el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <div class="title">盘库结果</div>
            <div class="bottom">
                <div>待盘点数量:{{ Dnum }}</div>
                <div>已盘点数量:{{ Ynum }}</div>
                <div>盘盈:{{ Pwin }}</div>
                <div>盘亏:{{ Plose }}</div>
                <div class="textarea">
                    盈亏原因备注：
                    <el-input type="textarea" disabled v-model="data.checkRemark" placeholder=""></el-input>
                </div>
            </div>
            <el-button type="" @click="closeBtn()" size="small" style="margin:10px calc(50% - 30px)">关闭</el-button>
        </div>
    </d2-container>
</template>

<script>
import { mapActions } from 'vuex'
import api from '@/api'
export default {
    data() {
        return {
            tableData: [],
            form: {},
            id: null,
            data: {},
            downloadUrl: process.env.VUE_APP_DOWNLOAD_URL,
            Dnum: null,
            Ynum: null,
            Pwin: null,
            Plose: null,
        }
    },
    created() {
        this.id = this.$route.query.id
        this.initialize()
    },
    methods: {
        closeBtn(){
            const tagName = '/auditListParticulars?id=' +  this.$route.query.id
            this.$router.push({ path: '/auditList' })
            setTimeout(() => this.close({ tagName: tagName }), 10)
        },
        ...mapActions('d2admin/page', ['close']),
        colorM(e) {
            if (((e.checkNum + e.scanNum) - e.goodsNum) == 0) {
                return ''
            } else if (((e.checkNum + e.scanNum) - e.goodsNum) > 0) {
                return 'success'
            } else if (((e.checkNum + e.scanNum) - e.goodsNum) < 0) {
                return 'danger'
            }
        },
        async initialize() {
            const res = await api.getAffirmAuditParticulars({
                id: this.id
            })
            try {
                let list = []
                this.data = res.body
                if(res.body.dimension==0){
                    list = res.body.checkGoodsList
                }else if(res.body.dimension==1){
                    list = res.body.inventoryList
                }
                for (let i = 0; i < list.length; i++) {
                    this.Dnum += list[i].goodsNum
                    this.Ynum += (list[i].checkNum + (list[i].scanNum?list[i].scanNum:0))
                    this.Pwin += ((list[i].checkNum + (list[i].scanNum?list[i].scanNum:0)) - list[i].goodsNum) > 0 ? ((list[i].checkNum + (list[i].scanNum?list[i].scanNum:0)) - list[i].goodsNum) : 0
                    this.Plose += ((list[i].checkNum + (list[i].scanNum?list[i].scanNum:0)) - list[i].goodsNum) < 0 ? ((list[i].checkNum + (list[i].scanNum?list[i].scanNum:0)) - list[i].goodsNum) : 0
                }
            } catch (err) {

            }
        },
        almightyM(e, t) {
            if (t == '盘库状态') {
                switch (e) {
                    case 0: return '待盘库'
                    case 1: return '盘库中'
                    case 2: return '盘库完成'
                    case 9: return '终止盘库'
                    default: return ''
                }
            }
        },
        // table样式
        headerClass() {
            return "background: #eef1f6; color: #606266;text-align:center"
        },
        rowClass() {
            return "text-align:center"
        },
    }
}
</script>

<style lang='scss' scoped>
.all {
    padding: 30px;
}

.content {
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    border: 3px solid #888888;
    border-radius: 10px;
    overflow: auto;
    padding-bottom: 30px;
}

.top {
    display: flex;
    flex-wrap: wrap;

    .topBox {
        font-size: 12px;
        font-weight: bold;
        min-width: 200px;
        height: 50px;
    }
}

.title {
    width: calc(100% - 30px);
    height: 50px;
    background: #d7d7d7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    font-size: 15px;
    font-weight: bold;
}

.bottom {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    font-weight: bold;
    padding: 0 15px;

    div {
        min-width: 200px;
        margin-top: 20px;
    }

    .textarea {

        width: 100%;
    }
}
</style>