var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "good" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("span", [_vm._v("商品信息")]),
          _vm.info.status != 4 && _vm.permission.isStop === false
            ? _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "danger",
                    disabled: _vm.stopOutLoading,
                    loading: _vm.stopOutLoading
                  },
                  on: { click: _vm.stopOut }
                },
                [_vm._v(" 终止出库 ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              ref: "tableData",
              attrs: {
                "cell-style": { textAlign: "center" },
                data: _vm.goodsList,
                "header-cell-style": {
                  background: "#dfe6ec",
                  color: "#666666",
                  textAlign: "center"
                },
                "row-class-name": _vm.rowClassName,
                "row-style": { height: "57px" },
                "element-loading-text": "数据加载中",
                "max-height": "620px",
                "tooltip-effect": "dark"
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              (_vm.page.pageNo - 1) * _vm.page.pageSize +
                                scope.$index +
                                1
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "商品编码", prop: "goodsId" }
              }),
              _c("el-table-column", {
                attrs: { label: "商品名称", prop: "goodsName" }
              }),
              _c("el-table-column", {
                attrs: { label: "商品图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: {
                            "preview-src-list": [
                              _vm.$oss + scope.row.goodsPictureUrl
                            ],
                            src: _vm.$oss + scope.row.goodsPictureUrl,
                            fit: "cover"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm.infoStatus != 0 && _vm.infoStatus != 1 && _vm.infoStatus != 4
                ? _c("el-table-column", {
                    attrs: { label: "是否扫码", prop: "scanCode" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm.infoStatus == 2
                                ? _c(
                                    "div",
                                    [
                                      scope.row.scanCode == true
                                        ? _c(
                                            "el-tag",
                                            { attrs: { type: "success" } },
                                            [_vm._v("是")]
                                          )
                                        : scope.row.scanCode == false
                                        ? _c(
                                            "el-tag",
                                            { attrs: { type: "warning" } },
                                            [_vm._v("否")]
                                          )
                                        : scope.row.scanCode == null
                                        ? _c("span")
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.infoStatus == 3
                                ? _c(
                                    "div",
                                    [
                                      scope.row.scanCode == true
                                        ? _c(
                                            "el-tag",
                                            { attrs: { type: "success" } },
                                            [_vm._v("是")]
                                          )
                                        : scope.row.scanCode == false
                                        ? _c(
                                            "el-tag",
                                            { attrs: { type: "warning" } },
                                            [_vm._v("否")]
                                          )
                                        : _vm._e(),
                                      scope.row.scanCode == null
                                        ? _c("span")
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4285684802
                    )
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "入库价", prop: "recyclePrice" }
              }),
              _c("el-table-column", {
                key: "出库价",
                attrs: { label: "出库价", prop: "outPrice", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.info.status != 0 && _vm.info.status != 4
                          ? _c("el-input-number", {
                              attrs: {
                                disabled: !_vm.isEdit,
                                min: 0,
                                precision: 2,
                                size: "small",
                                type: "number"
                              },
                              model: {
                                value: scope.row.outPrice,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "outPrice", $$v)
                                },
                                expression: "scope.row.outPrice"
                              }
                            })
                          : _c("span", [_vm._v(_vm._s(scope.row.outPrice))])
                      ]
                    }
                  }
                ])
              }),
              !_vm.isStock
                ? [
                    _c("el-table-column", {
                      key: "数量",
                      attrs: { label: "数量", prop: "num" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "出库总计", prop: "total" }
                    })
                  ]
                : _vm._e(),
              _vm.isStock
                ? [
                    _c("el-table-column", {
                      attrs: { label: "条码", prop: "barCode", width: "180" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "区域名称", prop: "areaName" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "货架名称", prop: "shelvesName" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.shelvesName
                                        ? scope.row.shelvesName
                                        : "- -"
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        76229912
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "扫码人", prop: "scanName" }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "扫码时间",
                        prop: "scanTime",
                        width: "180"
                      }
                    })
                  ]
                : _vm._e(),
              _vm.info.status != 0 &&
              _vm.info.status != 4 &&
              _vm.info.distribution !== 4
                ? _c("el-table-column", {
                    key: "操作",
                    attrs: { label: "操作", width: "220" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("el-button", {
                                attrs: {
                                  icon: "el-icon-delete",
                                  size: "mini",
                                  type: "danger"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteGoods(scope.row)
                                  }
                                }
                              }),
                              !_vm.isEdit
                                ? _c("el-button", {
                                    attrs: {
                                      icon: "el-icon-edit",
                                      size: "mini",
                                      type: "primary"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.editGoods(scope.row)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm.isEdit
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "info" },
                                      on: {
                                        click: function($event) {
                                          return _vm.cancelEdit(
                                            scope.$index,
                                            scope.row
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" 取消 ")]
                                  )
                                : _vm._e(),
                              _vm.isEdit
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.keepEdit(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v(" 保存 ")]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4002012813
                    )
                  })
                : _vm._e()
            ],
            2
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.page.pageNo,
              "page-size": _vm.page.pageSize,
              "page-sizes": [10, 20, 30, 40, 50, 100],
              total: _vm.pageTotal,
              layout: "->,total, sizes, prev, pager, next, jumper"
            },
            on: {
              "size-change": _vm.sizeChange,
              "current-change": _vm.currentChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "退货",
            visible: _vm.tuihuoLog,
            top: "8vh",
            width: "400px"
          },
          on: {
            "update:visible": function($event) {
              _vm.tuihuoLog = $event
            },
            close: _vm.logClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "InfoForm",
              staticStyle: { display: "flex", "flex-wrap": "wrap" },
              attrs: { "label-width": "100px" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "退货备注" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.Ttextarea,
                      callback: function($$v) {
                        _vm.Ttextarea = $$v
                      },
                      expression: "Ttextarea"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "max-width": "200px",
                margin: "0 auto",
                "margin-top": "10px"
              }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.logClose()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    disabled: _vm.tuihuoBtn
                  },
                  on: {
                    click: function($event) {
                      return _vm.logSaveBtn()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }