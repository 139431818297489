<template>
  <!-- 如果内容比较多可以添加top属性 -->
  <d2-container>
    <el-form :inline="true" :model="selectForm">
      <el-form-item>
        <el-select :disabled="isSelect"  size="small"  v-model="selectForm.type" @change="typeChace" placeholder="请选择">
          <el-option
            v-for="item in enumList"
            :key="item.keys"
            :label="item.values"
            :value="item.keys">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
         <el-input  size="small" clearable :disabled="isSelect" v-model="selectForm.name" @keyup.enter.native="search()" placeholder="文件名称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button   size="small" type="primary" :disabled="isSelect" @click="search" icon="el-icon-search">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button  size="small" type="warning" :disabled="isSelect" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button  size="small" :disabled="isCreate" type="success" icon="el-icon-circle-plus-outline" @click="toAdd">新增</el-button>
      </el-form-item>
      <el-form-item>
        <el-button size="small" :disabled="isDelete" type="danger" @click="deleteBatch" icon="el-icon-delete">删除</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          width="160px"
          prop="url"
          label="文件地址">
          <template slot-scope="scope">
            <el-image v-if="scope.row.type == 'images'" style="width: 120px; height:120px;" :preview-src-list="[getImage(scope.row)]" lazy fit :src="getImage(scope.row)"></el-image>
            <div v-if="scope.row.type == 'video'" style="width: 160px; height:90px; position:relative;">
              <i @click="playVideo(scope.row)" class="el-icon-video-play" style="color:#ffffff;left:31%; top:22%; font-size:50px; position:absolute; z-index:2;"></i>
              <el-image lazy fit style="width: 160px; height:90px;" @click="playVideo(scope.row)" :src="getVideo(scope.row)"></el-image>
            </div>
            <el-button size="small" @click="downFile(scope.row)" plain v-if="scope.row.type == 'other'" type="primary">下载<i class="el-icon-download el-icon--right"></i></el-button>
            <el-button size="small" @click="openDoc(scope.row)" plain v-if="scope.row.type == 'doc'" type="primary">打开<i class="el-icon-folder-opened el-icon--right"></i></el-button>
            <el-button size="small" @click="playVideo(scope.row)" plain v-if="scope.row.type == 'audio'" type="primary">播放<i class="el-icon-video-play el-icon--right"></i></el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="文件名称">
          <template slot-scope="scope">
            <el-input clearable  size="small" style="width:200px;" v-model="scope.row.name" placeholder="请输入内容"></el-input>&nbsp;&nbsp;&nbsp;&nbsp;
            <el-button size="small" @click="updateName(scope.row)" type="primary" plain>保存<i class="el-icon-edit el-icon--right"></i></el-button>
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          prop="suffix"
          label="后缀">
        </el-table-column>
        <el-table-column
          width="150"
          prop="type"
          label="类型">
          <template slot-scope="scope">
            <el-tag type="success">{{ checkType(scope.row.type) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          width="180"
          prop="createTime"
          label="上传时间">
        </el-table-column>
        <el-table-column
          fixed="right"
          width="100"
          label="操作">
          <template slot-scope="scope">
            <el-button
              plain
              :disabled="isDelete"
              size="small"
              type="danger"
              @click="dalete(scope.$index, scope.row)" icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-div">
        <el-pagination
          :current-page="this.pageNo"
          :disabled="isSelect"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10,20,30,40,50,100]"
          layout="jumper, prev, pager, next,sizes"
          :total="pageTotal">
        </el-pagination>
      </div>
      <el-dialog
      :append-to-body="true"
        style="padding:10px;"
        :title="playTitle"
        :visible.sync="dialogPlay"
        width="40%"
        :close-on-click-modal="false"
        @close="closeDialog">
         <video :src="videoUrl" controls autoplay width="100%"></video>
      </el-dialog>
      <FileEdit ref="edit" :disabled="editDisabled" :dialogVisible="dialogVisible" @close="userEditClose" @initTableData="initTableData"></FileEdit>
  </d2-container>
</template>

<script>
import api from '@/api'
import FileEdit from '../edit/index'
export default {
  name: 'FileList',
  components: {
    FileEdit
  },
  data () {
    return {
      isSelect: this.checkauthority('select_file'),
      isCreate: this.checkauthority('select_add'),
      isUpdate: this.checkauthority('select_update'),
      isDelete: this.checkauthority('select_delete'),
      playTitle: '',
      dialogPlay: false,
      videoUrl: true,
      uploadUrl: process.env.VUE_APP_DOWNLOAD_URL,
      selectForm: {
        name: '',
        suffix: '',
        type: ''
      },
      editDisabled: false,
      dialogVisible: false,
      pageNo: 1,
      pageSize: 50,
      tableSelects: [],
      tableData: [],
      pageTotal: 0,
      centerDialogVisible: false,
      enumList: []
    }
  },
  methods: {
    openDoc (row) {
      var fileUrl = this.uploadUrl + row.url
      window.open('https://view.officeapps.live.com/op/view.aspx?src=' + fileUrl)
    },
    downFile (row) {
      var fileUrl = this.uploadUrl + row.url
      window.open(fileUrl)
    },
    getImage (row) {
      var url = row.url
      return this.uploadUrl + url
    },
    getVideo (row) {
      return this.uploadUrl + row.abbreviationImg
    },
    playVideo (row) {
      this.playTitle = row.name
      this.dialogPlay = true
      this.videoUrl = this.uploadUrl + row.url
    },
    closeDialog () {
      this.dialogPlay = false
      this.videoUrl = ''
    },
    typeChace (res) {
      this.pageNo = 1
      this.initTableData()
    },
    initEunmData () {
      this.getEnumList('file_type')
    },
    async getEnumList (name) {
      var par = {
        name: name
      }
      var res = await api.GET_ENUMERATION_LIST(par)
      this.enumList = res.body
    },
    checkType (type) {
      for (var res in this.enumList) {
        var item = this.enumList[res]
        if (item.keys === type) {
          return item.values
        }
      }
    },
    switchChange (value) {
      this.updateStats(value)
    },
    checkStatsType (data) {
      if (data.stats === 0) {
        return 'success'
      } else {
        return 'danger'
      }
    },
    checkStats (data) {
      if (data.stats === 0) {
        return '正常'
      } else {
        return '已注销'
      }
    },
    btnCheckStats (data) {
      if (data.stats === 0) {
        return '注销'
      } else {
        return '恢复'
      }
    },
    async updateStats (data) {
      var par = {
        id: data.id,
        stats: data.stats
      }
      await api.UPDATE_FILE(par)
      this.initTableData()
    },
    async updateApi (row) {
      await api.UPDATE_FILE(row)
      this.$message.success('变更成功')
    },
    updateName (row) {
      this.$confirm('变更名称, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.updateApi(row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消变更'
        })
      })
    },
    dalete (index, data) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.daleteApi(index, data)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async daleteApi (index, data) {
      var par = {
        fileId: data.fileId
      }
      await api.DELETE_FILE(par)
      this.initTableData()
    },
    async deleteBatchApi (par) {
      await api.DELETE_BATCH_FILE(par)
      this.$message.success('操作成功')
      this.initTableData()
    },
    deleteBatch () {
      if (this.tableSelects.length === 0) {
        this.$message.error('请勾选要删除的表格数据')
        return
      }
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var par = []
        this.tableSelects.forEach(element => {
          par.push(element.fileId)
        })
        this.deleteBatchApi(par)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    info (index, data) {
      this.editDisabled = true
      this.dialogVisible = true
      this.$refs.edit.setTitle('详情')
      this.$refs.edit.setData(data)
    },
    toAdd () {
      if (this.selectForm.type === '') {
        this.$message({
          type: 'info',
          message: '请选择类型'
        })
        return
      }
      this.$refs.edit.setTitle('新增')
      this.editDisabled = false
      this.dialogVisible = true
      this.$refs.edit.initData()
    },
    userEditClose () {
      this.dialogVisible = false
    },
    reset () {
      this.selectForm.name = ''
      this.selectForm.suffix = ''
      this.selectForm.type = ''
      this.tableData = []
    },
    search () {
      if (this.selectForm.type === '') {
        this.$message({
          type: 'info',
          message: '请选择类型'
        })
        return
      }
      this.pageNo = 1
      this.initTableData()
    },
    async initTableData () {
      var par = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        name: this.selectForm.name,
        suffix: this.selectForm.suffix,
        type: this.selectForm.type
      }
      const res = await api.GET_FILE_PAGE_LIST(par)
      this.tableData = res.body.datas
      this.pageTotal = res.body.sumDataCount
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.initTableData()
    },
    handleCurrentChange (val) {
      this.pageNo = val
      this.initTableData()
    },
    handleSelectionChange (val) {
      this.tableSelects = val
    }
  },
  created () {
    if (this.isSelect) {
      return
    }
    this.initEunmData()
  }
}
</script>
<style>
  .editBtn{
    width: 60px;
  }
  .statsTag {
    width: 60px;
    text-align: center;
  }
 .demo .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  /*打开时文字位置设置*/
  .demo .el-switch__label--right {
    z-index: 1;
    right: -4px;
 }
 /*关闭时文字位置设置*/
 .demo .el-switch__label--left {
    z-index: 1;
    left: 18px;
 }
 /*显示文字*/
 .demo .el-switch__label.is-active {
   display: block;
 }
 .demo.el-switch .el-switch__core,
 .el-switch .el-switch__label {
   width: 50px !important;
 }
 .el-dialog__body {
    padding: 10px 10px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
}
.el-dialog {
    position: relative;
    margin: 0 auto 30px;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,.3);
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
}
</style>
