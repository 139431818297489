/**
 * <角色请求接口
 * @author 王振宇
 * @since 2021-03-02
 */
export default ({ service, request, serviceForMock, requestForMock, mock, faker, tools }) => ({
  GET_ROLE_PAGE_LIST (data = {}) {
    return request({
      url: '/v1/role/getPageList',
      method: 'post',
      params: data
    })
  },
  GET_ROLE_LIST (data = {}) {
    return request({
      url: '/v1/role/getList',
      method: 'post',
      params: data
    })
  },
  GET_USER_ROLES (data = {}, params = {}) {
    return request({
      url: '/v1/role/userRoles',
      method: 'post',
      data: data,
      params: params
    })
  },
  GET_ROLE_INFO () {
    return request({
      url: '/v1/role/info',
      method: 'post'
    })
  },
  SAVE_ROLE (data = {}) {
    return request({
      url: '/v1/role/save',
      method: 'post',
      data: data
    })
  },
  UPDATE_ROLE (data = {}) {
    return request({
      url: '/v1/role/update',
      method: 'put',
      data: data
    })
  },
  DELETE_ROLE (data = {}) {
    return request({
      url: '/v1/role/delete',
      method: 'delete',
      data: data
    })
  },
  DELETE_BATCH_ROLE (data = {}) {
    return request({
      url: '/v1/role/deleteBatch',
      method: 'delete',
      data: data
    })
  }
})
