<template>
  <d2-container>
    <div class="goods">
      <div class="search">
        <head-search label-style="width:100px">
          <head-search-item label="商品编码">
            <el-input v-model.trim="search.goodsId" placeholder="请输入商品编码" size="small" @keyup.enter.native="Search"></el-input>
          </head-search-item>
          <head-search-item label="商品名称">
            <el-input v-model.trim="search.goodsName" placeholder="请输入商品名称" size="small"
              @keyup.enter.native="Search"></el-input>
          </head-search-item>
          <head-search-item label="仓库名称">
            <el-select v-model.trim="search.warehouseId" class="searchInput" clearable placeholder="请选择仓库名称" size="small">
              <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.warehouseName"
                :value="item.warehouseId">
              </el-option>
            </el-select>
          </head-search-item>
          <head-search-item label="入库价区间">
              <el-input v-model="search.startPrice" placeholder="请输入最低价格" size="small"></el-input>
          </head-search-item>
           <head-search-item label="--" label-style="width:30px">
              <el-input v-model="search.endPrice" placeholder="请输入最高价格" size="small"></el-input>
          </head-search-item>
          <!-- <head-search-item label="一级分类">
            <el-select
              multiple
              collapse-tags
              size="small"
              v-model="search.id1s"
              placeholder="请选择一级分类"
            >
              <el-option
                v-for="item in options[0]"
                :key="item.id"
                :label="item.classifyName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </head-search-item> -->
          <!-- <head-search-item label="二级分类">
            <el-select multiple collapse-tags size="small" v-model="search.id2s" placeholder="请选择二级分类">
              <el-option v-for="item in options[1]" :key="item.id" :label="item.classifyName" :value="item.id">
              </el-option>
            </el-select>
          </head-search-item>
          <head-search-item label="三级分类">
            <el-select multiple collapse-tags size="small" v-model="search.id3s" placeholder="请选择三级分类">
              <el-option v-for="item in options[2]" :key="item.id" :label="item.classifyName" :value="item.id">
              </el-option>
            </el-select>
          </head-search-item> -->
          <!-- <head-search-item label="商品品牌">
            <el-select
              multiple
              collapse-tags
              size="small"
              v-model="search.brands"
              placeholder="请选择商品品牌"
            >
              <el-option
                v-for="item in options[3]"
                :key="item.id"
                :label="item.brandName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </head-search-item> -->
          <!-- <head-search-item label="商品IP">
            <el-select
              multiple
              collapse-tags
              style="width: 90%"
              size="small"
              v-model="search.ips"
              placeholder="请选择商品IP"
            >
              <el-option
                v-for="item in options[4]"
                :key="item.id"
                :label="item.ipName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </head-search-item> -->
          <slot></slot>
        </head-search>
        <head-search label-style="width:100px">
          <template slot="button">
            <el-button icon="el-icon-search" size="small" type="primary" @click="Search" v-if="!permission.isSearch">
              搜索
            </el-button>
            <el-button icon="el-icon-refresh" size="small" type="warning" @click="Reset" v-if="!permission.isSearch">
              重置
            </el-button>
            <el-button :disabled="exportLoading" :loading="exportLoading" icon="el-icon-download" size="small"
              type="success" @click="exportExcel(true)" v-if="!permission.isExport">
              有图导出
            </el-button>
            <el-button :disabled="exportLoading1" :loading="exportLoading1" icon="el-icon-download" size="small"
              type="success" @click="exportExcel(false)" v-if="!permission.isExport">
              无图导出
            </el-button>
           
          </template>
        </head-search>
      </div>
      <div class="table">
        <el-table ref="goodsTable" v-loading="tableLoading" :cell-style="{ textAlign: 'center' }" :data="goodsTable"
          :header-cell-style="{
            background: '#dfe6ec',
            color: '#666666',
            textAlign: 'center'
          }" :row-style="{ height: '57px' }" element-loading-text="数据加载中" max-height="620px" tooltip-effect="dark"
          @sort-change="sortChange">
          <el-table-column align="center" label="序号">
            <template slot-scope="scope">
              {{ (page.pageNo - 1) * page.pageSize + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="商品编码" prop="id" width="120"></el-table-column>
          <el-table-column align="center" label="商品原ID" prop="goodsId" width="120">
            <template slot-scope="scope">
              {{ scope.row.goodsId && scope.row.goodsId != ' ' ? scope.row.goodsId : '- -' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="商品名称" prop="goodsName"></el-table-column>
          <el-table-column align="center" label="商品图片" prop="pictureUrl">
            <template slot-scope="scope">
              <el-image :preview-src-list="[$oss + scope.row.pictureUrl]" :src="$oss + scope.row.pictureUrl"
                style="width: 40px; height: 40px"></el-image>
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" label="一级分类" prop="id1Name"></el-table-column> -->
          <!-- <el-table-column align="center" label="二级分类" prop="id2Name"></el-table-column>
          <el-table-column align="center" label="三级分类" prop="id3Name"></el-table-column> -->
          <!-- <el-table-column align="center" label="商品品牌" width="300" prop="brandName"></el-table-column> -->
          <!-- <el-table-column align="center" width="200" label="商品IP" prop="ipName"></el-table-column> -->
          <!--          <el-table-column-->
          <!--            align="center"-->
          <!--            label="是否扫码"-->
          <!--            prop="small_trash"-->
          <!--          >-->
          <!--            <template slot-scope="scope">-->
          <!--              <el-tag :type="scope.row.small_trash === 1 ? 'success' : 'warning'">-->
          <!--                {{scope.row.small_trash === 1 ? '是':'否'}}-->
          <!--              </el-tag>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column align="center" label="入库价（元）" prop="price">
            <template slot-scope="scope">
              <el-popover placement="right" trigger="click" width="280">
                <el-table v-loading="priceLoading" :data="priceData" element-loading-text="数据加载中">
                  <el-table-column label="序号" width="70">
                    <template slot-scope="scope">
                      {{ scope.$index + 1 }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="价格" property="price"></el-table-column>
                  <el-table-column align="center" label="在库库存" property="inventoryNums"></el-table-column>
                </el-table>
                <div slot="reference" style="font-size: 10px;cursor: pointer;color: #1482f0;"
                  @click="getAllPrice(scope.row.id, scope.row.warehouseId)"><span
                    style="border-bottom: 1px solid #1482f0;">{{
                      scope.row.price
                    }}</span></div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column align="center" label="在库数量" prop="sumAll" sortable="custom"></el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button icon="el-icon-info" plain size="small" type="info" v-if="!permission.isBaseInfo"
                @click="toDetails(scope.row)">
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :current-page="page.pageNo" :page-size="page.pageSize" :page-sizes="[10, 20, 30, 40, 50, 100]"
          :total="pageTotal" layout="->,total, sizes, prev, pager, next, jumper" @size-change="sizeChange"
          @current-change="currentchange"></el-pagination>
      </div>
    </div>
  </d2-container>
</template>
<script>
import { downloadFileByUrl } from '@/utils/index'
import api from '@/api'
import *as getListM from '@/components/getListM'
const searchFormat = {
  brands: [],
  field: '',
  goodsId: '',
  goodsName: '',
  id1s: [],
  id2s: [],
  id3s: [],
  ips: [],
  sort: 0,
  warehouseId: null,
  startPrice:null,
  endPrice:null,
}
const Copy = value => {
  return JSON.parse(JSON.stringify(value))
}
export default {
  name: 'goods',
  data() {
    return {
      warehouseList: [],
      permission: {
        isSearch: this.checkauthority('inventory_page'), // 查询
        isExport: this.checkauthority('inventory_good_export'), // 导出
        // isGoodsInfo: this.checkauthority('inv_pro_details'), // 商品详情
        isBaseInfo: this.checkauthority('inv_pro_base_info')// 基本详情
      },
      page: {
        pageNo: 1,
        pageSize: 50
      },
      pageTotal: 0,
      search: Copy(searchFormat),
      varehouseTable: [1, 2, 3, 4],
      goodsTable: [],
      priceData: [],
      options: [],
      listPromise: [],
      tableLoading: false,
      priceLoading: false,
      exportLoading: false,
      exportLoading1:false,
    }
  },
  async mounted() {
    this.warehouseList = await getListM.getWarehouse()//仓库名称
  },
  methods: {
    Search() {
      this.search.pageNo = 1
      this.getList()
    },
    Reset() {
      this.search = Copy(searchFormat)
      this.getList()
    },
    async getList() {
      if(Number(this.search.startPrice)>Number(this.search.endPrice)){
        this.$message.error('最低价最好')
      }
      this.tableLoading = true
      const { body: data } = await api.varehousetable_getList_goods(
        Object.assign(this.search, this.page)
      )
      this.tableLoading = false
      this.pageTotal = data.sumDataCount
      this.goodsTable = data.datas
    },
    toDetails(row) {
      this.$router.push({
        path: '/varehouseGoodsDetails',
        query: {
          id: row.id
        }
      })
    },
    async getAllPrice(id, warehouseId) {
      this.priceData = []
      this.priceLoading = true
      try {
        const data = await api.inventory_selectPrice({
          goodsCodings: [id],
          warehouseId: ''
        })
        this.priceData = data.body[id]
      } catch (e) {
        this.$message.error('获取价格失败')
      } finally {
        this.priceLoading = false
      }
    },
    async exportExcel(i) {
     i==true?this.exportLoading = true:this.exportLoading1 = true
      try {
        const { body: data } = await api.inventory_storage_goodExport(
       {   ...this.search,
          picAddress:i}
        )
        downloadFileByUrl(data)
      } finally {
        this.exportLoading = false
        this.exportLoading1 = false
      }
    },
    // 获取分类
    getClass() {
      ;[1, 2, 3].forEach(e => {
        this.listPromise.push(api.getList_Classify({ classifyLevel: e }))
      })
      this.listPromise.push(api.selectAll_brand())
      this.listPromise.push(api.getListIp_ip())
    },
    sizeChange(val) {
      this.page.pageSize = val
      this.getList()
    },
    currentchange(val) {
      this.page.pageNo = val
      this.getList()
    },
    sortChange({
      order,
      prop
    }) {
      this.search.sort = order === 'ascending' ? 0 : 1
      this.search.field = prop
      this.getList()
    }
  },
  created() {
    this.getList()
    this.getClass()
    Promise.all([...this.listPromise]).then(e => {
      e.forEach(e => {
        this.options.push(e.body)
      })
    })
  }
}
</script>
<style lang="scss" scoped>
::v-deep .content {
  width: 200px !important;
}

::v-deep .el-select {
  width: 100% !important;
}

::v-deep .el-select__tags-text {
  display: inline-block;
  max-width: 100px;

  @media (max-width: 1400px) {
    max-width: 42px;
  }

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .el-select .el-tag__close.el-icon-close {
  top: -7px;
  right: -4px;
}

.table {
  margin-top: 10px;
}
</style>
