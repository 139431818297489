<template>
  <div class="orderInfo">
    <d2-container>
      <head-search label-style="width:100px">
        <head-search-item label="采购类型">
          <el-select
            v-model="selectForm.purchaseTypes"
            clearable
            collapse-tags
            multiple
            placeholder="请选择采购类型"
            size="small"
          >
            <el-option
              v-for="item in purchaseTypesData"
              :key="item.keys"
              :label="item.values"
              :value="item.keys"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="采购渠道">
          <el-select
            v-model="selectForm.channels"
            clearable
            collapse-tags
            multiple
            placeholder="请选择采购渠道"
            size="small"
          >
            <el-option
              v-for="item in channelData"
              :key="item.keys"
              :label="item.values"
              :value="item.keys"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="发货方式">
          <el-select
            v-model="selectForm.deliveryMethods"
            clearable
            collapse-tags
            multiple
            placeholder="请选择发货方式"
            size="small"
          >
            <el-option
              v-for="item in deliveryMethodsData"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="采购状态">
          <el-select
            v-model="selectForm.statusList"
            clearable
            collapse-tags
            multiple
            placeholder="请选择采购状态"
            size="small"
          >
            <el-option
              v-for="(item, index) in statusData"
              :key="index"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="采购人员">
          <el-select
            v-model="selectForm.createdIds"
            clearable
            collapse-tags
            multiple
            placeholder="请选择制单人员"
            size="small"
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </head-search-item>
        <template slot="button">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
          >
            搜索
          </el-button>
          <el-button
            icon="el-icon-refresh"
            size="small"
            type="warning"
            @click="reset"
          >
            重置
          </el-button>
          <el-button
            :disabled="excelLoading"
            :loading="excelLoading"
            icon="el-icon-download"
            size="small"
            type="success"
            @click="exportExcel"
          >
            导出
          </el-button>
        </template>
      </head-search>
      <div v-loading="loadingSum" class="showNum">
        <div class="font">
          采购金额 <span class="num">{{ this.sumData.price }}</span>
        </div>
        <div class="font">
          商品数量 <span class="num">{{ this.sumData.sum }}</span>
        </div>
        <div class="font">
          订单数量 <span class="num">{{ this.sumData.sumAll }}</span>
        </div>
      </div>
      <div class="table">
        <el-table
          ref="tableData"
          v-loading="tableLoading"
          :cell-style="{ textAlign: 'center' }"
          :data="orderInfoList"
          :header-cell-style="{
            background: '#dfe6ec',
            color: '#666666',
            textAlign: 'center'
          }"
          :row-style="{ height: '57px' }"
          element-loading-text="数据加载中"
          max-height="620px"
          tooltip-effect="dark"
        >
          <el-table-column align="center" label="序号" width="80">
            <template slot-scope="scope">
              {{
                (selectForm.pageNo - 1) * selectForm.pageSize + scope.$index + 1
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="采购单号"
            prop="purchaseNo"
            width="150"
          ></el-table-column>
          <el-table-column
            label="商品数量"
            prop="productNum"
            width="150"
          ></el-table-column>
          <el-table-column
            label="采购金额"
            prop="totalMoney"
            width="150"
          ></el-table-column>
          <el-table-column
            label="采购类型"
            prop="purchaseType"
            width="150"
          ></el-table-column>
          <el-table-column
            label="采购渠道"
            prop="channel"
            width="150"
          ></el-table-column>
          <el-table-column
            label="付款方式"
            prop="payMethod"
            width="150"
          >
            <template slot-scope="scope">
              {{ payWay(scope.row.payMethod) }}
            </template>
          </el-table-column>
          <el-table-column
            label="发货方式"
            prop="deliveryMethod"
            width="150"
          >
            <template slot-scope="scope">
              {{ scope.row.deliveryMethod == 0 ? '入库' : '代发' }}
            </template>
          </el-table-column>
          <el-table-column
            label="采购状态"
            prop="status"
            width="150"
          >
            <template slot-scope="scope">
              {{
                scope.row.status == 0 ? '待采购' : scope.row.status == 1 ? '采购中' : scope.row.status == 2 ? '已完成' : scope.row.status == 9 ? '已终止' : '- -'
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="公司名称"
            prop="companyName"
            width="150"
          ></el-table-column>
          <el-table-column
            label="部门名称"
            prop="deptName"
            width="150"
          ></el-table-column>
          <el-table-column
            label="仓库名称"
            prop="warehouseName"
            width="150"
          ></el-table-column>
          <el-table-column
            label="制单人员"
            prop="createdName"
            width="150"
          >
            <template slot-scope="scope">
              {{ scope.row.createdName ? scope.row.createdName : '- -' }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="selectForm.pageNo"
          :page-size="selectForm.pageSize"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :total="selectForm.pageTotal"
          layout="->,total, sizes, prev, pager, next, jumper"
          @size-change="sizeChange"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </d2-container>
  </div>
</template>

<script>
import api from '@/api'
import { downloadFileByUrl } from '@/utils/index.js'

const selectFormData = {
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0,
  purchaseTypes: [],
  channels: [],
  createdIds: [],
  date: '',
  deliveryMethods: [],
  field: '',
  sort: 0,
  statusList: []
}
const sumDataDefault = {
  sum: 0,
  sumAll: 0,
  price: 0
}
export default {
  name: 'warehouseStatisticalOrdersInfo',
  props: {},
  data () {
    return {
      selectForm: JSON.parse(JSON.stringify(selectFormData)),
      sumData: JSON.parse(JSON.stringify(sumDataDefault)),
      orderInfoList: [],
      purchaseTypesData: [],
      excelLoading: false,
      tableLoading: false,
      loadingSum: false,
      userList: [],
      channelData: [],
      statusData: [
        {
          id: 0,
          value: '待采购'
        },
        {
          id: 1,
          value: '采购中'
        },
        {
          id: 2,
          value: '已完成'
        }
        // {
        //   id: 9,
        //   value: '已终止'
        // }
      ],
      deliveryMethodsData: [
        {
          id: 0,
          value: '入库'
        },
        {
          id: 1,
          value: '代发'
        }
      ]
    }
  },
  computed: {},
  watch: {
    $route (to, from) {
      this.$route.query.date = to.query.date
      this.getList()
      this.getSum()
      this.getPurchaseChannel()
      this.getPurchaseSource()
    }
  },
  created () {
    this.$store.commit('d2admin/page/modifyOpend', opened => {
      opened.forEach((current) => {
        if (current.name === 'purchaseStatisticalOrderInfo') {
          current.meta.title = '采购统计-订单-' + current.query.date
        }
      })
    })
    this.getList()
    this.getSum()
    this.getPurchaseChannel()
    this.getPurchaseSource()
    this.getUser()
  },
  methods: {
    payWay(e){
      if(e==0){
        return '对公'
      }else if(e==1){
        return '备用金'
      }else if(e==2){
        return '备用金代付'
      }else if(e==3){
        return '张总'
      }else if(e==4){
        return '上海实体店备用金'
      } else if (e == 5) {
        return '现金'
      } else if (e == 6) {
        return '上海店现金'
      }else if (e == 7) {
        return '日本备用金'
      }else if (e == 8) {
        return '挂账'
      }else if (e == 9) {
        return '日币现金'
      }
      else if (e == 10) {
        return '法人信用卡'
      }else if (e == 11) {
        return '员工垫付'
      }else if (e == 12) {
        return '公司转账'
      }else if (e == 13) {
        return '公司转账'
      }
    },
    search () {
      this.selectForm.pageNo = 1
      this.getList()
      this.getSum()
      this.getUser()
    },
    // 获取采购类型
    async getPurchaseSource () {
      const res = await api.getPurchaseSelect({
        name: 'source'
      })
      const filterName = [49]
      this.purchaseTypesData =
        res.body.filter((item, index) => !filterName.includes(item.id))
    },
    // 获取用户
    async getUser () {
      const { body: data } = await api.statisticalUser()
      this.userList = data
    },
    // 获取采购渠道
    async getPurchaseChannel () {
      const res = await api.getPurchaseSelect({
        name: 'channel'
      })
      this.channelData = res.body
    },
    async getList () {
      try {
        const res = await api.infoPurchaseStatisticalOrder({
          pageNo: 1,
          pageSize: 50,
          createdIds: this.selectForm.createdIds,
          date: this.$route.query.date,
          purchaseTypes: this.selectForm.purchaseTypes,
          channels: this.selectForm.channels,
          deliveryMethods: this.selectForm.deliveryMethods,
          statusList: this.selectForm.statusList
        })
        this.orderInfoList = res.body.datas
        this.selectForm.pageNo = res.body.pageNo
        this.selectForm.pageSize = res.body.pageSize
        this.selectForm.pageTotal = res.body.sumDataCount
        console.log(res.body.datas)
      } catch (error) {
        this.$message.error('加载数据失败')
      } finally {
        this.tableLoading = false
      }
    },
    async getSum () {
      this.loadingSum = true
      try {
        const res = await api.infoPurchaseStatisticalOrderSum({
          pageNo: 1,
          pageSize: 50,
          createdIds: this.selectForm.createdIds,
          date: this.$route.query.date,
          purchaseTypes: this.selectForm.purchaseTypes,
          channels: this.selectForm.channels,
          deliveryMethods: this.selectForm.deliveryMethods,
          statusList: this.selectForm.statusList
        })
        this.sumData.price = res.body.price ? res.body.price : 0
        this.sumData.sumAll = res.body.sumAll ? res.body.sumAll : 0
        this.sumData.sum = res.body.sum ? res.body.sum : 0
      } catch (error) {
        this.$message.error('聚合统计加载失败')
        this.sumData.price = 0
        this.sumData.sumAll = 0
        this.sumData.sum = 0
      } finally {
        this.loadingSum = false
      }
    },
    reset () {
      this.selectForm = JSON.parse(JSON.stringify(selectFormData))
      this.getList()
      this.getSum()
    },
    sizeChange (val) {
      this.selectForm.pageSize = val
      this.getList()
      this.getSum()
    },
    currentChange (val) {
      this.selectForm.pageNo = val
      this.getList()
      this.getSum()
    },
    async exportExcel () {
      this.excelLoading = true
      try {
        const res = await api.exportPurOrder({
          purchaseTypes: this.selectForm.purchaseTypes,
          channels: this.selectForm.channels,
          createdIds: this.selectForm.createdIds,
          date: this.$route.query.date,
          deliveryMethods: this.selectForm.deliveryMethods,
          statusList: this.selectForm.statusList,
          pageNo: this.selectForm.pageNo,
          pageSize: this.selectForm.pageSize
        })
        downloadFileByUrl(res.body)
      } catch (e) {
        this.$message.error('导出报表失败')
      } finally {
        this.excelLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.orderInfo {
}

.showNum {
  width: 30%;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  display: flex;
  justify-content: space-around;
  line-height: 30px;
  border: 2px solid #999;
  border-radius: 5px;
}

.font {
  font-weight: bolder;
  font-size: 16px;
}

.num {
  margin-left: 20px;
  font-weight: normal;
}
</style>
