<template>
    <div>
        <el-dialog :append-to-body="true" :close-on-click-modal="false" title="新增商品" :visible.sync="dialogFormVisible"
            top="5vh" width="57%" @close="closeDialog()">
            <el-form ref="redactData" :model="redactData" :rules="rules" class="demo-ruleForm" label-width="100px">
                <div style="width: 100%">
                    <div class="class1">
                        <div style="width: 50%;display: flex;flex-direction:column">
                            <el-form-item label="商品名称" prop="goodsName">
                                <el-input v-model="redactData.goodsName" clearable maxlength="60" placeholder="请输入商品名称"
                                    style="width:200px"></el-input>
                            </el-form-item>
                            <el-form-item label="商品IP" prop="goodsIp">
                                <el-select v-model="redactData.goodsIp" clearable placeholder="请选择商品IP" style="width:200px">
                                    <el-option v-for="(item, index) in goodsIpData" :key="index" :label="item.ipName"
                                        :value="item.id"
                                        style="width:300px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="商品品牌" prop="goodsBrand">
                                <el-select id="goodsBrand" v-model="redactData.goodsBrand" clearable placeholder="请选择商品品牌"
                                    style="width:200px">
                                    <el-option v-for="(item, index) in goodsBrandData" :key="index" :label="item.brandName"
                                        :value="item.id"
                                        style="width:300px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;"></el-option>
                                </el-select>
                            </el-form-item>
                            <div style="display: flex;">
                                <el-form-item label="商品分类" prop="goodsOne">
                                    <el-select v-model="redactData.goodsOne" clearable placeholder="请选择商品分类"
                                        style="width:200px" @change="changeOneClass()">
                                        <el-option v-for="(item, index) in oneClassData" :key="index"
                                            :label="item.classifyName" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
                        <div style="width: 50%;display: flex;flex-direction:column">
                            <el-form-item label="商品图片" prop="pictureUrl" style="display: flex;">
                                <erp-upload-img v-model="redactData.pictureUrl" :maxCount="1" :showDelete="true"
                                    :upload-path="'/commodityManagement/commodity/'"></erp-upload-img>
                                <el-tooltip class="item tooltip" content="注：商品方图请上传宽为500，高为500的图片" effect="dark"
                                    placement="top-start">
                                    <i class="el-icon-info" style="color:#1890ff;margin-left: 20px;"></i>
                                </el-tooltip>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div style="margin-top:10px;margin-right: 10px;">
                    <el-form-item label="商品详情">
                        <erp-Editor ref="goodsDeatil"
                            :uploadPath="'/commodityManagement/goodsDeatil/'" :value="redactData.goodsDeatil"
                            editorStyle="text-align:left;margin: 21px;width: calc(100% - 20px);"
                            style="text-align: left"></erp-Editor>
                        <!-- <el-input type="textarea" v-loading="loadingTextarea" v-model="redactData.goodsDeatil" :rows="4"></el-input> -->
                    </el-form-item>
                </div>
                <div style="display: flex;justify-content: center;">
                    <el-button size="small" type="primary" @click="submitForm('redactData')"> 新增 </el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
// 新增,修改数据
const append = {
    id: '', // id
    goodsId: '', // 商品原ID
    goodsName: '', // 商品名称
    goodsOne: null, // 一级分类
    goodsTwo: null, // 二级分类
    goodsThree: null, // 三级分类
    goodsBrand: '', // 商品品牌
    goodsIp: '', // 商品Ip
    pictureUrl: [], // 商品图片
    pictureUrlString: '', // 商品图片字符串
    goodsDeatil: '' // 商品详情
}
import api from '@/api'
export default {
    data() {
        return {
            oneClassData: [],
            goodsBrandData: [],
            goodsIpData: [],
            dialogFormVisible: false,
            redactData: JSON.parse(JSON.stringify(append)),
            rules: {
                goodsName: [
                    {
                        required: true,
                        message: '请输入商品名称',
                        trigger: 'blur'
                    },
                    {
                        pattern: /^(?!\s+)/,
                        message: '首不能为空格',
                        trigger: 'blur'
                    },
                    {
                        required: true,
                        trigger: 'blur',
                        validator: (rule, value, callback) => {
                            this.validateCheckName(rule, value, callback, 'cover')
                        }
                    }
                ],
                goodsOne: [
                    {
                        required: true,
                        message: '请选择一级分类',
                        trigger: 'blur'
                    }
                ],
                goodsIp: [{
                    required: true,
                    message: '请选择商品IP',
                    trigger: 'blur'
                }],
                goodsBrand: [
                    {
                        required: true,
                        message: '请选择商品品牌',
                        trigger: 'blur'
                    }
                ],
                pictureUrl: [
                    {
                        required: true,
                        trigger: 'change',
                        validator: (rule, value, callback) => {
                            this.validatePicture(rule, value, callback, 'cover')
                        }
                    }
                ]
            }
        }
    },
    methods: {
        async open() {
            this.dialogFormVisible = true
            const res = await api.getList_Classify({
                classifyLevel: 1
            })
            if (res.code === 200) {
                this.oneClassData = res.body
            }
            // 品牌接口
            const _brand = await api.selectAll_brand({})
            if (_brand.code === 200) {
                this.goodsBrandData = _brand.body
            } else {
                this.$message({
                    type: 'danger',
                    message: '商品品牌加载失败!'
                })
            }
            // ip接口
            const _ip = await api.getListIp_ip({})
            if (_ip.code === 200) {
                this.goodsIpData = _ip.body
            } else {
                this.$message({
                    type: 'danger',
                    message: '商品品牌加载失败!'
                })
            }
        },
        // 图片校验
        validatePicture(rule, value, callback) {
            if (value.length === 0) {
                callback(new Error('图片为必选项'))
            } else {
                callback()
            }
        },
        async validateCheckName(rule, value, callback) {
            if (this.title === '新增') {
                const res = await api.goodsName({
                    goodsName: value
                })
                if (!res.body) {
                    callback(new Error('名字重复'))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        },
        // 富文本处理
        showHtml(str) {
            return (
                str
                    .replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
                    .replace(/&amp;nbsp;/g, '')
                    .replace(/&lt;/g, '<')
                    .replace(/&gt;/g, '>')
                    .replace(/&quot;/g, '"')
                    // eslint-disable-next-line no-useless-escape
                    .replace(/&#39;/g, '\'')
            )
        },
        // 新增,编辑关闭
        closeDialog() {
            this.dialogFormVisible = false
            this.redactData = JSON.parse(JSON.stringify(append))
            this.$refs.redactData.clearValidate()
            this.$refs.goodsDeatil.clearContent()
        },
        // 新增保存
        submitForm(formName) {
            this.$refs.redactData.validate(async valid => {
                if (valid) {
                    const res = await api.save_goods({
                        goodsBrand: this.redactData.goodsBrand,
                        goodsDeatil: this.$refs.goodsDeatil.getContent(),
                        goodsIp: this.redactData.goodsIp,
                        goodsName: this.redactData.goodsName,
                        id1: this.redactData.goodsOne,
                        id2: this.redactData.goodsTwo,
                        id3: this.redactData.goodsThree,
                        pictureUrl: this.redactData.pictureUrl[0].url
                    })
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: '新增成功!'
                        })
                        this.dialogFormVisible = false
                        this.$emit('func', true)
                    }
                } else {
                    // console.log('error submit!!')
                    return false
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.class1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
</style>
