/**
 * <菜单管理请求接口
 * @author 王振宇
 * @since 2021-03-04
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_MENU_PAGE_LIST: function GET_MENU_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/menu/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_USER_MENU_LIST: function GET_USER_MENU_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/menu/getUserMenuList',
        method: 'get',
        params: data
      });
    },
    GET_MENU_LIST: function GET_MENU_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/menu/getList',
        method: 'get',
        params: data
      });
    },
    GET_MENU_INFO: function GET_MENU_INFO() {
      return request({
        url: '/v1/menu/info',
        method: 'get'
      });
    },
    SAVE_MENU: function SAVE_MENU() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/menu/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_MENU: function UPDATE_MENU() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/menu/update',
        method: 'put',
        data: data
      });
    },
    DELETE_MENU: function DELETE_MENU() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/menu/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_MENU: function DELETE_BATCH_MENU() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/menu/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});