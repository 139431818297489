<template>
  <d2-container>
    <template slot="header">Page 3 header</template>
    Hello World
  </d2-container>
</template>

<script>
export default {
  name: 'page3'
}
</script>
