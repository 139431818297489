export default (function (_ref) {
  var request = _ref.request;
  return {
    // 查询枚举
    enumeration_getList: function enumeration_getList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/enumeration/getList',
        method: 'post',
        data: data
      });
    }
  };
});