<template>
  <div class="good">
    <div class="title">
      <span>
        {{
          info.enterWarehouseState === 3 && info.enterWarehouseType === '代发'
            ? '用户信息'
            : '入库明细'
        }}
      </span>
    </div>
    <div
      class="table"
      v-if="
        !(info.enterWarehouseState === 3 && info.enterWarehouseType === '代发')
      "
    >
      <el-table
        ref="tableData"
        :data="[info.detailSum]"
        max-height="620px"
        element-loading-text="数据加载中"
        :cell-style="{ textAlign: 'center' }"
        tooltip-effect="dark"
        :row-style="{ height: '57px' }"
        :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center'
        }"
      >
        <el-table-column label="入库总数" prop="totalNum"></el-table-column>
        <el-table-column
          label="已扫码数量"
          prop="sweepCodeRecordNum"
        ></el-table-column>
        <el-table-column
          label="未扫码数量"
          prop="withoutRecordNum"
        ></el-table-column>
        <el-table-column label="完好数量" prop="intactNum"></el-table-column>
        <!-- <el-table-column
          label="货损数量"
          prop="cargoDamageNum"
        ></el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="errorLog(scope.row)">
              异常详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="detailsTable">
        <el-dialog title="异常详情" :visible.sync="errorVisible" width="90%">
          <el-table
            ref="tableData"
            :data="errorList"
            max-height="620px"
            element-loading-text="数据加载中"
            :cell-style="{ textAlign: 'center' }"
            tooltip-effect="dark"
            :row-style="{ height: '57px' }"
            :header-cell-style="{
              background: '#dfe6ec',
              color: '#666666',
              textAlign: 'center'
            }"
          >
            <el-table-column label="序号">
              <template slot-scope="scope">
                {{ (page.pageNo - 1) * page.pageSize + scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              label="商品编码"
              prop="goodsCoding"
            ></el-table-column>
            <el-table-column
              label="商品名称"
              prop="goodsName"
            ></el-table-column>
            <el-table-column label="商品条码" prop="barCode"></el-table-column>
            <el-table-column
              label="处理结果"
              prop="dealStatus"
              :formatter="dealStatusFormatter"
            ></el-table-column>
            <el-table-column label="处理人" prop="disposeBy"></el-table-column>
            <el-table-column
              label="处理时间"
              prop="disposeTimestamp"
            ></el-table-column>
          </el-table>
          <el-pagination
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page.sync="page.pageNo"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            layout="->,total, sizes, prev, pager, next, jumper"
            :total="PageTotal"
          ></el-pagination>
        </el-dialog>
      </div>
    </div>
    <div class="table" v-else>
      <el-table
        :data="[info]"
        v-if="
          info.enterWarehouseState === 3 && info.enterWarehouseType === '代发'
        "
        max-height="620px"
        element-loading-text="数据加载中"
        :cell-style="{ textAlign: 'center' }"
        tooltip-effect="dark"
        :row-style="{ height: '57px' }"
        :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center'
        }"
      >
        <el-table-column
          label="收货姓名"
          prop="deliveryUserName"
        ></el-table-column>
        <el-table-column
          label="收货电话"
          prop="deliveryPhone"
        ></el-table-column>
        <el-table-column
          label="收货地址"
          prop="deliveryAddress"
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import api from '@/api'
export default {
  props: {
    info: {
      type: Object
    }
  },
  data () {
    return {
      detailsList: [],
      errorList: [],
      errorVisible: false,
      page: {
        pageNo: 1,
        pageSize: 50
      },
      PageTotal: 0
    }
  },
  methods: {
    async errorLog () {
      this.errorVisible = true
      this.getErrorDetails()
    },
    async getErrorDetails () {
      const { body: data } = await api.intoDetail_exceptionPageList(
        Object.assign(
          { enterWarehouseNumber: this.info.enterWarehouseNumber },
          this.page
        )
      )
      this.errorList = data.datas
      this.PageTotal = data.sumDataCount
    },
    sizeChange (val) {
      this.page.pageSize = val
      this.getErrorDetails()
    },
    currentChange (val) {
      this.page.pageNo = val
      this.getErrorDetails()
    },
    dealStatusFormatter (row, column, cellValue) {
      switch (cellValue) {
        case null:
          return '未处理'
        case 0:
          return '补发'
        case 1:
          return '退款'
        case 2:
          return '公司承担'
        case 3:
          return '分批到货'
        default:
          return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #b8b8b81e;
  height: 30px;
  font-weight: bold;
}
</style>
