<template>
  <d2-container>
    <!--       搜索框         -->
    <div style="width:100%;">
      <div class="searchSty">
        <el-date-picker
          v-model="search.timeStamp"
          :picker-options="pickerOptions"
          align="right"
          end-placeholder="结束日期"
          range-separator="至"
          size="small"
          start-placeholder="开始日期"
          type="daterange"
          unlink-panels
          value-format="yyyy-MM-dd"
        ></el-date-picker>
        <div class="searchBtn">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="toQuery()"
          >
            搜索
          </el-button>
          <el-button
            icon="el-icon-refresh"
            size="small"
            type="warning"
            @click="reset()"
          >
            重置
          </el-button>
        </div>
      </div>
    </div>
    <!--       统计数值       -->
    <div v-loading="loadingSum" class="numberSty">
      <div class="intNumSty" style="background-color: #66a9c9;">
        <div class="titleName">订单数量</div>
        <div class="num">{{ this.sumData.orderNum }}</div>
      </div>
      <div
        class="intNumSty"
        style="margin-left: 20px;background-color: #428675;"
      >
        <div class="titleName">商品数量</div>
        <div class="num">{{ this.sumData.productNum }}</div>
      </div>
      <div
        class="intNumSty"
        style="margin-left: 20px;background-color: #8c4b31;"
      >
        <div class="titleName">入库总金额</div>
        <div class="num">{{ this.sumData.totalIntoMoney }}</div>
      </div>
      <div
        class="intNumSty"
        style="margin-left: 20px;background-color: #9d3a21;"
      >
        <div class="titleName">出库总金额</div>
        <div class="num">{{ this.sumData.totalOutMoney }}</div>
      </div>
      <div
        class="intNumSty"
        style="margin-left: 20px;background-color: #fab09d;"
      >
        <div class="titleName">出入库差价</div>
        <div class="num">{{ setNum(this.sumData.totalOutMoney, this.sumData.totalIntoMoney)  }}</div>
      </div>
    </div>
    <!--       TableData      -->
    <div style="margin-top: 10px;">
      <el-table
        ref="tableData"
        v-loading="loadingTotal"
        :cell-style="{ textAlign: 'center' }"
        :data="tableData"
        :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center'
        }"
        :row-style="{ height: '57px' }"
        element-loading-text="数据加载中"
        max-height="645px"
        tooltip-effect="dark"
      >
        <el-table-column align="center" label="序号" width="80">
          <template slot-scope="scope">
            {{ (search.pageNo - 1) * search.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="日期"
          prop="date"
        ></el-table-column>
        <!--        <el-table-column-->
        <!--          align="center"-->
        <!--          label="订单数量"-->
        <!--          prop="orderNum"-->
        <!--        ></el-table-column>-->
        <el-table-column
          align="center"
          label="商品数量"
          prop="productNum"
        ></el-table-column>
        <el-table-column
          align="center"
          label="入库金额"
          prop="totalIntoMoney"
        ></el-table-column>
        <el-table-column
          align="center"
          label="出库金额"
          prop="totalOutMoney"
        ></el-table-column>
        <el-table-column label="出入库差价">
          <template slot-scope="scope">
            {{setNum(scope.row.totalOutMoney,scope.row.totalIntoMoney)}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              plain
              size="small"
              type="info"
              @click.native.prevent="
                orderDetails(scope.row, scope.$index, tableData)
              "
            >
              订单明细
            </el-button>
            <el-button
              plain
              size="small"
              type="primary"
              @click.native.prevent="
                goodsDetails(scope.row, scope.$index, tableData)
              "
            >
              商品明细
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--       分页      -->
    <div style="display:flex;justify-content:right;margin-top: 10px;">
      <el-pagination
        v-if="this.search.pageSize > 0"
        :current-page="search.pageNo"
        :disabled="permission.isShow"
        :page-size="search.pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :total="search.pageTotal"
        layout="total, jumper, prev, pager, next,sizes"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </d2-container>
</template>
<script>
// import api from '@/api'
import api from '@/api'

const searchDefault = {
  timeStamp: [],
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0
}
const sumDataDefault = {
  orderNum: 0,
  productNum: 0,
  totalIntoMoney: 0,
  totalOutMoney: 0
}
export default {
  name: 'purchaseStatistical',
  data () {
    return {
      // 权限点
      permission: {},
      tableData: [],
      orderNum: 20,
      goodsNum: 40,
      purchaseMoney: 99.85,
      loadingTotal: false,
      loadingSum: false,
      search: JSON.parse(JSON.stringify(searchDefault)),
      sumData: JSON.parse(JSON.stringify(sumDataDefault)),
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  created () {
    this.initList()
    this.getSum()
  },
  methods: {
    setNum(a,b){
      if(a&&b){
        return (Number(a) - Number(b)).toFixed(2)
      }else if(a&&!b){
        return (Number(a) - 0).toFixed(2)
      }else if(!a&&b){
        return (0 - Number(b)).toFixed(2)
      }else{
        return 0
      }
    },
    handleSizeChange (val) {
      this.search.pageSize = val
      this.initList()
    },
    handleCurrentChange (val) {
      this.search.pageNo = val
      this.initList()
    },
    // 初始化页面
    async initList () {
      this.loadingTotal = true
      try {
        const res = await api.getPageList_deliveryStatisticalList({
          startDate: this.search.timeStamp[0],
          endDate: this.search.timeStamp[1],
          pageNo: this.search.pageNo,
          pageSize: this.search.pageSize,
        })
        this.tableData = res.body.datas
        this.search.pageSize = res.body.pageSize
        this.search.pageNo = res.body.pageNo
        this.search.pageTotal = res.body.sumDataCount
      } catch (error) {
        this.$message.error('加载数据失败')
      } finally {
        this.loadingTotal = false
      }
    },
    // 聚合统计
    async getSum () {
      this.loadingSum = true
      try {
        const res = await api.getPageList_deliveryStatisticalSum({
          startDate: this.search.timeStamp[0],
          endDate: this.search.timeStamp[1],
          pageNo: this.search.pageNo,
          pageSize: this.search.pageSize
        })
        this.sumData.orderNum = res.body.orderNum ? res.body.orderNum : 0
        this.sumData.productNum = res.body.productNum ? res.body.productNum : 0
        this.sumData.totalIntoMoney = res.body.totalIntoMoney ? res.body.totalIntoMoney : 0
        this.sumData.totalOutMoney = res.body.totalOutMoney ? res.body.totalOutMoney : 0
      } catch (error) {
        this.$message.error('聚合统计数据加载失败')
      } finally {
        this.loadingSum = false
      }
    },
    // 搜索
    toQuery () {
      console.log(this.search)
      this.initList()
      this.getSum()
    },
    // 重置
    reset () {
      this.search.nextToken = null
      this.search.pageSize = 50
      this.search.pageNo = 1
      this.search.timeStamp = []
      this.initList()
      this.getSum()
    },
    // 订单明细
    async orderDetails (row) {
      await this.$router.push({
        path: '/deliveryStatisticalOrderInfo',
        query: { date: row.date }
      })
    },
    // 商品明细
    async goodsDetails (row) {
      await this.$router.push({
        path: '/deliveryStatisticalGoodsInfo',
        query: { date: row.date }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.searchSty {
  display: flex;
}

.searchBtn {
  margin-left: 20px;
}

.numberSty {
  width: 100%;
  // height: 80px;
  margin: 10px 10px 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.intNumSty {
  width: 250px;
  height: 80px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.titleName {
  font-weight: bold;
  font-size: 14px;
  // width: 250px;
  margin-top: 10px;
  text-align: center;
}

.num {
  font-weight: 1000;
  font-size: 24px;
  // width: 250px;
  margin-top: 10px;
  color: #fff;
  text-align: center;
}
</style>
