export default ({ request }) => ({
  // 分页查询
  getPageList_audit (data = {}) {
    return request({
      url: '/v1/audit/auditPage',
      method: 'post',
      data: data
    })
  }
})
