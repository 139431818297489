export default (function (_ref) {
  var request = _ref.request;
  return {
    // 获取退货列表
    getTHCHKBList: function getTHCHKBList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/newStatical/getReturn',
        method: 'post',
        data: data
      });
    },
    // 获取退货列表集合
    getNewStaticalGetNewStat: function getNewStaticalGetNewStat() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/newStatical/getNewStat',
        method: 'post',
        data: data
      });
    }
  };
});