<template>
  <div class="dialog">
    <el-dialog :title="titleName" :close-on-click-modal="false" :visible.sync="visibleDialog" width="92%"
      @close="dialogClose">
      <div class="title">
        <span>基本信息</span>
      </div>
      <el-form v-loading="infoLoading" :model="form" ref="form" inline :rules="rules" size="normal">
        <div class="form">
          <template class="showBox" v-if="titleName === '编辑入库单'">
            <el-form-item label="入库单号" label-width="80px" class="show">
              <el-input v-model="form.enterWarehouseNumber" size="small" disabled style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="制单人员" label-width="80px" class="show">
              <el-input v-model="form.createdName" size="small" disabled style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="创建时间" label-width="80px" class="show">
              <el-input v-model="form.createdTimestamp" size="small" disabled style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="入库状态" label-width="80px" class="show">
              <div style="width: 200px">
                {{
      form.enterWarehouseState === 0
        ? '待入库'
        : form.enterWarehouseState === 1
          ? '入库中'
          : form.enterWarehouseState === 2
            ? '部分入库'
            : form.enterWarehouseState === 3
              ? '完成入库'
              : form.enterWarehouseState === 9
                ? '已终止'
                : ''
    }}
              </div>
            </el-form-item>
            <el-form-item v-if="form.enterWarehouseType === '调拨'" label="调出仓库" class="show" label-width="80px">
              <el-select disabled clearable style="width: 200px" size="small" v-model="form.sourceWarehouseName">
                <el-option v-for="item in list.WarehousingWarehouse" :key="item.warehouseId" :label="item.warehouseName"
                  :value="item.warehouseId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="form.enterWarehouseType === '调拨'" label="仓库位置" label-width="80px" class="show">
              {{
      form.sameWarehouseFlag === 1
        ? '与入库仓库同一地点'
        : '不同地点（需扫码）'
    }}
            </el-form-item>
            <!-- 其他入库没有采购 -->
            <template class="purchase" v-if="form.enterWarehouseType !== '其他'">
              <el-form-item label="采购单号" v-if="judge != '盘盈'" label-width="80px" class="show">
                <el-input v-model="form.purchaseId" size="small" disabled style="width: 200px"></el-input>
              </el-form-item>
              <el-form-item label="采购类型" v-if="judge != '盘盈'" label-width="80px" class="show">
                <el-input v-model="form.purchaseType" size="small" disabled style="width: 200px"></el-input>
              </el-form-item>
              <!-- 调拨入库不存在采购渠道 -->
              <el-form-item v-if="form.enterWarehouseType !== '调拨' && judge != '盘盈'" label="采购渠道" label-width="80px"
                class="show">
                <el-input v-model="form.channel" size="small" disabled style="width: 200px"></el-input>
              </el-form-item>
            </template>
          </template>
          <el-form-item label="入库类型" label-width="80px" prop="enterWarehouseType">
            <el-select size="small" v-model="form.enterWarehouseType" placeholder="请选择入库类型" style="width: 200px"
              :disabled="true">
              <el-option v-for="item in list.WarehousingType" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="付款方式" v-if="form.enterWarehouseType !== '调拨' && judge !== '盘盈'" label-width="80px"
            prop="payWay">
            <el-select :disabled="titleName === '编辑入库单'" style="width: 200px" size="small" v-model="form.payWay"
              placeholder="请选择付款方式">
              <el-option v-for="item in list.PayWay" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="入库仓库" label-width="80px" prop="warehouseId">
            <el-select :disabled="titleName === '编辑入库单'" style="width: 200px" size="small" v-model="form.warehouseId"
              placeholder="请选择入库仓库">
              <el-option v-for="item in list.WarehousingWarehouse" :key="item.warehouseId" :label="item.warehouseName"
                :value="item.warehouseId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form.enterWarehouseType == '其他'" label="入库来源" label-width="80px"
            prop="enterWarehouseSource">
            <el-select :disabled="titleName === '编辑入库单'" clearable style="width: 200px" size="small"
              v-model="form.enterWarehouseSource" placeholder="请选择入库来源">
              <el-option v-for="item in list.warehouseSource" :key="item.id" :label="item.keys"
                :value="item.keys"></el-option>
            </el-select>
          </el-form-item>
          <!--          <el-form-item-->
          <!--            label="是否扫码"-->
          <!--            label-width="80px"-->
          <!--            prop="small_trash"-->
          <!--          >-->
          <!--            <el-radio-group v-model="form.small_trash">-->
          <!--              <el-radio :label="1">是</el-radio>-->
          <!--              <el-radio :label="0">否</el-radio>-->
          <!--            </el-radio-group>-->
          <!--          </el-form-item>-->
          <!-- 调拨入库不存在物流 -->
          <template v-if="form.enterWarehouseType !== '调拨' && judge != '盘盈'">
            <el-form-item clearable label="物流公司" label-width="80px" prop="trackingCompany">
              <el-input :disabled="isEdit" style="width: 200px" size="small" v-model="form.logisticsCompany"
                placeholder="请输入物流公司"></el-input>
            </el-form-item>
            <el-form-item clearable label="物流单号" label-width="80px" prop="logisticsNumbers" v-if="judge != '盘盈'">
              <el-input :disabled="isEdit" style="width: 200px" size="small" v-model="form.logisticsNumbers"
                placeholder="请输入物流单号"></el-input>
            </el-form-item>
          </template>
          <el-form-item v-if="Array.isArray(info.childNumbers) &&
      info.childNumbers.length > 0 &&
      titleName !== '新增入库单'
      " label="子订单号" label-width="80px">
            <div class="pop" style="width: 200px">
              <el-popover width="150" trigger="click">
                <el-button slot="reference" size="mini" type="primary">
                  查看
                </el-button>
                <el-table :data="form.childNumbers" size="mini" max-height="620px" element-loading-text="数据加载中"
                  :cell-style="{ textAlign: 'center' }" tooltip-effect="dark" :row-style="{ height: '57px' }"
                  :header-cell-style="{
      background: '#dfe6ec',
      color: '#666666',
      textAlign: 'center'
    }">
                  <el-table-column label="子订单号">
                    <template slot-scope="scope">
                      <el-button @click="toDetails(scope.row)" type="text">
                        {{ scope.row }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-popover>
            </div>
          </el-form-item>
          <el-form-item v-if="form.fatherId" label="主订单号" label-width="80px">
            <el-button @click="toDetails(form.fatherId)" type="text">
              {{ form.fatherId }}
            </el-button>
          </el-form-item>
        </div>
        <br />
        <div class="title">
          <span>商品信息</span>
          <div class="btn" v-if="form.enterWarehouseType !== '调拨'">
            <el-button :disabled="isEdit" type="success" size="small" @click="addRow">
              添加
            </el-button>
          </div>
        </div>
        <div class="table">
          <el-form-item prop="goodsList">
            <el-table ref="goodsList" :data="form.goodsList" max-height="620px" element-loading-text="数据加载中"
              :cell-style="{ textAlign: 'center' }" tooltip-effect="dark" :row-style="{ height: '57px' }"
              :header-cell-style="{
      background: '#dfe6ec',
      color: '#666666',
      textAlign: 'center'
    }">
              <el-table-column label="序号" key="index" type="index" width="80" align="center"></el-table-column>
              <el-table-column label="商品编码" key="goodsCoding" prop="goodsCoding" align="center"></el-table-column>
              <el-table-column label="商品名称" width="700" key="goodsName" prop="goodsName"
                align="center"></el-table-column>
              <el-table-column label="商品图片" key="pictureUrl" align="center">
                <template slot-scope="scope">
                  <el-image :src="$oss + scope.row.pictureUrl" :preview-src-list="[$oss + scope.row.pictureUrl]"
                    fit="cover" style="width: 50px; height: 50px"></el-image>
                </template>
              </el-table-column>
              <el-table-column label="采购数量" key="purchaseNums" align="center" v-if="titleName == '编辑入库单' &&
      form.enterWarehouseType === '采购'
      ">
                <template slot-scope="scope">
                  <el-form-item :prop="'goodsList.' + scope.$index + '.purchaseNums'" :rules="rules.purchaseNums">
                    <el-input-number :precision="0" :min="0" :disabled="isEdit" clearable type="number" size="small"
                      v-model="scope.row.purchaseNums" placeholder="请输入采购数量"></el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column width="150" label="入库价" align="center" key="enterWarehousePrices">
                <template slot-scope="scope">
                  <el-form-item :prop="'goodsList.' + scope.$index + '.enterWarehousePrices'
      " :rules="rules.enterWarehousePrices">
                    <el-input-number :min="0" :precision="2" :disabled="isEdit" clearable :step="0.01" type="number"
                      size="small" @input.native="changeInput($event)"
                      v-model="scope.row.enterWarehousePrices"></el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column width="150" label="入库数量" align="center" key="enterWarehouseNums">
                <template slot-scope="scope">
                  <el-form-item :prop="'goodsList.' + scope.$index + '.enterWarehouseNums'"
                    :rules="rules.enterWarehouseNums">
                    <el-input-number width="300px" :min="1" :precision="0" :disabled="isEdit" clearable type="number"
                      size="small" v-model="scope.row.enterWarehouseNums"></el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="总计" align="center" key="total">
                <template slot-scope="scope">
                  {{
      scope.row.enterWarehousePrices *
        scope.row.enterWarehouseNums
        ? (
          scope.row.enterWarehousePrices *
          scope.row.enterWarehouseNums
        ).toFixed(2)
        : 0
    }}
                </template>
              </el-table-column>
              <el-table-column key="delete" v-if="form.enterWarehouseType !== '采购'" fixed="right" label="操作">
                <template slot-scope="scope">
                  <el-button :disabled="isEdit" type="danger" size="mini" @click="deleteRow(scope.$index)">
                    删除
                  </el-button>
                  <el-button type="success" size="mini" @click="copyBtn(scope.row, scope.$index)">复制</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </div>
      </el-form>
      <div class="title">
        <span>统计</span>
      </div>
      <div class="count">
        <div class="count-item">
          入库数量：
          <span class="red">{{ count.number }}</span>
        </div>
        <div class="count-item">
          入库总计：
          <span class="red">{{ count.price.toFixed(2) }}</span>
        </div>
      </div>
      <div class="title">
        <span>备注</span>
      </div>
      <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" maxlength="250" v-model="form.remark"></el-input>
      <span slot="footer">
        <el-button size="small" @click="cancel">取消</el-button>
        <el-button :disabled="isEdit" size="small" type="primary" :loading="saveloading" @click="save">
          保存
        </el-button>
      </span>
      <SetGoodsList ref="setGoodsList" @setGoods="addGoods"></SetGoodsList>
    </el-dialog>
  </div>
</template>
<script>
import SetGoodsList from '@/components/setGoodsList/index.vue'
import { formData, ruleData } from '../list/contant'
import api from '@/api'

export default {
  name: 'Dialog',
  data() {
    return {
      judge: null,
      isEdit: false,
      info: [], // 点击的行信息
      deleList: [],
      titleName: '',
      visibleDialog: false,
      form: JSON.parse(JSON.stringify(formData)),
      list: {
        PayWay: [
          { label: '对公', value: '0' },
          { label: '备用金', value: '1' },
          { label: '备用金代付', value: '2' },
          { label: '张总', value: '3' },
          { label: '上海实体店备用金', value: '4' },
          { label: '现金', value: '5' },
          { label: '上海店现金', value: '6' },
          { label: '日本备用金', value: '7' },
          { label: '挂账', value: '8' },
          { label: '日币现金', value: '9' },
          { label: '法人信用卡', value: '10' },
          { label: '员工垫付', value: '11' },
          { label: '公司转账', value: '12' },
          { label: '人民币转账', value: '13' }
        ]
      },
      listMap: {},
      rules: ruleData,
      infoLoading: false,
      saveloading: false
    }
  },
  computed: {
    count() {
      let number = 0
      let price = 0
      if (this.form.goodsList) {
        this.form.goodsList.forEach(item => {
          number += Number(item.enterWarehouseNums)
          price +=
            Number(item.enterWarehousePrices) * Number(item.enterWarehouseNums)
        })
      }
      return {
        number: number,
        price: price
      }
    }
  },
  methods: {
    copyBtn(e, i) {
      let row = {
        goodsCoding: e.goodsCoding,
        enterWarehousePrices: '',
        enterWarehouseNums: '',
        goodsName: e.goodsName,
        pictureUrl: e.pictureUrl
      }
      this.form.goodsList.splice(i, 0, row)
    },
    toDetails(row) {
      this.$router.push({
        path: '/stockindetails',
        query: {
          id: row
        }
      })
    },
    // 编辑信息
    async getInfo() {
      this.infoLoading = true
      const { body: data } = await api.getWarehouseIntoInfo({
        enterWarehouseNumber: this.info.enterWarehouseNumber
      })
      this.infoLoading = false
      this.form = data
      this.form.small_trash = this.form.small_trash ? this.form.small_trash : 1
    },
    changeInput(e) {
      if (e.target.value.indexOf('.') >= 0) {
        e.target.value = e.target.value.substring(
          0,
          e.target.value.indexOf('.') + 3
        )
      }
    },
    open() {
      setTimeout(() => {
        this.$refs.form.clearValidate()
      })
      return {
        add: () => {
          this.visibleDialog = true
          this.titleName = '新增入库单'
          this.form.enterWarehouseType = '其他'
        },
        edit: row => {
          this.judge = row.enterWarehouseType
          this.info = row
          // 查询编辑信息
          this.getInfo()
          this.visibleDialog = true
          this.titleName = '编辑入库单'
          if (
            (this.info.enterWarehouseType === '采购' ||
              this.info.enterWarehouseType === '调拨') &&
            this.info.enterWarehouseState === 0
          ) {
            this.isEdit = true
          } else {
            this.isEdit = false
          }
        }
      }
    },
    setList(list) {
      this.list = Object.assign(
        {
          PayWay: [
            { label: '对公', value: '0' },
            { label: '备用金', value: '1' },
            { label: '备用金代付', value: '2' },
            { label: '张总', value: '3' },
            { label: '上海实体店备用金', value: '4' },
            { label: '现金', value: '5' },
            { label: '上海店现金', value: '6' },
            { label: '日本备用金', value: '7' },
            { label: '挂账', value: '8' },
            { label: '日币现金', value: '9' },
            { label: '法人信用卡', value: '10' },
            { label: '员工垫付', value: '11' },
            { label: '公司转账', value: '12' },
            { label: '人民币转账', value: '13' }
          ]
        },
        list
      )
      this.list.warehouseSource.forEach(e => {
        e.id = e.id.toString()
      })
    },
    // 保存按钮
    save() {
      console.log(this.form)
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.saveloading = true
            this.visibleDialog = false
            const param = JSON.parse(JSON.stringify(this.form))
            param.goodsList = [
              ...param.goodsList,
              ...JSON.parse(JSON.stringify(this.deleList))
            ]
            // 使用后清除
            this.deleList = []
            param.goodsList = param.goodsList.map(e => {
              return {
                enterWarehouseNums: e.enterWarehouseNums,
                enterWarehousePrices: e.enterWarehousePrices,
                goodsCoding: e.goodsCoding,
                delFlg: e.delFlg || 0,
                id: e.id || undefined,
                version: e.version || undefined
              }
            })
            let res
            if (this.titleName === '编辑入库单') {
              res = await api.warehouseIntoOrderUpdate(param)
            } else {
              res = await api.warehouseIntoOrderSave(param)
            }
            if (res.code === 200) {
              this.$message.success('保存成功')
            } else {
              this.$message.error(res.msg)
            }
          } catch (error) {
            this.$message.error('保存失败')
          } finally {
            this.saveloading = false
          }
        } else {
          this.$message.error('请填写完整信息')
        }
      })
    },
    // 取消按钮
    cancel() {
      this.visibleDialog = false
    },
    // 关闭弹窗事件
    dialogClose() {
      this.form = JSON.parse(JSON.stringify(formData))
      this.judge = null
      this.isEdit = false
      this.$emit('dialogClose')
    },
    deleteRow(index) {
      if (this.form.goodsList[index].id) {
        this.deleList.push(
          Object.assign({ delFlg: 1 }, this.form.goodsList[index])
        )
      }
      this.form.goodsList.splice(index, 1)
    },
    addRow(e) {
      if (!this.form.warehouseId) {
        this.$message.warning('请先选择仓库')
        return
      }
      this.$refs.setGoodsList.open(e)
      // addGoods()將會被調用
    },
    addGoods(data, e) {
      const res = data.map(e => {
        const r = {
          goodsCoding: e.goodsCoding,
          enterWarehousePrices: '',
          enterWarehouseNums: '',
          goodsName: e.goodsName,
          pictureUrl: e.pictureUrl
        }
        return r
      })
      this.form.goodsList.push(...res)
    }
  },
  components: { SetGoodsList }
}
</script>
<style lang="scss" scoped>
.title {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #b8b8b81e;
  height: 30px;
  font-weight: bold;
}

.count {
  display: flex;
  width: 100%;
  height: 50px;
}

.count-item {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.red {
  color: red;
}

.el-form-item {
  width: 280px;
  display: flex;
  margin-right: 5px;
  margin-bottom: 0px;
}

::v-deep .el-form-item__error {
  position: relative !important;
  padding-top: 0px;
  top: 0px;
}

.form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

::v-deep .show {
  input {
    border: 1px !important;
    background: #00000000 !important;
    color: black !important;
  }

  .el-select__caret {
    display: none;
  }
}

::v-deep .el-form-item {
  width: fit-content !important;
}

.table {
  width: 100%;
  overflow: auto;
}
</style>
