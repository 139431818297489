import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import _objectSpread from "/root/workspace/ERP-CMS_zdZH/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dayjs from 'dayjs';
import { mapActions } from 'vuex';
import localeMixin from '@/locales/mixin.js';
export default {
  mixins: [localeMixin],
  data: function data() {
    return {
      timeInterval: null,
      time: dayjs().format('HH:mm:ss'),
      // 快速选择用户
      dialogVisible: false,
      loadingFlag: false,
      users: [{
        name: 'Admin',
        username: 'admin',
        password: 'admin'
      }, {
        name: 'lptnyy',
        username: 'lptnyy',
        password: 'wangyang'
      }, {
        name: 'User1',
        username: 'user1',
        password: 'user1'
      }],
      // 表单
      formLogin: {
        username: '',
        password: '',
        code: 'v9am'
      },
      // 表单校验
      rules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.timeInterval = setInterval(function () {
      _this.refreshTime();
    }, 1000);
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timeInterval);
  },
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/account', ['login'])), {}, {
    refreshTime: function refreshTime() {
      this.time = dayjs().format('HH:mm:ss');
    },

    /**
     * @description 接收选择一个用户快速登录的事件
     * @param {Object} user 用户信息
     */
    handleUserBtnClick: function handleUserBtnClick(user) {
      this.formLogin.username = user.username;
      this.formLogin.password = user.password;
      this.submit();
    },

    /**
     * @description 提交表单
     */
    // 提交登录信息
    submit: function submit() {
      var _this2 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          // 登录
          // 注意 这里的演示没有传验证码
          // 具体需要传递的数据请自行修改代码
          _this2.loadingFlag = true;

          if (_this2.formLogin.username == '仓库达达') {
            _this2.loadingFlag = false;
            return;
          }

          _this2.login({
            username: _this2.formLogin.username,
            password: _this2.formLogin.password
          }).then(function () {
            _this2.$message.success('登录成功，即将跳转...');

            setTimeout(function () {
              // 重定向对象不存在则返回顶层路径
              _this2.loadingFlag = false;
              console.log(_this2.$route.query.redirect, 'this.$route.query.redirect'); // this.$router.replace(this.$route.query.redirect || '/')

              _this2.$router.replace('/');
            }, 1000);
          }).catch(function (_err) {
            _this2.$message.error('登录失败，请正确填写账号或密码');

            _this2.loadingFlag = false;
          });
        } else {
          _this2.loadingFlag = false; // 登录表单校验失败

          _this2.$message.error('请填写账号或密码');
        }
      });
    }
  })
};