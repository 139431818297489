var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "erp-select" },
    [
      _c(
        "el-select",
        {
          attrs: { size: "small", placeholder: "请选择" },
          on: { change: _vm.seleChange },
          model: {
            value: _vm.val,
            callback: function($$v) {
              _vm.val = $$v
            },
            expression: "val"
          }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }