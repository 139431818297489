<template>
  <div class="varehouseTable">
    <component :is="type">
      <el-button
        v-if="!permission.isCheck"
        class="button"
        type="success"
        size="small"
        @click="type = type === 'goods' ? 'stock' : 'goods'"
        >按{{ type === "goods" ? "库位" : "商品" }}显示</el-button
      >
    </component>
  </div>
</template>

<script>
import goods from './goods/index.vue'
import stock from './stock/index.vue'
export default {
  components: { goods, stock },
  name: 'varehouseTable',
  data () {
    return {
      permission: {
        isCheck: this.checkauthority('varehouseTable_check') // 查询
      },
      type: 'goods'
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  position: absolute;
  right: 10px;
}
</style>
