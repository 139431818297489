<template>
  <div class="setGoodsList">
    <el-drawer
      ref="drawer"
      :append-to-body="true"
      :before-close="handleClose"
      :visible.sync="drawerVisible"
      size="70%"
      title="请选择商品"
    >
      <!--       搜索框         -->
      <div style="width:100%;">
        <div class="search">
          <div class="search_one">
            <div class="label">仓库名称</div>
            <el-select
              filterable
              :disabled="isAreaIdDisabled"
              class="filter-item"
              v-model="search.warehouseId"
              placeholder="请选择仓库"
              size="small"
            >
              <el-option
                v-for="item in warehousingWarehouse"
                :key="item.warehouseId"
                :label="item.warehouseName"
                :value="item.warehouseId"
              ></el-option>
            </el-select>
          </div>
          <div class="search_one">
            <div class="label">商品编码</div>
            <el-input
              v-model="search.id"
              :disabled="permission.isSearch"
              class="filter-item"
              clearable
              placeholder="请输入商品编码"
              size="small"
              @keyup.enter.native="initList"
            />
          </div>
          <div class="search_one">
            <div class="label">商品名称</div>
            <el-input
              v-model="search.goodsName"
              :disabled="permission.isSearch"
              class="filter-item"
              clearable
              placeholder="请输入商品"
              size="small"
              @keyup.enter.native="initList"
            />
          </div>
          <div class="search_one">
            <div class="label">一级分类</div>
            <el-select
              v-model="search.id1s"
              :disabled="permission.isSearch"
              class="filter-item"
              clearable
              collapse-tags
              multiple
              placeholder="请选择一级分类"
              size="small"
            >
              <el-option
                v-for="(item, index) in oneClassData"
                :key="index"
                :label="item.classifyName"
                :value="item.id"
                v-bind:title="onHoverText"
                @mouseenter.native="onMouseOver(item.classifyName)"
              ></el-option>
            </el-select>
          </div>
<!--          <div class="search_one">-->
<!--            <div class="label">二级分类</div>-->
<!--            <el-select-->
<!--              v-model="search.id2s"-->
<!--              :disabled="permission.isSearch"-->
<!--              class="filter-item"-->
<!--              clearable-->
<!--              collapse-tags-->
<!--              multiple-->
<!--              placeholder="请选择二级分类"-->
<!--              size="small"-->
<!--            >-->
<!--              <el-option-->
<!--                v-for="(item, index) in twoClassData"-->
<!--                :key="index"-->
<!--                :label="item.classifyName"-->
<!--                :value="item.id"-->
<!--                v-bind:title="onHoverText"-->
<!--                @mouseenter.native="onMouseOver(item.classifyName)"-->
<!--              ></el-option>-->
<!--            </el-select>-->
<!--          </div>-->
<!--          <div class="search_one">-->
<!--            <div class="label">三级分类</div>-->
<!--            <el-select-->
<!--              v-model="search.id3"-->
<!--              :disabled="permission.isSearch"-->
<!--              class="filter-item"-->
<!--              clearable-->
<!--              collapse-tags-->
<!--              multiple-->
<!--              placeholder="请选择三级分类"-->
<!--              size="small"-->
<!--            >-->
<!--              <el-option-->
<!--                v-for="(item, index) in threeClassData"-->
<!--                :key="index"-->
<!--                :label="item.classifyName"-->
<!--                :value="item.id"-->
<!--                v-bind:title="onHoverText"-->
<!--                @mouseenter.native="onMouseOver(item.classifyName)"-->
<!--              ></el-option>-->
<!--            </el-select>-->
<!--          </div>-->

          <div class="search_one">
            <div class="label">商品品牌</div>
            <el-select
              v-model="search.brands"
              :disabled="permission.isSearch"
              class="filter-item"
              clearable
              collapse-tags
              multiple
              placeholder="请选择商品品牌"
              size="small"
            >
              <el-option
                v-for="(item, index) in goodsBrandData"
                :key="index"
                :label="item.brandName"
                :value="item.id"
                style="width:200px"
                v-bind:title="onHoverText"
                @mouseenter.native="onMouseOver(item.brandName)"
              ></el-option>
            </el-select>
          </div>

          <div class="search_one">
            <div class="label">商品IP</div>
            <el-select
              v-model="search.ips"
              :disabled="permission.isSearch"
              class="filter-item"
              clearable
              collapse-tags
              multiple
              placeholder="请选择商品IP"
              size="small"
            >
              <el-option
                v-for="(item, index) in goodsIpData"
                :key="index"
                :label="item.ipName"
                :value="item.id"
                style="width:200px"
                v-bind:title="onHoverText"
                @mouseenter.native="onMouseOver(item.ipName)"
              ></el-option>
            </el-select>
          </div>

          <div class="btn">
            <el-button
              :disabled="permission.isSearch"
              icon="el-icon-search"
              size="small"
              type="primary"
              @click="toQuery()"
            >
              搜索
            </el-button>
            <el-button
              :disabled="permission.isReset"
              icon="el-icon-refresh"
              size="small"
              type="warning"
              @click="reset()"
            >
              重置
            </el-button>
          </div>
        </div>
      </div>

      <!--       TableData      -->
      <div style="margin-top: 10px; margin-left: 10px; margin-right: 10px;">
        <el-table
          ref="tableData"
          v-loading="loadingTotal"
          :cell-style="{ textAlign: 'center' }"
          :data="tableData"
          :header-cell-style="{
            background: '#dfe6ec',
            color: '#666666',
            textAlign: 'center'
          }"
          :row-style="{ height: '57px' }"
          element-loading-text="数据加载中"
          max-height="660"
          tooltip-effect="dark"
          @selection-change="selectionChange"
        >
          <el-table-column
            align="center"
            label="选择"
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column align="center" label="序号" width="80">
            <template slot-scope="scope">
              {{ (search.pageNo - 1) * search.pageSize + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="商品编码"
            prop="id"
            width="150"
          >
            <template slot-scope="scope">
              <div
                style="display: flex;justify-content: space-between; width: 100%"
              >
                <div style="white-space: nowrap;width: 80%;">
                  {{ scope.row.id }}
                </div>
                <div class="copySty" @click="copyUrl(scope.row.id)">
                  复制
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center"  label="商品原ID" prop="goodsId">
          </el-table-column>
          <el-table-column
            align="center"
            label="商品名称"
            prop="goodsName"
            width="200"
          >
            <template slot-scope="scope">
              <div

              >
                {{ scope.row.goodsName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="商品图片"
            prop="pictureUrl"
            width="100"
          >
            <template slot-scope="scope">
              <el-image
                :preview-src-list="[downloadUrl + '/' + scope.row.pictureUrl]"
                :src="downloadUrl + '/' + scope.row.pictureUrl"
                alt=""
                style="width: 50px; height: 50px;margin-top: 8px;cursor: pointer"
                @click="getPreview(downloadUrl + '/' + scope.row.pictureUrl)"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column align="center" label="入库价" prop="price"  width="140">
          </el-table-column>
          <el-table-column
            align="center"
            label="在库数量"
            prop="sumAll"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            label=" 一级分类"
            prop="goodsOne"
            width="120"
          >
            <template slot-scope="scope">
              <div
                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
              >
                {{ scope.row.id1Name === null ? '- -' : scope.row.id1Name }}
              </div>
            </template>
          </el-table-column>
<!--          <el-table-column-->
<!--            align="center"-->
<!--            label="二级分类"-->
<!--            prop="goodsTwo"-->
<!--            width="120"-->
<!--          >-->
<!--            <template slot-scope="scope">-->
<!--              <div-->
<!--                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"-->
<!--              >-->
<!--                {{ scope.row.id2Name === null ? '- -' : scope.row.id2Name }}-->
<!--              </div>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            label="三级分类"-->
<!--            prop="goodsThree"-->
<!--            width="120"-->
<!--          >-->
<!--            <template slot-scope="scope">-->
<!--              <div-->
<!--                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"-->
<!--              >-->
<!--                {{-->
<!--                  scope.row.id3Name === null ? '- -' : scope.row.id3Name-->
<!--                }}-->
<!--              </div>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column
            align="center"
            label="商品品牌"
            prop="brandName"
            width="120"
          >
            <template slot-scope="scope">
              {{ scope.row.brandName ? scope.row.brandName : '- -' }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="商品IP"
            prop="ipName"
            width="120"
          >
            <template slot-scope="scope">
              {{ scope.row.ipName ? scope.row.ipName : '- -' }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--       分页           -->
      <div
        style="display:flex;justify-content:right;margin-top: 10px;justify-content: space-between;"
      >
        <el-button class="submit" size="small" type="primary" @click="submit">
          确认
        </el-button>
        <el-pagination
          v-if="this.search.pageSize > 0"
          :current-page="search.pageNo"
          :page-sizes="[10,20,30,40,50,100]"
          :page-size="search.pageSize"
          :total="search.pageTotal"
          layout="total, jumper, prev, pager, next,sizes"
          @size-change="sizeChange"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </el-drawer>
  </div>
</template>
<script>
// 搜索数据
import api from '@/api'

const searchDefault = {
  id: '',
  warehouseId: '',
  goodsName: '',
  id1s: [],
  id2s: [],
  id3s: [],
  brands: [],
  ips: [],
  pageNo: 1,
  pageSize: 100,
  pageTotal: 0
}
export default {
  data () {
    return {
      permission: {
        isSearch: this.checkauthority('goods_select') // 搜索
      },
      search: { ...searchDefault },
      tableData: [],
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL,
      dialogVisible: false,
      oneClassData: [],
      twoClassData: [],
      threeClassData: [],
      goodsBrandData: [],
      goodsIpData: [],
      warehousingWarehouse: [],
      onHoverText: '',
      drawerVisible: false,
      isAreaIdDisabled: false,
      data: {},
      selection: []
    }
  },
  created () {
    this.initList()
    this.warehouse()
  },
  methods: {
    sizeChange (val) {
      this.search.pageSize = val
      this.initList()
    },
    currentChange (val) {
      this.search.pageNo = val
      this.initList()
    },
    onMouseOver (item) {
      this.onHoverText = item
    },
    open (areaId) {
      this.isAreaIdDisabled = areaId ? true : false
      this.search.warehouseId = areaId
      this.drawerVisible = true
      this.initList()
    },
    // 获得所有仓库
    async warehouse () {
      const { body: data } = await api.getPurchaseWarehouse()
      this.warehousingWarehouse = data
    },
    async initList () {
      this.loadingTotal = true
      // 分类接口
      for (let i = 1; i < 4; i++) {
        const res = await api.getList_Classify({
          classifyLevel: i
        })
        if (res.code === 200) {
          if (i === 1) {
            this.oneClassData = res.body
          } else if (i === 2) {
            this.twoClassData = res.body
          } else if (i === 3) {
            this.threeClassData = res.body
          }
        } else {
          this.$message({
            type: 'danger',
            message: '分类菜单加载失败!'
          })
        }
      }
      // 品牌接口
      const _brand = await api.selectAll_brand({})
      if (_brand.code === 200) {
        this.goodsBrandData = _brand.body
      } else {
        this.$message({
          type: 'danger',
          message: '商品品牌加载失败!'
        })
      }
      // ip接口
      const _ip = await api.getListIp_ip({})
      if (_ip.code === 200) {
        this.goodsIpData = _ip.body
      } else {
        this.$message({
          type: 'danger',
          message: '商品品牌加载失败!'
        })
      }
      const _goods = await api.varehousetable_getList_goods(this.search)
      // console.log(_goods)
      if (_goods.code === 200) {
        // console.log(_goods.datas)
        this.tableData = _goods.body.datas
        this.search.pageTotal = _goods.body.sumDataCount
        this.loadingTotal = false
      } else {
        this.$message({
          type: 'danger',
          message: '页面加载失败!'
        })
        this.loadingTotal = false
      }
    },
    toQuery () {
      this.initList()
    },
    reset () {
      this.search = { ...searchDefault }
      this.initList()
    },
    handleClose () {
      this.$refs.tableData.clearSelection()
      this.drawerVisible = false
    },
    // /定义点击复制的功能
    copyUrl (url) {
      // 创建一个 Input标签
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      // 执行浏览器复制命令
      // / 复制命令会将当前选中的内容复制到剪切板中
      // / 如这里构建的 Input标签
      document.execCommand('Copy')
      this.$message({
        message: '复制成功',
        type: 'success'
      })
      // /复制成功后再将构造的标签 移除
      oInput.remove()
    },
    selectionChange (val) {
      this.selection = val
    },
    submit () {
      this.$emit('setGoods', this.selection, this.data)
      this.drawerVisible = false
      this.$refs.drawer.closeDrawer()
    },
  }
}
</script>
<style lang="scss" scoped>
.search {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  position: relative;

  .search_one {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;

    .label {
      white-space: nowrap;
      font-size: 14px;
      margin-right: 10px;
      margin-left: 10px;
      width: 70px;
      text-align: center;
    }
  }
}

.btn {
  display: inline-block;
  position: absolute;
  right: 45px;
  bottom: 10px;
}

.el-select {
  width: 100%;
}

.copySty {
  font-size: 10px;
  cursor: pointer;
  color: #1482f0;
}

.submit {
  margin-left: 10px;
}

::v-deep .el-select__tags-text {
  display: inline-block;
  max-width: 100px;

  @media (max-width: 1400px) {
    max-width: 42px;
  }

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .el-select .el-tag__close.el-icon-close {
  top: -7px;
  right: -4px;
}
</style>
