<template>
    <d2-container>
        <head-search label-style='width:100px'>
            <head-search-item label='采购单号'>
                <el-input v-model="header.purchaseNum" clearable placeholder="请输入采购单号" size="small"></el-input>
            </head-search-item>
            <head-search-item label='仓库名称'>
                <el-select multiple v-model="header.warehouseId" clearable placeholder="请选择仓库名称" size="small">
                    <el-option v-for="(item, index) in warehouseList" :key="item.warehouseId" :label="item.warehouseName"
                        :value="item.warehouseId">
                    </el-option>
                </el-select>
            </head-search-item>
            <head-search-item label='操作人'>
                <el-select multiple v-model="header.operator" clearable placeholder="请选择操作人" size="small">
                    <el-option v-for="(item, index) in operatorList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </head-search-item>
            <head-search-item label='创建时间'>
                <el-date-picker v-model="header.time" end-placeholder="结束日期" range-separator="至" size="small"
                    start-placeholder="开始日期" type="daterange" value-format="yyyy-MM-dd"></el-date-picker>
            </head-search-item>
            <head-search-item style="margin-left:10px">
                <el-button icon="el-icon-search" size="small" type="primary" @click="toQuery()"
                    v-if="!permission.isSelectDept">搜索</el-button>
                <el-button icon="el-icon-refresh" size="small" type="warning" @click="resetBtn()"
                    v-if="!permission.isSelectDept">重置</el-button>
            </head-search-item>
        </head-search>
        <el-table :data="tableData" :header-cell-style="headerClass" :cell-style="rowClass" v-loading="loading">
            <!-- <el-table-column label="序号"></el-table-column> -->
            <el-table-column prop="enterWarehouseNumber" label="采购单号"></el-table-column>
            <el-table-column prop="refundProve" label="退货截图">
                <template slot-scope="scope">
                    <el-image :src="downloadUrl + '/' + scope.row.refundProve" alt=""
                        :preview-src-list="[downloadUrl + '/' + scope.row.refundProve]"
                        style="width: 50px; height: 50px;margin-top: 8px;cursor: pointer"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="sum" label="退货数量"></el-table-column>
            <el-table-column prop="sumPrice" label="退货金额"></el-table-column>
            <el-table-column prop="warehouseName" label="仓库名称"></el-table-column>
            <el-table-column prop="createdName" label="操作人"></el-table-column>
            <el-table-column prop="updateTimestamp" label="操作时间"></el-table-column>
            <el-table-column prop="" label="明细">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" @click.native.prevent="detail(scope.row)" v-if="!permission.isdetailDept">明细</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--      分页      -->
        <div style="display: flex; justify-content: right; margin-top: 10px">
            <el-pagination v-if="search.pageSize > 0" :current-page="search.pageNo" :page-size="search.pageSize"
                :page-sizes="[10, 20, 30, 40, 50, 100]" :total="search.pageTotal"
                layout="total, jumper, prev, pager, next,sizes" @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
            </el-pagination>
        </div>
    </d2-container>
</template>

<script>
import api from '@/api'
const headerSource = {
    purchaseNum: null,
    warehouseId: [],
    operator: [],
    time: [],
}
const searchSource = {
    pageNo: 1,
    pageSize: 50,
    pageTotal: 0,
}
export default {
    data() {
        return {
            // 权限点
            permission: {
                isSelectDept: this.checkauthority('get_purchase_th_list'), // 查看权限
                isdetailDept: this.checkauthority('get_purchase_th_list_detail'), // 查看详情  
            },
            downloadUrl: process.env.VUE_APP_DOWNLOAD_URL,
            loading: false,
            header: JSON.parse(JSON.stringify(headerSource)),
            search: JSON.parse(JSON.stringify(searchSource)),
            warehouseList: [],
            operatorList: [],
            tableData: [],
        }
    },
    created() {
        this.getWarehouse()
        this.getPurchaseUser()
        this.inie()
    },
    methods: {
        detail(e) {
            console.log(1);
            this.$router.push({
                path: '/purchaseThListDetail',
                query: { enterWarehouseNumber: e.enterWarehouseNumber }
            })
        },
        async inie() {
            this.loading = true
            const res = await api.getPurchaseThListList({
                "createdIds": this.header.operator,
                "endTime": this.header.time.length > 0 ? this.header.time[1] : null,
                "enterWarehouseNumber": this.header.purchaseNum,
                "field": "",
                "pageNo": this.search.pageNo,
                "pageSize": this.search.pageSize,
                "sort": 0,
                "startTime": this.header.time.length > 0 ? this.header.time[0] : null,
                "warehouseIds": this.header.warehouseId
            })
            try {
                this.tableData = res['body']['datas'] ? res['body'] : []
                this.search.pageSize = res.body.pageSize
                this.search.pageNo = res.body.pageNo
                this.search.pageTotal = res.body.sumDataCount ? res.body.sumDataCount : 0
            } catch (err) {

            } finally {
                this.loading = false
            }
        },
        toQuery() {
            this.search = JSON.parse(JSON.stringify(searchSource))
            this.inie()
        },
        resetBtn() {
            this.search = JSON.parse(JSON.stringify(searchSource))
            this.header = JSON.parse(JSON.stringify(headerSource))
            this.inie()
        },
        // 获取仓库列表
        async getWarehouse() {
            const { body: data } = await api.getPurchaseWarehouse()
            console.log(data, '仓库');
            this.warehouseList = data
        },
        // 获得操作人列表
        async getPurchaseUser() {
            const res = await api.getPurchaseUser()
            console.log(res, '操作人');
            this.operatorList = res.body
        },
        handleSizeChange(val) {
            this.search.pageSize = val
            this.inie()
        },
        handleCurrentChange(val) {
            this.search.pageNo = val
            this.inie()
        },
        // table样式
        headerClass() {
            return "background: #eef1f6; color: #606266;text-align:center"
        },
        rowClass() {
            return "text-align:center"
        },
    }
}
</script>

<style lang='scss' scoped></style>