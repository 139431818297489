// 库存列表
export default (function (_ref) {
  var request = _ref.request;
  return {
    inventoryStatisticalGetPage: function inventoryStatisticalGetPage() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventoryPrice/invStatical',
        method: 'post',
        data: data
      });
    } // 按库位维度导出excel
    // inventoryStatisticalExport (data = {}) {
    //   return request({
    //     url: 'yyyyy',
    //     method: 'post',
    //     data: data
    //   })
    // },

  };
});