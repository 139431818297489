export default ({ request }) => ({
  // 查询枚举
  enumeration_getList (data = {}) {
    return request({
      url: '/v1/enumeration/getList',
      method: 'post',
      data: data
    })
  }
})
