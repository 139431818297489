var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      _c(
        "el-dialog",
        {
          ref: "form",
          attrs: {
            "close-on-click-modal": false,
            title: _vm.titleName,
            visible: _vm.visibleDialog,
            width: "90%"
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleDialog = $event
            },
            close: _vm.dialogClose
          }
        },
        [
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v("基本信息")])
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                inline: "",
                size: "normal"
              }
            },
            [
              _c(
                "div",
                { staticClass: "form" },
                [
                  _vm.titleName === "编辑出库单"
                    ? [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "show",
                            attrs: { label: "出库单号", "label-width": "100px" }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: { disabled: "", size: "small" },
                              model: {
                                value: _vm.form.outId,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "outId", $$v)
                                },
                                expression: "form.outId"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "show",
                            attrs: { label: "出库状态", "label-width": "100px" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "200px" },
                                attrs: { disabled: "", size: "small" },
                                model: {
                                  value: _vm.form.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "status", $$v)
                                  },
                                  expression: "form.status"
                                }
                              },
                              _vm._l(_vm.list.outStatus, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "show",
                            attrs: { label: "制单人员", "label-width": "100px" }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: { disabled: "", size: "small" },
                              model: {
                                value: _vm.form.createdName,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "createdName", $$v)
                                },
                                expression: "form.createdName"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "show",
                            attrs: { label: "创建时间", "label-width": "100px" }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: { disabled: "", size: "small" },
                              model: {
                                value: _vm.form.createdTimestamp,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "createdTimestamp", $$v)
                                },
                                expression: "form.createdTimestamp"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.form.type === 1
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "show",
                                  attrs: {
                                    label: "系统订单号",
                                    "label-width": "100px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: { disabled: "", size: "small" },
                                    model: {
                                      value: _vm.form.orderId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "orderId", $$v)
                                      },
                                      expression: "form.orderId"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "show",
                                  attrs: {
                                    label: "主订单号 ",
                                    "label-width": "100px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: { disabled: "", size: "small" },
                                    model: {
                                      value: _vm.form.outId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "outId", $$v)
                                      },
                                      expression: "form.outId"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "show",
                                  attrs: {
                                    label: "子订单号",
                                    "label-width": "100px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: { disabled: "", size: "small" },
                                    model: {
                                      value: _vm.form.outId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "outId", $$v)
                                      },
                                      expression: "form.outId"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "出库类型",
                        "label-width": "100px",
                        prop: "type"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请选择出库类型",
                            size: "small",
                            disabled: _vm.titleName === "编辑出库单",
                            disabled: ""
                          },
                          model: {
                            value: _vm.form.type,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type"
                          }
                        },
                        _vm._l(_vm.list.outType, function(item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.value, value: item.key }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.judge != 5
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "订单来源",
                            "label-width": "100px",
                            prop: "source"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                placeholder: "请选择订单来源",
                                size: "small"
                              },
                              model: {
                                value: _vm.form.source,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "source", $$v)
                                },
                                expression: "form.source"
                              }
                            },
                            _vm._l(_vm.list.outSource, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.values, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "仓库名称",
                        "label-width": "100px",
                        prop: "areaId"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            filterable: "",
                            disabled: _vm.titleName === "编辑出库单",
                            placeholder: "请选择入库仓库",
                            size: "small"
                          },
                          on: { change: _vm.changeWarehouse },
                          model: {
                            value: _vm.form.areaId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "areaId", $$v)
                            },
                            expression: "form.areaId"
                          }
                        },
                        _vm._l(_vm.list.WarehousingWarehouse, function(item) {
                          return _c("el-option", {
                            key: item.warehouseId,
                            attrs: {
                              label: item.warehouseName,
                              value: item.warehouseId
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "配送方式",
                        "label-width": "100px",
                        prop: "distribution"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请选择配送方式",
                            size: "small"
                          },
                          on: { change: _vm.changeDistribution },
                          model: {
                            value: _vm.form.distribution,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "distribution", $$v)
                            },
                            expression: "form.distribution"
                          }
                        },
                        _vm._l(_vm.list.distributionType, function(item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.value, value: item.key }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.judge != 5
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "物流公司",
                            "label-width": "100px",
                            prop: "courierCompany"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              disabled: _vm.isEdit,
                              clearable: "",
                              placeholder: "请输入物流公司",
                              size: "small"
                            },
                            model: {
                              value: _vm.form.courierCompany,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "courierCompany", $$v)
                              },
                              expression: "form.courierCompany"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.judge != 5
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            clearable: "",
                            label: "物流单号",
                            "label-width": "100px",
                            prop: "courierOrderId"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              disabled: _vm.isEdit,
                              placeholder: "请输入物流单号",
                              size: "small"
                            },
                            model: {
                              value: _vm.form.courierOrderId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "courierOrderId", $$v)
                              },
                              expression: "form.courierOrderId"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              ),
              _c("br"),
              !(_vm.form.type === 0 && _vm.form.distribution === 1) &&
              _vm.judge != 5
                ? [
                    _c("div", { staticClass: "title" }, [
                      _c("span", [_vm._v("收货信息")])
                    ]),
                    _c(
                      "div",
                      { staticClass: "form" },
                      [
                        !(_vm.form.type === 0 && _vm.form.distribution === 0)
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  clearable: "",
                                  label: "收货姓名",
                                  "label-width": "80px",
                                  prop: "chargeName"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: _vm.isEdit,
                                    placeholder: "请输入收货姓名",
                                    size: "small"
                                  },
                                  model: {
                                    value: _vm.form.chargeName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "chargeName", $$v)
                                    },
                                    expression: "form.chargeName"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        !(_vm.form.type === 0 && _vm.form.distribution === 0)
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  clearable: "",
                                  label: "收货电话",
                                  "label-width": "80px",
                                  prop: "chargePhone"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: _vm.isEdit,
                                    placeholder: "请输入收货电话",
                                    size: "small"
                                  },
                                  model: {
                                    value: _vm.form.chargePhone,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "chargePhone", $$v)
                                    },
                                    expression: "form.chargePhone"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              clearable: "",
                              label: "收货地址",
                              "label-width": "80px",
                              prop: "chargeAddress"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "500px" },
                              attrs: {
                                disabled: _vm.isEdit,
                                placeholder: "请输入收货地址",
                                size: "small"
                              },
                              model: {
                                value: _vm.form.chargeAddress,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "chargeAddress", $$v)
                                },
                                expression: "form.chargeAddress"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c("div", { staticClass: "title" }, [
                _c("span", [_vm._v("商品信息")]),
                _c(
                  "div",
                  { staticClass: "btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: _vm.isEdit,
                          size: "small",
                          type: "success"
                        },
                        on: { click: _vm.addRow }
                      },
                      [_vm._v(" 添加 ")]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "table" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "outGoodsList" } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "outGoodsList",
                          staticStyle: { overflow: "auto" },
                          attrs: {
                            "cell-style": { textAlign: "center" },
                            data: _vm.form.outGoodsList,
                            "header-cell-style": {
                              background: "#dfe6ec",
                              color: "#666666",
                              textAlign: "center"
                            },
                            "row-style": { height: "57px" },
                            "element-loading-text": "数据加载中",
                            "max-height": "620px",
                            "row-key": "key",
                            "tooltip-effect": "dark"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "序号",
                              type: "index",
                              width: "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "商品编码",
                              prop: "goodsId",
                              width: "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "商品名称",
                              prop: "goodsName",
                              width: "300"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "商品图片",
                              width: "150"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px"
                                      },
                                      attrs: {
                                        "preview-src-list": [
                                          _vm.$oss + scope.row.goodsPictureUrl
                                        ],
                                        src:
                                          _vm.$oss + scope.row.goodsPictureUrl,
                                        fit: "cover"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "入库价",
                              width: "200"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "outGoodsList." +
                                            scope.$index +
                                            ".recyclePrice",
                                          rules: _vm.rules.recyclePrice
                                        }
                                      },
                                      [
                                        _c(
                                          "erp-select",
                                          {
                                            on: {
                                              change: function(
                                                newValue,
                                                oldValue
                                              ) {
                                                return _vm.changePrice(
                                                  newValue,
                                                  oldValue,
                                                  scope.$index,
                                                  scope.row,
                                                  _vm.goodsInPrice[
                                                    scope.row.goodsId
                                                  ]
                                                )
                                              }
                                            },
                                            model: {
                                              value: scope.row.recyclePrice,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "recyclePrice",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.recyclePrice"
                                            }
                                          },
                                          _vm._l(
                                            _vm.goodsInPrice[scope.row.goodsId],
                                            function(item) {
                                              return _c("el-option", {
                                                key: item.value.price,
                                                attrs: {
                                                  disabled: item.disabled,
                                                  label: item.value.price,
                                                  value: item.value.price
                                                }
                                              })
                                            }
                                          ),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "在库库存",
                              prop: "inventoryNums",
                              width: "120"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.inventoryNums
                                            ? scope.row.inventoryNums
                                            : "- -"
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "出库价",
                              width: "150"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "outGoodsList." +
                                            scope.$index +
                                            ".outPrice",
                                          rules: _vm.rules.outPrice
                                        }
                                      },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            disabled: _vm.isEdit,
                                            min: 0,
                                            precision: 2,
                                            clearable: "",
                                            placeholder: "请输入出库价",
                                            size: "small",
                                            type: "number"
                                          },
                                          nativeOn: {
                                            input: function($event) {
                                              return _vm.changeInput($event)
                                            }
                                          },
                                          model: {
                                            value: scope.row.outPrice,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "outPrice",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.outPrice"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "数量",
                              width: "150"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "outGoodsList." +
                                            scope.$index +
                                            ".num",
                                          rules: _vm.rules.num
                                        }
                                      },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            disabled: _vm.isEdit,
                                            max: scope.row.maxNum,
                                            min: 0,
                                            precision: 0,
                                            clearable: "",
                                            placeholder: "请输入出库数量",
                                            size: "small",
                                            type: "number"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.maxNum(
                                                scope.row.goodsId,
                                                scope.row.recyclePrice,
                                                scope.row.inventoryNums,
                                                scope.$index
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row.num,
                                            callback: function($$v) {
                                              _vm.$set(scope.row, "num", $$v)
                                            },
                                            expression: "scope.row.num"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "出库总计",
                              width: "150"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.outPrice * scope.row.num
                                            ? (
                                                scope.row.outPrice *
                                                scope.row.num
                                              ).toFixed(2)
                                            : 0
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              fixed: "right",
                              label: "操作",
                              width: "150"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled: _vm.isEdit,
                                          size: "mini",
                                          type: "success"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.copyBtn(
                                              scope.$index,
                                              scope.row
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 复制 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled: _vm.isEdit,
                                          size: "mini",
                                          type: "danger"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteRow(
                                              scope.$index,
                                              scope.row
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 删除 ")]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _c("div", { staticClass: "title" }, [_c("span", [_vm._v("统计")])]),
          _c("div", { staticClass: "count" }, [
            _c("div", { staticClass: "count-item" }, [
              _vm._v(" 出库数量： "),
              _c("span", { staticClass: "red" }, [
                _vm._v(_vm._s(_vm.count.outNum))
              ])
            ]),
            _c("div", { staticClass: "count-item" }, [
              _vm._v(" 入库价之和： "),
              _c("span", { staticClass: "red" }, [
                _vm._v(_vm._s(_vm.count.inSum.toFixed(2)))
              ])
            ]),
            _c("div", { staticClass: "count-item" }, [
              _vm._v(" 出库价之和： "),
              _c("span", { staticClass: "red" }, [
                _vm._v(_vm._s(_vm.count.outSum.toFixed(2)))
              ])
            ]),
            _c("div", { staticClass: "count-item" }, [
              _vm._v(" 出库差额： "),
              _c("span", { staticClass: "red" }, [
                _vm._v(_vm._s(_vm.count.diffSum.toFixed(2)))
              ])
            ])
          ]),
          _c("div", { staticClass: "title" }, [_c("span", [_vm._v("备注")])]),
          _c("el-input", {
            attrs: { autosize: { minRows: 2, maxRows: 4 }, type: "textarea" },
            model: {
              value: _vm.form.remark,
              callback: function($$v) {
                _vm.$set(_vm.form, "remark", $$v)
              },
              expression: "form.remark"
            }
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.keepLoading,
                    loading: _vm.keepLoading,
                    size: "small",
                    type: "primary"
                  },
                  on: { click: _vm.save }
                },
                [_vm._v(" 保存 ")]
              )
            ],
            1
          ),
          _c("setStockGoodsList", {
            ref: "setStockGoodsList",
            on: { setGoods: _vm.addGoods }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }