import Vue from 'vue'

import d2Container from './d2-container'

// 注意 有些组件使用异步加载会有影响
Vue.component('d2-container', d2Container)
Vue.component('d2-icon', () => import('./d2-icon'))
Vue.component('d2-icon-svg', () => import('./d2-icon-svg/index.vue'))
Vue.component('erp-upload-img', () => import('./erp-upload-img/index.vue'))
Vue.component('erp-upload-video', () => import('./erp-upload-video/index.vue'))
Vue.component('erp-Editor', () => import('./erp-wangEditor/index.vue'))
Vue.component('erp-isJurisdiction-search', () =>
  import('./erp-isJurisdiction-search/index.vue')
)
Vue.component('head-search', () =>
  import('./head-search/index.vue')
)
Vue.component('head-search-item', () =>
  import('./head-search/search-item/index.vue')
)
Vue.component('purchaseSelectGoods', () =>
  import('./purchaseSelectGoods/index.vue')
)
Vue.component('remoteSearch', () => import('./remoteSearch/remoteSearch.vue'))
