export default ({ request }) => ({
  // 分页查询
  auditing (params = {}) {
    return request({
      url: '/v1/enumeration/getPageList',
      method: 'post',
      params: params
    })
  },
  // 审核确认
  checkAudit (data = {}) {
    return request({
      url: '/v1/audit/checkAudit',
      method: 'post',
      data: data
    })
  }
})
