<template>
  <div class="good">
    <div class="title">
      <span>商品信息</span>
      <div class="button">
        <el-button
          size="small"
          type="danger"
          v-if="!info.isStop && !auth.isStop"
          :disabled="stopOutStockLoading"
          :loading="stopOutStockLoading"
          @click="stopOutStock"
        >
          终止入库
        </el-button>
        <el-button
          type="success"
          size="small"
          :disabled="onePiceRemakeLoading"
          :loading="onePiceRemakeLoading"
          @click="onePiceRemake"
          v-if="
            info.enterWarehouseState === 0 &&
              !info.isStop &&
              info.enterWarehouseType !== '调拨' &&
              info.enterWarehouseType !== '代发' && !auth.isCode
          "
        >
          一键生码
        </el-button>
      </div>
    </div>
    <div class="table">
      <el-table
        ref="tableData"
        :row-class-name="rowClassName"
        :data="goods.datas"
        max-height="770px"
        element-loading-text="数据加载中"
        :cell-style="{ textAlign: 'center' }"
        tooltip-effect="dark"
        :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center'
        }"
      >
        <el-table-column label="序号" width="80" align="center" key="index">
          <template slot-scope="scope">
            {{ (page.pageNo - 1) * page.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          key="goodsCoding"
          label="商品编码"
          prop="goodsCoding"
          align="center"
          width="110px"
        ></el-table-column>
        <el-table-column
          key="goodsName"
          label="商品名称"
          prop="goodsName"
          align="center"
          width="300px"
        ></el-table-column>
        <el-table-column key="goodsPicture" label="商品图片" align="center">
          <template slot-scope="scope">
            <el-image
              :src="
                scope.row.goodsPicture
                  ? $oss + scope.row.goodsPicture
                  : $oss + scope.row.pictureUrl
              "
              :preview-src-list="[
                scope.row.goodsPicture
                  ? $oss + scope.row.goodsPicture
                  : $oss + scope.row.pictureUrl
              ]"
              style="width: 40px; height: 40px"
              fit="cover"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          key="purchaseNums"
          v-if="
            (info.enterWarehouseState === 0 ||
              (info.enterWarehouseType === '代发' &&
                info.enterWarehouseState === 3)) &&
              info.enterWarehouseType !== '其他'
          "
          :label="
            info.enterWarehouseType === '调拨'
              ? '调拨数量'
              : info.enterWarehouseType === '代发' &&
                info.enterWarehouseState === 3
              ? '商品数量'
              : '采购数量'
          "
          prop="enterWarehouseNums"
          align="center"
          width="300px"
        ></el-table-column>
        <el-table-column
          key="barCode"
          v-if="
            info.enterWarehouseState !== 0 && info.enterWarehouseType !== '代发'
          "
          label="条码"
          prop="barCode"
          align="center"
          width="170px"
        ></el-table-column>

        <el-table-column
          key="intoNums"
          v-if="info.enterWarehouseState === 0"
          label="入库数量"
          prop="enterWarehouseNums"
          align="center"
          width="300px"
        ></el-table-column>
        <el-table-column
          key="enterWarehousePrices"
          label="入库价"
          prop="enterWarehousePrices"
          align="center"
        ></el-table-column>
        <el-table-column
          key="totalNum"
          v-if="
            info.enterWarehouseState === 0 ||
              (info.enterWarehouseState === 3 &&
                info.enterWarehouseType === '代发')
          "
          label="总计"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                (
                  scope.row.enterWarehousePrices * scope.row.enterWarehouseNums
                ).toFixed(2)
              }}
            </span>
          </template>
        </el-table-column>
        <template
          v-if="
            info.enterWarehouseState !== 0 && info.enterWarehouseType !== '代发'
          "
        >
          <el-table-column
            key="enterWarehouseAreaName"
            label="入库区域"
            prop="enterWarehouseAreaName"
            align="center"
          ></el-table-column>
          <el-table-column
            label="入库货架"
            prop="enterWarehouseShelfName"
            key="enterWarehouseShelfName"
            align="center"
          ></el-table-column>
          <el-table-column
            label="货架层数"
            prop="enterWarehouseShelvesNum"
            key="enterWarehouseShelvesNum"
            align="center"
          ></el-table-column>
          <el-table-column label="是否扫码" align="center" key="scanCode">
            <template slot-scope="scope">
              {{
                scope.row.scanCode === 1
                  ? '是'
                  : scope.row.scanCode === 0
                  ? '否'
                  : ''
              }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="是否货损" align="center" key="freightDamage">
            <template slot-scope="scope">
              {{
                scope.row.freightDamage == 1
                  ? '是'
                  : scope.row.freightDamage === 0
                  ? '否'
                  : ''
              }}
            </template>
          </el-table-column> -->
          <el-table-column
            label="扫码人"
            prop="scanCodeBy"
            align="center"
            key="scanCodeBy"
          ></el-table-column>
          <el-table-column
            label="扫码时间"
            width="200px"
            prop="scanCodeTimestamp"
            align="center"
          ></el-table-column>
        </template>
        <el-table-column
          v-if="
            ((info.enterWarehouseState === 0 && !info.isStop) ||
              info.enterWarehouseState === 1) &&
              info.enterWarehouseType !== '调拨' &&
              info.enterWarehouseType !== '代发' &&
              !info.isStop
          "
          label="操作"
          align="center"
          key="remake"
        >
          <template slot-scope="scope">
            <el-button
              type="danger"
              plain
              size="small"
              @click="remake(scope.row)"
              v-if="info.enterWarehouseState === 1 && auth.isCode === false"
            >
              重新生码
            </el-button>
            <el-button
              type="danger"
              plain
              size="small"
              v-if="
                info.enterWarehouseState === 0 &&
                  info.enterWarehouseType !== '调拨' &&
                  info.enterWarehouseType !== '代发' &&
                  !info.isStop && auth.isCode === false
              "
              @click="make(scope.row)"
            >
              生码
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="page.pageNo"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="page.pageSize"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import api from '@/api'
import { authority } from '../../list/contant'
export default {
  props: {
    goods: {
      type: Object
    },
    info: {
      type: Object
    }
  },
  created () {
    this.auth = authority(this)
  },
  data () {
    return {
      page: {
        pageNo: 1,
        pageSize: 50
      },
      auth: {},
      onePiceRemakeLoading: false,
      stopOutStockLoading: false,
      pageTotal: this.goods.sumDataCount
    }
  },
  watch: {
    goods: {
      handler (val) {
        this.pageTotal = val.sumDataCount
      },
      deep: true
    },
    info () {
      this.getGoodsList()
    }
  },
  methods: {
    rowClassName ({ row, rowIndex }) {
      if (row.freightDamage) {
        if (row.freightDamage === 1) {
          return 'damage'
        } else if (row.freightDamage === 2) {
          return 'loss'
        }
      }
    },
    sizeChange (val) {
      this.page.pageSize = val
      this.getGoodsList()
    },
    currentChange (val) {
      this.page.pageNo = val
      this.getGoodsList()
    },
    getGoodsList () {
      this.$emit('getGoods', this.page)
    },
    stopOutStock () {
      this.$confirm('是否终止入库？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          this.stopOutStockLoading = true
          const { code } = await api.stopEnterWarehouse({
            enterWarehouseNumber: this.info.enterWarehouseNumber
          })
          if (code === 200) {
            this.$message.success('终止入库成功')
            this.$emit('refresh')
            this.getInfo()
          }
        } catch (error) {
          this.$message.error('终止入库失败')
        } finally {
          this.stopOutStockLoading = false
        }
      })
    },
    make (row) {
      this.$confirm('是否生码？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await api.intoDetailPrint({
          enterWarehouseNumber: row.enterWarehouseNumber,
          goodsCoding: row.goodsCoding
        })
        if (code === 200) {
          this.$message.success('生码成功')
          this.$emit('refresh')
          this.getGoodsList()
        }
      })
    },
    remake (row) {
      this.$confirm('是否重新生码？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await api.intoDetailPrint({
          enterWarehouseNumber: row.enterWarehouseNumber,
          barCode: row.barCode
        })
        if (code === 200) {
          this.$message.success('生码成功')
          this.$emit('refresh')
          this.getGoodsList()
        }
      })
    },
    onePiceRemake () {
      this.$confirm('是否一键生码？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // onePiceRemakeLoading
        try {
          this.onePiceRemakeLoading = true
          const { code } = await api.intoDetailPrint({
            enterWarehouseNumber: this.info.enterWarehouseNumber
          })
          if (code === 200) {
            this.$message.success('生码成功')
            this.$emit('refresh')
            this.getGoodsList()
          }
        } catch (error) {
          this.$message.error('生码失败')
        } finally {
          this.onePiceRemakeLoading = false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #b8b8b81e;
  height: 30px;
  font-weight: bold;
}

::v-deep .damage {
  background: #efdede;
}

::v-deep .loss {
  background: #f5f7b2;
}
</style>
