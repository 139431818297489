var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c(
      "div",
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              border: "1px solid #e0e0e0",
              margin: "0 auto",
              padding: "10px 0 10px 0"
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  width: "80%",
                  margin: "0 auto",
                  "justify-content": "space-around"
                }
              },
              [
                _c("div", [
                  _c("img", {
                    staticClass: "imgDemonstration",
                    attrs: { src: require("../img/warehouse.png"), alt: "" }
                  }),
                  _c("div", { staticClass: "imgTitle" }, [_vm._v("仓库")])
                ]),
                _c("div", { staticClass: "arrows" }, [
                  _c("div", { staticClass: "arrowsLeft" }),
                  _c("i", { staticClass: "el-icon-arrow-right arrowsRight" })
                ]),
                _c("div", [
                  _c("img", {
                    staticClass: "imgDemonstration",
                    staticStyle: {
                      width: "40px",
                      height: "40px",
                      "margin-top": "6px"
                    },
                    attrs: { src: require("../img/area.png"), alt: "" }
                  }),
                  _c("div", { staticClass: "imgTitle" }, [_vm._v("区域")])
                ]),
                _c("div", { staticClass: "arrows" }, [
                  _c("div", { staticClass: "arrowsLeft" }),
                  _c("i", { staticClass: "el-icon-arrow-right arrowsRight" })
                ]),
                _c("div", [
                  _c("img", {
                    staticClass: "imgDemonstration",
                    attrs: { src: require("../img/shelves.png"), alt: "" }
                  }),
                  _c("div", { staticClass: "imgTitle" }, [_vm._v("货架")])
                ]),
                _c("div", { staticClass: "arrows" }, [
                  _c("div", { staticClass: "arrowsLeft" }),
                  _c("i", { staticClass: "el-icon-arrow-right arrowsRight" })
                ]),
                _c("div", [
                  _c("img", {
                    staticClass: "imgDemonstration",
                    attrs: { src: require("../img/location.png"), alt: "" }
                  }),
                  _c("div", { staticClass: "imgTitle" }, [_vm._v("库位")])
                ])
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            staticStyle: {
              "margin-top": "10px",
              display: "flex",
              "justify-content": "space-between",
              width: "98%",
              margin: "10px auto"
            }
          },
          [
            _c("div", { staticClass: "courseIdClass" }, [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c("erp-isJurisdiction-search", {
                    ref: "getJurisdictionData",
                    attrs: { type: "search" }
                  }),
                  _c(
                    "head-search",
                    { attrs: { "label-style": "width:100px" } },
                    [
                      _c(
                        "head-search-item",
                        { attrs: { label: "仓库名称" } },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "200px",
                              "margin-right": "8px"
                            },
                            attrs: {
                              disabled: _vm.permission.warehouse_select,
                              placeholder: "仓库名称",
                              clearable: "",
                              size: "small"
                            },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchX()
                              }
                            },
                            model: {
                              value: _vm.warehouseName,
                              callback: function($$v) {
                                _vm.warehouseName = $$v
                              },
                              expression: "warehouseName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      !_vm.permission.warehouse_select
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "small",
                                icon: "el-icon-search"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.searchX()
                                }
                              }
                            },
                            [_vm._v("搜索")]
                          )
                        : _vm._e(),
                      !_vm.permission.warehouse_select
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "warning",
                                size: "small",
                                icon: "el-icon-refresh-left"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.reset()
                                }
                              }
                            },
                            [_vm._v("重置")]
                          )
                        : _vm._e(),
                      !_vm.permission.isAddWarehouse
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                size: "small",
                                icon: "el-icon-plus"
                              },
                              on: { click: _vm.addWarehouse }
                            },
                            [_vm._v("新增仓库 ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        ),
        _c(
          "div",
          [
            !_vm.permission.warehouse_select
              ? _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingTotal,
                        expression: "loadingTotal"
                      }
                    ],
                    staticStyle: { width: "100%", "margin-top": "10px" },
                    attrs: {
                      data: _vm.tableData,
                      "element-loading-text": "数据加载中",
                      "cell-style": { textAlign: "center" },
                      "max-height": "618px",
                      "row-style": { height: "57px", textAlign: "center" },
                      "header-cell-style": {
                        background: "#dfe6ec",
                        color: "#666666",
                        textAlign: "center"
                      },
                      "default-expand-all": _vm.defaultExpandAll
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "序号", width: "50", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (_vm.search.currpage - 1) *
                                        _vm.search.pagesize +
                                        scope.$index +
                                        1
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4158122166
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "companyName",
                        label: "公司名称",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "departmentName",
                        label: "所属部门",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "warehouseName",
                        label: "仓库名称",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "warehouseAddress",
                        label: "仓库地址",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "areaNum",
                        label: "区域数量",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "shelvesNum",
                        label: "货架数量",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "layerNum",
                        label: "层数总计",
                        align: "center"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.layerNum == null
                                        ? 0
                                        : scope.row.layerNum
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1826378227
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "address",
                        label: "操作",
                        width: "400",
                        align: "center",
                        fixed: "right"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                !_vm.permission.isEditorWarehouse
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "success",
                                          plain: "",
                                          icon: "el-icon-edit-outline",
                                          size: "small"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.editorWarehouse(
                                              scope.$index
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 编辑仓库 ")]
                                    )
                                  : _vm._e(),
                                !_vm.permission.isParticulars
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "info",
                                          size: "small",
                                          plain: "",
                                          icon: "el-icon-info"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.particulars(scope.$index)
                                          }
                                        }
                                      },
                                      [_vm._v("仓库详情 ")]
                                    )
                                  : _vm._e(),
                                !_vm.permission.isDeleteX
                                  ? _c(
                                      "el-button",
                                      {
                                        key: scope.row.warehouseId,
                                        attrs: {
                                          type: "danger",
                                          disabled: _vm.deleteLoading,
                                          loading: _vm.deleteLoading,
                                          size: "small",
                                          plain: "",
                                          icon: "el-icon-delete"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteX(scope.$index)
                                          }
                                        }
                                      },
                                      [_vm._v("删除仓库")]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4292858381
                      )
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.sumDataCount !== 0
              ? _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.search.currpage,
                        "page-sizes": [10, 20, 30, 40, 50, 100],
                        "page-size": _vm.search.pagesize,
                        layout: "total, jumper, prev, pager, next,sizes",
                        total: _vm.search.currentPage
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.dialogTitle1,
              visible: _vm.dialogVisible1,
              width: "400px",
              "close-on-click-modal": false
            },
            on: {
              "update:visible": function($event) {
                _vm.dialogVisible1 = $event
              },
              close: _vm.WarehouseDialog
            }
          },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                attrs: {
                  "label-position": "right",
                  "label-width": "80px",
                  model: _vm.form1,
                  rules: _vm.rules
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "仓库名称", prop: "name" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "200px" },
                      attrs: { clearable: "", maxlength: "10", size: "small" },
                      model: {
                        value: _vm.form1.name,
                        callback: function($$v) {
                          _vm.$set(_vm.form1, "name", $$v)
                        },
                        expression: "form1.name"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "仓库地址", prop: "address" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "200px" },
                      attrs: { clearable: "", maxlength: "100", size: "small" },
                      model: {
                        value: _vm.form1.address,
                        callback: function($$v) {
                          _vm.$set(_vm.form1, "address", $$v)
                        },
                        expression: "form1.address"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "所属部门", prop: "orgId" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "200px" },
                        attrs: {
                          disabled: _vm.dialogTitle1 === "编辑仓库",
                          clearable: "",
                          size: "small",
                          placeholder: "请选择部门"
                        },
                        model: {
                          value: _vm.form1.orgId,
                          callback: function($$v) {
                            _vm.$set(_vm.form1, "orgId", $$v)
                          },
                          expression: "form1.orgId"
                        }
                      },
                      _vm._l(_vm.optionsX, function(item) {
                        return _c("el-option", {
                          key: item.orgId,
                          attrs: { label: item.orgName, value: item.orgId }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.WarehouseaCncel()
                      }
                    }
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      disabled: _vm.warehouseDetermineLoading,
                      loading: _vm.warehouseDetermineLoading,
                      size: "small"
                    },
                    on: {
                      click: function($event) {
                        return _vm.WarehouseDetermine(_vm.dialogTitle1)
                      }
                    }
                  },
                  [_vm._v("确 定")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }