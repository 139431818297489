<template>
  <!-- 如果内容比较多可以添加top属性 -->
  <d2-container>
    <el-form :inline="true" :model="selectForm">
      <el-form-item>
         <el-input size="small" clearable  :disabled="isSelect" v-model="selectForm.name" @keyup.enter.native="search()" placeholder="应用名称"></el-input>
      </el-form-item>
      <el-form-item>
         <el-input size="small" clearable  :disabled="isSelect" v-model="selectForm.appKey" @keyup.enter.native="search()" placeholder="应用key"></el-input>
      </el-form-item>
      <el-form-item>
         <el-input size="small" clearable  :disabled="isSelect" v-model="selectForm.appToken" @keyup.enter.native="search()" placeholder="应用token"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small"  :disabled="isSelect" type="primary" @click="search" icon="el-icon-search">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button size="small" :disabled="isSelect" type="warning" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button size="small" :disabled="isCreate" type="success" icon="el-icon-circle-plus-outline" @click="toAdd">新增</el-button>
      </el-form-item>
      <el-form-item>
        <el-button size="small" clearable  :disabled="isDelete" type="danger" @click="deleteBatch" icon="el-icon-delete">删除</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          prop="name"
          label="应用名称">
        </el-table-column>
        <el-table-column
          prop="appKey"
          label="应用key">
        </el-table-column>
        <el-table-column
          prop="appToken"
          label="应用token">
        </el-table-column>
        <el-table-column
          prop="stats"
          width="100"
          label="状态">
          <template slot-scope="scope">
            <el-switch
              :disabled="isUpdate"
              v-model="scope.row.stats"
              class="demo"
              active-color="#00A854"
              active-text="开启"
              :active-value="1"
              @change="switchChange(scope.row)"
              inactive-color="#F04134"
              inactive-text="注销"
              :inactive-value="0"/>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间">
        </el-table-column>
        <el-table-column
          fixed="right"
          width="260"
          label="操作">
          <template slot-scope="scope">
            <el-button
              plain
              type="info"
              size="small"
              :disabled="isSelect"
              @click="info(scope.$index, scope.row)" icon="el-icon-info">详情</el-button>
            <el-button
              plain
              type="primary"
              size="small"
              :disabled="isUpdate"
              @click="edit(scope.$index, scope.row)" icon="el-icon-edit-outline">编辑</el-button>
            <el-button
              plain
              size="small"
              type="danger"
              :disabled="isDelete"
              @click="dalete(scope.$index, scope.row)" icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-div">
        <el-pagination
          :current-page="this.pageNo"
          :disabled="isSelect"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10,20,30,40,50,100]"
          layout="jumper, prev, pager, next,sizes"
          :total="pageTotal">
        </el-pagination>
      </div>
      <AppsEdit ref="edit" :disabled="editDisabled" :dialogVisible="dialogVisible" @close="userEditClose" @initTableData="initTableData"></AppsEdit>
  </d2-container>
</template>

<script>
import api from '@/api'
import AppsEdit from '../edit/index'

export default {
  name: 'AppsList',
  components: {
    AppsEdit
  },
  data () {
    return {
      isSelect: this.checkauthority('apps_query'),
      isCreate: this.checkauthority('apps_create'),
      isUpdate: this.checkauthority('apps_update'),
      isDelete: this.checkauthority('apps_delete'),
      selectForm: {
        name: '',
        appKey: '',
        appToken: ''
      },
      editDisabled: false,
      dialogVisible: false,
      pageNo: 1,
      pageSize: 50,
      tableSelects: [],
      tableData: [],
      pageTotal: 0,
      centerDialogVisible: false
    }
  },
  methods: {
    initEunmData () {
      this.getEnumList('key')
    },
    async getEnumList (name) {
      var par = {
        name: name
      }
      var res = await api.GET_ENUMERATION_LIST(par)
      this.enumList = res.body
    },
    switchChange (value) {
      this.updateStats(value)
    },
    checkStatsType (data) {
      if (data.stats === 0) {
        return 'success'
      } else {
        return 'danger'
      }
    },
    checkStats (data) {
      if (data.stats === 0) {
        return '正常'
      } else {
        return '已注销'
      }
    },
    btnCheckStats (data) {
      if (data.stats === 0) {
        return '注销'
      } else {
        return '恢复'
      }
    },
    async updateStats (data) {
      console.log(data)
      var par = {
        appId: data.appId,
        stats: data.stats
      }
      await api.UPDATE_APPS(par)
      this.initTableData()
    },
    dalete (index, data) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.daleteApi(index, data)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async daleteApi (index, data) {
      var par = {
        appId: data.appId
      }
      await api.DELETE_APPS(par)
      this.initTableData()
    },
    async deleteBatchApi (par) {
      await api.DELETE_BATCH_APPS(par)
      this.$message.success('操作成功')
      this.initTableData()
    },
    deleteBatch () {
      if (this.tableSelects.length === 0) {
        this.$message.error('请勾选要删除的表格数据')
        return
      }
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var par = []
        this.tableSelects.forEach(element => {
          par.push(element.appId)
        })
        this.deleteBatchApi(par)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    info (index, data) {
      this.editDisabled = true
      this.dialogVisible = true
      this.$refs.edit.setTitle('详情')
      this.$refs.edit.setData(data)
    },
    edit (index, data) {
      this.dialogVisible = true
      this.editDisabled = false
      this.$refs.edit.setTitle('编辑')
      this.$refs.edit.setData(data)
    },
    toAdd () {
      this.$refs.edit.setTitle('新增')
      this.editDisabled = false
      this.dialogVisible = true
      this.$refs.edit.initData()
    },
    userEditClose () {
      this.dialogVisible = false
    },
    reset () {
      this.selectForm.name = ''
      this.selectForm.appKey = ''
      this.selectForm.appToken = ''
      this.initTableData()
    },
    search () {
      this.pageNo = 1
      this.initTableData()
    },
    async initTableData () {
      var par = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        name: this.selectForm.name,
        appKey: this.selectForm.appKey,
        appToken: this.selectForm.appToken
      }
      const res = await api.GET_APPS_PAGE_LIST(par)
      this.tableData = res.body.datas
      this.pageTotal = res.body.sumDataCount
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.initTableData()
    },
    handleCurrentChange (val) {
      this.pageNo = val
      this.initTableData()
    },
    handleSelectionChange (val) {
      this.tableSelects = val
    }
  },
  created () {
    if (this.isSelect) {
      return
    }
    this.initTableData()
  }
}
</script>
<style>
  .editBtn{
    width: 60px;
  }
  .statsTag {
    width: 60px;
    text-align: center;
  }
 .demo .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  /*打开时文字位置设置*/
  .demo .el-switch__label--right {
    z-index: 1;
    right: -4px;
 }
 /*关闭时文字位置设置*/
 .demo .el-switch__label--left {
    z-index: 1;
    left: 18px;
 }
 /*显示文字*/
 .demo .el-switch__label.is-active {
   display: block;
 }
 .demo.el-switch .el-switch__core,
 .el-switch .el-switch__label {
   width: 50px !important;
 }
</style>
