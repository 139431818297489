var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "good" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "table" },
      [
        _c(
          "el-table",
          {
            ref: "tableData",
            attrs: {
              data: [_vm.logInfo],
              "max-height": "620px",
              "element-loading-text": "数据加载中",
              "cell-style": { textAlign: "center" },
              "tooltip-effect": "dark",
              "row-style": { height: "57px" },
              "header-cell-style": {
                background: "#dfe6ec",
                color: "#666666",
                textAlign: "center"
              }
            }
          },
          [
            _c("el-table-column", {
              attrs: { label: "出库总数", prop: "sum", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { label: "已扫码数量", prop: "num", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { label: "未扫码数量", prop: "notNum", align: "center" }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("出库明细")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }