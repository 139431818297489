var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setGoodsList" },
    [
      _c(
        "el-dialog",
        {
          ref: "dialog",
          attrs: {
            title: "异常商品处理",
            width: "85%",
            "append-to-body": true,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            "before-close": _vm.closeDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "物流单号" } },
                      [
                        _c("el-input", {
                          staticClass: "searchInput",
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请输入物流单号",
                            clearable: "",
                            size: "small"
                          },
                          model: {
                            value: _vm.form.logisticsNumbers,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "logisticsNumbers", $$v)
                            },
                            expression: "form.logisticsNumbers"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "快递公司" } },
                      [
                        _c("el-input", {
                          staticClass: "searchInput",
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请输入快递公司",
                            clearable: "",
                            size: "small"
                          },
                          model: {
                            value: _vm.form.logisticsCompany,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "logisticsCompany", $$v)
                            },
                            expression: "form.logisticsCompany"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退款图片" } },
                      [
                        _c("erp-upload-img", {
                          attrs: {
                            showDelete: true,
                            "upload-path":
                              "/purchasingManagement/purchaseSelectDispose/",
                            maxCount: 10
                          },
                          model: {
                            value: _vm.form.refundProve,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "refundProve", $$v)
                            },
                            expression: "form.refundProve"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c("el-tab-pane", { attrs: { name: "withoutRecord" } }, [
                _c(
                  "span",
                  { attrs: { slot: "label" }, slot: "label" },
                  [
                    _vm._v(" 未记录商品处理 "),
                    _c("el-badge", {
                      attrs: {
                        value: _vm.withoutRecordList.length,
                        max: 99,
                        hidden: _vm.withoutRecordList.length === 0
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "10px",
                      "margin-left": "10px",
                      "margin-right": "10px"
                    }
                  },
                  [
                    _c(
                      "el-table",
                      {
                        ref: "withoutRecordList",
                        attrs: {
                          data: _vm.withoutRecordList,
                          "cell-style": { textAlign: "center" },
                          "tooltip-effect": "dark",
                          "max-height": "360",
                          "row-style": { height: "57px" },
                          "header-cell-style": {
                            background: "#dfe6ec",
                            color: "#666666",
                            textAlign: "center"
                          }
                        },
                        on: { "selection-change": _vm.handleSelectionChange }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "序号",
                            width: "80",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(" " + _vm._s(scope.$index + 1) + " ")
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品编码",
                            prop: "goodsCoding",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "white-space": "nowrap",
                                        width: "80%"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row.goodsCoding) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品名称",
                            prop: "goodsName",
                            align: "center",
                            width: "400"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        overflow: "hidden",
                                        "white-space": "nowrap",
                                        "text-overflow": "ellipsis"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(scope.row.goodsName) + " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品图片",
                            prop: "pictureUrl",
                            align: "center",
                            width: "100"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("img", {
                                    staticStyle: {
                                      width: "50px",
                                      height: "50px",
                                      "margin-top": "8px",
                                      cursor: "pointer"
                                    },
                                    attrs: {
                                      src:
                                        _vm.downloadUrl +
                                        "/" +
                                        scope.row.pictureUrl,
                                      alt: ""
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: " 分类",
                            prop: "idName",
                            align: "center",
                            width: "120"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        overflow: "hidden",
                                        "white-space": "nowrap",
                                        "text-overflow": "ellipsis"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.idName === null
                                              ? "- -"
                                              : scope.row.idName
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品品牌",
                            prop: "brandName",
                            align: "center",
                            width: "180"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        overflow: "hidden",
                                        "white-space": "nowrap",
                                        "text-overflow": "ellipsis"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(scope.row.brandName) + " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品IP",
                            prop: "ipName",
                            align: "center",
                            width: "180"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        overflow: "hidden",
                                        "white-space": "nowrap",
                                        "text-overflow": "ellipsis"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(scope.row.ipName) + " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "数量", prop: "num", align: "center" }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            prop: "dealStatus",
                            align: "center",
                            width: "270",
                            fixed: "right"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-radio-group",
                                    {
                                      nativeOn: {
                                        change: function($event) {
                                          $event.preventDefault()
                                          return _vm.chooseRadioWithoutRecord(
                                            scope.row.radio,
                                            scope.row,
                                            scope.$index
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row.radio,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "radio", $$v)
                                        },
                                        expression: "scope.row.radio"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 0 },
                                          model: {
                                            value: scope.row.radio,
                                            callback: function($$v) {
                                              _vm.$set(scope.row, "radio", $$v)
                                            },
                                            expression: "scope.row.radio"
                                          }
                                        },
                                        [_vm._v("补发")]
                                      ),
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 1 },
                                          model: {
                                            value: scope.row.radio,
                                            callback: function($$v) {
                                              _vm.$set(scope.row, "radio", $$v)
                                            },
                                            expression: "scope.row.radio"
                                          }
                                        },
                                        [_vm._v("退款")]
                                      ),
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 3 },
                                          model: {
                                            value: scope.row.radio,
                                            callback: function($$v) {
                                              _vm.$set(scope.row, "radio", $$v)
                                            },
                                            expression: "scope.row.radio"
                                          }
                                        },
                                        [_vm._v("分批到货")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "10px"
              }
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "submit",
                  attrs: { size: "small" },
                  on: { click: _vm.closeDialog }
                },
                [_vm._v("关闭")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "submit",
                  attrs: {
                    size: "small",
                    type: "primary",
                    disabled: _vm.submitLoading,
                    loading: _vm.submitLoading
                  },
                  on: { click: _vm.submit }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }