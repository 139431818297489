export default ({ request }) => ({
    // 获取退货列表
    getTHCHKBList(data = {}) {
        return request({
            url: '/newStatical/getReturn',
            method: 'post',
            data: data
        })
    },
    // 获取退货列表集合
    getNewStaticalGetNewStat(data = {}) {
        return request({
            url: '/newStatical/getNewStat',
            method: 'post',
            data: data
        })
    },
})