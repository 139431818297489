<template>
  <d2-container>
    <!--       搜索框        -->
    <div style="width: 100%">
      <div class="searchSty">
        <head-search label-style="width:100px">
          <head-search-item label="商品编码">
            <el-input v-model="search.goodsCoding" clearable placeholder="请输入商品编码" size="small"></el-input>
          </head-search-item>
          <head-search-item label="商品原ID">
            <el-input v-model="search.oldId" clearable placeholder="请输入商品原ID" size="small"></el-input>
          </head-search-item>
          <head-search-item label="商品名称">
            <el-input v-model="search.goodsName" clearable placeholder="请输入商品名称" size="small"></el-input>
          </head-search-item>
          <head-search-item label="统计日期">
            <el-date-picker v-model="search.timeStamp" :picker-options="pickerOptions" align="right"
              end-placeholder="结束日期" range-separator="至" size="small" start-placeholder="开始日期" type="daterange"
              unlink-panels value-format="yyyy-MM-dd">
            </el-date-picker>
          </head-search-item>
        </head-search>
        <div class="searchBtn">
          <el-button icon="el-icon-search" size="small" type="primary" @click="toQuery()">
            搜索
          </el-button>
          <el-button icon="el-icon-refresh" size="small" type="warning" @click="reset()">
            重置
          </el-button>
          <el-button :disabled="exportLoading" :loading="exportLoading" icon="el-icon-download" size="small"
            type="success" @click="exportExcel">
            导出
          </el-button>
        </div>
      </div>
    </div>
    <!--       统计数值       -->
    <div>
      <div v-loading="loadingNum" class="topCount">
        <div class="font">
          待采购数量 <span class="num">{{ sumData.numWait }}</span>
        </div>
        <div class="font">
          待采购金额 <span class="num">{{ isNumber(sumData.moneyWait).toFixed(2) }}</span>
        </div>
        <div class="font">
          采购中数量 <span class="num">{{ sumData.numIng }}</span>
        </div>
        <div class="font">
          采购中金额 <span class="num">{{ isNumber(sumData.moneyIng).toFixed(2) }}</span>
        </div>
        <div class="font">
          采购完成数量 <span class="num">{{ sumData.numEd }}</span>
        </div>
        <div class="font">
          采购完成金额 <span class="num">{{ isNumber(sumData.moneyEd).toFixed(2) }}</span>
        </div>
      </div>
    </div>
    <!--       TableData      -->
    <div style="margin-top: 10px">
      <el-table ref="tableData" v-loading="loadingList" :cell-style="{ textAlign: 'center' }" :data="tableData"
        :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center'
        }" :row-style="{ height: '57px' }" element-loading-text="数据加载中" max-height="670" tooltip-effect="dark"
        @sort-change="sortChange">
        <el-table-column align="center" label="序号" width="80">
          <template slot-scope="scope">
            {{ (search.pageNo - 1) * search.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="商品编码" prop="goodsCoding">
        </el-table-column>
        <el-table-column align="center" label="商品原ID" prop="oldId">
          <template slot-scope="scope">
            {{ scope.row.goodsId ? scope.row.goodsId : '- -' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="商品名称" prop="goodsName" width="300">
        </el-table-column>
        <el-table-column align="center" label="商品图片" prop="pictureUrl">
          <template slot-scope="scope">
            <el-image :src="downloadUrl + '/' + scope.row.pictureUrl" alt=""
              :preview-src-list="[downloadUrl + '/' + scope.row.pictureUrl]"
              style="width: 50px; height: 50px;margin-top: 8px;cursor: pointer"></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="待采购数量" prop="numWait" sortable="custom">
        </el-table-column>
        <el-table-column align="center" label="待采购金额" prop="moneyWait" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.moneyWait.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="采购中数量" prop="numIng" sortable="custom">
        </el-table-column>
        <el-table-column align="center" label="采购中金额" prop="moneyIng" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.moneyIng.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="采购完成数量" prop="numEd" sortable="custom">
        </el-table-column>
        <el-table-column align="center" label="采购完成金额" prop="moneyEd" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.moneyEd.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button plain size="small" type="primary" @click.native.prevent="
              goodsDetails(scope.row, scope.$index, tableData)
              ">明细
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--       分页      -->
    <div style="display: flex; justify-content: right; margin-top: 10px">
      <el-pagination v-if="this.search.pageSize > 0" :current-page="search.pageNo" :disabled="permission.isShow"
        :page-size="search.pageSize" :page-sizes="[10, 20, 30, 40, 50, 100]" :total="search.pageTotal"
        layout="total, jumper, prev, pager, next,sizes" @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </d2-container>
</template>

<script>
import api from '@/api'
import { downloadFileByUrl } from '@/utils/index'
const searchDefault = {
  timeStamp: [],
  goodsName: '',
  goodsCoding: '',
  oldId: '',
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0,
  field: null,
  sort: null,
}
const subDataDefault = {
  numWait: null,
  numIng: null,
  numEd: null,
  moneyWait: null,
  moneyIng: null,
  moneyEd: null
}
export default {
  name: 'purchaseSummary',
  data() {
    return {
      // 权限点
      permission: {},
      tableData: [],
      loadingList: false,
      loadingNum: false,
      exportLoading: false,
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL,
      sumData: JSON.parse(JSON.stringify(subDataDefault)),
      search: JSON.parse(JSON.stringify(searchDefault)),
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  created() {
    this.initList()
    this.getSum()
    this.seachEnterFun()
  },
  methods: {
    //键盘回车监听方法
    seachEnterFun() {
      document.onkeydown = () => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          //调用定义的搜索方法
          this.toQuery();
        }
      };
    },
    sortChange(column) {
      this.search.field = column.prop
      this.search.sort = column.order == 'ascending' ? 0 : column.order == 'descending' ? 1 : null
      this.initList()
      // column//当前列
      // column.prop//字段名
      // column.order//排序方法【ascending】是升序，【descending】是降序

    },
    handleSizeChange(val) {
      this.search.pageSize = val
      this.initList()
      this.getSum()
    },
    handleCurrentChange(val) {
      this.search.pageNo = val
      this.initList()
      this.getSum()
    },
    // 初始化页面
    async initList() {
      this.loadingList = true
      try {
        const { timeStamp, ...data } = this.search
        // const obj = {
        //   ...data,
        //   startTime: this.search.timeStamp[0] ? this.search.timeStamp[0] : '',
        //   endTime: this.search.timeStamp[1] ? this.search.timeStamp[1] : ''
        // }
        let obj = {
          "endTime": this.search.timeStamp[1] ? this.search.timeStamp[1] : '',
          "field": this.search.field,
          "goodsCoding": this.search.goodsCoding,
          "goodsName": this.search.goodsName,
          "oldId": this.search.oldId,
          "pageNo": this.search.pageNo,
          "pageSize": this.search.pageSize,
          "sort": this.search.sort,
          "startTime": this.search.timeStamp[0] ? this.search.timeStamp[0] : '',
        }

        const res = await api.getPurchaseSummaryList(obj)
        this.search.pageSize = res.body.pageSize
        this.search.pageNo = res.body.pageNo
        this.search.pageTotal = res.body.sumDataCount ? res.body.sumDataCount : 0
        this.tableData = res.body.datas
      } catch (error) {
        this.$message.error('加载数据失败')
      } finally {
        this.loadingList = false
      }
    },
    // 获取聚合统计
    async getSum() {
      this.loadingNum = true
      try {
        const { timeStamp, ...data } = this.search
        const obj = {
          ...data,
          startTime: this.search.timeStamp[0] ? this.search.timeStamp[0] : '',
          endTime: this.search.timeStamp[1] ? this.search.timeStamp[1] : ''
        }
        const res = await api.getPurchaseSummaryResult(obj)
        console.log(res)
        this.sumData.numWait = res.body.numWait ? res.body.numWait : 0
        this.sumData.numIng = res.body.numIng ? res.body.numIng : 0
        this.sumData.numEd = res.body.numEd ? res.body.numEd : 0
        this.sumData.moneyWait = res.body.moneyWait ? res.body.moneyWait : 0
        this.sumData.moneyIng = res.body.moneyIng ? res.body.moneyIng : 0
        this.sumData.moneyEd = res.body.moneyEd ? res.body.moneyEd : 0
      } catch (error) {
        this.$message.error('聚合统计数据加载失败')
        this.sumData = JSON.parse(JSON.stringify(subDataDefault))
      } finally {
        this.loadingNum = false
      }
    },
    // 搜索
    toQuery() {
      this.search.pageNo = 1
      this.initList()
      this.getSum()
    },
    // 重置
    reset() {
      this.search.nextToken = null
      this.search.pageSize = 50
      this.search.pageNo = 1
      this.search.timeStamp = []
      this.search = JSON.parse(JSON.stringify(searchDefault))
      this.initList()
      this.getSum()
    },
    // 明细
    async goodsDetails(row) {
      await this.$router.push({
        path: '/purchaseSummaryInfo',
        query: { goodsCoding: row.goodsCoding, goodsName: row.goodsName }
      })
    },
    isNumber(e) {
      if (e) {
        return Number(e)
      } else {
        return 0
      }
    },
    async exportExcel() {
      try {
        this.exportLoading = true
        this.search.pageNo = 1
        const data = await api.inventory_storage_exportPurStatical(this.search)
        downloadFileByUrl(data.body)
      } finally {
        this.exportLoading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.searchSty {
  display: flex;
}

.searchBtn {
  margin-left: 20px;
  width: 300px;
}

.numberSty {
  width: 900px;
  height: 80px;
  margin: 10px 10px 10px 0;
  display: flex;
}

.intNumSty {
  width: 250px;
  height: 80px;
  border-radius: 20px;
}

.titleName {
  font-weight: bold;
  font-size: 14px;
  width: 250px;
  margin-top: 10px;
  text-align: center;
}

.showNum {
  // width: 60%;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  display: flex;
  justify-content: space-around;
  line-height: 30px;
  border: 2px solid #999;
  border-radius: 5px;
  flex-wrap: wrap;
}

.font {
  font-weight: bolder;
  font-size: 16px;
}

.num {
  margin-left: 20px;
  font-weight: normal;
}

.topCount {
  width: 100%;
  padding: 0px 7px;
  display: flex;
  justify-content: space-around;
  /* justify-content: space-around; */
  box-shadow: 0px 0px 4px #888888;
  // border: 1px solid #000;
  min-height: 50px;
  align-items: center;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 600;
  flex-wrap: wrap;
  margin-bottom: 10px;
  border-radius: 10px;

  div {
    min-width: 250px;
  }

  span {
    color: blue;
    margin-left: 10px;
  }
}
</style>
