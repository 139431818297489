<template>
    <d2-container>
       <!--       搜索框         -->
    <div style="width:100%;出库单号">
      <div class="searchSty">
        <head-search label-style="width:100px">
          <head-search-item label="出库单号">
            <el-input
              v-model="search.outNo"
              clearable
              placeholder="请输入出库单号"
              size="small"
              @keyup.enter.native="toQuery"
            ></el-input>
          </head-search-item>
        </head-search>
        <div class=" searchBtn
              ">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="toQuery()"
          >
            搜索
          </el-button>
          <el-button
            icon="el-icon-refresh"
            size="small"
            type="warning"
            @click="reset()"
          >
            重置
          </el-button>
        </div>
      </div>
    </div>
    </d2-container>
</template>

<script>
const searchDefault = {
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0,
  outNo: ''
}
export default {
  name: 'salesReturns',

  data () {
    return {
      search: JSON.parse(JSON.stringify(searchDefault))
    }
  },

  mounted () {

  },

  methods: {
    initList () {

    },
    // 搜索
    toQuery () {
      this.search.pageNo = 1
      this.initList()
    },
    // 重置
    reset () {
      this.search = JSON.parse(JSON.stringify(searchDefault))
      this.search.nextToken = null
      this.search.pageSize = 50
      this.search.pageNo = 1
      this.initList()
    }
  }
}
</script>

<style lang="scss" scoped>
.searchSty {
  display: flex;
}
.searchBtn {
  margin-left: 20px;
}
</style>
