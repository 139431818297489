var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.titleName,
            visible: _vm.visibleDialog,
            top: "8vh",
            width: "80%"
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleDialog = $event
            },
            close: _vm.dialogClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "InfoForm",
              staticClass: "demo-ruleForm",
              staticStyle: { "font-weight": "bold" },
              attrs: {
                model: _vm.redactData,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "调拨标题", prop: "name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          disabled: _vm.titleName == "审核调拨单",
                          clearable: "",
                          size: "small"
                        },
                        model: {
                          value: _vm.redactData.name,
                          callback: function($$v) {
                            _vm.$set(_vm.redactData, "name", $$v)
                          },
                          expression: "redactData.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "来源仓库", prop: "outWarehouseId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "searchInput",
                          attrs: {
                            disabled: _vm.isEditable,
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择来源仓库",
                            size: "small"
                          },
                          on: { change: _vm.changeOutWarehouse },
                          model: {
                            value: _vm.redactData.outWarehouseId,
                            callback: function($$v) {
                              _vm.$set(_vm.redactData, "outWarehouseId", $$v)
                            },
                            expression: "redactData.outWarehouseId"
                          }
                        },
                        _vm._l(_vm.warehouseOutData, function(item) {
                          return _c("el-option", {
                            key: item.warehouseId,
                            attrs: {
                              label: item.warehouseName,
                              value: item.warehouseId,
                              title: _vm.onHoverText
                            },
                            nativeOn: {
                              mouseenter: function($event) {
                                return _vm.onMouseOver(item.warehouseName)
                              }
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "调入部门", prop: "intoDeptId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "searchInput",
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            disabled: _vm.isEditable,
                            clearable: "",
                            placeholder: "请选择调入部门",
                            size: "small"
                          },
                          on: { change: _vm.changeSet },
                          model: {
                            value: _vm.redactData.intoDeptId,
                            callback: function($$v) {
                              _vm.$set(_vm.redactData, "intoDeptId", $$v)
                            },
                            expression: "redactData.intoDeptId"
                          }
                        },
                        _vm._l(_vm.intoDept, function(item) {
                          return _c("el-option", {
                            key: item.orgId,
                            attrs: { label: item.orgName, value: item.orgId }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "调入仓库", prop: "intoWarehouseId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "searchInput",
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            disabled: _vm.disabledWarehouse || _vm.isEditable,
                            clearable: "",
                            placeholder: "请选择调入仓库",
                            size: "small"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.getIntoWarehouse($event)
                            }
                          },
                          model: {
                            value: _vm.redactData.intoWarehouseId,
                            callback: function($$v) {
                              _vm.$set(_vm.redactData, "intoWarehouseId", $$v)
                            },
                            expression: "redactData.intoWarehouseId"
                          }
                        },
                        _vm._l(_vm.intoWarehouse, function(item) {
                          return _c("el-option", {
                            key: item.warehouseId,
                            attrs: {
                              label: item.warehouseName,
                              value: item.warehouseId
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "调拨备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: this.titleName == "审核调拨单",
                      autosize: { minRows: 3, maxRows: 5 },
                      clearable: "",
                      maxlength: "240",
                      "show-word-limit": "",
                      size: "small",
                      type: "textarea"
                    },
                    model: {
                      value: _vm.redactData.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.redactData, "remark", $$v)
                      },
                      expression: "redactData.remark"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    height: "40px",
                    "background-color": "#d7d7d7"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-weight": "bolder",
                        "margin-left": "20px",
                        "line-height": "40px",
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c("div", [
                        _c("span", { staticStyle: { color: "#f56c6c" } }, [
                          _vm._v("* ")
                        ]),
                        _vm._v(" 商品列表")
                      ]),
                      _c(
                        "div",
                        { staticStyle: { "margin-right": "20px" } },
                        [
                          _vm.redactData.outWarehouseId &&
                          _vm.titleName != "审核调拨单"
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectGoods()
                                    }
                                  }
                                },
                                [_vm._v("选择商品 ")]
                              )
                            : _vm._e(),
                          _vm.titleName == "审核调拨单"
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectShelves()
                                    }
                                  }
                                },
                                [_vm._v("一键选择货架 ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "transferGoodsSty" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "transferGoods" } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingGoods,
                              expression: "loadingGoods"
                            }
                          ],
                          ref: "transferGoods",
                          staticStyle: { padding: "5px 5px 0" },
                          attrs: {
                            "cell-style": { textAlign: "center" },
                            data: _vm.redactData.transferGoods,
                            "header-cell-style": {
                              background: "#dfe6ec",
                              color: "#666666",
                              textAlign: "center"
                            },
                            "row-style": { height: "57px" },
                            "element-loading-text": "数据加载中",
                            "max-height": "430px",
                            "tooltip-effect": "dark"
                          },
                          on: { "selection-change": _vm.handleSelectionChange }
                        },
                        [
                          _vm.titleName == "审核调拨单"
                            ? _c("el-table-column", {
                                key: "selection",
                                attrs: { type: "selection", width: "55" }
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            key: "index",
                            attrs: {
                              align: "center",
                              label: "序号",
                              width: "80"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(" " + _vm._s(scope.$index + 1) + " ")
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            key: "goodsCoding",
                            attrs: {
                              align: "center",
                              label: "商品编码",
                              prop: "goodsCoding",
                              width: "150"
                            }
                          }),
                          _c("el-table-column", {
                            key: "goodsName",
                            attrs: {
                              align: "center",
                              label: "商品名称",
                              prop: "goodsName",
                              width: "200"
                            }
                          }),
                          _c("el-table-column", {
                            key: "pictureUrl",
                            attrs: {
                              align: "center",
                              label: "商品图片",
                              prop: "pictureUrl",
                              width: "150"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "40px",
                                        height: "40px"
                                      },
                                      attrs: {
                                        "preview-src-list": [
                                          _vm.downloadUrl +
                                            "/" +
                                            scope.row.pictureUrl
                                        ],
                                        src:
                                          _vm.downloadUrl +
                                          "/" +
                                          scope.row.pictureUrl
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            key: "price",
                            attrs: {
                              align: "center",
                              label: "原调拨价格",
                              width: "190"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "transferGoods." +
                                            scope.$index +
                                            ".price",
                                          rules: _vm.rules.price
                                        }
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: {
                                              "margin-right": "10px",
                                              width: "140px"
                                            },
                                            attrs: {
                                              disabled:
                                                _vm.titleName == "审核调拨单",
                                              clearable: "",
                                              placeholder: "请选择调拨价格",
                                              size: "small"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.changeGoodsMaxNum(
                                                  scope.row.goodsCoding,
                                                  scope.row.price,
                                                  scope.row.num,
                                                  scope.$index
                                                )
                                              }
                                            },
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.getGoodsInPrice([
                                                  scope.row.goodsCoding
                                                ])
                                              }
                                            },
                                            model: {
                                              value: scope.row.price,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "price",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.price"
                                            }
                                          },
                                          _vm._l(_vm.goodsInPrice, function(
                                            item
                                          ) {
                                            return _c("el-option", {
                                              key: item.price,
                                              attrs: {
                                                label: item.price,
                                                value: item.price
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            key: "outPrice",
                            attrs: {
                              align: "center",
                              label: "新调拨价格",
                              prop: "outPrice",
                              width: "250"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-input-number", {
                                      attrs: {
                                        disabled: _vm.titleName == "审核调拨单",
                                        min: 0,
                                        precision: 2,
                                        step: 10,
                                        size: "small"
                                      },
                                      model: {
                                        value: scope.row.outPrice,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "outPrice", $$v)
                                        },
                                        expression: "scope.row.outPrice"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            key: "num",
                            attrs: {
                              align: "center",
                              label: "调拨数量",
                              prop: "num",
                              width: "250"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "transferGoods." +
                                            scope.$index +
                                            ".num",
                                          rules: _vm.rules.num
                                        }
                                      },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            disabled:
                                              _vm.titleName == "审核调拨单",
                                            max: scope.row.goodsMaxNum,
                                            min: 1,
                                            step: 1,
                                            size: "small",
                                            "step-strictly": ""
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.goodsMaxNum(
                                                scope.row.goodsCoding,
                                                scope.row.price,
                                                scope.row.num,
                                                scope.$index
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row.num,
                                            callback: function($$v) {
                                              _vm.$set(scope.row, "num", $$v)
                                            },
                                            expression: "scope.row.num"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm.titleName == "审核调拨单"
                            ? _c("el-table-column", {
                                key: "areaId",
                                attrs: {
                                  align: "center",
                                  label: "入库区域",
                                  width: "190"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "transferGoods." +
                                                  scope.$index +
                                                  ".areaId",
                                                rules: _vm.rules.areaId
                                              }
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                    width: "140px"
                                                  },
                                                  attrs: {
                                                    clearable: "",
                                                    placeholder:
                                                      "请选择入库区域",
                                                    size: "small"
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.changeDisabledShelvesShelvesNum(
                                                        scope.row,
                                                        scope.$index
                                                      )
                                                    }
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.getArea(
                                                        _vm.redactData
                                                          .intoWarehouseId
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: scope.row.areaId,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "areaId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.areaId"
                                                  }
                                                },
                                                _vm._l(_vm.areaData, function(
                                                  item
                                                ) {
                                                  return _c("el-option", {
                                                    key: item.areaId,
                                                    attrs: {
                                                      label: item.areaName,
                                                      value: item.areaId
                                                    }
                                                  })
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  871825592
                                )
                              })
                            : _vm._e(),
                          _vm.titleName == "审核调拨单"
                            ? _c("el-table-column", {
                                key: "shelvesId",
                                attrs: {
                                  align: "center",
                                  label: "入库货架",
                                  width: "190"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "transferGoods." +
                                                  scope.$index +
                                                  ".shelvesId",
                                                rules: _vm.rules.shelvesId
                                              }
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                    width: "140px"
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      scope.row.disabledShelves,
                                                    clearable: "",
                                                    placeholder:
                                                      "请选择入库货架",
                                                    size: "small"
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.changeDisabledShelvesNum(
                                                        scope.row,
                                                        scope.$index
                                                      )
                                                    }
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.getShelves(
                                                        scope.row.areaId
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: scope.row.shelvesId,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "shelvesId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.shelvesId"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.ShelvesData,
                                                  function(item) {
                                                    return _c("el-option", {
                                                      key: item.shelvesId,
                                                      attrs: {
                                                        label: item.shelvesName,
                                                        value: item.shelvesId
                                                      }
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  27313903
                                )
                              })
                            : _vm._e(),
                          _vm.titleName == "审核调拨单"
                            ? _c("el-table-column", {
                                key: "shelvesNum",
                                attrs: {
                                  align: "center",
                                  label: "货架层数",
                                  width: "190"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "transferGoods." +
                                                  scope.$index +
                                                  ".shelvesNum",
                                                rules: _vm.rules.shelvesNum
                                              }
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  ref: "shelvesNumRef",
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                    width: "140px"
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      scope.row
                                                        .disabledShelvesNum,
                                                    clearable: "",
                                                    placeholder:
                                                      "请选择货架层数",
                                                    size: "small"
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.logShelvesNum(
                                                        scope.row
                                                      )
                                                    }
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.getShelvesNum(
                                                        scope.row.shelvesId,
                                                        scope.$index
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: scope.row.shelvesNum,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "shelvesNum",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.shelvesNum"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.ShelvesNumData,
                                                  function(item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.value,
                                                        value: item.value
                                                      }
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2183566849
                                )
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            key: "total",
                            attrs: { align: "center", label: "总计" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.outPrice
                                            ? scope.row.num
                                              ? (
                                                  scope.row.outPrice *
                                                  scope.row.num
                                                ).toFixed(2)
                                              : "- -"
                                            : scope.row.num
                                            ? (
                                                scope.row.price * scope.row.num
                                              ).toFixed(2)
                                            : "- -"
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm.titleName !== "审核调拨单"
                            ? _c("el-table-column", {
                                key: "operate",
                                attrs: { align: "center", label: "操作" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "danger",
                                                disable: _vm.deleteLoading,
                                                loading: _vm.deleteLoading
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteRow(
                                                    scope.$index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("删除 ")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2026255854
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-form-item", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "45%",
                          "margin-top": "15px"
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.cancel()
                              }
                            }
                          },
                          [_vm._v("取消")]
                        ),
                        this.titleName === "新增调拨单"
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  disable: _vm.submitLoading,
                                  loading: _vm.submitLoading,
                                  type: "success"
                                },
                                on: { click: _vm.submitForm }
                              },
                              [_vm._v("提交 ")]
                            )
                          : _vm._e(),
                        this.titleName === "编辑调拨单"
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  disable: _vm.editLoading,
                                  loading: _vm.editLoading,
                                  type: "primary"
                                },
                                on: { click: _vm.editForm }
                              },
                              [_vm._v("修改 ")]
                            )
                          : _vm._e(),
                        this.titleName === "审核调拨单"
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  disable: _vm.makeSureLoading,
                                  loading: _vm.makeSureLoading,
                                  type: "success"
                                },
                                on: { click: _vm.makeSure }
                              },
                              [_vm._v("确认 ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "区域,货架,层数选择",
            visible: _vm.visibleDialogArea,
            top: "25vh",
            width: "20%"
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleDialogArea = $event
            },
            close: _vm.dialogAreaClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "totalSelectionData",
              staticClass: "demo-ruleForm",
              staticStyle: { "font-weight": "bold" },
              attrs: {
                model: _vm.totalSelectionData,
                rules: _vm.area,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "入库区域", prop: "areaId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        clearable: "",
                        placeholder: "请选择入库区域",
                        size: "small"
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeDisabledTotalShelvesId(
                            _vm.totalSelectionData.areaId
                          )
                        }
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.getArea(_vm.redactData.intoWarehouseId)
                        }
                      },
                      model: {
                        value: _vm.totalSelectionData.areaId,
                        callback: function($$v) {
                          _vm.$set(_vm.totalSelectionData, "areaId", $$v)
                        },
                        expression: "totalSelectionData.areaId"
                      }
                    },
                    _vm._l(_vm.areaData, function(item) {
                      return _c("el-option", {
                        key: item.areaId,
                        attrs: { label: item.areaName, value: item.areaId }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "入库货架", prop: "shelvesId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        disabled: _vm.disabledTotalSelectionShelves,
                        clearable: "",
                        placeholder: "请选择入库货架",
                        size: "small"
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeDisabledTotalShelvesNum(
                            _vm.totalSelectionData.shelvesId
                          )
                        }
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.getShelves(_vm.totalSelectionData.areaId)
                        }
                      },
                      model: {
                        value: _vm.totalSelectionData.shelvesId,
                        callback: function($$v) {
                          _vm.$set(_vm.totalSelectionData, "shelvesId", $$v)
                        },
                        expression: "totalSelectionData.shelvesId"
                      }
                    },
                    _vm._l(_vm.ShelvesData, function(item) {
                      return _c("el-option", {
                        key: item.shelvesId,
                        attrs: {
                          label: item.shelvesName,
                          value: item.shelvesId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "货架层数", prop: "shelvesNum" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        disabled: _vm.disabledTotalSelectionShelvesNums,
                        clearable: "",
                        placeholder: "请选择货架层数",
                        size: "small"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.getShelvesNumForTotalSelection(
                            _vm.totalSelectionData.shelvesId
                          )
                        }
                      },
                      model: {
                        value: _vm.totalSelectionData.shelvesNum,
                        callback: function($$v) {
                          _vm.$set(_vm.totalSelectionData, "shelvesNum", $$v)
                        },
                        expression: "totalSelectionData.shelvesNum"
                      }
                    },
                    _vm._l(_vm.ShelvesNumDataForTotalSelection, function(
                      item,
                      index
                    ) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.value, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c("el-form-item", [
                _c(
                  "div",
                  {
                    staticStyle: { "margin-left": "40%", "margin-top": "15px" }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.abolish()
                          }
                        }
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "success" },
                        on: {
                          click: function($event) {
                            return _vm.determine()
                          }
                        }
                      },
                      [_vm._v("确定")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("setStockGoodsList", {
        ref: "setStockGoodsList",
        on: { setGoods: _vm.addGoods }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }