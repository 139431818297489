export default ({ request }) => ({
  // 分页查询
  warehouseStructure_getPageList (data = {}) {
    return request({
      url: '/v1/warehouse/getPageList',
      method: 'post',
      data: data
    })
  },
  // 查询部门
  warehouseStructure_getUserDeptSelect (data = {}) {
    return request({
      url: '/v1/organization/getUserDeptSelect',
      method: 'post',
      data: data
    })
  },
  // 查询仓库
  warehouseStructure_searchWarehouse (data = {}) {
    return request({
      url: '/v1/warehouse/searchWarehouse',
      method: 'post',
      data: data
    })
  },
  // 查询仓库详情||详情
  warehouseStructure_warehouseDetail (data = {}) {
    return request({
      url: '/v1/warehouse/warehouseDetail',
      method: 'post',
      data: data
    })
  },
  // 查询区域详情||详情
  warehouseStructure_areaDetail (data = {}) {
    return request({
      url: '/v1/warehouse/areaDetail',
      method: 'post',
      data: data
    })
  },
  // 查询区域名称
  warehouseStructure_searchArea (data = {}) {
    return request({
      url: '/v1/warehouse/searchArea',
      method: 'post',
      data: data
    })
  },
  // 新增仓库
  warehouseStructure_newWarehouse (data = {}) {
    return request({
      url: '/v1/warehouse/newWarehouse',
      method: 'post',
      data: data
    })
  },
  // 新增区域
  warehouseStructure_newArea (data = {}) {
    return request({
      url: '/v1/warehouse/newArea',
      method: 'post',
      data: data
    })
  },
  // 新增货架
  warehouseStructure_newShelves (data = {}) {
    return request({
      url: '/v1/warehouse/newShelves',
      method: 'post',
      data: data
    })
  },
  // 编辑仓库
  warehouseStructure_updateWarehouse (data = {}) {
    return request({
      url: '/v1/warehouse/updateWarehouse',
      method: 'post',
      data: data
    })
  },
  // 编辑区域
  warehouseStructure_updateArea (data = {}) {
    return request({
      url: '/v1/warehouse/updateArea',
      method: 'post',
      data: data
    })
  },
  // 编辑货架
  warehouseStructure_updateShelves (data = {}) {
    return request({
      url: '/v1/warehouse/updateShelves',
      method: 'post',
      data: data
    })
  },

  // 删除仓库
  warehouseStructure_deleteWarehouse (data = {}) {
    return request({
      url: '/v1/warehouse/deleteWarehouse',
      method: 'post',
      data: data
    })
  },
  // 删除区域
  warehouseStructure_deleteArea (data = {}) {
    return request({
      url: '/v1/warehouse/deleteArea',
      method: 'post',
      data: data
    })
  },
  // 删除货架
  warehouseStructure_deleteShelves (data = {}) {
    return request({
      url: '/v1/warehouse/deleteShelves',
      method: 'post',
      data: data
    })
  },
  // 查询所有公司以及公司Id
  warehouseStructure_getDeptSelect (data = {}) {
    return request({
      url: '/v1/organization/getDeptSelect',
      method: 'post',
      data: data
    })
  }
})
