<template>
  <div class="good">
    <div class="title">
      <span>出库明细</span>
    </div>
    <div class="table">
      <el-table
        ref="tableData"
        :data="[logInfo]"
        max-height="620px"
        element-loading-text="数据加载中"
        :cell-style="{ textAlign: 'center' }"
        tooltip-effect="dark"
        :row-style="{ height: '57px' }"
        :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center',
        }"
      >
        <el-table-column label="出库总数" prop="sum" align="center">
        </el-table-column>
        <el-table-column
          label="已扫码数量"
          prop="num"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="未扫码数量"
          prop="notNum"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      detailsList: [],
      errorList: [],
      errorVisible: false
    }
  },
  props: {
    logInfo: {
      type: Object
    }
  },
  methods: {}
}
</script>
<style lang='scss' scoped>
.title {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #b8b8b81e;
  height: 30px;
  font-weight: bold;
}
</style>
