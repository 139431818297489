export default ({ request }) => ({
    // 分页查询
    getAuditList(data = {}) {
        return request({
            url: '/v1/checkStock/checkAuditPage',
            method: 'post',
            data: data
        })
    },
    // 审核确认
    affirmAudit(data = {}) {
        return request({
            url: '/v1/checkStock/auditCheckStock',
            method: 'post',
            data: data
        })
    },
    // 明细
    getAffirmAuditParticulars(data = {}) {
        return request({
            url: '/v1/checkStock/getCheckDetail',
            method: 'post',
            data: data
        })
    }
})