export default ({ request }) => ({
  // 分页查询
  versionManagement_select (data = {}) {
    return request({
      url: '/v1/versionManagement/getPageList',
      method: 'post',
      data: data
    })
  },
  versionManagement_save (data = {}) {
    return request({
      url: '/v1/versionManagement/save',
      method: 'post',
      data: data
    })
  }
})
