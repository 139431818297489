<template>
  <div class="outStocklist">
    <d2-container>
      <head-search label-style="width:100px">
        <head-search-item label="出库单号">
          <el-input
            size="small"
            v-model="selectForm.outId"
            clearable
            placeholder="请输入订单编号"
            @keyup.enter.native="search"
          ></el-input>
        </head-search-item>
        <head-search-item label="系统订单号">
          <el-input
            collapse-tags
            size="small"
            v-model="selectForm.orderId"
            placeholder="请输入系统订单号"
            @keyup.enter.native="search"
          />
        </head-search-item>
        <head-search-item label="出库状态">
          <el-select
            collapse-tags
            size="small"
            multiple
            v-model="selectForm.statusList"
            placeholder="请选择出库状态"
            clearable
            filterable
          >
            <el-option
              v-for="item in list.outStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="出库类型">
          <el-select
            collapse-tags
            size="small"
            multiple
            v-model="selectForm.typeList"
            placeholder="请选择出库类型"
            clearable
            filterable
          >
            <el-option
              v-for="item in list.outType"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="订单来源">
          <el-select
            collapse-tags
            size="small"
            multiple
            v-model="selectForm.sourceList"
            placeholder="请选择订单来源"
            clearable
            filterable
          >
            <el-option
              v-for="item in list.outSource"
              :key="item.id"
              :label="item.values"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="商品名称">
          <el-input
            size="small"
            clearable
            v-model="selectForm.goodsName"
            placeholder="请输入商品名称"
            @keyup.enter.native="search"
          />
        </head-search-item>
        <head-search-item label="配送方式">
          <el-select
            collapse-tags
            size="small"
            multiple
            v-model="selectForm.distributionList"
            placeholder="请选择配送方式"
            clearable
            filterable
          >
            <el-option
              v-for="item in list.distributionType"
              :key="item.value"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="制单人">
          <el-select
            collapse-tags
            size="small"
            v-model="selectForm.createdId"
            placeholder="请选择制单人"
            clearable
            filterable
          >
            <el-option
              v-for="item in list.outUsers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="出库时间">
          <el-date-picker
            size="small"
            v-model="selectForm.outboundTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </head-search-item>
        <template slot="button">
          <el-button
            icon="el-icon-search"
            type="primary"
            @click="search"
            size="small"
            v-if="!auth.isShow"
          >搜索
          </el-button
          >
          <el-button
            icon="el-icon-refresh"
            type="warning"
            @click="reset"
            v-if="!auth.isShow"
            size="small"
          >重置
          </el-button
          >
          <el-button
            icon="el-icon-circle-plus-outline"
            v-if="!auth.isAdd"
            @click="handleAdd"
            type="success"
            size="small"
          >新增出库
          </el-button
          >
        </template>
      </head-search>
      <div class="table">
        <el-table
          ref="tableData"
          v-loading="outlistLoading"
          :data="outlist"
          max-height="620px"
          element-loading-text="数据加载中"
          :cell-style="{ textAlign: 'center' }"
          tooltip-effect="dark"
          :row-style="{ height: '57px' }"
          :header-cell-style="{
            background: '#dfe6ec',
            color: '#666666',
            textAlign: 'center'
          }"
        >
          <el-table-column label="序号" width="80" align="center">
            <template slot-scope="scope">
              {{
                (selectForm.pageNo - 1) * selectForm.pageSize + scope.$index + 1
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="outId"
            label="出库单号"
            width="180"
          ></el-table-column>
          <el-table-column prop="orderId" label="系统订单号" width="180">
            <template slot-scope="scope">
              {{
                scope.row.orderId && scope.row.orderId != ' ' ? scope.row.orderId : '- -'
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="出库状态"
            :formatter="outStateFormat"
          >
          </el-table-column>
          <el-table-column
            prop="type"
            label="出库类型"
            :formatter="outTypeFormat"
          >
          </el-table-column>
          <el-table-column
            prop="areaName"
            label="出库仓库"
          >
          </el-table-column>
          <el-table-column
            prop="source"
            label="订单来源"
            :formatter="sourceFormat"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="distribution"
            :formatter="distributionFormatter"
            label="配送方式"
          >
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="remark"
            label="备注"
          >
            <template slot-scope="scope">
              {{
                scope.row.remark ? scope.row.remark : '- -'
              }}
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="createdName"
            label="制单人"
          >
          </el-table-column>
          <el-table-column
            prop="createdTimestamp"
            label="创建时间"
            width="180"
          >
            <template slot-scope="scope">
              {{
                scope.row.createdTimestamp ? scope.row.createdTimestamp : '- -'
              }}
            </template>
          </el-table-column>
          <el-table-column prop="outboundTime" label="出库时间" width="180">
            <template slot-scope="scope">
              {{ scope.row.outboundTime || '- -' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300" fixed="right">
            <template slot-scope="scope">
              <el-button
                size="small"
                plain
                type="success"
                icon="el-icon-edit-outline"
                @click="handleEdit(scope.$index, scope.row)"
                v-if="scope.row.status === 0 && scope.row.type !== 1 && !auth.isEdit"
              >编辑
              </el-button>
              <el-button
                size="small"
                type="info"
                plain
                icon="el-icon-info"
                v-if="!auth.isInfo"
                @click="handleDetails(scope.$index, scope.row)"
              >
                明细
              </el-button>
              <el-button size="small" plain type="primary" @click="toStockOut(scope.row)"
                         v-show="scope.row.status == 0 || scope.row.status == 1 || scope.row.status == 2">
                {{scope.row.status == 0 ? '开始出库' : '继续出库' }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentchange"
          :current-page="selectForm.pageNo"
          :page-sizes="[10,20,30,40,50,100]"
          :page-size="selectForm.pageSize"
          layout="->,total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        ></el-pagination>
        <Dialog @dialogClose="dialogClose" ref="dialog"></Dialog>
        <el-dialog title="开始出库" :visible.sync="dialogVisible" width="80%" @close="stockOutClose" v-loading="dialogLoading">
          <el-table :data="tableData" ref="dialogTable" :border="true" height="500px" header-cell-class-name="DisableSelection">
            <el-table-column type="selection" width="55" :selectable="() => false"/>
            <el-table-column label="商品编码" prop="goodsId" align="center" width="110px"/>
            <el-table-column label="商品编码" prop="goodsName" align="center" width="110px"/>
            <el-table-column label="出库数量" prop="num" align="center" width="110px"/>
            <el-table-column label="扫码出库" prop="scanOutboundQuantity" align="center" width="110px"/>
            <el-table-column label="手动出库数量" prop="unit" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.productQuantity" size="small" type="number" style="width: 100px"
                          :disabled="scope.row.productQuantity == scope.row.manualOutboundQuantity &&
                          scope.row.manualOutboundQuantity == scope.row.num"
                          oninput="value=value.replace(/[^0-9]/g,'').slice(0,9)"/>
                <el-button v-show="scope.row.productQuantity > 0 && scope.row.manualOutboundQuantity != scope.row.num"
                           size="small" type="success" plain @click="rowSubmit(scope.row)">确定</el-button>
              </template>
            </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="dialogSubmit">确定出库</el-button>
          </span>
        </el-dialog>
      </div>
    </d2-container>
  </div>
</template>
<script>
import api from '@/api'
import Dialog from '../dialog/index.vue'
import { selectFormData, authority } from './contant'
import log from '@/libs/util.log'

var that
export default {
  components: {
    Dialog
  },
  name: 'outStockist',
  data () {
    return {
      auth: {},
      outlistLoading: false,
      selectForm: JSON.parse(JSON.stringify(selectFormData)),
      pageTotal: 0,
      list: {
        outStatus: [
          {
            value: 0,
            label: '待出库'
          },
          {
            value: 1,
            label: '出库中'
          },
          {
            value: 2,
            label: '部分出库'
          },
          {
            value: 3,
            label: '全部出库'
          },
          {
            value: 4,
            label: '终止出库'
          }
        ],
        WarehousingWarehouse: [],
        distributionType: [],
        outUsers: [],
        outSource: []
      },
      outlist: [],
      //出库
      currentRow: null,
      dialogVisible: false,
      dialogLoading: false,
      tableData: [],
      tableIndex: 1,
      tableSize: 10,
      tableTotal: 0
    }
  },
  created () {
    that = this
    this.auth = authority(this)
    this.select()
    Promise.all([
      this.warehouse(),
      this.getSelectList('DistributionEnum', 'distributionType'),
      this.getSelectList('OutTypeEnum', 'outType'),
      this.getoutSourceList(),
      this.getOutUsersList()
    ]).then(() => {
      this.$refs.dialog.setList(this.list)
    })
  },
  methods: {
    // 获得所有仓库
    async warehouse () {
      const { body: data } = await api.getPurchaseWarehouse()
      this.list.WarehousingWarehouse = data
    },
    // 获得下拉列表
    async getSelectList (name, target) {
      const { body: data } = await api.selectedEnums_getList({
        enumName: name
      })
      this.list[target] = data
    },
    async getoutSourceList () {
      const { body: data } = await api.enumeration_getList({
        name: 'out_source'
      })
      this.list.outSource = data
    },
    // 搜索
    search () {
      this.selectForm.pageNo = 1
      this.select()
    },
    // 获得出库列表
    async select () {
      this.outlistLoading = true
      const param = {
        ...this.selectForm,
        outboundTime: undefined,
        startTime: this.selectForm.outboundTime[0],
        endTime: this.selectForm.outboundTime[1]
      }
      const { body: data } = await api.outbound_getPageList(param)
      this.outlistLoading = false
      this.outlist = data.datas
      this.pageTotal = data.sumDataCount
    },
    //
    async getOutUsersList () {
      const { body: data } = await api.statisticalUser()
      this.list.outUsers = data
    },
    // 重置搜索
    reset () {
      this.selectForm = JSON.parse(JSON.stringify(selectFormData))
      this.select()
    },
    sizeChange (val) {
      this.selectForm.pageSize = val
      this.select()
    },
    currentchange (val) {
      this.selectForm.pageNo = val
      this.select()
    },
    // 打开编辑弹窗
    handleEdit (index, row) {
      this.$refs.dialog.open().edit(row)
    },
    // 打开添加弹窗
    handleAdd () {
      this.$refs.dialog.open().add()
    },
    // 打开明细弹窗
    handleDetails (index, row) {
      this.$router.push({
        path: '/stockoutdetails',
        query: {
          outId: row.outId
        }
      })
    },
    // 关闭弹窗事件
    dialogClose () {
    },
    // 出库状态格式化
    outTypeFormat (row, column, cellValue) {
      switch (cellValue) {
        case 0:
          return '调拨'
        case 1:
          return '代发'
        case 2:
          return '销售'
        case 3:
          return '其他'
        case 4:
          return '货损'
        case 5:
          return '盘亏'
      }
    },
    // 格式化出库状态
    outStateFormat (row, column, cellValue) {
      switch (cellValue) {
        case 0:
          return '待出库'
        case 1:
          return '出库中'
        case 2:
          return '部分出库'
        case 3:
          return '全部出库'
        case 4:
          return '终止出库'
      }
    },
    distributionFormatter (row, column, cellValue) {
      switch (cellValue) {
        case 0:
          return '自配送'
        case 1:
          return '门店自取'
        case 2:
          return '物流'
        case 3:
          return '其他'
        case 4:
          return '代发'
      }
    },
    sourceFormat (row, column, cellValue) {
      let res = ''
      this.list.outSource.forEach(item => {
        if (item.id === +cellValue) {
          res = item.values
        }
      })
      if (row.type == 5) {
        res = '盘库'
      }
      return res
    },
    //开始出库
    toStockOut (row) {
      this.currentRow = row
      console.log(row)
      this.getDialogData()
      this.dialogVisible = true
    },
    stockOutClose () {
      this.dialogVisible = false
    },
    async getDialogData () {
      this.dialogLoading = true
      let {body} = await api.getReadyList({
        queryType: 'YJ',
        goodsId:'',
        id: this.currentRow.id
      })
      this.dialogLoading = false
      this.tableData = body.map(c => {
        c.productQuantity = c.manualOutboundQuantity
        return c
      })
      console.log(this.tableData)
      this.$nextTick(()=>{
        that.tableData.forEach(c =>{
          if(c.manualOutboundQuantity + c.scanOutboundQuantity == c.num){
            that.$refs.dialogTable.toggleRowSelection(c);
          }
        })
      })
    },
    async rowSubmit (row) {
      row.productQuantity = parseInt(row.productQuantity)
      if(parseInt(row.scanOutboundQuantity) + row.productQuantity > row.num){
        return this.$message.warning('手动出库的数量过多')
      }
      let res = await api.outGoods({
        productQuantity: row.productQuantity,
        id: row.id
      })
      if (res.code == 200) {

        this.getDialogData()
      }
    },
    async dialogSubmit () {
      //获取已选中的信息
      let table = this.$refs.dialogTable.selection
      if(this.tableData.length != table.length){
        return this.$message.warning('请录入全部数据')
      }

      let res = await api.rowOutGoods({
        outId: this.currentRow.id
      })
      if(res.code == 200){
        this.$message.success('出库成功')
        this.dialogVisible = false
        this.select()
      }
      else{
        this.$message.error(res.msg)
      }
    }

  }
}
</script>
<style lang="scss" scoped>
::v-deep .button {
  margin-bottom: 10px;
}

::v-deep { //全选框用了fixed 用这个样式，没有用fixed就用下面那个样式
  .DisableSelection .cell .el-checkbox .el-checkbox__inner {
    background-color: var(--el-checkbox-disabled-input-fill) !important;
    border-color: var(--el-checkbox-disabled-border-color) !important;
    cursor: not-allowed !important;
  }
}

::v-deep .el-table .DisableSelection .cell .el-checkbox__inner {
  background-color: var(--el-checkbox-disabled-input-fill);
  border-color: var(--el-checkbox-disabled-border-color);
  cursor: not-allowed;
}

</style>
