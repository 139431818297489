<template>
  <d2-container>
    <!--       搜索框        -->
    <div style="width: 100%;">
      <head-search label-style="width:100px">
        <head-search-item label="采购单号">
          <el-input v-model="search.purchaseNo" clearable placeholder="请输入采购单号" size="small"></el-input>
        </head-search-item>
        <head-search-item label="采购类型">
          <el-select multiple v-model="search.purchaseType" class="searchInput" clearable placeholder="请选择采购类型"
            size="small">
            <el-option v-for="(item, index) in purchaseTypeList" :key="index" :label="item.values" :value="item.keys">
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="采购来源">
          <el-select multiple v-model="search.purchaseSource" class="searchInput" clearable placeholder="请选择采购来源"
            size="small">
            <el-option v-for="(item, index) in purchaseSourceList" :key="index" :label="item.values" :value="item.keys">
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="发货方式">
          <el-select multiple v-model="search.modeDespatch" class="searchInput" clearable placeholder="请选择发货方式"
            size="small">
            <el-option v-for="(item, index) in outWay" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="采购状态">
          <el-select multiple v-model="search.purchaseState" class="searchInput" clearable placeholder="请选择采购状态"
            size="small">
            <el-option v-for="(item, index) in purchaseStateList" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="付款方式">
          <el-select multiple v-model="search.paymentMethod" class="searchInput" clearable placeholder="请选择付款方式"
            size="small">
            <el-option v-for="(item, index) in payMethodsData" :key="index" :label="item.value" :value="item.id">
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="公司名称">
          <el-select multiple v-model="search.companyName" class="searchInput" clearable placeholder="请选择公司名称"
            size="small">
            <el-option v-for="(item, index) in companyNameList" :key="index" :label="item.orgName" :value="item.orgId">
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="部门名称">
          <el-select multiple v-model="search.industryTitle" class="searchInput" clearable placeholder="请选择部门名称"
            size="small">
            <el-option v-for="(item, index) in industryTitleList" :key="index" :label="item.orgName"
              :value="item.orgId">
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="仓库名称">
          <el-select multiple v-model="search.warehouseName" class="searchInput" clearable placeholder="请选择仓库名称"
            size="small">
            <el-option v-for="(item, index) in warehouseNameList" :key="index" :label="item.warehouseName"
              :value="item.warehouseId">
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="采购人">
          <el-select multiple v-model="search.purchasingAgent" class="searchInput" clearable placeholder="请选择采购人"
            size="small">
            <el-option v-for="(item, index) in purchasingAgentList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="审核状态">
          <el-select multiple v-model="search.auditStatus" class="searchInput" clearable placeholder="请选择审核状态"
            size="small">
            <el-option v-for="(item, index) in auditStatusState" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="商品编码">
          <el-input v-model="search.commodityCode" clearable placeholder="请输入商品编码" size="small"></el-input>
        </head-search-item>
        <head-search-item label="商品原ID">
          <el-input v-model="search.goodId" clearable placeholder="请输入商品原ID" size="small"></el-input>
        </head-search-item>
        <head-search-item label="商品名称">
          <el-input v-model="search.goodsName" clearable placeholder="请输入商品名称" size="small"></el-input>
        </head-search-item>
        <head-search-item label="创建时间">
          <el-date-picker v-model="search.time" end-placeholder="结束日期" range-separator="至" size="small"
            start-placeholder="开始日期" type="daterange" value-format="yyyy-MM-dd"></el-date-picker>
        </head-search-item>
        <div class="searchBtn">
          <el-button icon="el-icon-search" size="small" type="primary" @click="toQuery()">搜索</el-button>
          <el-button icon="el-icon-refresh" size="small" type="warning" @click="resetBtn()">重置</el-button>
          <el-button :disabled="exportLoading" :loading="exportLoading" icon="el-icon-download" size="small"
            type="success" @click="exportExcel">
            导出
          </el-button>
        </div>
      </head-search>
      <div class="topCount">
        <!-- <div>商品数量<span>{{ count.num countNum() }}</span></div>
        <div>采购金额<span>{{ count.money countMon() }}</span></div> -->
        <div>商品数量<span>{{ count.num ? count.num : 0 }}</span></div>
        <div>采购金额<span>{{ count.total ? count.total : 0 }}</span></div>
      </div>
      <el-table :data="tableData" :header-cell-style="headerClass" :cell-style="rowClass" @sort-change="sortChange"
        v-loading="loading">
        <el-table-column prop="createdTimestamp" label="创建时间" width="120"></el-table-column>
        <el-table-column prop="purchaseNo" label="采购单号" width="130"></el-table-column>
        <el-table-column prop="type" label="采购类型" width="120"></el-table-column>
        <el-table-column prop="payMethod" label="付款方式" width="100">
          <template slot-scope="scope">
            {{ pair(scope.row.payMethod, '付款方式') }}
          </template>
        </el-table-column>
        <el-table-column prop="channel" label="采购来源" width="100"></el-table-column>
        <el-table-column prop="deliveryMethod" label="发货方式" width="100">
          <template slot-scope="scope">
            {{ pair(scope.row.deliveryMethod, '发货方式') }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="采购状态" width="100">
          <template slot-scope="scope">
            {{ pair(scope.row.status, '采购状态') }}
          </template>
        </el-table-column>
        <el-table-column prop="auditStatus" label="审核状态" width="100">
          <template slot-scope="scope">
            {{ pair(scope.row.auditStatus, '审核状态') }}
          </template>
        </el-table-column>
        <el-table-column prop="goodsCoding" label="商品编码" width="150"></el-table-column>
        <el-table-column prop="goodsId" label="商品原ID" width="150"></el-table-column>
        <el-table-column prop="goodsName" label="商品名称" width="150"></el-table-column>
        <el-table-column prop="num" label="商品数量" width="150" sortable="custom"></el-table-column>
        <el-table-column prop="unitPrice" label="采购单价" width="150" sortable="custom"></el-table-column>
        <el-table-column prop="money" label="采购总价" width="150" sortable="custom"></el-table-column>
        <el-table-column prop="orgName" label="公司名称" width="150"></el-table-column>
        <el-table-column prop="deptName" label="部门名称" width="150"></el-table-column>
        <el-table-column prop="warehouseName" label="仓库名称" width="150"></el-table-column>
        <el-table-column prop="createdName" label="采购人" width="150"></el-table-column>
      </el-table>
      <!--      分页      -->
      <div style="display: flex; justify-content: right; margin-top: 10px">
        <el-pagination v-if="search.pageSize > 0" :current-page="search.pageNo" :page-size="search.pageSize"
          :page-sizes="[10, 20, 30, 40, 50, 100]" :total="search.pageTotal"
          layout="total, jumper, prev, pager, next,sizes" @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </d2-container>
</template>

<script>
import * as getListM from '@/components/getListM'
import { mapActions } from 'vuex'
import api from '@/api'
import { downloadFileByUrl } from '@/utils/index'
const searchDefault = {
  time: [],//创建时间
  purchaseNo: null,//采购单号
  purchaseType: null,//采购类型
  purchaseSource: null,//采购来源
  modeDespatch: null,//发货方式
  purchaseState: null,//采购状态
  paymentMethod: null,//付款方式
  companyName: null,//公司名称
  industryTitle: null,//部门名称
  warehouseName: null,//仓库名称
  purchasingAgent: null,//采购人
  auditStatus: null,//审核状态
  commodityCode: null,//商品编码
  goodId: null,//商品原ID
  goodsName: null,//商品名称
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0,
  field: null,
  sort: null,
}
export default {
  name: 'purchaseSummaryInfo',
  data() {
    return {
      goodsName: null,
      goodsCoding: null,
      count: {},
      search: JSON.parse(JSON.stringify(searchDefault)),
      tableData: [],
      statusData: [],
      // 发货方式
      outWay: [
        { label: '入库', value: 0 },
        { label: '代发', value: 1 }
      ],
      // 采购状态
      purchaseStateList: [
        { label: '待采购', value: 0 },
        { label: '采购中', value: 1 },
        { label: '已完成', value: 2 },
        // { label: '待终止', value: 3 },
        // { label: '已终止', value: 9 }
      ],
      // 审核状态
      auditStatusState: [
        { label: '待审核', value: 0 },
        { label: '审核通过', value: 1 },
        { label: '审核不通过', value: 2 }
      ],
      // 付款方式
      payMethodsData: [
        { id: '0', value: '对公' },
        { id: '1', value: '备用金' },
        { id: '2', value: '备用金代付' },
        { id: '3', value: '张总' },
        { id: '4', value: '上海实体店备用金' },
        { id: '5', value: '现金' },
        { id: '6', value: '上海店现金' },
        { id: '7', value: '日本备用金' },
        { id: '8', value: '挂账' },
        { id: '9', value: '日币现金' },
        { id: '10', value: '法人信用卡' },
        { id: '11', value: '员工垫付' },
        { id: '12', value: '公司转账' },
        { id: '13', value: '人民币转账' }
      ],
      loading: false,
      purchaseTypeList: [],//采购类型
      purchaseSourceList: [],//采购来源
      companyNameList: [],//公司名称
      industryTitleList: [],//部门名称
      warehouseNameList: [],//仓库名称
      purchasingAgentList: [],//采购人
      exportLoading: false
    }
  },
  watch: {
    $route(to, from) {
      this.$route.query.goodsCoding = to.query.goodsCoding
      this.$route.query.goodsName = to.query.goodsName
      this.goodsCoding = this.$route.query.goodsCoding ? JSON.parse(JSON.stringify(this.$route.query.goodsCoding)) : null
      this.goodsName = this.$route.query.goodsCoding ? JSON.parse(JSON.stringify(this.$route.query.goodsName)) : null
      if (this.goodsName && this.goodsCoding) {
        this.search.commodityCode = this.goodsCoding
        this.search.goodsName = this.goodsName
        this.toQuery()
      } else {
        this.search.commodityCode = null
        this.search.goodsName = null
        this.toQuery()
      }
    },
    async 'search.companyName'(newVal, oldVal) {
      if (newVal && newVal.length != 0) {
        let map = new Map
        let list = []
        let name = null
        this.industryTitleList = []
        if (this.search.companyName.length != 0) {
          this.search.companyName.forEach(async (e) => {
            list = await getListM.getPart(e)//部门名称
            this.companyNameList.forEach((x) => {
              if (e == x.orgId) {
                name = x.orgName
              }
            })
            list.forEach((s) => {
              s.orgName = name + '--' + s.orgName
            })
            this.industryTitleList.push(...list)
          })
        } else {
          this.industryTitleList = []
        }
        this.disabledDeptIds = false
      } else {
        this.industryTitleList = []
        this.search.industryTitle = []
        this.disabledDeptIds = true
      }
    }
  },
  created() {
    this.$store.commit('d2admin/page/modifyOpend', opened => {
      opened.forEach(current => {
        if (current.name === 'purchaseSummaryInfo') {
          if (current.query.goodsName) {
            current.meta.title = '采购明细-' + current.query.goodsName
          }
        }
      })
    })
    this.goodsCoding = this.$route.query.goodsCoding ? JSON.parse(JSON.stringify(this.$route.query.goodsCoding)) : null
    this.goodsName = this.$route.query.goodsName ? JSON.parse(JSON.stringify(this.$route.query.goodsName)) : null
    this.initialize()
    this.getCount()
    this.getPurchaseSource()
    this.getPurchaseChannel()
    this.getOrg()
    // this.getPart()
    this.getWarehouse()
    this.getPurchaseUser()
    this.seachEnterFun()
  },


  methods: {
    //键盘回车监听方法
    seachEnterFun() {
      document.onkeydown = () => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          //调用定义的搜索方法
          this.toQuery();
        }
      };
    },
    // 配对
    pair(e, t) {
      let map = new Map
      if (t == '采购状态') {
        this.purchaseStateList.forEach((e) => {
          map.set(e.value, e.label)
        })
        if (map.has(e)) {
          return map.get(e)
        }
      } else if (t == '发货方式') {
        this.outWay.forEach((e) => {
          map.set(e.value, e.label)
        })
        if (map.has(Number(e))) {
          return map.get(Number(e))
        }
      } else if (t == '付款方式') {
        this.payMethodsData.forEach((e) => {
          map.set(e.id, e.value)
        })
        if (map.has(e)) {
          return map.get(e)
        }
      } else if (t == '审核状态') {
        this.auditStatusState.forEach((e) => {
          map.set(e.value, e.label)
        })
        if (map.has(e)) {
          return map.get(e)
        }
      }
    },
    async initialize() {
      if (this.goodsName && this.goodsCoding) {
        this.search.commodityCode = this.goodsCoding
        this.search.goodsName = this.goodsName
      }
      let e = this.search
      this.loading = true
      const res = await api.getPurchaseSummaryInfoList({
        "startTime": e.time && e.time.length != 0 ? e.time[0] : null,
        "endTime": e.time && e.time.length != 0 ? e.time[1] : null,
        "field": e.field,
        "sort": e.sort,
        "goodsCoding": e.commodityCode == '' ? null : e.commodityCode,
        "goodsName": e.goodsName == '' ? null : e.goodsName,
        "pageNo": e.pageNo == '' ? null : e.pageNo,
        "pageSize": e.pageSize == '' ? null : e.pageSize,
        "purchaseNo": e.purchaseNo == '' ? null : e.purchaseNo,//采购单号
        "type": e.purchaseType == '' ? null : e.purchaseType,//采购类型
        "channel": e.purchaseSource == '' ? null : e.purchaseSource,//采购来源
        "deliveryMethod": e.modeDespatch == '' ? null : e.modeDespatch,//发货方式
        "status": e.purchaseState == '' ? null : e.purchaseState,//采购状态
        "payMethod": e.paymentMethod == '' ? null : e.paymentMethod,//付款方式
        "comIds": e.companyName == '' ? null : e.companyName,//公司ID
        "deptId": e.industryTitle == '' ? null : e.industryTitle,//部门ID
        "warehouseId": e.warehouseName == '' ? null : e.warehouseName,//仓库ID
        "createdId": e.purchasingAgent == '' ? null : e.purchasingAgent,//采购人
        "auditStatus": e.auditStatus == '' ? null : e.auditStatus,//审核状态
        "oldId": e.goodId == '' ? null : e.goodId,//商品原ID
      })
      try {
        this.search.pageTotal = res.body.sumDataCount ? res.body.sumDataCount : 0
        this.tableData = res.body.datas
        this.loading = false
      } catch (err) {

      }

    },
    async getCount() {
      let e = this.search
      const res = await api.getPurchaseSummaryInfoCount({
        "startTime": e.time && e.time.length != 0 ? e.time[0] : null,
        "endTime": e.time && e.time.length != 0 ? e.time[1] : null,
        "field": e.field,
        "sort": e.sort,
        "goodsCoding": e.commodityCode == '' ? null : e.commodityCode,
        "goodsName": e.goodsName == '' ? null : e.goodsName,
        "pageNo": e.pageNo == '' ? null : e.pageNo,
        "pageSize": e.pageSize == '' ? null : e.pageSize,
        "purchaseNo": e.purchaseNo == '' ? null : e.purchaseNo,//采购单号
        "type": e.purchaseType == '' ? null : e.purchaseType,//采购类型
        "channel": e.purchaseSource == '' ? null : e.purchaseSource,//采购来源
        "deliveryMethod": e.modeDespatch == '' ? null : e.modeDespatch,//发货方式
        "status": e.purchaseState == '' ? null : e.purchaseState,//采购状态
        "payMethod": e.paymentMethod == '' ? null : e.paymentMethod,//付款方式
        "comIds": e.companyName == '' ? null : e.companyName,//公司ID
        "deptId": e.industryTitle == '' ? null : e.industryTitle,//部门ID
        "warehouseId": e.warehouseName == '' ? null : e.warehouseName,//仓库ID
        "createdId": e.purchasingAgent == '' ? null : e.purchasingAgent,//采购人
        "auditStatus": e.auditStatus == '' ? null : e.auditStatus,//审核状态
        "oldId": e.goodId == '' ? null : e.goodId,//商品原ID
      })
      try {
        this.count = res.body
      } catch (err) {

      }
    },
    handleSizeChange(val) {
      this.search.pageSize = val
      this.initialize()
      this.getSum()
    },
    handleCurrentChange(val) {
      this.search.pageNo = val
      this.initialize()
      this.getSum()
    },
    getSum() {

    },
    toQuery() {
      this.initialize()
      this.getCount()
    },
    resetBtn() {
      this.goodsCoding = null
      this.goodsName = null
      this.search = JSON.parse(JSON.stringify(searchDefault))
      this.initialize()
      this.getCount()
    },
    // 获取采购类型
    async getPurchaseSource() {
      const res = await api.getPurchaseSelect({
        name: 'source'
      })
      const filterName = [49]
      this.purchaseTypeList =
        res.body.filter((item, index) => !filterName.includes(item.id))
    },
    // 获取采购渠道
    async getPurchaseChannel() {
      const res = await api.getPurchaseSelect({
        name: 'channel'
      })
      this.purchaseSourceList = res.body
    },
    // 查询公司
    async getOrg() {
      console.log(1);
      const { body: data } = await api.getorgselect()
      this.companyNameList = data
      console.log(2);
    },
    // // 获得部门列表
    // async getPart() {
    //   const res = await api.getDeptList({ orgPid: this.companyId })
    //   this.industryTitleList = res.body
    // },
    // 获取仓库列表
    async getWarehouse() {
      const { body: data } = await api.getPurchaseWarehouse()
      this.warehouseNameList = data
    },
    // 获得采购人列表
    async getPurchaseUser() {
      const res = await api.getPurchaseUser()
      this.purchasingAgentList = res.body
    },
    sortChange(column) {
      this.search.field = column.prop
      this.search.sort = column.order == 'ascending' ? 0 : column.order == 'descending' ? 1 : null
      this.initialize()
      this.getCount()
      // column//当前列
      // column.prop//字段名
      // column.order//排序方法【ascending】是升序，【descending】是降序

    },
    ...mapActions('d2admin/page', ['close']),
    // table样式
    headerClass() {
      return "background: #eef1f6; color: #606266;text-align:center"
    },
    rowClass() {
      return "text-align:center"
    },
    countNum() {
      let num1 = this.count.numEd ? this.count.numEd : 0
      let num2 = this.count.numIng ? this.count.numIng : 0
      let num3 = this.count.numWait ? this.count.numWait : 0
      return Number(num1 + num2 + num3)
    },
    countMon() {
      let mon1 = this.count.moneyEd ? this.count.moneyEd : 0
      let mon2 = this.count.moneyIng ? this.count.moneyIng : 0
      let mon3 = this.count.moneyWait ? this.count.moneyWait : 0
      return Number(mon1 + mon2 + mon3)
    },
    async exportExcel() {
      let e = this.search
      try {
        this.exportLoading = true
        const data = await api.inventory_storage_exportPurchase({
          "startTime": e.time && e.time.length != 0 ? e.time[0] : null,
          "endTime": e.time && e.time.length != 0 ? e.time[1] : null,
          "field": e.field,
          "sort": e.sort,
          "goodsCoding": e.commodityCode == '' ? null : e.commodityCode,
          "goodsName": e.goodsName == '' ? null : e.goodsName,
          "pageNo": e.pageNo == '' ? null : e.pageNo,
          "pageSize": e.pageSize == '' ? null : e.pageSize,
          "purchaseNo": e.purchaseNo == '' ? null : e.purchaseNo,//采购单号
          "type": e.purchaseType == '' ? null : e.purchaseType,//采购类型
          "channel": e.purchaseSource == '' ? null : e.purchaseSource,//采购来源
          "deliveryMethod": e.modeDespatch == '' ? null : e.modeDespatch,//发货方式
          "status": e.purchaseState == '' ? null : e.purchaseState,//采购状态
          "payMethod": e.paymentMethod == '' ? null : e.paymentMethod,//付款方式
          "comIds": e.companyName == '' ? null : e.companyName,//公司ID
          "deptId": e.industryTitle == '' ? null : e.industryTitle,//部门ID
          "warehouseId": e.warehouseName == '' ? null : e.warehouseName,//仓库ID
          "createdId": e.purchasingAgent == '' ? null : e.purchasingAgent,//采购人
          "auditStatus": e.auditStatus == '' ? null : e.auditStatus,//审核状态
          "oldId": e.goodId == '' ? null : e.goodId,//商品原ID
        })
        downloadFileByUrl(data.body)
      } finally {
        this.exportLoading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.searchBtn {
  margin-left: 20px;
  margin-bottom: 10px;
}

.numberSty {
  width: 900px;
  height: 80px;
  margin: 10px 10px 10px 0;
  display: flex;
}

.intNumSty {
  width: 250px;
  height: 80px;
  border-radius: 20px;
}

.titleName {
  font-weight: bold;
  font-size: 14px;
  width: 250px;
  margin-top: 10px;
  text-align: center;
}

.showNum {
  width: 60%;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  display: flex;
  justify-content: space-around;
  line-height: 30px;
  border: 2px solid #999;
  border-radius: 5px;
}

.font {
  font-weight: bolder;
  font-size: 16px;
}

.num {
  margin-left: 20px;
  font-weight: normal;
}

.allCount {
  border: 1px solid rgb(131, 131, 131);
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  align-items: center;
  margin-bottom: 10px;
  width: auto;

  div {
    min-width: 350px;
  }

  span {
    color: blue;
    margin-left: 20px;
  }
}

.topCount {
  width: 100%;
  padding: 0px 7px;
  display: flex;
  justify-content: space-around;
  /* justify-content: space-around; */
  box-shadow: 0px 0px 4px #888888;
  // border: 1px solid #000;
  min-height: 50px;
  align-items: center;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 600;
  flex-wrap: wrap;
  margin-bottom: 10px;
  border-radius: 10px;

  div {
    min-width: 250px;
  }

  span {
    color: blue;
    margin-left: 10px;
  }
}
</style>
