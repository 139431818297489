export default (function (_ref) {
  var request = _ref.request;
  return {
    // 获取调拨报表
    getAllocationDetailsList: function getAllocationDetailsList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/newStatical/getTranResult',
        method: 'post',
        data: data
      });
    },
    // 调拨的聚合
    getNewStaticalGetNewStatResponse: function getNewStaticalGetNewStatResponse() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/newStatical/getNewStatResponse',
        method: 'post',
        data: data
      });
    }
  };
});