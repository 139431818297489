var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "good" }, [
    _c("div", { staticClass: "title" }, [
      _c("span", [
        _vm._v(
          " " +
            _vm._s(
              _vm.info.enterWarehouseState === 3 &&
                _vm.info.enterWarehouseType === "代发"
                ? "用户信息"
                : "入库明细"
            ) +
            " "
        )
      ])
    ]),
    !(
      _vm.info.enterWarehouseState === 3 &&
      _vm.info.enterWarehouseType === "代发"
    )
      ? _c(
          "div",
          { staticClass: "table" },
          [
            _c(
              "el-table",
              {
                ref: "tableData",
                attrs: {
                  data: [_vm.info.detailSum],
                  "max-height": "620px",
                  "element-loading-text": "数据加载中",
                  "cell-style": { textAlign: "center" },
                  "tooltip-effect": "dark",
                  "row-style": { height: "57px" },
                  "header-cell-style": {
                    background: "#dfe6ec",
                    color: "#666666",
                    textAlign: "center"
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "入库总数", prop: "totalNum" }
                }),
                _c("el-table-column", {
                  attrs: { label: "已扫码数量", prop: "sweepCodeRecordNum" }
                }),
                _c("el-table-column", {
                  attrs: { label: "未扫码数量", prop: "withoutRecordNum" }
                }),
                _c("el-table-column", {
                  attrs: { label: "完好数量", prop: "intactNum" }
                }),
                _c("el-table-column", {
                  attrs: { label: "操作" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.errorLog(scope.row)
                                  }
                                }
                              },
                              [_vm._v(" 异常详情 ")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    408169624
                  )
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "detailsTable" },
              [
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: "异常详情",
                      visible: _vm.errorVisible,
                      width: "90%"
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.errorVisible = $event
                      }
                    }
                  },
                  [
                    _c(
                      "el-table",
                      {
                        ref: "tableData",
                        attrs: {
                          data: _vm.errorList,
                          "max-height": "620px",
                          "element-loading-text": "数据加载中",
                          "cell-style": { textAlign: "center" },
                          "tooltip-effect": "dark",
                          "row-style": { height: "57px" },
                          "header-cell-style": {
                            background: "#dfe6ec",
                            color: "#666666",
                            textAlign: "center"
                          }
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "序号" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (_vm.page.pageNo - 1) *
                                            _vm.page.pageSize +
                                            scope.$index +
                                            1
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            739483329
                          )
                        }),
                        _c("el-table-column", {
                          attrs: { label: "商品编码", prop: "goodsCoding" }
                        }),
                        _c("el-table-column", {
                          attrs: { label: "商品名称", prop: "goodsName" }
                        }),
                        _c("el-table-column", {
                          attrs: { label: "商品条码", prop: "barCode" }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "处理结果",
                            prop: "dealStatus",
                            formatter: _vm.dealStatusFormatter
                          }
                        }),
                        _c("el-table-column", {
                          attrs: { label: "处理人", prop: "disposeBy" }
                        }),
                        _c("el-table-column", {
                          attrs: { label: "处理时间", prop: "disposeTimestamp" }
                        })
                      ],
                      1
                    ),
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.page.pageNo,
                        "page-sizes": [10, 20, 30, 40, 50, 100],
                        layout: "->,total, sizes, prev, pager, next, jumper",
                        total: _vm.PageTotal
                      },
                      on: {
                        "size-change": _vm.sizeChange,
                        "current-change": _vm.currentChange,
                        "update:currentPage": function($event) {
                          return _vm.$set(_vm.page, "pageNo", $event)
                        },
                        "update:current-page": function($event) {
                          return _vm.$set(_vm.page, "pageNo", $event)
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "table" },
          [
            _vm.info.enterWarehouseState === 3 &&
            _vm.info.enterWarehouseType === "代发"
              ? _c(
                  "el-table",
                  {
                    attrs: {
                      data: [_vm.info],
                      "max-height": "620px",
                      "element-loading-text": "数据加载中",
                      "cell-style": { textAlign: "center" },
                      "tooltip-effect": "dark",
                      "row-style": { height: "57px" },
                      "header-cell-style": {
                        background: "#dfe6ec",
                        color: "#666666",
                        textAlign: "center"
                      }
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "收货姓名", prop: "deliveryUserName" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "收货电话", prop: "deliveryPhone" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "收货地址", prop: "deliveryAddress" }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }