export default (function (_ref) {
  var request = _ref.request;
  return {
    // 分页查询
    getLimit_ip: function getLimit_ip() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/ip/getLimit',
        method: 'post',
        data: data
      });
    },
    // 新增
    insertRow_ip: function insertRow_ip() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/ip/insertRow',
        method: 'post',
        data: data
      });
    },
    // 编辑
    updateRow_ip: function updateRow_ip() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/ip/updateRow',
        method: 'put',
        data: data
      });
    },
    // 删除
    deleteRow_ip: function deleteRow_ip() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/ip/deleteRow',
        method: 'delete',
        data: data
      });
    }
  };
});