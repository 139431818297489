<template>
  <div>
    <div class="dashboard-editor-container">
      <div class="whSty" id="totalWH">
        <div>
          <div class="tabSty">
            <div class="singSty">
              <div class="verticalBar"></div>
              <span class="textSty">订单数量与金额</span>
            </div>
            <div>
              <el-date-picker
                v-if="this.search.type === '日'"
                v-model="search.time"
                align="right"
                type="date"
                size="small"
                placeholder="选择日期"
                :clearable="false"
                :picker-options="pickerOptionsDate"
              >
              </el-date-picker>
              <el-date-picker
                v-if="this.search.type === '月'"
                size="small"
                v-model="search.time"
                type="month"
                placeholder="选择月"
                :clearable="false"
                :picker-options="pickerOptionsMonth"
              >
              </el-date-picker>
              <el-date-picker
                v-if="this.search.type === '年'"
                size="small"
                v-model="search.time"
                type="year"
                placeholder="选择年"
                :clearable="false"
                :picker-options="pickerOptionsYear"
              >
              </el-date-picker>
              <el-radio-group
                v-model="search.type"
                size="small"
                style="margin-left: 20px;"
              >
                <el-radio-button label="日"></el-radio-button>
                <el-radio-button label="月"></el-radio-button>
                <el-radio-button label="年"></el-radio-button>
              </el-radio-group>
              <el-button
                type="success"
                plain
                size="small"
                style="margin-left: 20px;"
                @click="getSearch()"
                >搜索</el-button
              >
            </div>
          </div>
        </div>
        <!-- 创建柱状图 -->
        <div
          v-loading="loadingEcharts"
          style="height:88%;width: 100%;margin-left: 10px"
        >
          <div
            ref="mixtureChart"
            id="mixtureChart"
            style="width: 100%;height:95%;"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api'
const searchDefault = {
  time: new Date(), // 获取日期与时间
  type: '日'
}
const lineChartDefault = {
  purchaseNum: [], // 采购商品
  issuingNum: [], // 代发商品
  allotNum: [], // 调拨商品
  intoNum: [], // 入库商品
  outNum: [] // 出库商品
}
const columnarChartDefault = {
  purchaseNum: [], // 采购商品
  issuingNum: [], // 代发商品
  allotNum: [], // 调拨商品
  intoNum: [], // 入库商品
  outNum: [] // 出库商品
}
export default {
  name: 'columnarStatisticalChart',
  data () {
    return {
      search: JSON.parse(JSON.stringify(searchDefault)),
      moneyList: JSON.parse(JSON.stringify(lineChartDefault)), // 采购金额
      countList: JSON.parse(JSON.stringify(columnarChartDefault)), // 采购商品
      loadingEcharts: false,
      dateData: [], // 日期数组
      pickerOptionsDate: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      pickerOptionsMonth: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      pickerOptionsYear: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  created () {},
  mounted () {
    this.initList()
  },
  methods: {
    async initList () {
      console.log(this.search.time)
      const myDate = new Date(this.search.time)
      var Y = myDate.getFullYear() // 获取当前完整年份
      var M = myDate.getMonth() + 1 // 获取当前月份
      var D = myDate.getDate() // 获取当前日1-31
      var H = myDate.getHours() // 获取当前小时
      var i = myDate.getMinutes() // 获取当前分钟
      var s = myDate.getSeconds() // 获取当前秒数
      // 月份不足10补0
      if (M < 10) {
        M = '0' + M
      }
      // 日不足10补0
      if (D < 10) {
        D = '0' + D
      }
      // 小时不足10补0
      if (H < 10) {
        H = '0' + H
      }
      // 分钟不足10补0
      if (i < 10) {
        i = '0' + i
      }
      // 秒数不足10补0
      if (s < 10) {
        s = '0' + s
      }
      // 拼接日期分隔符根据自己的需要来修改
      var changeDate = Y + '-' + M + '-' + D
      var changeMonth = Y + '-' + M
      var changeYear = Y
      if (this.search.type === '日') {
        this.search.time = changeDate.toString()
      } else if (this.search.type === '月') {
        this.search.time = changeMonth.toString()
      } else if (this.search.type === '年') {
        this.search.time = changeYear.toString()
      }
      this.$echarts.init(this.$refs.mixtureChart).dispose()
      this.moneyList = JSON.parse(JSON.stringify(lineChartDefault))
      this.countList = JSON.parse(JSON.stringify(columnarChartDefault))
      this.dateData = []
      this.loadingEcharts = true
      this.$nextTick(() => {
        this.initChart()
        this.loadingEcharts = false
      })
      const res = await api.getColumnarStatistical({
        time: this.search.time,
        type:
          this.search.type === '日'
            ? 'day'
            : this.search.type === '月'
              ? 'month'
              : this.search.type === '年'
                ? 'year'
                : ''
      })
      if (res.code === 200) {
        console.log(res.body)
        this.dateData = res.body.countList.map(v => v.time) // 日期转换
        this.countList.purchaseNum = res.body.countList.map(function (item) {
          return item.purchaseNum
        })
        this.countList.outNum = res.body.countList.map(function (item) {
          return item.outNum
        })
        this.countList.intoNum = res.body.countList.map(function (item) {
          return item.intoNum
        })
        this.countList.allotNum = res.body.countList.map(function (item) {
          return item.allotNum
        })
        this.countList.issuingNum = res.body.countList.map(function (item) {
          return item.issuingNum
        })
        // -----------
        this.moneyList.purchaseNum = res.body.moneyList.map(function (item) {
          return item.purchaseNum
        })
        this.moneyList.outNum = res.body.moneyList.map(function (item) {
          return item.outNum
        })
        this.moneyList.intoNum = res.body.moneyList.map(function (item) {
          return item.intoNum
        })
        this.moneyList.allotNum = res.body.moneyList.map(function (item) {
          return item.allotNum
        })
        this.moneyList.issuingNum = res.body.moneyList.map(function (item) {
          return item.issuingNum
        })
        this.initChart()
      } else {
        this.loadingEcharts = false
        this.$message.error('柱状图加载失败')
      }
    },
    initChart () {
      const myChart = this.$echarts.init(this.$refs.mixtureChart)
      const option = {
        legend: {
          data: [
            '采购订单',
            '代发订单',
            '调拨订单',
            '入库订单',
            '出库订单',
            '采购金额',
            '代发金额',
            '调拨金额',
            '入库金额',
            '出库金额'
          ],
          selected: {
            采购金额: true,
            代发金额: true,
            调拨金额: true,
            入库金额: true,
            出库金额: true
          }
        },
        xAxis: {
          type: 'category',
          data: this.dateData,
          name: '日期', // x轴名称
          nameTextStyle: {
            fontWeight: 600,
            fontSize: 12,
            color: '#000'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          textStyle: {
            align: 'left'
          },
          formatter: function (params) {
            let result = ''
            let content1 = ''
            let content2 = ''
            params.map((item, index) => {
              if (index < 5) {
                content1 += `${item.marker} ${item.seriesName}<span style='margin-left:5px;margin-right:20px'></span><span style='font-size:16px;font-weight: 600;float:right;width:50px'>${item.data}</span><br/>`
              }
              if (index > 4) {
                content2 += `${item.marker} ${item.seriesName}<span style='margin-left:5px;margin-right:20px'></span><span style='font-size:16px;font-weight: 600;float:right;width:50px'>${item.data}</span><br/>`
              }
              result = `<span style='font-size: 14px;margin-bottom:px'>${item.name}</span> <br/><div style='width:350px;display:flex'><div style='margin-right:15px'>${content1}</div><div >${content2}</div></div>`
            })
            return result
          }
        },
        yAxis: {
          name: '数量 / 金额',
          type: 'value',
          nameTextStyle: {
            fontWeight: 600,
            fontSize: 12,
            color: '#000'
          }
        },
        grid: {
          left: '5%',
          bottom: '2%',
          width: '85%',
          containLabel: true
        },
        series: [
          {
            name: '采购数量',
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              barBorderRadius: [2, 2, 0, 0]
            },
            data: this.countList.purchaseNum
          },
          {
            name: '代发数量',
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              barBorderRadius: [2, 2, 0, 0]
            },
            data: this.countList.issuingNum
          },
          {
            name: '调拨数量',
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              barBorderRadius: [2, 2, 0, 0]
            },
            data: this.countList.allotNum
          },
          {
            name: '入库数量',
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              barBorderRadius: [2, 2, 0, 0]
            },
            data: this.countList.intoNum
          },
          {
            name: '出库数量',
            type: 'bar',
            barMaxWidth: 30,
            yAxisIndex: 0, // 对应y轴的那一组坐标
            itemStyle: {
              barBorderRadius: [2, 2, 0, 0]
            },
            data: this.countList.outNum
          },
          {
            name: '采购金额',
            type: 'line',
            data: this.moneyList.purchaseNum
          },
          {
            name: '代发金额',
            type: 'line',
            data: this.moneyList.issuingNum
          },
          {
            name: '调拨金额',
            type: 'line',
            data: this.moneyList.allotNum
          },
          {
            name: '入库金额',
            type: 'line',
            data: this.moneyList.intoNum
          },
          {
            name: '出库金额',
            type: 'line',
            data: this.moneyList.outNum
          }
        ]
      }
      myChart.setOption(option)
    },
    getSearch () {
      console.log(this.search.time)
      this.initList()
    }
  }
}
</script>
<style scoped>
.dashboard-editor-container {
  margin-top: 20px;
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #ffffff;
  padding: 0px 10px 0px 0px;
  box-shadow: 0px 0px 4px #999999;
  border-radius: 5px;
}
.whSty {
  width: 100%;
  height: 640px;
}
.tabSty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}
.singSty {
  display: flex;
  height: 20px;
  padding-left: 10px;
}
.verticalBar {
  width: 10px;
  line-height: 20px;
  border-radius: 25%;
  background-color: #8ddece;
  margin-right: 5px;
}
.textSty {
  line-height: 20px;
  font-weight: bold;
}
</style>
