import { uniqueId } from 'lodash'

/**
 * @description 给菜单数据补充上 path 字段
 * @description https://github.com/d2-projects/d2-admin/issues/209
 * @param {Array} menu 原始的菜单数据
 */
export function supplementPath (menu) {
  return menu.map(e => ({
    ...e,
    path: e.path || uniqueId('d2-menu-empty-'),
    ...e.children ? {
      children: supplementPath(e.children)
    } : {}
  }))
}
export function getRouters (oldMenus) {
  var routers = []
  oldMenus.body.forEach(element => {
    element.children.forEach(res => {
      var router = {
        path: res.path,
        name: res.name,
        meta: {
          title: res.title,
          auth: true
        },
        componentStr: res.component
      }
      routers.push(router)
    })
  })
  return routers
}
export function getChildrens (childrens) {
  var menus = []
  childrens.forEach(element => {
    if (!element.hidden) {
      return
    }
    var menu = {
      title: element.title,
      icon: element.icon,
      path: element.path
    }
    menus.push(menu)
  })
  return supplementPath(menus)
}
export function getLeftMenus (oldMenus) {
  var menus = []
  oldMenus.body.forEach(element => {
    if (!element.hidden) {
      return
    }
    var menu = {
      title: element.title,
      icon: element.icon,
      path: element.path,
      children: getChildrens(element.children)
    }
    if (element.location === 'left' || element.location === 'all') {
      menus.push(menu)
    }
  })
  return supplementPath(menus)
}
export function getTopMenus (oldMenus) {
  var menus = []
  oldMenus.body.forEach(element => {
    if (!element.hidden) {
      return
    }
    var menu = {
      title: element.title,
      icon: element.icon,
      path: element.path,
      children: getChildrens(element.children)
    }
    if (element.location === 'top' || element.location === 'all') {
      menus.push(menu)
    }
  })
  return supplementPath(menus)
}
export const menuHeader = supplementPath([
  { path: '/index', title: '首页', icon: 'home' },
  {
    title: '开发工具',
    icon: 'folder-o',
    children: [
      { path: '/generateConfig', title: '生成配置' }
    ]
  },
  {
    title: '系统管理',
    icon: 'folder-o',
    children: [
      { path: '/userlist', title: '用户管理' },
      { path: '/roleList', title: '角色管理' },
      { path: '/enumeration', title: '枚举管理' },
      { path: '/menulist', title: '菜单管理' }
    ]
  }
])

export const menuAside = supplementPath([
  { path: '/index', title: '首页', icon: 'home' },
  {
    title: '开发工具',
    icon: 'folder-o',
    children: [
      { path: '/generateConfig', title: '生成配置' }
    ]
  },
  {
    title: '系统管理',
    icon: 'folder-o',
    children: [
      { path: '/userlist', title: '用户管理' },
      { path: '/roleList', title: '角色管理' },
      { path: '/enumeration', title: '枚举管理' },
      { path: '/menulist', title: '菜单管理' },
      { path: '/logList', title: '日志管理' }
    ]
  }
])
