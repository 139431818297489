<template>
  <d2-container>
    <head-search label-style='width:100px'>
      <head-search-item label='调拨单号'>
        <el-input v-model="header.transferId" size="small" placeholder="请输入调拨单号"></el-input>
      </head-search-item>
      <head-search-item label='商品编码'>
        <el-input v-model="header.goodsCoding" size="small" placeholder="请输入商品编码"></el-input>
      </head-search-item>
      <head-search-item label='商品原ID'>
        <el-input v-model="header.goodsId" size="small" placeholder="请输入商品原ID"></el-input>
      </head-search-item>
      <head-search-item label='商品名称'>
        <el-input v-model="header.goodsName" size="small" placeholder="请输入商品名称"></el-input>
      </head-search-item>
      <head-search-item label="创建人">
        <el-select multiple v-model="header.createdName" class="searchInput" clearable placeholder="请选择创建人"
          size="small">
          <el-option v-for="(item, index) in userList" :key="index" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="来源仓库">
        <el-select multiple v-model="header.outWarehouseId" class="searchInput" clearable placeholder="请选择来源仓库"
          size="small">
          <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.warehouseName"
            :value="item.warehouseId">
          </el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="调入仓库">
        <el-select multiple v-model="header.intoWarehouseId" class="searchInput" clearable placeholder="请选择调入仓库"
          size="small">
          <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.warehouseName"
            :value="item.warehouseId">
          </el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="公司名称">
        <el-select multiple v-model="header.orgIds" class="searchInput" clearable placeholder="请选择公司名称" size="small">
          <el-option v-for="(item, index) in companyList" :key="index" :label="item.orgName" :value="item.orgId">
          </el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="调入部门">
        <el-select multiple v-model="header.intoDeptId" class="searchInput" clearable placeholder="请选择调入部门" size="small"
          :disabled="disabledDeptIds">
          <el-option v-for="(item, index) in branchList" :key="index" :label="item.orgName" :value="item.orgId">
          </el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="审核人">
        <el-select multiple v-model="header.applyName" class="searchInput" clearable placeholder="请选择审核人" size="small">
          <el-option v-for="(item, index) in userList" :key="index" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </head-search-item>
      <head-search-item label='统计日期'>
        <el-date-picker v-model="header.time" end-placeholder="结束日期" range-separator="至" size="small"
          start-placeholder="开始日期" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </head-search-item>
      <div class="searchBtn">
        <el-button icon="el-icon-search" size="small" type="primary" @click="toQuery()">搜索</el-button>
        <el-button icon="el-icon-refresh" size="small" type="warning" @click="resetBtn()">重置</el-button>
        <el-button :disabled="exportLoading" :loading="exportLoading" icon="el-icon-download" size="small"
          type="success" @click="exportExcel">
          导出
        </el-button>
      </div>
    </head-search>
    <div class="topCount">
      <div>调拨数量：<span>{{ isNumber(count.num).toFixed(2) }}</span></div>
      <div>调拨总价：<span>{{ isNumber(count.total).toFixed(2) }}</span></div>
    </div>
    <el-table :data="tableData" :header-cell-style="headerClass" :cell-style="rowClass" v-loading="loading"
      @sort-change="sortChange">
      <el-table-column prop="createdTimestamp" label="调拨时间"></el-table-column>
      <el-table-column prop="transferId" label="调拨单号"></el-table-column>
      <el-table-column prop="outWarehouseName" label="来源仓库"></el-table-column>
      <el-table-column prop="intoWarehouseName" label="调入仓库"></el-table-column>
      <el-table-column prop="intoDeptName" label="调入部门"></el-table-column>
      <el-table-column prop="goodsCoding" label="商品编码"></el-table-column>
      <el-table-column prop="goodsId" label="商品原ID"></el-table-column>
      <el-table-column prop="goodsName" label="商品名称"></el-table-column>
      <el-table-column prop="num" label="调拨数量" sortable="custom"></el-table-column>
      <el-table-column prop="price" label="原调拨价格" sortable="custom"></el-table-column>
      <el-table-column prop="outPrice" label="新调拨价格" sortable="custom"></el-table-column>
      <el-table-column prop="total" label="调拨总价" sortable="custom"></el-table-column>
      <el-table-column prop="createdName" label="创建人"></el-table-column>
      <el-table-column prop="applyName" label="审核人"></el-table-column>
    </el-table>
    <div style="display: flex; justify-content: right; margin-top: 10px">
      <el-pagination v-if="search.pageSize > 0" :current-page="search.pageNo" :page-size="search.pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100]" :total="search.pageTotal"
        layout="total, jumper, prev, pager, next,sizes" @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </d2-container>
</template>

<script>

let searchSource = {
  ipName: '',
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0
}
let headerSource = {
  id: [],
  transferId: null,//调拨单号
  applyIds: null,
  time: [],//时间
  goodsCoding: null,//商品编码
  goodsId: null,//商品原ID
  goodsName: null,//商品名称
  createdName: [],//创建人
  outWarehouseId: [],//调出仓库
  intoWarehouseId: [],//调入仓库
  intoDeptId: [],//调入部门
  orgIds: [],//公司
  applyName: [],//审核人
  field: null,
  sort: null,
}
import api from '@/api'
import { downloadFileByUrl } from '@/utils/index'
import *as getListM from '../getListM'
export default {
  name: 'allocationDetails',
  data() {
    return {
      count: {},
      companyList: [],
      disabledDeptIds: false,
      userList: [],//创建人
      warehouseList: [],//仓库
      branchList: [],//部门
      loading: false,
      header: JSON.parse(JSON.stringify(headerSource)),
      search: JSON.parse(JSON.stringify(searchSource)),
      list: [],
      tableData: [],
      exportLoading: false
    }
  },
  async mounted() {
    this.initialize()
    this.countGet()
    this.userList = await getListM.getUser() // 用户查询
    this.orderSourceList = await getListM.getOrderSource()//查询订单来源
    this.companyList = await getListM.getOrg()//公司名称
    this.warehouseList = await getListM.getWarehouse()//仓库名称
    this.seachEnterFun()
  },
  watch: {
    async 'header.orgIds'(newVal, oldVal) {
      if (newVal && newVal.length != 0) {
        let map = new Map
        let list = []
        let name = null
        this.branchList = []
        if (this.header.orgIds.length != 0) {
          this.header.orgIds.forEach(async (e) => {
            list = await getListM.getPart(e)//部门名称
            this.companyList.forEach((x) => {
              if (e == x.orgId) {
                name = x.orgName
              }
            })
            list.forEach((s) => {
              s.orgName = name + '--' + s.orgName
            })
            this.branchList.push(...list)
          })
        }
        this.disabledDeptIds = false
      } else {
        this.branchList = []
        this.header.intoDeptId = []
        this.disabledDeptIds = true
      }
    }
  },
  methods: {
    isNumber(e) {
      if (e) {
        return Number(e)
      } else {
        return 0
      }
    },
    sortChange(column) {
      this.header.field = column.prop
      this.header.sort = column.order == 'ascending' ? 0 : column.order == 'descending' ? 1 : null
      if (this.header.sort == null) {
        this.header.field = null
      }
      this.initialize()
      this.getCount()
      this.countGet()
      // column//当前列
      // column.prop//字段名
      // column.order//排序方法【ascending】是升序，【descending】是降序
    },
    //键盘回车监听方法
    seachEnterFun() {
      document.onkeydown = () => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          //调用定义的搜索方法
          this.toQuery();
        }
      };
    },
    async countGet() {
      const res = await api.getNewStaticalGetNewStatResponse({
        "transferId": this.header.transferId,
        "applyIds": this.header.applyName,
        "createdIds": this.header.createdName,
        "endTime": this.header.time[1],
        "goodsCoding": this.header.goodsCoding,
        "goodsId": this.header.goodsId,
        "intoDeptIds": this.header.intoDeptId,
        "intoWarehouseIds": this.header.intoWarehouseId,
        "outWarehouseIds": this.header.outWarehouseId,
        "pageNo": this.search.pageNo,
        "pageSize": this.search.pageSize,
        "startTime": this.header.time[0],
        "goodsName": this.header.goodsName,
        "orgIds": this.header.orgIds,
        "field": this.header.field,
        "sort": this.header.sort,
      })
      try {
        this.count = res.body
      } catch (err) {

      }
    },
    async initialize() {
      this.loading = true
      const res = await api.getAllocationDetailsList({
        "transferId": this.header.transferId,
        "applyIds": this.header.applyName,
        "createdIds": this.header.createdName,
        "endTime": this.header.time[1],
        "goodsCoding": this.header.goodsCoding,
        "goodsId": this.header.goodsId,
        "intoDeptIds": this.header.intoDeptId,
        "intoWarehouseIds": this.header.intoWarehouseId,
        "outWarehouseIds": this.header.outWarehouseId,
        "pageNo": this.search.pageNo,
        "pageSize": this.search.pageSize,
        "startTime": this.header.time[0],
        "goodsName": this.header.goodsName,
        "orgIds": this.header.orgIds,
        "field": this.header.field,
        "sort": this.header.sort,
      })
      try {
        this.loading = false
        this.tableData = res.body.datas
        this.search.pageTotal = res.body.sumDataCount ? res.body.sumDataCount : 0
      } catch (err) {

      }
    },
    exportBtn() {

    },
    resetBtn() {
      this.header = JSON.parse(JSON.stringify(headerSource))
      this.initialize()
      this.countGet()
    },
    toQuery() {
      this.initialize()
      this.countGet()
    },
    // table样式
    headerClass() {
      return "background: #eef1f6; color: #606266;text-align:center"
    },
    rowClass() {
      return "text-align:center"
    },
    handleSizeChange(val) {
      this.search.pageSize = val
      this.initialize()
    },
    handleCurrentChange(val) {
      this.search.pageNo = val
      this.initialize()
    },
    async exportExcel() {
      try {
        this.exportLoading = true
        this.search.pageNo = 1
        const data = await api.inventory_storage_exportTransfer(this.search)
        downloadFileByUrl(data.body)
      } finally {
        this.exportLoading = false
      }
    },
  }
}
</script>

<style lang='scss' scoped>
.searchBtn {
  margin-left: 20px;
  margin-bottom: 10px;
}

.topCount {
  width: 100%;
  padding: 0px 7px;
  display: flex;
  justify-content: space-around;
  /* justify-content: space-around; */
  box-shadow: 0px 0px 4px #888888;
  // border: 1px solid #000;
  min-height: 50px;
  align-items: center;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 600;
  flex-wrap: wrap;
  margin-bottom: 10px;
  border-radius: 10px;

  div {
    min-width: 250px;
  }

  span {
    color: blue;
    margin-left: 10px;
  }
}
</style>
