var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "head-search",
        { attrs: { "label-style": "width:100px" } },
        [
          _c(
            "head-search-item",
            { attrs: { label: "调拨单号" } },
            [
              _c("el-input", {
                staticClass: "searchInput",
                staticStyle: { width: "200px" },
                attrs: {
                  clearable: "",
                  placeholder: "请输入调拨单号",
                  "prefix-icon": "el-icon-search",
                  size: "small"
                },
                model: {
                  value: _vm.search.transferId,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "transferId", $$v)
                  },
                  expression: "search.transferId"
                }
              })
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "调拨标题" } },
            [
              _c("el-input", {
                staticClass: "searchInput",
                staticStyle: { width: "200px" },
                attrs: {
                  clearable: "",
                  placeholder: "请输入调拨标题",
                  "prefix-icon": "el-icon-search",
                  size: "small"
                },
                model: {
                  value: _vm.search.name,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "name", $$v)
                  },
                  expression: "search.name"
                }
              })
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "来源仓库" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "searchInput",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择调来源仓库",
                    size: "small"
                  },
                  on: { change: _vm.changeOutWarehouse },
                  model: {
                    value: _vm.search.outWarehouseId,
                    callback: function($$v) {
                      _vm.$set(_vm.search, "outWarehouseId", $$v)
                    },
                    expression: "search.outWarehouseId"
                  }
                },
                _vm._l(_vm.warehouseOutData, function(item) {
                  return _c("el-option", {
                    key: item.warehouseId,
                    attrs: {
                      label: item.warehouseName,
                      value: item.warehouseId,
                      title: _vm.onHoverText
                    },
                    nativeOn: {
                      mouseenter: function($event) {
                        return _vm.onMouseOver(item.warehouseName)
                      }
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "调入部门" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "searchInput",
                  staticStyle: { "margin-right": "10px" },
                  attrs: {
                    clearable: "",
                    placeholder: "请选择调入部门",
                    size: "small"
                  },
                  on: { change: _vm.changeSet },
                  model: {
                    value: _vm.search.intoDeptId,
                    callback: function($$v) {
                      _vm.$set(_vm.search, "intoDeptId", $$v)
                    },
                    expression: "search.intoDeptId"
                  }
                },
                _vm._l(_vm.foldDepartmentData, function(item) {
                  return _c("el-option", {
                    key: item.orgId,
                    attrs: { label: item.orgName, value: item.orgId }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "调入仓库" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "searchInput",
                  staticStyle: { "margin-right": "10px" },
                  attrs: {
                    disabled: _vm.search.intoDeptId === "",
                    clearable: "",
                    placeholder: "请选择调入仓库",
                    size: "small"
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.getIntoWarehouse($event)
                    }
                  },
                  model: {
                    value: _vm.search.intoWarehouseId,
                    callback: function($$v) {
                      _vm.$set(_vm.search, "intoWarehouseId", $$v)
                    },
                    expression: "search.intoWarehouseId"
                  }
                },
                _vm._l(_vm.warehouseIntoData, function(item) {
                  return _c("el-option", {
                    key: item.warehouseId,
                    attrs: {
                      label: item.warehouseName,
                      value: item.warehouseId
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "调拨状态" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "searchInput",
                  attrs: {
                    clearable: "",
                    placeholder: "请选择状态",
                    size: "small"
                  },
                  model: {
                    value: _vm.search.status,
                    callback: function($$v) {
                      _vm.$set(_vm.search, "status", $$v)
                    },
                    expression: "search.status"
                  }
                },
                _vm._l(_vm.statusData, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label: item.value,
                      value: item.id,
                      title: _vm.onHoverText
                    },
                    nativeOn: {
                      mouseenter: function($event) {
                        return _vm.onMouseOver(item.value)
                      }
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "创建日期" } },
            [
              _c("el-date-picker", {
                staticClass: "searchTimer",
                attrs: {
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  size: "small",
                  "start-placeholder": "开始日期",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                model: {
                  value: _vm.search.submitTimeStamp,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "submitTimeStamp", $$v)
                  },
                  expression: "search.submitTimeStamp"
                }
              })
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "审核日期" } },
            [
              _c("el-date-picker", {
                staticClass: "searchTimer",
                attrs: {
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  size: "small",
                  "start-placeholder": "开始日期",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                model: {
                  value: _vm.search.auditTimeStamp,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "auditTimeStamp", $$v)
                  },
                  expression: "search.auditTimeStamp"
                }
              })
            ],
            1
          ),
          _c(
            "template",
            { slot: "button" },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    "margin-right": "10px",
                    "margin-left": "25px"
                  },
                  attrs: {
                    icon: "el-icon-search",
                    size: "small",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.searchTotal()
                    }
                  }
                },
                [_vm._v("搜索 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: {
                    icon: "el-icon-refresh",
                    size: "small",
                    type: "warning"
                  },
                  on: {
                    click: function($event) {
                      return _vm.reset()
                    }
                  }
                },
                [_vm._v("重置 ")]
              ),
              !this.managerFlag && !this.isSuper
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        icon: "el-icon-plus",
                        size: "small",
                        type: "success"
                      },
                      on: {
                        click: function($event) {
                          return _vm.add()
                        }
                      }
                    },
                    [_vm._v("新增 ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      ),
      _c(
        "el-tabs",
        {
          staticStyle: { "margin-top": "10px" },
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: this.managerFlag || this.isSuper ? "全部" : "已发起",
                name:
                  this.managerFlag || this.isSuper ? "complete" : "initiated"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "10px",
                    "margin-left": "10px",
                    "margin-right": "10px"
                  }
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingTotal,
                          expression: "loadingTotal"
                        }
                      ],
                      ref: "tableData",
                      attrs: {
                        "cell-style": { textAlign: "center" },
                        data: _vm.tableData,
                        "header-cell-style": {
                          background: "#dfe6ec",
                          color: "#666666",
                          textAlign: "center"
                        },
                        "row-style": { height: "57px" },
                        "element-loading-text": "数据加载中",
                        "max-height": "618px",
                        "tooltip-effect": "dark"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { align: "center", label: "序号", width: "80" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (_vm.page.pageNo - 1) *
                                        _vm.page.pageSize +
                                        scope.$index +
                                        1
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "创建时间",
                          prop: "createdTimestamp",
                          width: "180"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "调拨单号",
                          prop: "transferId",
                          width: "180"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "center",
                                      width: "100%"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(scope.row.transferId) + " "
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "copySty",
                                        staticStyle: { "margin-left": "20px" },
                                        on: {
                                          click: function($event) {
                                            return _vm.copyUrl(
                                              scope.row.transferId
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 复制 ")]
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          label: "调拨标题",
                          prop: "name",
                          align: "center",
                          width: "150"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "max-height": "33px",
                                      overflow: "hidden",
                                      "white-space": "nowrap",
                                      "text-overflow": "ellipsis"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(scope.row.name) + " ")]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "来源仓库",
                          prop: "outWarehouseName",
                          width: "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "调入部门",
                          prop: "intoDeptName",
                          width: "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "调入仓库",
                          prop: "intoWarehouseName",
                          width: "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "调拨状态",
                          prop: "status",
                          width: "120"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.status == 0
                                  ? _c(
                                      "div",
                                      [_c("el-tag", [_vm._v("待审核")])],
                                      1
                                    )
                                  : _vm._e(),
                                scope.row.status == 1
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-tag",
                                          { attrs: { type: "success" } },
                                          [_vm._v("完成订单")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                scope.row.status == 2
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-tag",
                                          { attrs: { type: "danger" } },
                                          [_vm._v("审核不通过")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                scope.row.status == 3
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-tag",
                                          { attrs: { type: "success" } },
                                          [_vm._v("选择库位完成")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                scope.row.status == 4
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-tag",
                                          { attrs: { type: "success" } },
                                          [_vm._v("审核通过")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                scope.row.status == 9
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-tag",
                                          { attrs: { type: "warning" } },
                                          [_vm._v("终止调拨")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          align: "center",
                          label: "备注信息",
                          prop: "remark",
                          width: "200"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "max-height": "33px",
                                      overflow: "hidden",
                                      "white-space": "nowrap",
                                      "text-overflow": "ellipsis"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.remark
                                            ? scope.row.remark
                                            : "- -"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "审核人",
                          prop: "applyName",
                          width: "120"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.applyName
                                          ? scope.row.applyName
                                          : "- -"
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "审核时间",
                          prop: "applyTimestamp",
                          width: "180"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.applyTimestamp
                                          ? scope.row.applyTimestamp
                                          : "- -"
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          fixed: "right",
                          label: "操作",
                          width: "300"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                !_vm.managerFlag &&
                                !_vm.isSuper &&
                                scope.row.status === 0
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          plain: "",
                                          size: "small",
                                          type: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.edit(
                                              scope.row,
                                              _vm.activeName
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" 编辑 ")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      plain: "",
                                      size: "small",
                                      type: "info"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.returnInfo(
                                          scope.row,
                                          _vm.activeName
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("详情 ")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "right",
                    "margin-top": "10px"
                  }
                },
                [
                  this.page.pageSize > 0
                    ? _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.page.pageNo,
                          "page-size": _vm.page.pageSize,
                          "page-sizes": [10, 20, 30, 40, 50, 100],
                          total: _vm.page.sumDataCount,
                          layout: "total, jumper, prev, pager, next,sizes"
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange
                        }
                      })
                    : _vm._e(),
                  _c("allotApplyDialog", { ref: "allotApplyDialog" })
                ],
                1
              )
            ]
          ),
          !this.managerFlag && !this.isSuper
            ? _c(
                "el-tab-pane",
                { attrs: { label: "已接收", name: "pending" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        "margin-left": "10px",
                        "margin-right": "10px"
                      }
                    },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingTotal,
                              expression: "loadingTotal"
                            }
                          ],
                          ref: "tableData",
                          attrs: {
                            "cell-style": { textAlign: "center" },
                            data: _vm.tableData,
                            "header-cell-style": {
                              background: "#dfe6ec",
                              color: "#666666",
                              textAlign: "center"
                            },
                            "row-style": { height: "57px" },
                            "element-loading-text": "数据加载中",
                            "max-height": "618px",
                            "tooltip-effect": "dark"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "序号",
                              width: "80"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            (_vm.page.pageNo - 1) *
                                              _vm.page.pageSize +
                                              scope.$index +
                                              1
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              739483329
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "创建时间",
                              prop: "createdTimestamp",
                              width: "180"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "调拨单号",
                              prop: "transferId",
                              width: "180"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "center",
                                            width: "100%"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.transferId) +
                                              " "
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "copySty",
                                              staticStyle: {
                                                "margin-left": "20px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.copyUrl(
                                                    scope.row.transferId
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" 复制 ")]
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3042129736
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "show-overflow-tooltip": true,
                              label: "调拨标题",
                              prop: "name",
                              align: "center",
                              width: "150"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "max-height": "33px",
                                            overflow: "hidden",
                                            "white-space": "nowrap",
                                            "text-overflow": "ellipsis"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.row.name) + " "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2255381753
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "来源仓库",
                              prop: "outWarehouseName",
                              width: "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "调入部门",
                              prop: "intoDeptName",
                              width: "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "调入仓库",
                              prop: "intoWarehouseName",
                              width: "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "调拨状态",
                              prop: "status",
                              width: "120"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      scope.row.status == 0
                                        ? _c(
                                            "div",
                                            [_c("el-tag", [_vm._v("待审核")])],
                                            1
                                          )
                                        : _vm._e(),
                                      scope.row.status == 1
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-tag",
                                                { attrs: { type: "success" } },
                                                [_vm._v("完成订单")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      scope.row.status == 2
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-tag",
                                                { attrs: { type: "danger" } },
                                                [_vm._v("审核不通过")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      scope.row.status == 4
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-tag",
                                                { attrs: { type: "success" } },
                                                [_vm._v("审核通过")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      scope.row.status == 3
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-tag",
                                                { attrs: { type: "success" } },
                                                [_vm._v("选择库位完成")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      scope.row.status == 9
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-tag",
                                                { attrs: { type: "warning" } },
                                                [_vm._v("终止调拨")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3805988390
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "show-overflow-tooltip": true,
                              align: "center",
                              label: "备注信息",
                              prop: "remark",
                              width: "200"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "max-height": "33px",
                                            overflow: "hidden",
                                            "white-space": "nowrap",
                                            "text-overflow": "ellipsis"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.remark
                                                  ? scope.row.remark
                                                  : "- -"
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              844028635
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "审核人",
                              prop: "applyName",
                              width: "120"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.applyName
                                                ? scope.row.applyName
                                                : "- -"
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              546488052
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "审核时间",
                              prop: "applyTimestamp",
                              width: "180"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.applyTimestamp
                                                ? scope.row.applyTimestamp
                                                : "- -"
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3290393108
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              fixed: "right",
                              label: "操作",
                              width: "350"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      scope.row.status === 4
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                plain: "",
                                                size: "small",
                                                type: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.audit(
                                                    scope.row,
                                                    _vm.activeName
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("选择货位 ")]
                                          )
                                        : _vm._e(),
                                      scope.row.status === 3
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.auditMakeSureLoading,
                                                loading:
                                                  _vm.auditMakeSureLoading,
                                                plain: "",
                                                size: "small",
                                                type: "success"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.auditMakeSure(
                                                    scope.row
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("完成订单 ")]
                                          )
                                        : _vm._e(),
                                      scope.row.status === 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                disabled: _vm.auditPassLoading,
                                                loading: _vm.auditPassLoading,
                                                plain: "",
                                                size: "small",
                                                type: "success"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.auditPass(
                                                    scope.row
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("审核通过 ")]
                                          )
                                        : _vm._e(),
                                      scope.row.status === 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.auditRejectionLoading,
                                                loading:
                                                  _vm.auditRejectionLoading,
                                                plain: "",
                                                size: "small",
                                                type: "danger"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.auditRejection(
                                                    scope.row
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("审核拒绝 ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            plain: "",
                                            size: "small",
                                            type: "info"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.returnInfo(
                                                scope.row,
                                                _vm.activeName
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("详情 ")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2208881013
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "right",
                        "margin-top": "10px"
                      }
                    },
                    [
                      this.page.pageSize > 0
                        ? _c("el-pagination", {
                            attrs: {
                              "current-page": _vm.page.pageNo,
                              "page-size": _vm.page.pageSize,
                              "page-sizes": [10, 20, 30, 40, 50, 100],
                              total: _vm.page.sumDataCount,
                              layout: "total, jumper, prev, pager, next,sizes"
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.handleCurrentChange
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c("allotApplyDialog", {
        ref: "allotApplyDialog",
        on: { customEvent: _vm.searchTotal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }