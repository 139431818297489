<template>
  <d2-container>
    <div class="header">
      <div class="searchBgc1" style="margin-bottom: 20px">基本信息</div>
      <el-form :model="redactData" ref="redactData" label-width="100px" :rules="rules" class="ruleSty">
        <div class="div2" :style="redactData.purchaseType === tag4
          ? 'display:flex;justify-content: left;'
          : ''
        ">
          <div class="div3" :style="redactData.purchaseType === tag4 ? 'display:flex' : ''">
            <div class="div1">
              <el-form-item label="采购类型" prop="purchaseType">
                <el-select v-model="redactData.purchaseType" @change="changePurchaseType" placeholder="请选择采购类型"
                           size="small" clearable>
                  <el-option v-for="item in purchaseTypesData" :key="item.keys" :label="item.values" :value="item.keys"
                             v-bind:title="onHoverText" @mouseenter.native="onMouseOver(item.values)"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="渠道" prop="channel" v-if="this.redactData.purchaseType !== this.tag4">
                <el-select v-model="redactData.channel" placeholder="请选择渠道" size="small" clearable>
                  <el-option v-for="item in channelsData" :key="item.keys" :label="item.values" :value="item.keys"
                             v-bind:title="onHoverText" @mouseenter.native="onMouseOver(item.values)"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="付款方式" prop="payMethod" v-if="this.redactData.purchaseType !== this.tag4">
                <el-select v-model="redactData.payMethod" placeholder="请选择付款方式" size="small" clearable>
                  <el-option v-for="(item, index) in payMethodsData" :key="index" :label="item.value" :value="item.id"
                             v-bind:title="onHoverText" @mouseenter.native="onMouseOver(item.values)"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="预定日期" prop="predictArriveDate" v-if="this.redactData.purchaseType === this.tag5">
                <!--                :picker-options="pickerOptions"-->
                <el-date-picker v-model="redactData.predictArriveDate" type="date" style="width:215px" size="small"
                                value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
              </el-form-item>
            </div>
            <div>
              <div class="div1">
                <el-form-item label="发货方式" prop="deliveryMethod" v-if="this.redactData.purchaseType !== this.tag4">
                  <el-select @change="deliveryMethodChange" v-model="redactData.deliveryMethod" placeholder="请选择发货方式"
                             size="small" clearable>
                    <el-option v-for="(item, index) in deliveryMethodsData" :key="index" :label="item.value"
                               :value="item.id" v-bind:title="onHoverText"
                               @mouseenter.native="onMouseOver(item.value)"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="收货人" prop="deliveryUserName" v-if="returnSty()">
                  <el-input v-model="redactData.deliveryUserName" placeholder="请输入收货人" clearable style="width:215px"
                            size="small"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="deliveryPhone" v-if="returnSty()">
                  <el-input v-model.trim="redactData.deliveryPhone" placeholder="请输入收货人联系方式" clearable maxlength="11"
                            style="width:215px" size="small"></el-input>
                </el-form-item>
                <el-form-item label="入库仓库" prop="warehouseId">
                  <el-select v-model="redactData.warehouseId" placeholder="请选择入库仓库" size="small" clearable
                             @click.native="openWarehouseId()" @change="getWarehouseAddress(redactData.warehouseId)">
                    <el-option v-for="(item, index) in warehouseIdsData" :key="index" :label="item.warehouseName"
                               :value="item.warehouseId" :disabled="item.disabled" v-bind:title="onHoverText"
                               @mouseenter.native="onMouseOver(item.value)"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="来源仓库" prop="sourceWarehouseId" v-if="this.redactData.purchaseType === this.tag4 &&
        this.redactData.warehouseId !== ''
        ">
                  <el-select v-model="redactData.sourceWarehouseId" placeholder="请选择来源仓库" size="small" clearable
                             @change="changeSourceWarehouseId()" @click.native="openSourceWarehouseId()">
                    <el-option v-for="(item, index) in sourceWarehouseIdsData" :key="index" :label="item.warehouseName"
                               :value="item.warehouseId" :disabled="item.disabled" v-bind:title="onHoverText"
                               @mouseenter.native="onMouseOver(item.value)"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="仓库位置" prop="sameWarehouseFlag" v-if="this.redactData.purchaseType === this.tag4 &&
        this.redactData.sourceWarehouseId !== ''
        ">
                  <el-select v-model="redactData.sameWarehouseFlag" placeholder="请选择仓库位置" size="small" clearable
                             @change="changeSameWarehouseFlag()">
                    <el-option v-for="(item, index) in sameWarehouseFlagData" :key="index" :label="item.value"
                               :value="item.id" v-bind:title="onHoverText"
                               @mouseenter.native="onMouseOver(item.value)"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="配送方式" prop="distribution" v-if="this.redactData.purchaseType === this.tag4 &&
        this.redactData.sameWarehouseFlag === '0' &&
        this.redactData.sourceWarehouseId !== ''
        ">
                  <el-radio v-model="redactData.distribution" label="0">
                    自配送
                  </el-radio>
                  <el-radio v-model="redactData.distribution" label="2" @click.native="changeLogisticsRule()">
                    物流
                  </el-radio>
                </el-form-item>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div class="searchBgc2" style="margin-bottom: 10px;display: flex;justify-content: space-between;" v-if="this.redactData.purchaseType !== '调拨' ||
        (this.redactData.sameWarehouseFlag !== '1' &&
          this.redactData.distribution === '2')
        ">
          物流信息
        </div>
        <div class="div1">
          <el-form-item label="物流公司" prop="courierCompany" v-if="this.redactData.purchaseType !== '调拨' ||
        (this.redactData.sameWarehouseFlag !== '1' &&
          this.redactData.distribution === '2')
        ">
            <el-input v-model="redactData.courierCompany" placeholder="请输入物流公司" clearable style="width:215px"
                      size="small"></el-input>
          </el-form-item>
          <el-form-item label="物流单号" prop="trackingNumber" v-if="this.redactData.purchaseType !== '调拨' ||
        (this.redactData.sameWarehouseFlag !== '1' &&
          this.redactData.distribution === '2')
        ">
            <el-input v-model="redactData.trackingNumber" placeholder="请输入物流单号" clearable style="width:215px"
                      size="small"></el-input>
          </el-form-item>
          <el-form-item label="收货地址" prop="deliveryAddress" v-if="this.redactData.purchaseType !== '调拨' ||
        (this.redactData.sameWarehouseFlag !== '1' &&
          this.redactData.distribution === '2')
        ">
            <el-input v-model="redactData.deliveryAddress" placeholder="请输入收货地址" clearable style="width:530px"
                      size="small"></el-input>
          </el-form-item>
        </div>
        <div class="searchBgc2" style="margin-bottom: 10px;display: flex;justify-content: space-between;"
             v-if="this.redactData.purchaseType !== this.tag4">
          交易截图
        </div>
        <el-form-item label="付款截图" v-if="this.redactData.purchaseType !== this.tag4">
          <erp-upload-img :showDelete="true" v-model="redactData.payProve"
                          :upload-path="'/purchasingManagement/purchasing/'" :maxCount="10"></erp-upload-img>
        </el-form-item>
        <div class="searchBgc2" style="margin-bottom: 10px;display: flex;justify-content: space-between;">
          商品信息
          <el-button icon="el-icon-circle-plus-outline" type="success" @click="addItems()" size="small"
                     style="margin-right: 10px;">
            添加
          </el-button>
        </div>
        <el-form-item class="itemAddSty">
          <el-table ref="itemAddData" :data="redactData.itemAddData" max-height="690px" element-loading-text="数据加载中"
                    :cell-style="{ textAlign: 'center' }" tooltip-effect="dark" style="box-sizing: border-box;"
                    :row-style="{ height: '77px' }" :header-cell-style="{
        background: '#dfe6ec',
        color: '#666666',
        textAlign: 'center'
      }">
            <el-table-column label="商品编码" prop="goodsCoding" align="center" width="100">
              <template slot-scope="scope">
                <div style="white-space: nowrap;width: 80%;font-size: 10px;cursor: pointer;color: #1482f0;">
                  {{ scope.row.goodsCoding }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="商品名称" prop="goodsName" align="center" width="300">
              <template slot-scope="scope">
                <div
                  style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;">
                  {{ scope.row.goodsName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="商品图片" prop="pictureUrl" align="center" width="80">
              <template slot-scope="scope">
                <img v-if="scope.row.pictureUrl !== '' && scope.row.pictureUrl !== null
        " :src="downloadUrl + '/' + scope.row.pictureUrl" alt=""
                     style="width: 50px; height: 50px;margin-top: 8px;cursor: pointer"
                     @click="getPreview(downloadUrl + '/' + scope.row.pictureUrl)"/>
                <div v-if="scope.row.pictureUrl === '' || scope.row.pictureUrl === null
        "
                     style="width: 50px;height: 50px;border: 1px solid #c0c0c0;display: flex;justify-content: center;margin: 0 auto;">
                </div>
              </template>
            </el-table-column>
            <el-table-column label="分类" prop="goodsOne" align="center" width="120">
              <template slot-scope="scope">
                <div
                  style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;">
                  {{ scope.row.goodsOne === null ? '- -' : scope.row.goodsOne }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="品牌" prop="goodsBrandName" align="center" width="120">
              <template slot-scope="scope">
                <div
                  style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;">
                  {{
                    scope.row.goodsBrandName === null
                      ? '- -'
                      : scope.row.goodsBrandName
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="商品IP" prop="goodsIpName" align="center" width="120">
              <template slot-scope="scope">
                <div
                  style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;">
                  {{ scope.row.goodsIpName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="我的入库价(元)" prop="warehousePriceRange" width="120" align="center">
              <template slot-scope="scope">
                <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                  {{
                    scope.row.warehousePriceRange === null ||
                    scope.row.warehousePriceRange === undefined
                      ? '- -'
                      : scope.row.warehousePriceRange
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="我的库存数量" prop="inventoryNum" width="120" align="center">
              <template slot-scope="scope">
                <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                  {{
                    scope.row.inventoryNum === null ||
                    scope.row.inventoryNum === undefined
                      ? '- -'
                      : scope.row.inventoryNum
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="我的在途数量" prop="inTransitNum" width="120" align="center">
              <template slot-scope="scope">
                <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                  {{
                    scope.row.inTransitNum === null ||
                    scope.row.inTransitNum === undefined
                      ? '- -'
                      : scope.row.inTransitNum
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="采购数量" align="center" width="150">
              <template slot-scope="scope">
                <!--<el-form-item :prop="'itemAddData.' + scope.$index + '.num'" :rules="rules.num">
                  <el-input-number v-model.number="scope.row.num" :precision="0" :step="1" :min="1"
                                   size="small" />
                </el-form-item>-->
                <el-input-number v-model.number="scope.row.num" :precision="0" :step="1" :min="1"
                                 size="small" />
              </template>
            </el-table-column>
            <el-table-column v-if="this.redactData.purchaseType !== this.tag4" label="采购单价" prop="unitPrice"
                             align="center" width="150">
              <template slot-scope="scope">
                <!--<el-form-item :prop="'itemAddData.' + scope.$index + '.unitPrice'" :rules="rules.unitPrice">
                  <el-input-number v-model="scope.row.unitPrice" :precision="2" :step="1" :min="0" size="small" />
                </el-form-item>-->
                <el-input-number v-model="scope.row.unitPrice" :precision="2" :step="1" :min="0" size="small" />
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100" align="center">
              <template slot-scope="scope">
                <el-button type="danger" icon="el-icon-delete" plain size="small" @click.native.prevent="
        remove(scope.$index, scope.row, redactData.itemAddData)
        ">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <div class="textSty" v-if="redactData.itemAddData.length > 0">
          <div>
            <span>合计数量：</span>
            <span class="spanSty" style="margin-right:10px">
              {{ isNaN(count.totalNum) ? 0 : count.totalNum }}
            </span>
          </div>
          <div v-if="this.redactData.purchaseType !== this.tag4">
            <span>合计金额：</span>
            <span class="spanSty">
              {{
                isNaN(count.totalAmount)
                  ? 0
                  : count.totalAmount / 100 > 0
                    ? (count.totalAmount / 100).toFixed(2)
                    : 0
              }}
            </span>
            &nbsp;元
          </div>
        </div>
        <div class="searchBgc2" style="margin-bottom: 20px;">
          备注信息
        </div>
        <el-form-item label="备注信息">
          <el-input style="width: 99%" type="textarea" :autosize="{ minRows: 5, maxRows: 10 }" placeholder="请输入备注信息"
                    v-model="redactData.remark" maxlength="240" show-word-limit></el-input>
        </el-form-item>
        <div style="display: flex;justify-content: center;margin-bottom: 20px;">
          <el-button size="small" @click="resetForm('redactData')">
            关闭
          </el-button>
          <el-button size="small" type="primary" :disabled="submitLoading" :loading="submitLoading"
                     @click.native.prevent="submitForm()">
            保存
          </el-button>
        </div>
      </el-form>
    </div>

    <!--     点击图片放大弹出页面         -->
    <el-dialog :append-to-body="true" :title="judgment" :visible.sync="dialogFormVisiblePic" @close="closeDialogPic()"
               width="540px" style="margin:20px auto" :close-on-click-modal="false">
      <img v-if="this.dialogImageUrl !== ''" style=" width: 500px; height: 500px; text-align: center" alt=""
           :src="this.dialogImageUrl"/>
    </el-dialog>

    <purchaseSelectGoods ref="purchaseSelectGoods" @setGoods="selectGoods"></purchaseSelectGoods>
  </d2-container>
</template>
<script>
import api from '@/api'
import { mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
const redactDataDefault = {
  purchaseType: '', // 采购
  channel: '', // 渠道
  payMethod: '', // 付款方式
  payProve: [], // 付款截图
  predictArriveDate: '', // 预定日期
  deliveryMethod: '', // 发货方式
  deliveryUserName: '', // 收货人
  deliveryPhone: '', // 收货人联系方式
  warehouseId: '', // 入库仓库
  sourceWarehouseId: '', // 来源仓库
  sameWarehouseFlag: '', //  是否来自同一仓库
  distribution: '', // 配送方式
  deliveryAddress: '', // 收获地址
  courierCompany: '', // 物流公司
  trackingNumber: '', // 物流单号
  remark: '', // 备注
  itemAddData: [
    // 前端存放 添加商品数据
  ], // 新增商品Data
  productList: [] // 给后台处理数据的数组
}
var that;
export default {
  name: 'newlyPurchasing',
  directives: {
    // 防止el-button重复点击
    preventReClick: {
      // 指令的定义
      inserted (el, binding) {
        el.addEventListener('click', () => {
          if (!el.disabled) {
            el.disabled = true
            setTimeout(() => {
              el.disabled = false
            }, binding.value || 5000)
          }
        })
      }
    }
  },
  data () {
    return {
      onHoverText: '', // 鼠标以上显示文字
      purchaseTypesData: [], // 采购类型Data
      channelsData: [], // 渠道Data
      warehouseIdsData: [], // 仓库Data
      sourceWarehouseIdsData: [], // 来源仓库Data
      sameWarehouseFlagData: [
        // 是否同一地点
        {
          id: '0',
          value: '不同地点(需扫码)'
        },
        {
          id: '1',
          value: '与入库仓库同一地点'
        }
      ],
      payMethodsData: [
        {
          id: '0',
          value: '对公'
        },
        {
          id: '1',
          value: '备用金'
        },
        {
          id: '2',
          value: '备用金代付'
        },
        {
          id: '3',
          value: '张总'
        },
        {
          id: '4',
          value: '上海实体店备用金'
        },
        {
          id: '5',
          value: '现金'
        },
        {
          id: '6',
          value: '上海店现金'
        },
        {
          id: '7',
          value: '日本备用金'
        },
        {
          id: '8',
          value: '挂账'
        },
        {
          id: '9',
          value: '日币现金'
        },
        {
          id: '10',
          value: '法人信用卡'
        },
        {
          id: '11',
          value: '员工垫付'
        },
        {
          id: '12',
          value: '公司转账'
        },
        {
          id: '13',
          value: '人民币转账'
        }
      ], // 付款方式
      deliveryMethodsData: [
        {
          id: '0',
          value: '入库'
        },
        {
          id: '1',
          value: '代发'
        }
      ], // 发货方式Data
      redactData:  cloneDeep(redactDataDefault), // 新增Data
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL, // downloadUrl
      judgment: '', // 标题
      submitLoading: false,
      dialogFormVisiblePic: false, // 弹框开启
      dialogImageUrl: '', // 图片路径
      totalNum: 0, // 合计数量
      totalAmount: 0, // 合计金额
      tag1: '欠货',
      tag2: '补货',
      tag3: '冲抵',
      tag4: '调拨',
      tag5: '预定',
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000 + 1
        }
      },
      rules: {
        purchaseType: [
          {
            required: true,
            message: '请选择采购类型',
            trigger: 'change'
          }
        ],
        channel: [{
          required: true,
          message: '请选择渠道',
          trigger: 'change'
        }],
        payMethod: [
          {
            required: true,
            message: '请选择付款方式',
            trigger: 'change'
          }
        ],
        deliveryMethod: [
          {
            required: true,
            message: '请选择发货方式',
            trigger: 'change'
          }
        ],
        deliveryUserName: [
          {
            required: true,
            message: '请输入收货人姓名',
            trigger: 'blur'
          },
          {
            pattern: /^(?!\s+)/,
            message: '首不能为空格',
            trigger: 'blur'
          }
        ],
        deliveryPhone: [
          {
            required: true,
            message: '请输入收货人联系方式',
            trigger: 'blur'
          },
          {
            pattern: /^(?!\s+)/,
            message: '首不能为空格',
            trigger: 'blur'
          }
          // {
          //   pattern: /^(1[35784]\d{9})$/,
          //   message: '请输入正确的手机号码'
          // }
        ],
        predictArriveDate: [
          {
            required: true,
            message: '请选择预定日期',
            trigger: 'change'
          }
        ],
        warehouseId: [
          {
            required: true,
            message: '请选择仓库',
            trigger: 'change'
          }
        ],
        sourceWarehouseId: [
          {
            required: true,
            message: '请选择来源仓库',
            trigger: 'change'
          }
        ],
        sameWarehouseFlag: [
          {
            required: true,
            message: '请选择仓库位置',
            trigger: 'change'
          }
        ],
        distribution: [
          {
            required: true,
            message: '请选择配送方式',
            trigger: 'change'
          }
        ],
        courierCompany: [
          {
            required: false,
            message: '请填写物流公司',
            trigger: 'blur'
          }
        ],
        trackingNumber: [
          {
            required: false,
            message: '请填写物流单号',
            trigger: 'blur'
          }
        ],
        deliveryAddress: [
          {
            pattern: /^(?!\s+)/,
            message: '首不能为空格',
            trigger: 'blur'
          }
        ],
        itemAddData: [
          {
            trigger: 'change',
            validator: (rule, value, callback) => {
              this.validateItemAddData(rule, value, callback, 'cover')
            }
          }
        ],
        num: [{
          required: true,
          message: '请输入采购数量',
          trigger: 'blur'
        }],
        unitPrice: [
          {
            required: true,
            message: '请输入采购价格',
            trigger: 'blur'
          },
          {
            pattern: /^\d+(\.\d{1,2})?$/,
            message: '请输入大于等于0，且小数点后不能超过两位',
            trigger: 'blur'
          },
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              this.validateNum(rule, value, callback, 'cover')
            }
          }
        ]
      },

      isSave: null,
    }
  },
  async created () {
    that = this
    // 获取数据
    const db = await that.$store.dispatch('d2admin/db/database',{
      user:true
    })
    const data = JSON.parse(db.get('newlyPurchasing').value() ?? "{}" ) // 'value'
    // 将数据还原到页面
    if (data && data.isSave === 'readyload') {
      this.$confirm('是否使用上一次未保存的数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        for (const key in data) {
          if (that.hasOwnProperty(key)) that[key] = data[key]
        }
        that.isSave = null
        that.$forceUpdate()
      }).catch(() => {
        that.getPurchaseSource()
        that.getPurchaseChannel()
        //清空
        db.set('newlyPurchasing',JSON.stringify({})).write();
      });
    }
    else{
      this.getPurchaseSource()
      this.getPurchaseChannel()
      //清空
      db.set('newlyPurchasing',JSON.stringify({})).write();
    }
  },
  computed: {
    count () {
      let num = 0
      let price = 0
      this.redactData.itemAddData.forEach(e => {
        const unitPrice = Number(e.unitPrice) * 100
        num += e.num ? Number(e.num) : null
        price += unitPrice * e.num ? Number(unitPrice) * Number(e.num) : null
      })
      return {
        totalNum: num,
        totalAmount: price
      }
    }
  },
  async beforeDestroy () {
    //如果没有保存成功 存储页面快照(根据用户私有化)
    const db = await that.$store.dispatch('d2admin/db/database', {
      user: true
    })

    if (!that.isSave) {
      that.isSave = 'readyload'
      db.set('newlyPurchasing',JSON.stringify(cloneDeep(that._data))).write();
    } else {
      //清空
      db.set('newlyPurchasing',JSON.stringify({})).write();
    }
  },
  methods: {
    ...mapActions('d2admin/page', ['close']),
    // 返回样式
    returnSty () {
      if (this.redactData.purchaseType !== this.tag4) {
        if (this.redactData.deliveryMethod === '1') {
          return true
        } else {
          return false
        }
      }
    },
    // 点击切换物流校验
    changeLogisticsRule () {
      this.rules.courierCompany[0].required = true
      this.rules.trackingNumber[0].required = true
    },
    // 点击切换来演仓库
    changeSourceWarehouseId () {
      this.redactData.sameWarehouseFlag = ''
      this.redactData.distribution = ''
    },
    // 点击入库仓库
    openWarehouseId () {
      this.getPurchaseWarehouse()
    },
    // 点击来源仓库
    openSourceWarehouseId () {
      this.getPurchaseSourceWarehouseIds()
    },
    // 获取采购类型
    async getPurchaseSource () {
      const res = await api.getPurchaseSelect({
        name: 'source'
      })
      const filterName = [49]
      this.purchaseTypesData =
        res.body.filter((item, index) => !filterName.includes(item.id))
    },
    // 获取采购渠道
    async getPurchaseChannel () {
      const res = await api.getPurchaseSelect({
        name: 'channel'
      })
      this.channelsData = res.body
    },
    // 获取入库仓库
    async getPurchaseWarehouse () {
      this.warehouseIdsData = []
      let data = []
      const res = await api.getPurchaseWarehouse()
      data = res.body
      data.forEach(e => {
        const obj = {
          warehouseAddress: e.warehouseAddress,
          warehouseId: e.warehouseId,
          warehouseName: e.warehouseName,
          disabled: false
        }
        this.warehouseIdsData.push(obj)
      })
      // console.log(this.warehouseIdsData, 'this.warehouseIdsData')
    },
    // 获取来源仓库
    async getPurchaseSourceWarehouseIds () {
      this.sourceWarehouseIdsData = []
      let data = []
      const res = await api.getPurchaseSourceWarehouseIds()
      data = res.body
      for (let i = 0; i < data.length; i++) {
        if (this.redactData.warehouseId === data[i].warehouseId) {
          data[i].disabled = true
        } else {
          data[i].disabled = false
        }
      }
      console.log(data)
      this.sourceWarehouseIdsData = data
      // console.log(this.sourceWarehouseIdsData, 'this.sourceWarehouseIdsData')
    },
    // 鼠标以上显示完整名称
    onMouseOver (item) {
      this.onHoverText = item
    },
    // 获取入库仓库地址
    getWarehouseAddress (val) {
      this.redactData.itemAddData = []
      this.redactData.sourceWarehouseId = ''
      this.warehouseIdsData.forEach(e => {
        if (val === e.warehouseId) {
          this.redactData.deliveryAddress = e.warehouseAddress
          if (this.redactData.deliveryMethod == '1') {
            this.redactData.deliveryAddress = ''
          }
        } else if (val === '') {
          this.redactData.deliveryAddress = ''
        }
      })
    },
    // 仓库位置改变
    changeSameWarehouseFlag () {
      // this.redactData.sameWarehouseFlag = ''
      this.redactData.distribution = ''
    },
    // 发货方式改变
    deliveryMethodChange (val) {
      if (val == 1) {
        this.redactData.deliveryAddress = ''
      }
    },
    // 采购类型改变
    changePurchaseType () {
      this.$refs.redactData.clearValidate()
      const oldPurchaseType = JSON.parse(
        JSON.stringify(this.redactData.purchaseType)
      )
      this.redactData = cloneDeep(redactDataDefault)
      this.redactData.purchaseType = oldPurchaseType
    },
    // 图片放大开启
    getPreview (payProve) {
      this.judgment = '放大图片'
      this.dialogFormVisiblePic = true
      this.dialogImageUrl = payProve
    },
    // 图片放大关闭
    closeDialogPic () {
      this.dialogFormVisiblePic = false
      this.dialogImageUrl = ''
    },
    // 添加商品
    addItems () {
      // if (this.redactData.sourceWarehouseId !== '' || this.redactData.warehouseId !== '') {
      //   this.$refs.purchaseSelectGoods.open()
      // } else {
      //   this.$message({
      //     type: 'warning',
      //     message: '请先选择来源仓库及入库仓库以便添加商品!'
      //   })
      // }
      if (this.redactData.purchaseType === '调拨') {
        if (this.redactData.sourceWarehouseId !== '') {
          this.$refs.purchaseSelectGoods.open()
        } else {
          this.$message({
            type: 'warning',
            message: '请先选择来源仓库及入库仓库以便添加商品!'
          })
        }
      } else {
        if (this.redactData.warehouseId !== '') {
          this.$refs.purchaseSelectGoods.open()
        } else {
          this.$message({
            type: 'warning',
            message: '请先选择入库仓库以便添加商品!'
          })
        }
      }
    },
    // 选择商品
    async selectGoods (data) {
      console.log(this.redactData.itemAddData, 'this.redactData.itemAddData')
      console.log(data, 'data')
      if (this.redactData.purchaseType === '调拨') {
        if (this.redactData.itemAddData.length !== 0) {
          let num = 0
          for (let i = 0; i < this.redactData.itemAddData.length; i++) {
            for (let j = 0; j < data.length; j++) {
              if (
                this.redactData.itemAddData[i].goodsCoding ===
                data[j].goodsCoding
              ) {
                num++
              }
            }
          }
          if (num !== 0) {
            this.$message({
              type: 'warning',
              message: '调拨时相同商品选择一次即可!'
            })
          }
        } else {
          const array = []
          data.map(e => {
            array.push(e.goodsCoding)
          })
          const res = await api.getMineProductInfo({
            goodsCodings: array,
            warehouseId: this.redactData.warehouseId
          })
          data.map(e => {
            const obj = {
              goodsBrandName: e.goodsBrandName,
              goodsCoding: e.goodsCoding,
              goodsIpName: e.goodsIpName,
              goodsName: e.goodsName,
              goodsOne: e.goodsOne,
              id: e.id,
              pictureUrl: e.pictureUrl
            }
            this.redactData.itemAddData.push(obj)
          })
          console.log(this.redactData.itemAddData, 'redactData.itemAddData')
          for (var i = 0; i < res.body.length; i++) {
            for (var j = 0; j < this.redactData.itemAddData.length; j++) {
              if (
                res.body[i].goodsCoding ===
                this.redactData.itemAddData[j].goodsCoding
              ) {
                console.log(this.redactData.itemAddData[j])
                this.redactData.itemAddData[j].inTransitNum =
                  res.body[i].inTransitNum
                this.redactData.itemAddData[j].inventoryNum =
                  res.body[i].inventoryNum
                this.redactData.itemAddData[j].warehousePriceRange =
                  res.body[i].warehousePriceRange
              }
            }
          }
        }
      } else {
        const array = []
        data.map(e => {
          array.push(e.goodsCoding)
        })
        const res = await api.getMineProductInfo({
          goodsCodings: array,
          warehouseId: this.redactData.warehouseId
        })
        data.map(e => {
          const obj = {
            goodsBrandName: e.goodsBrandName,
            goodsCoding: e.goodsCoding,
            goodsIpName: e.goodsIpName,
            goodsName: e.goodsName,
            goodsOne: e.goodsOne,
            id: e.id,
            pictureUrl: e.pictureUrl
          }
          this.redactData.itemAddData.push(obj)
        })
        console.log(this.redactData.itemAddData, 'redactData.itemAddData')
        for (let i = 0; i < res.body.length; i++) {
          for (let j = 0; j < this.redactData.itemAddData.length; j++) {
            if (
              res.body[i].goodsCoding ===
              this.redactData.itemAddData[j].goodsCoding
            ) {
              console.log(this.redactData.itemAddData[j])
              this.redactData.itemAddData[j].inTransitNum =
                res.body[i].inTransitNum
              this.redactData.itemAddData[j].inventoryNum =
                res.body[i].inventoryNum
              this.redactData.itemAddData[j].warehousePriceRange =
                res.body[i].warehousePriceRange
            }
          }
        }
      }
    },
    // 关闭
    resetForm () {
      // this.redactData = JSON.parse(JSON.stringify(redactDataDefault))
      // this.$refs.redactData.clearValidate()
      this.close({ tagName: '/newlyPurchasing' })
      this.$router.push({ path: '/purchasing' })
    },
    // 新增保存
    submitForm () {
      if(this.redactData.itemAddData && this.redactData.itemAddData.length === 0){
        return this.$message.warning('请添加商品')
      }
      let type = this.redactData.itemAddData.every(c => /^(\d+|\d+\.\d{1,2})$/.test(c.unitPrice?.toString()) && c.num > 0)
      if(!type){
        return this.$message.warning('请输入大于零的数，采购单价最多两位小数')
      }
      this.redactData.productList = []
      this.redactData.itemAddData.map(e => {
        this.redactData.productList.push({
          goodsCoding: e.goodsCoding,
          num: e.num,
          unitPrice: e.unitPrice,
          money: e.num * e.unitPrice
        })
      })
      const obj = JSON.parse(JSON.stringify(this.redactData))
      const array = []
      if (this.redactData.predictArriveDate === '') {
        obj.predictArriveDate = null
      }
      if (this.redactData.payProve.length > 0) {
        this.redactData.payProve.map(e => {
          array.push(e.url)
          obj.payProve = array.join()
        })
      } else {
        obj.payProve = array.join()
      }
      delete obj.itemAddData
      delete obj.deleteList
      this.$refs.redactData.validate(async valid => {
        if (valid) {
          try {
            this.submitLoading = true
            const res = await api.save_purchase(obj)
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '新增成功!'
              })
              this.redactData = cloneDeep(redactDataDefault)
              this.$refs.redactData.clearValidate()
              this.isSave = true
              this.$router.replace({ path: '/purchasing' })
              this.close({ tagName: '/newlyPurchasing' })
            } else if (res.code !== 200) {
              this.$message({
                type: 'danger',
                message: '新增失败!'
              })
            }
          } catch (error) {
            console.error(error) // 打印错误信息
            this.isSave = false
            this.$message({
              type: 'danger',
              message: '新增失败!'
            })
          } finally {
            this.submitLoading = false
          }
        } else {
          this.$message({
            type: 'warning',
            message: '请填写必填内容后提交'
          })
        }
      })
    },
    // 删除按钮
    remove (index, row, rows) {
      this.$confirm('是否删除该商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (row.num === null && row.unitPrice === null) {
          rows.splice(index, 1)
        } else {
          row.num = ''
          row.unitPrice = ''
          rows.splice(index, 1)
        }
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      })
    },
    // itemAddData校验 validateNum
    validateItemAddData (rule, value, callback) {
      if (this.redactData.itemAddData.length === 0) {
        callback(new Error('请添加商品'))
      } else {
        callback()
      }
    },
    // Num校验
    validateNum (rule, value, callback) {
      if (value >= 0) {
        callback()
      } else {
        callback(new Error('请输入大于0的数字'))
      }
    }
  }
}
</script>
<style scoped lang="scss">
.header {
  margin-left: 10px;
  margin-right: 10px;
  box-sizing: border-box;
  border: 2px solid #888;
  border-radius: 10px;
  min-height: 100%;
  width: 99%;
}

.searchBgc1 {
  height: 30px;
  line-height: 30px;
  background-color: #dfe6ec;
  padding-left: 10px;
  font-weight: bolder;
  border-radius: 10px 10px 0px 0px;
}

.searchBgc2 {
  height: 30px;
  line-height: 30px;
  background-color: #dfe6ec;
  padding-left: 10px;
  font-weight: bolder;
}

.ruleSty {
  width: 100%;
}

.textSty {
  display: flex;
  justify-content: right;
  margin-right: 5%;
  color: rgb(102, 102, 102);
  font-weight: bolder;
  margin-bottom: 20px;
}

.spanSty {
  width: 5%;
  height: 17px;
  line-height: 17px;
  color: #f60;
}

.div1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  flex-grow: transition 1;
}

.div2 {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  flex-grow: transition 1;
}

.div3 {
  display: inline-block;
}

::v-deep .itemAddSty .el-form-item__content {
  margin-left: 0px !important;
  padding: 0px 10px 0px;
}

::v-deep .itemAddSty .el-form-item__error {
  // margin-left: 48.2%;
  position: relative;
  top: 0;
  left: 0;
}
</style>
