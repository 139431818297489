<template>
  <!-- 如果内容比较多可以添加top属性 -->
  <d2-container>
    <el-form :inline="true" :model="selectForm">
          <el-form-item>
         <el-input clearable size="small" :disabled="isSelect" v-model="selectForm.method" @keyup.enter.native="search()" placeholder="方法"></el-input>
      </el-form-item>
      <el-form-item>
         <el-input clearable size="small"  :disabled="isSelect" v-model="selectForm.name" @keyup.enter.native="search()" placeholder="名称标识"></el-input>
      </el-form-item>
      <el-form-item>
         <el-input clearable size="small"  :disabled="isSelect" v-model="selectForm.serviceName" @keyup.enter.native="search()" placeholder="服务名称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" :disabled="isSelect" @click="search" icon="el-icon-search">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="warning" :disabled="isSelect" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="danger" :disabled="isDelete" @click="deleteBatch" icon="el-icon-delete">删除</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          prop="className"
          label="类名称">
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称标识">
        </el-table-column>
        <el-table-column
          prop="url"
          label="请求地址">
        </el-table-column>
        <el-table-column
          prop="method"
          label="方法">
        </el-table-column>
        <el-table-column
          prop="runTime"
          label="执行时间">
        </el-table-column>
        <el-table-column
          prop="serviceName"
          label="服务名称">
        </el-table-column>
        <el-table-column
          width="100"
          prop="stats"
          label="请求状态">
          <template slot-scope="scope">
            <el-tag :type="checkStatsTypes(scope.row.stats)">{{ checkStats(scope.row.stats) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间">
        </el-table-column>
        <el-table-column
          fixed="right"
          width="200"
          label="操作">
          <template slot-scope="scope">
            <el-button
              :disabled="isSelect"
              plain
              type="info"
              size="small"
              @click="info(scope.$index, scope.row)" icon="el-icon-info">详情</el-button>
            <el-button
              :disabled="isDelete"
              plain
              size="small"
              type="danger"
              @click="dalete(scope.$index, scope.row)" icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-div">
        <el-pagination
          :current-page="this.pageNo"
          :disabled="isSelect"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10,20,30,40,50,100]"
          layout="jumper, prev, pager, next,sizes"
          :total="pageTotal">
        </el-pagination>
      </div>
      <LogEdit ref="edit" :disabled="editDisabled" :dialogVisible="dialogVisible" @close="userEditClose" @initTableData="initTableData"></LogEdit>
  </d2-container>
</template>

<script>
import api from '@/api'
import LogEdit from '../edit/index'

export default {
  name: 'LogList',
  components: {
    LogEdit
  },
  data () {
    return {
      isSelect: this.checkauthority('logs_select'),
      isCreate: this.checkauthority(''),
      isUpdate: this.checkauthority(''),
      isDelete: this.checkauthority('log_delete'),
      selectForm: {
        method: '',
        name: '',
        serviceName: ''
      },
      editDisabled: false,
      dialogVisible: false,
      pageNo: 1,
      pageSize: 50,
      tableSelects: [],
      tableData: [],
      pageTotal: 0,
      centerDialogVisible: false,
      logStats: [],
      logStatsTypes: []
    }
  },
  methods: {
    checkStats (value) {
      for (var res of this.logStats) {
        var val = parseInt(res.keys)
        if (val === value) {
          return res.values
        }
      }
    },
    checkStatsTypes (value) {
      for (var res of this.logStatsTypes) {
        var val = parseInt(res.keys)
        if (val === value) {
          return res.values
        }
      }
    },
    initEunmData () {
      this.getLogStatsEnumList('log_stats')
      this.getLogStatsTypeEnumList('log_stats_type')
    },
    async getLogStatsEnumList (name) {
      var par = {
        name: name
      }
      var res = await api.GET_ENUMERATION_LIST(par)
      this.logStats = res.body
    },
    async getLogStatsTypeEnumList (name) {
      var par = {
        name: name
      }
      var res = await api.GET_ENUMERATION_LIST(par)
      this.logStatsTypes = res.body
    },
    async updateStats (data) {
      var par = {
        id: data.id,
        stats: data.stats
      }
      await api.UPDATE_LOG(par)
      this.initTableData()
    },
    dalete (index, data) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.daleteApi(index, data)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async daleteApi (index, data) {
      var par = {
        id: data.id
      }
      await api.DELETE_LOG(par)
      this.initTableData()
    },
    async deleteBatchApi (par) {
      await api.DELETE_BATCH_LOG(par)
      this.$message.success('操作成功')
      this.initTableData()
    },
    deleteBatch () {
      if (this.tableSelects.length === 0) {
        this.$message.error('请勾选要删除的表格数据')
        return
      }
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var par = []
        this.tableSelects.forEach(element => {
          par.push(element.id)
        })
        this.deleteBatchApi(par)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    info (index, data) {
      this.editDisabled = true
      this.dialogVisible = true
      this.$refs.edit.setTitle('详情')
      this.$refs.edit.setData(data)
    },
    edit (index, data) {
      this.dialogVisible = true
      this.editDisabled = false
      this.$refs.edit.setTitle('编辑')
      this.$refs.edit.setData(data)
    },
    toAdd () {
      this.$refs.edit.setTitle('新增')
      this.editDisabled = false
      this.dialogVisible = true
      this.$refs.edit.initData()
    },
    userEditClose () {
      this.dialogVisible = false
    },
    reset () {
      this.selectForm.method = ''
      this.selectForm.name = ''
      this.selectForm.serviceName = ''
      this.initTableData()
    },
    search () {
      this.pageNo = 1
      this.initTableData()
    },
    async initTableData () {
      var par = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        method: this.selectForm.method,
        name: this.selectForm.name,
        serviceName: this.selectForm.serviceName
      }
      const res = await api.GET_LOG_PAGE_LIST(par)
      this.tableData = res.body.datas
      this.pageTotal = res.body.sumDataCount
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.initTableData()
    },
    handleCurrentChange (val) {
      this.pageNo = val
      this.initTableData()
    },
    handleSelectionChange (val) {
      this.tableSelects = val
    }
  },
  created () {
    if (this.isSelect) {
      return
    }
    this.initEunmData()
    this.initTableData()
  }
}
</script>
<style>
  .editBtn{
    width: 60px;
  }
  .statsTag {
    width: 60px;
    text-align: center;
  }
 .demo .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  /*打开时文字位置设置*/
  .demo .el-switch__label--right {
    z-index: 1;
    right: -4px;
 }
 /*关闭时文字位置设置*/
 .demo .el-switch__label--left {
    z-index: 1;
    left: 18px;
 }
 /*显示文字*/
 .demo .el-switch__label.is-active {
   display: block;
 }
 .demo.el-switch .el-switch__core,
 .el-switch .el-switch__label {
   width: 50px !important;
 }
</style>
