var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "productExpiration" }, [
    _c("div", { staticClass: "dashboard-editor-container" }, [
      _c("div", { staticClass: "whSty", attrs: { id: "totalWH" } }, [
        _c("div", [
          _c("div", { staticClass: "tabSty" }, [
            _vm._m(0),
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("div", [
                  _c(
                    "div",
                    { staticStyle: { "margin-right": "10px" } },
                    [
                      _vm._v(" 商品名称 "),
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { clearable: "", size: "small" },
                        on: { input: _vm.toQuery },
                        model: {
                          value: _vm.search.goodsName,
                          callback: function($$v) {
                            _vm.$set(_vm.search, "goodsName", $$v)
                          },
                          expression: "search.goodsName"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" 入库 "),
                _c("el-input-number", {
                  attrs: { clearable: "", size: "small" },
                  on: { change: _vm.toQuery },
                  model: {
                    value: _vm.search.days,
                    callback: function($$v) {
                      _vm.$set(_vm.search, "days", $$v)
                    },
                    expression: "search.days"
                  }
                }),
                _vm._v(" 天以上 "),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { plain: "", size: "small", type: "success" },
                    on: {
                      click: function($event) {
                        return _vm.getExcel()
                      }
                    }
                  },
                  [_vm._v("导出 ")]
                )
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "whSty" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                ref: "table",
                staticStyle: { width: "100%" },
                attrs: {
                  "cell-style": { textAlign: "center" },
                  data: _vm.tableData,
                  "header-cell-style": _vm.headerCellStyle,
                  "row-style": { height: "57px", textAlign: "center" },
                  "element-loading-text": "数据加载中",
                  "max-height": "550px",
                  "tooltip-effect": "dark"
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "序号",
                    type: "index",
                    width: "80px"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "商品编码",
                    prop: "goodsCoding",
                    width: "100"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "商品条码",
                    prop: "barCode",
                    width: "100"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "商品名称",
                    prop: "goodsName",
                    width: "100"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "入库价",
                    prop: "intoPrice",
                    width: "120"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "入库单号",
                    prop: "intoNo",
                    width: "120"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "入库时间",
                    prop: "createdTimestamp",
                    width: "120"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "公司名称",
                    prop: "orgName",
                    width: "120"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "仓库名称",
                    prop: "warehouseName",
                    width: "120"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "区域名称",
                    prop: "areaName",
                    width: "120"
                  }
                })
              ],
              1
            ),
            this.search.pageSize > 0
              ? _c("el-pagination", {
                  staticStyle: {
                    "margin-top": "10px",
                    width: "100%",
                    "text-align": "right"
                  },
                  attrs: {
                    "page-size": _vm.search.pageSize,
                    "page-sizes": [10, 20, 30, 40, 50, 100],
                    total: _vm.search.pageTotal,
                    layout: "total, jumper, prev, pager, next,sizes"
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                    "current-page": _vm.search.pageNo
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "singSty" }, [
      _c("div", { staticClass: "verticalBar" }),
      _c("span", { staticClass: "textSty" }, [_vm._v("商品到期提醒")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }