<template>
  <!-- 如果内容比较多可以添加top属性 -->
  <el-dialog :close-on-click-modal="false" :append-to-body="true" :title="titleName" :visible.sync="dialogVisible"
    width="20%" :before-close="handleClose">
    <el-form ref="form" status-icon :model="form" :rules="rules" label-width="80px">
      <el-form-item label="姓名" prop="name">
        <el-input clearable size="small" :disabled="disabled" @keyup.enter.native="save()" class="inputText"
          v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="用户名称" prop="userName">
        <el-input clearable size="small" :disabled="disabled" @keyup.enter.native="save()" class="inputText"
          v-model="form.userName"></el-input>
      </el-form-item>
      <el-form-item label="用户密码" prop="passWord">
        <el-input maxlength="20" clearable size="small" :disabled="disabled" @keyup.enter.native="save()"
          :type="titleName==='新增用户'?'text':'password'" class="inputText" v-model="form.passWord"></el-input>
      </el-form-item>
      <el-form-item label="所属公司" prop="orgId">
        <el-select :disabled="disabledCompany" clearable size="small" @change="changeCompany" v-model="form.orgId"
          placeholder="请选择所属公司">
          <el-option v-for="item in company" :key="item.orgId" :label="item.orgName" :value="item.orgId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属部门" prop="deptId">
        <el-select :disabled="disabledDepartment  || this.form.orgId === ''" clearable size="small"
          v-model="form.deptId" placeholder="请选择所属部门">
          <el-option v-for="item in part" :key="item.orgId" :label="item.orgName" :value="item.orgId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <template>
          <el-radio v-model="form.userState" :label="0">正常</el-radio>
          <el-radio v-model="form.userState" :label="1">禁用</el-radio>
        </template>
      </el-form-item>
      <el-form-item v-if="form.roleId === 2 && userType === '总经办'" label="角色分配" prop="roleId">
        <el-input disabled size="small" v-model="userType">
        </el-input>
      </el-form-item>
      <el-form-item v-else label="角色分配" prop="roleId">
        <el-select :disabled="this.form.orgId === ''" size="small" v-model="form.roleId" placeholder="请选择角色">
          <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="parseInt(item.id)">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="bottomDiv">
      <el-button @click="handleClose" size="small">关闭</el-button>
      <el-button :disabled="disabled||cannonry" type="primary" @click="save" size="small">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  name: 'UserEdit',
  data () {
    return {
      cannonry:false,
      userType: '一般用户',
      disabledCompany: true,
      disabledDepartment: true,
      company: [],
      part: [],
      titleName: '',
      roleList: [],
      options: {},
      form: {
        roleId: null,
        name: '',
        userName: '',
        passWord: '',
        roleList: [],
        deptId: '',
        orgId: '',
        userState: 0
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { message: '姓名的开头不能有空格', pattern: /^(?!\s+)/ },
          { message: '姓名不得超过20个字符', max: 20 }
        ],
        userName: [
          { required: true, message: '请输入用户名称', trigger: 'blur' },
          { message: '用户密码开头不能有空格', pattern: /^(?!\s+)/ },
          { message: '姓名不得超过20个字符', max: 20 }
        ],
        passWord: [
          { required: true, message: '请输入用户密码', trigger: 'blur' }
        ],
        roleList: [
          { required: true, message: '请分配角色', trigger: 'blur' }
        ],
        orgId: [{ required: true, message: '请选择公司', trigger: 'change' }],
        deptId: [{ required: true, message: '请选择部门', trigger: 'change' }],
        roleId: [{ required: true, message: '请选择角色', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.getCompany()
  },
  methods: {
    changeCompany () {
      if (this.form.orgId !== '') {
        this.initRolesData(this.form.orgId)
      }
      this.form.deptId = ''
      this.form.roleId = ''
      this.getPart()
    },
    async getPart () {
      const { body: data } = await api.getDeptList({ orgPid: this.form.orgId })
      this.part = data
    },
    initData (e, title) {
      this.cannonry = false
      this.titleName = title
      this.options = JSON.parse(JSON.stringify(e))
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields()
      }
      this.form.id = null
      this.form.userName = null
      this.form.name = null
      this.form.passWord = null
      this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      if (this.form.orgId !== '') {
        this.initRolesData(this.userInfo.orgId)
      }
    },
    setData (data, e, title) {
      this.titleName = title
      this.options = JSON.parse(JSON.stringify(e))
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields()
      }
      this.form = {
        ...data,
        orgId: data.orgId.toString(),
        deptId: data.deptId.toString(),
        roleId: data.roleId | ''
      }
      this.getCompany()
      this.initRolesData(this.form.orgId)
    },
    async updateApi () {
      await api.UPDATE_USER(this.form)
      this.$emit('initTableData')
      this.$message.success('操作成功')
      this.handleClose()
    },
    async saveApi () {
      await api.SAVE_USER(this.form)
      this.$emit('initTableData')
      this.$message.success('操作成功')
      this.handleClose()
    },
    save () {
      this.cannonry = true
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id != null) {
            this.$confirm('此操作将永久修改该数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.updateApi()
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消修改'
              })
              this.cannonry = false
            })
          } else {
            this.saveApi()
          }
        } else {
          this.cannonry = false
          return false
        }
      })
    },
    handleClose (done) {
      this.form.userState = 0
      this.form.passWord = ''
      this.form.orgId = ''
      this.form.deptId = ''
      this.roleList = []
      this.$emit('close')
    },
    async initRolesData (orgId) {
      const { body: data } = await api.GET_ROLE_SELECT({ name: '', orgId: orgId })
      this.roleList = data
    },
    async getCompany () {
      this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      const res = await api.getDeptList({ orgPid: -1 })
      this.company = res.body
      // is super账号
      if (this.userInfo.orgId === 0 && this.userInfo.deptId === 0) {
        this.disabledCompany = false
        this.disabledDepartment = false
        this.getPart()
        this.userType = 'super'
      }
      if (this.userInfo.managerFlag) {
        // is 总经办账号
        this.userType = '总经办'
        this.form.orgId = JSON.parse(JSON.stringify(this.userInfo.orgId))
        this.getPart()
        this.disabledDepartment = false
        return
      }
      this.userType = '一般用户'
      // 普通用户直接赋值
      this.getPart()
    }
  }
}
</script>

<style scoped lang="scss">
.inputText {}

::v-deep .el-input__validateIcon {
  display: none !important;
}

.bottomDiv {
  width: 100%;
  text-align: center;
  // display: flex;
  // justify-content: right;
}

::v-deep .sty {
  margin-left: 10px;

  .el-input {
    width: 107% !important;
  }
}
</style>
