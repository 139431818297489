export default ({ request }) => ({
  // 分页查询
  getPageList_damagefreightType (params = {}, query = {}) {
    return request({
      url: '/v1/enumeration/getPageList',
      method: 'post',
      params: params,
      data: query
    })
  },
  // 新增
  save_damagefreightType (data = {}) {
    return request({
      url: '/v1/enumeration/save',
      method: 'post',
      data: data
    })
  },
  // 编辑
  update_damagefreightType (data = {}) {
    return request({
      url: '/v1/enumeration/update',
      method: 'put',
      data: data
    })
  },
  // 删除
  delete_damagefreightType (data = {}) {
    return request({
      url: '/v1/enumeration/delete',
      method: 'delete',
      data: data
    })
  }
})
