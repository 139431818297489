export default ({ request }) => ({
  // 统计报表 用户查询
  statisticalUser (data = {}) {
    return request({
      url: '/v1/user/likeUser',
      method: 'post',
      data: data
    })
  }
})
