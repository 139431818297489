export default ({ request }) => ({
  // 选中一级分类 获取下一级数据
  getListByFatherIdToGoods (data = {}) {
    return request({
      url: '/v1/classify/getListByFatherIdToGoods',
      method: 'post',
      data: data
    })
  },
  // 一级分类，二级分类，三级分类 查询
  getList_Classify (data = {}) {
    return request({
      url: '/v1/classify/getList',
      method: 'post',
      data: data
    })
  },
  // 商品品牌 查询
  selectAll_brand (data = {}) {
    return request({
      url: '/v1/brand/selectAll',
      method: 'post',
      data: data
    })
  },
  // 商品ip 查询
  getListIp_ip (data = {}) {
    return request({
      url: '/v1/ip/getList',
      method: 'post',
      data: data
    })
  },
  // 分页查询
  getPageList_goods (data = {}) {
    return request({
      url: '/v1/goods/getPageList',
      method: 'post',
      data: data
    })
  },
  // 关联商品查询
  betweenDetail (data = {}) {
    return request({
      url: '/v1/goods/betweenDetail',
      method: 'post',
      data: data
    })
  },
  // 关联商品删除
  betweenDelete (data = {}) {
    return request({
      url: '/v1/goods/betweenDelete',
      method: 'delete',
      data: data
    })
  },
  // 查询商品
  selectGoodsCoding (data = {}) {
    return request({
      url: '/v1/goods/selectGoodsCoding',
      method: 'post',
      data: data
    })
  },
  // 查询商品
  goodsName (data = {}) {
    return request({
      url: '/v1/goods/goodsName',
      method: 'post',
      data: data
    })
  },
  // 新增商品关联
  betweenAdd (data = {}) {
    return request({
      url: '/v1/goods/betweenAdd',
      method: 'post',
      data: data
    })
  },
  // 新增商品
  save_goods (data = {}) {
    return request({
      url: '/v1/goods/save',
      method: 'post',
      data: data
    })
  },
  // 删除商品
  delete_goods (data = {}) {
    return request({
      url: '/v1/goods/delete',
      method: 'delete',
      data: data
    })
  },
  // 编辑详情 查询
  goodsInfo (data = {}) {
    return request({
      url: '/v1/goods/info',
      method: 'post',
      data: data
    })
  },
  // 编辑商品
  update_goods (data = {}) {
    return request({
      url: '/v1/goods/update',
      method: 'post',
      data: data
    })
  }
})
