// 库存列表
export default (function (_ref) {
  var request = _ref.request;
  return {
    varehousetable_getList_stock: function varehousetable_getList_stock() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/getPageLocation',
        method: 'post',
        data: data
      });
    },
    varehousetable_getList_goods: function varehousetable_getList_goods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/getPageList',
        method: 'post',
        data: data
      });
    },
    // 根据仓库id查询区域
    entrepot_selectArea: function entrepot_selectArea() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/app/entrepot/selectArea',
        method: 'post',
        data: data
      });
    },
    // 根据区域id查询货架
    entrepot_selectShelves: function entrepot_selectShelves() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/app/entrepot/selectShelves',
        method: 'post',
        data: data
      });
    },
    // 按照商品显示查询库存明细基本信息
    product_baseInfo: function product_baseInfo() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/product/baseInfo',
        method: 'post',
        data: data
      });
    },
    // 按照商品显示查询在库详情信息
    product_inLibraryDetails: function product_inLibraryDetails() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/product/inLibraryDetails',
        method: 'post',
        data: data
      });
    },
    // 按照商品显示查询在库详情信息，详情信息中的商品详情
    product_inLibraryInfo: function product_inLibraryInfo() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/product/inLibrary/info',
        method: 'post',
        data: data
      });
    },
    // 查询库存详情商品的历史记录
    product_getGoodsCodingRecord: function product_getGoodsCodingRecord() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventoryPrice/getGoodsCodingRecord',
        method: 'post',
        data: data
      });
    },
    // 导出在库详情excel
    product_inLibraryExport: function product_inLibraryExport() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/product/inLibraryExport',
        method: 'post',
        data: data
      });
    },
    inventory_storageList: function inventory_storageList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/storage/list',
        method: 'post',
        data: data
      });
    },
    // 按库位维度导出excel
    stockposition_exportExcel: function stockposition_exportExcel() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/exportExcel',
        method: 'post',
        data: data
      });
    },
    // 按库位维度明细导出excel
    inventory_storage_export: function inventory_storage_export() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/storage/export',
        method: 'post',
        data: data
      });
    },
    // 按库位维度导出excel
    inventory_storage_locationExport: function inventory_storage_locationExport() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/storage/locationExport',
        method: 'post',
        data: data
      });
    },
    // 按商品维度导出excel
    inventory_storage_goodExport: function inventory_storage_goodExport() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/storage/goodExport',
        method: 'post',
        data: data
      });
    },
    // 库存维度中明细的详情
    inventory_storage_detail: function inventory_storage_detail() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/storage/detail',
        method: 'post',
        data: data
      });
    }
  };
});