var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loadingInfo,
            expression: "loadingInfo"
          }
        ],
        staticClass: "header"
      },
      [
        _c(
          "div",
          {
            staticClass: "searchBgc3",
            staticStyle: {
              "margin-bottom": "10px",
              display: "flex",
              "justify-content": "space-between"
            }
          },
          [_vm._v(" 基本信息 ")]
        ),
        _c(
          "el-form",
          {
            ref: "redactData",
            attrs: { model: _vm.redactData, "label-width": "100px" }
          },
          [
            _c("div", [
              _c("div", { staticClass: "div1" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "调拨单号:", prop: "allocateNo" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#1482f0",
                              "font-weight": "bolder"
                            }
                          },
                          [
                            _c("el-tag", [
                              _vm._v(_vm._s(this.$route.query.transferId))
                            ])
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "创建时间:", prop: "createdTimestamp" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#000",
                              "font-weight": "bolder"
                            }
                          },
                          [
                            _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v(
                                " " + _vm._s(this.redactData.createdTimestamp)
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "调拨状态:", prop: "status" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#1482f0",
                              "font-weight": "bolder"
                            }
                          },
                          [
                            this.redactData.status == 0
                              ? _c("div", [_c("el-tag", [_vm._v("待审核")])], 1)
                              : _vm._e(),
                            this.redactData.status == 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v("完成订单")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            this.redactData.status == 2
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v("审核不通过")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            this.redactData.status == 3
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v("选择库位完成")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            this.redactData.status == 4
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v("审核通过")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            this.redactData.status == 9
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [_vm._v("终止调拨")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "调拨标题:", prop: "name" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#000",
                              "font-weight": "bolder"
                            }
                          },
                          [_vm._v(" " + _vm._s(this.redactData.name) + " ")]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c("el-form-item", { attrs: { label: "创建人:" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#000",
                            "font-weight": "bolder"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(this.redactData.createdName) + " "
                          )
                        ]
                      )
                    ]),
                    _c("el-form-item", { attrs: { label: "审核人员:" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#000",
                            "font-weight": "bolder"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                this.redactData.applyName
                                  ? this.redactData.applyName
                                  : "- -"
                              ) +
                              " "
                          )
                        ]
                      )
                    ]),
                    _c("el-form-item", { attrs: { label: "审核时间:" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#000",
                            "font-weight": "bolder"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                this.redactData.applyTimestamp
                                  ? this.redactData.applyTimestamp
                                  : "- -"
                              ) +
                              " "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "searchBgc2",
                staticStyle: {
                  "margin-bottom": "10px",
                  "margin-top": "20px",
                  display: "flex",
                  "justify-content": "space-between"
                }
              },
              [_vm._v(" 调拨去向 ")]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  width: "80%",
                  margin: "0 auto",
                  "justify-content": "space-around"
                }
              },
              [
                _c("div", [
                  _c("div"),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c("div", [
                        _c("img", {
                          staticClass: "imgDemonstration",
                          staticStyle: { "margin-left": "6px" },
                          attrs: {
                            alt: "",
                            src: require("../../img/warehouse.png")
                          }
                        }),
                        _c("div", { staticClass: "imgTitle" }, [
                          _vm._v("来源仓库")
                        ])
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#1482f0",
                            "font-weight": "bolder",
                            "margin-left": "10px",
                            "line-height": "80px"
                          }
                        },
                        [
                          _c("el-tag", [
                            _vm._v(_vm._s(this.redactData.outWarehouseName))
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "arrows" }, [
                  _c("div", { staticClass: "arrowsLeft" }),
                  _c("i", { staticClass: "el-icon-arrow-right arrowsRight" })
                ]),
                _c("div", [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c("div", [
                        _c("img", {
                          staticClass: "imgDemonstration",
                          staticStyle: { "margin-left": "7px" },
                          attrs: {
                            alt: "",
                            src: require("../../img/department.png")
                          }
                        }),
                        _c("div", { staticClass: "imgTitle" }, [
                          _vm._v("调入部门")
                        ])
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#1482f0",
                            "font-weight": "bolder",
                            "margin-left": "10px",
                            "line-height": "80px"
                          }
                        },
                        [
                          _c("el-tag", { attrs: { type: "warning" } }, [
                            _vm._v(_vm._s(this.redactData.intoDeptName))
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "arrows" }, [
                  _c("div", { staticClass: "arrowsLeft" }),
                  _c("i", { staticClass: "el-icon-arrow-right arrowsRight" })
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between"
                    }
                  },
                  [
                    _c("div", [
                      _c("img", {
                        staticClass: "imgDemonstration",
                        staticStyle: { "margin-left": "6px" },
                        attrs: {
                          alt: "",
                          src: require("../../img/warehouse.png")
                        }
                      }),
                      _c("div", { staticClass: "imgTitle" }, [
                        _vm._v("调入仓库")
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#1482f0",
                          "font-weight": "bolder",
                          "margin-left": "10px",
                          "line-height": "80px"
                        }
                      },
                      [
                        _c("el-tag", [
                          _vm._v(_vm._s(this.redactData.intoWarehouseName))
                        ])
                      ],
                      1
                    )
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass: "searchBgc2",
                staticStyle: {
                  "margin-bottom": "10px",
                  "margin-top": "20px",
                  display: "flex",
                  "justify-content": "space-between"
                }
              },
              [_vm._v(" 商品信息 ")]
            ),
            _c(
              "el-form-item",
              { staticClass: "itemAddSty", attrs: { prop: "transferGoods" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingGoods,
                        expression: "loadingGoods"
                      }
                    ],
                    ref: "transferGoods",
                    staticStyle: { "box-sizing": "border-box" },
                    attrs: {
                      "cell-style": { textAlign: "center" },
                      data: _vm.redactData.transferGoods,
                      "header-cell-style": {
                        background: "#dfe6ec",
                        color: "#666666",
                        textAlign: "center"
                      },
                      "row-style": { height: "77px" },
                      "element-loading-text": "数据加载中",
                      "max-height": "690px",
                      "tooltip-effect": "dark"
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { align: "center", label: "序号", width: "80" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(" " + _vm._s(scope.$index + 1) + " ")
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "商品名称",
                        prop: "goodsName"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "商品图片",
                        prop: "pictureUrl"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("el-image", {
                                staticStyle: { width: "40px", height: "40px" },
                                attrs: {
                                  "preview-src-list": [
                                    _vm.downloadUrl + "/" + scope.row.pictureUrl
                                  ],
                                  src:
                                    _vm.downloadUrl + "/" + scope.row.pictureUrl
                                }
                              })
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "原调拨价格",
                        prop: "price"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "新调拨价格",
                        prop: "outPrice"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.outPrice == null
                                      ? "- -"
                                      : scope.row.outPrice == 0
                                      ? "0"
                                      : scope.row.outPrice
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "调拨数量", prop: "num" }
                    }),
                    _vm.redactData.status !== 0
                      ? _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "入库区域",
                            prop: "areaName",
                            width: "150"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.areaName
                                            ? scope.row.areaName
                                            : "- -"
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            543031992
                          )
                        })
                      : _vm._e(),
                    _vm.redactData.status !== 0
                      ? _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "入库货架",
                            prop: "shelvesName",
                            width: "150"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.shelvesName
                                            ? scope.row.shelvesName
                                            : "- -"
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            76229912
                          )
                        })
                      : _vm._e(),
                    _vm.redactData.status !== 0
                      ? _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "货架层数",
                            prop: "shelvesNum",
                            width: "150"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.shelvesNum
                                            ? scope.row.shelvesNum
                                            : "- -"
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            383790072
                          )
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "总计" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.outPrice
                                      ? scope.row.num
                                        ? scope.row.outPrice * scope.row.num
                                        : "- -"
                                      : scope.row.num
                                      ? scope.row.price * scope.row.num
                                      : "- -"
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "searchBgc2",
                staticStyle: { "margin-bottom": "20px" }
              },
              [_vm._v("备注信息")]
            ),
            _c("el-form-item", { attrs: { label: "调拨备注" } }, [
              _c("p", { staticClass: "textSpanSty" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      this.redactData.remark ? this.redactData.remark : "- -"
                    ) +
                    " "
                )
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "searchBgc2",
                staticStyle: { "margin-bottom": "20px" }
              },
              [_vm._v(" 操作日志 ")]
            ),
            _c(
              "div",
              [
                _c(
                  "el-timeline",
                  _vm._l(_vm.logList, function(item, index) {
                    return _c(
                      "el-timeline-item",
                      {
                        key: index,
                        staticStyle: {
                          "box-sizing": "border-box",
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "normal",
                          "word-break": "break-all",
                          "line-height": "23px",
                          "padding-right": "10px"
                        },
                        attrs: { timestamp: item.createdTimestamp }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.createdName +
                                item.operateDesc +
                                " " +
                                _vm.remark +
                                (item.remark === null ? "" : item.remark)
                            ) +
                            " "
                        )
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-bottom": "20px"
                }
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.resetForm("redactData")
                      }
                    }
                  },
                  [_vm._v("关闭 ")]
                ),
                _vm.redactData.status != 9 &&
                _vm.isStopAllot &&
                this.$route.query.activeName == "initiated"
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          type: "warning",
                          disabled: _vm.refuseLoading,
                          loading: _vm.refuseLoading
                        },
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.refuse()
                          }
                        }
                      },
                      [_vm._v("终止调拨 ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }