export default ({ request }) => ({
    // 获取入库列表
    getWarehousingDetailsList(data = {}) {
        return request({
            url: '/newStatical/getIntoStat',
            method: 'post',
            data: data
        })
    },
    // 获取入库数据统计
    getWarehousingDetailsCount(data = {}) {
        return request({
            url: '/newStatical/getNewStatRes',
            method: 'post',
            data: data
        })
    },
    
})
