<template>
  <div>
    <recordCard ref="recordCard"></recordCard>
    <productExpiration ref="productExpiration"></productExpiration>
    <columnarStatisticalChart
      ref="columnarStatisticalChart"
    ></columnarStatisticalChart>
    <brokenLineGraph ref="brokenLineGraph"></brokenLineGraph>
    <div class="divSty1">
      <pieStatistics class="pieSty" ref="pieStatistics"></pieStatistics>
      <commodityCard class="commodity" ref="commodityCard"></commodityCard>
    </div>
    <div class="message">
      <div class="spanSty">
        待办信息
      </div>
    </div>
    <div class="cardSty">
      <purchaseOrders class="card" ref="purchaseOrders"></purchaseOrders>
      <!-- <allotOrders class="card" ref="allotOrders"></allotOrders> -->
      <reserveOrders class="card" ref="reserveOrders"></reserveOrders>
    </div>
    <div class="cardSty1">
      <!-- <abnormalOrders class="card" ref="abnormalOrders"></abnormalOrders> -->
      <waitIntoOrders class="card" ref="waitIntoOrders"></waitIntoOrders>
      <waitOutOrders class="card" ref="waitOutOrders"></waitOutOrders>
    </div>
  </div>
</template>
<script>
// 豆腐块
import recordCard from './recordCard/index.vue'
// 条形统计图
import columnarStatisticalChart from './columnarStatisticalChart/index.vue'
// 折线统计图
import brokenLineGraph from './brokenLineGraph/index.vue'
// 饼形统计图
import pieStatistics from './pieStatistics/index.vue'
// 商品卡
import commodityCard from './commodityCard/index.vue'
// 待处理采购订单
import purchaseOrders from './pendingOrders/purchaseOrders/index.vue'
// 待处理调拨订单
import allotOrders from './pendingOrders/allotOrders/index.vue'
// 待处理预定订单
import reserveOrders from './pendingOrders/reserveOrders/index.vue'
// 待处理异常订单
import abnormalOrders from './pendingOrders/abnormalOrders/index.vue'
// 待处理入库订单
import waitIntoOrders from './pendingOrders/waitIntoOrders/index.vue'
// 待处理预定订单
import waitOutOrders from './pendingOrders/waitOutOrders/index.vue'
// 商品到期提醒
import productExpiration from './productExpiration/index.vue'
export default {
  components: {
    recordCard,
    columnarStatisticalChart,
    brokenLineGraph,
    pieStatistics,
    commodityCard,
    purchaseOrders,
    allotOrders,
    reserveOrders,
    abnormalOrders,
    waitIntoOrders,
    waitOutOrders,
    productExpiration
  },
  data () {
    return {}
  }
}
</script>
<style scoped>
.divSty1 {
  display: flex;
}
.pieSty {
  width: 33%;
}
.commodity {
  width: 66%;
}
.message {
  position: sticky;
  z-index: 999;
  top: 0;
  margin-top: 20px;
  width: 100%;
  height: 45px;
  background-color: #bbb;
  box-shadow: 0px 0px 4px #999999;
}
.spanSty {
  line-height: 45px;
  margin-left: 20px;
  font-weight: bolder;
  font-size: 14px;
}
.cardSty {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
}
.cardSty1 {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}
.card {
  width: 50%;
}
</style>
