var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c(
      "div",
      [
        _c("div", [
          _c("div", { staticClass: "headline" }, [
            _c("span", { staticStyle: { "margin-left": "10px" } }, [
              _vm._v("区域详情")
            ])
          ]),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-around",
                "line-height": "50px",
                "font-size": "20px"
              }
            },
            [
              _c(
                "div",
                { staticClass: "headerData", attrs: { title: this.areaName } },
                [
                  _vm._v("区域名称："),
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v(_vm._s(this.areaName))
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "headerData",
                  attrs: { title: this.shelvesNum }
                },
                [
                  _vm._v("货架数量："),
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v(_vm._s(this.shelvesNum))
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "headerData", attrs: { title: this.layerNum } },
                [
                  _vm._v("层数总计："),
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v(_vm._s(this.layerNum == null ? 0 : this.layerNum))
                  ])
                ]
              )
            ]
          )
        ]),
        _c(
          "div",
          {
            staticStyle: {
              position: "absolute",
              right: "25px",
              "margin-top": "5px"
            }
          },
          [
            !_vm.permission.isAddShelves
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "success",
                      size: "small",
                      icon: "el-icon-plus"
                    },
                    on: { click: _vm.addShelves }
                  },
                  [_vm._v("新增货架")]
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          [
            _c("div", { staticClass: "headline" }, [
              _c("span", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v("货架列表")
              ])
            ]),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%", "margin-top": "20px" },
                attrs: {
                  data: _vm.shelvesTabledata,
                  "header-cell-style": {
                    background: "#dfe6ec",
                    color: "#666666",
                    textAlign: "center"
                  },
                  "cell-style": { textAlign: "center" }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "date", label: "序号", width: "180" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "shelvesName",
                    label: "货架名称",
                    width: "180"
                  }
                }),
                _c("el-table-column", {
                  attrs: { prop: "shelvesNum", label: "货架层数" }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "all",
                    label: "操作",
                    fixed: "right",
                    width: "300"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          !_vm.permission.Shelves
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "success",
                                    size: "small",
                                    plain: "",
                                    icon: "el-icon-edit-outline"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.editorShelves(scope.$index)
                                    }
                                  }
                                },
                                [_vm._v("编辑货架")]
                              )
                            : _vm._e(),
                          !_vm.permission.isDeleteShelves
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    size: "small",
                                    plain: "",
                                    icon: "el-icon-delete"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.shelvesDelete(scope.$index)
                                    }
                                  }
                                },
                                [_vm._v("删除货架")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm.search2.currentPage !== 0
              ? _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.search2.currpage,
                        "page-sizes": [10, 20, 30, 40, 50, 100],
                        "page-size": _vm.search2.pagesize,
                        layout: "total, jumper, prev, pager, next,sizes",
                        total: _vm.search2.currentPage
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              "append-to-body": true,
              title: _vm.shelvesTitle,
              visible: _vm.dialogVisible4,
              width: "400px",
              "close-on-click-modal": false
            },
            on: {
              "update:visible": function($event) {
                _vm.dialogVisible4 = $event
              },
              close: _vm.WarehouseDialog3
            }
          },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm2",
                staticClass: "elFormX",
                attrs: {
                  "label-position": "right",
                  "label-width": "80px",
                  model: _vm.form3,
                  rules: _vm.rules2
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "货架名称", prop: "shelvesName3" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "", size: "small", clearable: "" },
                      model: {
                        value: _vm.form3.shelvesName3,
                        callback: function($$v) {
                          _vm.$set(_vm.form3, "shelvesName3", $$v)
                        },
                        expression: "form3.shelvesName3"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "货架层数", prop: "shelvesLayer3" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "", size: "small", clearable: "" },
                      model: {
                        value: _vm.form3.shelvesLayer3,
                        callback: function($$v) {
                          _vm.$set(_vm.form3, "shelvesLayer3", $$v)
                        },
                        expression: "form3.shelvesLayer3"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: {
                      click: function($event) {
                        _vm.dialogVisible4 = false
                      }
                    }
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.saveShelves(_vm.dialogTitle1)
                      }
                    }
                  },
                  [_vm._v("保 存")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }