// 盘库
export default (function (_ref) {
  var request = _ref.request;
  return {
    // 根据仓库，区域，货架 查询库存列表
    makeInventoryGetInventoryList: function makeInventoryGetInventoryList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/getInventoryList',
        method: 'post',
        data: data
      });
    },
    // 分页查询盘库单
    checkStockGetPage: function checkStockGetPage() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/checkStock/checkStockDetail',
        method: 'post',
        data: data
      });
    },
    // 新增保存盘库单
    makeInventorySaveCheckStock: function makeInventorySaveCheckStock() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/checkStock/saveCheckStock',
        method: 'post',
        data: data
      });
    },
    // 新增提交盘库单
    makeInventoryCreateCheckStock: function makeInventoryCreateCheckStock() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/checkStock/createCheckStock',
        method: 'post',
        data: data
      });
    },
    // 编辑获取盘库单信息
    makeInventoryGetStockDetail: function makeInventoryGetStockDetail() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/checkStock/getStockDetail',
        method: 'post',
        data: data
      });
    },
    // 终止盘库
    makeInventoryEndCheckStock: function makeInventoryEndCheckStock() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/checkStock/endCheckStock',
        method: 'post',
        data: data
      });
    },
    // 删除盘库
    makeInventoryDeleteCheckStock: function makeInventoryDeleteCheckStock() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/checkStock/deleteCheckStock',
        method: 'post',
        data: data
      });
    },
    // 编辑盘库保存/提交
    makeInventoryUpdateCheckStock: function makeInventoryUpdateCheckStock() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/checkStock/updateCheckStock',
        method: 'post',
        data: data
      });
    },
    // 录入盘库保存/提交
    makeInventoryEnterCheckStock: function makeInventoryEnterCheckStock() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/cmsCheck/enterCheckStock',
        method: 'post',
        data: data
      });
    },
    // 录入获取盘库单信息
    makeInventoryShowCheckStock: function makeInventoryShowCheckStock() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/checkStock/showCheckStock',
        method: 'post',
        data: data
      });
    },
    // 盘库单明细
    makeInventoryGetCheckDetail: function makeInventoryGetCheckDetail() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/checkStock/getCheckDetail',
        method: 'post',
        data: data
      });
    },
    // 盘库单修正
    amendmentDL: function amendmentDL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/checkStock/correct',
        method: 'post',
        data: data
      });
    }
  };
});