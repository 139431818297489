// 盘库
export default ({ request }) => ({
  // 根据仓库，区域，货架 查询库存列表
  makeInventoryGetInventoryList (data = {}) {
    return request({
      url: '/v1/inventory/getInventoryList',
      method: 'post',
      data: data
    })
  },
  // 分页查询盘库单
  checkStockGetPage (data = {}) {
    return request({
      url: '/v1/checkStock/checkStockDetail',
      method: 'post',
      data: data
    })
  },
  // 新增保存盘库单
  makeInventorySaveCheckStock (data = {}) {
    return request({
      url: '/v1/checkStock/saveCheckStock',
      method: 'post',
      data: data
    })
  },
  // 新增提交盘库单
  makeInventoryCreateCheckStock (data = {}) {
    return request({
      url: '/v1/checkStock/createCheckStock',
      method: 'post',
      data: data
    })
  },
  // 编辑获取盘库单信息
  makeInventoryGetStockDetail (data = {}) {
    return request({
      url: '/v1/checkStock/getStockDetail',
      method: 'post',
      data: data
    })
  },
  // 终止盘库
  makeInventoryEndCheckStock (data = {}) {
    return request({
      url: '/v1/checkStock/endCheckStock',
      method: 'post',
      data: data
    })
  },
  // 删除盘库
  makeInventoryDeleteCheckStock (data = {}) {
    return request({
      url: '/v1/checkStock/deleteCheckStock',
      method: 'post',
      data: data
    })
  },
  // 编辑盘库保存/提交
  makeInventoryUpdateCheckStock (data = {}) {
    return request({
      url: '/v1/checkStock/updateCheckStock',
      method: 'post',
      data: data
    })
  },
  // 录入盘库保存/提交
  makeInventoryEnterCheckStock (data = {}) {
    return request({
      url: '/v1/cmsCheck/enterCheckStock',
      method: 'post',
      data: data
    })
  },
  // 录入获取盘库单信息
  makeInventoryShowCheckStock (data = {}) {
    return request({
      url: '/v1/checkStock/showCheckStock',
      method: 'post',
      data: data
    })
  },
  // 盘库单明细
  makeInventoryGetCheckDetail (data = {}) {
    return request({
      url: '/v1/checkStock/getCheckDetail',
      method: 'post',
      data: data
    })
  },
  // 盘库单修正
  amendmentDL(data = {}) {
    return request({
      url: '/v1/checkStock/correct',
      method: 'post',
      data: data
    })
  }
})
