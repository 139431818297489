export default (function (_ref) {
  var request = _ref.request;
  return {
    // 获取采购退货列表
    getPurchaseThListList: function getPurchaseThListList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouseIntoOrder/getReturnMoney',
        method: 'post',
        data: data
      });
    },
    // 获取采购退货列表
    getPurchaseThListDetail: function getPurchaseThListDetail() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/intoDetail/returnMonetResult',
        method: 'post',
        data: data
      });
    }
  };
});