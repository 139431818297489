<template>
  <d2-container>
    <!--       搜索框         -->
    <div style="width:100%;">
      <div class="searchSty">
        <el-input
          v-model="search.goodsCoding"
          class="filter-item"
          clearable
          placeholder="请输入商品编码"
          prefix-icon="el-icon-search"
          size="small"
          @keyup.enter.native="toQuery"
        />

        <remoteSearch :name.sync="search.goodsName" :searchFunc="remoteSearchFunc"/>

        <!--<el-input
          v-model="search.goodsName"
          class="filter-item"
          clearable
          placeholder="请输入商品"
          prefix-icon="el-icon-search"
          size="small"
          @keyup.enter.native="toQuery"
        />-->

        <el-select
          v-model="search.goodsOne"
          class="filter-item"
          clearable
          placeholder="请选择商品分类"
          size="small"
        >
          <el-option
            v-for="(item, index) in oneClassData"
            :key="index"
            :label="item.classifyName"
            :value="item.id"
            v-bind:title="onHoverText"
            @mouseenter.native="onMouseOver(item.classifyName)"
          ></el-option>
        </el-select>
<!--
        <el-select
          v-model="search.goodsTwo"
          class="filter-item"
          clearable
          placeholder="请选择二级分类"
          size="small"
        >
          <el-option
            v-for="(item, index) in twoClassData"
            :key="index"
            :label="item.classifyName"
            :value="item.id"
            v-bind:title="onHoverText"
            @mouseenter.native="onMouseOver(item.classifyName)"
          ></el-option>
        </el-select>

        <el-select
          v-model="search.goodsThree"
          class="filter-item"
          clearable
          placeholder="请选择三级分类"
          size="small"
        >
          <el-option
            v-for="(item, index) in threeClassData"
            :key="index"
            :label="item.classifyName"
            :value="item.id"
            v-bind:title="onHoverText"
            @mouseenter.native="onMouseOver(item.classifyName)"
          ></el-option>
        </el-select> -->
        <el-select
          v-model="search.goodsBrand"
          class="filter-item"
          clearable
          placeholder="请选择商品品牌"
          size="small"
        >
          <el-option
            v-for="(item, index) in goodsBrandData"
            :key="index"
            :label="item.brandName"
            :value="item.id"
            style="width:200px"
            v-bind:title="onHoverText"
            @mouseenter.native="onMouseOver(item.brandName)"
          ></el-option>
        </el-select>

        <el-select
          v-model="search.goodsIp"
          class="filter-item"
          clearable
          placeholder="请选择商品IP"
          size="small"
        >
          <el-option
            v-for="(item, index) in goodsIpData"
            :key="index"
            :label="item.ipName"
            :value="item.id"
            style="width:200px"
            v-bind:title="onHoverText"
            @mouseenter.native="onMouseOver(item.ipName)"
          ></el-option>
        </el-select>
        <div class="searchBtnSty">
          <el-button
            v-if="!permission.isSearch"
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="toQuery()"
          >
            搜索
          </el-button>
          <el-button
            v-if="!permission.isReset"
            icon="el-icon-refresh"
            size="small"
            type="warning"
            @click="reset()"
          >
            重置
          </el-button>
          <el-button
            v-if="!permission.isAdd"
            icon="el-icon-circle-plus-outline"
            size="small"
            style="margin-right: 10px"
            type="success"
            @click="append"
          >
            新增
          </el-button>
        </div>
      </div>
    </div>

    <!--       TableData      -->
    <div style="margin-top: 10px; margin-left: 10px; margin-right: 10px;">
      <el-table
        ref="tableData"
        v-loading="loadingTotal"
        :cell-style="{ textAlign: 'center' }"
        :data="tableData"
        :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center'
        }"
        :row-style="{ height: '57px' }"
        element-loading-text="数据加载中"
        max-height="660"
        tooltip-effect="dark"
      >
        <el-table-column align="center" label="序号" width="70">
          <template slot-scope="scope">
            {{ (search.pageNo - 1) * search.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="商品编码"
          prop="goodsCoding"
          width="150"
        >
          <template slot-scope="scope">
            <div
              style="display: flex;justify-content: space-between; width: 100%"
            >
              <div style="white-space: nowrap;width: 80%;">
                {{ scope.row.goodsCoding }}
              </div>
              <div class="copySty" @click="copyUrl(scope.row.goodsCoding)">
                复制
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="商品原ID" prop="goodsId">
        </el-table-column>
        <el-table-column
          align="center"
          label="商品名称"
          prop="goodsName"
          width="300"
        >
          <template slot-scope="scope">
            <div
              style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
            >
              {{ scope.row.goodsName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="商品图片"
          prop="pictureUrl"
          width="100"
        >
          <template slot-scope="scope">
            <img
              :src="downloadUrl + '/' + scope.row.pictureUrl"
              alt=""
              style="width: 50px; height: 50px;margin-top: 8px;cursor: pointer"
              @click="getPreview(downloadUrl + '/' + scope.row.pictureUrl)"
            />
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="商品分类"
          prop="goodsOne"
          width="120"
        >
          <template slot-scope="scope">
            <div
              style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
            >
              {{ scope.row.goodsOne === null ? '- -' : scope.row.goodsOne }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          align="center"
          label="二级分类"
          prop="goodsTwo"
          width="100"
        >
          <template slot-scope="scope">
            <div
              style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
            >
              {{ scope.row.goodsTwo === null ? '- -' : scope.row.goodsTwo }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="三级分类"
          prop="goodsThree"
          width="100"
        >
          <template slot-scope="scope">
            <div
              style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
            >
              {{ scope.row.goodsThree === null ? '- -' : scope.row.goodsThree }}
            </div>
          </template>
        </el-table-column> -->
        <el-table-column
          align="center"
          label="商品品牌"
          prop="goodsBrandName"
          width="150"
        >
          <template slot-scope="scope">
            <div
              style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
            >
              {{ scope.row.goodsBrandName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="商品IP"
          prop="goodsIpName"
          width="150"
        >
          <template slot-scope="scope">
            <div
              style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
            >
              {{ scope.row.goodsIpName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="380">
          <template slot-scope="scope">
            <el-button
              v-if="!permission.isRedact"
              icon="el-icon-edit-outline"
              plain
              size="small"
              type="success"
              @click.native.prevent="redact(scope.row, scope.$index, tableData)"
            >
              编辑
            </el-button>
            <!-- <el-button
              v-if="!permission.isSelectGoods"
              icon="el-icon-paperclip"
              plain
              size="small"
              type="primary"
              @click.native.prevent="
                relevancy(scope.row, scope.$index, tableData)
              "
            >
              关联
            </el-button> -->
            <el-button
              v-if="!permission.isInfo"
              icon="el-icon-info"
              plain
              size="small"
              type="info"
              @click.native.prevent="
                particulars(scope.row, scope.$index, tableData)
              "
            >
              详情
            </el-button>
            <el-button
              v-if="!permission.isDelete"
              icon="el-icon-delete"
              plain
              size="small"
              type="danger"
              @click.native.prevent="remove(scope.row, scope.$index, tableData)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--       分页           -->
    <div style="display:flex;justify-content:right;margin-top: 10px;">
      <el-pagination
        v-if="this.search.pageSize > 0"
        :current-page="search.pageNo"
        :page-size="search.pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :total="search.pageTotal"
        layout="total, jumper, prev, pager, next,sizes"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>

    <!-- 点击新增,编辑弹窗 -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      top="5vh"
      width="57%"
      @close="closeDialog()"
    >
      <el-form
        ref="redactData"
        :model="redactData"
        :rules="rules"
        class="demo-ruleForm"
        label-width="100px"
      >
        <div style="width: 100%">
          <div class="class1">
            <div style="width: 50%;display: flex;flex-direction:column">
              <el-form-item v-if="this.title === '编辑'" label="商品编码" style="width: 50%">
                {{ redactData.goodsCoding }}
              </el-form-item>
              <el-form-item label="商品名称" prop="goodsName">
                <el-input
                  v-model="redactData.goodsName"
                  clearable
                  maxlength="60"
                  placeholder="请输入商品名称"
                  style="width:200px"
                ></el-input>
              </el-form-item>
              <el-form-item label="商品IP" prop="goodsIp">
                <el-select
                  v-model="redactData.goodsIp"
                  clearable
                  placeholder="请选择商品IP"
                  style="width:200px"
                >
                  <el-option
                    v-for="(item, index) in goodsIpData"
                    :key="index"
                    :label="item.ipName"
                    :value="item.id"
                    style="width:300px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;"
                    v-bind:title="onHoverText"
                    @mouseenter.native="onMouseOver(item.ipName)"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="商品品牌" prop="goodsBrand">
                <el-select
                  id="goodsBrand"
                  v-model="redactData.goodsBrand"
                  clearable
                  placeholder="请选择商品品牌"
                  style="width:200px"
                >
                  <el-option
                    v-for="(item, index) in goodsBrandData"
                    :key="index"
                    :label="item.brandName"
                    :value="item.id"
                    style="width:300px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;"
                    v-bind:title="onHoverText"
                    @mouseenter.native="onMouseOver(item.brandName)"
                  ></el-option>
                </el-select>
              </el-form-item>
              <div style="display: flex;">
                <el-form-item label="商品分类" prop="goodsOne">
                  <el-select
                    v-model="redactData.goodsOne"
                    clearable
                    placeholder="请选择商品分类"
                    style="width:200px"
                    @change="changeOneClass()"
                  >
                    <el-option
                      v-for="(item, index) in oneClassData"
                      :key="index"
                      :label="item.classifyName"
                      :value="item.id"
                      v-bind:title="onHoverText"
                      @mouseenter.native="onMouseOver(item.classifyName)"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-show="this.showTwoClass"
                  label="二级分类"
                  prop="goodsTwo"
                >
                  <el-select
                    v-model="redactData.goodsTwo"
                    clearable
                    placeholder="请选择二级分类"
                    style="width:200px"
                    @change="changeTwoClass()"
                  >
                    <el-option
                      v-for="(item, index) in twoClassDataSelect"
                      :key="index"
                      :label="item.classifyName"
                      :value="item.id"
                      v-bind:title="onHoverText"
                      @mouseenter.native="onMouseOver(item.classifyName)"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-show="this.showThreeClass"
                  label="三级分类"
                  prop="goodsThree"
                >
                  <el-select
                    v-model="redactData.goodsThree"
                    clearable
                    placeholder="请选择三级分类"
                    style="width:200px"
                  >
                    <el-option
                      v-for="(item, index) in threeClassDataSelect"
                      :key="index"
                      :label="item.classifyName"
                      :value="item.id"
                      v-bind:title="onHoverText"
                      @mouseenter.native="onMouseOver(item.classifyName)"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div style="width: 50%;display: flex;flex-direction:column">
              <el-form-item
                label="商品原ID"
                prop="goodsId"
                style="height: 30px"
                v-if="title !== '新增'"
              >
                {{ redactData.goodsId }}
              </el-form-item>
              <el-form-item
                label="商品图片"
                prop="pictureUrl"
              >
                <erp-upload-img
                  v-model="redactData.pictureUrl"
                  :maxCount="1"
                  :showDelete="true"
                  :upload-path="'/commodityManagement/commodity/'"
                ></erp-upload-img>
                <el-tooltip
                  class="item tooltip"
                  content="注：商品方图请上传宽为500，高为500的图片"
                  effect="dark"
                  placement="top-start"
                >
                  <i
                    class="el-icon-info"
                    style="color:#1890ff;margin-left: 20px;"
                  ></i>
                </el-tooltip>
              </el-form-item>
            </div>
          </div>
        </div>
        <div style="margin-top:10px;margin-right: 10px;">
          <el-form-item label="商品详情">
            <erp-Editor
              ref="goodsDeatil"
              v-loading="loadingTextarea"
              :uploadPath="'/commodityManagement/goodsDeatil/'"
              :value="redactData.goodsDeatil"
              editorStyle="text-align:left;margin: 21px;width: calc(100% - 20px);"
              style="text-align: left"
            ></erp-Editor>
            <!-- <el-input type="textarea" v-loading="loadingTextarea" v-model="redactData.goodsDeatil" :rows="4"></el-input> -->
          </el-form-item>
        </div>
        <div style="  display: flex;justify-content: center;">
          <el-button size="small" @click="resetForm('redactData')">
            返回
          </el-button>
          <el-button
            v-if="title === '编辑'"
            size="small"
            type="primary"
            @click="saveForm('redactData')"
          >
            保存
          </el-button>
          <el-button
            v-if="title === '新增'"
            size="small"
            type="primary"
            @click="submitForm('redactData')"
          >
            立即创建
          </el-button>
        </div>
      </el-form>
    </el-dialog>

    <!-- 点击详情弹窗 -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisibleInfo"
      top="5vh"
      width="65%"
      @close="closeDialogInfo()"
    >
      <div class="searchBgc">商品详情</div>
      <el-form
        ref="redactData"
        :model="redactData"
        class="demo-ruleForm"
        label-width="100px"
      >
        <div
          style="display: flex;justify-content: space-between;width: 100%;height: 250px;"
        >
          <div style="width: 30%;">
            <el-form-item label="商品编码:" prop="goodsCoding">
              {{ redactData.goodsCoding }}
            </el-form-item>
            <el-form-item label="商品分类:" prop="goodsOne">
              {{ redactData.goodsOne === null ? '- -' : redactData.goodsOne }}
            </el-form-item>
            <!-- <el-form-item label="二级分类:" prop="goodsTwo">
              {{ redactData.goodsTwo === null ? '- -' : redactData.goodsTwo }}
            </el-form-item>
            <el-form-item label="三级分类:" prop="goodsThree">
              {{
                redactData.goodsThree === null ? '- -' : redactData.goodsThree
              }}
            </el-form-item> -->
            <el-form-item label="商品品牌:" prop="goodsBrand">
              <div style="height: 70px;overflow: auto;">
                {{ redactData.goodsBrand }}
              </div>
            </el-form-item>
          </div>
          <div style="width: 60%;margin-right: 1%;">
            <div
              style="display: flex;justify-content: space-between;height: 185px;"
            >
              <div>
                <el-form-item label="商品原ID:" prop="goodsId">
                  {{ redactData.goodsId }}
                </el-form-item>
                <el-form-item label="商品名称:" prop="goodsName">
                  <div style="height: 40px;overflow: auto;">
                    {{ redactData.goodsName }}
                  </div>
                </el-form-item>
                <el-form-item label="商品IP:" prop="goodsIp">
                  {{ redactData.goodsIp }}
                </el-form-item>
              </div>
              <div>
                <el-form-item label="商品图片:" prop="pictureUrl">
                  <img
                    :src="downloadUrl + '/' + redactData.pictureUrlString"
                    alt=""
                    style="width: 150px; height: 150px;margin-top: 8px;cursor: pointer"
                    @click="
                      getPreview(
                        downloadUrl + '/' + redactData.pictureUrlString
                      )
                    "
                  />
                </el-form-item>
              </div>
            </div>


          </div>
        </div>
      </el-form>
      <!-- <div class="searchBgc" style="margin-bottom: 20px">关联商品</div> -->
      <!-- <el-table
        ref="correlationData"
        v-loading="loadingCorrelation"
        :cell-style="{ textAlign: 'center' }"
        :data="correlationData"
        :header-cell-style="{
          color: '#666666',
          textAlign: 'center'
        }"
        :row-style="{ height: '57px' }"
        element-loading-text="数据加载中"
        max-height="304"
      >
        <el-table-column align="center" label="序号" width="80">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="商品编码"
          prop="goodsCoding"
          width="110"
        ></el-table-column>
        <el-table-column
          align="center"
          label="商品名称"
          prop="goodsName"
          width="200"
        ></el-table-column>
        <el-table-column align="center" label="商品图片" prop="pictureUrl">
          <template slot-scope="scope">
            <img
              v-if="scope.row.pictureUrl !== ''"
              :src="downloadUrl + '/' + scope.row.pictureUrl"
              alt=""
              style="width: 50px; height: 50px;margin-top: 8px;cursor: pointer"
              @click="getPreview(downloadUrl + '/' + scope.row.pictureUrl)"
            />
            <div
              v-if="scope.row.pictureUrl === ''"
              style="width: 50px;height: 50px;border: 1px solid #c0c0c0;display: flex;justify-content: center;margin: 0 auto;"
            ></div>
          </template>
        </el-table-column>
        <el-table-column align="center" label=" 一级分类" prop="goodsOne">
          <template slot-scope="scope">
            {{ scope.row.goodsOne === null ? '- -' : scope.row.goodsOne }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="二级分类" prop="goodsTwo">
          <template slot-scope="scope">
            {{ scope.row.goodsTwo === null ? '- -' : scope.row.goodsTwo }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="三级分类" prop="goodsThree">
          <template slot-scope="scope">
            {{ scope.row.goodsThree === null ? '- -' : scope.row.goodsThree }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="商品品牌"
          prop="goodsBrandName"
          width="150"
        ></el-table-column>
        <el-table-column
          align="center"
          label="商品IP"
          prop="goodsIpName"
          width="150"
        ></el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              v-if="!permission.isDeleteGoods"
              size="small"
              type="danger"
              @click.native.prevent="
                relieve(scope.row, scope.$index, correlationData)
              "
            >
              解除关联
            </el-button>
          </template>
        </el-table-column>
      </el-table> -->
      <div>
        <div class="searchBgc" style="margin-bottom: 10px;">详细信息</div>
        <div style="margin-left: 20px;">
          <p v-html="redactData.goodsDeatil"></p>
        </div>
        <!-- 分页 -->
      </div>
      <div style="  display: flex;justify-content: center;margin-top:10px">
        <el-button size="small" @click="closeDialogInfo()">返回</el-button>
      </div>
    </el-dialog>

    <!-- 点击关联弹窗 -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisibleRelevance"
      width="40%"
      @close="closeDialogRelevance()"
    >
      <div class="relevance">
        <div>
          商品编码：
          <el-input
            v-model="searchGoodsCoding"
            clearable
            oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
            size="small"
            style="width:200px;margin: 0px 20px;"
          ></el-input>
        </div>
        <el-button
          v-if="!permission.isCodingGoods"
          icon="el-icon-search"
          size="small"
          type="primary"
          @click="toSearch"
        >
          搜索
        </el-button>
      </div>
      <div>
        <div class="searchBgc">搜索结果</div>
        <div style="margin-top:20px">
          <el-table
            ref="relevanceData"
            v-loading="loadingRelevance"
            :cell-style="{ textAlign: 'center' }"
            :data="relevanceData"
            :header-cell-style="{
              color: '#666666',
              textAlign: 'center'
            }"
            :row-style="{ height: '90px' }"
            element-loading-text="数据加载中"
            max-height="140"
            tooltip-effect="dark"
          >
            <el-table-column
              label="商品编码"
              prop="goodsCoding"
            ></el-table-column>
            <el-table-column
              label="商品名称"
              prop="goodsName"
            ></el-table-column>
            <el-table-column label="商品图片" prop="pictureUrl">
              <template slot-scope="scope">
                <img
                  v-if="
                    scope.row.pictureUrl !== '' && scope.row.pictureUrl !== null
                  "
                  :src="downloadUrl + '/' + scope.row.pictureUrl"
                  alt=""
                  style="width: 50px; height: 50px;margin-top: 8px;cursor: pointer"
                  @click="getPreview(downloadUrl + '/' + scope.row.pictureUrl)"
                />
                <div
                  v-if="
                    scope.row.pictureUrl === '' || scope.row.pictureUrl === null
                  "
                  style="width: 50px;height: 50px;border: 1px solid #c0c0c0;display: flex;justify-content: center;margin: 0 auto;"
                ></div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div style="  display: flex;justify-content: center;margin-top: 20px;">
        <el-button size="small" @click="closeDialogRelevance()">返回</el-button>
        <el-button
          v-if="!permission.isAddGoods"
          size="small"
          type="primary"
          @click="toAdd()"
        >
          添加
        </el-button>
      </div>
    </el-dialog>

    <!--     点击图片放大弹出页面         -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :title="judgment"
      :visible.sync="dialogFormVisiblePic"
      style="margin:20px auto"
      width="540px"
      @close="closeDialogPic()"
    >
      <img
        v-if="this.dialogImageUrl !== ''"
        :src="this.dialogImageUrl"
        alt=""
        style=" width: 500px; height: 500px; text-align: center"
      />
      <div
        v-if="this.dialogImageUrl === ''"
        style="width: 500px;height: 500px;text-align: center;border: 1px solid #c0c0c0;display: flex;justify-content: center;align-items: center;"
      >
        <span style="font-size: large">暂无图片</span>
      </div>
    </el-dialog>
  </d2-container>
</template>
<script>
import api from '@/api'

// 搜索数据
const searchDefault = {
  goodsCoding: '', // 商品编码
  goodsName: '', // 商品名称
  oneClass: '', // 一级分类
  twoClass: '', // 二级分类
  threeClass: '', // 三级分类
  goodsBrand: '', // 商品品牌
  goodsIp: '', // 商品Ip
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0
}
// 新增,修改数据
const append = {
  id: '', // id
  goodsId: '', // 商品原ID
  goodsName: '', // 商品名称
  goodsOne: null, // 一级分类
  goodsTwo: null, // 二级分类
  goodsThree: null, // 三级分类
  goodsBrand: '', // 商品品牌
  goodsIp: '', // 商品Ip
  pictureUrl: [], // 商品图片
  pictureUrlString: '', // 商品图片字符串
  goodsDeatil: '' // 商品详情
}

// 关联数据
const relevancePaging = {
  nextToken: null, // nextToken
  totalSize: 10, // 总个数
  limit: 10, // 分页页数
  page: 1 // 页数初始化
}

export default {
  name: 'commodity',
  data () {
    return {
      // 权限点
      permission: {
        isSearch: this.checkauthority('goods_select'), // 搜索
        isSearchInput: this.checkauthority('goods_select'), // 搜索框
        isReset: this.checkauthority('goods_select'), // 重置
        isDelete: this.checkauthority('goods_delete'), // 删除
        isAdd: this.checkauthority('goods_create'), // 新增
        isRedact: this.checkauthority('goods_update'), // 编辑
        isInfo: this.checkauthority('goodsBetween_detail'), // 详情
        isSelectGoods: this.checkauthority('goodsBetween_select'), // 关联
        isAddGoods: this.checkauthority('goodsBetween_create'), // 关联新增
        isCodingGoods: this.checkauthority('goods_goodsCoding'), // 关联搜索
        isDeleteGoods: this.checkauthority('goodsBetween_delete') // 关联解除
      },
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL,
      search: JSON.parse(JSON.stringify(searchDefault)),
      redactData: JSON.parse(JSON.stringify(append)),
      tableData: [],
      onHoverText: '',
      relevanceData: [
        {
          id: '',
          goodsCoding: '',
          goodsName: '',
          pictureUrl: '',
          betweenId: ''
        }
      ], // 关联数据
      searchCorrelation: JSON.parse(JSON.stringify(relevancePaging)),
      correlationData: [], // 关联商品数据
      oneClassData: [], // 一级菜单分类数据
      twoClassData: [], // 二级菜单分类数据
      threeClassData: [], // 三级菜单分类数据
      twoClassDataSelect: [], // 二级菜单分类数据Select
      threeClassDataSelect: [], // 三级菜单分类数据Select
      goodsBrandData: [], // 商品品牌数据
      goodsIpData: [], // 商品Ip数据
      searchGoodsCoding: '', // 商品编码搜索
      title: '', //  新增,编辑弹窗标题
      judgment: '', // 图片放大标题
      loadingTotal: false, // 加载动画
      loadingRelevance: false, // 加载动画
      loadingCorrelation: false, // 加载动画
      loadingTextarea: false, // 加载动画
      dialogFormVisible: false, // 新增,编辑弹窗是否开启
      dialogFormVisibleRelevance: false, // 关联弹窗
      dialogFormVisiblePic: false, // 图片放大弹窗
      dialogFormVisibleInfo: false, // 详情弹窗
      dialogImageUrl: '', // 放大图片路径
      showTwoClass: false,
      showThreeClass: false,
      betweenId: '',
      goodsCodingNum: '',
      rwoId: '',
      isClear: false,
      // rules校验
      rules: {
        goodsName: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur'
          },
          {
            pattern: /^(?!\s+)/,
            message: '首不能为空格',
            trigger: 'blur'
          },
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              this.validateCheckName(rule, value, callback, 'cover')
            }
          }
        ],
        goodsOne: [
          {
            required: true,
            message: '请选择一级分类',
            trigger: 'blur'
          }
        ],
        goodsIp: [{
          required: true,
          message: '请选择商品IP',
          trigger: 'blur'
        }],
        goodsBrand: [
          {
            required: true,
            message: '请选择商品品牌',
            trigger: 'blur'
          }
        ],
        pictureUrl: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              this.validatePicture(rule, value, callback, 'cover')
            }
          }
        ]
      }
    }
  },
  created () {
    this.initList()
  },
  methods: {
    onMouseOver (item) {
      this.onHoverText = item
    },
    // 富文本处理
    showHtml (str) {
      return (
        str
          .replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
          .replace(/&amp;nbsp;/g, '')
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
          .replace(/&quot;/g, '"')
          // eslint-disable-next-line no-useless-escape
          .replace(/&#39;/g, '\'')
      )
    },
    // /定义点击复制的功能
    copyUrl (url) {
      // 创建一个 Input标签
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      // 执行浏览器复制命令
      // / 复制命令会将当前选中的内容复制到剪切板中
      // / 如这里构建的 Input标签
      document.execCommand('Copy')
      this.$message({
        message: '复制成功',
        type: 'success'
      })
      // /复制成功后再将构造的标签 移除
      oInput.remove()
    },
    // 改变一级分类时
    async changeOneClass () {
      this.showTwoClass = false
      this.showThreeClass = false
      this.redactData.goodsTwo = null
      this.redactData.goodsThree = null
      if (
        this.redactData.goodsOne !== null ||
        this.redactData.goodsOne !== ''
      ) {
        const res = await api.getListByFatherIdToGoods({
          fatherId:
            this.redactData.goodsOne === '' ||
            this.redactData.goodsOne === null ||
            this.redactData.goodsOne === 0
              ? null
              : this.redactData.goodsOne
        })
        if (res.code === 200) {
          if (res.body.length > 0) {
            this.twoClassDataSelect = res.body
            this.showTwoClass = true
          } else {
            this.showTwoClass = false
          }
        }
      } else {
        this.redactData.goodsTwo = null
        this.redactData.goodsThree = null
        this.twoClassDataSelect = []
        this.threeClassDataSelect = []
        this.showTwoClass = false
        this.showThreeClass = false
      }
    },
    // 改变二级分类时
    async changeTwoClass () {
      this.showThreeClass = false
      this.redactData.goodsThree = null
      // console.log(this.redactData.goodsTwo === '' || this.redactData.goodsTwo !== null)
      if (
        this.redactData.goodsTwo !== null ||
        this.redactData.goodsTwo !== ''
      ) {
        const res = await api.getListByFatherIdToGoods({
          fatherId:
            this.redactData.goodsTwo === '' ||
            this.redactData.goodsTwo === null ||
            this.redactData.goodsTwo === 0
              ? null
              : this.redactData.goodsTwo
        })
        if (res.code === 200) {
          if (res.body.length > 0) {
            this.threeClassDataSelect = res.body
            this.showThreeClass = true
          } else {
            this.showThreeClass = false
          }
        }
      } else {
        this.redactData.goodsThree = null
        this.threeClassDataSelect = []
        this.showThreeClass = false
      }
    },
    // 初始化页面
    async initList () {
      this.loadingTotal = true
      // 分类接口
      for (let i = 1; i < 4; i++) {
        const res = await api.getList_Classify({
          classifyLevel: i
        })
        if (res.code === 200) {
          if (i === 1) {
            this.oneClassData = res.body
          } else if (i === 2) {
            this.twoClassData = res.body
          } else if (i === 3) {
            this.threeClassData = res.body
          }
        } else {
          this.$message({
            type: 'danger',
            message: '分类菜单加载失败!'
          })
        }
      }
      // 品牌接口
      const _brand = await api.selectAll_brand({})
      if (_brand.code === 200) {
        this.goodsBrandData = _brand.body
      } else {
        this.$message({
          type: 'danger',
          message: '商品品牌加载失败!'
        })
      }
      // ip接口
      const _ip = await api.getListIp_ip({})
      if (_ip.code === 200) {
        this.goodsIpData = _ip.body
      } else {
        this.$message({
          type: 'danger',
          message: '商品品牌加载失败!'
        })
      }
      const _goods = await api.getPageList_goods({
        goodsCoding: this.search.goodsCoding,
        goodsName: this.search.goodsName,
        id1: this.search.goodsOne,
        id2: this.search.goodsTwo,
        id3: this.search.goodsThree,
        goodsBrand: this.search.goodsBrand,
        goodsIp: this.search.goodsIp,
        pageNo: this.search.pageNo,
        pageSize: this.search.pageSize
      })
      // console.log(_goods)
      if (_goods.code === 200) {
        // console.log(_goods.datas)
        this.tableData = _goods.body.datas
        this.search.pageTotal = _goods.body.sumDataCount
        this.loadingTotal = false
      } else {
        this.$message({
          type: 'danger',
          message: '页面加载失败!'
        })
        this.loadingTotal = false
      }
    },
    handleSizeChange (val) {
      this.search.pageSize = val
      this.initList()
    },
    handleCurrentChange (val) {
      this.search.pageNo = val
      this.initList()
    },
    //远程搜索
    async remoteSearchFunc(){

    },

    // 搜索
    toQuery () {
      this.search.pageNo = 1
      this.initList()
    },
    // 重置
    reset () {
      this.search.goodsCoding = '' // 商品编码
      this.search.goodsName = '' // 商品名称
      this.search.goodsOne = '' // 一级分类
      this.search.goodsTwo = '' // 二级分类
      this.search.goodsThree = '' // 三级分类
      this.search.goodsBrand = '' // 商品品牌
      this.search.goodsIp = '' // 商品Ip
      //     this.  pageNo: 1,
      // pageSize: 50,
      this.initList()
    },
    // 新增
    append () {
      // this.resetForm()
      this.title = '新增'
      this.showTwoClass = false
      this.showThreeClass = false
      this.dialogFormVisible = true
    },
    // 编辑
    async redact (row, index) {
      console.log(row)
      this.loadingTextarea = true
      console.log(this.redactData.goodsDeatil)
      this.title = '编辑'
      this.redactData.goodsCoding = row.goodsCoding
      this.redactData.goodsName = row.goodsName
      this.redactData.goodsId = row.goodsId
      // this.redactData.goodsOne = row.id1
      // this.redactData.goodsTwo = row.id2
      row.id1 === 0
        ? (this.redactData.goodsOne = null)
        : (this.redactData.goodsOne = row.id1)
      row.id2 === 0
        ? (this.redactData.goodsTwo = null)
        : (this.redactData.goodsTwo = row.id2)
      row.id3 === 0
        ? (this.redactData.goodsThree = null)
        : (this.redactData.goodsThree = row.id3)
      const goods1 = await api.getListByFatherIdToGoods({
        fatherId: this.redactData.goodsOne
      })
      if (goods1.code === 200) {
        if (goods1.body.length > 0) {
          this.twoClassDataSelect = goods1.body
          this.showTwoClass = true
        } else {
          this.showTwoClass = false
        }
      }
      if (this.redactData.goodsTwo === 0) {
        this.showThreeClass = false
      } else {
        const goods2 = await api.getListByFatherIdToGoods({
          fatherId: this.redactData.goodsTwo
        })
        if (goods2.code === 200) {
          if (goods2.body.length > 0) {
            this.threeClassDataSelect = goods2.body
            this.showThreeClass = true
          } else {
            this.showThreeClass = false
          }
        }
      }
      this.redactData.id = row.id
      this.redactData.goodsBrand = row.goodsBrand
      this.redactData.goodsIp = row.goodsIp
      this.redactData.pictureUrl = [{ url: row.pictureUrl }]
      // console.log(this.redactData.pictureUrl)
      this.dialogFormVisible = true
      const res = await api.goodsInfo({
        id: row.id
      })
      console.log(res)
      if (res.code === 200) {
        this.redactData.goodsDeatil = res.body.goodsDeatil
        this.redactData.goodsDeatil = this.showHtml(res.body.goodsDeatil)
        this.loadingTextarea = false
      } else {
        this.loadingTextarea = false
      }
    },
    // 新增,编辑关闭
    closeDialog () {
      this.dialogFormVisible = false
      this.title = ''
      this.resetForm()
      this.$refs.goodsDeatil.clearContent()
    },
    // 新增保存
    submitForm (formName) {
      this.$refs.redactData.validate(async valid => {
        if (valid) {
          const res = await api.save_goods({
            goodsBrand: this.redactData.goodsBrand,
            goodsDeatil: this.$refs.goodsDeatil.getContent(),
            goodsIp: this.redactData.goodsIp,
            goodsName: this.redactData.goodsName,
            id1: this.redactData.goodsOne,
            id2: this.redactData.goodsTwo,
            id3: this.redactData.goodsThree,
            pictureUrl: this.redactData.pictureUrl[0].url
          })
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '新增成功!'
            })
            this.dialogFormVisible = false
            this.$refs.goodsDeatil.clearContent()
            this.initList()
          }
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    // 编辑保存
    saveForm (formName) {
      this.$refs.redactData.validate(async valid => {
        if (valid) {
          const res = await api.update_goods({
            goodsBrand: this.redactData.goodsBrand,
            goodsDeatil: this.$refs.goodsDeatil.getContent(),
            goodsIp: this.redactData.goodsIp,
            goodsName: this.redactData.goodsName,
            id: this.redactData.id,
            id1: this.redactData.goodsOne,
            id2: this.redactData.goodsTwo === '' ? 0 : this.redactData.goodsTwo,
            id3:
              this.redactData.goodsThree === ''
                ? 0
                : this.redactData.goodsThree,
            pictureUrl: this.redactData.pictureUrl[0].url
          })
          if (res.code === 200 && res.body === true) {
            this.$message({
              message: '编辑成功',
              type: 'success'
            })
            this.dialogFormVisible = false
            this.$refs.goodsDeatil.clearContent()
            this.initList()
          }
        } else {
          this.$message({
            message: '编辑失败',
            type: 'danger'
          })
        }
      })
    },
    // 新增,编辑 返回
    resetForm () {
      this.dialogFormVisible = false
      this.title = ''
      this.redactData.goodsCoding = ''
      this.redactData.goodsName = ''
      this.redactData.goodsOne = null
      this.redactData.goodsTwo = null
      this.redactData.goodsThree = null
      this.redactData.goodsBrand = ''
      this.redactData.goodsIp = ''
      this.redactData.pictureUrl = []
      this.redactData.goodsDeatil = ''
      this.redactData.id = ''
      this.$refs.redactData.clearValidate()
      this.$refs.goodsDeatil.clearContent()
    },
    // 关联
    relevancy (row, index) {
      this.title = '关联'
      if (row.betweenId === undefined || row.betweenId === null) {
        this.betweenId = ''
      } else {
        this.betweenId = row.betweenId
      }
      this.rwoId = row.id
      this.goodsCodingNum = row.goodsCoding
      this.dialogFormVisibleRelevance = true
    },
    // 关联 搜索
    async toSearch () {
      this.loadingRelevance = true
      if (this.searchGoodsCoding === '') {
        this.loadingRelevance = false
        this.$message({
          message: '请输入商品编码以便查询',
          type: 'warning'
        })
        // eslint-disable-next-line eqeqeq
      } else if (this.searchGoodsCoding == this.goodsCodingNum) {
        this.loadingRelevance = false
        this.$message({
          message: '同一商品无法关联自身',
          type: 'warning'
        })
      } else {
        const res = await api.selectGoodsCoding({
          goodsCoding: this.searchGoodsCoding
        })
        if (res.code === 200) {
          if (res.body) {
            if (
              res.body.goodsBrand === undefined ||
              res.body.goodsBrand === null
            ) {
              this.relevanceData[0].betweenId = ''
              this.$message({
                message: '查询为空'
              })
            } else {
              this.relevanceData[0].betweenId = res.body.betweenId
            }
            this.relevanceData[0].id = res.body.id
            this.relevanceData[0].goodsCoding = res.body.goodsCoding
            this.relevanceData[0].goodsName = res.body.goodsName
            this.relevanceData[0].pictureUrl = res.body.pictureUrl
            this.loadingRelevance = false
            this.searchGoodsCoding = ''
          } else {
            this.loadingRelevance = false
            this.$message({
              message: '未搜到该商品编码，请检查输入',
              type: 'info'
            })
          }
        } else {
          this.loadingRelevance = false
          this.$message({
            message: '查询失败',
            type: 'danger'
          })
        }
      }
    },
    // 关联 返回，关闭
    closeDialogRelevance () {
      this.dialogFormVisibleRelevance = false
      this.loadingRelevance = false
      this.title = ''
      this.betweenId = ''
      this.rwoId = ''
      this.goodsCodingNum = ''
      this.searchGoodsCoding = ''
      this.relevanceData[0].id = ''
      this.relevanceData[0].goodsCoding = ''
      this.relevanceData[0].goodsName = ''
      this.relevanceData[0].pictureUrl = ''
    },
    // 关联 添加 方法
    toAdd () {
      this.loadingRelevance = true
      if (
        this.relevanceData[0].id === '' &&
        this.relevanceData[0].goodsCoding === '' &&
        this.relevanceData[0].goodsName === '' &&
        this.relevanceData[0].pictureUrl === ''
      ) {
        this.loadingRelevance = false
        this.$message({
          message: '暂无数据，无法添加关联',
          type: 'warning'
        })
      } else {
        this.loadingRelevance = false
        this.$confirm(
          '是否将"' + this.relevanceData[0].goodsName + '"与此关联？',
          '操作',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(async () => {
          const res = await api.betweenAdd({
            bindLimitGoods: [
              {
                betweenId: this.betweenId,
                id: this.rwoId
              },
              {
                betweenId: this.relevanceData[0].betweenId,
                id: this.relevanceData[0].id
              }
            ]
          })
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '关联成功!'
            })
            this.relevanceData[0].betweenId = ''
            this.relevanceData[0].id = ''
            this.betweenId = ''
            this.closeDialogRelevance()
            this.initList()
          } else {
            this.$message({
              type: 'danger',
              message: '关联失败!'
            })
            this.closeDialogRelevance()
          }
        })
      }
    },
    // 详情
    async particulars (row, index) {
      // console.log(row)
      this.correlationData = []
      if (row.betweenId === undefined || row.betweenId === null) {
        this.betweenId = ''
      } else {
        this.betweenId = row.betweenId
      }
      this.title = '详情'
      this.loadingCorrelation = true
      this.redactData.goodsCoding = row.goodsCoding
      this.redactData.goodsName = row.goodsName
      this.redactData.goodsOne = row.goodsOne
      this.redactData.goodsTwo = row.goodsTwo
      this.redactData.goodsThree = row.goodsThree
      this.redactData.goodsBrand = row.goodsBrandName
      this.redactData.goodsIp = row.goodsIp
      this.redactData.pictureUrlString = row.pictureUrl
      this.redactData.goodsIp = row.goodsIpName
      this.redactData.goodsId = row.goodsId
      const _info = await api.goodsInfo({
        id: row.id
      })
      if (_info.code === 200) {
        this.redactData.goodsDeatil = this.showHtml(_info.body.goodsDeatil)
        // this.redactData.goodsDeatil = _info.body.goodsDeatil
      }
      const _Detail = await api.betweenDetail({
        // betweenId: this.betweenId,
        id: row.id
      })
      if (_Detail.code === 200) {
        console.log(_Detail.body)
        this.correlationData = _Detail.body
        this.loadingCorrelation = false
      } else {
        this.loadingCorrelation = false
      }
      this.dialogFormVisibleInfo = true
    },
    // 关联分页
    searchCorrelationPaging () {
      // console.log('1111')
    },
    // 详情关闭
    closeDialogInfo () {
      this.dialogFormVisibleInfo = false
      this.title = ''
      this.redactData.goodsCoding = ''
      this.redactData.goodsName = ''
      this.redactData.goodsOne = null
      this.redactData.goodsTwo = null
      this.redactData.goodsThree = null
      this.redactData.goodsBrand = ''
      this.redactData.goodsIp = ''
      this.redactData.pictureUrl = []
      this.redactData.goodsDeatil = ''
      this.redactData.id = ''
      this.betweenId = ''
      this.$refs.redactData.clearValidate()
    },
    // 解除关联
    relieve (row, index) {
      this.$confirm('是否解除与该商品的关联？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await api.betweenDelete({
          id: row.id
        })
        if (res.code === 200) {
          this.dialogFormVisibleInfo = false
          this.$message({
            type: 'success',
            message: '解除关联成功!'
          })
          this.betweenId = ''
          this.initList()
          // const _Detail = await api.betweenDetail({
          //   betweenId: this.betweenId,
          //   id: row.id
          // })
          // if (_Detail.code === 200) {
          //   console.log(_Detail.body)
          //   this.correlationData = _Detail.body.goodsDeatil
          //   this.loadingCorrelation = false
          // } else {
          //   this.loadingCorrelation = false
          // }
        } else {
          this.$message({
            type: 'danger',
            message: '解除关联失败!'
          })
        }
      })
    },
    // 删除
    remove (row, index) {
      this.$confirm('是否删除该商品？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await api.delete_goods({
          id: row.id
        })
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.initList()
        } else {
          this.$message({
            type: 'danger',
            message: '删除失败!'
          })
        }
      })
    },
    // 图片放大开启
    getPreview (pictureUrl) {
      this.judgment = '放大图片'
      this.dialogFormVisiblePic = true
      this.dialogImageUrl = pictureUrl
    },
    // 图片放大关闭
    closeDialogPic () {
      this.dialogFormVisiblePic = false
      this.dialogImageUrl = ''
    },
    // 图片校验
    validatePicture (rule, value, callback) {
      if (value.length === 0) {
        callback(new Error('图片为必选项'))
      } else {
        callback()
      }
    },
    async validateCheckName (rule, value, callback) {
      if (this.title === '新增') {
        const res = await api.goodsName({
          goodsName: value
        })
        if (!res.body) {
          callback(new Error('名字重复'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-message-box {
  width: 540px;
}

.class1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tooltip {
  position: absolute;
  top: 10px;
  left: 170px;
}

.relevance {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.searchBgc {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background-color: #dfe6ec;
  // margin-top: 20px;
  padding-left: 10px;
  font-weight: bolder;
}

.searchSty {
  display: flex;
  justify-content: space-around;
  width: calc(100% - 300px);
}

.searchBtnSty {
  display: flex;
}

.filter-item {
  width: 200px;
  height: 32px;
  margin: 0px 10px;
}

.copySty {
  font-size: 10px;
  cursor: pointer;
  color: #1482f0;
}

::v-deep .el-upload--picture-card {
  width: 150px;
  height: 150px;
}

::v-deep .el-icon-plus {
  text-align: center;
  line-height: 150px;
}
</style>
