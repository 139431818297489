//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';
import { useRoute, useRouter } from "vue-router";
export default {
  computed: {},
  methods: {
    upNew: function upNew() {
      window.open('https://docs.qq.com/doc/DWXFOd3BSV2pIQll5');
    }
  }
};