// 入库
export default (function (_ref) {
  var request = _ref.request;
  return {
    // 新增入库单
    warehouseIntoOrderSave: function warehouseIntoOrderSave() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouseIntoOrder/save',
        method: 'post',
        data: data
      });
    },
    // 查询入库单列表
    inventorylist_getPage: function inventorylist_getPage() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouseIntoOrder/getPageList',
        method: 'post',
        data: data
      });
    },
    // 编辑中的入库单详情
    getWarehouseIntoInfo: function getWarehouseIntoInfo() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouseIntoOrder/getWarehouseIntoInfo',
        method: 'post',
        data: data
      });
    },
    // 明细中的入库单详情
    getWarehouseDetailInfo: function getWarehouseDetailInfo() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouseIntoOrder/getWarehouseDetailInfo',
        method: 'post',
        data: data
      });
    },
    // 发货方式：代发 && 入库状态：完成入库（3） || 入库状态：待入库（0）
    getWarehouseDetailGoods: function getWarehouseDetailGoods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouseIntoOrderProduct/getPageList',
        method: 'post',
        data: data
      });
    },
    getWarehouseDetailGoods2: function getWarehouseDetailGoods2() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/intoDetail/getPageList',
        method: 'post',
        data: data
      });
    },
    // 编辑入库单 into_order_update
    warehouseIntoOrderUpdate: function warehouseIntoOrderUpdate() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouseIntoOrder/update',
        method: 'put',
        data: data
      });
    },
    // 详细商品信息中的重新生码
    intoDetailPrint: function intoDetailPrint() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/storage/reloadPrint',
        method: 'post',
        data: data
      });
    },
    // 查询异常详情
    getAbnormalDetail: function getAbnormalDetail() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouseIntoOrder/getAbnormalDetail',
        method: 'post',
        data: data
      });
    },
    // 终止入库
    stopEnterWarehouse: function stopEnterWarehouse() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouseIntoOrder/stopEnterWarehouse',
        method: 'put',
        data: data
      });
    },
    intoDetail_exceptionPageList: function intoDetail_exceptionPageList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/intoDetail/exceptionPageList',
        method: 'post',
        data: data
      });
    },
    inDetail_copyWarehouseIntoOrder: function inDetail_copyWarehouseIntoOrder() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouseIntoOrder/copyWarehouseIntoOrder',
        method: 'post',
        data: data
      });
    }
  };
});