<template>
  <div class="erp-select">
    <el-select v-model="val" @change="seleChange" size="small" placeholder="请选择">
      <slot />
    </el-select>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'ValueChange'
  },
  props: ['value'],
  data () {
    return {
      val: this.value,
      newValue: {},
      oldValue: {}
    }
  },
  watch: {
    value (val) {
      this.value = val
    }
  },
  methods: {
    seleChange (val) {
      this.newValue = val
      this.$emit('ValueChange', this.val)
      this.$emit('change', this.newValue, this.oldValue)
      this.oldValue = this.newValue
    }
  }
}
</script>

<style lang="scss" scoped></style>
