export var formData = {
  type: '',
  source: '',
  remark: '',
  courierCompany: '',
  courierOrderId: '',
  areaId: '',
  distribution: 0,
  chargeName: '',
  chargePhone: '',
  chargeAddress: '',
  outGoodsList: [],
  outImage: []
};
export var selectFormData = {
  statusList: [],
  typeList: [],
  sourceList: [],
  distributionList: [],
  outboundTime: [],
  orderId: '',
  createdId: null,
  goodsName: '',
  pageNo: 1,
  outId: '',
  pageSize: 50
};
export var authority = function authority(vue) {
  return {
    isAdd: vue.checkauthority('outbound_create'),
    // 新增
    isEdit: vue.checkauthority('outbound_upd'),
    // 编辑
    isShow: vue.checkauthority('outbound_select'),
    // 查询
    isInfo: vue.checkauthority('outbound_info') // 详情

  };
}; // 当出库类型为销售出库的时候显示
// <div class="title" v-if="titleName === '编辑入库单'">
//   <span>缺库商品</span>
//   <div class="btn">
//     <el-button type="success" size="small" @click="addRow('give')">添加</el-button>
//   </div>
// </div>
// <el-form-item prop="giveList" v-if="titleName === '编辑入库单'">
//   <el-table ref="giveList" :data="form.giveList" max-height="620px" element-loading-text="数据加载中"
//     :cell-style="{ textAlign: 'center' }" tooltip-effect="dark" :row-style="{ height: '57px' }"
//     :header-cell-style="{
//       background: '#dfe6ec',
//       color: '#666666',
//       textAlign: 'center',
//     }">
//     <el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
//     <el-table-column label="商品编码" width="150" prop="goodsCoding" align="center"></el-table-column>
//     <el-table-column label="商品名称" width="150" prop="commodityName" align="center"></el-table-column>
//     <el-table-column label="商品图片" width="150" align="center">
//       <template slot-scope="scope">
//         <el-image :src="scope.row.commodityImg" :preview-src-list="[scope.row.commodityImg]" fit="cover"
//           style="width: 50px; height: 50px"></el-image>
//       </template>
//     </el-table-column>
//     <el-table-column label="出库价" width="250" align="center">
//       <template slot-scope="scope">
//         <el-form-item :prop="'giveList.' + scope.$index + '.enterWarehousePrices'"
//           :rules="rules.enterWarehousePrices">
//           <el-input clearable type="number" size="small" v-model="scope.row.enterWarehousePrices"
//             placeholder="请输入入库价">
//           </el-input>
//         </el-form-item>
//       </template>
//     </el-table-column>
//     <el-table-column label="出库数量" width="250" align="center">
//       <template slot-scope="scope">
//         <el-form-item :prop="'giveList.' + scope.$index + '.enterWarehouseNums'"
//           :rules="rules.enterWarehouseNums">
//           <el-input clearable type="number" size="small" v-model="scope.row.enterWarehouseNums"
//             placeholder="请输入入库数量">
//           </el-input>
//         </el-form-item>
//       </template>
//     </el-table-column>
//     <el-table-column label="出库总计" width="150" align="center">
//       <template slot-scope="scope">
//         {{ (scope.row.enterWarehousePrices * scope.row.enterWarehouseNums) ? (scope.row.enterWarehousePrices *
//             scope.row.enterWarehouseNums) : ''
//         }}
//       </template>
//     </el-table-column>
//     <el-table-column fixed="right" label="操作" width="150" align="center">
//       <template slot-scope="scope">
//         <el-button type="danger" size="mini" @click="deleteRow(scope.$index, 'give')">删除</el-button>
//       </template>
//     </el-table-column>
//   </el-table>
// </el-form-item>