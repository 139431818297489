<template>
  <div class="orderDetails">
    <!-- 基本信息 -->
    <Basic v-if="!auth.isDetail" :info="info"></Basic>
    <!-- 商品信息 -->
    <Good
      v-if="!auth.isGoods"
      :goods="goods"
      :info="info"
      @getGoods="getGoods"
      @refresh="refresh"
    ></Good>
    <!-- 入库明细 -->
    <Particulars
      v-if="info.enterWarehouseState !== 0 && !auth.isDetail"
      :info="info"
    ></Particulars>
    <!-- 操作记录 -->
    <Log v-if="!auth.isDetail" :operateLogs="info.operateLogs"></Log>
    <div style="display: flex;justify-content: center;margin-bottom: 20px;">
      <el-button size="small" @click="resetForm()">
        关闭
      </el-button>
      <el-button
        v-if="info.enterWarehouseType === '其他' &&  info.isStop === true && permission.isCopy === false"
        :disabled="loadingCopy"
        :loading="loadingCopy"
        size="small"
        type="success"
        @click.native.prevent="copyOrder()"
      >
        复制订单
      </el-button>
      <!--      v-if="info.status === 4 && info.type === 3"-->
    </div>
  </div>
</template>
<script>
import Basic from './com/basic.vue'
import Good from './com/good.vue'
import Particulars from './com/particulars.vue'
import Log from './com/log.vue'
import api from '@/api'
import { authority } from '../list/contant'
import { mapActions } from 'vuex'
// 入库状态;
// 0 待入库
// 1 入库中
// 2 部分入库
// 3 完成入库
// 4 已终止
// 5 代发全部入库
// 6 调拨入库
export default {
  name: 'orderDetails',
  data () {
    return {
      // 权限点
      permission: {
        isCopy: this.checkauthority('into_order_copy') // 复制订单
      },
      query: {
        enterWarehouseNumber: this.$route.query.id
      },
      loadingCopy: false,
      info: {},
      goods: {},
      auth: {}
    }
  },
  created () {
    this.$store.commit('d2admin/page/modifyOpend', opened => {
      opened.forEach((current) => {
        if (current.name === 'stockindetails') {
          current.meta.title = '入库明细-' + current.query.id
        }
      })
    })
    this.auth = authority(this)
    this.getInfo()
  },
  watch: {
    $route (to, from) {
      this.query.enterWarehouseNumber = to.query.id
      this.refresh()
    }
  },
  methods: {
    ...mapActions('d2admin/page', ['close']),
    refresh () {
      this.getInfo()
      this.getGoods({
        pageNo: 1,
        pageSize: 50
      })
    },
    async getInfo () {
      const { body: data } = await api.getWarehouseDetailInfo({
        enterWarehouseNumber: this.query.enterWarehouseNumber
      })
      this.info = data
      this.info.small_trash = this.info.small_trash ? this.info.small_trash : 1
      if (this.info.enterWarehouseState === 9) {
        this.info.isStop = true
        this.info.enterWarehouseState = 0
      }
    },
    async getGoods (page) {
      let data
      if (
        (this.info.enterWarehouseState === 3 &&
          this.info.enterWarehouseType === '代发') ||
        this.info.enterWarehouseState === 0 ||
        this.info.isStop
      ) {
        data = await api.getWarehouseDetailGoods({
          enterWarehouseNumber: this.query.enterWarehouseNumber,
          ...page
        })
      } else {
        data = await api.getWarehouseDetailGoods2({
          enterWarehouseNumber: this.query.enterWarehouseNumber,
          ...page
        })
      }
      this.goods = data.body
    },
    resetForm () {
      const tagName = '/stockindetails?id=' + this.$route.query.id
      this.$router.push({ path: '/stockIn' })
      this.close({ tagName: tagName })
    },
    async copyOrder () {
      this.loadingCopy = true
      try {
        await api.inDetail_copyWarehouseIntoOrder({
          enterWarehouseNumber: this.info.enterWarehouseNumber
        })
        this.$message.success('已复制该订单')
        this.resetForm()
      } catch (e) {
        this.$message.warning('复制订单失败')
      } finally {
        this.loadingCopy = false
      }
    }
  },
  components: {
    Basic,
    Good,
    Particulars,
    Log
  }
}
</script>
<style lang="scss" scoped>
::v-deep .d2-theme-container-main-body {
  overflow: auto !important;
}
</style>
