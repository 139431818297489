export default ({ request }) => ({
    // 获取调拨报表
    getAllocationDetailsList(data = {}) {
        return request({
            url: '/newStatical/getTranResult',
            method: 'post',
            data: data
        })
    },
    // 调拨的聚合
    getNewStaticalGetNewStatResponse(data = {}) {
        return request({
            url: '/newStatical/getNewStatResponse',
            method: 'post',
            data: data
        })
    },
})