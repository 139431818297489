var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c("div", { staticClass: "header" }, [
      _c(
        "div",
        { staticStyle: { "margin-left": "10px", "margin-right": "10px" } },
        [
          _c("el-input", {
            staticStyle: { width: "200px" },
            attrs: {
              placeholder: "请输入商品品牌",
              clearable: "",
              size: "small"
            },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchTotal()
              }
            },
            model: {
              value: _vm.search.brandName,
              callback: function($$v) {
                _vm.$set(_vm.search, "brandName", $$v)
              },
              expression: "search.brandName"
            }
          }),
          !_vm.permission.isSearch
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: {
                    icon: "el-icon-search",
                    type: "primary",
                    size: "small"
                  },
                  on: {
                    click: function($event) {
                      return _vm.searchTotal()
                    }
                  }
                },
                [_vm._v(" 搜索 ")]
              )
            : _vm._e(),
          !_vm.permission.isReset
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: {
                    icon: "el-icon-refresh",
                    type: "warning",
                    size: "small"
                  },
                  on: {
                    click: function($event) {
                      return _vm.reset()
                    }
                  }
                },
                [_vm._v(" 重置 ")]
              )
            : _vm._e(),
          !_vm.permission.isAdd
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: {
                    icon: "el-icon-circle-plus-outline",
                    type: "success",
                    size: "small"
                  },
                  on: { click: _vm.add }
                },
                [_vm._v(" 新增 ")]
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _c(
      "div",
      {
        staticStyle: {
          "margin-top": "10px",
          "margin-left": "10px",
          "margin-right": "10px"
        }
      },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingTotal,
                expression: "loadingTotal"
              }
            ],
            ref: "tableData",
            attrs: {
              data: _vm.tableData,
              "max-height": "645px",
              "element-loading-text": "数据加载中",
              "cell-style": { textAlign: "center" },
              "tooltip-effect": "dark",
              "row-style": { height: "57px" },
              "header-cell-style": {
                background: "#dfe6ec",
                color: "#666666",
                textAlign: "center"
              }
            }
          },
          [
            _c("el-table-column", {
              attrs: { label: "序号", width: "80", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.search.pageNo - 1) * _vm.search.pageSize +
                              scope.$index +
                              1
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { label: "品牌名称", prop: "brandName", align: "center" }
            }),
            _c("el-table-column", {
              attrs: {
                label: "品牌图片",
                prop: "brandPicture",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("img", {
                        staticStyle: {
                          width: "50px",
                          height: "50px",
                          "margin-top": "8px",
                          cursor: "pointer"
                        },
                        attrs: {
                          src: _vm.downloadUrl + "/" + scope.row.brandPicture,
                          alt: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.getPreview(
                              _vm.downloadUrl + "/" + scope.row.brandPicture
                            )
                          }
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { label: "操作", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      !_vm.permission.isRedact
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                icon: "el-icon-edit-outline",
                                plain: "",
                                size: "small"
                              },
                              nativeOn: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.redact(
                                    scope.row,
                                    scope.$index,
                                    _vm.tableData
                                  )
                                }
                              }
                            },
                            [_vm._v(" 编辑 ")]
                          )
                        : _vm._e(),
                      !_vm.permission.isDelete
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "danger",
                                icon: "el-icon-delete",
                                plain: "",
                                size: "small"
                              },
                              nativeOn: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.remove(
                                    scope.row,
                                    scope.$index,
                                    _vm.tableData
                                  )
                                }
                              }
                            },
                            [_vm._v(" 删除 ")]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "right",
              "margin-top": "10px"
            }
          },
          [
            this.search.pageSize > 0
              ? _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.search.pageNo,
                    "page-sizes": [10, 20, 30, 40, 50, 100],
                    "page-size": _vm.search.pageSize,
                    layout: "total, jumper, prev, pager, next,sizes",
                    total: _vm.search.pageTotal
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticStyle: { margin: "20px auto" },
            attrs: {
              "append-to-body": true,
              title: _vm.judgment,
              visible: _vm.dialogFormVisible,
              width: "25%",
              "close-on-click-modal": false
            },
            on: {
              "update:visible": function($event) {
                _vm.dialogFormVisible = $event
              },
              close: function($event) {
                return _vm.closeDialog()
              }
            }
          },
          [
            _c(
              "el-form",
              {
                ref: "redactData",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.redactData,
                  "label-width": "100px",
                  rules: _vm.rules
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "品牌名称", prop: "brandName" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "240px" },
                      attrs: { placeholder: "请输入品牌名称", clearable: "" },
                      model: {
                        value: _vm.redactData.brandName,
                        callback: function($$v) {
                          _vm.$set(_vm.redactData, "brandName", $$v)
                        },
                        expression: "redactData.brandName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "品牌图片", prop: "brandPicture" } },
                  [
                    _c("erp-upload-img", {
                      attrs: {
                        showDelete: true,
                        "upload-path": "/commodityManagement/brand/",
                        maxCount: 1
                      },
                      model: {
                        value: _vm.redactData.brandPicture,
                        callback: function($$v) {
                          _vm.$set(_vm.redactData, "brandPicture", $$v)
                        },
                        expression: "redactData.brandPicture"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center"
                    }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.resetForm("redactData")
                          }
                        }
                      },
                      [_vm._v(" 取消 ")]
                    ),
                    this.judgment === "新增"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.submitForm("redactData")
                              }
                            }
                          },
                          [_vm._v(" 立即创建 ")]
                        )
                      : _vm._e(),
                    this.judgment === "编辑"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.submitForm("redactData")
                              }
                            }
                          },
                          [_vm._v(" 保存 ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticStyle: { margin: "20px auto" },
            attrs: {
              "append-to-body": true,
              title: _vm.judgment_pic,
              visible: _vm.dialogFormVisible_pic,
              width: "540px",
              "close-on-click-modal": false
            },
            on: {
              "update:visible": function($event) {
                _vm.dialogFormVisible_pic = $event
              },
              close: function($event) {
                return _vm.closeDialogPic()
              }
            }
          },
          [
            this.dialogImageUrl !== ""
              ? _c("img", {
                  staticStyle: {
                    width: "500px",
                    height: "500px",
                    "text-align": "center"
                  },
                  attrs: { alt: "", src: this.dialogImageUrl }
                })
              : _vm._e(),
            this.dialogImageUrl === ""
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      width: "500px",
                      height: "500px",
                      "text-align": "center",
                      border: "1px solid #c0c0c0",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center"
                    }
                  },
                  [
                    _c("span", { staticStyle: { "font-size": "large" } }, [
                      _vm._v("暂无图片")
                    ])
                  ]
                )
              : _vm._e()
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }