<template>
  <div>
    <div class="dashboard-editor-container">
      <div class="singSty">
        <div class="verticalBar"></div>
        <span class="textSty">统计</span>
      </div>
      <div class="whSty">
        <!-- 创建饼图 -->
        <div v-loading="loadingEcharts" style="height:100%">
          <div ref="pieChart" style="width:95%;height:95%;"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api'
export default {
  name: 'pieStatistics',
  data () {
    return {
      loadingEcharts: false,
      echartsData: [],
      echartsDataName: [],
      echartsDataData: []
    }
  },
  mounted () {
    this.initList()
  },
  methods: {
    async initList () {
      this.loadingEcharts = true
      const res = await api.getPieStatistics()
      if (res.code === 200) {
        this.echartsData = res.body
        this.loadingEcharts = false
        this.initChart()
      } else {
        this.loadingEcharts = false
        this.$message.error('饼图加载失败')
      }
    },
    initChart () {
      const myChart = this.$echarts.init(this.$refs.pieChart)
      this.echartsData.forEach(e => {
        this.echartsDataName.push(e.name)
      })
      this.echartsData.forEach(e => {
        const obj = {
          value: e.value,
          name: e.name
        }
        this.echartsDataData.push(obj)
      })
      console.log(this.echartsDataName)
      console.log(this.echartsDataData, 'echartsDataData')
      //  this.echartsDataName = this.echartsData.map(v => v.name) // 数据转换
      // const pieData = [
      //   {
      //     value: this.echartsData[0].value,
      //     name: '出库订单'
      //   },
      //   {
      //     value: this.echartsData[1].value,
      //     name: '采购订单'
      //   },
      //   {
      //     value: this.echartsData[2].value,
      //     name: '代发订单'
      //   },
      //   {
      //     value: this.echartsData[3].value,
      //     name: '调拨订单'
      //   },
      //   {
      //     value: this.echartsData[4].value,
      //     name: '入库订单'
      //   }
      // ]
      // 绘制图表
      const option = {
        legend: {
          right: '0',
          bottom: '10',
          orient: 'vertical',
          data: this.echartsDataName
        },
        tooltip: {},
        series: [
          {
            type: 'pie',
            data: this.echartsDataData
          }
        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>
<style scoped>
.dashboard-editor-container {
  margin-top: 20px;
  padding: 20px 20px 20px 0px;
  margin-left: 20px;
  /* margin-right: 20px; */
  background-color: #ffffff;
  box-shadow: 0px 0px 4px #999999;
  border-radius: 5px;
}
.singSty {
  display: flex;
  height: 20px;
  padding-left: 10px;
}
.verticalBar {
  width: 10px;
  line-height: 20px;
  border-radius: 25%;
  background-color: #8ddece;
  margin-right: 5px;
}
.textSty {
  line-height: 20px;
  font-weight: bold;
}
.whSty {
  width: 100%;
  height: 400px;
}
</style>
