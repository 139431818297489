<template>
  <d2-container>
    <div>
      <div style="width:100%;border:1px solid #e0e0e0;margin:0 auto;padding: 10px 0 10px 0;">
        <!-- <div>图片演示</div> -->
        <div style="display:flex;width: 80%;margin:0 auto;justify-content: space-around">
          <div><img src="../img/warehouse.png" alt="" class="imgDemonstration" />
            <div class="imgTitle">仓库</div>
          </div>
          <div class="arrows">
            <div class="arrowsLeft"></div><i class="el-icon-arrow-right arrowsRight"></i>
          </div>
          <div><img src="../img/area.png" alt="" class="imgDemonstration"
              style="width:40px;height:40px;margin-top:6px" />
            <div class="imgTitle">区域</div>
          </div>
          <div class="arrows">
            <div class="arrowsLeft"></div><i class="el-icon-arrow-right arrowsRight"></i>
          </div>
          <div><img src="../img/shelves.png" alt="" class="imgDemonstration" />
            <div class="imgTitle">货架</div>
          </div>
          <div class="arrows">
            <div class="arrowsLeft"></div><i class="el-icon-arrow-right arrowsRight"></i>
          </div>
          <div><img src="../img/location.png" alt="" class="imgDemonstration" />
            <div class="imgTitle">库位</div>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px;display: flex;justify-content: space-between;width: 98%;margin: 10px auto;">
        <div class="courseIdClass">
              <div style="display: flex;">
              <erp-isJurisdiction-search type="search" ref="getJurisdictionData"></erp-isJurisdiction-search>
              <head-search label-style="width:100px">
                <head-search-item label="仓库名称">
              <el-input :disabled="permission.warehouse_select" v-model="warehouseName" placeholder="仓库名称"
              @keyup.enter.native="searchX()"
                style="width:200px;margin-right: 8px;" clearable size="small"></el-input>
                </head-search-item>
              </head-search>
              <div>
                <el-button v-if="!permission.warehouse_select" type="primary" @click="searchX()" size="small"
                icon="el-icon-search">搜索</el-button>
              <el-button v-if="!permission.warehouse_select" type="warning" @click="reset()" size="small"
                icon="el-icon-refresh-left">重置</el-button>
              <el-button type="success" @click="addWarehouse" v-if="!permission.isAddWarehouse" size="small"
                icon="el-icon-plus">新增仓库
              </el-button>
              </div>
              </div>
        </div>
      </div>
      <!-- 表单 -->
      <div>
        <el-table v-if="!permission.warehouse_select" :data="tableData" style="width: 100%;margin-top: 10px;"
          element-loading-text="数据加载中"
        :cell-style="{ textAlign: 'center' }"
        max-height="618px"
        v-loading="loadingTotal"
        :row-style="{ height: '57px', textAlign: 'center' }"
        :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center'
        }"
          :default-expand-all="defaultExpandAll">
          <el-table-column label="序号" width="50" align="center">
            <template slot-scope="scope">
              {{ (search.currpage - 1) * search.pagesize + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="companyName" label="公司名称" align="center">
          </el-table-column>
          <el-table-column prop="departmentName" label="所属部门" align="center">
          </el-table-column>
          <el-table-column prop="warehouseName" label="仓库名称" align="center">
          </el-table-column>
          <el-table-column prop="warehouseAddress" label="仓库地址" align="center">
          </el-table-column>
          <el-table-column prop="areaNum" label="区域数量" align="center">
          </el-table-column>
          <el-table-column prop="shelvesNum" label="货架数量" align="center">
          </el-table-column>
          <el-table-column prop="layerNum" label="层数总计" align="center">
            <template slot-scope="scope">
              {{scope.row.layerNum==null?0:scope.row.layerNum}}
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" width="400" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="success" @click="editorWarehouse(scope.$index)" v-if="!permission.isEditorWarehouse"
                plain icon="el-icon-edit-outline" size="small">
                编辑仓库
              </el-button>
              <el-button type="info" @click="particulars(scope.$index)" v-if="!permission.isParticulars"
                size="small" plain icon="el-icon-info">仓库详情
              </el-button>
              <el-button type="danger" @click="deleteX(scope.$index)" :disabled="deleteLoading" :loading="deleteLoading" v-if="!permission.isDeleteX" size="small"
                plain icon="el-icon-delete" :key="scope.row.warehouseId">删除仓库</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="block" v-if="sumDataCount!==0">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="search.currpage" :page-sizes="[10,20,30,40,50,100]" :page-size="search.pagesize"
            layout="total, jumper, prev, pager, next,sizes" :total="search.currentPage">
          </el-pagination>
        </div>
      </div>
      <!-- 编辑仓库/新增仓库 -->
      <el-dialog :title="dialogTitle1" :visible.sync="dialogVisible1" width="400px" :close-on-click-modal="false"
        @close="WarehouseDialog">
        <el-form label-position="right" label-width="80px" :model="form1" :rules="rules" ref="ruleForm">
          <el-form-item label="仓库名称" prop="name">
            <el-input v-model="form1.name" clearable maxlength="10" size="small"  style="width:200px"></el-input>
          </el-form-item>
          <el-form-item label="仓库地址" prop="address">
            <el-input v-model="form1.address" clearable maxlength="100" size="small" style="width:200px"></el-input>
          </el-form-item>
          <el-form-item label="所属部门" prop="orgId">
            <el-select :disabled="dialogTitle1==='编辑仓库'" v-model="form1.orgId" clearable size="small" style="width:200px" placeholder="请选择部门">
              <el-option v-for="item in optionsX" :key="item.orgId" :label="item.orgName" :value="item.orgId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="WarehouseaCncel()" size="small">取 消</el-button>
          <el-button type="primary" :disabled="warehouseDetermineLoading" :loading="warehouseDetermineLoading" @click="WarehouseDetermine(dialogTitle1)" size="small">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </d2-container>

</template>
<script>
import api from '@/api'
import store from '@/store'
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export default {
  name: 'warehouseStructure',
  data () {
    return {
      departmentDisabled: true,
      defaultExpandAll: false,
      warehouseDetermineLoading: false,
      sumDataCount: '',
      permission: {
        isAddWarehouse: this.checkauthority('warehouse_new'), // 新增仓库
        isEditorWarehouse: this.checkauthority('update_warehouse'), // 编辑仓库
        isParticulars: this.checkauthority('warehouse_detail'), // 仓库详情
        isDeleteX: this.checkauthority('delete_warehouse'), // 删除仓库
        isSelect: this.checkauthority('warehouse_select') // 查看权限
      },
      disabled_shelves: true,
      loadingTotal: false,
      deleteLoading: false,
      warehouseName: '', // 搜索仓库名
      dialogVisible1: false, // 编辑仓库/新增仓库弹窗是否展示
      dialogTitle1: '', // 编辑仓库/新增仓库弹窗的title
      index1: 0, // 编辑仓库时获取的index
      /// /编辑仓库/新增仓库弹窗的绑定值
      form1: {
        name: '',
        address: '',
        orgId: ''
      },
      // 主页分页
      search: {
        currentPage: 1, // 总共有多少条
        pagesize: 50, // 每页的数据条数
        currpage: 1// 默认开始页面
      },
      tableData: [],
      // 所属部门的名称以及id
      optionsX: [
      ],
      // 所有仓库的名称以及id
      options: [
      ],
      // 所有公司的名称以及ID
      company: [],
      // 所有部门的名称以及ID
      department: [],
      // 选择查询的公司Id
      companyId: '',
      // 选择查询的部门ID
      departmentIds: [],
      // 编辑仓库/新增仓库的校验
      rules: {
        // 编辑仓库/新增仓库名称校验
        name: [
          { required: true, message: '请输入仓库名称', trigger: 'blur' },
          { pattern: /^(?!\s+)/, message: '第一个字符或所有字符不能为空格', trigger: 'blur' }
        ],
        // 编辑仓库/新增仓库仓库地址校验
        address: [
          { required: true, message: '请输入仓库地址', trigger: 'blur' },
          { pattern: /^(?!\s+)/, message: '第一个字符或所有字符不能为空格', trigger: 'blur' }
        ],
        // 编辑仓库/新增仓管人员校验
        orgId: [
          { required: true, message: '请选择部门', trigger: 'change' }
        ]
      }
    }
  },
  async created () {
    this.loadingTotal = true
    await this.getUserDeptSelect()
    await this.searchWarehouse()
    await this.initList()
    this.loadingTotal = false
  },
  methods: {
    // 部门查询
    async getUserDeptSelect () {
      const res = await api.warehouseStructure_getUserDeptSelect({})
      this.optionsX = res.body
    },
    // 仓库查询
    async searchWarehouse () {
      const res = await api.warehouseStructure_searchWarehouse({})
      this.options = res.body
    },
    // 初始化页面
    async initList () {
      this.companyId = this.$refs.getJurisdictionData.companyId
      this.departmentIds = this.$refs.getJurisdictionData.departmentId
      try {
        this.loadingTotal = true
        const res = await api.warehouseStructure_getPageList({
          pageNo: this.search.currpage,
          pageSize: this.search.pagesize,
          companyId: this.companyId,
          warehouseName: this.warehouseName,
          departmentIds: this.departmentIds
        })
        this.tableData = res.body.datas
        this.search.currpage = res.body.pageNo
        this.search.pagesize = res.body.pageSize
        this.search.currentPage = res.body.sumDataCount
      } catch (error) {
        this.$message({
          type: 'warning',
          message: '获取数据失败!'
        })
      } finally {
        this.loadingTotal = false
      }
    },
    // 仓库
    // 新增仓库
    addWarehouse () {
      this.dialogVisible1 = true
      this.dialogTitle1 = '新增仓库'
    },
    // 编辑仓库
    editorWarehouse (index) {
      // particular
      this.dialogVisible1 = true
      this.dialogTitle1 = '编辑仓库'
      this.form1.name = this.tableData[index].warehouseName
      this.form1.address = this.tableData[index].warehouseAddress
      this.form1.orgId = this.tableData[index].departmentId
      this.index1 = index
    },
    // 仓库对话框的关闭
    WarehouseDialog () {
      this.dialogVisible1 = false
      this.$refs.ruleForm.resetFields()
      this.form1.name = ''
      this.form1.address = ''
      this.form1.personnel = ''
    },
    // 仓库对话框的取消
    WarehouseaCncel () {
      this.WarehouseDialog()
    },
    // 仓库对话框的确定
    WarehouseDetermine (d) {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            this.warehouseDetermineLoading = true
            if (d === '新增仓库') {
              const res = await api.warehouseStructure_newWarehouse({
                departmentId: this.form1.orgId,
                warehouseAddress: this.form1.address,
                warehouseName: this.form1.name
              })
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '新增仓库成功!'
                })
                this.initList()
                this.WarehouseDialog()
              }
            } else if (d === '编辑仓库') {
              const i = this.index1
              const res = await api.warehouseStructure_updateWarehouse({
                departmentId: this.form1.orgId,
                version: this.tableData[i].version,
                warehouseAddress: this.form1.address,
                warehouseId: this.tableData[i].warehouseId,
                warehouseName: this.form1.name
              })
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '修改成功!'
                })
                this.initList()
                this.WarehouseDialog()
              }
            }
          } catch (error) {
            console.error(error) // 打印错误信息
            this.$message({
              type: 'warning',
              message: '操作失败!'
            })
          } finally {
            this.warehouseDetermineLoading = false
          }
        } else {
          this.$message.warning('请填写完整信息')
        }
      })
    },
    // 详情
    // 详情按钮(区域详情)
    async particulars (index) {
      var newRes = {
        path: '/arealDetails' + this.tableData[index].warehouseId,
        name: this.tableData[index].warehouseName,
        component: _import('store/arealDetails/index'),
        meta: {
          title: this.tableData[index].warehouseName
        }
      }
      var routes = this.$router.options.routes[0]
      routes.children.push(newRes)
      this.$router.addRoutes(this.$router.options.routes)
      store.commit('d2admin/page/init', this.$router.options.routes)

      var par = {
        pageNo: this.search.currpage,
        pageSize: this.search.pagesize,
        warehouseName: this.tableData[index].warehouseName
      }
      localStorage.setItem(this.tableData[index].warehouseId, JSON.stringify(par))
      await this.$router.push({
        path: '/arealDetails' + this.tableData[index].warehouseId,
        query: {
          warehouseId: this.tableData[index].warehouseId
        }
      })
    },

    // 主页面删除
    deleteX (index) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          this.deleteLoading = true
          if (this.tableData.length === 1 && this.search.currpage !== 1) {
            this.search.currpage = this.search.currpage - 1
          }
          await api.warehouseStructure_deleteWarehouse({
            version: this.tableData[index].version,
            warehouseId: this.tableData[index].warehouseId
          })
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.initList()
        } catch (error) {
          console.error(error) // 打印错误信息
          this.$message({
            type: 'warning',
            message: '删除失败!'
          })
        } finally {
          this.deleteLoading = false
        }
      })
    },
    // 主页面搜索
    async searchX () {
      this.search.pageNo = 1
      this.initList()
    },
    // 主页面重置
    reset () {
      // this.$refs.getJurisdictionData.companyId = ''
      // this.$refs.getJurisdictionData.departmentId = []
      this.$refs.getJurisdictionData.disabledInput()
      this.warehouseName = ''
      this.initList()
    },
    // 主页分页
    handleSizeChange (val) {
      this.search.pagesize = val
      this.initList()
    },
    handleCurrentChange (val) {
      this.search.currpage = val
      this.initList()
    },
    // 新增货架里的仓库下拉框的改变
    async shelves_warehouse () {
      if (this.form3.id !== '') {
        this.disabled_shelves = false
        const res = await api.warehouseStructure_searchArea({ warehouseId: this.form3.id })
        this.options1 = res.body
        // for (let i = 0; i < this.options1.length; i++) {
        //   this.options1[i].id = this.options1[i].id.toString()
        // }
      } else {
        this.disabled_shelves = true
        this.form3.areaId = ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.head-container {
  .el-row[style="margin-left: -10px; margin-right: -10px; height: 37px;"] {
    position: absolute;
    top: 100px;
    width: 300px;
    right: -140px;
    z-index: 10;
    display: flex;
  }
}

::v-deep .elFormX {
  .el-form-item__content {
    margin-left: 0px !important;
  }
}

.headline {
  width: 100%;
  font-size: 20px;
  line-height: 40px;
  background-color: #a3a3a3;
  color: #fff;
}

::v-deep .el-form-item__error {
  position: absolute;
  bottom: 10px;
}

.paging {
  height: 40px;
}

.el-pagination {
  position: absolute;
  right: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: space-around;
  padding: 0 100px;
}

.block {
  margin-top: 10px;
}

.imgTitle {
  text-align: center;
}

.imgDemonstration {
  width: 50px;
  height: 50px;
}

.arrows {
  display: flex;
  align-items: center;
  margin-top: -10px;
}

.arrowsLeft {
  height: 3px;
  background-color: #1296db;
  width: 180px;
  margin-top: 2px;
}

.arrowsRight {
  color: #1296db;
  font-weight: 900;
  font-size: 40px;
  margin-left: -25px;
}

::v-deep .courseIdClass .el-tag {
  height: 100%;
  white-space: normal;
}

.sty {
  display: inline-block;
}
</style>
