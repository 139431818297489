var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        title: _vm.titleName,
        visible: _vm.dialogVisible,
        width: "20%",
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "status-icon": "",
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "姓名", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "inputText",
                attrs: { clearable: "", size: "small", disabled: _vm.disabled },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.save()
                  }
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户名称", prop: "userName" } },
            [
              _c("el-input", {
                staticClass: "inputText",
                attrs: { clearable: "", size: "small", disabled: _vm.disabled },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.save()
                  }
                },
                model: {
                  value: _vm.form.userName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "userName", $$v)
                  },
                  expression: "form.userName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户密码", prop: "passWord" } },
            [
              _c("el-input", {
                staticClass: "inputText",
                attrs: {
                  maxlength: "20",
                  clearable: "",
                  size: "small",
                  disabled: _vm.disabled,
                  type: _vm.titleName === "新增用户" ? "text" : "password"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.save()
                  }
                },
                model: {
                  value: _vm.form.passWord,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "passWord", $$v)
                  },
                  expression: "form.passWord"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属公司", prop: "orgId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.disabledCompany,
                    clearable: "",
                    size: "small",
                    placeholder: "请选择所属公司"
                  },
                  on: { change: _vm.changeCompany },
                  model: {
                    value: _vm.form.orgId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "orgId", $$v)
                    },
                    expression: "form.orgId"
                  }
                },
                _vm._l(_vm.company, function(item) {
                  return _c("el-option", {
                    key: item.orgId,
                    attrs: { label: item.orgName, value: item.orgId }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属部门", prop: "deptId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.disabledDepartment || this.form.orgId === "",
                    clearable: "",
                    size: "small",
                    placeholder: "请选择所属部门"
                  },
                  model: {
                    value: _vm.form.deptId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "deptId", $$v)
                    },
                    expression: "form.deptId"
                  }
                },
                _vm._l(_vm.part, function(item) {
                  return _c("el-option", {
                    key: item.orgId,
                    attrs: { label: item.orgName, value: item.orgId }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: 0 },
                    model: {
                      value: _vm.form.userState,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "userState", $$v)
                      },
                      expression: "form.userState"
                    }
                  },
                  [_vm._v("正常")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: 1 },
                    model: {
                      value: _vm.form.userState,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "userState", $$v)
                      },
                      expression: "form.userState"
                    }
                  },
                  [_vm._v("禁用")]
                )
              ]
            ],
            2
          ),
          _vm.form.roleId === 2 && _vm.userType === "总经办"
            ? _c(
                "el-form-item",
                { attrs: { label: "角色分配", prop: "roleId" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "", size: "small" },
                    model: {
                      value: _vm.userType,
                      callback: function($$v) {
                        _vm.userType = $$v
                      },
                      expression: "userType"
                    }
                  })
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "角色分配", prop: "roleId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: this.form.orgId === "",
                        size: "small",
                        placeholder: "请选择角色"
                      },
                      model: {
                        value: _vm.form.roleId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "roleId", $$v)
                        },
                        expression: "form.roleId"
                      }
                    },
                    _vm._l(_vm.roleList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: parseInt(item.id) }
                      })
                    }),
                    1
                  )
                ],
                1
              )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottomDiv" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("关闭")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: _vm.disabled || _vm.cannonry,
                type: "primary",
                size: "small"
              },
              on: { click: _vm.save }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }