<template>
  <div >
    <div>
      <div v-if="auth.org_normal_query" class="search">
        <el-input v-model="search.orgName" clearable placeholder="请输入机构名称" size="small" style="width: 300px;">
        </el-input>
        <div class="btn">
          <el-button icon="el-icon-search" size="small" type="primary" @click="searchBtn">搜索</el-button>
          <el-button icon="el-icon-refresh" size="small" type="warning" @click="resetBtn">重置</el-button>
          <el-button v-if="auth.org_add" icon="el-icon-circle-plus-outline" size="small" type="success" @click="add">新增
          </el-button>
        </div>
      </div>
      <div style=" overflow:auto">
        <el-table v-if="auth.org_normal_query" ref="table" :data="tableData" :lazy="auth.org_dept_list" :load="load"
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}" border row-key="orgId"
                  style="width: 100%">
          <el-table-column align="left" label="机构名称" prop="orgName" width="180">
          </el-table-column>
          <el-table-column v-if="auth.org_add || auth.org_add || auth.org_update_name" align="left" label="操作"
                           prop="name" width="180">
            <template slot-scope="scope">
              <el-button v-if="scope.row.orgPid == -1 && auth.org_add" icon="el-icon-circle-plus-outline" plain
                         size="small" type="success" @click="addSubclass(scope.row)">
                新增部门
              </el-button>
              <el-button v-if="auth.org_update_name" icon="el-icon-edit-outline" plain size="small" type="primary"
                         @click="compile(scope.row)">编辑
              </el-button>
              <el-button v-if="scope.row.orgManagerFlag !== 1 && auth.org_delete" icon="el-icon-delete" plain size="small"
                         type="danger" @click="deletes(scope.row)">删除
              </el-button>
            </template>
          </el-table-column>

        </el-table>
      </div>
    </div>
    <div>
      <el-dialog :append-to-body="true" :close-on-click-modal="false" :title="titleName" :visible.sync="dialogVisible" width="300px"
                 @close="closeLog">
        <el-form ref="ruleForm" :rules="rules" class="elFormX" label-position="right" label-width="80px">
          <div v-show="titleName === '删除'" style="margin-left:30px">是否确认删除？</div>
          <el-input  v-if="titleName !== '删除' && PName!==''" v-model="PName" :disabled="true"
                    clearable maxlength="20" placeholder="请输入名称"
                    size="small" style="width:250px;margin-left: 20px;margin-bottom: 20px;">
          </el-input>
          <el-input v-if="titleName !== '删除'" v-model="form.orgName" clearable maxlength="20"
                    placeholder="请输入名称" size="small" style="width:250px;margin-left: 20px;">
          </el-input>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false, form.orgName = '',PName = ''">取 消</el-button>
          <el-button type="primary" @click="save(titleName)">确定</el-button>
        </span>
      </el-dialog>
      <el-pagination
        v-if="pageSize > 0"
        style="margin-top: 20px;display:flex;justify-content: right"
        :current-page="pageNo"
        :page-sizes="[10,20,30,40,50,100]"
        :total="pageTotal"
        layout="total, jumper, prev, pager, next,sizes"
        @size-change="sizeChange"
        @current-change="currentChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import api from '@/api'

export default {
  name: 'organizationChart',
  data () {
    return {
      objMap: new Map(),
      PName: '',
      auth: {
        org_add: !this.checkauthority('org_add'),
        org_delete: !this.checkauthority('org_delete'),
        org_update_name: !this.checkauthority('org_update_name'),
        org_normal_query: !this.checkauthority('org_normal_query'),
        org_dept_list: !this.checkauthority('org_dept_list')
      },
      search: {
        orgName: ''
      },
      pageNo: 1,
      pageSize: 10,
      pageTotal: 0,
      newarr: [],
      orgId: '',
      orgPid: '',
      form: {
        orgName: ''
      },
      titleName: '',
      dialogVisible: false,
      tableData: [],
      rules: {
        orgName: [{
          required: true,
          message: '必填项不可为空',
          trigger: 'blur'
        }]
      }
    }
  },
  created () {
    if (!this.auth.org_normal_query) {
      return
    }
    this.inline()
  },
  methods: {
    sizeChange (val) {
      this.pageSize = val
      this.inline()
    },
    currentChange (val) {
      this.pageNo = val
      this.inline()
    },
    // 初始化页面
    async inline () {
      const res = await api.organizationChart_getPageList({
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }, this.search)
      if (res.code === 200) {
        this.pageTotal = res.body.sumDataCount
        this.pageSize = res.body.pageSize
        this.tableData = res.body.datas.map((e) => {
          e.hasChildren = true
          e.orgId = e.orgId.toString()
          return e
        })
      }
    },
    searchBtn () {
      this.inline()
    },
    resetBtn () {
      this.search.orgName = ''
      this.inline()
      this.toggleRowExpansion_forAll()
    },
    // 新增
    add () {
      this.titleName = '新增机构'
      this.dialogVisible = true
    },
    // 新增部门
    addSubclass (obj) {
      this.titleName = '新增部门'
      this.dialogVisible = true
      this.orgId = obj.orgId
      this.PName = obj.orgName
      this.obj = obj
      this.orgPid = obj.orgId
    },
    // 编辑
    compile (obj) {
      this.titleName = '编辑'
      this.orgPid = obj.orgPid
      this.dialogVisible = true
      this.orgId = obj.orgId
      this.form.orgName = obj.orgName
      this.tableData.forEach((e) => {
        if (e.orgId.toString() === obj.orgPid.toString()) {
          this.PName = e.orgName
        }
      })
    },
    // 删除
    deletes (obj) {
      this.titleName = '删除'
      this.dialogVisible = true
      this.orgId = obj.orgId
      this.orgPid = obj.orgPid
    },
    // 确定
    async save (name) {
      if (!/^(?!\s+)/.test(this.form.orgName)) {
        this.$message.error('机构名称的开头不能有空格')
        return
      }
      if (name === '新增机构') {
        if (this.form.orgName === '') {
          this.$message.error('不可为空')
          return
        }
        const res = await api.addCompanyOrDept({ orgName: this.form.orgName })
        if (res.code === 200) {
          this.$message({
            message: '新增成功',
            type: 'success'
          })
          this.dialogVisible = false
        }
      } else if (name === '新增部门') {
        if (this.form.orgName === '') {
          this.$message.error('不可为空')
          return
        }
        const res = await api.addCompanyOrDept({
          orgName: this.form.orgName,
          orgPid: this.orgId
        })
        if (res.code === 200) {
          this.$message({
            message: '新增成功',
            type: 'success'
          })
          this.dialogVisible = false
        }
      } else if (name === '编辑') {
        const res = await api.updCompanyOrDept({
          orgName: this.form.orgName,
          orgId: this.orgId
        })
        if (res.code === 200) {
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.dialogVisible = false
        }
      } else if (name === '删除') {
        const res = await api.delCompanyOrDept({ orgId: this.orgId })
        if (res.code === 200) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.dialogVisible = false
        }
      }
      this.inline()
      this.updateMenu()
      this.dialogVisible = false
    },
    closeLog () {
      this.PName = ''
      this.form.orgName = ''
    },
    // 更新子菜单
    updateMenu () {
      const {
        tree,
        treeNode,
        resolve
      } = this.objMap.get(this.orgPid)
      this.$set(this.$refs.table.store.states.lazyTreeNodeMap, this.orgPid, [])
      this.load(tree, treeNode, resolve)
    },
    // 收回所有
    toggleRowExpansion_forAll () {
      this.tableData.forEach(item => {
        this.$refs.table.toggleRowExpansion(item, false)
        if (item.children !== undefined && item.children != null) {
          this.toggleRowExpansion_forAll(item.children, false)
        }
      })
    },

    // 点击的时候在给相应的子集
    async load (tree, treeNode, resolve) {
      this.objMap.set(tree.orgId, {
        tree,
        treeNode,
        resolve
      })
      const res = await api.getDeptList({ orgPid: tree.orgId })
      res.body = res.body.map((e) => {
        e.orgId = e.orgId.toString()
        return e
      })
      if (resolve) {
        resolve(res.body)
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.search {
  width: 100%;
  display: inline-block;
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.btn {
  margin-right: 20px;
}
</style>
