var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setGoodsList" },
    [
      _c(
        "el-drawer",
        {
          ref: "drawer",
          attrs: {
            title: "请选择商品",
            size: "70%",
            "append-to-body": true,
            visible: _vm.drawerVisible,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.drawerVisible = $event
            }
          }
        },
        [
          _c("div", { staticStyle: { width: "100%" } }, [
            _c("div", { staticClass: "search" }, [
              _c(
                "div",
                { staticClass: "search_one" },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("商品编码")]),
                  _c("el-input", {
                    staticClass: "filter-item",
                    attrs: {
                      disabled: _vm.permission.isSearch,
                      placeholder: "请输入商品编码",
                      clearable: "",
                      size: "small"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.initList($event)
                      }
                    },
                    model: {
                      value: _vm.search.goodsCoding,
                      callback: function($$v) {
                        _vm.$set(_vm.search, "goodsCoding", $$v)
                      },
                      expression: "search.goodsCoding"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "search_one" },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("商品名称")]),
                  _c("el-input", {
                    staticClass: "filter-item",
                    attrs: {
                      disabled: _vm.permission.isSearch,
                      placeholder: "请输入商品",
                      clearable: "",
                      size: "small"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.initList($event)
                      }
                    },
                    model: {
                      value: _vm.search.goodsName,
                      callback: function($$v) {
                        _vm.$set(_vm.search, "goodsName", $$v)
                      },
                      expression: "search.goodsName"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "search_one" },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("一级分类")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        disabled: _vm.permission.isSearch,
                        placeholder: "请选择一级分类",
                        size: "small",
                        clearable: ""
                      },
                      model: {
                        value: _vm.search.goodsOne,
                        callback: function($$v) {
                          _vm.$set(_vm.search, "goodsOne", $$v)
                        },
                        expression: "search.goodsOne"
                      }
                    },
                    _vm._l(_vm.oneClassData, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.classifyName,
                          value: item.id,
                          title: _vm.onHoverText
                        },
                        nativeOn: {
                          mouseenter: function($event) {
                            return _vm.onMouseOver(item.classifyName)
                          }
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "search_one" },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("商品品牌")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        disabled: _vm.permission.isSearch,
                        placeholder: "请选择商品品牌",
                        size: "small",
                        clearable: ""
                      },
                      model: {
                        value: _vm.search.goodsBrand,
                        callback: function($$v) {
                          _vm.$set(_vm.search, "goodsBrand", $$v)
                        },
                        expression: "search.goodsBrand"
                      }
                    },
                    _vm._l(_vm.goodsBrandData, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        staticStyle: { width: "200px" },
                        attrs: {
                          label: item.brandName,
                          value: item.id,
                          title: _vm.onHoverText
                        },
                        nativeOn: {
                          mouseenter: function($event) {
                            return _vm.onMouseOver(item.brandName)
                          }
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "search_one" },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("商品IP")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        disabled: _vm.permission.isSearch,
                        placeholder: "请选择商品IP",
                        size: "small",
                        clearable: ""
                      },
                      model: {
                        value: _vm.search.goodsIp,
                        callback: function($$v) {
                          _vm.$set(_vm.search, "goodsIp", $$v)
                        },
                        expression: "search.goodsIp"
                      }
                    },
                    _vm._l(_vm.goodsIpData, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        staticStyle: { width: "200px" },
                        attrs: {
                          label: item.ipName,
                          value: item.id,
                          title: _vm.onHoverText
                        },
                        nativeOn: {
                          mouseenter: function($event) {
                            return _vm.onMouseOver(item.ipName)
                          }
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        size: "small",
                        disabled: _vm.permission.isSearch
                      },
                      on: {
                        click: function($event) {
                          return _vm.toQuery()
                        }
                      }
                    },
                    [_vm._v(" 搜索 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh",
                        type: "warning",
                        size: "small",
                        disabled: _vm.permission.isReset
                      },
                      on: {
                        click: function($event) {
                          return _vm.reset()
                        }
                      }
                    },
                    [_vm._v(" 重置 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        type: "success",
                        size: "small",
                        disabled: _vm.permission.isReset
                      },
                      on: {
                        click: function($event) {
                          return _vm.addGoods()
                        }
                      }
                    },
                    [_vm._v("添加")]
                  )
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "10px",
                "margin-left": "10px",
                "margin-right": "10px"
              }
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingTotal,
                      expression: "loadingTotal"
                    }
                  ],
                  ref: "tableData",
                  attrs: {
                    data: _vm.tableData,
                    "element-loading-text": "数据加载中",
                    "cell-style": { textAlign: "center" },
                    "tooltip-effect": "dark",
                    "max-height": "660",
                    "row-style": { height: "57px" },
                    "header-cell-style": {
                      background: "#dfe6ec",
                      color: "#666666",
                      textAlign: "center"
                    }
                  },
                  on: { "selection-change": _vm.selectionChange }
                },
                [
                  (_vm.type
                  ? _vm.type === "checkButton"
                  : false)
                    ? _c("el-table-column", {
                        attrs: { align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.checkSelect(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v(" 选择 ")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3598867742
                        )
                      })
                    : _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          label: "选择",
                          width: "55",
                          align: "center"
                        }
                      }),
                  _c("el-table-column", {
                    attrs: { label: "序号", width: "80", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (_vm.search.pageNo - 1) *
                                    _vm.search.pageSize +
                                    scope.$index +
                                    1
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品编码",
                      prop: "goodsCoding",
                      align: "center",
                      width: "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  width: "100%"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "white-space": "nowrap",
                                      width: "80%"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(scope.row.goodsCoding) + " "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "copySty",
                                    on: {
                                      click: function($event) {
                                        return _vm.copyUrl(
                                          scope.row.goodsCoding
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" 复制 ")]
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "商品原ID",
                      prop: "goodsId"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品名称",
                      prop: "goodsName",
                      align: "center",
                      width: "300"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis"
                                }
                              },
                              [_vm._v(" " + _vm._s(scope.row.goodsName) + " ")]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品图片",
                      prop: "pictureUrl",
                      align: "center",
                      width: "100"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-image", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "margin-top": "8px",
                                cursor: "pointer"
                              },
                              attrs: {
                                src:
                                  _vm.downloadUrl + "/" + scope.row.pictureUrl,
                                alt: "",
                                "preview-src-list": [
                                  _vm.downloadUrl + "/" + scope.row.pictureUrl
                                ]
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: " 一级分类",
                      prop: "goodsOne",
                      align: "center",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.goodsOne === null
                                        ? "- -"
                                        : scope.row.goodsOne
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品品牌",
                      prop: "goodsBrandName",
                      align: "center",
                      width: "260"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(scope.row.goodsBrandName) + " "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品IP",
                      prop: "goodsIpName",
                      align: "center",
                      width: "160"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(scope.row.goodsIpName) + " "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "margin-top": "10px"
              }
            },
            [
              (_vm.type
              ? _vm.type !== "checkButton"
              : false)
                ? _c(
                    "el-button",
                    {
                      staticClass: "submit",
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(" 确认 ")]
                  )
                : _vm._e(),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.search.pageNo,
                  "page-size": _vm.search.pageSize,
                  "page-sizes": [10, 20, 30, 40, 50, 100],
                  layout: "total, jumper, prev, pager, next,sizes",
                  total: _vm.search.pageTotal
                },
                on: {
                  "size-change": _vm.sizeChange,
                  "current-change": _vm.currentChange
                }
              })
            ],
            1
          ),
          _c("add", {
            ref: "addRef",
            on: {
              func: function($event) {
                return _vm.addSuccess()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }