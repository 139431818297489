<template>
  <!-- 如果内容比较多可以添加top属性 -->
  <el-dialog :close-on-click-modal="false" :append-to-body="true" top="10" :title="title" :visible.sync="dialogVisible"
    :before-close="handleClose">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="角色权限" name="0">
        <el-form ref="form" status-icon :model="form" :rules="rules" label-width="80px">
          <el-form-item label="角色名称" prop="name" style="width:90%;">
            <el-input size="small" clearable :disabled="disabled" @keyup.enter.native="save()" class="inputText"
              v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark" style="width:90%;">
            <el-input size="small" clearable v-if="!disabled" @keyup.enter.native="save()" class="inputText"
              v-model="form.remark"></el-input>
            <span v-else>{{form.remark}}</span>
          </el-form-item>
          <el-form-item label="app端登录" prop="appFlag" style="width:90%;">
            <el-switch :disabled="title=='详情'" v-model="form.appFlag" :active-value="1" :inactive-value="0"></el-switch>
          </el-form-item>
          <el-form-item label="权限分配" prop="roleMenus">
            <el-tree :render-content="renderContent" ref="tree" default-expand-all :data="menuList" show-checkbox
              :default-checked-keys="defaultCheckList" @check="selectMenu" node-key="id">
            </el-tree>
          </el-form-item>
        </el-form>
        <div class="bottomDiv">
          <el-button size="small" @click="handleClose">关闭</el-button>
          <el-button size="small" :disabled="disabled" type="primary" @click="save">保存</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="角色用户" name="1" v-if="title === '详情'">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" type="index" label="序号">
          </el-table-column>
          <el-table-column align="center" prop="userName" label="用户名">
          </el-table-column>
          <el-table-column align="center" prop="name" label="姓名">
          </el-table-column>
          <el-table-column align="center" prop="orgName" label="所属公司">
          </el-table-column>
          <el-table-column align="center" prop="deptName" label="所属部门">
          </el-table-column>
          <el-table-column align="center" prop="createdName" label="创建人">
          </el-table-column>
          <el-table-column align="center" prop="createdTimestamp" label="创建时间" width="250px">
          </el-table-column>
        </el-table>
        <div class="pager-div">
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import api from '@/api'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  name: 'RoleEdit',
  data () {
    return {
      select: {
        pageNo: 1,
        pageSize: 50
      },
      pageTotal: 0,
      roleId: '',
      detail: false,
      tableData: [],
      activeName: '0',
      defaultCheckList: [21],
      selectMenuList: [],
      menuList: [],
      title: '',
      form: {
        name: '',
        id: null,
        roleMenus: [],
        remark: '',
        appFlag: 0
      },
      rules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { pattern: /^(?!\s+)/, message: '第一个字符或所有字符不能为空格', trigger: 'blur' }
        ],
        roleMenus: [
          { required: true, message: '请选择权限', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async handleClick () {
      if (this.activeName === '1') {
        const { body } = await api.GET_USER_ROLES({ roleId: this.roleId })
        this.tableData = body
      }
    },
    // 修改节点Dom
    renderContent (h, { node, data }) {
      let className = ''
      // eslint-disable-next-line no-mixed-operators
      if (data.type && data.type === 2) {
        console.log(data.type)
        className = 'especially'
      }
      return (<div class={className}>{data.label}</div>)
    },
    // 改变tree节点样式
    changeTreeClass () {
      // 找到之前做标记的class
      const classDomList = document.getElementsByClassName('especially')
      // 改变这几个样式
      for (let i = 0; i < classDomList.length; i++) {
        classDomList[i].parentNode.style.cssText = 'float: left'
        classDomList[i].parentNode.className = 'el-tree-node__content option-wrapper'
        classDomList[i].parentNode.parentNode.parentNode.style.marginLeft = '70px'
      }
    },
    selectMenu (data, data1) {
      this.selectMenuList = data1.checkedNodes
      var par = []
      this.selectMenuList.forEach(res => {
        var parVo = {
          menuId: res.id
        }
        if (res.layer !== 1) {
          par.push(parVo)
        }
      })
      this.form.roleMenus = par
    },
    setTitle (title) {
      this.title = title
    },
    initData () {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields()
      }
      this.form.name = null
      this.form.id = null
      this.form.roleMenus = []
      this.initMenusData()
    },
    setData (data) {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields()
      }
      this.form.name = data.name
      this.roleId = data.id
      this.form.id = data.id
      this.form.roleMenus = data.roleMenus
      this.form.remark = data.remark
      this.form.appFlag = data.appFlag
      this.initMenusData()
    },
    async updateApi () {
      await api.UPDATE_ROLE(this.form)
      this.$emit('initTableData')
      this.$message.success('操作成功')
      this.handleClose()
    },
    async saveApi () {
      await api.SAVE_ROLE(this.form)
      this.$emit('initTableData')
      this.$message.success('操作成功')
      this.handleClose()
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id != null) {
            this.$confirm('此操作将永久修改该数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.updateApi()
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消修改'
              })
            })
          } else {
            this.saveApi()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleClose (done) {
      this.activeName = '0'
      this.$emit('close')
    },
    recursionMenus (menus) {
      var childrens = []
      for (var i = 0; i < menus.children.length; i++) {
        var menu = menus.children[i]
        var children = this.recursionMenus(menu)
        var menuJson = {
          id: menu.menuId,
          label: menu.title,
          type: menu.type,
          layer: menu.layer
        }
        if (children.length > 0) {
          menuJson.children = children
        }
        if (this.disabled) {
          menuJson.disabled = this.disabled
        }
        childrens.push(menuJson)
      }
      return childrens
    },
    async initMenusData () {
      var par = {}
      const res = await api.GET_MENU_LIST(par)
      var menus = []
      for (var i = 0; i < res.body.length; i++) {
        var menu = res.body[i]
        var children = this.recursionMenus(menu)
        var menuJson = {
          id: menu.menuId,
          label: menu.title,
          type: menu.type,
          layer: menu.layer
        }
        if (children.length > 0) {
          menuJson.children = children
        }
        if (this.disabled) {
          menuJson.disabled = this.disabled
        }
        menus.push(menuJson)
      }
      this.menuList = menus
      var arr = []
      for (var k = 0; k < this.form.roleMenus.length; k++) {
        var menuSelect = this.form.roleMenus[k]
        arr.push(menuSelect.menuId)
      }
      this.defaultCheckList = arr
      Promise.resolve().then(() => this.changeTreeClass())
    }
  }
}
</script>

<style>
.inputText {
  width: 70%;
}

.bottomDiv {
  width: 100%;
  text-align: center;
}

.option-wrapper {
  padding: 0 !important;
}
</style>
