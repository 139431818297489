export default ({ request }) => ({
    // 获取出库报表
    getDeliveryDetailsList(data = {}) {
        return request({
            url: '/newStatical/getOutList',
            method: 'post',
            data: data
        })
    },
    // 获取出库统计
    getDeliveryDetailsCount(data = {}) {
        return request({
            url:'/newStatical/getNewStateRes',
            method: 'post',
            data: data
        })
    },
    

})