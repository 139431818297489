export default (function (_ref) {
  var request = _ref.request;
  return {
    // 分页查询
    getPageList_audit: function getPageList_audit() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/audit/auditPage',
        method: 'post',
        data: data
      });
    }
  };
});