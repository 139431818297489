import api from '@/api'
// 获取用户
export async function getUser() {
    const { body: data } = await api.statisticalUser()
    return data
}
// 获取订单来源
export async function getOrderSource() {
    const { body: data } = await api.enumeration_getList({ name: 'out_source' })
    return data
}
// 查询公司
export async function getOrg() {
    const { body: data } = await api.getorgselect()
    return data
}
// 获得部门列表
export async function getPart(id) {
    const res = await api.getDeptList({ orgPid: id })
    return res.body
}
// 获取仓库列表
export async function getWarehouse() {
    const { body: data } = await api.getPurchaseWarehouse()
    return data
}
// 获取区域
export async function getArea(id) {
    const res = await api.departmentGetChooseArea({
        warehouseId: id
    })
    return res.body
}
// 获取货架
export async function getShelves(id) {
    const res = await api.departmentGetChooseShelves({
        areaId: id
    })
    return res.body
}

