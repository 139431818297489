<template>
  <div class="productExpiration">
    <div class="dashboard-editor-container">
      <div id="totalWH" class="whSty">
        <div>
          <div class="tabSty">
            <div class="singSty">
              <div class="verticalBar"></div>
              <span class="textSty">商品到期提醒</span>
            </div>
            <div style="display: flex;align-items: center;">
              <div>
                <div style="margin-right: 10px;">
                  商品名称
                  <el-input v-model="search.goodsName" clearable size="small" style="width: 200px;" @input="toQuery"/>
                </div>
              </div>
              入库
              <el-input-number v-model="search.days" clearable size="small" @change="toQuery"/>
              天以上
              <el-button plain size="small" style="margin-left: 20px;" type="success" @click="getExcel()">导出
              </el-button>
            </div>
          </div>
        </div>
        <div class="whSty">
          <el-table ref="table" v-loading="loading" :cell-style="{ textAlign: 'center' }"
                    :data="tableData"
                    :header-cell-style="headerCellStyle" :row-style="{ height: '57px', textAlign: 'center' }"
                    element-loading-text="数据加载中"
                    max-height="550px" style="width: 100%;" tooltip-effect="dark">
            <el-table-column align="center" label="序号" type="index" width="80px">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="商品编码" prop="goodsCoding" width="100">
            </el-table-column>
            <el-table-column align="center" label="商品条码" prop="barCode" width="100">
            </el-table-column>
            <el-table-column align="center" label="商品名称" prop="goodsName" width="100">
            </el-table-column>
            <el-table-column align="center" label="入库价" prop="intoPrice" width="120">
            </el-table-column>
            <el-table-column align="center" label="入库单号" prop="intoNo" width="120">
            </el-table-column>
            <el-table-column align="center" label="入库时间" prop="createdTimestamp" width="120">
            </el-table-column>
            <el-table-column align="center" label="公司名称" prop="orgName" width="120">
            </el-table-column>
            <el-table-column align="center" label="仓库名称" prop="warehouseName" width="120">
            </el-table-column>
            <el-table-column align="center" label="区域名称" prop="areaName" width="120">
            </el-table-column>
          </el-table>
          <el-pagination v-if="this.search.pageSize > 0" :page-size="search.pageSize"
                         :page-sizes="[10, 20, 30, 40, 50, 100]"
                         :total="search.pageTotal" layout="total, jumper, prev, pager, next,sizes"
                         style="margin-top: 10px;width: 100%;text-align: right;"
                         @size-change="handleSizeChange" @current-change="handleCurrentChange"
                         @current-page="search.pageNo">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api'
import { downloadFileByUrl } from '@/utils/index'
export default {
  name: 'productExpiration',
  data () {
    return {
      headerCellStyle: {
        color: '#666666',
        background: '#dfe6ec'
      },
      tableData: [],
      loading: false,
      search: {
        pageNo: 1,
        pageSize: 10,
        pageTotal: 0,
        goodsName: '',
        days: 100
      },
      searchExcel: {
        pageNo: 1,
        pageSize: 10,
        pageTotal: 0,
        goodsName: '',
        days: 100
      }
    }
  },
  created () {
    this.initList()
  },
  methods: {
    toQuery () {
      this.search.pageNo = 1
      this.search.pageSize = 10
      this.initList()
    },
    async initList () {
      const { body } = await api.getproductExpiration(this.search)
      this.tableData = body.datas
      this.search.pageTotal = body.sumDataCount
      this.searchExcel = JSON.parse(JSON.stringify(this.search))
    },
    async getExcel () {
      const data = await api.getproductExpirationExcel(this.searchExcel)
      downloadFileByUrl(data.body)
    },
    // 搜索分页
    handleSizeChange (val) {
      this.search.pageSize = val
      this.initList()
    },
    // 搜索跳转页数
    handleCurrentChange (val) {
      this.search.pageNo = val
      this.initList()
    }
  }
}
</script>
<style scoped>
.dashboard-editor-container {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0px 0px 4px #999999;
  border-radius: 5px;
}

.whSty {
  width: 100%;
  height: 640px;
}

.tabSty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.singSty {
  display: flex;
  height: 20px;
  padding-left: 10px;
}

.verticalBar {
  width: 10px;
  line-height: 20px;
  border-radius: 25%;
  background-color: #8ddece;
  margin-right: 5px;
}

.textSty {
  line-height: 20px;
  font-weight: bold;
}
</style>
