<template>
  <d2-container>
    <div>
      <div>
        <div v-if="!permission.isWarehouse">
          <div class="headline">
            <span style="margin-left:10px">仓库详情</span>
          </div>
          <div
            style="display:flex;justify-content: space-around;line-height: 50px;font-size: 20px;"
          >
            <div class="headerData" :title="this.warehouseName">
              仓库名称：
              <span style="font-weight: bold;">{{ this.warehouseName }}</span>
            </div>
            <div class="headerData" :title="this.warehouseAddress">
              仓库地址：
              <span style="font-weight: bold;">{{ this.warehouseAddress }}</span>
            </div>
            <div class="headerData" :title="this.areaNum">
              区域数量：
              <span style="font-weight: bold;">{{ this.areaNum }}</span>
            </div>
            <div class="headerData" :title="this.shelvesNum">
              货架数量：
              <span style="font-weight: bold;">{{ this.shelvesNum }}</span>
            </div>
            <div class="headerData"  style="font-weight: bold;":title="this.layerNum">
              层数总计：
              <span style="font-weight: bold;">{{ this.layerNum == null ? 0 : this.layerNum }}</span>
            </div>
          </div>
        </div>
      </div>
      <div style="position:absolute;right:25px;margin-top: 5px;">
        <el-button
          type="success"
          @click="addArea"
          v-if="!permission.isAddArea"
          size="small"
          icon="el-icon-plus"
        >
          新增区域
        </el-button>
      </div>
      <div>
        <div class="headline">
          <span style="margin-left:10px">区域列表</span>
        </div>
        <el-table
          :data="particularsTableData"
          style="width: 100%;margin-top: 20px;"
          :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center'
        }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column prop="date" label="序号" width="180">
            <template slot-scope="scope">
              {{ (search2.currpage - 1) * search2.pagesize + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            prop="areaName"
            label="区域名称"
            width="180"
          ></el-table-column>
          <el-table-column prop="shelvesNum" label="货架数量"></el-table-column>
          <el-table-column prop="layerNum" label="层数总计">
            <template slot-scope="scope">
              {{ scope.row.layerNum == null ? 0 : scope.row.layerNum }}
            </template>
          </el-table-column>
          <el-table-column prop="all" label="操作" fixed="right" width="350">
            <template slot-scope="scope">
              <el-button
                type="success"
                @click="editorArea(scope.$index)"
                v-if="!permission.isEditorArea"
                size="small"
                plain
                icon="el-icon-edit-outline"
              >
                编辑区域
              </el-button>
              <el-button
                type="info"
                @click="areaParticulars(scope.$index)"
                v-if="!permission.isEditorShelves"
                size="small"
                plain
                icon="el-icon-info"
              >
                区域详情
              </el-button>
              <el-button
                type="danger"
                @click="areaDelete(scope.$index)"
                v-if="!permission.isDeleteArea"
                :disabled="areaDeleteLoading"
                :loading="areaDeleteLoading"
                size="small"
                plain
                icon="el-icon-delete"
              >
                删除区域
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="block" v-if="search2.currentPage !== 0">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="search2.currpage"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="search2.pagesize"
            layout="total, jumper, prev, pager, next,sizes"
            :total="search2.currentPage"
          ></el-pagination>
        </div>
      </div>
      <el-dialog
        :append-to-body="true"
        :title="areaTitle"
        :visible.sync="dialogVisible2"
        width="400px"
        :close-on-click-modal="false"
        @close="WarehouseDialog1"
      >
        <el-form
          label-position="right"
          label-width="80px"
          :model="form2"
          :rules="rules1"
          ref="ruleForm1"
        >
          <el-form-item label="区域名称" prop="areaName">
            <el-input
              v-model="form2.areaName"
              style="width:200px"
              size="small"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="areaCancel()" size="small">取 消</el-button>
          <el-button type="primary" :disabled="areaDetermineLoading" :loading="areaDetermineLoading" @click="areaDetermine" size="small">
            确 定
          </el-button>
        </span>
      </el-dialog>
    </div>
  </d2-container>
</template>
<script>
import api from '@/api'
import store from '@/store'
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export default {
  data () {
    return {
      sumDataCount: '',
      areaTitle: '',
      permission: {
        isAddArea: this.checkauthority('area_new'), // 新增区域   V
        isEditorArea: this.checkauthority('update_area'), // 修改区域   X
        isEditorShelves: this.checkauthority('area_detail'), // 区域详情
        isDeleteArea: this.checkauthority('delete_area'), // 删除区域V
        isWarehouse: this.checkauthority('warehouse_select') // 仓库详情
      },
      // 区域详情分页
      search2: {
        currentPage: 1, // 总共有多少条
        pagesize: 50, // 每页的数据条数
        currpage: 1 // 默认开始页面
      },
      particularsTableData: [],
      id: '',
      pageNo: '',
      pageSize: '',
      warehouseName: '',
      warehouseAddress: '',
      areaNum: '',
      shelvesNum: '',
      layerNum: '',
      dialogVisible2: false,
      areaDetermineLoading: false,
      areaDeleteLoading: false,
      // 新增区域弹窗的绑定值
      form2: {
        id: '',
        areaName: ''
      },
      rules1: {
        // 新增区域弹窗
        // id: [
        //   { required: true, message: '请选择仓库', trigger: 'change' }
        // ],
        // 新增区域名称
        areaName: [
          { required: true, message: '请输入区域名称', trigger: 'blur' },
          {
            pattern: /^(?!\s+)/,
            message: '第一个字符或所有字符不能为空格',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  watch: {
    $route (to, from) {
      this.inline()
    }
  },
  created () {
    this.inline()
  },
  methods: {
    async inline () {
      const json = JSON.parse(
        localStorage.getItem(this.$route.query.warehouseId)
      )
      this.id = this.$route.query.warehouseId
      this.pageNo = json.pageNo
      this.pageSize = json.pageSize
      if (this.pageNo !== '' && this.pageSize !== '' && this.id !== '') {
        this.getParams()
      }
      const res1 = await api.warehouseStructure_getPageList({
        warehouseId: this.id,
        pageNo: 1,
        pageSize: 1
      })
      this.warehouseName = res1.body.datas[0].warehouseName
      this.warehouseAddress = res1.body.datas[0].warehouseAddress
      this.areaNum = res1.body.datas[0].areaNum
      this.shelvesNum = res1.body.datas[0].shelvesNum
      this.layerNum = res1.body.datas[0].layerNum
    },
    async getParams () {
      const res = await api.warehouseStructure_warehouseDetail({
        pageNo: this.search2.currpage,
        pageSize: this.search2.pagesize,
        warehouseId: this.id
      })
      this.particularsTableData = res.body.datas
      this.search2.currpage = res.body.pageNo
      this.search2.pagesize = res.body.pageSize
      this.search2.currentPage = res.body.sumDataCount
    },
    // 新增区域
    addArea () {
      this.dialogVisible2 = true
      this.areaTitle = '新增区域'
    },
    // 编辑区域
    editorArea (index) {
      this.index2 = index
      this.dialogVisible2 = true
      this.areaId = this.particularsTableData[index].areaId
      this.form2.areaName = this.particularsTableData[index].areaName
      this.areaTitle = '编辑区域'
      this.areaVersion = this.particularsTableData[index].version
    },
    // 区域详情
    areaParticulars (index) {
      var newRes = {
        path: '/shelvesDetails' + this.particularsTableData[index].areaId,
        name: this.particularsTableData[index].areaName,
        component: _import('store/shelvesDetails/index'),
        meta: {
          title: this.particularsTableData[index].areaName
        }
      }
      var routes = this.$router.options.routes[0]
      routes.children.push(newRes)
      this.$router.addRoutes(this.$router.options.routes)
      store.commit('d2admin/page/init', this.$router.options.routes)

      var par = {
        areaName: this.particularsTableData[index].areaName,
        warehouseId: this.id,
        pageNo: this.search2.currpage,
        pageSize: this.search2.pagesize
      }
      localStorage.setItem(
        this.particularsTableData[index].areaId,
        JSON.stringify(par)
      )
      this.$router.push({
        path: '/shelvesDetails' + this.particularsTableData[index].areaId,
        query: {
          areaId: this.particularsTableData[index].areaId
        }
      })
    },
    // 删除区域
    async areaDelete (index) {
      try {
        this.areaDeleteLoading = true
        if (this.particularsTableData.length === 1 && this.search2.currpage !== 1) {
          this.search2.currpage = this.search2.currpage - 1
        }
        const res = await api.warehouseStructure_deleteArea({
          areaId: this.particularsTableData[index].areaId,
          version: this.particularsTableData[index].version
        })
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除区域成功!'
          })
          this.inline()
        }
      } catch (error) {
        this.$message({
          type: 'warning',
          message: '删除区域失败!'
        })
      } finally {
        this.areaDeleteLoading = false
      }
    },
    handleSizeChange (val) {
      this.search2.pagesize = val
      this.inline()
    },
    handleCurrentChange (val) {
      this.search2.currpage = val
      this.inline()
    },
    WarehouseDialog1 () {
      this.dialogVisible2 = false
      this.$refs.ruleForm1.resetFields()
      this.form2.areaName = ''
    },
    // 新增区域的取消
    areaCancel () {
      this.WarehouseDialog1()
    },
    // 新增/编辑区域的确定
    areaDetermine () {
      this.$refs.ruleForm1.validate(async valid => {
        if (valid) {
          try {
            this.areaDetermineLoading = true
            if (this.areaTitle === '新增区域') {
              const res = await api.warehouseStructure_newArea({
                areaName: this.form2.areaName,
                warehouseId: this.id
              })
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '新增区域成功!'
                })
                this.WarehouseDialog1()
                this.inline()
              }
            } else if (this.areaTitle === '编辑区域') {
              const res = await api.warehouseStructure_updateArea({
                areaName: this.form2.areaName,
                areaId: this.areaId,
                version: this.areaVersion
              })
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '修改区域成功!'
                })
                this.WarehouseDialog1()
                this.inline()
              }
            }
          } catch (error) {
            this.$message({
              type: 'warning',
              message: '操作失败!'
            })
          } finally {
            this.areaDetermineLoading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.head-container {
  .el-row[style='margin-left: -10px; margin-right: -10px; height: 37px;'] {
    position: absolute;
    top: 100px;
    width: 300px;
    right: -140px;
    z-index: 10;
    display: flex;
  }
}

::v-deep .elFormX {
  .el-form-item__content {
    margin-left: 0px !important;
  }
}

.headline {
  width: 100%;
  font-size: 20px;
  line-height: 40px;
  background-color: #a3a3a3;
  color: #fff;
}

::v-deep .el-form-item__error {
  position: absolute;
  bottom: 10px;
}

.paging {
  height: 40px;
}

.el-pagination {
  position: absolute;
  right: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: space-around;
  padding: 0 100px;
}

.block {
  margin-top: 10px;
}

.headerData {
  font-weight: 700;
  width: 19%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  span {
    font-weight: 100;
  }
}
</style>
