export default ({ request }) => ({
    // 商品出入库汇总
    getCommoditySummaryStatementList(data = {}) {
        return request({
            url: '/newStatical/getInvResult',
            method: 'post',
            data: data
        })
    },
    // 数据统计
    getCommoditySummaryStatementCount(data = {}) {
        return request({
            url: '/newStatical/getInvAggregate',
            method: 'post',
            data: data
        })
    },
})
