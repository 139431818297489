<template>
    <d2-container>
      <div>
    <div>
      <div class="headline"><span style="margin-left:10px">区域详情</span></div>
      <div style="display:flex;justify-content: space-around;line-height: 50px;font-size: 20px;">
        <div class="headerData" :title="this.areaName">区域名称：<span style="font-weight: bold;">{{this.areaName}}</span></div>
        <div class="headerData" :title="this.shelvesNum">货架数量：<span style="font-weight: bold;">{{this.shelvesNum}}</span></div>
        <div class="headerData" :title="this.layerNum">层数总计：<span style="font-weight: bold;">{{this.layerNum==null?0:this.layerNum}}</span></div>
      </div>
    </div>
    <div style="position:absolute;right:25px;margin-top: 5px;">
      <el-button type="success" @click="addShelves" v-if="!permission.isAddShelves" size="small"
        icon="el-icon-plus">新增货架</el-button>
    </div>
    <div>
      <div class="headline"><span style="margin-left:10px">货架列表</span></div>
      <el-table :data="shelvesTabledata" style="width: 100%;margin-top: 20px;"
        :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center'
        }"
        :cell-style="{textAlign:'center'}">
        <el-table-column prop="date" label="序号" width="180">
          <template slot-scope="scope">
            {{scope.$index+1}}
          </template>
        </el-table-column>
        <el-table-column prop="shelvesName" label="货架名称" width="180">
        </el-table-column>
        <el-table-column prop="shelvesNum" label="货架层数">
        </el-table-column>
        <el-table-column prop="all" label="操作" fixed="right" width="300">
          <template slot-scope="scope">
            <el-button type="success" @click="editorShelves(scope.$index)"
              v-if="!permission.Shelves" size="small" plain icon="el-icon-edit-outline">编辑货架</el-button>
            <el-button type="danger" @click="shelvesDelete(scope.$index)"
                  v-if="!permission.isDeleteShelves" size="small" plain icon="el-icon-delete">删除货架</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="search2.currentPage!==0">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="search2.currpage" :page-sizes="[10,20,30,40,50,100]" :page-size="search2.pagesize"
          layout="total, jumper, prev, pager, next,sizes" :total="search2.currentPage">
        </el-pagination>
      </div>
    </div>
    <el-dialog :append-to-body="true" :title="shelvesTitle" :visible.sync="dialogVisible4" width="400px" :close-on-click-modal="false"
      @close="WarehouseDialog3">
      <el-form label-position="right" label-width="80px" :model="form3" :rules="rules2" ref="ruleForm2" class="elFormX">
        <el-form-item label="货架名称" prop="shelvesName3">
          <el-input v-model="form3.shelvesName3" placeholder="" style="width:200px" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="货架层数" prop="shelvesLayer3">
          <el-input v-model="form3.shelvesLayer3" placeholder="" style="width:200px" size="small" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible4 = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveShelves(dialogTitle1)" size="small">保 存</el-button>
      </span>
    </el-dialog>
  </div>
    </d2-container>

</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      warehouseId: '',
      sumDataCount: '',
      index: '',
      form3: {
        // id: '',
        // areaId: '',
        shelvesName3: '',
        shelvesLayer3: ''
      },
      shelvesTitle: '',
      dialogVisible4: false,
      areaId: '',
      areaName: '',
      layerNum: '',
      shelvesNum: '',
      pageNo: '',
      pageSize: '',
      permission: {
        Shelves: this.checkauthority('update_shelves'), // 修改货架
        isAddShelves: this.checkauthority('shelves_new'), // 新增货架
        isDeleteShelves: this.checkauthority('delete_shelves')// 删除货架
      },
      shelvesTabledata: [],
      // 货架详情分页
      search2: {
        currentPage: 1, // 总共有多少条
        pagesize: 50, // 每页的数据条数
        currpage: 1// 默认开始页面
      },
      // 新增货架
      rules2: {
        shelvesName3: [
          { required: true, message: '请输入货架名称', trigger: 'blur' },
          { pattern: /^(?!\s+)/, message: '第一个字符或所有字符不能为空格', trigger: 'blur' }
        ],
        shelvesLayer3: [
          { required: true, message: '请输入货架层数', trigger: 'blur' },
          { pattern: /^(?!\s+)/, message: '第一个字符或所有字符不能为空格', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (/^(?:[1-9]\d*)$/.test(value) === false) {
                callback(new Error('请输入正整数'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },

  watch: {
    '$route' (to, from) {
      this.inline()
    }
  },
  created () {
    this.inline()
  },
  methods: {
    async inline () {
      const json = JSON.parse(localStorage.getItem(this.$route.query.areaId))
      this.pageNo = json.pageNo
      this.pageSize = json.pageSize
      this.areaId = this.$route.query.areaId
      this.areaName = json.areaName
      this.warehouseId = json.warehouseId
      if (this.pageNo !== '' && this.pageSize !== '' && this.areaId !== '') {
        this.getParams()
      }
      const res1 = await api.warehouseStructure_warehouseDetail({ pageNo: this.search2.currpage, pageSize: this.search2.pagesize, areaId: this.areaId, warehouseId: this.warehouseId })
      this.layerNum = res1.body.datas[0].layerNum
      this.shelvesNum = res1.body.datas[0].shelvesNum
    },
    async getParams () {
      const res = await api.warehouseStructure_areaDetail({ pageNo: this.search2.currpage, pageSize: this.search2.pagesize, areaId: this.areaId })
      this.shelvesTabledata = res.body.datas
      this.search2.currpage = res.body.pageNo
      this.search2.pagesize = res.body.pageSize
      this.search2.currentPage = res.body.sumDataCount
    },
    // 货架
    // 新增货架
    addShelves () {
      this.dialogVisible4 = true
      this.shelvesTitle = '新增货架'
    },
    // 新增货架消失
    WarehouseDialog3 () {
      this.dialogVisible4 = false
      this.$refs.ruleForm2.resetFields()
      this.form3.shelvesName3 = ''
      this.form3.shelvesLayer3 = ''
    },
    // 新增/编辑货架保存
    saveShelves () {
      this.$refs.ruleForm2.validate(async (valid) => {
        if (valid) {
          if (this.shelvesTitle === '新增货架') {
            const res = await api.warehouseStructure_newShelves({ areaId: this.areaId, shelvesName: this.form3.shelvesName3, shelvesNum: this.form3.shelvesLayer3 })
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '新增货架成功!'
              })
              this.WarehouseDialog3()
              this.inline()
            } else {
              return false
            }
          } else if (this.shelvesTitle === '编辑货架') {
            const res = await api.warehouseStructure_updateShelves({ shelvesId: this.shelvesTabledata[this.index].shelvesId, shelvesName: this.form3.shelvesName3, shelvesNum: this.form3.shelvesLayer3, version: this.shelvesTabledata[this.index].version })
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '编辑货架成功!'
              })
              this.WarehouseDialog3()
              this.inline()
            } else {
              return false
            }
          }
        }
      })
    },
    // 编辑货架
    async editorShelves (index) {
      this.index = index
      this.dialogVisible4 = true
      this.shelvesTitle = '编辑货架'
      this.form3.shelvesName3 = this.shelvesTabledata[index].shelvesName
      this.form3.shelvesLayer3 = this.shelvesTabledata[index].shelvesNum

      // const res = await api.warehouseStructure_areaDetail({ pageNo: this.areaSearch.currpage, pageSize: this.areaSearch.pagesize, warehouseId: this.tableData[index].warehouseId })
    },
    // 编辑删除
    async shelvesDelete (index) {
      const res = await api.warehouseStructure_deleteShelves({ shelvesId: this.shelvesTabledata[index].shelvesId, version: this.shelvesTabledata[index].version })
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: '删除货架成功!'
        })
        this.inline()
      } else {
        return false
      }
    },
    handleSizeChange (val) {
      this.search2.pagesize = val
      this.inline()
    },
    handleCurrentChange (val) {
      this.search2.currpage = val
      this.inline()
    }
  }
}
</script>

<style lang="scss" scoped>
  .head-container {
    .el-row[style="margin-left: -10px; margin-right: -10px; height: 37px;"] {
      position: absolute;
      top: 100px;
      width: 300px;
      right: -140px;
      z-index: 10;
      display: flex;
    }
  }

  ::v-deep .elFormX {
    .el-form-item__content {
      margin-left: 0px !important;
    }
  }

  .headline {
    width: 100%;
    font-size: 20px;
    line-height: 40px;
    background-color: #a3a3a3;
    color: #fff;
  }

  ::v-deep .el-form-item__error {
    position: absolute;
    bottom: 10px;
  }

  .paging {
    height: 40px;
  }

  .el-pagination {
    position: absolute;
    right: 20px;
  }
  .dialog-footer{
    display: flex;
    justify-content: space-around;
    padding: 0 100px;
  }
  .block{
  margin-top: 10px;
}
.headerData {
  font-weight: 700;
  width: 33%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  span{
  font-weight: 100;
  }
}
  </style>
