var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderDetails" },
    [
      _c("Basic", {
        attrs: {
          goodsInfo: _vm.goodsInfo,
          info: _vm.info,
          logInfo: _vm.logInfo
        },
        on: { getInfo: _vm.getInfo }
      }),
      _c("Good", {
        attrs: { goodsInfo: _vm.goodsInfo, info: _vm.info },
        on: { getGoods: _vm.getGoods, refresh: _vm.refresh }
      }),
      _vm.info.type !== 1
        ? _c("Particulars", { attrs: { logInfo: _vm.logInfo } })
        : _vm._e(),
      _c("Log", { attrs: { logInfo: _vm.logInfo } }),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-bottom": "20px"
          }
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.resetForm()
                }
              }
            },
            [_vm._v(" 关闭 ")]
          ),
          _vm.info.status === 4 &&
          _vm.info.type === 3 &&
          _vm.permission.isCopy === false
            ? _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "success",
                    loading: _vm.loadingCopy,
                    disabled: _vm.loadingCopy
                  },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.copyOrder()
                    }
                  }
                },
                [_vm._v(" 复制订单 ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }