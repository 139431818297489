<template>
  <d2-container>
    <div class="header" v-loading="infoDataLoading">
      <div
        class="searchBgc3"
        style="margin-bottom: 10px;display: flex;justify-content: space-between;"
      >
        基本信息
      </div>
      <el-form
        ref="InfoForm"
        :model="redactData"
        class="demo-ruleForm"
        label-width="100px"
        style="font-weight: bold"
      >
        <div style="display: flex">
          <el-form-item label="盘点单号">
            <el-tag>{{ redactData.checkId }}</el-tag>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-tag type="success">
              {{ redactData.createdTimestamp }}
            </el-tag>
          </el-form-item>
          <el-form-item label="完成时间" v-if="redactData.status == 2">
            <el-tag type="success">
              {{ redactData.endTime }}
            </el-tag>
          </el-form-item>
          <el-form-item label="审核状态">
            <div v-if="redactData.auditStatus == 0">
              <el-tag>待审核</el-tag>
            </div>
            <div v-if="redactData.auditStatus == 1">
              <el-tag type="success">审核通过</el-tag>
            </div>
            <div v-if="redactData.auditStatus == 2">
              <el-tag type="danger">审核不通过</el-tag>
            </div>
            <div v-if="redactData.auditStatus == 9">
              <el-tag type="warning">终止盘库</el-tag>
            </div>
          </el-form-item>
          <el-form-item label="盘点状态">
            <el-tag
              :type="
                redactData.status === 0
                  ? ''
                  : redactData.status === 1
                  ? 'warning'
                  : redactData.status === 3
                  ? 'success'
                  : redactData.status === 9
                  ? 'danger'
                  : ''
              "
            >
              {{
                redactData.status === 0
                  ? "待盘库"
                  : redactData.status === 1
                  ? "盘库中"
                  : redactData.status === 3
                  ? "盘库完成"
                  : redactData.status === 9
                  ? "终止盘库"
                  : ""
              }}
            </el-tag>
          </el-form-item>
          <el-form-item label="制单人员">
            <div style="font-weight: normal">{{ redactData.createdName }}</div>
          </el-form-item>
        </div>
        <div style="display: flex;">
          <el-form-item label="盘点维度">
            <span>{{
              redactData.dimension == 0 ? "商品维度" : "货架维度"
            }}</span>
          </el-form-item>
          <!-- <el-form-item label="盘点类型">
            <span>{{ redactData.type == 0 ? "录入盘库" : "扫码盘库" }}</span>
          </el-form-item> -->
          <el-form-item label="盘点标题">
            <span>{{ redactData.checkName }}</span>
          </el-form-item>
        </div>
        <el-form-item label="盘库备注">
            <el-input disabled type="textarea" v-model="redactData.remark"></el-input>
        </el-form-item>
        <div
          class="searchBgc2"
          style="margin-bottom: 10px;display: flex;justify-content: space-between;"
        >
          商品列表
          <el-button
            :disable="exportLoading"
            :loading="exportLoading"
            size="mini"
            style="height: 26px;margin-right: 20px;margin-top: 2px;"
            type="primary"
            @click="exportTable()"
            >导出
          </el-button>
        </div>
        <div class="goodsDataSty">
          <el-form-item class="itemAddSty">
            <el-table
              ref="goodsData"
              :cell-style="{ textAlign: 'center' }"
              :data="redactData.goodsData"
              :header-cell-style="{
                background: '#dfe6ec',
                color: '#666666',
                textAlign: 'center'
              }"
              :row-style="{ height: '57px' }"
              element-loading-text="数据加载中"
              max-height="430px"
              style="padding: 5px 5px 0;"
              tooltip-effect="dark"
            >
              <el-table-column
                align="center"
                key="index"
                label="序号"
                width="80"
              >
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                key="id"
                label="商品编码"
                prop="id"
              >
              </el-table-column>
              <el-table-column
                align="center"
                key="goodsName"
                label="商品名称"
                prop="goodsName"
              >
              </el-table-column>
              <el-table-column
                align="center"
                label="商品图片"
                prop="pictureUrl"
                key="pictureUrl"
              >
                <template slot-scope="scope">
                  <el-image
                    :preview-src-list="[
                      downloadUrl + '/' + scope.row.pictureUrl
                    ]"
                    :src="downloadUrl + '/' + scope.row.pictureUrl"
                    style="width: 40px; height: 40px;"
                  />
                </template>
              </el-table-column>
              <el-table-column
                v-if="redactData.dimension == '1'"
                align="center"
                prop="areaName"
                key="areaName"
                label="区域"
              >
              </el-table-column>
              <el-table-column
                v-if="redactData.dimension == '1'"
                align="center"
                prop="shelvesName"
                key="shelvesName"
                label="货架"
              >
              </el-table-column>
              <el-table-column
                v-if="redactData.dimension == '1'"
                align="center"
                prop="shelvesLayers"
                key="shelvesLayers"
                label="层数"
              >
              </el-table-column>
              <el-table-column
                align="center"
                label="入库价"
                key="intoPrices"
                prop="intoPrices"
              >
              </el-table-column>
              <el-table-column
                align="center"
                label="在库数量"
                key="goodsNum"
                prop="goodsNum"
              >
              </el-table-column>
              <el-table-column
                align="center"
                label="实际库存"
                key="checkNum"
                prop="checkNum"
              >
                <template slot-scope="scope">
                  {{ redactData.status === 9 ? "- -" : scope.row.checkNum }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="盘点盈亏"
                prop="breakEven"
                key="breakEven"
                v-if="redactData.status !== 9"
              >
                <template slot-scope="scope">
                  <el-tag
                    :style="{
                      color:
                        scope.row.breakEven > 0
                          ? '#00cc66'
                          : scope.row.breakEven < 0
                          ? '#cc3333'
                          : ''
                    }"
                    :type="
                      scope.row.breakEven > 0
                        ? 'success'
                        : scope.row.breakEven < 0
                        ? 'danger'
                        : ''
                    "
                  >
                    {{ scope.row.breakEven }}
                  </el-tag>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </div>
        <!-- <div class="searchBgc2" style="margin-bottom: 20px;">备注信息</div> -->
        <!-- <el-form-item label="盘点备注">
          <p class="textSpanSty">
            {{ redactData.remark ? redactData.remark : "- -" }}
          </p>
        </el-form-item> -->
        <!-- <div
          class="searchBgc2"
          style="margin-bottom: 20px;"
          v-if="redactData.status != 9"
        >
          盘点结果
        </div> -->
        <div class="title">盘库结果</div>
        <div class="bottom">
          <div>待盘点数量:{{ Dnum }}</div>
          <div>已盘点数量:{{ Ynum }}</div>
          <div>盘盈:{{ Pwin }}</div>
          <div>盘亏:{{ Plose }}</div>
          <div>出库单号：<span class="jump" @click="redactData.outId?handleDetailsOut(redactData.outId):''">{{ redactData.outId }}</span></div>
          <div>入库单号：<span class="jump" @click="redactData.intoId?handleDetailsIn(redactData.intoId):''">{{ redactData.intoId }}</span></div>
          <div class="textarea">
              盈亏原因备注：
              <el-input type="textarea" disabled v-model="redactData.checkRemark" placeholder=""></el-input>
          </div>
        </div>
        <!-- <div
          v-if="redactData.status != 9"
          style="display: flex;justify-content: space-between;width: 50%;margin-top: 10px;margin-left: 15px"
        >
          <div style="margin-right: 30px">
            <span style="font-weight: bolder">盘点盈亏:</span>
            {{ this.getBreakEven() }}
          </div>
        </div> -->
        <div class="searchBgc2" style="margin-bottom: 20px;margin-top: 20px;">
          操作日志
        </div>
        <div>
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in redactData.logList"
              :key="index"
              :timestamp="item.createdTimestamp"
              style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;"
            >
              {{
                item.createdName +
                  item.operateDesc +
                  "     " +
                  "备注信息:" +
                  (item.remark === null ? "" : item.remark)
              }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-form>
      <div style="display: flex;justify-content: center;margin-top: 10px">
        <el-button
          size="mini"
          style="margin-bottom: 20px;margin-top: 20px;"
          @click="goBack()"
          >关闭</el-button
        >
      </div>
    </div>
  </d2-container>
</template>
<script>
import { downloadFileByUrl } from '@/utils'
import api from '@/api'
import { mapActions } from 'vuex'

export default {
  name: 'MakeInventoryDetails',
  data () {
    return {
      Dnum: null,
      Ynum: null,
      Pwin: null,
      Plose: null,
      visibleDialog: false, // 弹窗开关
      titleName: '', // 弹窗名称
      exportLoading: false,
      infoDataLoading: false,
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL, // downloadUrl
      redactData: {},
      warehouseIdData: [],
      areaIdData: [],
      shelvesIdData: []
    }
  },
  watch: {
    $route (to, from) {
      this.$route.query.checkId = to.query.checkId
      this.$route.query.id = to.query.id
      this.initList()
    }
  },
  created () {
    this.$store.commit('d2admin/page/modifyOpend', opened => {
      opened.forEach(current => {
        if (current.name === 'MakeInventoryDetails') {
          current.meta.title = '盘库详情-' + current.query.checkId
        }
      })
    })
    this.initList()
  },
  computed: {},
  methods: {
    // 入库打开明细弹窗
    handleDetailsIn(row) {
      this.$router.push({
        path: '/stockindetails',
        query: {
          id: row
        }
      })
    },
    // 出库打开明细列表
    handleDetailsOut (row) {
      this.$router.push({
        path: '/stockoutdetails',
        query: {
          outId: row
        }
      })
    },
    async calculateM(){
      const res = await api.getAffirmAuditParticulars({
        id:  this.$route.query.id
      })
      try {
        let list = []
        if(this.redactData.dimension == 0){
          list = res.body.checkGoodsList
        }else{
          list = res.body.inventoryList
        }
       
        for (let i = 0; i < list.length; i++) {
          this.Dnum += list[i].goodsNum
          this.Ynum += (list[i].checkNum + (list[i].scanNum?list[i].scanNum:0))
          this.Pwin += ((list[i].checkNum + (list[i].scanNum?list[i].scanNum:0)) - list[i].goodsNum) > 0 ? ((list[i].checkNum + (list[i].scanNum?list[i].scanNum:0)) - list[i].goodsNum) : 0
          this.Plose += ((list[i].checkNum + (list[i].scanNum?list[i].scanNum:0)) - list[i].goodsNum) < 0 ? ((list[i].checkNum + (list[i].scanNum?list[i].scanNum:0)) - list[i].goodsNum) : 0
        }
      } catch (err) {

      }
    },
    ...mapActions('d2admin/page', ['close']),
    async initList () {
      try {
        this.infoDataLoading = true
        const res = await api.makeInventoryGetCheckDetail({
          id: this.$route.query.id
        })
        this.redactData = res.body
        const goodsList = this.redactData.dimension === 0
          ? this.redactData.checkGoodsList
          : this.redactData.inventoryList
        this.redactData.goodsData = goodsList.map(item => {
          const breakEven = item.checkNum - item.goodsNum
          return {
            id: item.goodsCoding,
            goodsName: item.goodsName,
            pictureUrl: item.pictureUrl,
            intoPrices: item.intoPrices || item.intoPrice,
            goodsNum: item.goodsNum,
            areaName: item.areaName,
            shelvesName: item.shelvesName,
            shelvesLayers: item.shelvesLayers,
            checkNum: item.checkNum,
            breakEven: breakEven > 0 ? `+${breakEven}` : breakEven
          }
        })
        this.calculateM()
      } catch (error) {
        this.$message.error('获取详情失败')
      } finally {
        this.infoDataLoading = false
      }
    },
    getBreakEven () {
      // this.redactData.goodsData.map(item => item.checkNum)
    },
    goBack () {
      const tagName =
        '/MakeInventoryDetails?checkId=' +
        this.$route.query.checkId +
        '&id=' +
        this.$route.query.id
      this.redactData = {}
      this.$router.push({ path: '/makeInventory' })
      setTimeout(() => this.close({ tagName: tagName }), 10)
    },
    async exportTable () {
      this.exportLoading = true
      try {
        const res = await api.xxx({})
        downloadFileByUrl(res.body)
      } catch (e) {
        this.$message.error('导出报表失败')
      } finally {
        this.exportLoading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .goodsDataSty .el-form-item__content {
  margin-left: 0 !important;
}
.header {
  margin-left: 9px;
  margin-right: 9px;
  box-sizing: border-box;
  border: 2px solid #888;
  border-radius: 10px;
  min-height: 100%;
  width: 99%;
}

.searchBgc1 {
  height: 30px;
  line-height: 30px;
  background-color: #dfe6ec;
  padding-left: 10px;
  font-weight: bolder;
  border-radius: 10px 10px 0px 0px;
}

.searchBgc2 {
  height: 30px;
  line-height: 30px;
  background-color: #dfe6ec;
  padding-left: 10px;
  font-weight: bolder;
}

.searchBgc3 {
  height: 30px;
  line-height: 30px;
  border-radius: 10px 10px 0 0;
  background-color: #dfe6ec;
  padding-left: 10px;
  font-weight: bolder;
}
::v-deep .itemAddSty .el-form-item__content {
  margin-left: 0px !important;
  padding: 0px 10px 0px;
}
.textSpanSty {
  // background-color: #f60;
  width: 100%;
  margin-top: 0px;
  flex-wrap: wrap-reverse;
  table-layout: fixed;
  word-wrap: break-word;
  word-break: normal;
  overflow: hidden;
}

.title {
  height: 30px;
  line-height: 30px;
  background-color: #dfe6ec;
  padding-left: 10px;
  font-weight: bolder;
}

.bottom {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    font-weight: bold;
    padding: 0 15px;

    div {
        min-width: 200px;
        margin-top: 20px;
    }

    .textarea {

        width: 100%;
    }
}
.jump{
  cursor:pointer;
  color: rgb(96, 96, 249);
}
</style>
