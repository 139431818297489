/**
 * <日志管理请求接口
 * @author 王振宇
 * @since 2021-04-08
 */
export default ({ service, request, serviceForMock, requestForMock, mock, faker, tools }) => ({
  GET_LOG_PAGE_LIST (data = {}) {
    return request({
      url: '/v1/log/getPageList',
      method: 'get',
      params: data
    })
  },
  GET_LOG_INFO () {
    return request({
      url: '/v1/log/info',
      method: 'get'
    })
  },
  SAVE_LOG (data = {}) {
    return request({
      url: '/v1/log/save',
      method: 'post',
      data: data
    })
  },
  UPDATE_LOG (data = {}) {
    return request({
      url: '/v1/log/update',
      method: 'put',
      data: data
    })
  },
  DELETE_LOG (data = {}) {
    return request({
      url: '/v1/log/delete',
      method: 'delete',
      data: data
    })
  },
  DELETE_BATCH_LOG (data = {}) {
    return request({
      url: '/v1/log/deleteBatch',
      method: 'delete',
      data: data
    })
  }
})
