export default (function (_ref) {
  var request = _ref.request;
  return {
    // 分页查询
    warehouseStructure_getPageList: function warehouseStructure_getPageList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/getPageList',
        method: 'post',
        data: data
      });
    },
    // 查询部门
    warehouseStructure_getUserDeptSelect: function warehouseStructure_getUserDeptSelect() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/organization/getUserDeptSelect',
        method: 'post',
        data: data
      });
    },
    // 查询仓库
    warehouseStructure_searchWarehouse: function warehouseStructure_searchWarehouse() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/searchWarehouse',
        method: 'post',
        data: data
      });
    },
    // 查询仓库详情||详情
    warehouseStructure_warehouseDetail: function warehouseStructure_warehouseDetail() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/warehouseDetail',
        method: 'post',
        data: data
      });
    },
    // 查询区域详情||详情
    warehouseStructure_areaDetail: function warehouseStructure_areaDetail() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/areaDetail',
        method: 'post',
        data: data
      });
    },
    // 查询区域名称
    warehouseStructure_searchArea: function warehouseStructure_searchArea() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/searchArea',
        method: 'post',
        data: data
      });
    },
    // 新增仓库
    warehouseStructure_newWarehouse: function warehouseStructure_newWarehouse() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/newWarehouse',
        method: 'post',
        data: data
      });
    },
    // 新增区域
    warehouseStructure_newArea: function warehouseStructure_newArea() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/newArea',
        method: 'post',
        data: data
      });
    },
    // 新增货架
    warehouseStructure_newShelves: function warehouseStructure_newShelves() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/newShelves',
        method: 'post',
        data: data
      });
    },
    // 编辑仓库
    warehouseStructure_updateWarehouse: function warehouseStructure_updateWarehouse() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/updateWarehouse',
        method: 'post',
        data: data
      });
    },
    // 编辑区域
    warehouseStructure_updateArea: function warehouseStructure_updateArea() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/updateArea',
        method: 'post',
        data: data
      });
    },
    // 编辑货架
    warehouseStructure_updateShelves: function warehouseStructure_updateShelves() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/updateShelves',
        method: 'post',
        data: data
      });
    },
    // 删除仓库
    warehouseStructure_deleteWarehouse: function warehouseStructure_deleteWarehouse() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/deleteWarehouse',
        method: 'post',
        data: data
      });
    },
    // 删除区域
    warehouseStructure_deleteArea: function warehouseStructure_deleteArea() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/deleteArea',
        method: 'post',
        data: data
      });
    },
    // 删除货架
    warehouseStructure_deleteShelves: function warehouseStructure_deleteShelves() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/deleteShelves',
        method: 'post',
        data: data
      });
    },
    // 查询所有公司以及公司Id
    warehouseStructure_getDeptSelect: function warehouseStructure_getDeptSelect() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/organization/getDeptSelect',
        method: 'post',
        data: data
      });
    }
  };
});