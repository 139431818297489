// 库存列表
export default ({ request }) => ({
  varehousetable_getList_stock(data = {}) {
    return request({
      url: "/v1/inventory/getPageLocation",
      method: "post",
      data: data
    });
  },
  varehousetable_getList_goods(data = {}) {
    return request({
      url: "/v1/inventory/getPageList",
      method: "post",
      data: data
    });
  },
  // 根据仓库id查询区域
  entrepot_selectArea(data = {}) {
    return request({
      url: "/app/entrepot/selectArea",
      method: "post",
      data: data
    });
  },
  // 根据区域id查询货架
  entrepot_selectShelves(data = {}) {
    return request({
      url: "/app/entrepot/selectShelves",
      method: "post",
      data: data
    });
  },
  // 按照商品显示查询库存明细基本信息
  product_baseInfo(data = {}) {
    return request({
      url: "/v1/inventory/product/baseInfo",
      method: "post",
      data: data
    });
  },
  // 按照商品显示查询在库详情信息
  product_inLibraryDetails(data = {}) {
    return request({
      url: "/v1/inventory/product/inLibraryDetails",
      method: "post",
      data: data
    });
  },
  // 按照商品显示查询在库详情信息，详情信息中的商品详情
  product_inLibraryInfo(data = {}) {
    return request({
      url: "/v1/inventory/product/inLibrary/info",
      method: "post",
      data: data
    });
  },
  // 查询库存详情商品的历史记录
  product_getGoodsCodingRecord(data = {}) {
    return request({
      url: "/v1/inventoryPrice/getGoodsCodingRecord",
      method: "post",
      data: data
    });
  },
  // 导出在库详情excel
  product_inLibraryExport(data = {}) {
    return request({
      url: "/v1/inventory/product/inLibraryExport",
      method: "post",
      data: data
    });
  },
  inventory_storageList(data = {}) {
    return request({
      url: "/v1/inventory/storage/list",
      method: "post",
      data: data
    });
  },
  // 按库位维度导出excel
  stockposition_exportExcel(data = {}) {
    return request({
      url: "/v1/inventory/exportExcel",
      method: "post",
      data: data
    });
  },
  // 按库位维度明细导出excel
  inventory_storage_export(data = {}) {
    return request({
      url: "/v1/inventory/storage/export",
      method: "post",
      data: data
    });
  },
  // 按库位维度导出excel
  inventory_storage_locationExport(data = {}) {
    return request({
      url: "/v1/inventory/storage/locationExport",
      method: "post",
      data: data
    });
  },
  // 按商品维度导出excel
  inventory_storage_goodExport(data = {}) {
    return request({
      url: "/v1/inventory/storage/goodExport",
      method: "post",
      data: data
    });
  },
  // 库存维度中明细的详情
  inventory_storage_detail(data = {}) {
    return request({
      url: "/v1/inventory/storage/detail",
      method: "post",
      data: data
    });
  },
  // 入库单导出
  inventory_storage_exportInto(data = {}) {
    return request({
      url: "/newStatical/exportInto",
      method: "post",
      data: data
    });
  },
  // 出库单导出
  inventory_storage_outBoundExcel(data = {}) {
    return request({
      url: "/newStatical/outBoundExcel",
      method: "post",
      data: data
    });
  },
  // 采购汇总导出
  inventory_storage_exportPurStatical(data = {}) {
    return request({
      url: "/purchase/statical/exportPurStatical",
      method: "post",
      data: data
    });
  },
  // 采购单导出
  inventory_storage_exportPurchase(data = {}) {
    return request({
      url: "/purchase/statical/exportPurchase",
      method: "post",
      data: data
    });
  },
  // 调拨单导出
  inventory_storage_exportTransfer(data = {}) {
    return request({
      url: "/newStatical/exportTransfer",
      method: "post",
      data: data
    });
  }
});
