<template>
  <d2-container>
    <div class="header">
      <div class="searchBgc3" style="margin-bottom: 10px;display: flex;justify-content: space-between;">
        基本信息
      </div>
      <el-form ref="redactData" :model="redactData" class="ruleSty" label-width="100px">
        <div :style="redactData.purchaseType === tag3
        ? 'display:flex;justify-content: left;'
        : ''
        " class="div2">
          <div class="div3">
            <div class="div1">
              <el-form-item label="采购单号" prop="purchaseNo">
                <div style="  color: #1482f0;">
                  <el-tag>{{ this.purchaseNo }}</el-tag>
                </div>
              </el-form-item>
              <el-form-item label="创建日期">
                <div style="color: #606266">
                  <el-tag type="warning">{{ this.createdTimestamp }}</el-tag>
                </div>
              </el-form-item>
              <el-form-item label="采购状态" prop="status">
                <div style="color: #606266">
                  <el-tag type="success">
                    {{
        this.status === 0
          ? '待采购'
          : this.status === 1
            ? '采购中'
            : this.status === 2
              ? '已完成'
              : this.status === 3
                ? '待终止'
                : this.status === 9
                  ? '已终止'
                  : '- -'
      }}
                  </el-tag>
                </div>
              </el-form-item>
              <el-form-item v-if="this.redactData.purchaseType === '调拨'" label="配送方式" prop="distribution">
                <el-tag type="danger">{{
        this.redactData.distribution === 0
          ? '自配送'
          : this.redactData.distribution === 1
            ? '门店自取'
            : this.redactData.distribution === 2
              ? '物流'
              : ''
      }}
                </el-tag>
              </el-form-item>
            </div>
            <div>
              <div class="div1">
                <el-form-item label="采购类型" prop="purchaseType">
                  <el-select v-model="redactData.purchaseType" :disabled="true" clearable placeholder="请选择采购类型"
                    size="small">
                    <el-option v-for="item in purchaseTypesData" :key="item.keys" :label="item.values"
                      :value="item.keys" v-bind:title="onHoverText"
                      @mouseenter.native="onMouseOver(item.values)"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-if="this.redactData.purchaseType !== this.tag3" label="渠道" prop="channel">
                  <el-select v-model="redactData.channel" :disabled="true" clearable placeholder="请选择渠道" size="small">
                    <el-option v-for="item in channelsData" :key="item.keys" :label="item.values" :value="item.keys"
                      v-bind:title="onHoverText" @mouseenter.native="onMouseOver(item.values)"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-if="this.redactData.purchaseType !== this.tag3" label="付款方式" prop="payMethod">
                  <el-select v-model="redactData.payMethod" :disabled="true" clearable placeholder="请选择付款方式"
                    size="small">
                    <el-option v-for="(item, index) in payMethodsData" :key="index" :label="item.value" :value="item.id"
                      v-bind:title="onHoverText" @mouseenter.native="onMouseOver(item.values)"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="div1">
                <el-form-item v-if="this.redactData.purchaseType === this.tag2" label="预定日期" prop="predictArriveDate">
                  <el-date-picker v-model="redactData.predictArriveDate" :disabled="showRedactFlag"
                    :picker-options="pickerOptions" placeholder="选择日期" size="small" style="width:215px" type="date"
                    value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <el-form-item v-if="this.redactData.purchaseType !== this.tag3" label="发货方式" prop="deliveryMethod">
                  <el-select v-model="redactData.deliveryMethod" :disabled="true" clearable placeholder="请选择发货方式"
                    size="small">
                    <el-option v-for="(item, index) in deliveryMethodsData" :key="index" :label="item.value"
                      :value="item.id" v-bind:title="onHoverText"
                      @mouseenter.native="onMouseOver(item.value)"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-if="returnSty()" label="收货人" prop="deliveryUserName">
                  <el-input v-model="redactData.deliveryUserName" :disabled="showRedactFlag" clearable
                    placeholder="请输入收货人" size="small" style="width:215px"></el-input>
                </el-form-item>
                <el-form-item v-if="returnSty()" label="联系方式" prop="deliveryPhone">
                  <el-input v-model.trim="redactData.deliveryPhone" :disabled="showRedactFlag" clearable maxlength="11"
                    placeholder="请输入收货人联系方式" size="small" style="width:215px"></el-input>
                </el-form-item>
                <el-form-item label="入库仓库" prop="warehouseId">
                  <el-select v-model="redactData.warehouseId" :disabled="true" clearable placeholder="请选择入库仓库"
                    size="small" @change="getWarehouseAddress(item.warehouseAddress)">
                    <el-option v-for="(item, index) in warehouseIdsData" :key="index" :label="item.warehouseName"
                      :value="item.warehouseId" v-bind:title="onHoverText"
                      @mouseenter.native="onMouseOver(item.value)"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-if="this.redactData.purchaseType === this.tag3" label="来源仓库" prop="sourceWarehouseId">
                  <el-select v-model="redactData.sourceWarehouseId" :disabled="true" clearable placeholder="请选择入库仓库"
                    size="small">
                    <el-option v-for="(item, index) in sourceWarehouseIdsData" :key="index" :label="item.warehouseName"
                      :value="item.warehouseId" v-bind:title="onHoverText"
                      @mouseenter.native="onMouseOver(item.value)"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-if="this.redactData.purchaseType === this.tag3" label="仓库位置" prop="sameWarehouseFlag">
                  <el-select v-model="redactData.sameWarehouseFlag" :disabled="true" clearable placeholder="请选择仓库位置"
                    size="small">
                    <el-option v-for="(item, index) in sameWarehouseFlagData" :key="index" :label="item.value"
                      :value="item.id" v-bind:title="onHoverText"
                      @mouseenter.native="onMouseOver(item.value)"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div v-if="this.redactData.purchaseType !== '调拨' ||
        this.redactData.distribution === 2
        " class="searchBgc2" style="margin-bottom: 10px;display: flex;justify-content: space-between;">
          物流信息
        </div>
        <div class="div1">
          <el-form-item v-if="this.redactData.purchaseType !== '调拨' ||
        this.redactData.distribution === 2
        " label="物流公司">
            <el-input v-model="redactData.courierCompany" :disabled="this.status === 9 || this.status === 2" clearable
              placeholder="请输入物流公司" size="small" style="width:215px"></el-input>
          </el-form-item>
          <el-form-item v-if="this.redactData.purchaseType !== '调拨' ||
        this.redactData.distribution === 2
        " label="物流单号">
            <el-input v-model="redactData.trackingNumber" :disabled="this.status === 9 || this.status === 2" clearable
              placeholder="请输入物流单号" size="small" style="width:215px"></el-input>
          </el-form-item>
          <el-form-item v-if="this.redactData.purchaseType !== '调拨' ||
        this.redactData.distribution === 2
        " label="收货地址" prop="deliveryAddress">
            <el-input v-model="redactData.deliveryAddress" :disabled="this.status === 9 || this.status === 2" clearable
              placeholder="请输入收货地址" size="small" style="width:530px"></el-input>
            <el-button v-if="this.status !== 9 && this.redactData.trackingNumber !== ''" v-preventReClick size="small"
              style="margin-left: 20px" type="info" @click="openLogistics()">
              查看物流
            </el-button>
          </el-form-item>
        </div>
        <div v-if="this.redactData.purchaseType !== this.tag3" class="searchBgc2"
          style="margin-bottom: 10px;display: flex;justify-content: space-between;">
          交易截图
        </div>
        <div v-if="this.redactData.purchaseType !== this.tag3" class="div4">
          <div>
            <el-form-item label="付款截图">
              <erp-upload-img v-if="this.status === 0" v-model="redactData.payProve" :maxCount="10" :showDelete="true"
                :upload-path="'/purchasingManagement/purchasing/'"></erp-upload-img>
              <erp-upload-img v-if="this.status !== 0" v-model="redactData.payProve"
                :maxCount="redactData.payProve.length"
                :upload-path="'/purchasingManagement/purchasing'"></erp-upload-img>
            </el-form-item>
          </div>
          <div>
            <el-form-item v-if="this.redactData.purchaseType === this.tag2 && this.redactData.payProve.length > 0"
              label="尾款截图">
              <erp-upload-img v-if="this.status === 0" v-model="redactData.finalPaymentProve" :maxCount="10"
                :showDelete="true" :upload-path="'/purchasingManagement/purchasing'"></erp-upload-img>
              <erp-upload-img v-if="this.status !== 0" v-model="redactData.finalPaymentProve"
                :maxCount="redactData.finalPaymentProve.length" :showDelete="true"
                :upload-path="'/purchasingManagement/purchasing/'"></erp-upload-img>
              <!-- <div v-if="this.status === 9 || this.status === 2">
                <img
                  v-for="(item, index) in redactData.finalPaymentProve"
                  :key="index"
                  style=" width: 150px; height: 150px; text-align: center;cursor: pointer;"
                  alt=""
                  :src="downloadUrl + '/' + item.url"
                  @click="getPreview(downloadUrl + '/' + item.url)"
                />
              </div> -->
            </el-form-item>
          </div>
        </div>
        <div class="searchBgc2" style="margin-bottom: 10px;display: flex;justify-content: space-between;">
          商品信息
        </div>
        <el-form-item class="itemAddSty" prop="itemAddData">
          <el-table ref="itemAddData" :cell-style="{ textAlign: 'center' }" :data="redactData.itemAddData"
            :header-cell-style="{
        background: '#dfe6ec',
        color: '#666666',
        textAlign: 'center'
      }" :row-style="{ height: '77px' }" element-loading-text="数据加载中" max-height="690px"
            style="box-sizing: border-box;" tooltip-effect="dark">
            <el-table-column align="center" label="商品编码" prop="goodsCoding" width="120">
              <template slot-scope="scope">
                {{ scope.row.goodsCoding }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="商品名称" prop="goodsName" width="260">
              <template slot-scope="scope">
                <div
                  style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;">
                  {{ scope.row.goodsName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="商品图片" prop="pictureUrl" width="80">
              <template slot-scope="scope">
                <img v-if="scope.row.pictureUrl !== '' && scope.row.pictureUrl !== null
        " :src="downloadUrl + '/' + scope.row.pictureUrl" alt=""
                  style="width: 50px; height: 50px;margin-top: 8px;cursor: pointer"
                  @click="getPreview(downloadUrl + '/' + scope.row.pictureUrl)" />
                <div v-if="scope.row.pictureUrl === '' || scope.row.pictureUrl === null
        " style="width: 50px;height: 50px;border: 1px solid #c0c0c0;display: flex;justify-content: center;margin: 0 auto;">
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="分类" prop="goodsOne" width="120">
              <template slot-scope="scope">
                <div
                  style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;">
                  {{ scope.row.goodsOne === null ? '- -' : scope.row.goodsOne }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="品牌" prop="goodsBrandName" width="120">
              <template slot-scope="scope">
                <div
                  style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;">
                  {{
        scope.row.goodsBrandName === null
          ? '- -'
          : scope.row.goodsBrandName
      }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="商品IP" prop="goodsIpName" width="120">
              <template slot-scope="scope">
                <div
                  style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;">
                  {{ scope.row.goodsIpName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="我的入库价(元)" prop="warehousePriceRange" width="120">
              <template slot-scope="scope">
                <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                  {{
        scope.row.warehousePriceRange === null ||
          scope.row.warehousePriceRange === undefined
          ? '- -'
          : scope.row.warehousePriceRange
      }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="我的库存数量" prop="inventoryNum" width="120">
              <template slot-scope="scope">
                <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                  {{
          scope.row.inventoryNum === null ||
            scope.row.inventoryNum === undefined
            ? '- -'
            : scope.row.inventoryNum
        }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="我的在途数量" prop="inTransitNum" width="120">
              <template slot-scope="scope">
                <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                  {{
          scope.row.inTransitNum === null ||
            scope.row.inTransitNum === undefined
            ? '- -'
            : scope.row.inTransitNum
        }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="采购数量" width="150">
              <template slot-scope="scope">
                <el-form-item :prop="'itemAddData.' + scope.$index + '.num'">
                  <el-input-number v-model.number="scope.row.num" :disabled="showRedactFlag" :min="1" :precision="0"
                    :step="1" size="small"></el-input-number>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column v-if="(this.confirmAllocate === true &&
        this.redactData.purchaseType === this.tag3) ||
        this.redactData.purchaseType !== this.tag3 ||
        (this.status === 1 &&
          this.redactData.purchaseType === this.tag3) ||
        (this.status === 2 &&
          this.redactData.purchaseType === this.tag3)
        " align="center" label="采购单价" prop="unitPrice" width="150">
              <template slot-scope="scope">
                <el-form-item :prop="'itemAddData.' + scope.$index + '.unitPrice'">
                  <el-input-number v-model="scope.row.unitPrice" :disabled="showRedactFlag" :min="0" :precision="2"
                    :step="1" size="small"></el-input-number>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <div v-if="redactData.itemAddData.length > 0" class="textSty">
          <div>
            <span>合计数量：</span>
            <span class="spanSty" style="margin-right:10px">
              {{ isNaN(count.totalNum) ? 0 : count.totalNum }}
            </span>
          </div>
          <div v-if="(this.confirmAllocate === true &&
        this.redactData.purchaseType === this.tag3) ||
        this.redactData.purchaseType !== this.tag3 ||
        (this.status === 1 &&
          this.redactData.purchaseType === this.tag3) ||
        (this.status === 2 &&
          this.redactData.purchaseType === this.tag3)
        ">
            <span>合计金额：</span>
            <span class="spanSty">
              {{
        isNaN(count.totalAmount)
          ? 0
          : count.totalAmount / 100 > 0
            ? (count.totalAmount / 100).toFixed(2)
            : 0
      }}
            </span>
            &nbsp;元
          </div>
        </div>
        <div v-if="this.intoOrderInfo !== null" class="searchBgc2"
          style="margin-bottom: 10px;display: flex;justify-content: space-between;">
          入库明细
          <el-button icon="el-icon-circle-plus-outline" size="small" style="margin-right: 10px;" type="warning"
            @click="toDispose()">
            处理
          </el-button>
        </div>
        <div v-if="this.intoOrderInfo !== null"
          style="margin-left: 20px;margin-bottom:10px;display: flex;justify-content: space-around;">
          <div>
            <span></span>
            采购数量:
            <span style="margin-left:20px;color: rgb(102, 102, 102);">
              {{ this.intoOrderInfo.totalNum }}
            </span>
          </div>
          <div>
            记录数量:
            <span style="margin-left:20px;color: rgb(102, 102, 102);">
              {{ this.intoOrderInfo.sweepCodeRecordNum }}
            </span>
          </div>
          <div>
            完好:
            <span style="margin-left:20px;color: rgb(102, 102, 102);">
              {{ this.intoOrderInfo.intactNum }}
            </span>
          </div>
          <div>
            未记录:
            <span style="margin-left:20px;color: red;">
              {{ this.intoOrderInfo.withoutRecordNum }}
            </span>
          </div>
          <!-- <div>
            货损:
            <span style="margin-left:20px;color: red;">
              {{ this.intoOrderInfo.cargoDamageNum }}
            </span>
          </div> -->
        </div>

        <div class="searchBgc2" style="margin-bottom: 20px;">备注信息</div>
        <el-form-item label="备注信息">
          <el-input v-model="redactData.remark" :autosize="{ minRows: 5, maxRows: 10 }" :disabled="this.status === 9 || this.status === 2 || this.status === 1
        " maxlength="240" placeholder="请输入备注信息" show-word-limit style="width: 99%" type="textarea"></el-input>
        </el-form-item>

        <div v-if="this.status === 9 || this.status === 3" class="searchBgc2" style="margin-bottom: 20px;">终止相关</div>
        <div v-if="this.status === 9 || this.status === 3">
          <el-form-item label="终止图片">
            <erp-upload-img v-if="redactData.backProve.length > 0" v-model="redactData.backProve"
              :maxCount="redactData.backProve.length"
              :upload-path="'/purchasingManagement/purchasing/'"></erp-upload-img>
            <div v-if="this.status === 9 && redactData.backProve.length === 0">- -</div>
          </el-form-item>
          <el-form-item label="终止原因">
            {{ redactData.refundReason ? redactData.refundReason : '- -' }}
          </el-form-item>
        </div>

        <div class="searchBgc2" style="margin-bottom: 20px;">
          操作日志
        </div>
        <div>
          <el-timeline>
            <el-timeline-item v-for="(item, index) in logList" :key="index" :timestamp="item.createdTimestamp"
              style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;">
              {{
        item.createdName +
        item.operateDesc +
        ' ' +
        remark +
        (item.remark === null ? '' : item.remark)
      }}
            </el-timeline-item>
          </el-timeline>
        </div>
        <div class="searchBgc2" style="margin-bottom: 20px;"></div>
        <div style="display: flex;justify-content: center;margin-bottom: 20px;">
          <el-button size="small" @click="resetForm('redactData')">
            关闭
          </el-button>
        </div>
      </el-form>
    </div>

    <!--     点击图片放大弹出页面         -->
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :title="'图片放大'" :visible.sync="dialogFormVisiblePic"
      style="margin:20px auto" width="540px" @close="closeDialogPic()">
      <img v-if="this.dialogImageUrl !== ''" :src="this.dialogImageUrl" alt=""
        style=" width: 500px; height: 500px; text-align: center" />
    </el-dialog>

    <!--     点击查看物流出页面         -->
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :title="'查看物流'"
      :visible.sync="dialogFormVisibleLogistics" style="margin:20px auto;" width="40%" @close="closeDialogLogistics()">
      <div style="max-height: 600px;overflow:scroll;">
        <el-timeline>
          <el-timeline-item v-for="(item, index) in Logistics" :key="index" :timestamp="item.time">
            {{ item.context }}
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
  </d2-container>
</template>
<script>
import api from '@/api'
import { mapActions } from 'vuex'

const redactDataDefault = {
  purchaseNo: '', // 采购单号
  purchaseType: '', // 采购
  channel: '', // 渠道
  payMethod: '', // 付款方式
  payProve: [], // 付款截图
  finalPaymentProve: [], // 尾款截图
  backProve: [], // 退款图片
  refundReason: '', // 退款原因
  predictArriveDate: '', // 预定日期
  deliveryMethod: '', // 发货方式
  deliveryUserName: '', // 收货人
  deliveryPhone: '', // 收货人联系方式
  warehouseId: '', // 入库仓库
  sourceWarehouseId: '', // 来源仓库
  sameWarehouseFlag: '', //  是否来自同意仓库
  deliveryAddress: '', // 收获地址
  courierCompany: '', // 物流公司
  trackingNumber: '', // 物流单号
  remark: '', // 备注
  itemAddData: [
    // 前端存放 添加商品数据
  ], // 新增商品Data
  productList: [], // 给后台处理数据的数组 -- 新增编辑
  deleteList: [] // 给后台处理数据的数组 -- 删除
}
const intoOrderInfoDefault = {
  cargoDamageList: [],
  cargoDamageNum: null, // 货损数量
  enterWarehouseNumber: '',
  intactNum: null, // 完好数量
  sweepCodeRecordNum: null, // 记录数量
  totalNum: null, // 采购数量
  withoutRecordList: [],
  withoutRecordNum: null // 未记录数量
}
export default {
  directives: {
    // 防止el-button重复点击
    preventReClick: {
      // 指令的定义
      inserted(el, binding) {
        el.addEventListener('click', () => {
          if (!el.disabled) {
            el.disabled = true
            setTimeout(() => {
              el.disabled = false
            }, binding.value || 5000)
          }
        })
      }
    }
  },
  data() {
    return {
      // 权限点
      permission: {
        isEnd: this.checkauthority('purchase_end'), // 终止
        isUpdate: this.checkauthority('purchase_upd'), // 编辑
        isAffirm: this.checkauthority('purchase_confirm_allocate') // 调拨确认
      },
      intoOrderInfo: JSON.parse(JSON.stringify(intoOrderInfoDefault)), // 入库明细
      confirmAllocate: false, // 就显示确认按钮
      remark: '备注信息:', // 文字
      cargoDamageFlag: null, // 是否破损
      logList: [], // 操作日志
      showRedactFlag: true, // 打开编辑
      purchaseNo: '', // 采购单号
      createdTimestamp: '', // 创建时间
      status: '', // 采购状态
      onHoverText: '', // 鼠标以上显示文字
      purchaseTypesData: [], // 采购类型Data
      channelsData: [], // 渠道Data
      warehouseIdsData: [], // 仓库Data
      sourceWarehouseIdsData: [], // 来源仓库Data
      matchData: [], // 比较数组
      Logistics: [], // 物流数组
      sameWarehouseFlagData: [
        // 是否同一地点
        {
          id: 0,
          value: '不同地点(需扫码)'
        },
        {
          id: 1,
          value: '与入库仓库同一地点'
        }
      ],
      payMethodsData: [
        {
          id: '0',
          value: '对公'
        },
        {
          id: '1',
          value: '备用金'
        },
        {
          id: '2',
          value: '备用金代付'
        },
        {
          value: '张总',
          id: '3'
        },
        {
          value: '上海实体店备用金',
          id: '4'
        },
        { id: '5', value: '现金' },
        { id: '6', value: '上海店现金' },
        { id: '7', value: '日本备用金' },
        { id: '8', value: '挂账' },
        { id: '9', value: '日币现金' },
        { id: '10', value: '法人信用卡' },
        { id: '11', value: '员工垫付' },
        { id: '12', value: '公司转账' },
        { id: '13', value: '人民币转账' }
      ], // 付款方式
      deliveryMethodsData: [
        {
          id: '0',
          value: '入库'
        },
        {
          id: '1',
          value: '代发'
        }
      ], // 发货方式 Data
      redactData: JSON.parse(JSON.stringify(redactDataDefault)), // 新增Data
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL, // downloadUrl
      judgment: '', // 标题
      dialogFormVisiblePic: false, // 弹框开启
      dialogFormVisibleLogistics: false, // 弹框开启
      dialogImageUrl: '', // 图片路径
      totalNum: 0, // 合计数量
      totalAmount: 0, // 合计金额
      tag1: '补货',
      tag2: '预定',
      tag3: '调拨',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000 + 1
        }
      }
    }
  },
  watch: {
    $route(to, from) {
      this.$route.query.purchaseNo = to.query.purchaseNo
      this.initList()
      this.getPurchaseSource()
      this.getPurchaseChannel()
      this.getPurchaseWarehouse()
      this.getPurchaseSourceWarehouseIds()
    }
  },
  created() {
    this.$store.commit('d2admin/page/modifyOpend', opened => {
      opened.forEach((current) => {
        if (current.name === 'auditDetail') {
          current.meta.title = '审计详情-' + current.query.purchaseNo
        }
      })
    })
    this.initList()
    this.getPurchaseSource()
    this.getPurchaseChannel()
    this.getPurchaseWarehouse()
    this.getPurchaseSourceWarehouseIds()
  },
  // 页面销毁之前
  beforeDestroy() {
    //
  },
  computed: {
    count() {
      let num = 0
      let price = 0
      this.redactData.itemAddData.forEach(e => {
        const unitPrice = Number(e.unitPrice) * 100
        num += e.num ? Number(e.num) : null
        price += unitPrice * e.num ? Number(unitPrice) * Number(e.num) : null
      })
      return {
        totalNum: num,
        totalAmount: price
      }
    }
  },
  methods: {
    ...mapActions('d2admin/page', ['close']),
    // 返回样式
    returnSty() {
      if (this.redactData.purchaseType !== this.tag3) {
        return this.redactData.deliveryMethod === '1'
      }
    },
    // 获取采购类型
    async getPurchaseSource() {
      const res = await api.getPurchaseSelect({
        name: 'source'
      })
      const filterName = [49]
      this.purchaseTypesData =
        res.body.filter((item, index) => !filterName.includes(item.id))
    },
    // 获取采购渠道
    async getPurchaseChannel() {
      const res = await api.getPurchaseSelect({
        name: 'channel'
      })
      this.channelsData = res.body
    },
    // 获取仓库
    async getPurchaseWarehouse() {
      const res = await api.getPurchaseWarehouse()
      this.warehouseIdsData = res.body
    },
    // 获取来源仓库
    async getPurchaseSourceWarehouseIds() {
      const res = await api.getPurchaseSourceWarehouseIds()
      this.sourceWarehouseIdsData = res.body
    },
    // 获取仓库地址
    getWarehouseAddress(val) {
      console.log(val)
      this.sourceWarehouseIdsData.forEach(e => {
        if (val === e.warehouseId) {
          this.redactData.deliveryAddress = e.warehouseAddress
        }
      })
    },
    // 鼠标以上显示完整名称
    onMouseOver(item) {
      this.onHoverText = item
    },
    // 获取数据
    async initList() {
      this.loadingData = true
      try {
        const res = await api.info_purchase({
          purchaseNo: this.$route.query.purchaseNo
        })
        this.redactData.purchaseType = res.body.purchaseType
        this.redactData.channel = res.body.channel
        this.redactData.payMethod = res.body.payMethod
        this.redactData.predictArriveDate = res.body.predictArriveDate
        this.redactData.deliveryMethod = res.body.deliveryMethod
        this.redactData.deliveryUserName = res.body.deliveryUserName
        this.redactData.deliveryPhone = res.body.deliveryPhone
        this.redactData.warehouseId = res.body.warehouseId
        this.redactData.sourceWarehouseId = res.body.sourceWarehouseId
        this.redactData.purchaseNo = res.body.purchaseNo
        this.redactData.sameWarehouseFlag = res.body.sameWarehouseFlag
        this.redactData.deliveryAddress = res.body.deliveryAddress
        this.redactData.courierCompany = res.body.courierCompany
        this.redactData.distribution = res.body.distribution
        this.redactData.trackingNumber = res.body.trackingNumber
        this.redactData.remark = res.body.remark
        this.redactData.itemAddData = res.body.productList
        this.cargoDamageFlag = res.body.cargoDamageFlag
        this.logList = res.body.logList
        this.intoOrderInfo = res.body.intoOrderInfo
        this.purchaseNo = res.body.purchaseNo
        this.status = res.body.status
        this.confirmAllocate = res.body.confirmAllocate
        this.createdTimestamp = res.body.createdTimestamp
        this.redactData.auditStatus = res.body.auditStatus
        this.redactData.refundReason = res.body.refundReason
        let marchArray = []
        marchArray = JSON.parse(JSON.stringify(res.body.productList))
        marchArray.map(e => {
          this.matchData.push(e)
        })
        if (res.body.backProve === '' || res.body.backProve === null) {
          this.redactData.backProve = []
        } else {
          this.redactData.backProve = (res.body.backProve || '')
            .split(',')
            .map(item => {
              return {
                name: item,
                url: item
              }
            })
        }
        if (res.body.payProve === '' || res.body.payProve === null) {
          this.redactData.payProve = []
        } else {
          this.redactData.payProve = (res.body.payProve || '')
            .split(',')
            .map(item => {
              return {
                name: item,
                url: item
              }
            })
        }
        if (res.body.finalPaymentProve === '' || res.body.finalPaymentProve === null) {
          this.redactData.finalPaymentProve = []
        } else {
          this.redactData.finalPaymentProve = (res.body.finalPaymentProve || '')
            .split(',')
            .map(item => {
              return {
                name: item,
                url: item
              }
            })
        }
      } catch (e) {
        this.message({
          type: 'error',
          message: '获取数据失败'
        })
      } finally {
        this.loadingData = false
      }
    },
    // 图片放大开启
    getPreview(payProve) {
      this.judgment = '放大图片'
      this.dialogFormVisiblePic = true
      this.dialogImageUrl = payProve
    },
    // 图片放大关闭
    closeDialogPic() {
      this.dialogFormVisiblePic = false
      this.dialogImageUrl = ''
    },
    // 关闭页面
    resetForm() {
      this.redactData = JSON.parse(JSON.stringify(redactDataDefault))
      this.$refs.redactData.clearValidate()
      const tagName = '/auditDetail?purchaseNo=' + this.$route.query.purchaseNo
      this.$router.push({ path: '/audit' })
      setTimeout(() => this.close({ tagName: tagName }), 10)
    },
    // 查看物流
    async openLogistics() {
      if (this.redactData.trackingNumber === '') {
        console.log('1')
        this.$message({
          type: 'warning',
          message: '请填写物流单号!'
        })
      } else {
        const res = await api.getLogistics({
          // logisticsNumber: "JD0086304241079"
          logisticsNumber: this.redactData.trackingNumber
        })
        if (res.body === null || res.body == []) {
          this.$message({
            type: 'info',
            message: '暂无物流信息，请稍后再试'
          })
        } else {
          this.Logistics = res.body
          this.dialogFormVisibleLogistics = true
        }
      }
    },
    // 关闭查看
    closeDialogLogistics() {
      this.dialogFormVisibleLogistics = false
      this.Logistics = []
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  margin-left: 9px;
  margin-right: 9px;
  box-sizing: border-box;
  border: 2px solid #888;
  border-radius: 10px;
  min-height: 100%;
  width: 99%;
}

.searchBgc1 {
  height: 30px;
  line-height: 30px;
  background-color: #dfe6ec;
  padding-left: 10px;
  font-weight: bolder;
  border-radius: 10px 10px 0px 0px;
}

.searchBgc2 {
  height: 30px;
  line-height: 30px;
  background-color: #dfe6ec;
  padding-left: 10px;
  font-weight: bolder;
}

.searchBgc3 {
  height: 30px;
  line-height: 30px;
  border-radius: 10px 10px 0 0;
  background-color: #dfe6ec;
  padding-left: 10px;
  font-weight: bolder;
}

.ruleSty {
  width: 100%;
}

.textSty {
  display: flex;
  justify-content: right;
  margin-right: 5%;
  color: rgb(102, 102, 102);
  font-weight: bolder;
  margin-bottom: 20px;
}

.spanSty {
  width: 5%;
  height: 17px;
  line-height: 17px;
  color: #f60;
}

.div1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-right: 20px;
  flex-grow: transition 1;
}

.div2 {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  flex-grow: transition 1;
}

.div3 {
  display: inline-block;
}

.div4 {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  flex-grow: transition 1;
}

::v-deep .itemAddSty .el-form-item__content {
  margin-left: 0px !important;
  padding: 0px 10px 0px;
}

::v-deep .itemAddSty .el-form-item__error {
  // margin-left: 48.2%;
  position: relative;
  top: 0;
  left: 0;
}
</style>
