<template>
  <d2-container>
    <el-tabs type="card" @tab-click="handleClick">
      <el-tab-pane v-for="item in fileTabDatas" :key="item.id" :label="item.values" :name="item.keys">
        <div style="width:100%;">
          <div class="imageDiv" v-for="tableData in fileTableDatas" :key="tableData.id">
            <el-image class="image" :src="getImage(tableData)"></el-image>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </d2-container>
</template>
<script>

import api from '@/api'
export default {
  name: 'FileList2',
  components: { },
  data () {
    return {
      pageNo: 1,
      pageSize: 50,
      pageTotal: 0,
      uploadUrl: process.env.VUE_APP_DOWNLOAD_URL,
      fileTabDatas: [],
      fileTableDatas: []
    }
  },
  methods: {
    getImage (row) {
      var url = row.url
      return this.uploadUrl + url
    },
    handleClick (tab, event) {
      this.pageNo = 1
      this.initTableData(tab.name)
    },
    async getEnumList (name) {
      var par = {
        name: name
      }
      var res = await api.GET_ENUMERATION_LIST(par)
      this.fileTabDatas = res.body
    },
    initDatas () {
      this.getEnumList('file_type')
    },
    async initTableData (type) {
      var par = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        type: type
      }
      const res = await api.GET_FILE_PAGE_LIST(par)
      this.fileTableDatas = res.body.datas
      this.pageTotal = res.body.sumDataCount
    }
  },
  created () {
    this.initDatas()
  }
}
</script>
<style>
  .imageDiv {
    width: 22%;
    float: left;
    margin: 1%;
    height: 130px;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    border-color: #bebebe;
  }
  .image {
    height: 130px;
  }
</style>
