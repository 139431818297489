export default (function (_ref) {
  var request = _ref.request;
  return {
    // 分页查询
    getPageList_brand: function getPageList_brand() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/brand/getPageList',
        method: 'post',
        data: data
      });
    },
    // 新增
    insertRow_brand: function insertRow_brand() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/brand/insertRow',
        method: 'post',
        data: data
      });
    },
    // 编辑
    updateRow_brand: function updateRow_brand() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/brand/updateRow',
        method: 'put',
        data: data
      });
    },
    // 删除
    deleteRow_brand: function deleteRow_brand() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/brand/deleteRow',
        method: 'delete',
        data: data
      });
    }
  };
});