<template>
  <!-- 如果内容比较多可以添加top属性 -->
  <el-dialog
  :append-to-body="true"
  :close-on-click-modal="false"
    top="3%"
    :title="title"
    :visible.sync="dialogVisible"
    width="70%"
    :before-close="handleClose">
    <el-form ref="form" status-icon :model="form" :rules="rules" label-width="120px">
    <el-container>
        <el-aside>
          <el-form-item label="创建时间" prop="createTime">
            <el-input :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.createTime" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="方法" prop="method">
            <el-input :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.method" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="名称标识" prop="name">
            <el-input :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="操作类型" prop="operationType">
            <el-input :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.operationType" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="操作人Id" prop="operatorId">
            <el-input :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.operatorId" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="操作人账号" prop="operatorName">
            <el-input :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.operatorName" autocomplete="off"></el-input>
          </el-form-item>
        </el-aside>
        <el-main style="margin-left: 50px; padding:0px;">
          <el-form-item label="执行时间" prop="runTime">
            <el-input :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.runTime" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="服务名称" prop="serviceName">
            <el-input :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.serviceName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="请求地址" prop="url">
            <el-input :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.url" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="请求参数" prop="requestBody">
            <el-input type="textarea" :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.requestBody" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="返回参数" prop="responseBody">
            <el-input type="textarea" :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.responseBody" autocomplete="off"></el-input>
          </el-form-item>
        </el-main>
    </el-container>
    </el-form>
    <div class="bottomDiv">
      <el-button @click="handleClose">关闭</el-button>
      <el-button :disabled="disabled" type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  name: 'LogEdit',
  data () {
    return {
      title: '',
      form: {
        createTime: null,
        method: '',
        name: '',
        operationType: '',
        operatorId: null,
        operatorName: '',
        requestBody: '',
        responseBody: '',
        runTime: null,
        serviceName: '',
        stats: 0,
        url: '',
        logStats: [],
        logStatsTypes: []
      },
      rules: {
        createTime: [
          { required: true, message: '请输入创建时间', trigger: 'blur' }
        ],
        method: [
          { required: true, message: '请输入方法', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入名称标识', trigger: 'blur' }
        ],
        operationType: [
          { required: true, message: '请输入操作类型', trigger: 'blur' }
        ],
        operatorId: [
          { required: true, message: '请输入操作人Id', trigger: 'blur' }
        ],
        operatorName: [
          { required: true, message: '请输入操作人账号', trigger: 'blur' }
        ],
        requestBody: [
          { required: true, message: '请输入请求体', trigger: 'blur' }
        ],
        responseBody: [
          { required: true, message: '请输入返回体', trigger: 'blur' }
        ],
        runTime: [
          { required: true, message: '请输入执行时间', trigger: 'blur' }
        ],
        serviceName: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        stats: [
          { required: true, message: '请输入0 成功 1失败', trigger: 'blur' }
        ],
        url: [
          { required: true, message: '请输入请求地址', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    checkStats (value) {
      for (var res of this.logStats) {
        var val = parseInt(res.keys)
        if (val === value) {
          return res.values
        }
      }
    },
    checkStatsTypes (value) {
      for (var res of this.logStatsTypes) {
        var val = parseInt(res.keys)
        if (val === value) {
          return res.values
        }
      }
    },
    initEunmData () {
      this.getLogStatsEnumList('log_stats')
      this.getLogStatsTypeEnumList('log_stats_type')
    },
    async getLogStatsEnumList (name) {
      var par = {
        name: name
      }
      var res = await api.GET_ENUMERATION_LIST(par)
      this.logStats = res.body
    },
    async getLogStatsTypeEnumList (name) {
      var par = {
        name: name
      }
      var res = await api.GET_ENUMERATION_LIST(par)
      this.logStatsTypes = res.body
    },
    setTitle (title) {
      this.title = title
    },
    initData () {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields()
      }
      this.form.createTime = null
      this.form.method = null
      this.form.name = null
      this.form.operationType = null
      this.form.operatorId = null
      this.form.operatorName = null
      this.form.requestBody = null
      this.form.responseBody = null
      this.form.runTime = null
      this.form.serviceName = null
      this.form.stats = null
      this.form.url = null
    },
    setData (data) {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields()
      }
      this.form.createTime = data.createTime
      this.form.method = data.method
      this.form.name = data.name
      this.form.operationType = data.operationType
      this.form.operatorId = data.operatorId
      this.form.operatorName = data.operatorName
      this.form.requestBody = data.requestBody
      this.form.responseBody = data.responseBody
      this.form.runTime = data.runTime
      this.form.serviceName = data.serviceName
      this.form.stats = data.stats
      this.form.url = data.url
    },
    async updateApi () {
      await api.UPDATE_LOG(this.form)
      this.$emit('initTableData')
      this.$message.success('操作成功')
      this.handleClose()
    },
    async saveApi () {
      await api.SAVE_LOG(this.form)
      this.$emit('initTableData')
      this.$message.success('操作成功')
      this.handleClose()
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id != null) {
            this.$confirm('此操作将永久修改该数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.updateApi()
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消修改'
              })
            })
          } else {
            this.saveApi()
          }
        } else {
          return false
        }
      })
    },
    handleClose (done) {
      this.$emit('close')
    }
  },
  created () {
    this.initEunmData()
  }
}
</script>

<style>
  .inputText {
    width: 70%;
  }
  .bottomDiv {
    width: 100%;
    text-align: center;
  }
</style>
