<template>
  <div class="damagefreightList">
    <d2-container>
      <head-search label-style="width:100px">
        <head-search-item label="商品条码">
          <el-input :disabled="auth.isSearch" size="small" @keyup.enter.native="search" v-model="selectForm.purchaseNo"
            clearable placeholder="请输商品条码"></el-input>
        </head-search-item>
        <head-search-item label="商品名称">
          <el-input :disabled="auth.isSearch" size="small" @keyup.enter.native="search" v-model="selectForm.purchaseNo"
            clearable placeholder="请输商品条码"></el-input>
        </head-search-item>
        <head-search-item label="单号">
          <el-input :disabled="auth.isSearch" size="small" @keyup.enter.native="search" v-model="selectForm.purchaseNo"
            clearable placeholder="请输单号"></el-input>
        </head-search-item>
        <head-search-item label="仓库名称">
          <el-select :disabled="auth.isSearch" size="small" v-model="selectForm.warehouseId" placeholder="请选择仓库名称"
            @change="changeWarehouse" clearable filterable>
            <el-option v-for="item in list.warehouse" :key="item.warehouseId" :label="item.warehouseName"
              :value="item.warehouseId"></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="区域名称">
          <el-select :disabled="!selectForm.warehouseId" size="small" v-model="selectForm.areaId" placeholder="请选择区域名称"
            clearable filterable @change="changeArea">
            <el-option v-for="item in list.area" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="货架名称">
          <el-select :disabled="selectForm.areaId" collapse-tags size="small" multiple v-model="selectForm.shelfId"
            placeholder="请选择货架名称" clearable filterable>
            <el-option v-for="item in list.shelf" :key="item.id" :label="item.keys" :value="item.id"></el-option>
          </el-select>
        </head-search-item>
        <!-- <head-search-item label="货损来源">
          <el-select
            :disabled="auth.isSearch"
            size="small"
            v-model="selectForm.orgId"
            placeholder="请选择货损来源"
            clearable
            filterable
          >
            <el-option
              v-for="item in list.org"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="货损类型">
          <el-select
            collapse-tags
            size="small"
            multiple
            v-model="selectForm.deptId"
            placeholder="请选择货损类型"
            clearable
            filterable
          >
            <el-option
              v-for="item in list.partList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </head-search-item> -->
        <template slot="button">
          <el-button :disabled="auth.isSearch" icon="el-icon-search" type="primary" @click="search" size="small">
            搜索
          </el-button>
          <el-button :disabled="auth.isSearch" icon="el-icon-refresh" type="warning" @click="reset" size="small">
            重置
          </el-button>
        </template>
      </head-search>
      <div class="table">
        <el-table ref="tableData" v-loading="tableLoading" :data="auaditList" max-height="620px"
          element-loading-text="数据加载中" :cell-style="{ textAlign: 'center' }" tooltip-effect="dark"
          :row-style="{ height: '57px' }" :header-cell-style="{
            background: '#dfe6ec',
            color: '#666666',
            textAlign: 'center'
          }">
          <el-table-column label="序号" width="80" align="center">
            <template slot-scope="scope">
              {{
            (selectForm.pageNo - 1) * selectForm.pageSize + scope.$index + 1
          }}
            </template>
          </el-table-column>
          <el-table-column prop="enterWarehouseNumber" label="商品条码" width="150"></el-table-column>
          <el-table-column prop="enterWarehouseState" label="商品名称" width="150"></el-table-column>
          <el-table-column prop="commodityIntoSum" label="商品编码" width="150"></el-table-column>
          <el-table-column prop="enterWarehouseType" label="入库价（元）" width="150"></el-table-column>
          <el-table-column prop="warehouseName" label="仓库名称" width="150"></el-table-column>
          <el-table-column prop="warehouseName" label="区域名称" width="150"></el-table-column>
          <el-table-column prop="warehouseName" label="货架名称" width="150"></el-table-column>
          <!-- <el-table-column
            prop="warehouseName"
            label="货损图片"
            width="150"
          ></el-table-column> -->
          <!-- <el-table-column
            prop="warehouseName"
            label="货损来源"
            width="150"
          ></el-table-column> -->
          <el-table-column prop="warehouseName" label="单号" width="150"></el-table-column>
          <!-- <el-table-column
            prop="warehouseName"
            label="货损类型"
            width="150"
          ></el-table-column> -->
          <el-table-column prop="warehouseName" label="备注" width="150"></el-table-column>
          <el-table-column prop="warehouseName" label="提交人员" width="150"></el-table-column>
          <el-table-column prop="enterWarehouseTimestamp" label="提交时间" width="150"></el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="selectForm.pageNo"
          :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="selectForm.pageSize"
          layout="->,total, sizes, prev, pager, next, jumper" :total="pageTotal"></el-pagination>
      </div>
    </d2-container>
  </div>
</template>
<script>
import api from '@/api'
const selectFormData = {
  pageNo: 1,
  pageSize: 10,
  enterWarehouseNumber: '',
  enterWarehouseState: '',
  enterWarehouseType: '',
  warehouseName: '',
  deliveryMethod: '',
  intoTimeStamp: [],
  remark: '',
  deptId: '',
  orgId: ''
}
export default {
  data() {
    return {
      auaditList: [],
      tableLoading: false,
      auth: {},
      selectForm: JSON.parse(JSON.stringify(selectFormData)),
      pageTotal: 0,
      list: {
        channel: [],
        source: [],
        org: [],
        partList: [],
        payWay: [
          { id: '0', value: '对公' },
          { id: '1', value: '备用金' },
          { id: '2', value: '备用金代付' },
          { id: '3', value: '张总' },
          { id: '4', value: '上海实体店备用金' },
          { id: '5', value: '现金' },
          { id: '6', value: '上海店现金' },
          { id: '7', value: '日本备用金' },
          { id: '8', value: '挂账' },
          { id: '9', value: '日币现金' },
          { id: '10', value: '法人信用卡' },
          { id: '11', value: '员工垫付' },
          { id: '12', value: '公司转账' },
          { id: '13', value: '人民币转账' }
        ],
        outWay: [
          { id: '0', value: '入库' },
          { id: '1', value: '代发' }
        ],
        // 采购状态
        purchaseState: [
          { id: '0', value: '待采购' },
          { id: '1', value: '采购中' },
          { id: '2', value: '已完成' },
          { id: '9', value: '已终止' }
        ]
      }
    }
  },
  methods: {
    async getWarehouse() {
      const { body: data } = await api.getPurchaseWarehouse()
      this.list.warehouse = data
    },
    async search() {
      const { body: data } = await api.getPageList_auadit(this.selectForm)
      this.auaditList = data.list
      this.pageTotal = data.total
    },
    async changeWarehouse(val) {
      if (this.selectForm.warehouseId) {
        const { body: data } = await api.warehouseStructure_searchArea({ warehouseId: this.selectForm.warehouseId })
        this.list.area = data
        this.$forceUpdate()
      } else {
        this.selectForm.areaId = ''
        this.selectForm.shelfId = ''
      }
    },
    async changeArea() {
      console.log(this.selectForm)
      if (this.selectForm.areaId) {
        const { body: data } = await api.entrepot_selectShelves({ areaId: this.selectForm.areaId })
        this.list.shelf = data
        this.$forceUpdate()
      }
    },
    reset() {
      this.selectForm = JSON.parse(JSON.stringify(selectFormData))
      this.search()
    },
    sizeChange(val) {
      this.selectForm.pageSize = val
      this.search()
    },
    currentChange(val) {
      this.selectForm.pageNo = val
      this.search()
    }
  },
  created() {
    this.getWarehouse()
    // this.search()
  }
}
</script>
<style lang="scss" scoped>
.table {
  margin-top: 10px;
}
</style>
