export default (function (_ref) {
  var request = _ref.request;
  return {
    // 根据部门id查询仓库
    getDepartmentToWarehouse: function getDepartmentToWarehouse() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'v1/warehouse/selectToDept',
        method: 'post',
        data: query
      });
    },
    // 根据仓库查询区域(总经办)
    departmentGetChooseArea: function departmentGetChooseArea() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/chooseArea',
        method: 'post',
        data: query
      });
    },
    // 根据区域查询货架(总经办)
    departmentGetChooseShelves: function departmentGetChooseShelves() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/warehouse/chooseShelves',
        method: 'post',
        data: query
      });
    },
    // 获取调拨商品列表
    get_transferGoods: function get_transferGoods() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/transferGoods/getList',
        method: 'post',
        data: query
      });
    },
    // 分页查询 --- 总经办
    getPageList_allotApply: function getPageList_allotApply() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'v1/transferSlip/getPageList',
        method: 'post',
        data: query
      });
    },
    // 分页查询 --- 部门用户
    getPageList_auditTransfer: function getPageList_auditTransfer() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/auditTransfer/getPageList',
        method: 'post',
        data: query
      });
    },
    // 调拨新增保存
    save_allotApply: function save_allotApply() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/transferSlip/save',
        method: 'post',
        data: query
      });
    },
    // 调拨编辑保存
    update_allotApply: function update_allotApply() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/transferSlip/update',
        method: 'post',
        data: query
      });
    },
    // 调拨商品删除
    delete_allotApply: function delete_allotApply() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/transferGoods/delete',
        method: 'post',
        data: query
      });
    },
    // 调拨查询详情
    info_allotApply: function info_allotApply() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/transferSlip/info',
        method: 'post',
        data: params
      });
    },
    // 调拨审核确认信息
    makeSure_allotApply: function makeSure_allotApply() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/transferSlip/chooseArea',
        method: 'post',
        data: params
      });
    },
    // 终止调拨
    stop_allotApply: function stop_allotApply() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/transferSlip/stop',
        method: 'post',
        data: query
      });
    },
    // 审核同意
    check_allotApply: function check_allotApply() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/transferSlip/audit',
        method: 'post',
        data: query
      });
    }
  };
});