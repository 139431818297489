export default ({ request }) => ({
  // 获取采购商品列表
  getPurchaseSummaryInfoList(data = {}) {
    return request({
      url: '/purchase/statical/getGoodsList',
      method: 'post',
      data: data
    })
  },
  // 获取采购商品统计
  getPurchaseSummaryInfoCount(data = {}) {
    return request({
      url: '/purchase/statical/getPurNewGoodsResult',
      method: 'post',
      data: data
    })
  },

})
