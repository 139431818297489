<template>
  <div class="damagefreightType">
    <d2-container>
      <head-search label-style="width:100px">
        <template slot="button">
          <el-button
            :disabled="auth.isSearch"
            icon="el-icon-search"
            type="primary"
            @click="add"
            size="small"
          >
            新增
          </el-button>
        </template>
      </head-search>
      <div class="table">
        <el-table
          ref="tableData"
          v-loading="tableLoading"
          :data="typeList"
          max-height="620px"
          element-loading-text="数据加载中"
          :cell-style="{ textAlign: 'center' }"
          tooltip-effect="dark"
          :row-style="{ height: '57px' }"
          :header-cell-style="{
            background: '#dfe6ec',
            color: '#666666',
            textAlign: 'center'
          }"
        >
          <el-table-column label="序号" width="80" align="center">
            <template slot-scope="scope">
              {{
                (selectForm.pageNo - 1) * selectForm.pageSize + scope.$index + 1
              }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="type"
            label="货损类型"
            width="150"
          ></el-table-column> -->
          <el-table-column
            prop="type"
            label="创建时间"
            width="150"
          ></el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                :disabled="auth.isSearch"
                type="primary"
                plain
                size="small"
                @click="edit(scope.row)"
              >
                编辑
              </el-button>
              <el-button
                plain
                :disabled="auth.isSearch"
                type="danger"
                size="small"
                @click="del(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="selectForm.pageNo"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="selectForm.pageSize"
          layout="->,total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        ></el-pagination>
        <el-dialog
          :title="title"
          :visible="visible"
          @close="close"
          width="500px"
        >
          <el-form
            :model="form"
            ref="form"
            :rules="rules"
            label-width="80px"
            :inline="false"
            size="normal"
          >
            <el-form-item label="货损类型" prop="type">
              <el-input
                size="small"
                v-model="form.type"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="mini" @click="onSubmit">
                确认
              </el-button>
              <el-button size="mini" @click="close">取消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </div>
    </d2-container>
  </div>
</template>
<script>
import api from '@/api'
export default {
  data () {
    return {
      typeList: [1],
      tableLoading: false,
      pageTotal: 0,
      selectForm: {
        pageNo: 1,
        pageSize: 10
      },
      visible: false,
      form: {
        ok: '',
        remark: ''
      },
      rules: {
        type: [{ required: true, message: '请输入货损类型', trigger: 'blur' }]
      },
      auth: {},
      title: '新增'
    }
  },
  methods: {
    async getList () {
      const { body: data } = await api.getPageList_damagefreightType(
        this.selectForm
      )
      this.typeList = data.list
      this.pageTotal = data.total
    },
    add () {
      this.title = '新增'
      this.visible = true
    },
    del (row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await api.delete_damagefreightType({ id: row.id })
          this.$message.success('删除成功')
          this.getList()
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    edit () {
      this.title = '编辑'
      this.visible = true
    },
    close () {
      this.$refs.form.resetFields()
      this.visible = false
    },
    sizeChange (val) {
      this.selectForm.pageSize = val
      this.getList()
    },
    currentChange (val) {
      this.selectForm.pageNo = val
      this.getList()
    },
    onSubmit () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.title === '新增') {
            await api.save_damagefreightType(this.form)
          } else {
            await api.update_damagefreightType(this.form)
          }
          this.$message.success('操作成功')
          this.close()
          this.getList()
        }
      })
    },
    created () {
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  margin-top: 10px;
}
</style>
