var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        top: "10",
        title: _vm.title,
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "角色权限", name: "0" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "status-icon": "",
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "80px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "90%" },
                      attrs: { label: "角色名称", prop: "name" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "inputText",
                        attrs: {
                          size: "small",
                          clearable: "",
                          disabled: _vm.disabled
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.save()
                          }
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "90%" },
                      attrs: { label: "备注", prop: "remark" }
                    },
                    [
                      !_vm.disabled
                        ? _c("el-input", {
                            staticClass: "inputText",
                            attrs: { size: "small", clearable: "" },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.save()
                              }
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(_vm.form.remark))])
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "90%" },
                      attrs: { label: "app端登录", prop: "appFlag" }
                    },
                    [
                      _c("el-switch", {
                        attrs: {
                          disabled: _vm.title == "详情",
                          "active-value": 1,
                          "inactive-value": 0
                        },
                        model: {
                          value: _vm.form.appFlag,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "appFlag", $$v)
                          },
                          expression: "form.appFlag"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "权限分配", prop: "roleMenus" } },
                    [
                      _c("el-tree", {
                        ref: "tree",
                        attrs: {
                          "render-content": _vm.renderContent,
                          "default-expand-all": "",
                          data: _vm.menuList,
                          "show-checkbox": "",
                          "default-checked-keys": _vm.defaultCheckList,
                          "node-key": "id"
                        },
                        on: { check: _vm.selectMenu }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "bottomDiv" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.handleClose }
                    },
                    [_vm._v("关闭")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        disabled: _vm.disabled,
                        type: "primary"
                      },
                      on: { click: _vm.save }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.title === "详情"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "角色用户", name: "1" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableData }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { align: "center", type: "index", label: "序号" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "userName",
                          label: "用户名"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", prop: "name", label: "姓名" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "orgName",
                          label: "所属公司"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "deptName",
                          label: "所属部门"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "createdName",
                          label: "创建人"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "createdTimestamp",
                          label: "创建时间",
                          width: "250px"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "pager-div" })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }