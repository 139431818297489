export default (function (_ref) {
  var request = _ref.request;
  return {
    // 每日代发
    generationGetPage: function generationGetPage() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/issued/getLimit',
        method: 'post',
        data: data
      });
    },
    // 每日代发聚合统计
    generationGetAgg: function generationGetAgg() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/issued/getAgg',
        method: 'post',
        data: data
      });
    },
    // 每日代发 - 商品
    generationGoodsLimit: function generationGoodsLimit() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/issued/goodsLimit',
        method: 'post',
        data: data
      });
    },
    // 每日代发 - 商品聚合统计
    generationAggGoods: function generationAggGoods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/issued/aggGoods',
        method: 'post',
        data: data
      });
    },
    // 商品导出
    generationExportGoods: function generationExportGoods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/issued/exprotGoods',
        method: 'post',
        data: data
      });
    },
    // 每日代发 - 订单
    generationOrderLimit: function generationOrderLimit() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/issued/orderLimit',
        method: 'post',
        data: data
      });
    },
    // 每日代发 - 订单聚合统计
    generationAggOrder: function generationAggOrder() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/issued/aggOrder',
        method: 'post',
        data: data
      });
    },
    // 订单导出
    generationExportOrder: function generationExportOrder() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/issued/exportOrder',
        method: 'post',
        data: data
      });
    }
  };
});