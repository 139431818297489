/**
 * <枚举管理请求接口
 * @author 王振宇
 * @since 2021-03-02
 */
export default ({ service, request, serviceForMock, requestForMock, mock, faker, tools }) => ({
  GET_ENUMERATION_PAGE_LIST (data = {}) {
    return request({
      url: '/v1/enumeration/getPageList',
      method: 'post',
      params: data
    })
  },
  GET_ENUMERATION_LIST (data = {}) {
    return request({
      url: '/v1/enumeration/getList',
      method: 'post',
      data: data
    })
  },
  GET_ENUMERATION_INFO () {
    return request({
      url: '/v1/enumeration/info',
      method: 'get'
    })
  },
  SAVE_ENUMERATION (data = {}) {
    return request({
      url: '/v1/enumeration/save',
      method: 'post',
      data: data
    })
  },
  UPDATE_ENUMERATION (data = {}) {
    return request({
      url: '/v1/enumeration/update',
      method: 'put',
      data: data
    })
  },
  DELETE_ENUMERATION (data = {}) {
    return request({
      url: '/v1/enumeration/delete',
      method: 'delete',
      data: data
    })
  },
  DELETE_BATCH_ENUMERATION (data = {}) {
    return request({
      url: '/v1/enumeration/deleteBatch',
      method: 'delete',
      data: data
    })
  }
})
