var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Inventorylist" },
    [
      _c(
        "d2-container",
        [
          _c(
            "head-search",
            { attrs: { "label-style": "width:100px" } },
            [
              _c(
                "head-search-item",
                { attrs: { label: "入库单号" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.auth.isSearch,
                      size: "small",
                      clearable: "",
                      placeholder: "请输入库单号"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.search($event)
                      }
                    },
                    model: {
                      value: _vm.selectForm.enterWarehouseNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.selectForm, "enterWarehouseNumber", $$v)
                      },
                      expression: "selectForm.enterWarehouseNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.auth.isSearch,
                      size: "small",
                      clearable: "",
                      placeholder: "请输入商品名称"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.search($event)
                      }
                    },
                    model: {
                      value: _vm.selectForm.goodsName,
                      callback: function($$v) {
                        _vm.$set(_vm.selectForm, "goodsName", $$v)
                      },
                      expression: "selectForm.goodsName"
                    }
                  })
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "入库状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.auth.isSearch,
                        "collapse-tags": "",
                        size: "small",
                        multiple: "",
                        placeholder: "请选择入库状态",
                        clearable: "",
                        filterable: ""
                      },
                      model: {
                        value: _vm.selectForm.enterWarehouseStates,
                        callback: function($$v) {
                          _vm.$set(_vm.selectForm, "enterWarehouseStates", $$v)
                        },
                        expression: "selectForm.enterWarehouseStates"
                      }
                    },
                    _vm._l(_vm.list.WarehousingStatus, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "入库类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.auth.isSearch,
                        "collapse-tags": "",
                        size: "small",
                        multiple: "",
                        placeholder: "请选择入库类型",
                        clearable: "",
                        filterable: ""
                      },
                      model: {
                        value: _vm.selectForm.enterWarehouseTypes,
                        callback: function($$v) {
                          _vm.$set(_vm.selectForm, "enterWarehouseTypes", $$v)
                        },
                        expression: "selectForm.enterWarehouseTypes"
                      }
                    },
                    _vm._l(_vm.list.WarehousingType, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "入库仓库" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.auth.isSearch,
                        "collapse-tags": "",
                        size: "small",
                        multiple: "",
                        placeholder: "请选择入库仓库",
                        clearable: "",
                        filterable: ""
                      },
                      model: {
                        value: _vm.selectForm.warehouseIds,
                        callback: function($$v) {
                          _vm.$set(_vm.selectForm, "warehouseIds", $$v)
                        },
                        expression: "selectForm.warehouseIds"
                      }
                    },
                    _vm._l(_vm.list.WarehousingWarehouse, function(item) {
                      return _c("el-option", {
                        key: item.warehouseId,
                        attrs: {
                          label: item.warehouseName,
                          value: item.warehouseId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "发货方式" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.auth.isSearch,
                        "collapse-tags": "",
                        size: "small",
                        multiple: "",
                        placeholder: "请选择发货方式",
                        clearable: "",
                        filterable: ""
                      },
                      model: {
                        value: _vm.selectForm.deliveryMethods,
                        callback: function($$v) {
                          _vm.$set(_vm.selectForm, "deliveryMethods", $$v)
                        },
                        expression: "selectForm.deliveryMethods"
                      }
                    },
                    _vm._l(_vm.list.WarehousingShipments, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "入库日期" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      disabled: _vm.auth.isSearch,
                      size: "small",
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd HH:mm:ss"
                    },
                    model: {
                      value: _vm.selectForm.intoTimeStamp,
                      callback: function($$v) {
                        _vm.$set(_vm.selectForm, "intoTimeStamp", $$v)
                      },
                      expression: "selectForm.intoTimeStamp"
                    }
                  })
                ],
                1
              ),
              _c(
                "template",
                { slot: "button" },
                [
                  !_vm.auth.isSearch
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            type: "primary",
                            size: "small"
                          },
                          on: { click: _vm.search }
                        },
                        [_vm._v(" 搜索 ")]
                      )
                    : _vm._e(),
                  !_vm.auth.isSearch
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-refresh",
                            type: "warning",
                            size: "small"
                          },
                          on: { click: _vm.reset }
                        },
                        [_vm._v(" 重置 ")]
                      )
                    : _vm._e(),
                  !_vm.auth.isAdd
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-plus-outline",
                            type: "success",
                            size: "small"
                          },
                          on: { click: _vm.handleAdd }
                        },
                        [_vm._v(" 新增入库 ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  ref: "tableData",
                  attrs: {
                    data: _vm.Inventorylist,
                    "max-height": "620px",
                    "element-loading-text": "数据加载中",
                    "cell-style": { textAlign: "center" },
                    "tooltip-effect": "dark",
                    "row-style": { height: "57px" },
                    "header-cell-style": {
                      background: "#dfe6ec",
                      color: "#666666",
                      textAlign: "center"
                    }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", width: "80", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (_vm.selectForm.pageNo - 1) *
                                    _vm.selectForm.pageSize +
                                    scope.$index +
                                    1
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "enterWarehouseNumber",
                      label: "入库单号",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "父订单号", width: "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.fatherId ? scope.row.fatherId : "-"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "enterWarehouseState",
                      formatter: _vm.enterWarehouseStateFormat,
                      label: "入库状态",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "enterWarehouseType",
                      label: "入库类型",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "warehouseName",
                      label: "入库仓库",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "deliveryMethod",
                      label: "发货方式",
                      width: "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.enterWarehouseType === "调拨"
                                    ? "入库"
                                    : scope.row.deliveryMethod
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createdTimestamp",
                      label: "创建时间",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "remark",
                      label: "备注",
                      width: "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.remark ? scope.row.remark : "- -"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "enterWarehouseTimestamp",
                      label: "入库时间",
                      width: "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.enterWarehouseTimestamp
                                    ? scope.row.enterWarehouseTimestamp
                                    : "- -"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "300", fixed: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.enterWarehouseState === 0 &&
                            scope.row.type !== "代发" &&
                            !_vm.auth.isEdit &&
                            !_vm.auth.isEditDetail
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "small",
                                      plain: "",
                                      type: "success",
                                      icon: "el-icon-edit-outline"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleEdit(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" 编辑 ")]
                                )
                              : _vm._e(),
                            !(
                              scope.row.enterWarehouseType === "代发" &&
                              (scope.row.enterWarehouseState === 0 ||
                                scope.row.enterWarehouseState === 1 ||
                                scope.row.enterWarehouseState === 4)
                            ) &&
                            !_vm.auth.isDetail &&
                            !_vm.auth.isGoods
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "small",
                                      type: "info",
                                      plain: "",
                                      icon: "el-icon-info"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleDetails(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" 明细 ")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.enterWarehouseState === 0,
                                    expression:
                                      "scope.row.enterWarehouseState === 0"
                                  }
                                ],
                                attrs: {
                                  size: "small",
                                  plain: "",
                                  type: "primary"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.toStockIn(scope.row)
                                  }
                                }
                              },
                              [_vm._v("开始入库 ")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.selectForm.pageNo,
                  "page-sizes": [10, 20, 30, 40, 50, 100],
                  "page-size": _vm.selectForm.pageSize,
                  layout: "->,total, sizes, prev, pager, next, jumper",
                  total: _vm.pageTotal
                },
                on: {
                  "size-change": _vm.sizeChange,
                  "current-change": _vm.currentChange
                }
              }),
              _c("Dialog", {
                ref: "dialog",
                on: { dialogClose: _vm.dialogClose }
              }),
              _c(
                "el-dialog",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading"
                    }
                  ],
                  attrs: {
                    title: "开始入库",
                    visible: _vm.dialogVisible,
                    width: "80%"
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.dialogVisible = $event
                    },
                    close: _vm.stockInClose
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 2 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                plain: "",
                                type: "primary",
                                size: "small"
                              },
                              on: { click: _vm.createCode }
                            },
                            [_vm._v("生码")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "mb-10", attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 2,
                              placeholder: "请输入位置备注"
                            },
                            model: {
                              value: _vm.remark,
                              callback: function($$v) {
                                _vm.remark = $$v
                              },
                              expression: "remark"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { display: "flex" },
                          attrs: { span: 12 }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                size: "small",
                                type: "primary",
                                plain: ""
                              },
                              on: { click: _vm.fastChooseCache }
                            },
                            [_vm._v("一键选择货架")]
                          ),
                          _c("el-cascader", {
                            staticStyle: { "margin-right": "10px" },
                            attrs: {
                              size: "small",
                              clearable: "",
                              options: _vm.locationDownList,
                              props: {
                                row: null,
                                checkStrictly: true,
                                value: "id",
                                label: "name",
                                children: "children",
                                sourceData: _vm.locationDownList,
                                lazy: true,
                                lazyLoad: _vm.getDetailDownList
                              }
                            },
                            model: {
                              value: _vm.allCache,
                              callback: function($$v) {
                                _vm.allCache = $$v
                              },
                              expression: "allCache"
                            }
                          }),
                          _c("el-input", {
                            staticStyle: { width: "100px" },
                            attrs: {
                              size: "small",
                              clearable: "",
                              type: "primary",
                              plain: ""
                            },
                            model: {
                              value: _vm.allEnterWarehouseShelvesNum,
                              callback: function($$v) {
                                _vm.allEnterWarehouseShelvesNum = $$v
                              },
                              expression: "allEnterWarehouseShelvesNum"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      ref: "dialogTable",
                      attrs: {
                        data: _vm.tableData,
                        border: true,
                        height: "450px"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品编码",
                          prop: "goodsCoding",
                          align: "center",
                          width: "110px"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品名称",
                          prop: "goodsName",
                          align: "center",
                          width: "180px"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品图片",
                          align: "center",
                          width: "230px"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "40px",
                                    height: "40px"
                                  },
                                  attrs: {
                                    src: scope.row.goodsPicture
                                      ? _vm.$oss + scope.row.goodsPicture
                                      : _vm.$oss + scope.row.pictureUrl,
                                    "preview-src-list": [
                                      scope.row.goodsPicture
                                        ? _vm.$oss + scope.row.goodsPicture
                                        : _vm.$oss + scope.row.pictureUrl
                                    ],
                                    fit: "cover"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "采购数量",
                          prop: "enterWarehouseNums",
                          align: "center",
                          width: "110px"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "*入库数量",
                          width: "130px",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    type: "number",
                                    size: "small",
                                    oninput:
                                      "value=value.replace(/[^0-9]/g,'').slice(0,9);"
                                  },
                                  model: {
                                    value: scope.row.num,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "num", $$v)
                                    },
                                    expression: "scope.row.num"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "*位置信息", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-cascader", {
                                  attrs: {
                                    size: "small",
                                    clearable: "",
                                    options: scope.row.locationDownList,
                                    props: {
                                      row: scope.row,
                                      checkStrictly: true,
                                      value: "id",
                                      label: "name",
                                      children: "children",
                                      sourceData: scope.row.locationDownList,
                                      lazy: true,
                                      lazyLoad: _vm.getDetailDownList
                                    }
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.locationChange(
                                        $event,
                                        scope.row
                                      )
                                    }
                                  },
                                  model: {
                                    value: scope.row.location,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "location", $$v)
                                    },
                                    expression: "scope.row.location"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "入库层数", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    type: "number",
                                    size: "small",
                                    disabled: scope.row.location.length < 3,
                                    oninput:
                                      "value=value.replace(/[^0-9]/g,'').slice(0,9)"
                                  },
                                  model: {
                                    value: scope.row.enterWarehouseShelvesNum,
                                    callback: function($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "enterWarehouseShelvesNum",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "scope.row.enterWarehouseShelvesNum"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "放置位置", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    rows: 2,
                                    placeholder: "请输入手动放置位置"
                                  },
                                  model: {
                                    value: scope.row.enterWarehouseRemark,
                                    callback: function($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "enterWarehouseRemark",
                                        $$v
                                      )
                                    },
                                    expression: "scope.row.enterWarehouseRemark"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.tableIndex,
                      "page-sizes": [50, 100, 200, 300, 500],
                      "page-size": _vm.tableSize,
                      layout: "->,total, sizes, prev, pager, next, jumper",
                      total: _vm.tableTotal
                    },
                    on: {
                      "size-change": _vm.dialogSizeChange,
                      "current-change": _vm.dialogCurrentChange
                    }
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogVisible = false
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.dialogSubmit }
                        },
                        [_vm._v("确定")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }