<template>
  <!-- 如果内容比较多可以添加top属性 -->
  <el-dialog
    top="10"
    :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="980px"
    :before-close="handleClose">
    <el-container>
      <el-aside>
        <el-tree
          :data="tableData"
          node-key="id"
          default-expand-all
          :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span>
              <el-button
                :disabled="editDisabled || disabled"
                type="text"
                icon="el-icon-circle-plus-outline"
                @click="() => appendMenus(data)">
              </el-button>
            </span>
          </span>
        </el-tree>
      </el-aside>
      <el-main style="margin-left: 100px;">
        <el-form ref="form" status-icon :model="form" :rules="rules" label-width="80px">
          <el-form-item label="菜单名称" :prop="propMenu.title">
            <el-input  clearable size="small" :disabled="disabled" @keyup.enter.native="save()" style="width:80%;" v-model="form.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="组件名称" :prop="propMenu.name">
            <el-input clearable size="small" :disabled="disabled" @keyup.enter.native="save()" style="width:80%;" v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="显示图标" :prop="propMenu.icon">
            <e-icon-picker :disabled="disabled" v-model="form.icon" style="width:80%;" @keyup.enter.native="save()"/>
          </el-form-item>
          <el-form-item label="组件路径" :prop="propMenu.component">
            <el-input clearable size="small" :disabled="disabled" @keyup.enter.native="save()" style="width:80%;" v-model="form.component" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="访问路径" :prop="propMenu.path">
            <el-input clearable size="small" :disabled="disabled" @keyup.enter.native="save()" style="width:80%;" v-model="form.path" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="权限值" :prop="propMenu.path">
            <el-input clearable size="small" :disabled="disabled" @keyup.enter.native="save()" style="width:80%;" v-model="form.jurisdiction" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="排列顺序" prop="orderNum">
            <el-input clearable size="small" :disabled="disabled" @keyup.enter.native="save()" style="width:80%;" v-model="form.orderNum" autocomplete="off"></el-input>
            <el-tooltip class="item" effect="dark" content="数大的在前面,请输入正整数" placement="top-start">
                <i class="el-icon-warning-outline" style="font-size: 20px;color:red;"></i>
              </el-tooltip>
          </el-form-item>
          <el-form-item label="菜单类型" prop="type">
            <el-radio-group v-model="form.type" @change="typeChange">
              <el-radio
                :disabled="disabled"
                :key="item.keys"
                v-for="item in menuTyes"
                :label="item.keys">
                {{item.values}}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="菜单显示" :prop="propMenu.hidden">
            <el-radio-group v-model="form.hidden">
              <el-radio :disabled="disabled" :label="true">显示</el-radio>
              <el-radio :disabled="disabled" :label="false">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="显示位置" :prop="propMenu.location">
            <el-radio-group v-model="form.location">
              <el-radio :disabled="disabled" label="left">左侧菜单</el-radio>
              <el-radio :disabled="disabled" label="top">顶部菜单</el-radio>
              <el-radio :disabled="disabled" label="all">全部菜单</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
    <div class="bottomDiv">
      <el-button @click="handleClose" size="small">关闭</el-button>
      <el-button :disabled="disabled" size="small" type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  name: 'MenuEdit',
  data () {
    return {
      title: '',
      tableData: [],
      selectMenuId: null,
      editDisabled: false,
      propMenu: {
        title: '',
        name: '',
        icon: '',
        component: '',
        path: '',
        pid: '',
        hidden: '',
        location: ''
      },
      menuTyes: [],
      form: {
        menuId: null,
        component: '',
        hidden: null,
        icon: '',
        path: '',
        pid: null,
        title: '',
        type: null,
        name: '',
        jurisdiction: '',
        location: 'left',
        orderNum: ''
      },
      rules: {
        component: [
          { required: true, message: '请输入组建路径', trigger: 'blur' }
        ],
        hidden: [
          { required: true, message: '请输入是否显示', trigger: 'blur' }
        ],
        path: [
          { required: true, message: '请输入访问路径', trigger: 'blur' }
        ],
        pid: [
          { required: true, message: '请输入父级目录', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请输入目录类型', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入组件名称', trigger: 'blur' }
        ],
        orderNum: [
          { required: true, message: '请输入位置', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (/^(?:[1-9]\d*)$/.test(value) === false) {
                callback(new Error('请输入正整数'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    /**
    * 获取枚举的方法 created
    */
    initEunmData () {
      this.getEnumList('menu_type')
    },
    async getEnumList (name) {
      var par = {
        name: name
      }
      var res = await api.GET_ENUMERATION_LIST(par)
      this.menuTyes = res.body
    },
    typeChange (op) {
      console.log(op)
    },
    setTitle (title) {
      this.title = title
    },
    initData () {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields()
      }
      this.form.menuId = null
      this.form.component = null
      this.form.hidden = null
      this.form.icon = null
      this.form.path = null
      this.form.pid = null
      this.form.title = null
      this.form.type = null
      this.form.name = null
      this.selectMenuId = null
      this.form.jurisdiction = null
      this.form.location = 'left'
      this.editDisabled = false
      this.initTableData()
    },
    setData (data) {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields()
      }
      this.form.menuId = data.menuId
      this.form.component = data.component
      this.form.hidden = data.hidden
      this.form.icon = data.icon
      this.form.path = data.path
      this.form.pid = data.pid
      this.form.title = data.title
      this.form.type = data.type + ''
      this.form.name = data.name
      this.selectMenuId = data.pid
      this.form.jurisdiction = data.jurisdiction
      this.form.location = data.location
      this.form.orderNum = data.orderNum + ''
      this.editDisabled = true
      this.initTableData()
    },
    async updateApi () {
      await api.UPDATE_MENU(this.form)
      this.$emit('initTableData')
      this.$message.success('操作成功')
      this.handleClose()
    },
    async saveApi () {
      await api.SAVE_MENU(this.form)
      this.$emit('initTableData')
      this.$message.success('操作成功')
      this.handleClose()
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.menuId != null) {
            this.$confirm('此操作将永久修改该数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.updateApi()
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消修改'
              })
            })
          } else {
            if (this.form.type !== '0' && this.selectMenuId === null) {
              this.$message({
                message: '没有选择主菜单',
                type: 'error'
              })
              return
            } else {
              this.form.pid = this.selectMenuId
            }
            this.saveApi()
          }
        } else {
          return false
        }
      })
    },
    handleClose (done) {
      this.$emit('close')
    },
    appendMenus (data) {
      if (data.layer === 3) {
        this.$message({
          message: '无法在添加子菜单',
          type: 'error'
        })
        return
      }
      this.selectMenuId = data.id
      this.$message({
        message: '已选择主菜单',
        type: 'success'
      })
    },
    recursionMenus (menus) {
      var childrens = []
      for (var i = 0; i < menus.children.length; i++) {
        var menu = menus.children[i]
        var children = this.recursionMenus(menu)
        var menuJson = {
          id: menu.menuId,
          label: menu.title,
          type: menu.type,
          layer: menu.layer
        }
        if (children.length > 0) {
          menuJson.children = children
        }
        childrens.push(menuJson)
      }
      return childrens
    },
    async initTableData () {
      var par = {}
      const res = await api.GET_MENU_LIST(par)
      var menus = []
      for (var i = 0; i < res.body.length; i++) {
        var menu = res.body[i]
        var children = this.recursionMenus(menu)
        var menuJson = {
          id: menu.menuId,
          label: menu.title,
          type: menu.type,
          layer: menu.layer
        }
        if (children.length > 0) {
          menuJson.children = children
        }
        menus.push(menuJson)
      }
      this.tableData = menus
    }
  },
  created () {
    this.initEunmData()
  }
}
</script>

<style>
  .inputText {
    width: 70%;
  }
  .bottomDiv {
    width: 100%;
    text-align: center;
  }
  .nameItem {
    width: 40%;
  }
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
  .container {
    border-color: #bebebb;
    border-width: 1px;
    border-style: solid;
  }
</style>
