var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderDetails" },
    [
      !_vm.auth.isDetail
        ? _c("Basic", { attrs: { info: _vm.info } })
        : _vm._e(),
      !_vm.auth.isGoods
        ? _c("Good", {
            attrs: { goods: _vm.goods, info: _vm.info },
            on: { getGoods: _vm.getGoods, refresh: _vm.refresh }
          })
        : _vm._e(),
      _vm.info.enterWarehouseState !== 0 && !_vm.auth.isDetail
        ? _c("Particulars", { attrs: { info: _vm.info } })
        : _vm._e(),
      !_vm.auth.isDetail
        ? _c("Log", { attrs: { operateLogs: _vm.info.operateLogs } })
        : _vm._e(),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-bottom": "20px"
          }
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.resetForm()
                }
              }
            },
            [_vm._v(" 关闭 ")]
          ),
          _vm.info.enterWarehouseType === "其他" &&
          _vm.info.isStop === true &&
          _vm.permission.isCopy === false
            ? _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.loadingCopy,
                    loading: _vm.loadingCopy,
                    size: "small",
                    type: "success"
                  },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.copyOrder()
                    }
                  }
                },
                [_vm._v(" 复制订单 ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }