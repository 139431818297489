var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "10",
        title: _vm.title,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "980px",
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-container",
        [
          _c(
            "el-aside",
            [
              _c("el-tree", {
                attrs: {
                  data: _vm.tableData,
                  "node-key": "id",
                  "default-expand-all": "",
                  "expand-on-click-node": false
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var node = ref.node
                      var data = ref.data
                      return _c("span", { staticClass: "custom-tree-node" }, [
                        _c("span", [_vm._v(_vm._s(node.label))]),
                        _c(
                          "span",
                          [
                            _c("el-button", {
                              attrs: {
                                disabled: _vm.editDisabled || _vm.disabled,
                                type: "text",
                                icon: "el-icon-circle-plus-outline"
                              },
                              on: {
                                click: function() {
                                  return _vm.appendMenus(data)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ])
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-main",
            { staticStyle: { "margin-left": "100px" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "status-icon": "",
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "80px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "菜单名称", prop: _vm.propMenu.title } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: {
                          clearable: "",
                          size: "small",
                          disabled: _vm.disabled,
                          autocomplete: "off"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.save()
                          }
                        },
                        model: {
                          value: _vm.form.title,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "组件名称", prop: _vm.propMenu.name } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: {
                          clearable: "",
                          size: "small",
                          disabled: _vm.disabled,
                          autocomplete: "off"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.save()
                          }
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "显示图标", prop: _vm.propMenu.icon } },
                    [
                      _c("e-icon-picker", {
                        staticStyle: { width: "80%" },
                        attrs: { disabled: _vm.disabled },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.save()
                          }
                        },
                        model: {
                          value: _vm.form.icon,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "icon", $$v)
                          },
                          expression: "form.icon"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "组件路径", prop: _vm.propMenu.component }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: {
                          clearable: "",
                          size: "small",
                          disabled: _vm.disabled,
                          autocomplete: "off"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.save()
                          }
                        },
                        model: {
                          value: _vm.form.component,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "component", $$v)
                          },
                          expression: "form.component"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "访问路径", prop: _vm.propMenu.path } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: {
                          clearable: "",
                          size: "small",
                          disabled: _vm.disabled,
                          autocomplete: "off"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.save()
                          }
                        },
                        model: {
                          value: _vm.form.path,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "path", $$v)
                          },
                          expression: "form.path"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "权限值", prop: _vm.propMenu.path } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: {
                          clearable: "",
                          size: "small",
                          disabled: _vm.disabled,
                          autocomplete: "off"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.save()
                          }
                        },
                        model: {
                          value: _vm.form.jurisdiction,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "jurisdiction", $$v)
                          },
                          expression: "form.jurisdiction"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排列顺序", prop: "orderNum" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: {
                          clearable: "",
                          size: "small",
                          disabled: _vm.disabled,
                          autocomplete: "off"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.save()
                          }
                        },
                        model: {
                          value: _vm.form.orderNum,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "orderNum", $$v)
                          },
                          expression: "form.orderNum"
                        }
                      }),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "数大的在前面,请输入正整数",
                            placement: "top-start"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-warning-outline",
                            staticStyle: { "font-size": "20px", color: "red" }
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "菜单类型", prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.typeChange },
                          model: {
                            value: _vm.form.type,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type"
                          }
                        },
                        _vm._l(_vm.menuTyes, function(item) {
                          return _c(
                            "el-radio",
                            {
                              key: item.keys,
                              attrs: {
                                disabled: _vm.disabled,
                                label: item.keys
                              }
                            },
                            [_vm._v(" " + _vm._s(item.values) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "菜单显示", prop: _vm.propMenu.hidden } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.hidden,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "hidden", $$v)
                            },
                            expression: "form.hidden"
                          }
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { disabled: _vm.disabled, label: true } },
                            [_vm._v("显示")]
                          ),
                          _c(
                            "el-radio",
                            { attrs: { disabled: _vm.disabled, label: false } },
                            [_vm._v("隐藏")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "显示位置", prop: _vm.propMenu.location }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.location,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "location", $$v)
                            },
                            expression: "form.location"
                          }
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { disabled: _vm.disabled, label: "left" }
                            },
                            [_vm._v("左侧菜单")]
                          ),
                          _c(
                            "el-radio",
                            { attrs: { disabled: _vm.disabled, label: "top" } },
                            [_vm._v("顶部菜单")]
                          ),
                          _c(
                            "el-radio",
                            { attrs: { disabled: _vm.disabled, label: "all" } },
                            [_vm._v("全部菜单")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottomDiv" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("关闭")]
          ),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.disabled, size: "small", type: "primary" },
              on: { click: _vm.save }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }