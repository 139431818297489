export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    /**
     * @description 登录
     * @param {Object} data 登录携带的信息
     */
    GET_ROLE_SELECT: function GET_ROLE_SELECT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'v1/role/getRoleSelect',
        method: 'post',
        data: data
      });
    },
    SYS_USER_LOGIN: function SYS_USER_LOGIN() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/user/login',
        method: 'post',
        data: {
          username: data.username,
          password: data.password,
          loginType: 'back'
        }
      });
    },
    SYS_USER_LOGOUT: function SYS_USER_LOGOUT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/user/logout',
        method: 'post',
        data: data
      });
    },
    GET_SYS_USER_LIST: function GET_SYS_USER_LIST() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return request({
        url: '/v1/user/getPageList',
        method: 'post',
        params: params,
        data: query
      });
    },
    GET_USER_INFO: function GET_USER_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/user/userInfo',
        method: 'post',
        data: data
      });
    },
    SAVE_USER: function SAVE_USER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/user/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_USER: function UPDATE_USER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/user/update',
        method: 'put',
        data: data
      });
    },
    DELETE_USER: function DELETE_USER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/user/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_USER: function DELETE_BATCH_USER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/user/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});