<template>
  <div class="log">
    <div class="title">
      <span>操作记录</span>
    </div>
    <el-timeline class="table">
      <el-timeline-item
        v-for="(activity, index) in operateLogs"
        :key="index"
        :timestamp="activity.updateTimestamp"
      >
        {{
          activity.updateName +
            " " +
            activity.operateDesc +
            " 备注：" +
            (activity.remark ? activity.remark : "无")
        }}
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
export default {
  name: 'log',
  props: {
    operateLogs: {
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #b8b8b81e;
  height: 30px;
  font-weight: bold;
}

.table {
  margin-bottom: 7px;
  margin-top: 30px;
}
</style>
