var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.titleName,
            visible: _vm.visibleDialog,
            top: "8vh",
            width: "80%"
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleDialog = $event
            },
            close: _vm.dialogClose
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "InfoForm",
              staticStyle: { display: "flex", "flex-wrap": "wrap" },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "25%" },
                  attrs: { label: "盘库标题", prop: "checkName" }
                },
                [
                  _c("el-input", {
                    staticStyle: { "max-width": "200px" },
                    attrs: {
                      size: "small",
                      disabled:
                        _vm.titleName == "录入盘库单" ||
                        _vm.titleName == "修正盘库单",
                      placeholder: ""
                    },
                    model: {
                      value: _vm.form.checkName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "checkName", $$v)
                      },
                      expression: "form.checkName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "25%" },
                  attrs: { label: "盘库维度", prop: "dimension" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { "max-width": "200px" },
                      attrs: {
                        disabled:
                          _vm.titleName == "录入盘库单" ||
                          _vm.titleName == "修正盘库单" ||
                          _vm.titleName == "编辑盘库单",
                        placeholder: "请选择盘库维度",
                        size: "small"
                      },
                      model: {
                        value: _vm.form.dimension,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "dimension", $$v)
                        },
                        expression: "form.dimension"
                      }
                    },
                    _vm._l(_vm.dimensionList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "25%" },
                  attrs: { label: "盘点仓库", prop: "warehouseId" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { "max-width": "200px" },
                      attrs: {
                        disabled:
                          _vm.titleName == "录入盘库单" ||
                          _vm.titleName == "修正盘库单",
                        placeholder: "请选择盘点仓库",
                        size: "small"
                      },
                      model: {
                        value: _vm.form.warehouseId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "warehouseId", $$v)
                        },
                        expression: "form.warehouseId"
                      }
                    },
                    _vm._l(_vm.warehouseList, function(item) {
                      return _c("el-option", {
                        key: item.warehouseId,
                        attrs: {
                          label: item.warehouseName,
                          value: item.warehouseId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.titleName == "编辑盘库单" ||
              _vm.titleName == "录入盘库单" ||
              _vm.titleName == "修正盘库单"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "20%" },
                      attrs: { label: "盘库单号" }
                    },
                    [_vm._v(_vm._s(_vm.form.checkId))]
                  )
                : _vm._e(),
              _vm.titleName == "编辑盘库单" ||
              _vm.titleName == "录入盘库单" ||
              _vm.titleName == "修正盘库单"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "20%" },
                      attrs: { label: "盘库人员" }
                    },
                    [_vm._v(_vm._s(_vm.form.createdName))]
                  )
                : _vm._e(),
              _vm.titleName == "编辑盘库单" || _vm.titleName == "录入盘库单"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "20%" },
                      attrs: { label: "盘库状态" }
                    },
                    [_vm._v(_vm._s(_vm.almighty(_vm.form.status, "盘库状态")))]
                  )
                : _vm._e(),
              _vm.titleName == "编辑盘库单" || _vm.titleName == "录入盘库单"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "20%" },
                      attrs: { label: "创建时间" }
                    },
                    [_vm._v(_vm._s(_vm.form.createdTimestamp))]
                  )
                : _vm._e(),
              _vm.titleName == "编辑盘库单" || _vm.titleName == "录入盘库单"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "20%" },
                      attrs: { label: "完成时间" }
                    },
                    [_vm._v(_vm._s(_vm.form.endTime))]
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "盘库备注" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      disabled:
                        _vm.titleName == "录入盘库单" ||
                        _vm.titleName == "修正盘库单",
                      placeholder: ""
                    },
                    model: {
                      value: _vm.form.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("div", [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*")
                    ]),
                    _vm._v("商品列表")
                  ]),
                  _vm.form.warehouseId &&
                  _vm.form.dimension == 0 &&
                  (_vm.titleName == "编辑盘库单" ||
                    _vm.titleName == "新增盘库单")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "success" },
                          on: {
                            click: function($event) {
                              return _vm.selectGoods()
                            }
                          }
                        },
                        [_vm._v("选择商品")]
                      )
                    : _vm._e(),
                  _vm.form.warehouseId &&
                  _vm.form.dimension == 1 &&
                  (_vm.titleName == "编辑盘库单" ||
                    _vm.titleName == "新增盘库单")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "success" },
                          on: {
                            click: function($event) {
                              return _vm.selectGoodsShelf()
                            }
                          }
                        },
                        [_vm._v("选择货架")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "goodsDataSty" },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.form.tableData,
                        "header-cell-style": _vm.headerClass,
                        "cell-style": _vm.rowClass
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "序号", fixed: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(" " + _vm._s(scope.$index + 1) + " ")
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goodsCoding",
                          label: "商品编码",
                          fixed: ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goodsName",
                          label: "商品名称",
                          fixed: ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "pictureUrl", label: "商品图片" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "40px",
                                    height: "40px"
                                  },
                                  attrs: {
                                    "preview-src-list": [
                                      _vm.downloadUrl +
                                        "/" +
                                        scope.row.pictureUrl
                                    ],
                                    src:
                                      _vm.downloadUrl +
                                      "/" +
                                      scope.row.pictureUrl
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "intoPrices", label: "入库价" }
                      }),
                      !(
                        _vm.form.warehouseId &&
                        _vm.form.dimension == 1 &&
                        (_vm.titleName == "编辑盘库单" ||
                          _vm.titleName == "新增盘库单")
                      )
                        ? _c("el-table-column", {
                            attrs: { prop: "goodsNum", label: "在库数量" }
                          })
                        : _vm._e(),
                      _vm.form.warehouseId &&
                      _vm.form.dimension == 1 &&
                      (_vm.titleName == "编辑盘库单" ||
                        _vm.titleName == "新增盘库单")
                        ? _c("el-table-column", {
                            attrs: { prop: "areaName", label: "区域" }
                          })
                        : _vm._e(),
                      _vm.form.warehouseId &&
                      _vm.form.dimension == 1 &&
                      (_vm.titleName == "编辑盘库单" ||
                        _vm.titleName == "新增盘库单")
                        ? _c("el-table-column", {
                            attrs: { prop: "shelvesName", label: "货架" }
                          })
                        : _vm._e(),
                      _vm.form.warehouseId &&
                      _vm.form.dimension == 1 &&
                      (_vm.titleName == "编辑盘库单" ||
                        _vm.titleName == "新增盘库单")
                        ? _c("el-table-column", {
                            attrs: { prop: "shelvesLayers", label: "层数" }
                          })
                        : _vm._e(),
                      _vm.titleName != "编辑盘库单" &&
                      _vm.titleName != "新增盘库单"
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "checkNum",
                              label: "手动录入",
                              width: "200"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "tableData." +
                                              scope.$index +
                                              ".checkNum",
                                            rules: _vm.rules.checkNum
                                          }
                                        },
                                        [
                                          _c("el-input-number", {
                                            attrs: {
                                              disabled:
                                                _vm.titleName == "修正盘库单",
                                              min: 1,
                                              step: 1,
                                              size: "small",
                                              "step-strictly": ""
                                            },
                                            model: {
                                              value: scope.row.checkNum,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "checkNum",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.checkNum"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2558948931
                            )
                          })
                        : _vm._e(),
                      _vm.titleName != "编辑盘库单" &&
                      _vm.titleName != "新增盘库单"
                        ? _c("el-table-column", {
                            attrs: { prop: "", label: "盘点盈亏" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            type: _vm.almightyM(
                                              scope.row,
                                              "color"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.almightyM(scope.row, "num")
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4109718435
                            )
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.titleName == "修正盘库单"
                ? _c("div", { staticClass: "title" }, [_vm._v("盘库结果")])
                : _vm._e(),
              _vm.titleName == "修正盘库单"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "25%" },
                      attrs: { label: "待盘点数量" }
                    },
                    [_vm._v(_vm._s(_vm.Dnum))]
                  )
                : _vm._e(),
              _vm.titleName == "修正盘库单"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "25%" },
                      attrs: { label: "已盘点数量" }
                    },
                    [_vm._v(_vm._s(_vm.Ynum))]
                  )
                : _vm._e(),
              _vm.titleName == "修正盘库单"
                ? _c(
                    "el-form-item",
                    { staticStyle: { width: "25%" }, attrs: { label: "盘盈" } },
                    [_vm._v(_vm._s(_vm.Pwin))]
                  )
                : _vm._e(),
              _vm.titleName == "修正盘库单"
                ? _c(
                    "el-form-item",
                    { staticStyle: { width: "25%" }, attrs: { label: "盘亏" } },
                    [_vm._v(_vm._s(_vm.Plose))]
                  )
                : _vm._e(),
              _vm.titleName == "录入盘库单" || _vm.titleName == "修正盘库单"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { prop: "checkRemark", label: "盈亏原因备注" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          disabled: _vm.titleName == "修正盘库单",
                          placeholder: ""
                        },
                        model: {
                          value: _vm.form.checkRemark,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "checkRemark", $$v)
                          },
                          expression: "form.checkRemark"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.titleName == "修正盘库单"
                ? _c("div", { staticClass: "title" }, [_vm._v("修正库存")])
                : _vm._e(),
              _vm.titleName == "修正盘库单"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "50%" },
                      attrs: { label: "入库单号" }
                    },
                    [_vm._v(_vm._s(_vm.form.intoId))]
                  )
                : _vm._e(),
              _vm.titleName == "修正盘库单"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "50%" },
                      attrs: { label: "出库单号" }
                    },
                    [_vm._v(_vm._s(_vm.form.outId))]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.titleName == "修正盘库单"
            ? _c(
                "div",
                {
                  staticStyle: {
                    "max-width": "200px",
                    margin: "0 auto",
                    "margin-top": "10px"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.dialogClose()
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        disabled: _vm.btnDisabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.amendBtn()
                        }
                      }
                    },
                    [_vm._v("修正")]
                  )
                ],
                1
              )
            : _c(
                "div",
                {
                  staticStyle: {
                    "max-width": "250px",
                    margin: "0 auto",
                    "margin-top": "10px"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.dialogClose()
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "success" },
                      on: {
                        click: function($event) {
                          return _vm.saveBtn()
                        }
                      }
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        disabled: _vm.btnDisabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.submitBtn()
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "货架选择",
            visible: _vm.selectGoodsShelfLog,
            top: "8vh",
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              _vm.selectGoodsShelfLog = $event
            },
            close: _vm.logClose
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "InfoForm",
              staticStyle: { display: "flex", "flex-wrap": "wrap" },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "盘点仓库" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        disabled: true,
                        placeholder: "请选择盘点仓库",
                        size: "small"
                      },
                      model: {
                        value: _vm.logData.warehouseId,
                        callback: function($$v) {
                          _vm.$set(_vm.logData, "warehouseId", $$v)
                        },
                        expression: "logData.warehouseId"
                      }
                    },
                    _vm._l(_vm.warehouseIdList, function(item) {
                      return _c("el-option", {
                        key: item.warehouseId,
                        attrs: {
                          label: item.warehouseName,
                          value: item.warehouseId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "盘点区域" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择盘点仓库", size: "small" },
                      on: {
                        change: function($event) {
                          return _vm.areaIDC()
                        }
                      },
                      model: {
                        value: _vm.logData.areaID,
                        callback: function($$v) {
                          _vm.$set(_vm.logData, "areaID", $$v)
                        },
                        expression: "logData.areaID"
                      }
                    },
                    _vm._l(_vm.areaIDList, function(item) {
                      return _c("el-option", {
                        key: item.areaId,
                        attrs: { label: item.areaName, value: item.areaId }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "盘点货架" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择盘点仓库", size: "small" },
                      model: {
                        value: _vm.logData.goodsShelfId,
                        callback: function($$v) {
                          _vm.$set(_vm.logData, "goodsShelfId", $$v)
                        },
                        expression: "logData.goodsShelfId"
                      }
                    },
                    _vm._l(_vm.goodsShelfIdList, function(item) {
                      return _c("el-option", {
                        key: item.shelvesId,
                        attrs: {
                          label: item.shelvesName,
                          value: item.shelvesId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "max-width": "200px",
                margin: "0 auto",
                "margin-top": "10px"
              }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.logClose()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.logSaveBtn()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("setStockGoodsList", {
        ref: "setStockGoodsList",
        on: { setGoods: _vm.addGoods }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }