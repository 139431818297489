var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "good" }, [
    _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("商品信息")]),
      _c(
        "div",
        { staticClass: "button" },
        [
          !_vm.info.isStop && !_vm.auth.isStop
            ? _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "danger",
                    disabled: _vm.stopOutStockLoading,
                    loading: _vm.stopOutStockLoading
                  },
                  on: { click: _vm.stopOutStock }
                },
                [_vm._v(" 终止入库 ")]
              )
            : _vm._e(),
          _vm.info.enterWarehouseState === 0 &&
          !_vm.info.isStop &&
          _vm.info.enterWarehouseType !== "调拨" &&
          _vm.info.enterWarehouseType !== "代发" &&
          !_vm.auth.isCode
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "small",
                    disabled: _vm.onePiceRemakeLoading,
                    loading: _vm.onePiceRemakeLoading
                  },
                  on: { click: _vm.onePiceRemake }
                },
                [_vm._v(" 一键生码 ")]
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "table" },
      [
        _c(
          "el-table",
          {
            ref: "tableData",
            attrs: {
              "row-class-name": _vm.rowClassName,
              data: _vm.goods.datas,
              "max-height": "770px",
              "element-loading-text": "数据加载中",
              "cell-style": { textAlign: "center" },
              "tooltip-effect": "dark",
              "header-cell-style": {
                background: "#dfe6ec",
                color: "#666666",
                textAlign: "center"
              }
            }
          },
          [
            _c("el-table-column", {
              key: "index",
              attrs: { label: "序号", width: "80", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.page.pageNo - 1) * _vm.page.pageSize +
                              scope.$index +
                              1
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              key: "goodsCoding",
              attrs: {
                label: "商品编码",
                prop: "goodsCoding",
                align: "center",
                width: "110px"
              }
            }),
            _c("el-table-column", {
              key: "goodsName",
              attrs: {
                label: "商品名称",
                prop: "goodsName",
                align: "center",
                width: "300px"
              }
            }),
            _c("el-table-column", {
              key: "goodsPicture",
              attrs: { label: "商品图片", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-image", {
                        staticStyle: { width: "40px", height: "40px" },
                        attrs: {
                          src: scope.row.goodsPicture
                            ? _vm.$oss + scope.row.goodsPicture
                            : _vm.$oss + scope.row.pictureUrl,
                          "preview-src-list": [
                            scope.row.goodsPicture
                              ? _vm.$oss + scope.row.goodsPicture
                              : _vm.$oss + scope.row.pictureUrl
                          ],
                          fit: "cover"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            (_vm.info.enterWarehouseState === 0 ||
              (_vm.info.enterWarehouseType === "代发" &&
                _vm.info.enterWarehouseState === 3)) &&
            _vm.info.enterWarehouseType !== "其他"
              ? _c("el-table-column", {
                  key: "purchaseNums",
                  attrs: {
                    label:
                      _vm.info.enterWarehouseType === "调拨"
                        ? "调拨数量"
                        : _vm.info.enterWarehouseType === "代发" &&
                          _vm.info.enterWarehouseState === 3
                        ? "商品数量"
                        : "采购数量",
                    prop: "enterWarehouseNums",
                    align: "center",
                    width: "300px"
                  }
                })
              : _vm._e(),
            _vm.info.enterWarehouseState !== 0 &&
            _vm.info.enterWarehouseType !== "代发"
              ? _c("el-table-column", {
                  key: "barCode",
                  attrs: {
                    label: "条码",
                    prop: "barCode",
                    align: "center",
                    width: "170px"
                  }
                })
              : _vm._e(),
            _vm.info.enterWarehouseState === 0
              ? _c("el-table-column", {
                  key: "intoNums",
                  attrs: {
                    label: "入库数量",
                    prop: "enterWarehouseNums",
                    align: "center",
                    width: "300px"
                  }
                })
              : _vm._e(),
            _c("el-table-column", {
              key: "enterWarehousePrices",
              attrs: {
                label: "入库价",
                prop: "enterWarehousePrices",
                align: "center"
              }
            }),
            _vm.info.enterWarehouseState === 0 ||
            (_vm.info.enterWarehouseState === 3 &&
              _vm.info.enterWarehouseType === "代发")
              ? _c("el-table-column", {
                  key: "totalNum",
                  attrs: { label: "总计", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (
                                      scope.row.enterWarehousePrices *
                                      scope.row.enterWarehouseNums
                                    ).toFixed(2)
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    706167206
                  )
                })
              : _vm._e(),
            _vm.info.enterWarehouseState !== 0 &&
            _vm.info.enterWarehouseType !== "代发"
              ? [
                  _c("el-table-column", {
                    key: "enterWarehouseAreaName",
                    attrs: {
                      label: "入库区域",
                      prop: "enterWarehouseAreaName",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    key: "enterWarehouseShelfName",
                    attrs: {
                      label: "入库货架",
                      prop: "enterWarehouseShelfName",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    key: "enterWarehouseShelvesNum",
                    attrs: {
                      label: "货架层数",
                      prop: "enterWarehouseShelvesNum",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    key: "scanCode",
                    attrs: { label: "是否扫码", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.scanCode === 1
                                      ? "是"
                                      : scope.row.scanCode === 0
                                      ? "否"
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1208882965
                    )
                  }),
                  _c("el-table-column", {
                    key: "scanCodeBy",
                    attrs: {
                      label: "扫码人",
                      prop: "scanCodeBy",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "扫码时间",
                      width: "200px",
                      prop: "scanCodeTimestamp",
                      align: "center"
                    }
                  })
                ]
              : _vm._e(),
            ((_vm.info.enterWarehouseState === 0 && !_vm.info.isStop) ||
              _vm.info.enterWarehouseState === 1) &&
            _vm.info.enterWarehouseType !== "调拨" &&
            _vm.info.enterWarehouseType !== "代发" &&
            !_vm.info.isStop
              ? _c("el-table-column", {
                  key: "remake",
                  attrs: { label: "操作", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.info.enterWarehouseState === 1 &&
                            _vm.auth.isCode === false
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      plain: "",
                                      size: "small"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.remake(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v(" 重新生码 ")]
                                )
                              : _vm._e(),
                            _vm.info.enterWarehouseState === 0 &&
                            _vm.info.enterWarehouseType !== "调拨" &&
                            _vm.info.enterWarehouseType !== "代发" &&
                            !_vm.info.isStop &&
                            _vm.auth.isCode === false
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      plain: "",
                                      size: "small"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.make(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v(" 生码 ")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3384409775
                  )
                })
              : _vm._e()
          ],
          2
        ),
        _c("el-pagination", {
          attrs: {
            "current-page": _vm.page.pageNo,
            "page-sizes": [10, 20, 30, 40, 50, 100],
            "page-size": _vm.page.pageSize,
            layout: "->,total, sizes, prev, pager, next, jumper",
            total: _vm.pageTotal
          },
          on: {
            "size-change": _vm.sizeChange,
            "current-change": _vm.currentChange
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }