<template>
   <d2-container>
      <div class="all">
         <div class="title">基本信息</div>
      <div style="display:flex;flex-wrap:wrap;padding: 0 20px;">
         <div class="dataMain"><span style="font-weight:bold">采购单号</span>：<span>{{ data.purchaseId }}</span></div>
         <div class="dataMain"><span style="font-weight:bold">操作人</span> ：<span>{{ data.updateName }}</span></div>
         <div class="dataMain"><span style="font-weight:bold">操作时间</span>：<span>{{ data.updateTimestamp }}</span></div>
         <div class="dataMain"><span style="font-weight:bold">仓库名称</span>：<span>{{ data.warehouseName }}</span></div>
         <div class="dataMain"><span style="font-weight:bold">退货数量</span>：<span> {{ data.sum }}</span></div>
         <div class="dataMain"><span style="font-weight:bold">退货金额</span>：<span>{{ data.sumPrice }}</span></div>
         <!-- <div class="dataMain"><span>快递公司</span>：<span> {{ data.company }}</span></div>
         <div class="dataMain"><span>快递单号</span>：<span> {{ data.companyOrder }}</span></div> -->
      </div>
      <div class="title">退款截图</div>
      <el-image :src="downloadUrl + '/' + data.refundProve" alt=""
         :preview-src-list="[downloadUrl + '/' + data.refundProve]"
         style="width: 100px; height: 100px;margin: 10px;cursor: pointer"></el-image>
      <div class="title">商品信息</div>
      <el-table :data="data.resList" :header-cell-style="headerClass" :cell-style="rowClass" v-loading="loading"
         style="width:100%">
         <el-table-column prop="goodsCoding" label="商品编码"></el-table-column>
         <el-table-column prop="goodsName" label="商品名称"></el-table-column>
         <el-table-column prop="idName" label="商品分类"></el-table-column>
         <el-table-column prop="brandName" label="商品品牌"></el-table-column>
         <el-table-column prop="ipName" label="商品IP"></el-table-column>
         <el-table-column prop="pictureUrl" label="商品图片">
            <template slot-scope="scope">
               <el-image :src="downloadUrl + '/' + scope.row.pictureUrl" alt=""
                  :preview-src-list="[downloadUrl + '/' + scope.row.pictureUrl]"
                  style="width: 50px; height: 50px;margin-top: 8px;cursor: pointer"></el-image>
            </template>
         </el-table-column>
         <el-table-column prop="enterWarehousePrices" label="采购价格"></el-table-column>
         <el-table-column prop="sum" label="退货数量"></el-table-column>
         <el-table-column prop="sumPrice" label="总计"></el-table-column>
      </el-table>
      </div>
   </d2-container>
</template>

<script>
import api from '@/api'
export default {
   data() {
      return {
         enterWarehouseNumber: null,
         tableData: [],
         data: {},
         downloadUrl: process.env.VUE_APP_DOWNLOAD_URL,
         loading: false,
         // /v1/intoDetail/returnMonetResult
      }
   },
   watch: {
      $route(to, from) {
         this.$route.query.enterWarehouseNumber = to.query.enterWarehouseNumber
         this.enterWarehouseNumber = this.$route.query.enterWarehouseNumber
      },
   },
   created() {
      this.enterWarehouseNumber = this.$route.query.enterWarehouseNumber
      this.getData()
   },
   methods: {
      async getData() {
         const res = await api.getPurchaseThListDetail({ enterWarehouseNumber: this.enterWarehouseNumber })
         this.data = res.body
         console.log(this.data);
      },
      // table样式
      headerClass() {
         return "background: #eef1f6; color: #606266;text-align:center"
      },
      rowClass() {
         return "text-align:center"
      },
   }
}
</script>

<style lang='scss' scoped>
.all{
   border:1px solid #888;
   border-radius: 10px;
}
.title {
   width: calc(100% - 20px);
   height: 35px;
   line-height: 35px;
   background-color: #f3f3f3;
   display: inline-block;
   padding: 0 10px;
   overflow: hidden;
   font-weight: bold;
}

.dataMain {
   display: inline-block;
   min-width: 250px;
   font-size: 14px;
   line-height: 40px;
}
</style>