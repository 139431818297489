<template>
    <div class='message'>
        <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
            <el-table :data="messageList">
                <el-table-column prop="titleName" align="center" label="标题">
                </el-table-column>
                <el-table-column prop="titleName" align="center" label="状态">
                </el-table-column>
                <el-table-column prop="titleName" align="center" label="时间">
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template>
                        <el-button type="primary">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :total="page.totalSize" :current-page="page.pageNo" :page-size="[10,20,30,40,50,100]"
                layout="jumper, prev, pager, next,sizes" @current-change="handleClick" @size-change="handleClick">
            </el-pagination>
            <el-tab-pane label="全部消息" name="0">
            </el-tab-pane>
            <el-tab-pane label="未读消息" name="1"></el-tab-pane>
            <el-tab-pane label="已读消息" name="2"></el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import api from '@/api'
import page from '@/store/modules/d2admin/modules/page'

export default {
  name: 'message',
  computed: {
  },
  data () {
    return {
      messageList: [1],
      activeName: 0,
      page: {
        pageNo: 1,
        pageSize: 50,
        pageTotal: 100
      }
    }
  },
  created () {
    this.handleClick()
  },
  methods: {
    async handleClick () {
      const { body: data } = await api.getMessage(page, { message: this.activeName })
      this.page.pageTotal = data.pageTotal
      this.messageList = data
    }
  }
}
</script>
<style lang='scss'>

</style>
