export var formData = {
  enterWarehouseType: '',
  enterWarehouseSource: '',
  payWay: '0',
  warehouseId: '',
  goodsList: [],
  remark: '',
  logisticsCompany: '',
  logisticsNumbers: '',
  childNumbers: [],
  small_trash: null
};
export var ruleData = {
  enterWarehouseSource: [{
    required: true,
    message: '请选择入库来源',
    trigger: 'change'
  }],
  small_trash: [{
    required: true,
    message: '请选择扫码状态',
    trigger: 'change'
  }],
  payWay: [{
    required: true,
    message: '请选择付款方式',
    trigger: 'change'
  }],
  warehouseId: [{
    required: true,
    message: '请选择入库仓库',
    trigger: 'change'
  }],
  enterWarehousePrices: [{
    required: true,
    message: '请输入入库价',
    trigger: 'blur'
  }, {
    pattern: /^\d+(\.\d{1,2})?$/,
    message: '请输入正数，且小数点后不能超过两位',
    trigger: 'blur'
  }],
  enterWarehouseNums: [{
    required: true,
    message: '请输入入库数量',
    trigger: 'blur'
  }, {
    pattern: /^[1-9]\d*$/,
    message: '请输入正整数',
    trigger: 'blur'
  }],
  goodsList: [{
    required: true,
    message: '请选择商品',
    trigger: 'blur'
  }],
  purchaseNums: [{
    required: true,
    message: '请输入采购数量',
    trigger: 'blur'
  }, {
    pattern: /^\d+(\.\d{1,2})?$/,
    message: '请输入正数，且小数点后不能超过两位',
    trigger: 'blur'
  }]
};
export var selectFormData = {
  deliveryMethods: [],
  enterWarehouseNumber: undefined,
  goodsName: undefined,
  enterWarehouseStates: [],
  enterWarehouseTypes: [],
  warehouseIds: [],
  intoTimeStamp: [],
  pageNo: 1,
  pageSize: 50
};
export var authority = function authority(vue) {
  return {
    isSearch: vue.checkauthority('into_order_select'),
    // 搜索
    isAdd: vue.checkauthority('into_order_create'),
    // 新增
    isEdit: vue.checkauthority('into_order_update'),
    // 编辑
    isDetail: vue.checkauthority('into_order_detail_info'),
    // 明细中的基本信息
    isStop: vue.checkauthority('into_order_stop'),
    // 终止入库
    isCode: vue.checkauthority('reload_print'),
    // 一键生码
    isEditDetail: vue.checkauthority('into_order_get_info'),
    // 编辑列表中的信息
    isGoods: vue.checkauthority('into_order_product_page_limit') // 详情中的商品信息

  };
};