//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'log',
  props: {
    logInfo: {
      type: Object
    }
  },
  watch: {
    logInfo: {
      handler: function handler(val) {
        this.logData = val.logList;
      },
      immediate: true
    }
  },
  data: function data() {
    return {
      logData: []
    };
  }
};