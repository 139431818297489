<template>
  <d2-container>
    <div class="header">
      <div style=" margin-left: 10px; margin-right: 10px;">
        <el-input
          v-model="search.brandName"
          placeholder="请输入商品品牌"
          style="width:200px;"
          clearable
          size="small"
          @keyup.enter.native="searchTotal()"
        ></el-input>
        <el-button
          icon="el-icon-search"
          style="margin-left:20px"
          type="primary"
          @click="searchTotal()"
          size="small"
          v-if="!permission.isSearch"
        >
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh"
          style="margin-left:20px"
          type="warning"
          @click="reset()"
          size="small"
          v-if="!permission.isReset"
        >
          重置
        </el-button>
        <el-button
          icon="el-icon-circle-plus-outline"
          type="success"
          style="margin-left:20px"
          @click="add"
          size="small"
          v-if="!permission.isAdd"
        >
          新增
        </el-button>
      </div>
    </div>
    <!-- <div style="margin-top: 10px; margin-left: 10px; margin-right: 10px;">
    </div> -->
    <div style="margin-top: 10px; margin-left: 10px; margin-right: 10px;">
      <el-table
        ref="tableData"
        :data="tableData"
        max-height="645px"
        element-loading-text="数据加载中"
        :cell-style="{ textAlign: 'center' }"
        tooltip-effect="dark"
        v-loading="loadingTotal"
        :row-style="{ height: '57px' }"
        :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center'
        }"
      >
        <el-table-column label="序号" width="80" align="center">
          <template slot-scope="scope">
            {{ (search.pageNo - 1) * search.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          label="品牌名称"
          prop="brandName"
          align="center"
        ></el-table-column>
        <el-table-column label="品牌图片" prop="brandPicture" align="center">
          <template slot-scope="scope">
            <img
              :src="downloadUrl + '/' + scope.row.brandPicture"
              alt=""
              style="width: 50px; height: 50px;margin-top: 8px;cursor: pointer"
              @click="getPreview(downloadUrl + '/' + scope.row.brandPicture)"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-edit-outline"
              plain
              size="small"
              v-if="!permission.isRedact"
              @click.native.prevent="redact(scope.row, scope.$index, tableData)"
            >
              编辑
            </el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              plain
              size="small"
              v-if="!permission.isDelete"
              @click.native.prevent="remove(scope.row, scope.$index, tableData)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div style="display:flex;justify-content:right;margin-top: 10px;">
        <!-- <div style="font-size: 13px;min-width: 35.5px;height: 28px;margin-top: 2px;">
          <span style="line-height: 32px;font-weight: 400;color: #606266;">共</span><span
            style="line-height: 32px;font-weight: 400;color: #606266;">{{this.search.pageTotal}}</span>条
        </div> -->
        <el-pagination
          :current-page="search.pageNo"
          v-if="this.search.pageSize > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="search.pageSize"
          layout="total, jumper, prev, pager, next,sizes"
          :total="search.pageTotal"
        ></el-pagination>
      </div>
      <el-dialog
        :append-to-body="true"
        :title="judgment"
        :visible.sync="dialogFormVisible"
        @close="closeDialog()"
        width="25%"
        style="margin:20px auto"
        :close-on-click-modal="false"
      >
        <el-form
          :model="redactData"
          ref="redactData"
          label-width="100px"
          :rules="rules"
          class="demo-ruleForm"
        >
          <el-form-item label="品牌名称" prop="brandName">
            <el-input
              v-model="redactData.brandName"
              placeholder="请输入品牌名称"
              clearable
              style="width:240px"
            ></el-input>
          </el-form-item>
          <el-form-item label="品牌图片" prop="brandPicture">
            <erp-upload-img
              :showDelete="true"
              v-model="redactData.brandPicture"
              :upload-path="'/commodityManagement/brand/'"
              :maxCount="1"
            ></erp-upload-img>
          </el-form-item>
          <div style="  display: flex;justify-content:center;">
            <el-button size="small" @click="resetForm('redactData')">
              取消
            </el-button>
            <el-button
              size="small"
              type="primary"
              v-if="this.judgment === '新增'"
              @click="submitForm('redactData')"
            >
              立即创建
            </el-button>
            <el-button
              size="small"
              type="primary"
              v-if="this.judgment === '编辑'"
              @click="submitForm('redactData')"
            >
              保存
            </el-button>
          </div>
        </el-form>
      </el-dialog>
      <!--     点击图片放大弹出页面         -->
      <el-dialog
        :append-to-body="true"
        :title="judgment_pic"
        :visible.sync="dialogFormVisible_pic"
        @close="closeDialogPic()"
        width="540px"
        style="margin:20px auto"
        :close-on-click-modal="false"
      >
        <img
          v-if="this.dialogImageUrl !== ''"
          style=" width: 500px; height: 500px; text-align: center"
          alt=""
          :src="this.dialogImageUrl"
        />
        <div
          v-if="this.dialogImageUrl === ''"
          style="width: 500px;height: 500px;text-align: center;border: 1px solid #c0c0c0;display: flex;justify-content: center;align-items: center;
        "
        >
          <span style="font-size: large">暂无图片</span>
        </div>
      </el-dialog>
    </div>
  </d2-container>
</template>
<script>
import api from '@/api'

// 搜索数据
const searchDefault = {
  brandName: '', // 品牌名称
  pageNo: 1,
  pageSize: 50,
  pageTotal: 0
}

// 编辑数据
const redact = {
  brandPicture: [],
  brandName: ''
}

export default {
  // eslint-disable-next-line vue/no-unused-components
  name: 'brand',
  data () {
    return {
      // 权限点
      permission: {
        isSearch: this.checkauthority('brand_select'), // 搜索
        isReset: this.checkauthority('brand_select'), // 重置
        isDelete: this.checkauthority('brand_delete_row'), // 删除
        isAdd: this.checkauthority('brand_insert_row'), // 新增
        isRedact: this.checkauthority('brand_update_row') // 编辑
      },
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL,
      search: JSON.parse(JSON.stringify(searchDefault)),
      tableData: [],
      redactData: JSON.parse(JSON.stringify(redact)),
      loadingTotal: false,
      judgment: '',
      judgment_pic: '',
      dialogImageUrl: '',
      idNum: '',
      dialogFormVisible: false,
      dialogFormVisible_pic: false,
      rules: {
        name: [],
        brandName: [
          { required: true, message: '请输入品牌名称', trigger: 'blur' },
          { pattern: /^(?!\s+)/, message: '首不能为空格', trigger: 'blur' }
        ],
        brandPicture: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              this.validatePicture(rule, value, callback, 'cover')
            }
          }
        ]
      }
    }
  },
  created () {
    this.initList()
  },
  methods: {
    getImage (row) {
      console.log(row)
      // var url = row.brandPicture[0]
      // return this.uploadUrl + 'v1/file/uploadFileImg' + url
    },
    handleSizeChange (val) {
      this.search.pageSize = val
      this.initList()
    },
    handleCurrentChange (val) {
      this.search.pageNo = val
      this.initList()
    },
    // 初始化页面
    async initList () {
      this.loadingTotal = true
      // eslint-disable-next-line no-undef
      const res = await api.getPageList_brand({
        brandName: this.search.brandName,
        pageNo: this.search.pageNo,
        pageSize: this.search.pageSize
      })
      if (res.code === 200) {
        this.tableData = res.body.datas
        this.search.pageTotal = res.body.sumDataCount
        this.loadingTotal = false
      } else {
        this.$message({
          type: 'danger',
          message: '页面加载失败!'
        })
        this.loadingTotal = false
      }
    },
    // 图片放大开启
    getPreview (pictureUrl) {
      this.judgment_pic = '放大图片'
      this.dialogFormVisible_pic = true
      this.dialogImageUrl = pictureUrl
    },
    // 图片放大关闭
    closeDialogPic () {
      this.dialogFormVisible_pic = false
      this.dialogImageUrl = ''
    },
    // 搜索
    searchTotal () {
      this.search.pageNo = 1
      this.initList()
    },
    // 重置
    reset () {
      this.search.brandName = ''
      this.search.nextToken = null
      this.search.limit = 10
      this.search.page = 1
      this.initList()
    },
    // 点击新增
    add () {
      this.redactData.brandName = ''
      this.redactData.brandPicture = []
      this.judgment = '新增'
      this.dialogFormVisible = true
    },
    // 关闭新增
    closeDialog () {
      this.judgment = ''
      this.redactData.brandPicture = []
      this.dialogFormVisible = false
      this.resetForm()
    },
    // 编辑
    redact (row) {
      this.judgment = '编辑'
      this.redactData.ipName = row.ipName
      this.dialogFormVisible = true
      this.idNum = row.id.toString()
      this.redactData.brandName = row.brandName
      this.redactData.brandPicture = [{ url: row.brandPicture }]
    },
    // 新增编辑 创建
    submitForm () {
      console.log(this.redactData.brandPicture)
      this.$refs.redactData.validate(async valid => {
        if (valid) {
          if (this.judgment === '新增') {
            const res = await api.insertRow_brand({
              brandPicture: this.redactData.brandPicture[0].url,
              brandName: this.redactData.brandName
            })
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '新增成功!'
              })
              this.dialogFormVisible = false
              this.initList()
            } else {
              this.$message({
                type: 'danger',
                message: '新增失败!'
              })
            }
          } else {
            const res = await api.updateRow_brand({
              brandPicture: this.redactData.brandPicture[0].url,
              brandName: this.redactData.brandName,
              id: this.idNum
            })
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '编辑成功!'
              })
              this.dialogFormVisible = false
              this.initList()
            } else {
              this.$message({
                type: 'danger',
                message: '编辑失败!'
              })
            }
          }
        } else {
          this.$message({
            type: 'waring',
            message: '请填写内容后提交'
          })
        }
      })
    },
    // 新增编辑返回
    resetForm () {
      // this.judgment = ''
      this.redactData.brandName = ''
      this.redactData.brandPicture = []
      this.idNum = ''
      this.$refs.redactData.clearValidate()
      this.dialogFormVisible = false
    },
    // 删除
    async remove (row) {
      var idNum = row.id.toString()
      this.$confirm('是否删除该品牌？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await api.deleteRow_brand({
          id: idNum
        })
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.search.pageNo = 1
          this.initList()
        } else {
          this.$message({
            type: 'danger',
            message: '删除失败!'
          })
        }
      })
    },
    // 图片校验
    validatePicture (rule, value, callback) {
      if (value.length === 0) {
        callback(new Error('图片为必选项'))
      } else {
        callback()
      }
    }
  }
}
</script>
<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
}
</style>
