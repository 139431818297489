<template>
  <d2-container>
    <div class="goods">
      <div class="search">
        <head-search label-style="width:100px">
          <head-search-item label="公司名称">
            <el-select
              size="small"
              v-model="search.orgId"
              placeholder="请选择公司名称"
            >
              <el-option
                v-for="item in orgOptions"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </head-search-item>
          <head-search-item label="仓库名称">
            <el-select
              size="small"
              v-model="search.warehouseId"
              placeholder="请选择仓库名称"
            >
              <el-option
                v-for="item in warehouseIdOptions"
                :key="item.warehouseId"
                :label="item.warehouseName"
                :value="item.warehouseId"
              ></el-option>
            </el-select>
          </head-search-item>
          <template slot="button">
            <el-button
              icon="el-icon-search"
              type="primary"
              @click="Search"
              size="small"
            >
              搜索
            </el-button>
            <el-button
              icon="el-icon-refresh"
              type="warning"
              @click="Reset"
              size="small"
            >
              重置
            </el-button>
            <el-button
              type="success"
              size="small"
              @click="exportExcel"
              icon="el-icon-download"
              :disabled="exportLoading"
              :loading="exportLoading"
              v-if="!permission.isExport"
            >
              导出
            </el-button>
            <slot></slot>
          </template>
        </head-search>
      </div>
      <div class="table">
        <el-table
          ref="goodsTable"
          v-loading="tableLoading"
          :data="stockTable"
          max-height="620px"
          @sort-change="SortChange"
          element-loading-text="数据加载中"
          :cell-style="{ textAlign: 'center' }"
          tooltip-effect="dark"
          :row-style="{ height: '57px' }"
          :header-cell-style="{
            background: '#dfe6ec',
            color: '#666666',
            textAlign: 'center'
          }"
        >
          <el-table-column align="center" label="序号">
            <template slot-scope="scope">
              {{ (page.pageNo - 1) * page.pageSize + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="公司名称"
            prop="orgName"
          ></el-table-column>
          <el-table-column
            align="center"
            label="仓库名称"
            prop="warehouseName"
          ></el-table-column>
          <el-table-column
            align="center"
            label="所属部门"
            prop="deptName"
          ></el-table-column>
          <el-table-column
            align="center"
            label="区域数量"
            prop="areaNum"
          ></el-table-column>
          <el-table-column
            align="center"
            label="货架数量"
            prop="shelvesNum"
          ></el-table-column>
          <el-table-column
            align="center"
            label="层数总计"
            prop="shelvesLayers"
          ></el-table-column>
          <el-table-column
            align="center"
            label="在库金额"
            prop="price"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            align="center"
            label="在库数量"
            prop="sum"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            align="center"
            label="在途数量"
            prop="boat"
            sortable="custom"
          >
            <template slot-scope="scope">
              <div
                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
              >
                {{
                  scope.row.boat === null || scope.row.boat === undefined
                    ? '- -'
                    : scope.row.boat
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="在途金额"
            prop="boatPrice"
          ></el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                @click="toDetails(scope.row)"
                type="info"
                icon="el-icon-info"
                size="small"
                plain
                v-if="!permission.isInfo"
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentchange"
          :current-page="page.pageNo"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="page.pageSize"
          layout="->,total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        ></el-pagination>
      </div>
    </div>
  </d2-container>
</template>
<script>
import api from '@/api'
import { downloadFileByUrl } from '@/utils/index'
const searchFormat = {
  field: '',
  orgId: '',
  sort: 0,
  warehouseId: ''
}
const Copy = value => {
  return JSON.parse(JSON.stringify(value))
}
export default {
  name: 'goods',
  data () {
    return {
      permission: {
        isExport: this.checkauthority('location_export'), // 导出
        isInfo: this.checkauthority('storage_list') // 详情
      },
      warehouseIdOptions: [],
      orgOptions: [],
      stockTable: [],
      page: {
        pageNo: 1,
        pageSize: 50
      },
      pageTotal: 0,
      tableLoading: false,
      org: [],
      search: Copy(searchFormat),
      exportLoading: false
    }
  },
  created () {
    this.getList()
    this.getWarehouse()
    this.getOrg()
  },
  methods: {
    sizeChange (val) {
      this.page.pageSize = val
      this.getList()
    },
    currentchange (val) {
      this.page.pageNo = val
      this.getList()
    },
    // 查询仓库
    async getWarehouse () {
      const { body: data } = await api.getPurchaseWarehouse()
      this.warehouseIdOptions = data
    },
    // 查询公司
    async getOrg () {
      const { body: data } = await api.getorgselect()
      this.orgOptions = data
    },
    Search () {
      this.page.pageNo = 1
      this.getList()
    },
    Reset () {
      this.page.pageNo = 1
      this.search = Copy(searchFormat)
      this.getList()
    },
    async exportExcel () {
      this.exportLoading = true
      try {
        const { body: data } = await api.inventory_storage_locationExport(
          this.search
        )
        downloadFileByUrl(data)
      } finally {
        this.exportLoading = false
      }
    },
    async getList () {
      this.tableLoading = true
      const { body: data } = await api.varehousetable_getList_stock(
        Object.assign(this.search, this.page)
      )
      this.tableLoading = false
      this.pageTotal = data.sumDataCount
      this.stockTable = data.datas
    },
    toDetails (row) {
      this.$router.push({
        path: '/stockposition',
        query: {
          warehouseId: row.warehouseId
        }
      })
    },
    SortChange (sort) {
      this.search.sort = sort.order === 'ascending' ? 0 : 1
      this.search.field = sort.prop
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  margin-left: 20px;
}
</style>
