var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            title: "新增商品",
            visible: _vm.dialogFormVisible,
            top: "5vh",
            width: "57%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.closeDialog()
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "redactData",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.redactData,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c("div", { staticStyle: { width: "100%" } }, [
                _c("div", { staticClass: "class1" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50%",
                        display: "flex",
                        "flex-direction": "column"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品名称", prop: "goodsName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              clearable: "",
                              maxlength: "60",
                              placeholder: "请输入商品名称"
                            },
                            model: {
                              value: _vm.redactData.goodsName,
                              callback: function($$v) {
                                _vm.$set(_vm.redactData, "goodsName", $$v)
                              },
                              expression: "redactData.goodsName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品IP", prop: "goodsIp" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                clearable: "",
                                placeholder: "请选择商品IP"
                              },
                              model: {
                                value: _vm.redactData.goodsIp,
                                callback: function($$v) {
                                  _vm.$set(_vm.redactData, "goodsIp", $$v)
                                },
                                expression: "redactData.goodsIp"
                              }
                            },
                            _vm._l(_vm.goodsIpData, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                staticStyle: {
                                  width: "300px",
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis"
                                },
                                attrs: { label: item.ipName, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品品牌", prop: "goodsBrand" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                id: "goodsBrand",
                                clearable: "",
                                placeholder: "请选择商品品牌"
                              },
                              model: {
                                value: _vm.redactData.goodsBrand,
                                callback: function($$v) {
                                  _vm.$set(_vm.redactData, "goodsBrand", $$v)
                                },
                                expression: "redactData.goodsBrand"
                              }
                            },
                            _vm._l(_vm.goodsBrandData, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                staticStyle: {
                                  width: "300px",
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis"
                                },
                                attrs: { label: item.brandName, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品分类", prop: "goodsOne" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择商品分类"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.changeOneClass()
                                    }
                                  },
                                  model: {
                                    value: _vm.redactData.goodsOne,
                                    callback: function($$v) {
                                      _vm.$set(_vm.redactData, "goodsOne", $$v)
                                    },
                                    expression: "redactData.goodsOne"
                                  }
                                },
                                _vm._l(_vm.oneClassData, function(item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.classifyName,
                                      value: item.id
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50%",
                        display: "flex",
                        "flex-direction": "column"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { display: "flex" },
                          attrs: { label: "商品图片", prop: "pictureUrl" }
                        },
                        [
                          _c("erp-upload-img", {
                            attrs: {
                              maxCount: 1,
                              showDelete: true,
                              "upload-path": "/commodityManagement/commodity/"
                            },
                            model: {
                              value: _vm.redactData.pictureUrl,
                              callback: function($$v) {
                                _vm.$set(_vm.redactData, "pictureUrl", $$v)
                              },
                              expression: "redactData.pictureUrl"
                            }
                          }),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item tooltip",
                              attrs: {
                                content:
                                  "注：商品方图请上传宽为500，高为500的图片",
                                effect: "dark",
                                placement: "top-start"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-info",
                                staticStyle: {
                                  color: "#1890ff",
                                  "margin-left": "20px"
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]),
              _c(
                "div",
                {
                  staticStyle: { "margin-top": "10px", "margin-right": "10px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品详情" } },
                    [
                      _c("erp-Editor", {
                        ref: "goodsDeatil",
                        staticStyle: { "text-align": "left" },
                        attrs: {
                          uploadPath: "/commodityManagement/goodsDeatil/",
                          value: _vm.redactData.goodsDeatil,
                          editorStyle:
                            "text-align:left;margin: 21px;width: calc(100% - 20px);"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { display: "flex", "justify-content": "center" }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("redactData")
                        }
                      }
                    },
                    [_vm._v(" 新增 ")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }