/**
 * <文件表请求接口
 * @author 王振宇
 * @since 2021-04-20
 */
export default ({ service, request, serviceForMock, requestForMock, mock, faker, tools }) => ({
  GET_FILE_PAGE_LIST (data = {}) {
    return request({
      url: '/v1/file/getPageList',
      method: 'get',
      params: data
    })
  },
  GET_FILE_INFO () {
    return request({
      url: '/v1/file/info',
      method: 'get'
    })
  },
  SAVE_FILE (data = {}) {
    return request({
      url: '/v1/file/save',
      method: 'post',
      data: data
    })
  },
  UPDATE_FILE (data = {}) {
    return request({
      url: '/v1/file/update',
      method: 'put',
      data: data
    })
  },
  DELETE_FILE (data = {}) {
    return request({
      url: '/v1/file/delete',
      method: 'delete',
      data: data
    })
  },
  DELETE_BATCH_FILE (data = {}) {
    return request({
      url: '/v1/file/deleteBatch',
      method: 'delete',
      data: data
    })
  }
})
