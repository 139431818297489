<template>
  <d2-container>
    <head-search label-style="width:100px">
      <head-search-item label="调拨单号">
        <el-input v-model="search.transferId" class="searchInput" clearable placeholder="请输入调拨单号"
          prefix-icon="el-icon-search" size="small" style="width: 200px"></el-input>
      </head-search-item>
      <head-search-item label="调拨标题">
        <el-input v-model="search.name" class="searchInput" clearable placeholder="请输入调拨标题" prefix-icon="el-icon-search"
          size="small" style="width: 200px"></el-input>
      </head-search-item>
      <head-search-item label="来源仓库">
        <el-select v-model="search.outWarehouseId" class="searchInput" clearable filterable placeholder="请选择调来源仓库"
          size="small" @change="changeOutWarehouse">
          <el-option v-for="item in warehouseOutData" :key="item.warehouseId" :label="item.warehouseName"
            :value="item.warehouseId" v-bind:title="onHoverText" @mouseenter.native="onMouseOver(item.warehouseName)">
          </el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="调入部门">
        <el-select v-model="search.intoDeptId" class="searchInput" clearable placeholder="请选择调入部门" size="small"
          style="margin-right: 10px" @change="changeSet">
          <el-option v-for="item in foldDepartmentData" :key="item.orgId" :label="item.orgName" :value="item.orgId">
          </el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="调入仓库">
        <el-select v-model="search.intoWarehouseId" :disabled="search.intoDeptId === ''" class="searchInput" clearable
          placeholder="请选择调入仓库" size="small" style="margin-right: 10px" @click.native="getIntoWarehouse">
          <el-option v-for="item in warehouseIntoData" :key="item.warehouseId" :label="item.warehouseName"
            :value="item.warehouseId">
          </el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="调拨状态">
        <el-select v-model="search.status" class="searchInput" clearable placeholder="请选择状态" size="small">
          <el-option v-for="(item, index) in statusData" :key="index" :label="item.value" :value="item.id"
            v-bind:title="onHoverText" @mouseenter.native="onMouseOver(item.value)">
          </el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="创建日期">
        <el-date-picker v-model="search.submitTimeStamp" class="searchTimer" end-placeholder="结束日期" range-separator="至"
          size="small" start-placeholder="开始日期" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </head-search-item>
      <head-search-item label="审核日期">
        <el-date-picker v-model="search.auditTimeStamp" class="searchTimer" end-placeholder="结束日期" range-separator="至"
          size="small" start-placeholder="开始日期" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </head-search-item>
      <template slot="button">
        <el-button icon="el-icon-search" size="small" style="margin-right: 10px; margin-left: 25px" type="primary"
          @click="searchTotal()">搜索
        </el-button>
        <el-button icon="el-icon-refresh" size="small" style="margin-right: 10px" type="warning" @click="reset()">重置
        </el-button>
        <el-button icon="el-icon-plus" size="small" v-if="!this.managerFlag && !this.isSuper" style="margin-right: 10px"
          type="success" @click="add()">新增
        </el-button>
      </template>
    </head-search>
    <el-tabs v-model="activeName" style="margin-top: 10px" type="border-card" @tab-click="handleClick">
      <el-tab-pane :label="this.managerFlag || this.isSuper ? '全部' : '已发起'"
        :name="this.managerFlag || this.isSuper ? 'complete' : 'initiated'">
        <div style="margin-top: 10px; margin-left: 10px; margin-right: 10px">
          <el-table ref="tableData" v-loading="loadingTotal" :cell-style="{ textAlign: 'center' }" :data="tableData"
            :header-cell-style="{
              background: '#dfe6ec',
              color: '#666666',
              textAlign: 'center',
            }" :row-style="{ height: '57px' }" element-loading-text="数据加载中" max-height="618px" tooltip-effect="dark">
            <el-table-column align="center" label="序号" width="80">
              <template slot-scope="scope">
                {{ (page.pageNo - 1) * page.pageSize + scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="创建时间" prop="createdTimestamp" width="180">
            </el-table-column>
            <el-table-column align="center" label="调拨单号" prop="transferId" width="180">
              <template slot-scope="scope">
                <div style="display: flex; justify-content: center; width: 100%">
                  {{ scope.row.transferId }}
                  <div class="copySty" style="margin-left: 20px" @click="copyUrl(scope.row.transferId)">
                    复制
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="调拨标题" prop="name" align="center" width="150">
              <template slot-scope="scope">
                <div style="max-height: 33px;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                      ">
                  {{ scope.row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="来源仓库" prop="outWarehouseName" width="150">
            </el-table-column>
            <el-table-column align="center" label="调入部门" prop="intoDeptName" width="150">
            </el-table-column>
            <el-table-column align="center" label="调入仓库" prop="intoWarehouseName" width="150">
            </el-table-column>
            <el-table-column align="center" label="调拨状态" prop="status" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 0">
                  <el-tag>待审核</el-tag>
                </div>
                <div v-if="scope.row.status == 1">
                  <el-tag type="success">完成订单</el-tag>
                </div>
                <div v-if="scope.row.status == 2">
                  <el-tag type="danger">审核不通过</el-tag>
                </div>
                <div v-if="scope.row.status == 3">
                  <el-tag type="success">选择库位完成</el-tag>
                </div>
                <div v-if="scope.row.status == 4">
                  <el-tag type="success">审核通过</el-tag>
                </div>
                <div v-if="scope.row.status == 9">
                  <el-tag type="warning">终止调拨</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" align="center" label="备注信息" prop="remark" width="200">
              <template slot-scope="scope">
                <div style="
                                      max-height: 33px;
                                      overflow: hidden;
                                      white-space: nowrap;
                                      text-overflow: ellipsis;
                                    ">
                  {{ scope.row.remark ? scope.row.remark : "- -" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="审核人" prop="applyName" width="120">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.applyName ? scope.row.applyName : "- -" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="审核时间" prop="applyTimestamp" width="180">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.applyTimestamp ? scope.row.applyTimestamp : "- -" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed="right" label="操作" width="300">
              <template slot-scope="scope">
                <el-button v-if="!managerFlag && !isSuper && scope.row.status === 0" plain size="small" type="primary"
                  @click="edit(scope.row, activeName)">
                  编辑
                </el-button>
                <el-button plain size="small" type="info" @click="returnInfo(scope.row, activeName)">详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="display: flex; justify-content: right; margin-top: 10px">
          <el-pagination v-if="this.page.pageSize > 0" :current-page="page.pageNo" :page-size="page.pageSize"
            :page-sizes="[10, 20, 30, 40, 50, 100]" :total="page.sumDataCount"
            layout="total, jumper, prev, pager, next,sizes" @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
          </el-pagination>
          <allotApplyDialog ref="allotApplyDialog"></allotApplyDialog>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="!this.managerFlag && !this.isSuper" label="已接收" name="pending">
        <div style="margin-top: 10px; margin-left: 10px; margin-right: 10px">
          <el-table ref="tableData" v-loading="loadingTotal" :cell-style="{ textAlign: 'center' }" :data="tableData"
            :header-cell-style="{
              background: '#dfe6ec',
              color: '#666666',
              textAlign: 'center',
            }" :row-style="{ height: '57px' }" element-loading-text="数据加载中" max-height="618px" tooltip-effect="dark">
            <el-table-column align="center" label="序号" width="80">
              <template slot-scope="scope">
                {{ (page.pageNo - 1) * page.pageSize + scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="创建时间" prop="createdTimestamp" width="180">
            </el-table-column>
            <el-table-column align="center" label="调拨单号" prop="transferId" width="180">
              <template slot-scope="scope">
                <div style="display: flex; justify-content: center; width: 100%">
                  {{ scope.row.transferId }}
                  <div class="copySty" style="margin-left: 20px" @click="copyUrl(scope.row.transferId)">
                    复制
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="调拨标题" prop="name" align="center" width="150">
              <template slot-scope="scope">
                <div style="max-height: 33px;
                                      overflow: hidden;
                                      white-space: nowrap;
                                      text-overflow: ellipsis;
                                    ">
                  {{ scope.row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="来源仓库" prop="outWarehouseName" width="150">
            </el-table-column>
            <el-table-column align="center" label="调入部门" prop="intoDeptName" width="150">
            </el-table-column>
            <el-table-column align="center" label="调入仓库" prop="intoWarehouseName" width="150">
            </el-table-column>
            <el-table-column align="center" label="调拨状态" prop="status" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 0">
                  <el-tag>待审核</el-tag>
                </div>
                <div v-if="scope.row.status == 1">
                  <el-tag type="success">完成订单</el-tag>
                </div>
                <div v-if="scope.row.status == 2">
                  <el-tag type="danger">审核不通过</el-tag>
                </div>
                <div v-if="scope.row.status == 4">
                  <el-tag type="success">审核通过</el-tag>
                </div>
                <div v-if="scope.row.status == 3">
                  <el-tag type="success">选择库位完成</el-tag>
                </div>
                <div v-if="scope.row.status == 9">
                  <el-tag type="warning">终止调拨</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" align="center" label="备注信息" prop="remark" width="200">
              <template slot-scope="scope">
                <div style="max-height: 33px;
                                      overflow: hidden;
                                      white-space: nowrap;
                                      text-overflow: ellipsis;
                                    ">
                  {{ scope.row.remark ? scope.row.remark : "- -" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="审核人" prop="applyName" width="120">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.applyName ? scope.row.applyName : "- -" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="审核时间" prop="applyTimestamp" width="180">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.applyTimestamp ? scope.row.applyTimestamp : "- -" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed="right" label="操作" width="350">
              <template slot-scope="scope">
                <el-button v-if="scope.row.status === 4" plain size="small" type="primary"
                  @click="audit(scope.row, activeName)">选择货位
                </el-button>
                <el-button v-if="scope.row.status === 3" :disabled="auditMakeSureLoading" :loading="auditMakeSureLoading" plain size="small" type="success"
                  @click="auditMakeSure(scope.row)">完成订单
                </el-button>
                <el-button v-if="scope.row.status === 0" :disabled="auditPassLoading" :loading="auditPassLoading" plain size="small" type="success"
                  @click="auditPass(scope.row)">审核通过
                </el-button>
                <el-button v-if="scope.row.status === 0" :disabled="auditRejectionLoading" :loading="auditRejectionLoading" plain size="small" type="danger"
                  @click="auditRejection(scope.row)">审核拒绝
                </el-button>
                <el-button plain size="small" type="info" @click="returnInfo(scope.row, activeName)">详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="display: flex; justify-content: right; margin-top: 10px">
          <el-pagination v-if="this.page.pageSize > 0" :current-page="page.pageNo" :page-size="page.pageSize"
            :page-sizes="[10, 20, 30, 40, 50, 100]" :total="page.sumDataCount"
            layout="total, jumper, prev, pager, next,sizes" @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <allotApplyDialog ref="allotApplyDialog" @customEvent="searchTotal"></allotApplyDialog>
  </d2-container>
</template>

<script>
import api from '@/api'
import allotApplyDialog from './allotApplyDialog/index'

// 搜索数据
const searchDefault = {
  transferId: '', // 调拨订单
  name: '', // 调拨标题
  outWarehouseId: '', // 来源仓库
  intoDeptId: '', // 调入部门
  intoWarehouseId: '', // 调入仓库
  status: '', // 状态
  submitTimeStamp: [], // 创建时间
  auditTimeStamp: [] // 审核时间
}
// 分页数据
const pageDefault = {
  pageNo: 1,
  pageSize: 50,
  sumDataCount: 0
}
export default {
  name: 'allotApply',
  components: {
    allotApplyDialog
  },
  data () {
    return {
      onHoverText: '', // 鼠标以上显示文字
      statusData: [
        // 状态Data
        {
          id: '0',
          value: '待审核'
        },
        {
          id: '1',
          value: '完成订单'
        },
        {
          id: '2',
          value: '审核不通过'
        },
        {
          id: '3',
          value: '选择库位完成'
        },
        {
          id: '4',
          value: '审核通过'
        },
        {
          id: '9',
          value: '终止调拨'
        }
      ],
      warehouseOutData: [], // 来源仓库Data
      foldDepartmentData: [], // 部门Data
      warehouseIntoData: [], // 部门下仓库Data
      search: JSON.parse(JSON.stringify(searchDefault)), // 搜索条件
      page: JSON.parse(JSON.stringify(pageDefault)), // 搜索条件
      tableData: [], // 表格数据
      loadingTotal: false, // 加载状态
      auditMakeSureLoading: false,
      auditPassLoading: false,
      auditRejectionLoading: false,
      userInfo: {}, // 用户信息
      managerFlag: false, // is总经办
      isSuper: false, // isSuper
      deptId: '', // 部门id
      activeName: '' // 切换名称
    }
  },
  created () {
    const obj = JSON.parse(window.localStorage.getItem('userInfo'))
    this.userInfo = obj
    if (this.userInfo.orgId === 0 && this.userInfo.deptId === 0) {
      this.isSuper = true
    } else {
      this.isSuper = false
    }
    this.managerFlag = obj.managerFlag
    this.deptId = obj.deptId
    // 总经办
    if (this.managerFlag === true) {
      this.getPurchaseSourceWarehouseIds() // 来源仓库
    } else if (this.managerFlag === false) {
      this.getUserWarehouse() // 来源仓库
    }
    this.getPart()
    this.getWarehouse()
    this.activeName =
      this.managerFlag || this.isSuper ? 'complete' : 'initiated'
    this.handleClick({ index: '0' })
  },
  methods: {
    // 点击标签
    async handleClick (e) {
      this.search = JSON.parse(JSON.stringify(searchDefault))
      this.page = JSON.parse(JSON.stringify(pageDefault))
      if (this.managerFlag || this.isSuper) {
        this.activeName = 'complete'
        await this.getSubmitterList()
      } else {
        if (e.index === '0') {
          this.activeName = 'initiated'
          await this.getSubmitterList()
        } else if (e.index === '1') {
          this.activeName = 'pending'
          await this.getAcceptorList()
        }
      }
    },
    // 获取来源仓库 (总经办)
    async getPurchaseSourceWarehouseIds () {
      const res = await api.getPurchaseSourceWarehouseIds()
      this.warehouseOutData = res.body
    },
    // 获取来源仓库 (普通用户)
    async getUserWarehouse () {
      const res = await api.getDepartmentToWarehouse({
        deptId: this.deptId
      })
      this.warehouseOutData = res.body
    },
    // 普通用户下获取部门
    async getPart () {
      const res = await api.getDeptList({ orgPid: this.userInfo.orgId })
      this.foldDepartmentData = res.body.filter(
        (item) => item.orgManagerFlag !== 1
      )
    },
    // 获得仓库
    async getWarehouse () {
      const res = await api.getDepartmentToWarehouse({
        deptId: this.userInfo.deptId
      })
      this.warehouseIntoData = res.body.filter(
        (item) => item.warehouseId !== this.search.outWarehouseId
      )
    },
    // 改变来源仓库
    changeOutWarehouse () {
      this.search.intoWarehouseId = ''
    },
    // 改变选择部门
    async changeSet () {
      this.search.intoWarehouseId = ''
      const res = await api.getDepartmentToWarehouse({
        deptId: this.search.intoDeptId
      })
      this.warehouseIntoData = res.body.filter(
        (item) => item.warehouseId !== this.search.outWarehouseId
      )
    },
    // 点击获取调入仓库
    async getIntoWarehouse () {
      const res = await api.getDepartmentToWarehouse({
        deptId: this.search.intoDeptId
      })
      this.warehouseIntoData = res.body.filter(
        (item) => item.warehouseId !== this.search.outWarehouseId
      )
    },
    // 获取总经办查看列表 或 接收方列表
    async getSubmitterList () {
      this.loadingTotal = true
      try {
        const res = await api.getPageList_allotApply({
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize,
          auditStart: this.search.auditTimeStamp[0],
          auditEnd: this.search.auditTimeStamp[1],
          createTimeStamp: this.search.submitTimeStamp[0],
          endTimeStamp: this.search.submitTimeStamp[1],
          intoDeptId: this.search.intoDeptId,
          intoWarehouseId: this.search.intoWarehouseId,
          name: this.search.name,
          outWarehouseId: this.search.outWarehouseId,
          status: this.search.status,
          transferId: this.search.transferId
        })
        this.tableData = res.body.datas
        this.page.sumDataCount = res.body.sumDataCount
        this.page.pageSize = res.body.pageSize
      } catch (e) {
        this.$message({
          type: 'warning',
          message: '调拨页面加载失败!'
        })
      } finally {
        this.loadingTotal = false
      }
    },
    // 获取 接收方列表
    async getAcceptorList () {
      this.loadingTotal = true
      try {
        const res = await api.getPageList_auditTransfer({
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize,
          auditStart: this.search.auditTimeStamp[0],
          auditEnd: this.search.auditTimeStamp[1],
          createTimeStamp: this.search.submitTimeStamp[0],
          endTimeStamp: this.search.submitTimeStamp[1],
          intoDeptId: this.search.intoDeptId,
          intoWarehouseId: this.search.intoWarehouseId,
          name: this.search.name,
          outWarehouseId: this.search.outWarehouseId,
          status: this.search.status,
          transferId: this.search.transferId
        })
        this.tableData = res.body.datas
        this.page.sumDataCount = res.body.sumDataCount
        this.page.pageSize = res.body.pageSize
      } catch (e) {
        this.$message({
          type: 'warning',
          message: '调拨页面加载失败!'
        })
      } finally {
        this.loadingTotal = false
      }
    },
    // 鼠标以上显示完整名称
    onMouseOver (item) {
      this.onHoverText = item
    },
    // /定义点击复制的功能
    copyUrl (url) {
      // 创建一个 Input标签
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      // 执行浏览器复制命令
      // / 复制命令会将当前选中的内容复制到剪切板中
      // / 如这里构建的 Input标签
      document.execCommand('Copy')
      this.$message({
        message: '复制成功',
        type: 'success'
      })
      // /复制成功后再将构造的标签 移除
      oInput.remove()
    },
    // 搜索
    async searchTotal () {
      this.page.pageNo = 1
      if (this.managerFlag || this.isSuper) {
        await this.getSubmitterList()
      } else {
        if (this.activeName === 'initiated') {
          await this.getSubmitterList()
        } else if (this.activeName === 'pending') {
          await this.getAcceptorList()
        }
      }
    },
    // 搜索分页
    async handleSizeChange (val) {
      this.page.pageSize = val
      if (this.managerFlag || this.isSuper) {
        await this.getSubmitterList()
      } else {
        if (this.activeName === 'initiated') {
          await this.getSubmitterList()
        } else if (this.activeName === 'pending') {
          await this.getAcceptorList()
        }
      }
    },
    // 搜索跳转页数
    async handleCurrentChange (val) {
      this.page.pageNo = val
      if (this.managerFlag || this.isSuper) {
        await this.getSubmitterList()
      } else {
        if (this.activeName === 'initiated') {
          await this.getSubmitterList()
        } else if (this.activeName === 'pending') {
          await this.getAcceptorList()
        }
      }
    },
    // 重置
    async reset () {
      this.search = JSON.parse(JSON.stringify(searchDefault))
      this.page = JSON.parse(JSON.stringify(pageDefault))
      if (this.managerFlag || this.isSuper) {
        await this.getSubmitterList()
      } else {
        if (this.activeName === 'initiated') {
          await this.getSubmitterList()
        } else if (this.activeName === 'pending') {
          await this.getAcceptorList()
        }
      }
    },
    // 新增
    add () {
      this.$refs.allotApplyDialog.openDialog(
        '新增调拨单',
        undefined,
        undefined
      )
    },
    // 编辑
    edit (item, activeName) {
      this.$refs.allotApplyDialog.openDialog(
        '编辑调拨单',
        item,
        this.managerFlag,
        activeName
      )
    },
    // 跳转至详情
    async returnInfo (item, activeName) {
      await this.$router.push({
        path: '/allotApplyDetail',
        query: {
          id: item.id,
          transferId: item.transferId,
          activeName: activeName
        }
      })
    },
    // 审核
    audit (item) {
      this.$refs.allotApplyDialog.openDialog(
        '审核调拨单',
        item,
        this.managerFlag
      )
    },
    // 完成订单
    auditMakeSure (row) {
      this.$confirm('是否完成’' + row.transferId + '订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          try {
            this.auditMakeSureLoading = true
            const res = await api.check_allotApply({
              id: row.id,
              status: 1
            })
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              if (this.managerFlag) {
                await this.getSubmitterList()
              } else {
                if (this.activeName === 'initiated') {
                  await this.getSubmitterList()
                } else if (this.activeName === 'pending') {
                  await this.getAcceptorList()
                }
              }
            }
          } catch (error) {
            this.$message({
              type: 'warning',
              message: '操作失败!'
            })
          } finally {
            this.auditMakeSureLoading = false
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    // 审核通过
    auditPass (row) {
      this.$confirm('是否将’' + row.transferId + '‘审核通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          try {
            this.auditPassLoading = true
            const res = await api.check_allotApply({
              id: row.id,
              status: 4
            })
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              if (this.managerFlag) {
                await this.getSubmitterList()
              } else {
                if (this.activeName === 'initiated') {
                  await this.getSubmitterList()
                } else if (this.activeName === 'pending') {
                  await this.getAcceptorList()
                }
              }
            }
          } catch (error) {
            this.$message({
              type: 'warning',
              message: '操作失败!'
            })
          } finally {
            this.auditPassLoading = false
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    // 审核不通过
    auditRejection (row) {
      this.$confirm('是否将’' + row.transferId + '‘审核不通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          try {
            this.auditRejectionLoading = true
            const res = await api.check_allotApply({
              id: row.id,
              status: 2
            })
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              if (this.managerFlag) {
                await this.getSubmitterList()
              } else {
                if (this.activeName === 'initiated') {
                  await this.getSubmitterList()
                } else if (this.activeName === 'pending') {
                  await this.getAcceptorList()
                }
              }
            }
          } catch (error) {
            this.$message({
              type: 'warning',
              message: '操作失败!'
            })
          } finally {
            this.auditRejectionLoading = false
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
}

.searchInput {
  margin-right: 20px;
  width: 200px !important;
}

.searchTimer {
  margin-right: 20px;
  width: 400px !important;
}

.allocateNoSty {
  font-size: 10px;
  cursor: pointer;
  color: #1482f0;
}

.copySty {
  font-size: 10px;
  cursor: pointer;
  color: green;
}

::v-deep .el-table .warning-row {
  background: #ffcc66;
}
</style>
