var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", { staticClass: "all" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "head-search",
          { staticClass: "top", attrs: { "label-style": "width:100px;" } },
          [
            _c(
              "head-search-item",
              { staticClass: "topBox", attrs: { label: "盘库标题" } },
              [_c("el-tag", [_vm._v(_vm._s(_vm.data.checkName))])],
              1
            ),
            _c(
              "head-search-item",
              { staticClass: "topBox", attrs: { label: "盘库维度" } },
              [
                _c("el-tag", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.data.dimension == 0
                          ? "商品维度"
                          : _vm.data.dimension == 1
                          ? "货架维度"
                          : ""
                      )
                  )
                ])
              ],
              1
            ),
            _c(
              "head-search-item",
              { staticClass: "topBox", attrs: { label: "盘点仓库" } },
              [_c("el-tag", [_vm._v(_vm._s(_vm.data.warehouseName))])],
              1
            ),
            _c(
              "head-search-item",
              { staticClass: "topBox", attrs: { label: "盘库单号" } },
              [_c("el-tag", [_vm._v(_vm._s(_vm.data.checkId))])],
              1
            ),
            _c(
              "head-search-item",
              { staticClass: "topBox", attrs: { label: "盘库状态" } },
              [
                _c("el-tag", [
                  _vm._v(_vm._s(_vm.almightyM(_vm.data.status, "盘库状态")))
                ])
              ],
              1
            ),
            _c(
              "head-search-item",
              { staticClass: "topBox", attrs: { label: "盘库人员" } },
              [_c("el-tag", [_vm._v(_vm._s(_vm.data.createdName))])],
              1
            ),
            _c(
              "head-search-item",
              { staticClass: "topBox", attrs: { label: "创建时间" } },
              [_c("el-tag", [_vm._v(_vm._s(_vm.data.createdTimestamp))])],
              1
            ),
            _c(
              "head-search-item",
              { staticClass: "topBox", attrs: { label: "完成时间" } },
              [_c("el-tag", [_vm._v(_vm._s(_vm.data.endTime))])],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "textarea",
            staticStyle: { padding: "15px", "font-size": "14px" }
          },
          [
            _vm._v(" 盘库备注： "),
            _c("el-input", {
              attrs: { type: "textarea", disabled: "", placeholder: "" },
              model: {
                value: _vm.data.remark,
                callback: function($$v) {
                  _vm.$set(_vm.data, "remark", $$v)
                },
                expression: "data.remark"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "title" },
          [
            _c("div", [_vm._v("商品列表")]),
            _c(
              "el-button",
              { attrs: { type: "primary", plain: "", size: "small" } },
              [_vm._v("导出")]
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            attrs: {
              data:
                _vm.data.dimension == 0
                  ? _vm.data.checkGoodsList
                  : _vm.data.inventoryList,
              "header-cell-style": _vm.headerClass,
              "cell-style": _vm.rowClass
            }
          },
          [
            _c("el-table-column", {
              attrs: { prop: "", label: "序号" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { prop: "goodsCoding", label: "商品编码" }
            }),
            _c("el-table-column", {
              attrs: { prop: "goodsName", label: "商品名称" }
            }),
            _c("el-table-column", {
              attrs: { prop: "pictureUrl", label: "商品图片" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("img", {
                        staticStyle: {
                          width: "50px",
                          height: "50px",
                          "margin-top": "8px",
                          cursor: "pointer"
                        },
                        attrs: {
                          src: _vm.downloadUrl + "/" + scope.row.pictureUrl
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { prop: "intoPrices", label: "入库价" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [_vm._v(" " + _vm._s(scope.row.intoPrice) + " ")]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { prop: "goodsNum", label: "在库数量" }
            }),
            _c("el-table-column", {
              attrs: { prop: "checkNum", label: "实际库存" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.checkNum +
                              (scope.row.scanNum ? scope.row.scanNum : 0)
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { prop: "", label: "盘点盈亏" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-tag", { attrs: { type: _vm.colorM(scope.row) } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.checkNum +
                                (scope.row.scanNum ? scope.row.scanNum : 0) -
                                scope.row.goodsNum
                            )
                        )
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _c("div", { staticClass: "title" }, [_vm._v("盘库结果")]),
        _c("div", { staticClass: "bottom" }, [
          _c("div", [_vm._v("待盘点数量:" + _vm._s(_vm.Dnum))]),
          _c("div", [_vm._v("已盘点数量:" + _vm._s(_vm.Ynum))]),
          _c("div", [_vm._v("盘盈:" + _vm._s(_vm.Pwin))]),
          _c("div", [_vm._v("盘亏:" + _vm._s(_vm.Plose))]),
          _c(
            "div",
            { staticClass: "textarea" },
            [
              _vm._v(" 盈亏原因备注： "),
              _c("el-input", {
                attrs: { type: "textarea", disabled: "", placeholder: "" },
                model: {
                  value: _vm.data.checkRemark,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "checkRemark", $$v)
                  },
                  expression: "data.checkRemark"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "el-button",
          {
            staticStyle: { margin: "10px calc(50% - 30px)" },
            attrs: { type: "", size: "small" },
            on: {
              click: function($event) {
                return _vm.closeBtn()
              }
            }
          },
          [_vm._v("关闭")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }