export default (function (_ref) {
  var request = _ref.request;
  return {
    // 豆腐块数据
    getTopInfo: function getTopInfo() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/homePage/top/info',
        method: 'get',
        data: query
      });
    },
    // 柱状图数据
    getColumnarStatistical: function getColumnarStatistical() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/homePage/top/orderBrokenLine',
        method: 'post',
        data: data
      });
    },
    // 折现图数据
    getLineStatistics: function getLineStatistics() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/homePage/top/orderProductBrokenLine',
        method: 'post',
        data: data
      });
    },
    // 饼图数据
    getProductStatistics: function getProductStatistics() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/homePage/top/productStatistics',
        method: 'get',
        data: query
      });
    },
    // 商品数据
    getPieStatistics: function getPieStatistics() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/homePage/top/orderStatistics',
        method: 'get',
        data: query
      });
    },
    // 待处理采购单数据
    getPurchaseOrders: function getPurchaseOrders() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/homePage/top/waitPurchaseList',
        method: 'get',
        data: query
      });
    },
    // 待处理调拨单数据
    getAllotOrders: function getAllotOrders() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/homePage/top/waitAllotList',
        method: 'get',
        data: query
      });
    },
    // 待处理预定单数据
    getReserveOrders: function getReserveOrders() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/homePage/top/waitReserveList',
        method: 'get',
        data: query
      });
    },
    // 待处理异常单数据
    getAbnormalOrders: function getAbnormalOrders() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/homePage/top/waitAbnormalList',
        method: 'get',
        data: query
      });
    },
    // 待处理入库单数据
    getWaitIntoOrders: function getWaitIntoOrders() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/homePage/top/waitIntoOrderList',
        method: 'get',
        data: query
      });
    },
    // 待处理出库单数据
    getWaitOutOrders: function getWaitOutOrders() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/homePage/top/waitOutOrderList',
        method: 'get',
        data: query
      });
    },
    // 商品到期提醒excel
    getproductExpirationExcel: function getproductExpirationExcel() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/storage/exportInv',
        method: 'post',
        data: data
      });
    },
    // 商品到期提醒列表
    getproductExpiration: function getproductExpiration() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/inventory/storage/getPageResult',
        method: 'post',
        data: data
      });
    }
  };
});