<template>
  <div class="Inventorylist">
    <d2-container>
      <head-search label-style="width:100px">
        <head-search-item label="入库单号">
          <el-input
            :disabled="auth.isSearch"
            size="small"
            @keyup.enter.native="search"
            v-model="selectForm.enterWarehouseNumber"
            clearable
            placeholder="请输入库单号"
          ></el-input>
        </head-search-item>
        <head-search-item label="商品名称">
          <el-input
            :disabled="auth.isSearch"
            size="small"
            @keyup.enter.native="search"
            v-model="selectForm.goodsName"
            clearable
            placeholder="请输入商品名称"
          ></el-input>
        </head-search-item>
        <head-search-item label="入库状态">
          <el-select
            :disabled="auth.isSearch"
            collapse-tags
            size="small"
            multiple
            v-model="selectForm.enterWarehouseStates"
            placeholder="请选择入库状态"
            clearable
            filterable
          >
            <el-option
              v-for="item in list.WarehousingStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="入库类型">
          <el-select
            :disabled="auth.isSearch"
            collapse-tags
            size="small"
            multiple
            v-model="selectForm.enterWarehouseTypes"
            placeholder="请选择入库类型"
            clearable
            filterable
          >
            <el-option
              v-for="item in list.WarehousingType"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="入库仓库">
          <el-select
            :disabled="auth.isSearch"
            collapse-tags
            size="small"
            multiple
            v-model="selectForm.warehouseIds"
            placeholder="请选择入库仓库"
            clearable
            filterable
          >
            <el-option
              v-for="item in list.WarehousingWarehouse"
              :key="item.warehouseId"
              :label="item.warehouseName"
              :value="item.warehouseId"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="发货方式">
          <el-select
            :disabled="auth.isSearch"
            collapse-tags
            size="small"
            multiple
            v-model="selectForm.deliveryMethods"
            placeholder="请选择发货方式"
            clearable
            filterable
          >
            <el-option
              v-for="item in list.WarehousingShipments"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </head-search-item>
        <head-search-item label="入库日期">
          <el-date-picker
            :disabled="auth.isSearch"
            size="small"
            v-model="selectForm.intoTimeStamp"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </head-search-item>
        <template slot="button">
          <el-button
            v-if="!auth.isSearch"
            icon="el-icon-search"
            type="primary"
            @click="search"
            size="small"
          >
            搜索
          </el-button>
          <el-button
            v-if="!auth.isSearch"
            icon="el-icon-refresh"
            type="warning"
            @click="reset"
            size="small"
          >
            重置
          </el-button>
          <el-button
            v-if="!auth.isAdd"
            icon="el-icon-circle-plus-outline"
            @click="handleAdd"
            type="success"
            size="small"
          >
            新增入库
          </el-button>
        </template>
      </head-search>
      <div class="table">
        <el-table
          ref="tableData"
          v-loading="tableLoading"
          :data="Inventorylist"
          max-height="620px"
          element-loading-text="数据加载中"
          :cell-style="{ textAlign: 'center' }"
          tooltip-effect="dark"
          :row-style="{ height: '57px' }"
          :header-cell-style="{
            background: '#dfe6ec',
            color: '#666666',
            textAlign: 'center'
          }"
        >
          <el-table-column label="序号" width="80" align="center">
            <template slot-scope="scope">
              {{
                (selectForm.pageNo - 1) * selectForm.pageSize + scope.$index + 1
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="enterWarehouseNumber"
            label="入库单号"
            width="150"
          ></el-table-column>
          <el-table-column label="父订单号" width="150">
            <template slot-scope="scope">
              {{ scope.row.fatherId ? scope.row.fatherId : '-' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="enterWarehouseState"
            :formatter="enterWarehouseStateFormat"
            label="入库状态"
            width="150"
          ></el-table-column>
          <!-- <el-table-column
            prop="commodityIntoSum"
            :formatter="commodityIntoSum"
            label="入库总数"
            width="150"
          ></el-table-column> -->
          <el-table-column
            prop="enterWarehouseType"
            label="入库类型"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="warehouseName"
            label="入库仓库"
            width="150"
          ></el-table-column>
          <el-table-column prop="deliveryMethod" label="发货方式" width="150">
            <template slot-scope="scope">
              {{
                scope.row.enterWarehouseType === '调拨'
                  ? '入库'
                  : scope.row.deliveryMethod
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createdTimestamp"
            label="创建时间"
            width="150"
          ></el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="remark"
            label="备注"
            width="150"
          >
            <template slot-scope="scope">
              {{ scope.row.remark ? scope.row.remark : '- -' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="enterWarehouseTimestamp"
            label="入库时间"
            width="150"
          >
            <template slot-scope="scope">
              {{ scope.row.enterWarehouseTimestamp ? scope.row.enterWarehouseTimestamp : '- -' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300" fixed="right">
            <template slot-scope="scope">
              <el-button
                size="small"
                plain
                type="success"
                icon="el-icon-edit-outline"
                v-if="
                  scope.row.enterWarehouseState === 0 &&
                    scope.row.type !== '代发' && !auth.isEdit && !auth.isEditDetail
                "
                @click="handleEdit(scope.$index, scope.row)"
              >
                编辑
              </el-button>
              <el-button
                size="small"
                type="info"
                plain
                icon="el-icon-info"
                v-if="
                  !(
                    scope.row.enterWarehouseType === '代发' &&
                    (scope.row.enterWarehouseState === 0 ||
                      scope.row.enterWarehouseState === 1 ||
                      scope.row.enterWarehouseState === 4)
                  ) && !auth.isDetail && !auth.isGoods
                "
                @click="handleDetails(scope.$index, scope.row)"
              >
                明细
              </el-button>
              <el-button size="small" plain type="primary" @click="toStockIn(scope.row)"
                         v-show="scope.row.enterWarehouseState === 0">开始入库
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="selectForm.pageNo"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="selectForm.pageSize"
          layout="->,total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        ></el-pagination>
        <Dialog @dialogClose="dialogClose" ref="dialog"></Dialog>
        <el-dialog title="开始入库" :visible.sync="dialogVisible" v-loading="dialogLoading" width="80%" @close="stockInClose">
          <el-row>
            <el-col :span="2">
              <el-button plain type="primary" size="small" @click="createCode">生码</el-button>
            </el-col>
            <el-col :span="12" class="mb-10">
              <el-input type="textarea" :rows="2" placeholder="请输入位置备注" v-model="remark"/>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" style="display: flex;">
              <el-button size="small" type="primary" plain @click="fastChooseCache" style="margin-right: 10px;">一键选择货架</el-button>
              <el-cascader v-model="allCache" size="small" clearable style="margin-right: 10px;"
                           :options="locationDownList"
                           :props="{
                               row:null,
                    checkStrictly:true,
                    value:'id',
                    label:'name',
                    children:'children',
                    sourceData:locationDownList,
                    lazy:true,
                    lazyLoad:getDetailDownList
                  }"/>
              <el-input v-model="allEnterWarehouseShelvesNum" size="small" clearable type="primary" plain style="width: 100px" />
            </el-col>
          </el-row>
          <el-table :data="tableData" ref="dialogTable" :border="true" height="450px">
            <el-table-column type="selection" width="55"/>
            <el-table-column label="商品编码" prop="goodsCoding" align="center" width="110px"/>
            <el-table-column label="商品名称" prop="goodsName" align="center" width="180px"/>
            <el-table-column label="商品图片" align="center" width="230px">
              <template slot-scope="scope">
                <el-image :src=" scope.row.goodsPicture? $oss + scope.row.goodsPicture: $oss + scope.row.pictureUrl"
                          :preview-src-list="[scope.row.goodsPicture? $oss + scope.row.goodsPicture: $oss + scope.row.pictureUrl]"
                          style="width: 40px; height: 40px" fit="cover"/>
              </template>
            </el-table-column>
            <el-table-column label="采购数量" prop="enterWarehouseNums" align="center" width="110px"/>
            <el-table-column label="*入库数量" width="130px" align="center">
              <template slot-scope="scope">
                <el-input type="number" v-model="scope.row.num" size="small" style="width: 100px"
                          oninput="value=value.replace(/[^0-9]/g,'').slice(0,9);"/>
              </template>
            </el-table-column>
            <el-table-column label="*位置信息" align="center">
              <template slot-scope="scope">
                <el-cascader v-model="scope.row.location" size="small" clearable
                             @change="locationChange($event,scope.row)"
                             :options="scope.row.locationDownList"
                             :props="{
                               row:scope.row,
                    checkStrictly:true,
                    value:'id',
                    label:'name',
                    children:'children',
                    sourceData:scope.row.locationDownList,
                    lazy:true,
                    lazyLoad:getDetailDownList
                  }"/>
              </template>
            </el-table-column>
            <el-table-column label="入库层数" align="center">
              <template slot-scope="scope">
                <el-input type="number" v-model="scope.row.enterWarehouseShelvesNum" size="small" style="width: 100px"
                          :disabled="scope.row.location.length < 3"
                          oninput="value=value.replace(/[^0-9]/g,'').slice(0,9)"/>
              </template>
            </el-table-column>
            <el-table-column label="放置位置" align="center">
              <template slot-scope="scope">
                <el-input type="textarea" :rows="2" placeholder="请输入手动放置位置" v-model="scope.row.enterWarehouseRemark"/>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="dialogSizeChange" @current-change="dialogCurrentChange" :current-page="tableIndex"
                         :page-sizes="[50, 100, 200, 300, 500]" :page-size="tableSize" layout="->,total, sizes, prev, pager, next, jumper"
                         :total="tableTotal"/>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="dialogSubmit">确定</el-button>
          </span>


        </el-dialog>
      </div>
    </d2-container>
  </div>
</template>

<script>
import api from '@/api'
import Dialog from '../dialog/index.vue'
import { selectFormData, authority } from './contant'
import { cloneDeep } from 'lodash'

var that
export default {
  components: {
    Dialog
  },
  name: 'stockIn',
  data () {
    return {
      tableLoading: false,
      auth: {},
      selectForm: cloneDeep(selectFormData),
      pageTotal: 0,
      list: {
        WarehousingStatus: [
          {
            value: 0,
            label: '待入库'
          },
          {
            value: 1,
            label: '入库中'
          },
          {
            value: 2,
            label: '部分入库'
          },
          {
            value: 3,
            label: '完成入库'
          },
          {
            value: 9,
            label: '已终止'
          }
        ],
        WarehousingType: [
          // { label: '采购', value: '采购' },
          // { label: '调拨', value: '调拨' },
          // { label: '代发', value: '代发' },
          {
            label: '其他',
            value: '其他'
          },
          {
            label: '盘盈',
            value: '盘盈'
          }
          // { label: '退款', value: '退款' }
          // { label: '赠品', value: '赠品' }
        ],
        WarehousingWarehouse: [],
        WarehousingShipments: [
          {
            value: '0',
            label: '入库'
          },
          {
            value: '1',
            label: '代发'
          }
        ]
      },
      Inventorylist: [],
      //开始入库
      currentRow: null,
      dialogVisible: false,
      dialogLoading: false,
      tableData: [],
      tableIndex: 1,
      tableSize: 50,
      tableTotal: 0,
      remark: '',
      //位置下拉数据
      locationDownList: [],

      allCache:[],
      allEnterWarehouseShelvesNum:0,

    }
  },
  created () {
    that = this
  },
  mounted () {
    this.auth = authority(this)
    this.select()
    Promise.all([this.warehouse(), this.orderSource()]).then(() => {
      this.$refs.dialog.setList(this.list)
    })
  },
  methods: {
    dialogClose () {
    },
    // 搜索
    search () {
      this.selectForm.pageNo = 1
      this.select()
    },
    // 获得入库列表
    async select () {
      this.tableLoading = true
      const { body: data } = await api.inventorylist_getPage(this.selectForm)
      this.tableLoading = false
      this.pageTotal = data.sumDataCount
      this.Inventorylist = data.datas
    },
    // 重置搜索
    reset () {
      this.selectForm = JSON.parse(JSON.stringify(selectFormData))
      this.select()
    },
    // 打开编辑弹窗
    handleEdit (index, row) {
      this.$refs.dialog.open().edit(row, this.list)
    },
    // 打开添加弹窗
    handleAdd () {
      this.$refs.dialog.open().add(this.list)
    },
    // 打开明细弹窗
    handleDetails (index, row) {
      this.$router.push({
        path: '/stockindetails',
        query: {
          id: row.enterWarehouseNumber
        }
      })
    },
    // 格式化入库状态
    enterWarehouseStateFormat (row, column, cellValue) {
      switch (cellValue) {
        // 入库状态;0待入库 1入库中 2部分入库 3完成入库 4已终止
        case 0:
          return '待入库'
        case 1:
          return '入库中'
        case 2:
          return '部分入库'
        case 3:
          return '完成入库'
        case 9:
          return '已终止'
      }
    },
    // 获得所有仓库
    async warehouse () {
      const { body: data } = await api.getPurchaseWarehouse()
      this.list.WarehousingWarehouse = data
    },
    async orderSource () {
      const { body: data } = await api.enumeration_getList({ name: 'channel' })
      this.list.warehouseSource = data
    },
    sizeChange (val) {
      this.selectForm.pageSize = val
      this.select()
    },
    currentChange (val) {
      this.selectForm.pageNo = val
      this.select()
    },
    //开始入库
    async toStockIn (row) {
      console.log(row)
      this.currentRow = row
      //获取仓库
      let res = await api.warehouseStructure_searchWarehouse()
      that.locationDownList = res.body.filter(c => c.id == row.warehouseId).map(c => {
        c.children = []
        return c
      })
      console.log('仓库下拉', that.locationDownList)
      this.getDialogData()

      this.remark = ''
      this.dialogVisible = true
    },
    async getDetailDownList (node, resolve) {
      //取出层级，查询id
      let {
        level,
        value,
        config,
        parent,
      } = node
      //获取库位
      console.log(level)
      if (level === 1) {
        let res = await api.warehouseStructure_searchArea({ warehouseId: value })
        console.log('库位下拉', res.body)
        let result = res.body.map(c => {
          return c
        })
        config.sourceData.find(c => c.id == value).children = result;
        resolve(result)
      }
      //获取货架
      if (level === 2) {
        let res = await api.departmentGetChooseShelves({ areaId: value })
        console.log('货架下拉', res.body)
        let result = res.body.map(c => {
          c.name = c.shelvesName
          c.id = c.shelvesId
          c.leaf = true
          c.shelvesNum = c.shelvesNum
          return c
        })
        config.sourceData.find(c =>
          c.id == parent.value).children.find(c => c.id == value).children = result
        resolve(result)
      }
      if (level == 3) {
        if(config.row){
          //取得最大值 用于判断是否超过最大值
          config.row.maxShelvesNum = node.data.shelvesNum
        }
        resolve()
      }
    },
    locationChange (e,row) {
      //选中货架时
      if(e.length == 3) {
        //递归查找
        function handle (arr, value) {
          for(let i = 0; i < arr.length; i++) {
            if(arr[i].id == value) {
              row.maxShelvesNum = arr[i].shelvesNum
              return true
              break
            }
            else if(arr[i].children.length > 0){
              let a = handle(arr[i].children,value)
              if(a){
                return a
                break
              }
            }
          }
        }
        handle(this.locationDownList, e[2])
      }
    },
    fastChooseCache () {
      if(this.allCache.length === 0){
        return this.$message.warning('请先选择位置')
      }
      this.tableData.forEach(c =>{
        c.locationDownList = cloneDeep(this.locationDownList)
        c.location = this.allCache
        c.enterWarehouseShelvesNum = this.allEnterWarehouseShelvesNum
        this.$refs.dialogTable.toggleRowSelection(c)
      })
      this.$forceUpdate()
    },
    async getDialogData () {
      this.dialogLoading = true
      const { body } = await api.getWarehouseDetailGoods({
        enterWarehouseNumber: this.currentRow.enterWarehouseNumber,
        pageNo: this.tableIndex,
        pageSize: this.tableSize
      })
      this.dialogLoading = false
      this.tableData = body.datas.map(c => {
        c.num = 0//数量
        c.location = []//位置信息
        c.enterWarehouseRemark = null//手动放置位置
        c.enterWarehouseShelvesNum = null//入库层数
        c.locationDownList = cloneDeep(this.locationDownList)
        return c
      })
      console.log(this.tableData)
      this.tableTotal = body.sumDataCount
    },
    stockInClose () {
      this.dialogVisible = false
      this.tableData = []
    },
    dialogSizeChange (val) {
      this.tableSize = val
      this.tableIndex = 1
      this.getDialogData()
    },
    dialogCurrentChange (val) {
      this.tableIndex = val
      this.getDialogData()
    },
    async createCode () {
      let res = await api.createCode({
        enterWarehouseNumber: this.currentRow.enterWarehouseNumber,
        printFlag: false,
      })
      if (res.code == 200) {
        that.$message.success('生码成功')
      }
    },
    dialogSubmit () {
      //获取已选中的信息
      let table = this.$refs.dialogTable.selection
      if (table.length == 0) return this.$message.warning('请至少选中一条数据')
      let goodsInfos = table.map(c => {
        let [enterWarehouseId, enterWarehouseArea, enterWarehouseShelf] = c.location
        return {
          id: c.id,
          goodsCoding: c.goodsCoding,
          enterWarehouseId,//仓库id
          enterWarehouseArea,//库位id
          enterWarehouseShelf,//货架id
          enterWarehouseShelvesNum: c.enterWarehouseShelvesNum ? parseInt(c.enterWarehouseShelvesNum) : null,//入库层数
          enterWarehouseRemark: c.enterWarehouseRemark,//手动放置位置
          number: parseInt(c.num),//数量
          enterWarehouseNums: c.enterWarehouseNums, //采购数量 用于判断
          maxShelvesNum:c.maxShelvesNum//最大入库层数
        }
      })
      let type = goodsInfos.every(c =>
        (c.enterWarehouseId || c.enterWarehouseRemark)
        && c.number && c.number <= c.enterWarehouseNums)
      if (!type) return this.$message.warning('请检查信息, 入库数量大于0且不能大于采购数量、至少选择一项位置信息或填写手动放置位置')
      let statu = true;
      for(let i = 0; i < goodsInfos.length; i++){
        let item = goodsInfos[i]
        //如果当前选到了第三层 则判断是否超过最大值
       if(item.enterWarehouseShelf && item.maxShelvesNum < item.enterWarehouseShelvesNum){
         statu = false
         this.$message.warning(`当前${item.goodsCoding}商品的入库层数不能超过${item.maxShelvesNum}`)
         break
       }
      }
      if(!statu) return
      api.goodsInStock({
        enterWarehouseNumber: this.currentRow.enterWarehouseNumber,
        remark: this.remark,
        goodsInfos
      }).then(res => {
        if (res.code == 200) {
          that.$message.success('入库成功')
          that.dialogVisible = false
          that.select()
        } else {
          that.$message.error(res.msg)
        }

      })
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
