<template>
  <div class="setGoodsList">
    <el-dialog title="异常商品处理" ref="dialog" width="85%" :append-to-body="true" :close-on-click-modal="false"
      :visible.sync="dialogVisible" :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
        <div style="display: flex;">
          <div>
            <!-- <el-form-item label="处理方式" prop="logisticsNumbersCompany">
              <el-select v-model="processMode" placeholder="请选择" @change="playC()" size="small">
                <el-option v-for="item in processModeList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="物流单号">
              <el-input v-model="form.logisticsNumbers" class="searchInput" placeholder="请输入物流单号" style="width:200px;"
                clearable size="small"></el-input>
            </el-form-item>
            <el-form-item label="快递公司">
              <el-input v-model="form.logisticsCompany" class="searchInput" placeholder="请输入快递公司" style="width:200px;"
                clearable size="small"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="退款图片">
              <erp-upload-img :showDelete="true" v-model="form.refundProve"
                :upload-path="'/purchasingManagement/purchaseSelectDispose/'" :maxCount="10"></erp-upload-img>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane name="withoutRecord">
          <span slot="label">
            未记录商品处理
            <el-badge :value="withoutRecordList.length" :max="99" :hidden="withoutRecordList.length === 0"></el-badge>
          </span>
          <div style="margin-top: 10px; margin-left: 10px; margin-right: 10px;">
            <el-table ref="withoutRecordList" :data="withoutRecordList" :cell-style="{ textAlign: 'center' }"
              tooltip-effect="dark" max-height="360" :row-style="{ height: '57px' }" :header-cell-style="{
                background: '#dfe6ec',
                color: '#666666',
                textAlign: 'center'
              }" @selection-change="handleSelectionChange">
              <el-table-column label="序号" width="80" align="center">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column label="商品编码" prop="goodsCoding" align="center">
                <template slot-scope="scope">
                  <div style="white-space: nowrap;width: 80%;">
                    {{ scope.row.goodsCoding }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商品名称" prop="goodsName" align="center" width="400">
                <template slot-scope="scope">
                  <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                    {{ scope.row.goodsName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商品图片" prop="pictureUrl" align="center" width="100">
                <template slot-scope="scope">
                  <img :src="downloadUrl + '/' + scope.row.pictureUrl" alt=""
                    style="width: 50px; height: 50px;margin-top: 8px;cursor: pointer" />
                </template>
              </el-table-column>
              <el-table-column label=" 分类" prop="idName" align="center" width="120">
                <template slot-scope="scope">
                  <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                    {{
                      scope.row.idName === null ? "- -" : scope.row.idName
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商品品牌" prop="brandName" align="center" width="180">
                <template slot-scope="scope">
                  <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                    {{ scope.row.brandName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商品IP" prop="ipName" align="center" width="180">
                <template slot-scope="scope">
                  <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                    {{ scope.row.ipName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="数量" prop="num" align="center">
              </el-table-column>
              <el-table-column label="操作" prop="dealStatus" align="center" width="270" fixed="right">
                <template slot-scope="scope">
                  <el-radio-group v-model="scope.row.radio" @change.native.prevent="
                    chooseRadioWithoutRecord(
                      scope.row.radio,
                      scope.row,
                      scope.$index
                    )
                    ">
                    <el-radio v-model="scope.row.radio" :label="0">补发</el-radio>
                    <el-radio v-model="scope.row.radio" :label="1">退款</el-radio>
                    <el-radio v-model="scope.row.radio" :label="3">分批到货</el-radio>
                  </el-radio-group>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- <div class="selectionSty">
            <span
              style="font-size: 10px;color: #000;margin-right: 20px;font-weight:bolder;margin-top: -1px;"
              >全部</span
            >
            <el-radio-group
              v-model="radioTotalWithoutRecord"
              @change.native.prevent="radioTotal_WithoutRecord($event)"
            >
              <el-radio :label="0">补发</el-radio>
              <el-radio :label="1">退款</el-radio>
              <el-radio :label="3">分批到货</el-radio>
            </el-radio-group>
          </div> -->
        </el-tab-pane>
        <!-- <el-tab-pane name="cargoDamage">
          <span slot="label">
            货损商品处理
            <el-badge :value="this.cargoDamageList.length" :max="99"
              :hidden="this.cargoDamageList.length === 0"></el-badge>
          </span>
          <div style="margin-top: 10px; margin-left: 10px; margin-right: 10px;">
            <el-table ref="cargoDamageList" :data="cargoDamageList" :cell-style="{ textAlign: 'center' }"
              tooltip-effect="dark" max-height="490" :row-style="{ height: '57px' }" :header-cell-style="{
                background: '#dfe6ec',
                color: '#666666',
                textAlign: 'center'
              }">
              <el-table-column label="序号" width="80" align="center">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column label="商品编码" prop="goodsCoding" align="center" width="150">
                <template slot-scope="scope">
                  <div style="white-space: nowrap;width: 80%;">
                    {{ scope.row.goodsCoding }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商品名称" prop="goodsName" align="center" width="400">
                <template slot-scope="scope">
                  <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                    {{ scope.row.goodsName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商品图片" prop="pictureUrl" align="center" width="100">
                <template slot-scope="scope">
                  <img :src="downloadUrl + '/' + scope.row.pictureUrl" alt=""
                    style="width: 50px; height: 50px;margin-top: 8px;cursor: pointer" />
                </template>
              </el-table-column>
              <el-table-column label=" 分类" prop="goodsOne" align="center" width="120">
                <template slot-scope="scope">
                  <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                    {{
                      scope.row.goodsOne === null ? "- -" : scope.row.goodsOne
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商品品牌" prop="goodsBrandName" align="center" width="180">
                <template slot-scope="scope">
                  <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                    {{ scope.row.goodsBrandName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商品IP" prop="goodsIpName" align="center" width="180">
                <template slot-scope="scope">
                  <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                    {{ scope.row.goodsIpName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="270" fixed="right">
                <template slot-scope="scope">
                  <el-radio-group v-model="scope.row.radio" @change.native.prevent="
                    chooseRadioCargoDamageList(
                      scope.row.radio,
                      scope.row,
                      scope.$index
                    )
                    ">
                    <el-radio v-model="scope.row.radio" label="0">补发</el-radio>
                    <el-radio v-model="scope.row.radio" label="1">退款</el-radio>
                    <el-radio v-model="scope.row.radio" label="2">公司承担</el-radio>
                  </el-radio-group>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane> -->
      </el-tabs>

      <div style="display:flex;justify-content:center;margin-top: 10px;">
        <el-button size="small" class="submit" @click="closeDialog">关闭</el-button>
        <el-button size="small" type="primary" class="submit" :disabled="submitLoading" :loading="submitLoading"
          @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import api from '@/api'
import { mapActions } from 'vuex'
export default {
  name: 'purchaseSelectDispose',
  data() {
    return {
      processMode: '1',
      enterWarehouseNumber: '',
      form: {
        logisticsCompany: '',
        logisticsNumbers: '',
        refundProve: []
      },
      rules: {
        logisticsNumbersCompany: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              this.logisticsNumbersCompany(rule, value, callback, 'cover')
            }
          }
        ],
        refundProve: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              this.validatePicture(rule, value, callback, 'cover')
            }
          }
        ]
      },
      cargoDamageList: [], // 货损商品
      withoutRecordList: [], // 未记录商品
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL,
      oneClassData: [],
      goodsBrandData: [],
      goodsIpData: [],
      onHoverText: '',
      dialogVisible: false,
      submitLoading: false,
      activeName: '',
      processModeList: [{ name: '退货', id: '1' }, { name: '分批到货', id: '3' }, { name: '补发', id: '0' }]
    }
  },
  created() {
    this.showActiveName()
    this.getPurchaseDetails()
  },
  methods: {
    playC() {

    },
    ...mapActions('d2admin/page', ['close']),
    showActiveName() {
      this.activeName = 'withoutRecord'
      if (this.cargoDamageList.length === 0) {
        this.activeName = 'withoutRecord'
      } else {
        this.activeName = 'cargoDamage'
      }
    },
    chooseRadioWithoutRecord(val, row, index) {
      this.withoutRecordList[index].dealStatus = String(val)
    },
    chooseRadioCargoDamageList(val, row, index) {
      this.cargoDamageList[index].dealStatus = String(val)
    },
    handleClick() {
      this.showActiveName()
    },
    onMouseOver(item) {
      this.onHoverText = item
    },
    open(data) {
      api.queryProductById({ enterWarehouseNumber: data }).then(res => {
        this.data = res['body']
        this.activeName = 'cargoDamage'
        this.showActiveName()
        this.getPurchaseDetails(res['body'])
        this.dialogVisible = true
        this.enterWarehouseNumber = data
      }).catch(err => {

      })

    },
    getPurchaseDetails(intoOrderInfo) {
      if (intoOrderInfo) {
        
        // this.cargoDamageList = intoOrderInfo.cargoDamageList
        // this.withoutRecordList = intoOrderInfo.withoutRecordList
        this.withoutRecordList = intoOrderInfo
        for (const i in this.cargoDamageList) {
          this.cargoDamageList[i].dealStatus = null
        }
        for (const j in this.withoutRecordList) {
          this.withoutRecordList[j].dealStatus = null
        }
      }
    },
    handleSelectionChange(e) {
      this.withoutRecordList.forEach(s => {
        e.forEach(x => {
          if (s == x) {
            s['change'] = true
          } else {
            s['change'] = false
          }
        })
      })
      console.log(e, 'eee');
    },
    async submit() {
      let array = []
      let num = 0
      // array = [...this.cargoDamageList, ...this.withoutRecordList]
      this.withoutRecordList.forEach(e => {
          array.push({
            "dealStatus": e.radio,
            "intoProductId": e.intoProductId
          })
      })
      console.log(array,'array');
      for (let i = 0; i < array.length; i++) {
        if (array[i].dealStatus === null||array[i].dealStatus === undefined) {
          num++
        }
      }
      if (num > 0) {
        this.$message({
          type: 'warning',
          message: '请将所有商品进行处理!'
        })
      } else {
          try {
            this.submitLoading = true
            const refundProveArray = []
            let refundProve = ''
            this.form.refundProve.map(e => {
              refundProveArray.push(e.url)
              refundProve = refundProveArray.join()
            })
            const res = await api.deal_purchase({
              enterWarehouseNumber: this.enterWarehouseNumber,
              logisticsCompany: this.form.logisticsCompany,
              logisticsNumbers: this.form.logisticsNumbers,
              refundProve: refundProve,
              productDealList: array
            })
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '处理成功!'
              })
              this.cargoDamageList = []
              this.withoutRecordList = []
              this.enterWarehouseNumber = ''
              this.form.logisticsCompany = ''
              this.form.logisticsNumbers = ''
              this.$refs.form.resetFields()
              this.$router.push({ path: '/purchasing' })
              this.close({ tagName: '/purchaseDetails' })
            } else {
              this.$message({
                type: 'danger',
                message: '处理失败!'
              })
            }
          } catch (error) {
            this.$message({
              type: 'danger',
              message: '处理失败!'
            })
          } finally {
            this.submitLoading = false
          }
        } 
      //   else {
      //     this.$message({
      //       type: 'danger',
      //       message: '处理失败!'
      //     })
      //   }
      // })
      // }
    },
    closeDialog() {
      this.activeName = ''
      this.cargoDamageList = []
      this.withoutRecordList = []
      this.enterWarehouseNumber = ''
      this.form.logisticsCompany = ''
      this.form.logisticsNumbers = ''
      this.$refs.form.resetFields()
      this.dialogVisible = false
    },
    // 图片校验
    validatePicture(rule, value, callback) {
      let num = 0
      this.cargoDamageList.forEach(e => {
        if (e.dealStatus === '1') {
          if (this.form.refundProve.length === 0) {
            num++
          }
        }
      })
      this.withoutRecordList.forEach(e => {
        if (e.dealStatus === '1') {
          if (this.form.refundProve.length === 0) {
            num++
          }
        }
      })
      if (num > 0) {
        callback(new Error('请上传退款图片'))
      } else {
        callback()
      }
    },
    // 物流单号,快递公司校验
    logisticsNumbersCompany(rule, value, callback) {
      let num = 0
      this.cargoDamageList.forEach(e => {
        if (e.dealStatus === '0' || e.dealStatus === '3') {
          if (
            this.form.logisticsCompany === '' ||
            this.form.logisticsNumbers === ''
          ) {
            num++
          }
        }
      })
      this.withoutRecordList.forEach(e => {
        if (e.dealStatus === '0' || e.dealStatus === '3') {
          if (
            this.form.logisticsCompany === '' ||
            this.form.logisticsNumbers === ''
          ) {
            num++
          }
        }
      })
      if (num > 0) {
        callback(new Error('请填写物流信息'))
      } else {
        callback()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-badge__content {
  text-align: center;
}

.selectionSty {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 20px;
  display: flex;
  justify-content: right;
}

.radioGroupSty {
  background-color: rgb(223, 230, 236, 0.4);
}
</style>
