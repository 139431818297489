var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "dashboard-editor-container" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "whSty" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingCommodityCard,
                  expression: "loadingCommodityCard"
                }
              ],
              ref: "table",
              staticStyle: {
                width: "100%",
                "margin-left": "10px",
                margin: "10px 10px 0px 10px"
              },
              attrs: {
                "header-cell-style": _vm.headerCellStyle,
                "element-loading-text": "数据加载中",
                data: _vm.tableData,
                "max-height": "390px",
                "cell-style": { textAlign: "center" },
                "row-style": { height: "57px", textAlign: "center" },
                "tooltip-effect": "dark"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  align: "center",
                  width: "80px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品名称",
                  prop: "goodsName",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "white-space": "nowrap",
                              "text-overflow": "ellipsis"
                            }
                          },
                          [_vm._v(" " + _vm._s(scope.row.goodsName) + " ")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "在途数量",
                  prop: "ingCount",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "在库数量",
                  prop: "invCount",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "累计采购数量",
                  prop: "purchaseCount",
                  width: "120",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "累计入库数量",
                  prop: "intoCount",
                  width: "120",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "累计出库数量",
                  prop: "outCount",
                  width: "120",
                  align: "center"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "singSty" }, [
      _c("div", { staticClass: "verticalBar" }),
      _c("span", { staticClass: "textSty" }, [_vm._v("商品排行榜(前50)")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }