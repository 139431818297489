<template>
  <d2-container>
    <div>
      <el-input
        size="small"
        clearable
        v-model="className"
        placeholder="请输入分类名称"
        style="width:200px;margin: 10px;"
        :disabled="permission.isClass"
        @keyup.enter.native="search"
      ></el-input>
      <el-button
        icon="el-icon-search"
        type="primary"
        style="margin-left:10px"
        @click="search"
        v-if="!permission.classify_select"
        size="small"
      >
        搜索
      </el-button>
      <el-button
        icon="el-icon-refresh"
        type="warning"
        @click="reset"
        v-if="!permission.classify_select"
        size="small"
      >
        重置
      </el-button>
      <el-button
        icon="el-icon-circle-plus-outline"
        type="success"
        style="margin-top: 10px;"
        @click="add"
        v-if="!permission.isAdd"
        size="small"
      >
        新增
      </el-button>
    </div>
    <div>
      <!-- 适合数据一起传过来 -->
      <!-- <el-table :data="tableData.slice((searchX.currpage-1)*searchX.pagesize,searchX.currpage*searchX.pagesize)" -->
      <!-- 适合数据根据页数传过来 -->
      <!--    :lazy="!permission.classify_getList_byFatherId" -->
      <div style="margin-top: 10px; margin-left: 10px; margin-right: 10px;">
        <el-table
          :data="tableData"
          style="width: 100%"
          row-key="id"
          border
          :lazy="false"
          :load="load"
          ref="table"
          :tree-props="{ children: 'list', hasChildren: 'hasChildren' }"
          @expand-change="expandUpdate"
        >
          <el-table-column
            prop="classifyName"
            label="分类名称"
            width="180"
          ></el-table-column>
          <el-table-column prop="name" label="操作" width="180" align="center">
            <template slot-scope="scope">
              <!-- <el-button
                icon="el-icon-circle-plus-outline"
                plain
                type="success"
                @click="addSubclass(scope.row)"
                v-if="!permission.isAddSubclass"
                v-show="scope.row.hasChildren"
                size="small"
              >
                新增子分类
              </el-button> -->
              <el-button
                icon="el-icon-edit-outline"
                type="primary"
                plain
                @click="compile(scope.row)"
                v-if="!permission.isCompile"
                size="small"
              >
                编辑
              </el-button>
              <el-button
                icon="el-icon-delete"
                v-if="!permission.isRemove"
                plain
                type="danger"
                @click="remove(scope.row)"
                size="small"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        style="display:flex;justify-content:right;margin-top: 10px;margin-right: 10px;"
        :current-page="searchX.currpage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="searchX.pagesize"
        layout="total, jumper, prev, pager, next,sizes"
        :total="searchX.currentPage"
        v-if="!permission.classify_select"
      ></el-pagination>
    </div>
    <el-dialog
      :append-to-body="true"
      :title="titleName"
      :visible.sync="dialogVisible"
      width="400px"
      :close-on-click-modal="false"
      @close="closeDlg"
    >
      <el-form
        label-position="right"
        label-width="80px"
        :model="form"
        :rules="rules"
        ref="ruleForm"
      >
        <!-- <el-form-item label="上级分类" prop="oldClass">
          <el-input
            size="small"
            clearable
            disabled
            v-model="form.oldClass"
            style="width:200px"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="分类名称" prop="newClass">
          <el-input
            size="small"
            clearable
            v-model="form.newClass"
            style="width:200px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="  display: flex;justify-content: center;">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="confirm">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </d2-container>
</template>
<script>
import api from '@/api'

export default {
  data () {
    return {
      rowCaches: {},
      tree1: {},
      tree2: {},
      e: {}, // 点击新增子类按钮保存的数据
      searchX: {
        currentPage: 0, // 总共有多少条
        pagesize: 50, // 每页的数据条数
        currpage: 1 // 默认开始页面
      },
      permission: {
        // isSearch: this.checkauthority('classification_search_button'), // 搜索
        // isReset: this.checkauthority('classification_reset_button'), // 重置
        isAdd: this.checkauthority('classify_create'), // 新增
        isAddSubclass: this.checkauthority('classify_create'), // 新增子类
        isCompile: this.checkauthority('classify_update'), // 编辑
        isRemove: this.checkauthority('classify_delete'), // 删除
        classify_select: this.checkauthority('classify_select'), // 分页查询
        classify_getList_byFatherId: this.checkauthority(
          'classify_getList_byFatherId'
        ) // 获取下一级数据
        // isClass: this.checkauthority('classification_className_input')// 输入框
      },
      titleName: '',
      dialogVisible: false,
      className: '',
      form: {
        oldClass: '',
        newClass: '',
        id: ''
      },
      tableData: [],
      rules: {
        newClass: [
          { required: true, message: '分类名称不可为空', trigger: 'blur' },
          {
            pattern: /^(?!\s+)/,
            message: '第一个字符或所有字符不能为空格',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
    this.inline()
  },
  methods: {
    // 页面初始化
    async inline () {
      const res = await api.classification_getPageList({
        classifyName: this.className,
        pageNo: this.searchX.currpage,
        pageSize: this.searchX.pagesize
      })
      if (res.code === 200) {
        this.tableData = res.body.datas.map(e => {
          e.hasChildren = true
          e.classifyLevel = 1
          e.id = e.id.toString()
          return e
        })
        this.searchX.currpage = res.body.pageNo
        this.searchX.pagesize = res.body.pageSize
        this.searchX.currentPage = res.body.sumDataCount
      }
    },
    async load (tree, treeNode, resolve) {
      this.rowCaches[tree.id] = { tree, treeNode, resolve, first: true }
      if (tree.classifyLevel < 2) {
        this.tree1 = tree
        const res = await api.classification_add_subclass({ fatherId: tree.id })
        if (res.code !== 200) {
          this.$message.error('查询子集失败')
          return
        }
        if (res.body.length === 0) {
          this.$message({
            message: '这个分类还没有子集',
            type: 'warning'
          })
        }
        resolve(
          res.body.map(e => {
            e.hasChildren = true
            e.id = e.id.toString()
            e.classifyLevel = tree.classifyLevel + 1
            return e
          })
        )
      } else if (tree.classifyLevel === 2) {
        this.tree2 = tree
        const res = await api.classification_add_subclass({ fatherId: tree.id })
        if (res.body.length === 0) {
          this.$message({
            message: '这个分类还没有子集',
            type: 'warning'
          })
        }
        resolve(
          res.body.map(e => {
            e.hasChildren = false
            e.id = e.id.toString()
            e.classifyLevel = tree.classifyLevel + 1
            return e
          })
        )
      }
    },
    expandUpdate (tree, open) {
      var obj = this.rowCaches[tree.id]
      if (obj.first) {
        obj.first = false
      } else {
        if (!open) {
          this.$set(
            this.$refs.table.$refs.tableHeader.store.states.lazyTreeNodeMap,
            tree.id,
            []
          )
          this.$refs.table.$refs.tableHeader.store.states.treeData[
            tree.id
          ].loaded = false
        } else {
          obj.resolve(tree)
        }
      }
    },
    closeDlg () {
      this.$refs.ruleForm.resetFields()
      this.form.oldClass = ''
      this.form.newClass = ''
    },
    // 新增
    add () {
      this.dialogVisible = true
      this.titleName = '新增'
      this.form.oldClass = '所有分类'
    },
    // 新增子类
    addSubclass (e) {
      this.dialogVisible = true
      this.titleName = '新增子类'
      this.form.oldClass = e.classifyName
      this.e = e
    },
    // 编辑
    compile (e) {
      if (e.classifyLevel === 1) {
        this.form.oldClass = '所有分类'
      } else if (e.classifyLevel === 2) {
        this.form.oldClass = this.tree1.classifyName
      } else if (e.classifyLevel === 3) {
        this.form.oldClass = this.tree2.classifyName
      }
      this.dialogVisible = true
      this.titleName = '编辑'
      this.form.newClass = e.classifyName
      this.e = e
    },
    // 删除
    remove (e) {
      this.$confirm('是否删除该分类?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await api.classification_delete({ id: e.id })
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.inline()
          } else {
            this.$message.console.error('删除失败')
          }
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // })
        })
    },
    // 弹窗取消
    cancel () {
      this.dialogVisible = false
    },
    // 弹窗确定
    confirm () {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          if (this.titleName === '新增') {
            const res = await api.classification_add({
              classifyLevel: 1,
              classifyName: this.form.newClass,
              fatherId: 0
            })
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '新增成功!'
              })
              this.inline()
              this.dialogVisible = false
            } else {
              this.$message.error('新增失败')
            }
          }
          if (this.titleName === '新增子类') {
            const classifyLevel = this.e.classifyLevel
            const fatherId = this.e.id
            const res = await api.classification_add({
              classifyLevel: classifyLevel + 1,
              classifyName: this.form.newClass,
              fatherId: fatherId
            })
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '新增子类成功!'
              })
              this.inline()
              this.dialogVisible = false
            } else {
              this.$message.error('新增子类失败')
            }
          }
          if (this.titleName === '编辑') {
            const res = await api.classification_update({
              classifyName: this.form.newClass,
              id: this.e.id
            })
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '编辑成功!'
              })
              this.inline()
              this.dialogVisible = false
            } else {
              this.$message.error('编辑失败')
            }
          }
        } else {
          return false
        }
      })
    },
    // 搜索
    search () {
      this.searchX.currpage = 1
      this.inline()
    },
    // 重置
    reset () {
      this.className = ''
      this.inline()
    },
    // 主页分页
    handleSizeChange (val) {
      this.searchX.pagesize = val
      this.inline()
    },
    handleCurrentChange (val) {
      this.searchX.currpage = val
      this.inline()
    }
  }
}
</script>
<style scoped lang="scss"></style>
