<template>
  <d2-container>
    <div class="goods">
      <div class="search">
        <head-search label-style="width:100px">
          <head-search-item label="公司名称">
            <el-select
              multiple
              collapse-tags
              size="small"
              v-model="search.companyIds"
              placeholder="请选择公司名称"
            >
              <el-option
                v-for="item in orgOptions"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </head-search-item>
          <head-search-item label="仓库名称">
            <el-select
              multiple
              collapse-tags
              size="small"
              v-model="search.warehouseIds"
              placeholder="请选择仓库名称"
            >
              <el-option
                v-for="item in warehouseIdOptions"
                :key="item.warehouseId"
                :label="item.warehouseName"
                :value="item.warehouseId"
              ></el-option>
            </el-select>
          </head-search-item>
          <head-search-item label="日期选择">
            <el-date-picker
              v-model="search.endTime"
              align="right"
              type="date"
              size="small"
              placeholder="选择日期"
              :clearable="false"
              :picker-options="pickerOptionsDate"
            >
            </el-date-picker>
          </head-search-item>
          <template slot="button">
            <el-button
              icon="el-icon-search"
              type="primary"
              @click="Search"
              size="small"
            >
              搜索
            </el-button>
            <el-button
              icon="el-icon-refresh"
              type="warning"
              @click="Reset"
              size="small"
            >
              重置
            </el-button>
<!--            <el-button-->
<!--              type="success"-->
<!--              size="small"-->
<!--              @click="exportExcel"-->
<!--              icon="el-icon-download"-->
<!--              v-loading="exportLoading"-->
<!--              v-if="!permission.isExport"-->
<!--            >-->
<!--              导出-->
<!--            </el-button>-->
            <slot></slot>
          </template>
        </head-search>
      </div>
      <div class="table">
        <el-table
          ref="goodsTable"
          v-loading="tableLoading"
          :data="stockTable"
          max-height="620px"
          @sort-change="SortChange"
          element-loading-text="数据加载中"
          :cell-style="{ textAlign: 'center' }"
          tooltip-effect="dark"
          :row-style="{ height: '57px' }"
          :header-cell-style="{
            background: '#dfe6ec',
            color: '#666666',
            textAlign: 'center'
          }"
        >
          <el-table-column align="center" label="序号">
            <template slot-scope="scope">
              {{ (page.pageNo - 1) * page.pageSize + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="公司名称"
            prop="companyName"
          >
          </el-table-column>
          <el-table-column
            align="center"
            label="仓库名称"
            prop="warehouseName"
          ></el-table-column>
          <el-table-column
            align="center"
            label="在库金额"
            prop="price"
          ></el-table-column>
          <el-table-column
            align="center"
            label="在库数量"
            prop="inventoryNums"
          ></el-table-column>
          <el-table-column
            align="center"
            label="所属部门"
            prop="deptName"
          ></el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentchange"
          :current-page="page.pageNo"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="page.pageSize"
          layout="->,total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        ></el-pagination>
      </div>
    </div>
  </d2-container>
</template>
<script>
import api from '@/api'
import { downloadFileByUrl } from '@/utils/index'
const searchFormat = {
  field: '',
  companyIds: [],
  sort: 0,
  warehouseIds: [],
  endTime: new Date()
}
const Copy = value => {
  return JSON.parse(JSON.stringify(value))
}
export default {
  name: 'goods',
  data () {
    return {
      permission: {
        isExport: this.checkauthority('location_export'), // 导出
        isInfo: this.checkauthority('storage_list') // 详情
      },
      pickerOptionsDate: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      warehouseIdOptions: [],
      orgOptions: [],
      stockTable: [],
      page: {
        pageNo: 1,
        pageSize: 50
      },
      pageTotal: 0,
      tableLoading: false,
      org: [],
      search: Copy(searchFormat),
      exportLoading: false
    }
  },
  created () {
    this.getList()
    this.getWarehouse()
    this.getOrg()
  },
  methods: {
    sizeChange (val) {
      this.page.pageSize = val
      this.getList()
    },
    currentchange (val) {
      this.page.pageNo = val
      this.getList()
    },
    // 查询仓库
    async getWarehouse () {
      const { body: data } = await api.getPurchaseWarehouse()
      this.warehouseIdOptions = data
    },
    // 查询公司
    async getOrg () {
      const { body: data } = await api.getorgselect()
      this.orgOptions = data
    },
    Search () {
      this.page.pageNo = 1
      this.getList()
    },
    Reset () {
      this.page.pageNo = 1
      this.search = Copy(searchFormat)
      this.getList()
    },
    async exportExcel () {
      this.exportLoading = true
      try {
        const { body: data } = await api.inventoryStatisticalExport(
          this.search
        )
        downloadFileByUrl(data)
      } finally {
        this.exportLoading = false
      }
    },
    async getList () {
      const myDate = new Date(this.search.endTime)
      var Y = myDate.getFullYear() // 获取当前完整年份
      var M = myDate.getMonth() + 1 // 获取当前月份
      var D = myDate.getDate() // 获取当前日1-31
      var H = myDate.getHours() // 获取当前小时
      var i = myDate.getMinutes() // 获取当前分钟
      var s = myDate.getSeconds() // 获取当前秒数
      // 月份不足10补0
      if (M < 10) {
        M = '0' + M
      }
      // 日不足10补0
      if (D < 10) {
        D = '0' + D
      }
      // 小时不足10补0
      if (H < 10) {
        H = '0' + H
      }
      // 分钟不足10补0
      if (i < 10) {
        i = '0' + i
      }
      // 秒数不足10补0
      if (s < 10) {
        s = '0' + s
      }
      // 拼接日期分隔符根据自己的需要来修改
      const changeDate = Y + '-' + M + '-' + D
      this.search.endTime = changeDate.toString()
      this.tableLoading = true
      const { body: data } = await api.inventoryStatisticalGetPage(
        Object.assign(this.search, this.page)
      )
      this.tableLoading = false
      this.pageTotal = data.sumDataCount
      this.stockTable = data.datas
    },
    toDetails (row) {
      this.$router.push({
        path: '/stockposition',
        query: {
          warehouseId: row.warehouseId
        }
      })
    },
    SortChange (sort) {
      this.search.sort = sort.order === 'ascending' ? 0 : 1
      this.search.field = sort.prop
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  margin-left: 20px;
}
</style>
