<template>
  <!-- 如果内容比较多可以添加top属性 -->
  <el-dialog
  :append-to-body="true"
    :title="title"
    :visible.sync="dialogVisible"
    width="40%"
    :close-on-click-modal="false"
    :before-close="handleClose">
    <el-form ref="form" status-icon :model="form" :rules="rules" label-width="120px">
      <el-form-item label="应用名称" prop="name">
        <el-input size="small" clearable  :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="应用key" prop="appKey">
        <el-input size="small" clearable  :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.appKey" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="应用token" prop="appToken">
        <el-input size="small" clearable  :disabled="disabled" @keyup.enter.native="save()" class="inputText" v-model="form.appToken" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="stats">
        <el-radio-group :disabled="disabled" @keyup.enter.native="save()" v-model="form.stats" autocomplete="off">
          <el-radio
            v-for="item in appsStatsEnums"
            :key="item.keys"
            :label="item.keys">
            {{item.values}}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div class="bottomDiv">
      <el-button size="small" @click="handleClose">关闭</el-button>
      <el-button size="small"  :disabled="disabled" type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  name: 'AppsEdit',
  data () {
    return {
      title: '',
      appsStatsEnums: [],
      form: {
        name: '',
        appKey: '',
        appToken: '',
        stats: null
      },
      rules: {
        name: [
          { required: true, message: '请输入应用名称', trigger: 'blur' }
        ],
        appKey: [
          { required: true, message: '请输入应用key', trigger: 'blur' }
        ],
        appToken: [
          { required: true, message: '请输入应用token', trigger: 'blur' }
        ],
        stats: [
          { required: true, message: '请输入状态', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async getAppsStatsEnums () {
      var res = await api.GET_ENUMERATION_LIST({ name: 'apps_stats' })
      this.appsStatsEnums = res.body
    },
    initEunmData () {
      this.getEnumList('key')
    },
    async getEnumList (name) {
      var par = {
        name: name
      }
      var res = await api.GET_ENUMERATION_LIST(par)
      this.enumList = res.body
    },
    setTitle (title) {
      this.title = title
    },
    initData () {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields()
      }
      this.form.name = null
      this.form.appKey = null
      this.form.appToken = null
      this.form.stats = null
      this.form.appId = null
    },
    setData (data) {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields()
      }
      this.form.name = data.name
      this.form.appKey = data.appKey
      this.form.appToken = data.appToken
      this.form.stats = data.stats + ''
      this.form.appId = data.appId
    },
    async updateApi () {
      await api.UPDATE_APPS(this.form)
      this.$emit('initTableData')
      this.$message.success('操作成功')
      this.handleClose()
    },
    async saveApi () {
      await api.SAVE_APPS(this.form)
      this.$emit('initTableData')
      this.$message.success('操作成功')
      this.handleClose()
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.appId != null) {
            this.$confirm('此操作将永久修改该数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.updateApi()
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消修改'
              })
            })
          } else {
            this.saveApi()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleClose (done) {
      this.$emit('close')
    }
  },
  created () {
    this.getAppsStatsEnums()
  }
}
</script>

<style>
  .inputText {
    width: 70%;
  }
  .bottomDiv {
    width: 100%;
    text-align: center;
  }
</style>
