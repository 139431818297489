export default ({ service, request, serviceForMock, requestForMock, mock, faker, tools }) => ({
  /**
   * @description 登录
   * @param {Object} data 登录携带的信息
   */
  GET_ROLE_SELECT (data = {}) {
    return request({
      url: 'v1/role/getRoleSelect',
      method: 'post',
      data: data
    })
  },

  SYS_USER_LOGIN (data = {}) {
    return request({
      url: '/user/login',
      method: 'post',
      data: {
        username: data.username,
        password: data.password,
        loginType: 'back'
      }
    })
  },
  SYS_USER_LOGOUT (data = {}) {
    return request({
      url: '/user/logout',
      method: 'post',
      data: data
    })
  },
  GET_SYS_USER_LIST (params = {}, query = {}) {
    return request({
      url: '/v1/user/getPageList',
      method: 'post',
      params: params,
      data: query
    })
  },
  GET_USER_INFO (data = {}) {
    return request({
      url: '/v1/user/userInfo',
      method: 'post',
      data: data
    })
  },
  SAVE_USER (data = {}) {
    return request({
      url: '/v1/user/save',
      method: 'post',
      data: data
    })
  },
  UPDATE_USER (data = {}) {
    return request({
      url: '/v1/user/update',
      method: 'put',
      data: data
    })
  },
  DELETE_USER (data = {}) {
    return request({
      url: '/v1/user/delete',
      method: 'delete',
      data: data
    })
  },
  DELETE_BATCH_USER (data = {}) {
    return request({
      url: '/v1/user/deleteBatch',
      method: 'delete',
      data: data
    })
  }
})
