<template>
  <d2-container>
    <el-form :inline="true" :model="selectForm">
      <is-jurisdiction-search type="search" ref="getJurisdictionData" @initFinish="initFinish"></is-jurisdiction-search>
      <el-form-item label="状态">
        <el-select :disabled="isSelect" size="small" v-model="selectForm.userState" placeholder="请选择状态">
          <el-option v-for="item in options.status" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input :disabled="isSelect" size="small" v-model="selectForm.userName" clearable
          @keyup.enter.native="search" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input :disabled="isSelect" size="small" v-model="selectForm.name" clearable @keyup.enter.native="search()"
          placeholder="输入用户姓名">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button :disabled="isSelect" size="small" type="primary" @click="search" icon="el-icon-search">搜索</el-button>
        <el-button :disabled="isSelect" size="small" type="warning" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button :disabled="isCreate" size="small" type="success" icon="el-icon-circle-plus-outline" @click="toAdd">新增
        </el-button>
      </el-form-item>
      <!-- <el-form-item>
        <el-button :disabled="isDelete" type="danger" @click="deleteBatch" icon="el-icon-delete">删除</el-button>
      </el-form-item> -->
    </el-form>
    <el-table :data="tableData" :row-style="{height:'60px'}" :header-cell-style="{
      background: '#dfe6ec',
      color: '#666666',
      textAlign: 'center',
    }" @selection-change="handleSelectionChange">
      <el-table-column width="140" prop="userName" label="用户名称" align="center">
      </el-table-column>
      <el-table-column width="140" prop="name" label="用户姓名" align="center">
      </el-table-column>
      <el-table-column width="140" prop="orgName" label="所属公司" align="center">
      </el-table-column>
      <el-table-column width="140" prop="deptName" label="所属部门" align="center">
      </el-table-column>
      <el-table-column width="140" prop="roleName" label="用户角色" align="center">
      </el-table-column>
      <el-table-column width="80" prop="userState" label="状态" align="center">
        <template slot-scope="scope">
          {{ scope.row.userState === 0?'正常':'禁用' }}
        </template>
        <!-- <template slot-scope="scope">
          <el-tag class="statsTag" :type="checkStatsType(scope.row)">{{ checkStats(scope.row )}}</el-tag>
          <el-switch :disabled="isUpdate" v-model="scope.row.stats" class="demo" active-color="#00A854" active-text="正常"
            :active-value="0" @change="switchChange(scope.row)" inactive-color="#F04134" inactive-text="注销"
            :inactive-value="1" />
        </template> -->
      </el-table-column>
      <!-- <el-table-column width="180" prop="loginTime" label="登录时间">
      </el-table-column>
      <el-table-column width="140" prop="loginNum" label="登录次数">
      </el-table-column> -->
      <el-table-column width="180" prop="createdName" label="创建人" align="center">
      </el-table-column>
      <el-table-column width="180" prop="createdTimestamp" label="创建时间" align="center">
      </el-table-column>

      <el-table-column width="280" height="60" label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button plain type="info" size="mini" :disabled="isSelect" @click="info(scope.$index, scope.row)"
            icon="el-icon-info">详情</el-button> -->
          <el-button plain type="primary" size="small" :disabled="isUpdate" @click="edit(scope.$index, scope.row)"
            icon="el-icon-edit-outline">编辑</el-button>
          <el-button plain size="small" type="danger" :disabled="isDelete" @click="dalete(scope.$index, scope.row)"
            icon="el-icon-delete">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-div">
      <el-pagination :disabled="isSelect" :current-page="this.pageNo" @size-change="handleSizeChange"
      :page-size="pageSize"
        @current-change="handleCurrentChange" :page-sizes="[10,20,30,40,50,100]"
        layout="jumper, prev, pager, next,sizes,total" :total="pageTotal">
      </el-pagination>
    </div>
    <UserEdit ref="edit" :disabled="editDisabled" :dialogVisible="dialogVisible" @close="userEditClose"
      @initTableData="initTableData"></UserEdit>
  </d2-container>
</template>

<script>
import api from '@/api'
import UserEdit from '../edit/index'
import isJurisdictionSearch from '@/components/erp-isJurisdiction-search'
export default {
  name: 'UserList',
  components: {
    UserEdit,
    isJurisdictionSearch
  },
  data () {
    return {
      isSelect: this.checkauthority('user_select'),
      isCreate: this.checkauthority('user_add'),
      isUpdate: this.checkauthority('user_edit'),
      isDelete: this.checkauthority('user_del'),
      options: {
        company: [],
        part: [],
        status: [{
          label: '全部',
          value: undefined
        }, {
          label: '正常',
          value: 0
        },
        {
          label: '禁用',
          value: 1
        }
        ]
      },
      selectForm: {
        userName: '',
        name: '',
        orgId: '',
        deptIds: [],
        userState: 0
      },
      editDisabled: false,
      dialogVisible: false,
      pageNo: 1,
      pageSize: 50,
      tableSelects: [],
      tableData: [],
      pageTotal: 0,
      centerDialogVisible: false,
      userStatsEunm: [],
      userStatsEunmType: [],
      isCompany: true,
      isFinish: false
    }
  },
  methods: {
    /**
   * 获取枚举的方法 created
   */
    initEunmData () {
      this.getEnumList('', this.userStatsEunm)
      this.getEnumList('', this.userStatsEunmType)
    },
    async getEnumList (key, items) {
      var par = {
        keys: key
      }
      var res = await api.GET_ENUMERATION_LIST(par)
      items = res.body
    },
    switchChange (value) {
      this.updateStats(value)
    },
    checkStatsType (data) {
      if (data.stats === 0) {
        return 'success'
      } else {
        return 'danger'
      }
    },
    checkStats (data) {
      if (data.stats === 0) {
        return '正常'
      } else {
        return '已注销'
      }
    },
    btnCheckStats (data) {
      if (data.stats === 0) {
        return '注销'
      } else {
        return '恢复'
      }
    },
    async updateStats (data) {
      var par = {
        id: data.id,
        stats: data.stats
      }
      await api.UPDATE_USER(par)
      this.initTableData()
    },
    dalete (index, data) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.daleteApi(index, data)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async daleteApi (index, data) {
      var par = {
        id: data.id
      }
      await api.DELETE_USER(par)
      this.initTableData()
    },
    async deleteBatchApi (par) {
      await api.DELETE_BATCH_USER(par)
      this.$message.success('操作成功')
      this.initTableData()
    },
    deleteBatch () {
      if (this.tableSelects.length === 0) {
        this.$message.error('请勾选要删除的表格数据')
        return
      }
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var par = []
        this.tableSelects.forEach(element => {
          par.push(element.id)
        })
        this.deleteBatchApi(par)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    info (index, data) {
      this.editDisabled = true
      this.dialogVisible = true
      this.$refs.edit.setData(data)
    },
    edit (index, data) {
      this.dialogVisible = true
      this.editDisabled = false
      this.$refs.edit.setData(data, this.options, '编辑用户')
    },
    toAdd () {
      this.dialogVisible = true
      this.editDisabled = false
      this.$refs.edit.initData(this.options, '新增用户')
    },
    userEditClose () {
      this.dialogVisible = false
    },
    async reset () {
      this.selectForm.userName = ''
      this.selectForm.name = ''
      this.selectForm.userState = undefined
      this.$refs.getJurisdictionData.disabledInput()
      this.$refs.getJurisdictionData.departmentId = []
      this.$refs.getJurisdictionData.disabledDepartment = false
      this.isCompany = true
      const res = await api.GET_SYS_USER_LIST({ pageNo: this.pageNo, pageSize: this.pageSize }, this.selectForm)
      this.tableData = res.body.datas
      this.pageTotal = res.body.sumDataCount
      this.initTableData()
    },
    search () {
      this.pageNo = 1
      this.initTableData()
    },
    async initTableData () {
      this.selectForm.orgId = this.$refs.getJurisdictionData.companyId
      this.selectForm.deptIds = this.$refs.getJurisdictionData.departmentId
      const res = await api.GET_SYS_USER_LIST({ pageNo: this.pageNo, pageSize: this.pageSize }, this.selectForm)
      this.tableData = res.body.datas
      this.pageTotal = res.body.sumDataCount
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.initTableData()
    },
    handleCurrentChange (val) {
      this.pageNo = val
      this.initTableData()
    },
    handleSelectionChange (val) {
      this.tableSelects = val
    },
    initFinish () {
      if (!this.isFinish) {
        this.initTableData()
        this.isFinish = true
        this.$refs.getJurisdictionData.disabledDepartment = false
      }
    }
  },
  created () {
    if (this.isSelect) {
      return
    }
    this.isFinish = false
    this.selectForm.userState = undefined
  }
}
</script>
<style scoped lang="scss">
.editBtn {
  width: 60px;
}

.statsTag {
  width: 60px;
  text-align: center;
}

.demo .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

/*打开时文字位置设置*/
.demo .el-switch__label--right {
  z-index: 1;
  right: -4px;
}

/*关闭时文字位置设置*/
.demo .el-switch__label--left {
  z-index: 1;
  left: 18px;
}

/*显示文字*/
.demo .el-switch__label.is-active {
  display: block;
}

.demo.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}

.sty {
  margin-top: 4px;
}
</style>
