<template>
  <d2-container>
    <head-search label-style="width:100px">
      <head-search-item label="采购单号">
        <el-input
          prefix-icon="el-icon-search"
          class="searchInput"
          v-model="search.purchaseNo"
          placeholder="请输入采购单号"
          @keyup.enter.native="initList"
          style="width:200px;"
          clearable
          size="small"
        ></el-input>
      </head-search-item>
      <head-search-item label="商品名称">
        <el-input
          class="searchInput"
          v-model="search.goodsName"
          placeholder="请输入商品名称"
          @keyup.enter.native="initList"
          style="width:200px;"
          clearable
          size="small"
        ></el-input>
      </head-search-item>
      <head-search-item label="采购类型">
        <el-select
          class="searchInput"
          multiple
          collapse-tags
          v-model="search.purchaseTypes"
          placeholder="请选择采购类型"
          size="small"
          clearable
        >
          <el-option
            v-for="item in purchaseTypesData"
            :key="item.keys"
            :label="item.values"
            :value="item.keys"
            v-bind:title="onHoverText"
            @mouseenter.native="onMouseOver(item.values)"
          ></el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="采购发起人">
        <el-select
          class="searchInput"
          v-model="search.createdIds"
          placeholder="请选择采购发起人"
          size="small"
          multiple
          collapse-tags
          clearable
        >
          <el-option
            v-for="(item, index) in createdIdsData"
            :key="index"
            :label="item.userName"
            :value="item.id"
            v-bind:title="onHoverText"
            @mouseenter.native="onMouseOver(item.value)"
          ></el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="渠道">
        <el-select
          class="searchInput"
          multiple
          collapse-tags
          v-model="search.channels"
          placeholder="请选择渠道"
          size="small"
          clearable
        >
          <el-option
            v-for="item in channelData"
            :key="item.keys"
            :label="item.values"
            :value="item.keys"
            v-bind:title="onHoverText"
            @mouseenter.native="onMouseOver(item.values)"
          ></el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="发货方式">
        <el-select
          class="searchInput"
          v-model="search.deliveryMethods"
          placeholder="请选择发货方式"
          size="small"
          multiple
          collapse-tags
          clearable
        >
          <el-option
            v-for="(item, index) in deliveryMethodsData"
            :key="index"
            :label="item.value"
            :value="item.id"
            v-bind:title="onHoverText"
            @mouseenter.native="onMouseOver(item.value)"
          ></el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="状态">
        <el-select
          class="searchInput"
          v-model="search.status"
          placeholder="请选择状态"
          size="small"
          multiple
          collapse-tags
          clearable
        >
          <el-option
            v-for="(item, index) in statusData"
            :key="index"
            :label="item.value"
            :value="item.id"
            v-bind:title="onHoverText"
            @mouseenter.native="onMouseOver(item.value)"
          ></el-option>
        </el-select>
      </head-search-item>
      <head-search-item label="采购日期">
        <el-date-picker
          class="searchTimer"
          v-model="search.createTimes"
          type="daterange"
          value-format="yyyy-MM-dd"
          align="center"
          size="small"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </head-search-item>
      <head-search-item label="金额区间">
        <el-input-number
          :max="search.rightTotalMoney"
          :min="0"
          v-model="search.leftTotalMoney"
          clearable
          size="small"
          style="width: 170px;"
          @keyup.enter.native="search"
        ></el-input-number>
      </head-search-item>
      <span style="line-height: 30px;margin-left: 10px;margin-right: 10px;"
        >~</span
      >
      <head-search-item>
        <el-input-number
          :min="search.leftTotalMoney"
          v-model="search.rightTotalMoney"
          clearable
          size="small"
          style="width: 170px;"
          @keyup.enter.native="search"
        ></el-input-number>
      </head-search-item>
      <template slot="button">
        <el-button
          :disabled="permission.isShow"
          icon="el-icon-search"
          style="margin-right:10px;margin-left: 25px;"
          type="primary"
          @click="searchTotal()"
          size="small"
        >
          搜索
        </el-button>
        <el-button
          v-if="!permission.isShow"
          icon="el-icon-refresh"
          style="margin-right:10px"
          type="warning"
          @click="reset()"
          size="small"
        >
          重置
        </el-button>
        <el-button
          v-if="!permission.isAdd"
          icon="el-icon-circle-plus-outline"
          type="success"
          @click="add()"
          size="small"
        >
          新增
        </el-button>
      </template>
    </head-search>
    <div style="margin-top: 10px;display: flex">
      <i
        class="el-icon-warning"
        style="color: #f60;line-height: 22px;margin-right: 10px"
        >TIPS:</i
      >
      <div style="display: flex;margin-right: 10px">
        <span>异常：</span>
        <div style="width: 20px;height: 20px;background:#f8c662"></div>
      </div>
      <!--      <div style="display: flex;margin-right: 10px">-->
      <!--        <span>调拨：</span><div style="width: 20px;height: 20px;background:#45b787"></div>-->
      <!--      </div>-->
      <div style="display: flex;margin-right: 10px">
        <span>审核拒绝：</span>
        <div style="width: 20px;height: 20px;background:#65CCFF"></div>
      </div>
    </div>
    <div style="margin-top: 10px; margin-left: 10px; margin-right: 10px;">
      <el-table
        ref="tableData"
        :data="tableData"
        max-height="618px"
        element-loading-text="数据加载中"
        :cell-style="{ textAlign: 'center' }"
        v-loading="loadingTotal"
        :row-style="{ height: '57px', textAlign: 'center' }"
        :row-class-name="specialLabel"
        :header-cell-style="{
          background: '#dfe6ec',
          color: '#666666',
          textAlign: 'center'
        }"
      >
        <el-table-column label="序号" width="80" align="center">
          <template slot-scope="scope">
            {{ (page.pageNo - 1) * page.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          label="采购单号"
          prop="purchaseNo"
          align="center"
          width="180"
        >
          <template slot-scope="scope">
            <div style="display: flex;justify-content: center; width: 100%">
              <div>
                {{ scope.row.purchaseNo }}
              </div>
              <div
                class="copySty"
                style="margin-left: 10px;"
                @click="copyUrl(scope.row.purchaseNo)"
              >
                复制
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="采购类型"
          prop="purchaseType"
          align="center"
        ></el-table-column>
        <el-table-column
          label="商品数量"
          prop="productNum"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          label="采购金额"
          prop="totalMoney"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.totalMoney === null ? "- -" : scope.row.totalMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="渠道" prop="channel" width="100" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.channel === "" ? "- -" : scope.row.channel }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="发货方式" prop="deliveryMethod" align="center">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.deliveryMethod === "0"
                  ? "入库"
                  : scope.row.deliveryMethod === "1"
                  ? "代发"
                  : "- -"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="采购发起人"
          prop="createdName"
          align="center"
        ></el-table-column>
        <el-table-column label="状态" prop="status" width="100" align="center">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.status === 0
                  ? "待采购"
                  : scope.row.status === 1
                  ? "采购中"
                  : scope.row.status === 2
                  ? "已完成"
                  : scope.row.status === 3
                  ? "待终止"
                  : scope.row.status === 9
                  ? "已终止"
                  : "- -"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          label="备注信息"
          prop="remark"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.remark ? scope.row.remark : '- -' }}
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="createdTimestamp"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button
              plain
              type="info"
              v-if="!permission.isInfo"
              size="small"
              @click="returnInfo(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display:flex;justify-content:right;margin-top: 10px;">
      <el-pagination
        v-if="this.page.pageSize > 0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageNo"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="page.pageSize"
        layout="total, jumper, prev, pager, next,sizes"
        :total="page.sumDataCount"
      ></el-pagination>
    </div>
  </d2-container>
</template>

<script>
import api from '@/api'

// 搜索数据
const searchDefault = {
  purchaseNo: '', // 采购订单
  goodsName: '', // 商品名称
  purchaseTypes: [], // 采购
  channels: [], // 渠道
  deliveryMethods: [], // 发货方式
  status: [], // 状态
  createdIds: [], // 采购发起人
  createTimes: [], // 创建时间
  rightTotalMoney: undefined,
  leftTotalMoney: undefined
}
// 分页数据
const pageDefault = {
  pageNo: 1,
  pageSize: 50,
  sumDataCount: 0
}
export default {
  name: 'purchasing',
  data () {
    return {
      // 权限点
      permission: {
        isShow: this.checkauthority('purchase_page'), // 查询
        isAdd: this.checkauthority('purchase_create'), // 新增
        isInfo: this.checkauthority('purchase_detail') // 详情
      },
      onHoverText: '', // 鼠标以上显示文字
      purchaseTypesData: [], // 采购类型Data
      channelData: [], // 渠道Data
      deliveryMethodsData: [
        { id: '0', value: '入库' },
        { id: '1', value: '代发' }
      ], // 发货方式Data
      statusData: [
        { id: '0', value: '待采购' },
        { id: '1', value: '采购中' },
        { id: '2', value: '已完成' },
        { id: '3', value: '待终止' },
        { id: '9', value: '已终止' }
      ], // 状态Data
      createdIdsData: [], // 采购发起人Data
      search: JSON.parse(JSON.stringify(searchDefault)), // 搜索条件
      page: JSON.parse(JSON.stringify(pageDefault)), // 搜索条件
      tableData: [], // 表格数据
      loadingTotal: false, // 加载状态
      pickerOptions: {
        // 快捷日期选择
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  created () {
    this.initList()
  },
  methods: {
    // 获取采购类型
    async getPurchaseSource () {
      const res = await api.getPurchaseSelect({
        name: 'source'
      })
      const filterName = [49]
      this.purchaseTypesData = res.body.filter(
        (item, index) => !filterName.includes(item.id)
      )
    },
    // 获取采购渠道
    async getPurchaseChannel () {
      const res = await api.getPurchaseSelect({
        name: 'channel'
      })
      this.channelData = res.body
    },
    // 获取采购发起人
    async getPurchaseUser () {
      const res = await api.getPurchaseUser()
      this.createdIdsData = res.body
    },
    // 特殊标识
    specialLabel ({ row }) {
      if (row.cargoDamageFlag === 1) {
        return 'warning-row'
      } else if (row.confirmAllocate === true) {
        return 'success-row'
      } else if (row.auditStatus === 2 && row.status !== 9) {
        return 'danger-row'
      }
      return ''
    },
    // 初始化页面
    async initList () {
      this.loadingTotal = true
      this.getPurchaseSource()
      this.getPurchaseChannel()
      this.getPurchaseUser()
      const res = await api.getPageList_prurchasingList(
        {
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize
        },
        this.search
      )
      if (res.code === 200) {
        this.tableData = res.body.datas
        this.page.sumDataCount = res.body.sumDataCount
        this.loadingTotal = false
      } else {
        this.$message({
          type: 'danger',
          message: '页面加载失败!'
        })
        this.loadingTotal = false
      }
    },
    // 鼠标以上显示完整名称
    onMouseOver (item) {
      this.onHoverText = item
    },
    // /定义点击复制的功能
    copyUrl (url) {
      // 创建一个 Input标签
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      // 执行浏览器复制命令
      // / 复制命令会将当前选中的内容复制到剪切板中
      // / 如这里构建的 Input标签
      document.execCommand('Copy')
      this.$message({
        message: '复制成功',
        type: 'success'
      })
      // /复制成功后再将构造的标签 移除
      oInput.remove()
    },
    // 跳转至详情
    async returnInfo (item) {
      await this.$router.push({
        path: '/purchaseDetails',
        query: {
          purchaseNo: item.purchaseNo
        }
      })
    },
    // 搜索
    searchTotal () {
      this.page.pageNo = 1
      this.initList()
    },
    // 搜索分页
    handleSizeChange (val) {
      this.page.pageSize = val
      this.initList()
    },
    // 搜索跳转页数
    handleCurrentChange (val) {
      this.page.pageNo = val
      this.initList()
    },
    // 重置
    reset () {
      this.search = JSON.parse(JSON.stringify(searchDefault))
      this.page = JSON.parse(JSON.stringify(pageDefault))
      this.initList()
    },
    add () {
      this.$router.push('/newlyPurchasing')
    }
  }
}
</script>

<style scoped lang="scss">
.searchTimer {
  margin-right: 20px;
  width: 400px !important;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
}
.searchInput {
  margin-right: 20px;
  width: 220px !important;
}
//.purchaseNoSty {
//  font-size: 10px;
//  cursor: pointer;
//  color: #1482f0;
//}
.copySty {
  font-size: 10px;
  cursor: pointer;
  color: green;
}

::v-deep .el-table .warning-row {
  background: #f8c662;
}

::v-deep .el-table .success-row {
  background: #45b787;
}
::v-deep .el-table .danger-row {
  background: #65ccff;
}
</style>
