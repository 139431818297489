var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c("div", { staticStyle: { width: "100%" } }, [
      _c(
        "div",
        { staticClass: "searchSty" },
        [
          _c(
            "head-search",
            { attrs: { "label-style": "width:100px" } },
            [
              _c(
                "head-search-item",
                { attrs: { label: "商品编码" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入商品编码",
                      size: "small"
                    },
                    model: {
                      value: _vm.search.goodsCoding,
                      callback: function($$v) {
                        _vm.$set(_vm.search, "goodsCoding", $$v)
                      },
                      expression: "search.goodsCoding"
                    }
                  })
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "商品原ID" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入商品原ID",
                      size: "small"
                    },
                    model: {
                      value: _vm.search.oldId,
                      callback: function($$v) {
                        _vm.$set(_vm.search, "oldId", $$v)
                      },
                      expression: "search.oldId"
                    }
                  })
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入商品名称",
                      size: "small"
                    },
                    model: {
                      value: _vm.search.goodsName,
                      callback: function($$v) {
                        _vm.$set(_vm.search, "goodsName", $$v)
                      },
                      expression: "search.goodsName"
                    }
                  })
                ],
                1
              ),
              _c(
                "head-search-item",
                { attrs: { label: "统计日期" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "picker-options": _vm.pickerOptions,
                      align: "right",
                      "end-placeholder": "结束日期",
                      "range-separator": "至",
                      size: "small",
                      "start-placeholder": "开始日期",
                      type: "daterange",
                      "unlink-panels": "",
                      "value-format": "yyyy-MM-dd"
                    },
                    model: {
                      value: _vm.search.timeStamp,
                      callback: function($$v) {
                        _vm.$set(_vm.search, "timeStamp", $$v)
                      },
                      expression: "search.timeStamp"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "searchBtn" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-search",
                    size: "small",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.toQuery()
                    }
                  }
                },
                [_vm._v(" 搜索 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-refresh",
                    size: "small",
                    type: "warning"
                  },
                  on: {
                    click: function($event) {
                      return _vm.reset()
                    }
                  }
                },
                [_vm._v(" 重置 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.exportLoading,
                    loading: _vm.exportLoading,
                    icon: "el-icon-download",
                    size: "small",
                    type: "success"
                  },
                  on: { click: _vm.exportExcel }
                },
                [_vm._v(" 导出 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("div", [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingNum,
              expression: "loadingNum"
            }
          ],
          staticClass: "topCount"
        },
        [
          _c("div", { staticClass: "font" }, [
            _vm._v(" 待采购数量 "),
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.sumData.numWait))
            ])
          ]),
          _c("div", { staticClass: "font" }, [
            _vm._v(" 待采购金额 "),
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.isNumber(_vm.sumData.moneyWait).toFixed(2)))
            ])
          ]),
          _c("div", { staticClass: "font" }, [
            _vm._v(" 采购中数量 "),
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.sumData.numIng))
            ])
          ]),
          _c("div", { staticClass: "font" }, [
            _vm._v(" 采购中金额 "),
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.isNumber(_vm.sumData.moneyIng).toFixed(2)))
            ])
          ]),
          _c("div", { staticClass: "font" }, [
            _vm._v(" 采购完成数量 "),
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.sumData.numEd))
            ])
          ]),
          _c("div", { staticClass: "font" }, [
            _vm._v(" 采购完成金额 "),
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.isNumber(_vm.sumData.moneyEd).toFixed(2)))
            ])
          ])
        ]
      )
    ]),
    _c(
      "div",
      { staticStyle: { "margin-top": "10px" } },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingList,
                expression: "loadingList"
              }
            ],
            ref: "tableData",
            attrs: {
              "cell-style": { textAlign: "center" },
              data: _vm.tableData,
              "header-cell-style": {
                background: "#dfe6ec",
                color: "#666666",
                textAlign: "center"
              },
              "row-style": { height: "57px" },
              "element-loading-text": "数据加载中",
              "max-height": "670",
              "tooltip-effect": "dark"
            },
            on: { "sort-change": _vm.sortChange }
          },
          [
            _c("el-table-column", {
              attrs: { align: "center", label: "序号", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.search.pageNo - 1) * _vm.search.pageSize +
                              scope.$index +
                              1
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "商品编码", prop: "goodsCoding" }
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "商品原ID", prop: "oldId" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.goodsId ? scope.row.goodsId : "- -"
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "商品名称",
                prop: "goodsName",
                width: "300"
              }
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "商品图片", prop: "pictureUrl" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-image", {
                        staticStyle: {
                          width: "50px",
                          height: "50px",
                          "margin-top": "8px",
                          cursor: "pointer"
                        },
                        attrs: {
                          src: _vm.downloadUrl + "/" + scope.row.pictureUrl,
                          alt: "",
                          "preview-src-list": [
                            _vm.downloadUrl + "/" + scope.row.pictureUrl
                          ]
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "待采购数量",
                prop: "numWait",
                sortable: "custom"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "待采购金额",
                prop: "moneyWait",
                sortable: "custom"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(" " + _vm._s(scope.row.moneyWait.toFixed(2)) + " ")
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "采购中数量",
                prop: "numIng",
                sortable: "custom"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "采购中金额",
                prop: "moneyIng",
                sortable: "custom"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(" " + _vm._s(scope.row.moneyIng.toFixed(2)) + " ")
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "采购完成数量",
                prop: "numEd",
                sortable: "custom"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "采购完成金额",
                prop: "moneyEd",
                sortable: "custom"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(" " + _vm._s(scope.row.moneyEd.toFixed(2)) + " ")
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "操作" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", size: "small", type: "primary" },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.goodsDetails(
                                scope.row,
                                scope.$index,
                                _vm.tableData
                              )
                            }
                          }
                        },
                        [_vm._v("明细 ")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "right",
          "margin-top": "10px"
        }
      },
      [
        this.search.pageSize > 0
          ? _c("el-pagination", {
              attrs: {
                "current-page": _vm.search.pageNo,
                disabled: _vm.permission.isShow,
                "page-size": _vm.search.pageSize,
                "page-sizes": [10, 20, 30, 40, 50, 100],
                total: _vm.search.pageTotal,
                layout: "total, jumper, prev, pager, next,sizes"
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }