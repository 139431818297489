<template>
  <d2-container>
    <div class="header" v-loading="loadingInfo">
      <div class="searchBgc3" style="margin-bottom: 10px;display: flex;justify-content: space-between;">
        基本信息
      </div>
      <el-form ref="redactData" :model="redactData" label-width="100px">
        <div>
          <div  class="div1">
            <div style="display: flex">
              <el-form-item label="调拨单号:" prop="allocateNo">
                <div style="  color: #1482f0;font-weight: bolder;">
                  <el-tag>{{ this.$route.query.transferId }}</el-tag>
                </div>
              </el-form-item>
              <el-form-item label="创建时间:" prop="createdTimestamp">
                <div style="color: #000;font-weight: bolder;">
                  <el-tag type="success"> {{ this.redactData.createdTimestamp }}</el-tag>
                </div>
              </el-form-item>
              <el-form-item label="调拨状态:" prop="status">
                <div style="  color: #1482f0;font-weight: bolder;">
                  <div v-if="this.redactData.status == 0">
                    <el-tag>待审核</el-tag>
                  </div>
                  <div v-if="this.redactData.status == 1">
                    <el-tag type="success">完成订单</el-tag>
                  </div>
                  <div v-if="this.redactData.status == 2">
                    <el-tag type="danger">审核不通过</el-tag>
                  </div>
                  <div v-if="this.redactData.status == 3">
                    <el-tag type="success">选择库位完成</el-tag>
                  </div>
                  <div v-if="this.redactData.status == 4">
                    <el-tag type="success">审核通过</el-tag>
                  </div>
                  <div v-if="this.redactData.status == 9">
                    <el-tag type="warning">终止调拨</el-tag>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="调拨标题:" prop="name">
                <div style="color: #000;font-weight: bolder;">
                  {{ this.redactData.name }}
                </div>
              </el-form-item>
            </div>
            <div style="display: flex">
              <el-form-item label="创建人:">
                <div style="color: #000;font-weight: bolder;">
                  {{ this.redactData.createdName }}
                </div>
              </el-form-item>
              <el-form-item label="审核人员:">
                <div style="color: #000;font-weight: bolder;">
                  {{ this.redactData.applyName ? this.redactData.applyName : '- -' }}
                </div>
              </el-form-item>
              <el-form-item label="审核时间:">
                <div style="color: #000;font-weight: bolder;">
                  {{ this.redactData.applyTimestamp ? this.redactData.applyTimestamp : '- -' }}
                </div>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="searchBgc2"
          style="margin-bottom: 10px;margin-top: 20px; display: flex;justify-content: space-between;">
          调拨去向
        </div>
        <div  style="display:flex;width: 80%;margin:0 auto;justify-content: space-around">
          <div>
            <div>
            </div>
            <div style="display: flex;justify-content: space-between">
              <div>
                <img alt="" class="imgDemonstration" src="../../img/warehouse.png" style="margin-left: 6px" />
                <div class="imgTitle">来源仓库</div>
              </div>
              <div style="color: #1482f0;font-weight: bolder;margin-left: 10px;line-height: 80px">
                <el-tag>{{ this.redactData.outWarehouseName }}</el-tag>
              </div>
            </div>
          </div>
          <div class="arrows">
            <div class="arrowsLeft"></div>
            <i class="el-icon-arrow-right arrowsRight"></i>
          </div>
          <div>
            <div style="display: flex;justify-content: space-between">
              <div>
                <img alt="" class="imgDemonstration" src="../../img/department.png" style="margin-left: 7px" />
                <div class="imgTitle">调入部门</div>
              </div>
              <div style="color: #1482f0;font-weight: bolder;margin-left: 10px;line-height: 80px">
                <el-tag type="warning">{{ this.redactData.intoDeptName }}</el-tag>
              </div>
            </div>
          </div>
          <div class="arrows">
            <div class="arrowsLeft"></div>
            <i class="el-icon-arrow-right arrowsRight"></i>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div>
              <img alt="" class="imgDemonstration" src="../../img/warehouse.png" style="margin-left: 6px" />
              <div class="imgTitle">调入仓库</div>
            </div>
            <div style="color: #1482f0;font-weight: bolder;margin-left: 10px;line-height: 80px">
              <el-tag>{{ this.redactData.intoWarehouseName }}</el-tag>
            </div>
          </div>
        </div>
        <div class="searchBgc2"
          style="margin-bottom: 10px;margin-top: 20px; display: flex;justify-content: space-between;">
          商品信息
        </div>
        <el-form-item class="itemAddSty" prop="transferGoods">
          <el-table ref="transferGoods" v-loading="loadingGoods" :cell-style="{ textAlign: 'center' }"
            :data="redactData.transferGoods" :header-cell-style="{
              background: '#dfe6ec',
              color: '#666666',
              textAlign: 'center'
            }" :row-style="{ height: '77px' }" element-loading-text="数据加载中" max-height="690px"
            style="box-sizing: border-box;" tooltip-effect="dark">
            <el-table-column align="center" label="序号" width="80">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="商品名称" prop="goodsName">
            </el-table-column>
            <el-table-column align="center" label="商品图片" prop="pictureUrl">
              <template slot-scope="scope">
                <el-image :preview-src-list="[downloadUrl + '/' + scope.row.pictureUrl]"
                  :src="downloadUrl + '/' + scope.row.pictureUrl" style="width: 40px; height: 40px;" />
              </template>
            </el-table-column>
            <el-table-column align="center" label="原调拨价格" prop="price">
            </el-table-column>
            <el-table-column align="center" label="新调拨价格" prop="outPrice">
              <template slot-scope="scope">
                {{ scope.row.outPrice == null ? '- -' : (scope.row.outPrice == 0 ? '0' : scope.row.outPrice) }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="调拨数量" prop="num">
            </el-table-column>
            <el-table-column v-if="redactData.status !== 0" align="center" label="入库区域" prop="areaName" width="150">
              <template slot-scope="scope">
                {{ scope.row.areaName ? scope.row.areaName : '- -' }}
              </template>
            </el-table-column>
            <el-table-column v-if="redactData.status !== 0" align="center" label="入库货架" prop="shelvesName" width="150">
              <template slot-scope="scope">
                {{ scope.row.shelvesName ? scope.row.shelvesName : '- -' }}
              </template>
            </el-table-column>
            <el-table-column v-if="redactData.status !== 0" align="center" label="货架层数" prop="shelvesNum" width="150">
              <template slot-scope="scope">
                {{ scope.row.shelvesNum ? scope.row.shelvesNum : '- -' }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="总计">
              <template slot-scope="scope">
                {{
                  scope.row.outPrice ? (scope.row.num ? scope.row.outPrice * scope.row.num : '- -') : (scope.row.num ? scope.row.price * scope.row.num : '- -')
                }}
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <div class="searchBgc2" style="margin-bottom: 20px;">备注信息</div>
        <el-form-item label="调拨备注">
          <p class="textSpanSty">
            {{ this.redactData.remark ?  this.redactData.remark : '- -' }}
          </p>
        </el-form-item>
        <div class="searchBgc2" style="margin-bottom: 20px;">
          操作日志
        </div>
        <div>
          <el-timeline>
            <el-timeline-item v-for="(item, index) in logList" :key="index" :timestamp="item.createdTimestamp"
              style="box-sizing: border-box;overflow: hidden;text-overflow: ellipsis;white-space: normal;word-break: break-all;line-height: 23px;padding-right: 10px;">
              {{
                item.createdName +
                item.operateDesc +
                ' ' +
                remark +
                (item.remark === null ? '' : item.remark)
              }}
            </el-timeline-item>
          </el-timeline>
        </div>
        <div style="display: flex;justify-content: center;margin-bottom: 20px;">
          <el-button size="small" @click="resetForm('redactData')">关闭
          </el-button>
          <el-button v-if="redactData.status != 9 && isStopAllot && this.$route.query.activeName == 'initiated'" size="small" type="warning"
          :disabled="refuseLoading" :loading="refuseLoading"  @click.native.prevent="refuse()">终止调拨
          </el-button>
        </div>
      </el-form>
    </div>
  </d2-container>
</template>
<script>
import api from '@/api'
import { mapActions } from 'vuex'

const redactDataDefault = {
  id: '',
  name: '', // 调拨标题
  outWarehouseId: '', // 来源仓库
  outWarehouseName: '', // 来源仓库名称
  intoDeptId: '', //  调入部门
  intoDeptName: '', //  调入部门名称
  intoWarehouseId: '', // 调入仓库
  intoWarehouseName: '', // 调入仓库名称
  remark: '', // 备注
  createdName: '', // 创建人
  applyName: '', // 审核人员
  applyTimestamp: '', // 审核时间
  transferGoods: [] // 调拨商品
}
export default {
  directives: {
    // 防止el-button重复点击
    preventReClick: {
      // 指令的定义
      inserted (el, binding) {
        el.addEventListener('click', () => {
          if (!el.disabled) {
            el.disabled = true
            setTimeout(() => {
              el.disabled = false
            }, binding.value || 5000)
          }
        })
      }
    }
  },
  name: 'allotApplyDetail',
  data () {
    return {
      logList: [], // 操作日志
      remark: '备注信息:',
      status: '',
      loadingGoods: false,
      loadingInfo: false,
      refuseLoading: false,
      downloadUrl: process.env.VUE_APP_DOWNLOAD_URL, // downloadUrl
      redactData: JSON.parse(JSON.stringify(redactDataDefault)),
      userInfo: {},
      isStopAllot: false
    }
  },
  watch: {
    $route (to, from) {
      this.$route.query.id = to.query.id
      this.$route.query.transferId = to.query.transferId
      this.$route.query.activeName = to.query.activeName
      this.initList()
      this.getTransferGoods()
    }
  },
  async created () {
    this.$store.commit('d2admin/page/modifyOpend', opened => {
      opened.forEach((current) => {
        if (current.name === 'allotApplyDetail') {
          current.meta.title = '调拨详情-' + current.query.transferId
        }
      })
    })
    await this.initList()
    await this.getTransferGoods()
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const res = await api.getDepartmentToWarehouse({
      deptId: this.userInfo.deptId
    })
    res.body.forEach(item => {
      if (item.warehouseId == this.redactData.outWarehouseId) {
        this.isStopAllot = true
      }
    })
  },
  methods: {
    ...mapActions('d2admin/page', ['close']),
    // 获取信息
    async initList () {
      this.loadingInfo = true
      try {
        const res = await api.info_allotApply({
          id: this.$route.query.id
        })
        // this.redactData = res.body.transferSlipPes
        this.redactData.id = res.body.transferSlipPes.id
        this.redactData.name = res.body.transferSlipPes.name // 调拨标题
        this.redactData.outWarehouseId = res.body.transferSlipPes.outWarehouseId // 来源仓库
        this.redactData.outWarehouseName = res.body.transferSlipPes.outWarehouseName // 来源仓库名称
        this.redactData.intoDeptId = res.body.transferSlipPes.intoDeptId //  调入部门
        this.redactData.intoDeptName = res.body.transferSlipPes.intoDeptName //  调入部门名称
        this.redactData.intoWarehouseId = res.body.transferSlipPes.intoWarehouseId // 调入仓库
        this.redactData.intoWarehouseName = res.body.transferSlipPes.intoWarehouseName // 调入仓库名称
        this.redactData.remark = res.body.transferSlipPes.remark // 备注
        this.redactData.applyName = res.body.transferSlipPes.applyName // 审核人员
        this.redactData.applyTimestamp = res.body.transferSlipPes.applyTimestamp // 审核时间
        this.redactData.createdTimestamp = res.body.transferSlipPes.createdTimestamp // 创建时间
        this.redactData.status = res.body.transferSlipPes.status // 状态
        this.redactData.createdName = res.body.transferSlipPes.createdName // 创建人
        this.logList = res.body.logList
      } catch (error) {
        this.$message.error('加载数据失败')
      } finally {
        this.loadingInfo = false
      }
    },
    // 获取调拨商品列表
    async getTransferGoods () {
      this.loadingGoods = true
      try {
        const res = await api.get_transferGoods({
          id: this.$route.query.id
        })
        this.redactData.transferGoods = res.body
      } catch (e) {
        this.$message.error('取调拨商品列表失败')
      } finally {
        this.loadingGoods = false
      }
    },
    // 取消
    resetForm () {
      this.$refs.redactData.clearValidate()
      const tagName = '/allotApplyDetail?id=' + this.$route.query.id + '&' + 'transferId=' + this.$route.query.transferId + '&' + 'activeName=' + this.$route.query.activeName
      this.$router.push({ path: '/allotApply' })
      setTimeout(() => this.close({ tagName: tagName }), 10)
    },
    // 终止调拨
    refuse () {
      // loadingInfo
      // refuseLoading
      this.$confirm('是否终止调拨此订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          this.loadingInfo = true
          this.refuseLoading = true
          const res = await api.stop_allotApply({
            id: this.redactData.id
          })
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '终止成功!'
            })
            this.resetForm()
          } else {
            this.$message({
              type: 'warning',
              message: res.msg
            })
          }
        } catch (error) {
          this.$message({
            type: 'warning',
            message: '终止失败!'
          })
        } finally {
          this.loadingInfo = false
          this.refuseLoading = false
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消终止'
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  margin-left: 10px;
  margin-right: 10px;
  box-sizing: border-box;
  border: 2px solid #888;
  border-radius: 10px;
  min-height: 100%;
  width: 99%;
}

.searchBgc3 {
  height: 30px;
  line-height: 30px;
  border-radius: 10px 10px 0 0;
  background-color: #dfe6ec;
  padding-left: 10px;
  font-weight: bolder;
}

.searchBgc2 {
  height: 30px;
  line-height: 30px;
  background-color: #dfe6ec;
  padding-left: 10px;
  font-weight: bolder;
}

.div1 {
  width: 100%;
  //display: flex;
  //flex-wrap: wrap;
  //justify-content: left;
  margin-right: 20px;
  //flex-grow: transition 1;
}

.textAreaSty {
  border: 1px solid #dcdfe6;
  padding: 5px 15px;
  box-sizing: border-box;
  width: 98%;
  border-radius: 4px;
}

.textSpanSty {
  // background-color: #f60;
  width: 100%;
  margin-top: 0px;
  flex-wrap: wrap-reverse;
  table-layout: fixed;
  word-wrap: break-word;
  word-break: normal;
  overflow: hidden;
}

.textSty {
  display: flex;
  justify-content: right;
  margin-right: 5%;
  color: rgb(102, 102, 102);
  font-weight: bolder;
  margin-bottom: 20px;
}

.spanSty {
  width: 5%;
  height: 17px;
  line-height: 17px;
  color: #f60;
}

.imgTitle {
  margin-top: 5px;
  text-align: center;
  font-weight: bolder;
}

.imgDemonstration {
  width: 50px;
  height: 50px;
}

.arrows {
  display: flex;
  align-items: center;
  margin-top: -10px;
}

.arrowsLeft {
  height: 3px;
  background-color: #1296db;
  width: 180px;
  margin-top: 2px;
}

.arrowsRight {
  color: #1296db;
  font-weight: 900;
  font-size: 40px;
  margin-left: -25px;
}

::v-deep .itemAddSty .el-form-item__content {
  margin-left: 0px !important;
  padding: 0px 10px 0px;
}

::v-deep .itemAddSty .el-form-item__error {
  // margin-left: 48.2%;
  position: relative;
  top: 0;
  left: 0;
}
</style>
