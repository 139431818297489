var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "head-search",
        { attrs: { "label-style": "width:100px" } },
        [
          _c(
            "head-search-item",
            { attrs: { label: "退货单号" } },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入商品编码" },
                model: {
                  value: _vm.header.outNo,
                  callback: function($$v) {
                    _vm.$set(_vm.header, "outNo", $$v)
                  },
                  expression: "header.outNo"
                }
              })
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "商品编码" } },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入商品编码" },
                model: {
                  value: _vm.header.goodsCoding,
                  callback: function($$v) {
                    _vm.$set(_vm.header, "goodsCoding", $$v)
                  },
                  expression: "header.goodsCoding"
                }
              })
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "商品原ID" } },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入商品原ID" },
                model: {
                  value: _vm.header.goodsId,
                  callback: function($$v) {
                    _vm.$set(_vm.header, "goodsId", $$v)
                  },
                  expression: "header.goodsId"
                }
              })
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "商品名称" } },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入商品名称" },
                model: {
                  value: _vm.header.goodsName,
                  callback: function($$v) {
                    _vm.$set(_vm.header, "goodsName", $$v)
                  },
                  expression: "header.goodsName"
                }
              })
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "公司名称" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "searchInput",
                  attrs: {
                    multiple: "",
                    clearable: "",
                    placeholder: "请选择公司名称",
                    size: "small"
                  },
                  model: {
                    value: _vm.header.orgIds,
                    callback: function($$v) {
                      _vm.$set(_vm.header, "orgIds", $$v)
                    },
                    expression: "header.orgIds"
                  }
                },
                _vm._l(_vm.companyList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.orgName, value: item.orgId }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "部门名称" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "searchInput",
                  attrs: {
                    multiple: "",
                    clearable: "",
                    placeholder: "请选择部门名称",
                    size: "small",
                    disabled: _vm.disabledDeptIds
                  },
                  model: {
                    value: _vm.header.deptIds,
                    callback: function($$v) {
                      _vm.$set(_vm.header, "deptIds", $$v)
                    },
                    expression: "header.deptIds"
                  }
                },
                _vm._l(_vm.branchList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.orgName, value: item.orgId }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "仓库名称" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "searchInput",
                  attrs: {
                    multiple: "",
                    clearable: "",
                    placeholder: "请选择仓库名称",
                    size: "small"
                  },
                  model: {
                    value: _vm.header.warehouseIds,
                    callback: function($$v) {
                      _vm.$set(_vm.header, "warehouseIds", $$v)
                    },
                    expression: "header.warehouseIds"
                  }
                },
                _vm._l(_vm.warehouseList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label: item.warehouseName,
                      value: item.warehouseId
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "操作人" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "searchInput",
                  attrs: {
                    multiple: "",
                    clearable: "",
                    placeholder: "请选择操作人",
                    size: "small"
                  },
                  model: {
                    value: _vm.header.createdIds,
                    callback: function($$v) {
                      _vm.$set(_vm.header, "createdIds", $$v)
                    },
                    expression: "header.createdIds"
                  }
                },
                _vm._l(_vm.userList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "head-search-item",
            { attrs: { label: "统计日期" } },
            [
              _c("el-date-picker", {
                attrs: {
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  size: "small",
                  "start-placeholder": "开始日期",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                model: {
                  value: _vm.header.time,
                  callback: function($$v) {
                    _vm.$set(_vm.header, "time", $$v)
                  },
                  expression: "header.time"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "searchBtn" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-search",
                    size: "small",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.toQuery()
                    }
                  }
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-refresh",
                    size: "small",
                    type: "warning"
                  },
                  on: {
                    click: function($event) {
                      return _vm.resetBtn()
                    }
                  }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "topCount" }, [
        _c("div", [
          _vm._v("入库总价："),
          _c("span", [_vm._v(_vm._s(_vm.isNumber(_vm.count.price).toFixed(2)))])
        ]),
        _c("div", [
          _vm._v("出库总价："),
          _c("span", [
            _vm._v(_vm._s(_vm.isNumber(_vm.count.outPrice).toFixed(2)))
          ])
        ]),
        _c("div", [
          _vm._v("数量："),
          _c("span", [_vm._v(_vm._s(_vm.isNumber(_vm.count.sum).toFixed(2)))])
        ])
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            data: _vm.tableData,
            "header-cell-style": _vm.headerClass,
            "cell-style": _vm.rowClass
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "createdTimestamp", label: "退货时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "outId", label: "退货单号" }
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsCoding", label: "商品编码" }
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsId", label: "商品原ID" }
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsName", label: "商品名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "price", label: "入库价（元）", sortable: "custom" }
          }),
          _c("el-table-column", {
            attrs: { prop: "outPrice", label: "出库价", sortable: "custom" }
          }),
          _c("el-table-column", {
            attrs: { prop: "sum", label: "数量", sortable: "custom" }
          }),
          _c("el-table-column", {
            attrs: { prop: "reason", label: "退货原因" }
          }),
          _c("el-table-column", {
            attrs: { prop: "orgName", label: "公司名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "deptName", label: "部门名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "warehouseName", label: "仓库名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "createdName", label: "操作人" }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "right",
            "margin-top": "10px"
          }
        },
        [
          _vm.search.pageSize > 0
            ? _c("el-pagination", {
                attrs: {
                  "current-page": _vm.search.pageNo,
                  "page-size": _vm.search.pageSize,
                  "page-sizes": [10, 20, 30, 40, 50, 100],
                  total: _vm.search.pageTotal,
                  layout: "total, jumper, prev, pager, next,sizes"
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }