// 入库
export default ({ request }) => ({
  // 新增入库单
  warehouseIntoOrderSave (data = {}) {
    return request({
      url: '/v1/warehouseIntoOrder/save',
      method: 'post',
      data: data
    })
  },
  // 查询入库单列表
  inventorylist_getPage (data = {}) {
    return request({
      url: '/v1/warehouseIntoOrder/getPageList',
      method: 'post',
      data: data
    })
  },
  // 编辑中的入库单详情
  getWarehouseIntoInfo (data = {}) {
    return request({
      url: '/v1/warehouseIntoOrder/getWarehouseIntoInfo',
      method: 'post',
      data: data
    })
  },
  // 明细中的入库单详情
  getWarehouseDetailInfo (data = {}) {
    return request({
      url: '/v1/warehouseIntoOrder/getWarehouseDetailInfo',
      method: 'post',
      data: data
    })
  },
  // 发货方式：代发 && 入库状态：完成入库（3） || 入库状态：待入库（0）
  getWarehouseDetailGoods (data = {}) {
    return request({
      url: '/v1/warehouseIntoOrderProduct/getPageList',
      method: 'post',
      data: data
    })
  },
  getWarehouseDetailGoods2 (data = {}) {
    return request({
      url: '/v1/intoDetail/getPageList',
      method: 'post',
      data: data
    })
  },
  // 编辑入库单 into_order_update
  warehouseIntoOrderUpdate (data = {}) {
    return request({
      url: '/v1/warehouseIntoOrder/update',
      method: 'put',
      data: data
    })
  },
  // 详细商品信息中的重新生码
  intoDetailPrint (data = {}) {
    return request({
      url: '/v1/inventory/storage/reloadPrint',
      method: 'post',
      data: data
    })
  },
  // 查询异常详情
  getAbnormalDetail (data = {}) {
    return request({
      url: '/v1/warehouseIntoOrder/getAbnormalDetail',
      method: 'post',
      data: data
    })
  },
  // 终止入库
  stopEnterWarehouse (data = {}) {
    return request({
      url: '/v1/warehouseIntoOrder/stopEnterWarehouse',
      method: 'put',
      data: data
    })
  },
  intoDetail_exceptionPageList (data = {}) {
    return request({
      url: '/v1/intoDetail/exceptionPageList',
      method: 'post',
      data: data
    })
  },
  inDetail_copyWarehouseIntoOrder (data = {}) {
    return request({
      url: '/v1/warehouseIntoOrder/copyWarehouseIntoOrder',
      method: 'post',
      data: data
    })
  },
  /**
   * 生码
   * */
  createCode(data){
    return request({
      url: '/app/into/generateBarcode',
      method:'post',
      data
    })
  },
  /**
   * 商品入库
   * */
  goodsInStock(data){
    return request({
      url:'/cms/into/clickPlaceAndConfirm',
      method: 'post',
      data
    })
  }
})
