export default (function (_ref) {
  var request = _ref.request;
  return {
    // 选中一级分类 获取下一级数据
    getListByFatherIdToGoods: function getListByFatherIdToGoods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/classify/getListByFatherIdToGoods',
        method: 'post',
        data: data
      });
    },
    // 一级分类，二级分类，三级分类 查询
    getList_Classify: function getList_Classify() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/classify/getList',
        method: 'post',
        data: data
      });
    },
    // 商品品牌 查询
    selectAll_brand: function selectAll_brand() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/brand/selectAll',
        method: 'post',
        data: data
      });
    },
    // 商品ip 查询
    getListIp_ip: function getListIp_ip() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/ip/getList',
        method: 'post',
        data: data
      });
    },
    // 分页查询
    getPageList_goods: function getPageList_goods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/goods/getPageList',
        method: 'post',
        data: data
      });
    },
    // 关联商品查询
    betweenDetail: function betweenDetail() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/goods/betweenDetail',
        method: 'post',
        data: data
      });
    },
    // 关联商品删除
    betweenDelete: function betweenDelete() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/goods/betweenDelete',
        method: 'delete',
        data: data
      });
    },
    // 查询商品
    selectGoodsCoding: function selectGoodsCoding() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/goods/selectGoodsCoding',
        method: 'post',
        data: data
      });
    },
    // 查询商品
    goodsName: function goodsName() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/goods/goodsName',
        method: 'post',
        data: data
      });
    },
    // 新增商品关联
    betweenAdd: function betweenAdd() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/goods/betweenAdd',
        method: 'post',
        data: data
      });
    },
    // 新增商品
    save_goods: function save_goods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/goods/save',
        method: 'post',
        data: data
      });
    },
    // 删除商品
    delete_goods: function delete_goods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/goods/delete',
        method: 'delete',
        data: data
      });
    },
    // 编辑详情 查询
    goodsInfo: function goodsInfo() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/goods/info',
        method: 'post',
        data: data
      });
    },
    // 编辑商品
    update_goods: function update_goods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/v1/goods/update',
        method: 'post',
        data: data
      });
    }
  };
});