var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": true,
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "550px",
        "close-on-click-modal": false,
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "status-icon": "",
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "url" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    headers: _vm.uploadHeaders,
                    drag: "",
                    accept: ".jpeg,.png,.jpg",
                    "file-list": _vm.fileList,
                    action: _vm.uploadUrl + "v1/file/uploadFileImg",
                    "on-success": _vm.handleAvatarSuccess,
                    "before-upload": _vm.beforeAvatarUpload,
                    multiple: ""
                  }
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("将文件拖到此处，或"),
                    _c("em", [_vm._v("点击上传")])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip"
                    },
                    [_vm._v("上传文件不能超过10M")]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottomDiv" },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关闭")]),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.disabled, type: "primary" },
              on: { click: _vm.save }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }